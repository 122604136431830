<div class="flex flex-col gap-4 w-full justify-between transition h-full text-gray-500">

  <div class="py-6" #radialChart></div>

  <!-- Button Group -->
  <div class="flex flex-row gap-3 w-full">
    <div class="bg-primary-50 rounded-lg flex flex-col items-center justify-center h-[78px] w-full text-primary">
      <div class="bg-primary-light w-8 h-8 rounded-full text-sm font-medium flex items-center justify-center mb-1"> {{succeeded}}</div>
      <dd class="text-sm font-medium line-clamp-1">Erfüllt</dd>
    </div>
    <div class="bg-yellow-50 rounded-lg flex flex-col items-center justify-center h-[78px] w-full text-bic-yellow">
      <div class="bg-yellow-100 w-8 h-8 rounded-full text-sm font-medium flex items-center justify-center mb-1">{{ warning }}</div>
      <div class="text-sm font-medium line-clamp-1">Warnung</div>
    </div>
    <div class="bg-red-50 rounded-lg flex flex-col items-center justify-center h-[78px] w-full text-error">
      <div class="bg-red-100 w-8 h-8 rounded-full text-sm font-medium flex items-center justify-center mb-1">{{ failed }}</div>
      <div class="text-sm font-medium line-clamp-1">Fehler</div>
    </div>
  </div>

  <div>
  <hr>

  <!-- Button -->
  <div class="flex flex-row justify-end pt-4">
    <div class="flex flex-row gap-1 text-sm items-center rounded-lg px-3 py-2 cursor-pointer hover:bg-gray-100 active:opacity-50" (click)="openReport()">
      <p>Bericht anzeigen</p>
      <svg class="w-2.5 h-2.5 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
      </svg>
    </div>
  </div>
  </div>

</div>
