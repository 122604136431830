import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-loi-delete-standard-confirmation-dialog',
  templateUrl: './loi-delete-standard-confirmation-dialog.component.html',
  styleUrls: ['./loi-delete-standard-confirmation-dialog.component.scss']
})
export class LoiDeleteStandardConfirmationDialogComponent implements OnInit {
  //@Input() standardName: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: {}, private dialogRef: MatDialogRef<LoiDeleteStandardConfirmationDialogComponent>) {
  }

  ngOnInit() {
  }

  onDelete() {
    this.dialogRef.close(true);
  }
}
