<div class="flex flex-col overflow-hidden">
  <img #image id="image" height="400" [src]="sanitizedUrl">
  <div class="flex flex-row p-2 justify-between">
    <button mat-flat-button (click)="close()">
      Abbrechen
    </button>

    <div class="flex flex-row">
      <button  mat-flat-button (click)="reset()">
        Zurücksetzen
      </button>
      <button color="primary" mat-flat-button (click)="crop()">
        Auswählen
      </button>
    </div>
  </div>
</div>
