import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.scss']
})
export class ViewImageComponent implements OnInit{

  image!: string

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    image: string
  }, private dialogRef: MatDialogRef<ViewImageComponent>) {


  }

  ngOnInit() {
    this.image = this.data.image
  }

  close() {
    this.dialogRef.close('close');
  }

  removeImage(){
    this.dialogRef.close('remove');
  }

  uploadImage(){
    this.dialogRef.close('upload');
  }

}
