import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {AuthControllerService, User} from "../../api";

@Injectable({
  providedIn: 'root'
})
export class UserResolver  {
  constructor(private authControllerService: AuthControllerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    return this.authControllerService.profile();
  }
}
