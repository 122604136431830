import { Component, OnInit } from '@angular/core';
import {FileControllerService, ProjectFile, Task, TaskControllerService} from "../../../api";
import {StateConfigService} from "../../../services/state/state-config.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {TaskDetailsService} from "../../../services/task-details.service";
import {PopoutContentService} from "../../../services/popout/popout.service";
import {ActivatedRoute} from "@angular/router";
import {BicIfcViewerComponent} from "@buildinginformationcloud/bic-ifc-viewer";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {FileTreeNode} from "../../util/files/files.component";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {faIfc} from "@awesome.me/kit-6ed88ea8d1/icons/modules/kit/custom";

@Component({
  selector: 'app-ifc-viewer',
  templateUrl: './ifc-viewer.component.html',
  styleUrls: ['./ifc-viewer.component.scss']
})
export class IfcViewerComponent implements OnInit {

  ifcFiles: ProjectFile[] = [];
  fileForm!: UntypedFormGroup;
  ifcUrlString = '';
  filenameString = '';
  columns =['Fachmodelle', 'Größe (MB)', 'Zuletzt geändert']
  column = ['name', 'size', 'lastModified']
  colTypes =['text', 'filesize', 'date']
  preselectedFile!: FileTreeNode;

  constructor(private fileControllerService: FileControllerService,
              private stateConfigService: StateConfigService,
              private taskService: TaskDetailsService,
              public activatedRoute: ActivatedRoute,
              public popoutService: PopoutContentService) { }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['url']) {
        this.ifcUrlString = decodeURIComponent(params['url']);
        this.popoutService.opened = true;
      }

      if (params['filename']) {
        this.filenameString = params['filename'];
      }
    });

    this.fileForm = new UntypedFormGroup({
      projectFile: new UntypedFormControl(undefined, Validators.required),
    });

    this.fileControllerService.getFiles(this.stateConfigService.getProjectId()).subscribe(files => {
      this.ifcFiles = files.filter(file => file.fileType === 'IFC');
    });
  }

  openIFCViewer(file: FileTreeNode){
    let baseUrl = window.location.origin + '/projects/' + this.stateConfigService.selectedProject.id + '/files/' + file.id;
    this.popoutService.open(baseUrl, "IFC-Viewer")
  }

  get projectFile() {
    return this.fileForm.value.projectFile;
  }

  filename() {
    return "";
    // if (this.filenameString != '') {
    //   return this.filenameString;
    // }
    //
    // return this.fileForm.value.projectFile.lph + ' - ' + this.fileForm.value.projectFile.name;
  }

  test(){
    console.log(this.preselectedFile)
  }

  createTask() {
    let task = {
      lph: this.fileForm.value.projectFile.lph,
    }
    this.taskService.setOpenedTask(task as Task);
  }

  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }

  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faArrowRight = faArrowRight;
  protected readonly faIfc = faIfc;
}
