import {AfterViewInit, Component, Input} from '@angular/core';
import {CheckResultData} from "../dashboard.component";
import {KeyValue} from "@angular/common";

@Component({
  selector: 'app-dashboard-checks',
  templateUrl: './dashboard-checks.component.html',
  styleUrls: ['./dashboard-checks.component.scss']
})
export class DashboardChecksComponent implements AfterViewInit {


  @Input() checks: CheckResultData[] = [];
  selectedCheck!: CheckResultData

  get succeeded(): number {
    return this.selectedCheck!.succeeded;
  }

  get warning(): number {
    return this.selectedCheck!.warning;
  }

  get failed(): number {
    return this.selectedCheck!.failed;
  }

  get total(): number {
    return this.succeeded + this.warning + this.failed;
  }

  get values() {
    return this.selectedCheck!.values;
  }

  ngAfterViewInit(): void {
    this.selectedCheck = this.checks[0];
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }


  protected readonly Math = Math;
}
