/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Role } from './role';

export interface UserSimpleDto { 
    id?: number;
    title?: UserSimpleDto.TitleEnum;
    firstName?: string;
    lastName?: string;
    mail?: string;
    profileImage?: string;
    isActivated?: boolean;
    roleInOrganisations?: { [key: string]: Role; };
    roleInProjects?: { [key: string]: Role; };
}
export namespace UserSimpleDto {
    export type TitleEnum = 'PROF' | 'PROF_DR' | 'DR' | 'NONE';
    export const TitleEnum = {
        PROF: 'PROF' as TitleEnum,
        PROFDR: 'PROF_DR' as TitleEnum,
        DR: 'DR' as TitleEnum,
        NONE: 'NONE' as TitleEnum
    };
}