import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, ValidationErrors, ValidatorFn,
  Validators
} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {
  AuthControllerService,
  BillingControllerService,
  Organisation,
  OrganisationControllerService,
  ProjectControllerService,
  ServiceControllerService,
  SimplePlace,
  User,
  UserControllerService,
  UserFullDto
} from "../../api";
import {Observable, Subject} from "rxjs";
import {DatePipe} from "@angular/common";
import {MatRipple} from "@angular/material/core";
import {AuthService} from "../../services/auth/auth.service";
import {CanDeactivateComponent} from "../../guards/unsaved-changes.guard";
import {PermissionService} from "../../services/permission/permission.service";
import {ErrorService} from "../../services/error/error.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {StateConfigService} from "../../services/state/state-config.service";
import {AddressInput} from "../special/application/State";
import SalutationEnum = User.SalutationEnum;
import LegalFormEnum = Organisation.LegalFormEnum;
import BranchEnum = User.BranchEnum;
import TitleEnum = User.TitleEnum;
import CountryEnum = AddressInput.CountryEnum;
import {faChevronRight, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import { MatExpansionPanel } from '@angular/material/expansion';
import {LoiConfirmationDialog} from "../ifcCheck/components/loi-check-choose-rules/loi-check-choose-rules.component";
import {FtaPopupComponent} from "./fta-popup/fta-popup.component";
import {faCircleInfo, faXmark} from "@fortawesome/pro-solid-svg-icons";
import {FtaPopupDeactivateComponent} from "./fta-popup/fta-popup-deactivate/fta-popup-deactivate.component";

/**
 * Profile of User
 */
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, CanDeactivateComponent {

  registerForm!: UntypedFormGroup;
  passwordForm!: UntypedFormGroup;
  addressInfoForm!: FormGroup;
  @ViewChild('addressPanel') addressPanel!: MatExpansionPanel;
  @ViewChild('twoFaPanel') twoFaPanel!: MatExpansionPanel;

  @Input() user!: User;

  tfaMail: boolean = false
  tfaApp: boolean = false

  showPasswordOld: boolean = false;
  showPasswordNew: boolean = false;
  showPasswordNewRepeat: boolean = false;


  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private authControllerService: AuthControllerService,
              private userControllerService: UserControllerService,
              public permissionService: PermissionService,
              public dialog: MatDialog,
              public snackbar: MatSnackBar,
              public stateConfigService: StateConfigService,
              private errorService: ErrorService) {}

  unsubscribe: Subject<void> = new Subject<void>();
  salutation: typeof SalutationEnum = SalutationEnum;
  branch: typeof BranchEnum = BranchEnum;

  title: typeof TitleEnum = TitleEnum;

  isInit: boolean = false;
  /**
   * Initialization of Component
   */
  ngOnInit(): void {
    this.route.parent?.queryParams.subscribe(params => {
      this.isInit = params.init;
    });

    if (this.user) {
      console.log(this.user);
      this.initForm(this.user);
    } else {
      this.authControllerService.profile().subscribe(user => {
        this.user = user;
        console.log(this.user);
        this.initForm(user);
        if(this.user.isUsing2FA){
          if(this.user.isUsing2FAViaMail){
            this.tfaMail = true;
          }else{
            this.tfaApp = true;
          }
        }
        this.registerForm.updateValueAndValidity({onlySelf: false, emitEvent: true});
      });
    }

  }

  /**
   * Init profile Form for given User
   * @param user user for Form Data
   */
  initForm(user: User) {
    this.registerForm = this.formBuilder.group({
      title: new UntypedFormControl(TitleEnum.NONE),
      salutation: new UntypedFormControl(SalutationEnum.NONE, [Validators.required]),
      firstName: new UntypedFormControl("", [Validators.required, Validators.pattern('^[a-zA-ZäöüÄÖÜß ]*$')]),
      lastName: new UntypedFormControl("", [Validators.required, Validators.pattern('^[a-zA-ZäöüÄÖÜß ]*$')]),
      birthdate: new UntypedFormControl(),
      branch: new UntypedFormControl(),
      mail: new UntypedFormControl({value: this.user.mail, disabled: true}),
      phoneNumber: new UntypedFormControl(this.user.phoneNumber, [Validators.pattern('^[0-9]*$')]),
    });

    this.addressInfoForm = new FormGroup({
      zipCode: new UntypedFormControl(this.user.zipCode, [Validators.required]),
      city: new UntypedFormControl(this.user.city, [Validators.required]),
      country: new UntypedFormControl(this.user.country, [Validators.required]),
      street: new UntypedFormControl(this.user.street, [Validators.required]),
      houseNumber: new UntypedFormControl(this.user.houseNumber, [Validators.required]),
    });


    this.passwordForm = this.formBuilder.group({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.minLength(8), Validators.required, this.passwordValidator()]),
      newPasswordRepeat: new FormControl('', [Validators.required, this.matchPasswords()]),
      user: this.user
    });

    this.registerForm.patchValue(user);
    this.registerForm.patchValue({
      mailValidation: user.mail
    })

    let firstChange = true;
    this.registerForm.valueChanges.subscribe(() => {
      if(!firstChange) {
        this.registerForm.markAsDirty();
      }
      firstChange = false;
    });

  }

  completeRegistration() {
    this.user = {...this.user, ...this.registerForm.value};
    this.userControllerService.updateUser(this.user).subscribe(user => {
      this.router.navigateByUrl("/projects");
    });
  }


  save(): void {
    this.user = {...this.user, ...this.registerForm.value};
    console.log(this.registerForm.get('birthdate')?.value);
    console.log(this.user);
    if(this.registerForm.valid){
      this.userControllerService.updateUser(this.user).subscribe(user => {
        this.registerForm.markAsPristine();
        this.user = user;
        this.snackbar.open("Änderungen gespeichert", "OK", {
          panelClass: ['snackbar-success'],
        });
      });
    }else {
      if(this.registerForm.controls.firstName.invalid){
        this.snackbar.open("Bitte einen gültigen Vornamen ohne Sonderzeichen angeben", "OK", {
          panelClass: ['snackbar-error'],
        });
      }else if (this.registerForm.controls.lastName.invalid){
        this.snackbar.open("Bitte einen gültigen Nachnamen ohne Sonderzeichen angeben", "OK", {
          duration: 1000,
        });
      }else{
        this.snackbar.open("Bitte alle Pflichtfelder ausfüllen", "OK", {
          duration: 1000,
        });
      }
    }

  }

  saveAddress(): void {
    console.log(this.addressInfoForm.value);
    console.log(this.user);
    this.user = {...this.user, ...this.addressInfoForm.value};
    console.log(this.user);
    if (this.addressInfoForm.valid) {
      this.userControllerService.updateUser(this.user).subscribe(user => {
        this.addressInfoForm.markAsPristine();
        this.user = user;
        this.snackbar.open("Änderungen gespeichert", "OK", {
          duration: 1000,
        });
      });
    } else {
      this.snackbar.open("Bitte alle Pflichtfelder ausfüllen", "OK", {
        duration: 1000,
      });
    }

  }

  renewPassword(): void {
    this.errorService.errorEvent$.subscribe((error) => {
      this.snackbar.open("Passwort konnte nicht geändert werden", "OK", {
        duration: 5000,
      });
    });

    if (this.passwordForm.value.newPassword === this.passwordForm.value.newPasswordRepeat) {
      this.authControllerService.renewPassword(this.passwordForm.value).subscribe(pw => {
        this.passwordForm = this.formBuilder.group({
          currentPassword: new UntypedFormControl(),
          newPassword: new UntypedFormControl(),
          newPasswordRepeat: new UntypedFormControl(),
          user: this.user
        });
        this.snackbar.open("Passwort geändert", "OK", {
          duration: 5000,
        });
      });
    } else {
      this.snackbar.open("Passwörter stimmen nicht überein", "OK", {
        duration: 5000,
        panelClass: ['snackbar-error']
      })
    }
  }

  /**
   * Opens User delete Dialog
   */
  openDeleteDialog() {
    let dialogRef = this.dialog.open(UserDeleteDialog, {
      panelClass:"rounded-corners-dialog",
      width: '540px',
      data: {user: this.user}
    });
  }

  disableForm(): boolean {
    return this.registerForm.disabled;
  }

  validForm(): boolean {
    return this.registerForm.valid || this.registerForm.disabled;
  }

  canDeactivate(): boolean {
    return this.registerForm.valid && (this.registerForm.pristine || this.isInit);
  }

  handleChange(event: Event){
    const inputElement = event.target as HTMLInputElement;

    if(inputElement.id == 'tfaApp'){
      this.tfaApp = inputElement.checked;
      if(this.tfaMail){
        this.tfaMail = false;
        this.openDeactivatePopup('tfaApp', true);
      }else{
        if(!this.tfaApp){
          this.openDeactivatePopup('tfaApp');
        }else{
          this.openTfaPopup();
        }
      }
    }else{
      this.tfaMail = inputElement.checked;
      if(this.tfaApp){
        this.tfaApp = false;
        this.openDeactivatePopup('tfaMail', true);
      }else{
        if(!this.tfaMail){
          this.openDeactivatePopup('tfaMail');
        }else{
          this.openTfaPopup();
        }
      }
    }

  }

  openDeactivatePopup(input: string, changeMethode: boolean = false){
    const dialogRef = this.dialog.open(FtaPopupDeactivateComponent, {
      panelClass: 'rounded-corners-dialog',
      width: '400px',
      height: '500px',
      data: {changeMethode: changeMethode},
    });

    dialogRef.afterClosed().subscribe(result => {

      if(result.success) {
        if(result.activateOtherMethod){
          if(input == 'tfaApp') {
            this.tfaApp = true;
            this.openTfaPopup();
          }else{
            this.tfaMail = true;
            this.openTfaPopup();
          }
        }else{
          this.snackbar.open("2FA erfolgreich deaktiviert", "OK", {
            duration: 5000,
          });
          this.tfaApp = false
          this.tfaMail = false
        }
      }else{
        if(changeMethode){
          if(input == 'tfaApp') {
            this.tfaMail = true;
            this.tfaApp = false;
          }else{
            this.tfaApp = true;
            this.tfaMail = false;
          }
        }else{
          if(input == 'tfaApp') {
            this.tfaApp = true;
          }else {
            this.tfaMail = true;
          }
        }
      }
    });
  }

  openTfaPopup(){
    const dialogRef = this.dialog.open(FtaPopupComponent, {
      panelClass: 'rounded-corners-dialog',
      width: '400px',
      height: '500px',
      data: {isMail: this.tfaMail,
        user: this.user},
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result.success) {
          this.snackbar.open("2FA erfolgreich aktiviert", "OK", {
            duration: 5000,
          });
          this.authControllerService.profile().subscribe(user => {
            this.user = user;
          });
        }else{
          this.tfaApp = false
          this.tfaMail = false
        }
      });
  }

  is2faButtonDisabled(): boolean {
    if(!this.user.isUsing2FA){
      return !this.tfaApp && !this.tfaMail;
    }else{
      return this.tfaMail == this.user.isUsing2FAViaMail && this.tfaApp == !this.user.isUsing2FAViaMail;
    }
  }

  testLength(str: string): boolean {
    return str.length >= 8;
  }

  testLowerCase(str: string): boolean {
    return /[a-z]+/.test(str);
  }

  testUpperCase(str: string): boolean {
    return /[A-Z]+/.test(str);
  }

  testNumeric(str: string): boolean {
    return /[0-9]+/.test(str);
  }

  testSpecialCharacter(str: string): boolean {
    return /[!\?&%=.\-:*]+/.test(str);
  }

  matchPasswords(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      if (this.passwordForm) {
        return this.passwordForm.get('newPassword')?.value == control.value ? null : {matchValue: false};
      } else {
        return {matchValue: false};
      }

    };
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const hasUpperCase = /[A-Z]+/.test(value);
      const hasLowerCase = /[a-z]+/.test(value);
      const hasNumeric = /[0-9]+/.test(value);
      const hasSpecialCharacter = /[!\?&%=.\-:*]+/.test(value);
      const hasNoSpaces = !/\s/.test(value);


      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialCharacter && hasNoSpaces;

      return !passwordValid ? {passwordInvalid: true} : null;
    };
  }


  protected readonly faChevronRight = faChevronRight;

}

/**
 * Dialog for User Deletion
 */
@Component({
  selector: 'profile-deleteDialog',
  templateUrl: './profile-delete-dialog.html',
  styleUrls: ['./profile.component.scss'],
})
export class UserDeleteDialog implements OnInit {

  /**
   * MatRipple
   */
  @ViewChild(MatRipple) ripple!: MatRipple;

  /**
   * Constructor
   * @param router Router
   * @param userControllerService UserControllerService
   * @param data Dialog data
   * @param dialogRef MatDialogRef
   * @param authService AuthService
   */
  constructor(private router: Router, private userControllerService: UserControllerService, @Inject(MAT_DIALOG_DATA) public data: { user: User }, private dialogRef: MatDialogRef<UserDeleteDialog>, private authService: AuthService) {
  }

  /**
   * Handler on holding down delete Button
   * @param e time holding down
   */
  holdHandler(e: any) {
    // if (e === 3000) {
    //   this.dialogRef.close();
    //   this.userControllerService.deleteUser(this.data.user.id!).subscribe(next => {
    //     this.authService.logout()
    //     this.router.navigate(
    //     ["/login"],
    //     { queryParams: { isDeletedAccount: true } }
    //     );
    //   })
    // }
  }

  close() {
    this.dialogRef.close();
  }

  delete() {
    this.userControllerService.deleteUser(this.data.user.id!).subscribe(next => {
      this.authService.logout()
      this.router.navigate(
      ["/login"],
      { queryParams: { isDeletedAccount: true } }
      );
    })
  }

  /**
   * Initialization of Component
   */
  ngOnInit() {
  }


  protected readonly faXmark = faXmark;
}
