<div class="h-full w-full flex flex-col justify-center items-center gap-2">
  <div class="h-12 w-full px-1.5 py-2 rounded-lg border border-[#e6eaec] justify-center items-center gap-2 inline-flex">
    <div class="h-8 bg-gray-100 rounded-lg justify-center items-center flex"
    [class]="percentage==100?'w-12':'w-10'">
      <div class="text-[#042e3f] text-sm font-medium font-['Inter'] leading-none ">{{percentage}}%</div>
    </div>
    <div class="text-[#042e3f] text-sm font-medium font-['Inter'] leading-none">{{ state }}</div>
  </div>

  <div class="h-full w-full px-3 py-3 bg-gray-50 rounded-lg justify-start items-end gap-6 inline-flex transition">

    <div class="w-1/2" #columnChart></div>

    <div class="w-1/2 h-36 flex-col justify-end items-start gap-3 inline-flex">
      <div class="self-stretch h-6 text-[#042e3f] text-base font-normal font-['Inter'] leading-normal">{{ basicTasks }}</div>
      <div class="self-stretch opacity-50 text-[#042e3f] text-sm font-semibold font-['Inter'] leading-none">Basic
        Tasks
      </div>
      <div class="border-b border-gray-300 h-[1px]"></div>
      <div class="self-stretch h-6 text-[#042e3f]/40 text-base font-normal font-['Inter'] leading-normal">{{ bcfTasks }}</div>
      <div class="self-stretch opacity-50 text-[#042e3f]/40 text-sm font-semibold font-['Inter'] leading-none">BCF
        Tasks
      </div>
    </div>
  </div>

</div>
