import { Injectable } from '@angular/core';
import {interval, Subscription} from "rxjs";
import {StateConfigService} from "./state-config.service";
import {DefaultService} from "../../bim-api";
import {BimBaseCheckControllerService, IfcControllerService} from "../../api";

@Injectable({
  providedIn: 'root'
})
export class BimBaseStateService {

  private bimBaseStateSubscription!: Subscription;
  private runningChecks: string[] = [];
  constructor(private stateConfigService: StateConfigService, private bimService: DefaultService, private bimBaseControllerService: BimBaseCheckControllerService) {


  }

  public start(): void {
    this.stateConfigService.runningBimBaseChecksChange.subscribe((runningChecks: string[] | undefined) => {
      this.runningChecks = runningChecks ?? [];

      if (this.bimBaseStateSubscription) {
        this.bimBaseStateSubscription.unsubscribe();
      }

      // Subscribe to all running checks to get their status in 20s intervals and update the state
      this.bimBaseStateSubscription = interval(20000).subscribe(() => {
        for (let check of this.runningChecks) {
          this.bimService.status(check).subscribe((status) => {
            if(status.status == "Completed") {
              // @ts-ignore
              const result = status.output;

              this.bimBaseControllerService.completeCheck1(result, check).subscribe();
            }
          })
        }
      });

    })
  }

  public stop(): void {
    if(this.bimBaseStateSubscription){
        this.bimBaseStateSubscription.unsubscribe();
    }
  }


}
