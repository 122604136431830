<div class="w-full">
  <app-stepper [linear]="true" (selectedIndexChange)="changeStep($event)" [selectedIndex]="selectedStep">
    <cdk-step [label]="'Prüfregelauswahl'" [completed]="completedSteps.includes(0)" [editable]="false">
    </cdk-step>
    <cdk-step [label]="'Ergebnis - Prüfregeln'" [completed]="completedSteps.includes(1)">
    </cdk-step>
    <cdk-step [label]="'Ergebnis - Dateien'" [completed]="isDocView">
    </cdk-step>
    <cdk-step [label]="'Ergebnis - Detail'" [completed]="isDetail">
    </cdk-step>
  </app-stepper>

</div>
