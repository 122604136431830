import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'app-dashboardcard',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() title: string = "";
  @Input() icon!: IconProp;
  @Input() info: boolean = false;
  @Input() gapBetweenHeadlineAndContent: boolean = true;

  @Output() onClick: EventEmitter<void> = new EventEmitter<void>();



  buttonClicked(): void {
    this.onClick.emit();
  }

}
