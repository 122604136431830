
<div class="flex flex-col justify-start items-center w-full h-full overflow-y-scroll overflow-x-hidden" *ngIf="stateConfigService.contingent && this.prices.length > 0">
  <div class="shadow-full py-4 px-6 w-full flex flex-row justify-between items-center">
    <span class="font-medium text-lg">Shop</span>


    <div class="w-full flex flex-row justify-end items-center gap-12">
        <bic-select [dropdownWithSelectionWidth]="false" [value]="stateConfigService.isPersonal ? 0: stateConfigService.selectedOrganisation.id">
          <bic-dropdown-element [value]="0" (onClick)="stateConfigService.selectOrganisation(undefined, false, true)">Persönlich</bic-dropdown-element>
          <bic-dropdown-element *ngFor="let organisation of organisations" [value]="organisation.id" (onClick)="stateConfigService.selectOrganisation(organisation.id, false, false)">
            {{organisation.name}}
          </bic-dropdown-element>
        </bic-select>
        <bic-button [color]="'primary'" [textColor]="'white'" (onClick)="openCustomerPortal()" [text]="'Zahlungen verwalten'"></bic-button>
    </div>
  </div>




  <div class="w-full h-full p-6 pb-12 flex flex-col justify-between items-center">
    <div class="flex flex-row w-full justify-start">
      <div *ngIf="!stateConfigService.contingent">
        Kein aktives Abonnement
      </div>
      <div class="flex flex-col justify-start items-start" *ngIf="stateConfigService.contingent">
        <div class="flex flex-row">
          <span class="text-gray-500 font-medium">Ihr Abo:</span>
          <span class="text-gray-900 font-medium">&nbsp;{{activeAbonnementName}}</span>
        </div>
        <div class="flex flex-row">
          <span class="text-gray-500 font-medium">Aktueller Zeitraum: </span>
          <span class="text-gray-900 font-medium">&nbsp;{{stateConfigService.contingent.currentPeriodStart | date: 'dd.MM.yyyy'}} - {{(stateConfigService.contingent.trialEnd ? stateConfigService.contingent.trialEnd : stateConfigService.contingent.currentPeriodEnd) | date:'dd.MM.yyyy'}}</span>
        </div>
        <div class="flex flex-row">
          <span class="text-gray-500 font-medium">Verfügbare Credits: </span>
          <span class="text-gray-900 font-medium">&nbsp;{{stateConfigService.contingent.credits}}</span>
        </div>
      </div>
    </div>

    <div class="w-full h-full flex flex-col justify-start items-center gap-4 -mt-16">
      <div class="flex flex-row w-full">
        <div class="flex flex-col w-full items-center">
          <h1 class="text-[36px] font-extrabold">planBIC - Preismodelle</h1>
          <p class="text-lg pt-4 pb-6">Das passende Abo-Modell für Ihr Projekt!</p>
          <div class="flex flex-row justify-center items-center font-medium overflow-hidden relative rounded-lg bg-gray-200">
            <div class="flex flex-row w-full p-0.5 justify-center">
              <bic-button (onClick)="priceType = PriceType.MONTHLY" [color]="isMonthly ? 'secondary' : 'gray-200'" [textColor]="isMonthly ? 'white' : 'gray-900'" [text]="'Monatlich'"></bic-button>
              <bic-button (onClick)="priceType = PriceType.YEARLY" [color]="isYearly ? 'secondary' : 'gray-200'" [textColor]="isYearly ? 'white' : 'gray-900'" [text]="'Jährlich'"></bic-button>
            </div>
          </div>
        </div>
      </div>


      <div class="flex flex-row flex-grow justify-center items-start w-full h-full gap-6">
        <div class="w-full h-full" *ngFor="let price of abonnements">
          <app-checkout-price-card [active]="isActivated(price)" [price]="price"
                                   [isAddOn]="priceType == PriceType.ADDON"
                                   [isSpecial]="isSpecial(price)"
                                   [addOn]="oneTimeBy(price)" [productSettings]="productSettingsBy(price)"
                                   [contingent]="stateConfigService.contingent"
                                   (checkoutClicked)="checkout($event)">
          </app-checkout-price-card>
        </div>
      </div>
    </div>


    <div class="flex flex-col " *ngIf="false">
      <div class="flex items-center justify-center text-xl font-semibold">
        Enterprise
      </div>
      <div class="flex items-center justify-center">

      </div>
      <div class="flex flex-row justify-center p-2">
        <a href="mailto:info@thinkbic.de"
           class="rounded-md shadow-full text-primary font-medium text-center py-2 px-8 select-none cursor-pointer hover:bg-primary hover:text-white">Jetzt anfragen</a>
      </div>
    </div>
  </div>
</div>
