import { Pipe, PipeTransform } from '@angular/core';
import {User} from "../api";
import BranchEnum = User.BranchEnum;

@Pipe({
  name: 'branch'
})
export class BranchPipe implements PipeTransform {

  transform(value: BranchEnum): String {
    switch (value) {
      case BranchEnum.BAUHERR: return "Bauherr*innen";
      case BranchEnum.PROJEKTENTWICKLER: return "Projektentwickler*innen";
      case BranchEnum.VERANTWORTLICHERPLANER: return "Verantwortliche Planer*innen";
      case BranchEnum.FACHPLANER: return "Fachplaner*innen";
      case BranchEnum.AUSFHRENDESBAUUNTERNEHMEN: return "Ausführende Bauunternehmen";
      case BranchEnum.NUTZER: return "Immobilien Nutzer*innen";
      case BranchEnum.BAUGENEHMIGUNGSBEHRDE: return "Baugenehmigungsbehörden";
      default: return "Immobilien Nutzer*innen";
    }

    }

}
