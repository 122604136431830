import {Component, Inject, OnInit} from '@angular/core';
import {Organisation, OrganisationControllerService, User} from "../../../api";
import {Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-organisation-popup',
  templateUrl: './organisation-popup.component.html',
  styleUrls: ['./organisation-popup.component.scss']
})
export class OrganisationPopupComponent implements OnInit {

  organisation!: Organisation | undefined;
  title!: string;

  organisationChangedEvent: Subject<Organisation> = new Subject<Organisation>();
  clearEvent: Subject<void> = new Subject<void>();


  constructor(@Inject(MAT_DIALOG_DATA) public data: { organisationId: number, title: string }, private organisationControllerService: OrganisationControllerService, private dialogRef: MatDialogRef<OrganisationPopupComponent>) { }

  ngOnInit(): void {
    if(this.data.title){
      this.title = this.data.title;
    }else{
      this.title = "Unternehmenseinstellungen";
    }
    this.organisationControllerService.getOrganisation(this.data.organisationId).subscribe((data) => {
      this.organisation = data;
      this.organisationChangedEvent.next(this.organisation);
    });
  }

  close(){
    this.dialogRef.close();
  }

}
