import {Component, OnInit} from '@angular/core';
import {IFCCheck, IfcControllerService, LOICheckRule} from "../../../../api";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {rule} from "postcss";
import {DefaultService} from "../../../../bim-api";

@Component({
  selector: 'app-loi-check-choose-files-editor',
  templateUrl: './loi-check-editor.component.html',
  styleUrls: ['./loi-check-editor.component.scss']
})
export class LoiCheckEditorComponent implements OnInit {

  rule!: LOICheckRule
  ruleData: any = {name: '', rules: []};

  constructor(public ifcController: IfcControllerService, public stateService: StateConfigService, public router: Router, public activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.paramMap.get('id');

    this.ifcController.getRule(id!, this.stateService.selectedProject.id!).subscribe(rule => {
      this.rule = rule;
      this.ruleData = rule.loiRule ? JSON.parse(rule.loiRule) : {name: '', rules: []};
    });
  }

  save(rule: any) {
    let loiRule: LOICheckRule = {
      name: rule.name,
      loiRule: JSON.stringify(rule)
    }

    loiRule = {...this.rule, ...loiRule}

    if (loiRule.id) {
      this.ifcController.updateRule(loiRule, this.stateService.getProjectId()).subscribe((res) => {
        this.router.navigate(['projects', this.stateService.getProjectId(), 'loi-check'])

      })
    } else {

      this.ifcController.createRule(loiRule, this.stateService.getProjectId()).subscribe((res) => {
        this.router.navigate(['projects', this.stateService.getProjectId(), 'loi-check'])
      })
    }

  }

  protected readonly JSON = JSON;
}
