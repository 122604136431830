import {Component, ElementRef, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {StateConfigService} from "../../../services/state/state-config.service";
import {ChatService} from "../service/chat.service";
import {faCheck, faEllipsisVertical, faFile, faPenToSquare, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {Tooltip} from "flowbite";
import {faXmark} from "@fortawesome/pro-solid-svg-icons";
import {TemplatePortal} from "@angular/cdk/portal";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";

@Component({
  selector: 'app-all-chats-view',
  templateUrl: './all-chats-view.component.html',
  styleUrls: ['./all-chats-view.component.scss']
})
export class AllChatsViewComponent {
  hovering: boolean[] = [];

  private overlayRef!: OverlayRef;
  private mulitplier = 0;

  constructor(private router: Router,
              private stateConfigService: StateConfigService,
              protected chatService: ChatService,
              private overlay: Overlay, private viewContainerRef: ViewContainerRef, private elementRef: ElementRef) {
  }

  getChatList() {
    return this.chatService.threads
  }

  isNewDate(index: number): boolean {
    if (index === 0) return true;
    if (index > this.chatService.threads.length - 1) return true;
    const currentDate = this.chatService.threads[index].createdOn.toISOString().split('T')[0];
    const previousDate = this.chatService.threads[index - 1].createdOn.toISOString().split('T')[0];
    return currentDate !== previousDate;
  }

  getCurrentChat() {
    return this.chatService.currentChat
  }

  selectChat(chat: { id: string, topic: string, createdOn: Date }) {
    this.chatService.updateCurrentChat(chat)

    this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'chat', chat.id]).then(() => {
      this.chatService.getChatMessages(chat.id);
    })
  }

  deleteChat(chat: { id: string, topic: string, createdOn: Date }) {
    console.log('delete',chat)
    /*this.chatService.deleteChat(chat.id).then(() => {
      if (this.getCurrentChat()?.id == chat.id) {
        this.chatService.updateCurrentChat(undefined)
        this.chatService.currentChatMessages = []
        this.chatService.allFiles = []
        this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'chat'])
      }
    })*/
  }

  showTooltip(targetElId: string, triggerElId:string) {
    const targetEl = document.getElementById(targetElId);
    const triggerEl = document.getElementById(triggerElId);
    if (targetEl) {
      const tooltip =  new Tooltip(targetEl, triggerEl, {triggerType: 'hover', placement:'top'}, {id: targetElId, override: true});
      tooltip.show()
    }
  }

  onIconClick(event: Event, buttonId: string, dropdown: any) {
    const buttonElement = document.getElementById(buttonId) as HTMLElement;
    if (buttonElement) {
      dropdown.openDropdown(event, buttonElement, 'below');
    } else {
      console.error(`Element with ID ${buttonId} not found.`);
    }
  }

  openDropdown(event: Event, origin: HTMLElement, dropdown:any, direction: 'above' | 'below' = 'below') {
    event.stopPropagation();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions([
        {
          originX: 'start',
          originY: direction === 'below' ? 'bottom' : 'top',
          overlayX: 'start',
          overlayY: direction === 'below' ? 'top' : 'bottom',
        }
      ]);

    this.overlayRef = this.overlay.create({positionStrategy});
    const portal = new TemplatePortal(dropdown, this.viewContainerRef);
    this.overlayRef.attach(portal);
    this.overlayRef.overlayElement.style.visibility = 'hidden';

    setTimeout(() => {
      const overlayWidth = this.overlayRef.overlayElement.offsetWidth;
      const buttonWidth = origin.offsetWidth;
      const updatedPositionStrategy = this.overlay.position()
        .flexibleConnectedTo(origin)
        .withPositions([
          {
            originX: 'start',
            originY: direction === 'below' ? 'bottom' : 'top',
            overlayX: 'start',
            overlayY: direction === 'below' ? 'top' : 'bottom',
            offsetX: (-overlayWidth + buttonWidth) * this.mulitplier
          }
        ]);
      this.overlayRef.updatePositionStrategy(updatedPositionStrategy);
      this.overlayRef.overlayElement.style.visibility = 'visible';
    });
  }

  protected readonly faTrash = faTrash;
  protected readonly faFile = faFile;
  protected readonly faFolderOpen = faFolderOpen;

  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faPlus = faPlus;
  protected readonly faPenToSquare = faPenToSquare;
  renameChat: boolean[]=[];
  protected readonly faXmark = faXmark;
  protected readonly faCheck = faCheck;
  topicInputIsFocused: boolean = false;
}
