import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {faChevronDown, faChevronRight, faClipboardList} from "@fortawesome/free-solid-svg-icons";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {SWMCheck, RuleResult, SwmControllerService, Chapter, K2Rule, K1Rule, Task} from "../../../../api";
import {CircleState} from "../../../util/design/circle-state/circle-state.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TaskDetailsService} from "../../../../services/task-details.service";
import {faCircleQuestion, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {SWMFolderEnum} from "../files/files.component";

@Component({
  selector: 'app-fireprotection-report',
  templateUrl: './fireprotection-report.component.html',
  styleUrls: ['./fireprotection-report.component.scss'],
    animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0', minHeight: '0' })),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class FireprotectionReportComponent  implements OnInit {

  swmCheck: SWMCheck | undefined;
  displayedColumns = ['actions', 'name', 'description', 'type', 'task']
  displayedColumnsDetail: string[] = ['id', 'k1'];
  dataSource: MatTableDataSource<RuleResult> = new MatTableDataSource<RuleResult>();
  expandedElement!: RuleResult | undefined;
  fileId = 'leer';
  fileResult: RuleResult[] = [];
  resultMap = new Map();
  k2Map = new Map();
  expandedMap = new Map<K1Rule, boolean>
  hoveredChapter : Chapter | null = null



  constructor(private swmControllerService: SwmControllerService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private taskDetailService: TaskDetailsService,
              private  swmController: SwmControllerService) {
  }

  ngOnInit(): void {
    this.swmCheck = this.activatedRoute.parent?.snapshot.data.swmCheck;
    this.fileId = this.activatedRoute.snapshot.params.fileId;
    let fileResult = this.swmCheck!.checkResult!.find(result => result.file_id === this.fileId)!.result!;
    this.fileResult = fileResult;
    this.dataSource = new MatTableDataSource(fileResult);
    this.resultMap = this.calculateResponse(fileResult);
    this.k2Map = this.calculateAllK2Rules(fileResult);


  }

  createTask(ruleResult: RuleResult){
    let task = {
      markup: {
        topic: {
          title: 'Überarbeiten des BSKs im Bezug auf: ' + ruleResult.description,
        }
      }
    }

    this.taskDetailService.setOpenedTask(task as Task);

  }

  calculateResult(ruleResult: RuleResult){
    let numberOfFailedK1Rules =  ruleResult.k1Rules!.filter(rule => rule.result?.length == 0 ).length;
    if (numberOfFailedK1Rules == 0){
      let numberOfFailedK2Rules = ruleResult.k1Rules!.filter(rule => rule.k2Rules!.filter(k2Rule => k2Rule.result?.length == 0).length > 0).length;
      if (numberOfFailedK2Rules == 0) {
        return "success"
      }else{
        return "warning"
      }
    }else if (numberOfFailedK1Rules == ruleResult.k1Rules!.length) {
      return "error"
    }else{
      return "warning"
    }

  }

   get completed() {
    return (100 / this.total) * this.dataSource.data.filter(result => this.calculateResult(result) === 'success').length
  }

  get warning() {
    return (100 / this.total) * this.dataSource.data.filter(result => this.calculateResult(result) === 'warning').length
  }

  get error() {
    return (100 / this.total) * this.dataSource.data.filter(result => this.calculateResult(result) === 'error').length
  }

    get total() {
    return this.dataSource.data.length
  }

  get fixedCheckDate(): Date{
    let date = new Date(this.swmCheck!.checkDate!);
    date.setHours(date.getHours() + 2);
    return date;
  }

  calculateResponse(ruleResults: RuleResult[]){
    let rulesMap = new Map<string, Map<K1Rule, Map<Chapter, K2Rule[]>>>();
    ruleResults.forEach(ruleResult => {
      let k1Map = new Map<K1Rule, Map<Chapter, K2Rule[]>>();
      ruleResult.k1Rules!.forEach(k1Rule => {
        let chapterMap = new Map<Chapter, K2Rule[]>();
        k1Rule.result!.forEach(chapter => {
        let filteredK2Rules = k1Rule.k2Rules!.filter(k2Rule =>
          k2Rule.result!.find(result => result.chapter_number === chapter.chapter_number) != undefined
        );

        chapterMap.set(chapter, filteredK2Rules);
      });

      // Umwandeln der Map in ein Array und Sortieren nach der Länge der K2Rule-Listen
      let sortedChapterEntries = Array.from(chapterMap.entries()).sort((a, b) => {
        return b[1].length - a[1].length;
      });

      // Erstellen einer neuen Map aus dem sortierten Array
      let sortedChapterMap = new Map<Chapter, K2Rule[]>(sortedChapterEntries);

      // Setzen der sortierten Map in die k1Map
        this.expandedMap.set(k1Rule, false)
      setTimeout(() => {
      k1Map.set(k1Rule, sortedChapterMap);
    }, 0);

      })
      rulesMap.set(ruleResult.id!, k1Map)
    })
    return rulesMap;
  }

  calculateAllK2Rules(ruleResults: RuleResult[]){
    let k2Map = new Map<string, Map<K1Rule, K2Rule[]>>();
    ruleResults.forEach(ruleResult => {
      let k1Map = new Map<K1Rule, K2Rule[]>();
      ruleResult.k1Rules!.forEach(k1Rule => {
        let k2Rules = k1Rule.k2Rules!
        k2Rules.sort((a, b) => {
              const aLength = a.result ? a.result.length : 0;
              const bLength = b.result ? b.result.length : 0;
              return bLength - aLength;
          });
        k1Map.set(k1Rule, k2Rules)
      })
      k2Map.set(ruleResult.id!, k1Map)
    })
    return k2Map;

  }

  noFullfilledK2s(k2rule: K2Rule, element: RuleResult, k1rule: K1Rule, chapter: Chapter){
    return !this.resultMap.get(element.id).get(k1rule).get(chapter).find((obj: { id: string | undefined; })  => obj.id === k2rule.id)
  }

  isThereAChapterWithoutK2( element: RuleResult, k1rule: K1Rule){
    for(let x in this.resultMap.get(element.id).get(k1rule)){
      if(x.length > 0)
        return false;
    }
    return this.k2Map.get(element.id).get(k1rule).length > 0;
  }

  test(chapter: any){
    // console.log(chapter)
    return "test"
  }

  transformK1Result(k1result: K1Rule) : Chapter[]{
    let chapterOccurrences = new Map<Chapter,number>

    k1result.result?.forEach(chpt => {
      chapterOccurrences.set(chpt, 0)
    })

    k1result.k2Rules?.forEach(k2 => {
    k2.result?.forEach(chpt => {
      if (chapterOccurrences.has(chpt)) {
        chapterOccurrences.set(chpt, (chapterOccurrences.get(chpt) ?? 0) + 1);
      } else {
        chapterOccurrences.set(chpt, 1);
      }
    });
  });

  // Konvertiere die Map in ein Array von Einträgen und sortiere es nach den Vorkommen
  let sortedChapters = Array.from(chapterOccurrences.entries())
    .sort((a, b) => b[1] - a[1])
    .map(entry => entry[0]);

    chapterOccurrences = new Map<Chapter,number>

  return sortedChapters;

  }

  switchExpand(k1rule: K1Rule){
    if(this.expandedMap.get(k1rule)){
      this.expandedMap.set(k1rule, false)
    }else{
      this.expandedMap.set(k1rule, true)
    }
  }

  getInfoText(chapter: Chapter){
    if(chapter.level == 1){
      return "Identifikation durch die Überschrift des Kapitels"
    }else if(chapter.level == 3){
      return "Identifikation durch die Überschrift und den Inhalt des Kapitels"
    }else if(chapter.level == 4){
      return "Identifikation durch den Inhalt des Kapitels"
    }
    else return ""
  }

  downloadReport(){
    this.swmController.getDownloadLink(this.swmCheck!.id,  this.fileId).subscribe(res  => {
      const a = document.createElement('a');
      a.href = res[0]
      a.target = '_blank';
      a.click();
    })
  }




  protected readonly faChevronDown = faChevronDown;
  protected readonly faClipboardList = faClipboardList;
  protected readonly CircleState = CircleState;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faFolderOpen = faFolderOpen;
  protected readonly faCircleQuestion = faCircleQuestion;
}
