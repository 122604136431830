import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {int} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";

interface  ErrorEvent {
  status: number;
  message: string;
  url: string;
}

/**
 * Service for HTTP Error Handling
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  /**
   * Subject of Error Responses
   * @private
   */
  private errorEvent: Subject<ErrorEvent> = new Subject();

  /**
   * ErrorEvents as Observable
   */
  public errorEvent$ = this.errorEvent.asObservable();

  /**
   * Constructor
   */
  constructor() {
  }

  /**
   * Triggers new error
   * @param error new HTTP Error Response
   */
  public triggerError(error: HttpErrorResponse): void {
    this.errorEvent.next({status: error.status, url: error.url!, message: error.message});
  }
}
