import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bic-tag',
  templateUrl: './bic-tag.component.html',
  styleUrls: ['./bic-tag.component.scss']
})
export class BicTagComponent implements OnInit {

  @Input()
  class_css: string = "bg-primary rounded-md text-white font-medium px-4";

  constructor() { }

  ngOnInit(): void {
  }

}
