import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CompletenessCheck, QualityCheck} from "../../../api";

@Component({
  selector: 'app-hoai-stepper',
  templateUrl: './hoai-stepper.component.html',
  styleUrls: ['./hoai-stepper.component.scss']
})
export class HoaiStepperComponent implements OnInit {
  selectedStep = 0;

  completedSteps: number[] = [0];

  @Input() isDocView: boolean = false;
  @Input() isDetail: boolean = false;

  hoaiCheck!: CompletenessCheck;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.hoaiCheck = this.activatedRoute.snapshot.parent!.data.hoaiCheck;

    if(this.hoaiCheck) {
      this.completedSteps.push(0);

      if(this.hoaiCheck.completed) {
        this.selectedStep = 1;
        this.completedSteps = [0, 1, 2, 3];
      }
    }


    const url = this.activatedRoute.snapshot.pathFromRoot
      .map(v => v.url)
      .filter(v => v.length > 0)
      .slice(2)
      .flat()

    if (url.length == 1 || url.at(-1)?.path == 'hoai') {
      this.selectedStep = 0;
    }

    if (url.at(-1)?.path == 'report') {
      this.selectedStep = 1;
    }
  }

  changeStep(index: number): void {
    const projectId = this.activatedRoute.snapshot.parent!.parent!.params.id;
    const checkId = this.hoaiCheck.id;

    const fileId = this.activatedRoute.snapshot.params.fileId;
    const ruleId = this.activatedRoute.snapshot.params.ruleId;
  }


}
