import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-files-wrong-format-dialog',
  templateUrl: './files-upload-error-dialog.component.html',
  styleUrls: ['./files-upload-error-dialog.component.scss']
})
export class FilesUploadErrorDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {incorrectFileType: boolean, forbiddenChar: boolean }, public dialogRef: MatDialogRef<FilesUploadErrorDialogComponent>) { }

  incorrectFileType: boolean = false;
  forbiddenChar: boolean = false;

  ngOnInit(): void {
    this.incorrectFileType = this.data.incorrectFileType;
    this.forbiddenChar = this.data.forbiddenChar;
  }

}
