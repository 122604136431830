/**
 * bic-bim-api
 * Import from bic-bim-api Function App
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CheckLoiRules } from './checkLoiRules';

export interface CheckLoiBody { 
    file?: string;
    rules?: Array<CheckLoiRules>;
}