import {Component, Injectable} from '@angular/core';
import {ComponentType} from "@angular/cdk/portal";
import {NavigationEnd, Router} from "@angular/router";

/**
 * Service showing content in the drawer component of the app.
 */
@Injectable({
  providedIn: 'root'
})
export class DrawerContentService {

  opened: boolean = false;
  large: boolean = false;
  component: ComponentType<any> | null = null;

  config: DrawerContentConfig<any> | null = null;
  constructor(private router: Router) {
    router.events.subscribe(
      (val) => {
        if(val instanceof NavigationEnd) {
          this.close();
        }
      }
    )
  }

  open<T, D = any, R = any>(component: ComponentType<T>, config?: DrawerContentConfig<D>) {
    setTimeout(() => {
      this.opened = true;
      this.component = component;

      if (config) {
        this.config = config;
        this.large = config.large;
      }
    }, 10);
  }

  close() {
    this.config?.onClose?.();
    this.opened = false;
    this.component = null;
    this.config = null;
    this.large = false;
  }

  toggleSize() {
    this.large = !this.large;
  }
}

class DrawerContentConfig<T> {
  data: any;
  large: boolean = false;
  title: string = "";
  fixedSize?: boolean = false;

  saveAction?: () => void;
  deleteAction?: () => void;
  onClose?: () => void;
}

