<div class="flex flex-row">

  <div class="flex flex-col justify-between items-stretch shadow-md w-full">

    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-col h-full justify-center items-center hover:bg-gray-200 rounded-r-md">
        <button cdkStepperPrevious class="p-2 pt-3">
          <fa-icon class="text-secondary" [icon]="faChevronLeft"></fa-icon>
        </button>
      </div>

      <div class="flex flex-row justify-center items-center w-full">

        <button (click)="onClick(i)"
                *ngFor="let step of steps; let i = index;" class="w-full p-[1px] rounded-md" [class]=" (step.completed || selectedIndex === i || steps.get(i-1)?.completed) && step.editable ? 'hover:bg-gray-200' : 'cursor-default'">
          <div class="w-full justify-center text-center text-secondary font-medium" [ngClass]="{
               'opacity-50': selectedIndex !== i && !step.completed,
                }">
            {{ i + 1 }}. {{ step.label }}
          </div>

          <div class="flex flex-col w-full h-3 bg-secondary rounded-full mt-0.5 mb-6" [ngClass]="{
               'opacity-50': selectedIndex === i,
               'opacity-100 ': selectedIndex !== i && step.completed,
               'opacity-10': selectedIndex !== i && !step.completed
             }" >
          </div>
        </button>

      </div>

      <div class="flex flex-col h-full justify-center items-center hover:bg-gray-200 rounded-l-md">
        <button cdkStepperNext class="p-2 pt-3">
          <fa-icon class="text-secondary" [icon]="faChevronRight"></fa-icon>
        </button>
      </div>
    </div>
  </div>

</div>
