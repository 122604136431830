import { Component } from '@angular/core';
import {FileControllerService, ProjectFile} from "../../../api";
import {SelectionModel} from "@angular/cdk/collections";
import {FileTreeNode} from "../../util/files/files.component";
import {ActivatedRoute, Router} from "@angular/router";
import {StateConfigService} from "../../../services/state/state-config.service";
import {faFolder, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {DefaultService} from "../../../hasselmann-mueller-api";
import {Body} from "../../../hasselmann-mueller-api";

@Component({
  selector: 'app-plan-choose-files',
  templateUrl: './plan-choose-files.component.html',
  styleUrls: ['./plan-choose-files.component.scss']
})
export class PlanChooseFilesComponent {

  filteredPV: ProjectFile[] = [];
  filteredPL: ProjectFile[] = [];
  selectedFolder: string = '';
  runningDocumentClassification: string[] = [];
  selectedPVFiles: SelectionModel<FileTreeNode> = new SelectionModel<FileTreeNode>(true, []);
  selectedPLFiles: SelectionModel<FileTreeNode> = new SelectionModel<FileTreeNode>(true, []);

  constructor(private fileControllerService: FileControllerService,
              private activatedRoute: ActivatedRoute,
              public stateConfigService: StateConfigService,
              private router: Router, private planControllerService: DefaultService) {

  }

  ngOnInit() {
    this.loadFiles();
    this.stateConfigService.runningClassificationsChange.subscribe((runningClassifications) => {
      this.runningDocumentClassification = runningClassifications;
      this.loadFiles();
    });

  }


  get projectId(): number {
    return this.stateConfigService.getProjectId();
  }

  navigateToChoosingRules() {
    const requestBody: Body = {
        tables: this.selectedPVFiles.selected.map(file => file.link!),
        plans: this.selectedPLFiles.selected.map(file => file.ocrExtraction!)
      }

    this.planControllerService.analyze(requestBody).subscribe((response) => {
      this.router.navigate(['projects', this.projectId, 'plan', 'report'], {queryParams:{url:response.url}});
    });
  }

  onFolderClick(folder: string) {
    this.selectedFolder = folder;
  }

  get shownFiles(): ProjectFile[] {
    switch (this.selectedFolder) {
      case 'PV':
        return this.filteredPV;
      case 'PL':
        return this.filteredPL;
      default:
        return [];
    }
  }

  get shownSelectedFiles(): SelectionModel<FileTreeNode> {
    switch (this.selectedFolder) {
      case 'PV':
        return this.selectedPVFiles;
      case 'PL':
        return this.selectedPLFiles;
      default:
        return new SelectionModel<FileTreeNode>(true, []);
    }
  }

  protected readonly faChevronLeft = faChevronLeft;

  getSelectedPVFiles(): { name: string, size: string }[] {
    return this.selectedPVFiles.selected.map(node => ({name: node.name || '', size: this.calculateSize(node.size!)}));
  }

  getSelectedPLFiles(): { name: string, size: string }[] {
    return this.selectedPLFiles.selected.map(node => ({name: node.name || '', size: this.calculateSize(node.size!)}));
  }

  loadFiles(): void {
    this.fileControllerService.getFiles(this.stateConfigService.getProjectId()).subscribe(files => {
      this.filteredPV = files.filter(file => file.name?.toLocaleLowerCase().endsWith('.pdf') && !file.uploadFailed);
      this.filteredPL = files.filter(file => file.name?.toLocaleLowerCase().endsWith('.pdf') && !file.uploadFailed);

      /*if (this.check) {
        this.selectedPVFiles = new SelectionModel<FileTreeNode>(true, this.filteredPV.filter(file => this.check!.projectFiles!['PV'].map(file => file.id).includes(file.id)).map(file => file as FileTreeNode));
        this.selectedLVFiles = new SelectionModel<FileTreeNode>(true, this.filteredLV.filter(file => this.check!.projectFiles!['LV'].map(file => file.id).includes(file.id)).map(file => file as FileTreeNode));
        this.selectedRBFiles = new SelectionModel<FileTreeNode>(true, this.filteredRB.filter(file => this.check!.projectFiles!['ROOM_BOOK'].map(file => file.id).includes(file.id)).map(file => file as FileTreeNode));
      }*/
    });
  }

  protected readonly faChevronRight = faChevronRight;
  protected readonly faFolder = faFolder;
  protected readonly faFolderOpen = faFolderOpen;

  private calculateSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size < kb) {
      return `${size} Bytes`;
    } else if (size < mb) {
      return `${(size / kb).toFixed(2)} KB`;
    } else if (size < gb) {
      return `${(size / mb).toFixed(2)} MB`;
    } else {
      return `${(size / gb).toFixed(2)} GB`;
    }

  }


}

