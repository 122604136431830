import {Component, OnInit, ViewChild, OnDestroy, AfterViewInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Task, TaskControllerService} from "../../api";
import {filter} from "rxjs/operators";
import {animate, style, transition, trigger} from "@angular/animations";
import {ToolbarService} from "../util/design/toolbar/toolbar.service";
import {TaskListComponent} from "./task-list/task-list.component";
import {DrawerContentService} from "../../services/drawer-content.service";
import {TaskDetailComponent} from "./task-detail/task-detail.component";
import {Subscription} from 'rxjs';
import {DefaultService} from "../../bim-api";
import {StateConfigService} from "../../services/state/state-config.service";
import {TaskBoardComponent} from "./task-board/task-board.component";
import {faArrowUpFromBracket, faFilter} from "@fortawesome/free-solid-svg-icons";
import { initFlowbite } from 'flowbite';
import {faIfc} from "@awesome.me/kit-6ed88ea8d1/icons/modules/kit/custom";
import {faArrowDownFromBracket, faArrowDownToBracket} from "@fortawesome/pro-solid-svg-icons";


/*interface navLink {
  label: string,
  link: string,
  index: number
}*/

enum ViewType {
  LIST = 0,
  BOARD = 1
}

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateX(50%)'}),
        animate('500ms ease-in', style({opacity: 1, transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        style({opacity: 1, transform: 'translateX(0%)'}),
        animate('500ms ease-in', style({opacity: 0, transform: 'translateX(50%)'}))
      ])
    ]),
  ]
})
export class TasksComponent implements OnInit, AfterViewInit, OnDestroy {

  subscription: Subscription = new Subscription();
  viewType: typeof ViewType = ViewType;
  //activeLinkIndex = ViewType.LIST;
  activeLinkIndex = ViewType.BOARD;

  selectedTaskId!: string | undefined

  @ViewChild('applist') applist: TaskListComponent | undefined;
  @ViewChild('board') board: TaskBoardComponent | undefined;

  keysToDelete = [
    "markup.id",
    "markup.topic.creationUser",
    "markup.topic.modifiedUser",
    "markup.topic.assignedToUser",
    "markup.comment.authorUser",
    "markup.comment.modifiedAuthorfUser",
    //"markup.comment.viewpoint.viewpoint",
    "markup.comment.viewpoint.snapshot",
    "markup.comment.viewpoint.snapshotLink",
    "markup.comment.viewpoint.index",
    "markup.viewpoints.snapshotLink",
    "visualizationInfo.components.id",
    "visualizationInfo.components.viewSetupHints.id",
    "visualizationInfo.components.visibility.id",
    "visualizationInfo.components.exceptions.id",
    "visualizationInfo.components.exceptions.component.id",
    "visualizationInfo.perspectiveCamera.id",
    "visualizationInfo.perspectiveCamera.cameraViewPoint.id",
    "visualizationInfo.perspectiveCamera.cameraDirection.id",
    "visualizationInfo.perspectiveCamera.cameraUpVector.id",
    "visualizationInfo.clippingPlanes.id",
    "visualizationInfo.clippingPlanes.clippingPlane.id",
    "visualizationInfo.clippingPlanes.clippingPlane.location.id",
    "visualizationInfo.clippingPlanes.clippingPlane.direction.id"
  ];

  constructor(public activatedRoute: ActivatedRoute,
              public drawer: DrawerContentService,
              public taskControllerService: TaskControllerService,
              public stateConfigService: StateConfigService,
              public bimApi: DefaultService,
              private router: Router, private toolbar: ToolbarService) {
  }

  ngAfterViewInit(): void {
        initFlowbite();
    }

  ngOnInit(): void {
    this.toolbar.config = {
      add: {
        disabled: () => false,
        action: () => this.selectedTaskId = undefined
      }
    }
    this.selectedTaskId = this.activatedRoute.snapshot.firstChild?.params.id ?? undefined
    //this.activeLinkIndex = this.activatedRoute.snapshot.firstChild?.data.tab ?? 0

    if (this.selectedTaskId != undefined) {
      this.drawer.open(TaskDetailComponent, {
        title: '',

        large: false,
        fixedSize: true,
        data: this.selectedTaskId
      })
    }

    this.subscription = this.router.events
      .pipe(
        filter(value => value instanceof NavigationEnd),
      )
      .subscribe(event => {

        this.selectedTaskId = this.activatedRoute.snapshot.firstChild?.params.id ?? undefined
        if (this.selectedTaskId == undefined) {
          this.applist?.refreshList();
        }
        this.activeLinkIndex = this.activatedRoute.snapshot.firstChild?.data.tab ?? 0

        if (this.selectedTaskId != undefined) {
          this.drawer.open(TaskDetailComponent, {
            title: '',
            large: false,
            fixedSize: true,
            data: this.selectedTaskId
          })
        }
      });
  }

  importBCF(event: any) {
    let files: File[] = event.target.files;


    let file = new File([files[0]], files[0].name, {type: "application/octet-stream"});

    this.bimApi.bcf2jsonForm(file).subscribe((data) => {
      data.forEach((task: Task) => {
        task.isImported = true;
        this.taskControllerService.updateTask(task, '', this.stateConfigService.getProjectId() ?? -1).subscribe((task: Task) => {
          this.applist?.refreshList();
          this.board?.refreshList();
        });
      })
    });
  }

  safeDelete(obj: any, key: string): void {
        const keyPath = key.split(".");
        let currentObj = obj;
        for (let index = 0; index < keyPath.length ; index++) {
          if (currentObj && keyPath[index] in currentObj) {
            if (keyPath.indexOf(keyPath[index]) === keyPath.length - 1) {
              delete currentObj[keyPath[index]];
            } else {
              currentObj = currentObj[keyPath[index]];
            }
          } else if (Array.isArray(currentObj)) {
            currentObj.forEach(obj => {
              if (obj && keyPath[index] in obj) {
                if (keyPath.indexOf(keyPath[index]) === keyPath.length - 1) {
                  delete obj[keyPath[index]];
                } else {
                  this.safeDelete(obj, keyPath.slice(index).join('.'))
                }
              } else {
                this.safeDelete(obj, keyPath.slice(index).join('.'))
              }
            })
          } else {
            break;
          }
        }
  }

  removeViewpointFromComment(task:any){
    task["markup"]["comment"].forEach((comment:any)=>{
      if(comment["viewpoint"]) {
        delete comment["viewpoint"]["viewpoint"]
      }
    })
  }

  addBICInformation(task:Task){
    if(task.lph){
      task.markup?.topic?.description?.concat(" LPH: " + task.lph)
    }
    if (task.priority){
      task.markup?.topic?.description?.concat(" Priority: " + task.priority)
    }
    if (task.state){
      task.markup?.topic?.description?.concat(" State: " + task.state)
    }
    if (task.projectID){
      task.markup?.topic?.description?.concat(" Project: " + task.projectID)
    }
  }

  exportBCF(task:Task) {
    let jsonTask = JSON.parse(JSON.stringify(task))
    this.addBICInformation(jsonTask)
    this.keysToDelete.forEach(keys=>{
      this.safeDelete(jsonTask, keys)
    })
    this.removeViewpointFromComment(jsonTask)

    this.bimApi.json2bcf([jsonTask]).subscribe((data) => {
      window.open(data.url, '_blank');
    });
  }

  exportBCFs(tasks:Task[]) {
    const convertedTasks: Task[] = []
    tasks.forEach(task => {
      let jsonTask = JSON.parse(JSON.stringify(task))
      this.addBICInformation(jsonTask)
      this.keysToDelete.forEach(keys=>{
        this.safeDelete(jsonTask, keys)
      })

      this.removeViewpointFromComment(jsonTask)
      convertedTasks.push(jsonTask)
    })

    this.bimApi.json2bcf(convertedTasks).subscribe((data) => {
      // @ts-ignore
      data.url.forEach(url =>{
        window.open(url, '_blank');
      })
    });
  }

  changeViewType(viewType:number) {
    this.activeLinkIndex = viewType;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  protected readonly console = console;
  protected readonly faFilter = faFilter;
  protected readonly faArrowUpFromBracket = faArrowUpFromBracket;
  protected readonly faIfc = faIfc;
  protected readonly faArrowDownFromBracket = faArrowDownFromBracket;
  protected readonly faArrowDownToBracket = faArrowDownToBracket;
}
