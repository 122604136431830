import {Component, EventEmitter, Injectable} from '@angular/core';
import {ComponentPortal, ComponentType} from "@angular/cdk/portal";
import {NavigationEnd, Router} from "@angular/router";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {TaskDetailComponent} from "../components/tasks/task-detail/task-detail.component";
import {BehaviorSubject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {TaskControllerService} from "../api";
import {Task} from "../api";


@Injectable({
  providedIn: 'root'
})
export class TaskDetailsService {

  private taskSource = new BehaviorSubject<Task | undefined>(undefined);
  currentTask = this.taskSource.asObservable();

  currentTaskChanged = new EventEmitter<Task>();
  currentTaskDeleted = new EventEmitter<Task>();



  constructor(private router: Router, private taskControllerService: TaskControllerService, public overlay: Overlay, private dialog: MatDialog ) {
  }

  setOpenedTask(task: Task | undefined){
    this.taskSource.next(task);
    this.openDialog();
  }

  getOpenedTask(){
    return this.taskSource.getValue();
  }

  clearOpenedTask(){
    this.taskSource.next(undefined);
  }

  closeAndSaveTask(task: Task){
    this.taskSource.next(task);
  }

  openDialog() {
    let ref = this.dialog.open(TaskDetailComponent, {
      panelClass:"rounded-corners-dialog",
      data: this.getOpenedTask(),
      width: "50%"
    }
  );

  }
}
