import { Component } from '@angular/core';
import {StateConfigService} from "../../../services/state/state-config.service";
import {ChatService} from "../service/chat.service";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import {faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {PopoutContentService} from "../../../services/popout/popout.service";
import {FileControllerService} from "../../../api";

@Component({
  selector: 'app-all-files-view',
  templateUrl: './all-files-view.component.html',
  styleUrls: ['./all-files-view.component.scss']
})
export class AllFilesViewComponent {

  constructor(protected chatService: ChatService,
              private popOutService: PopoutContentService,
              private stateConfigService: StateConfigService,
              private fileControllerService: FileControllerService,) {
  }

  get project() {
    return this.stateConfigService.getProject()
  }

  openSource(source: {dataId: string, fileName: string }) {
    const id = source.dataId
    const url = "projects/" + this.project?.id + "/files/" + id
    this.popOutService.open(url, source.fileName, true)
  }

  openFolder(source: {dataId: string, fileName: string}){
    this.fileControllerService.getFile(source.dataId!).subscribe(file => {
      const url = "projects/"+this.project?.id+"/files?lph="+file.lph+"&folder="+file.folder
      this.popOutService.open(url, source.fileName, true)
    })
  }

  protected readonly faFile = faFile;
  protected readonly faFolderOpen = faFolderOpen;
}
