import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleAndPrivileges'
})
export class RoleAndPrivilegesPipe implements PipeTransform {
  transform(value:  undefined | string): string {
    switch (value) {
/*      case "ADD_PAY_PER_USE" : return "Add Pay Per Use";
      case "ADD_PROJECT" : return "Add Project";
      case "ADD_TASKS" : return "Add Tasks";
      case "ADD_USERS" : return "Add User";
      case "CHECK_PROJECT" : return "Check Project";
      case "DOWNLOAD_FILES" : return "Download Files";
      case "DOWNLOAD_REPORT" : return "Download Report";
      case "EDIT_ABONNEMENT" : return "Edit Abonnement";
      case "EDIT_CHECK_SETTINGS" : return "Edit Check Settings";
      case "EDIT_ORGANISATION" : return "Edit Organisation";
      case "EDIT_PROJECT" : return "Edit Project";
      case "EDIT_PROJECT_SETTINGS" : return "Edit Project Settings";
      case "EDIT_TASKS" : return "Edit Tasks";
      case "EDIT_USERS_PERMISSIONS" : return "Edit Users Permissions";*/
      case "ORGANISATION_PROJECT_DELETE" : return "Projekte löschen";
      case "ORGANISATION_PROJECT_EDIT" : return "Projekte bearbeiten";
      case "ORGANISATION_SETTINGS" : return "Organisationseinstellungen bearbeiten";
      case "ORGANISATION_SHOP" : return "Kaufrechte";
      case "ORGANISATION_USER_MANAGEMENT" : return "Mitglieder verwalten";
      case "PROJECT_BIM_TOOLS_CHECK" : return "BIM-Tools Prüfrechte";
      case "PROJECT_FILE_DELETE" : return "Dateien löschen";
      case "PROJECT_FILE_EDIT" : return "Dateien hochladen und verschieben";
      case "PROJECT_HOAI_CHECK" : return "HOAI-Check Prüfrechte";
      case "PROJECT_QUALITY_CHECK" : return "Qualitätscheck Prüfrechte";
      case "PROJECT_SETTINGS" : return "Projekteinstellungen bearbeiten";
      case "PROJECT_SHOP" : return "Kaufrechte";
      case "PROJECT_TASK_DELETE" : return "Tasks löschen";
      case "PROJECT_TASK_EDIT" : return "Tasks verwalten";
      case "PROJECT_USER_MANAGEMENT" : return "Mitglieder verwalten";
/*      case "READ_INVOICES" : return "Read Invoices";
      case "REMOVE_FILES" : return "Remove Files";
      case "REMOVE_PROJECT" : return "Remove Project";
      case "REMOVE_TASKS" : return "Remove Tasks";
      case "REMOVE_USERS" : return "Remove Users";
      case "UPLOAD_FILES" : return "Upload Files";
      case "VERIFY_TASKS" : return "Verify Tasks";
      case "VIEW_FILES" : return "View Files";
      case "VIEW_REPORT" : return "View Report";*/

      case "PROJECT_VIEWER": return "Beobachter*in";
      case "PROJECT_EDITOR": return "Bearbeiter*in";
      case "PROJECT_ADMIN": return "Administrator*in";
/*      case "ROLE_PROJECT_USER": return "Role Project User";
      case "ROLE_PROJECT_CHECKER": return "Role Project Checker";
      case "ROLE_GLOBAL_PROJECT_ADMIN": return "Role Global Project Admin";
      case "ROLE_PROJECT_ADMIN": return "Role Project Admin";
      case "ROLE_USERS_ADMIN": return "Role Users Admin";
      case "ROLE_TASK_ADMIN": return "Role Task Admin";*/

      case "ORGANISATION_ADMIN": return "Administrator*in";
      case "ORGANISATION_VIEWER": return "Beobachter*in";
      case "ORGANISATION_EDITOR": return "Bearbeiter*in";

      default:
        return value ?? "";
    }
 }
}


