/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TaskDashboardData { 
    taskName?: string;
    state?: TaskDashboardData.StateEnum;
}
export namespace TaskDashboardData {
    export type StateEnum = 'OPEN' | 'PROCESS' | 'DONE' | 'VERIFIED';
    export const StateEnum = {
        OPEN: 'OPEN' as StateEnum,
        PROCESS: 'PROCESS' as StateEnum,
        DONE: 'DONE' as StateEnum,
        VERIFIED: 'VERIFIED' as StateEnum
    };
}