/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompactServiceAspectEvaluationResult } from './compactServiceAspectEvaluationResult';
import { ComplianceCheckResult } from './complianceCheckResult';
import { ServiceRelationEvaluationResult } from './serviceRelationEvaluationResult';

export interface CompactPartialServiceEvaluationResult { 
    partialServiceName?: string;
    s3KeyOfAnalyzedDocument?: string;
    succeed?: boolean;
    affidavitPossible?: boolean;
    fulfilledByAffidavit?: boolean;
    k0IsFulfilled?: boolean;
    serviceAspectEvaluationResults?: Array<CompactServiceAspectEvaluationResult>;
    serviceRelationEvaluationResults?: Array<ServiceRelationEvaluationResult>;
    complianceCheckResult?: ComplianceCheckResult;
}