import {AfterViewInit, AfterViewChecked , Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {StateConfigService} from "../../../services/state/state-config.service";
import {
  faChevronDown, faCopy,
  faFile,
  faX
} from "@fortawesome/free-solid-svg-icons";
import {PopoutContentService} from "../../../services/popout/popout.service";
import {ChatService} from "../service/chat.service";
import {faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {FileControllerService} from "../../../api";
import {from, switchMap} from "rxjs";
import {initFlowbite, Tooltip} from "flowbite";

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss']
})
export class ChatViewComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('chatViewContainer') private chatViewContainer!: ElementRef;

  constructor(private popOutService: PopoutContentService,
              private stateConfigService: StateConfigService,
              protected chatService: ChatService,
              private fileControllerService: FileControllerService) {
  }
  ngAfterViewChecked () {
    this.scrollToBottom();
  }

  ngOnInit() {
    if (this.chatService.currentChat) {
      this.chatService.getChatMessages(this.chatService.currentChat?.id)
    } else {
      this.chatService.currentChatMessages = []
    }

    this.chatService.messagesScrollDown.subscribe(() => {
      this.scrollToBottom()
      initFlowbite()
    })

  }

  ngAfterViewInit() {
    // Initialize tooltips
    //const tooltips = document.querySelectorAll('[data-tooltip-target]');
    //tooltips.forEach(tooltip => {
    //  new Tooltip(tooltip); // Ensure this matches Flowbite's initialization method
    //});
    //initFlowbite()
  }

  usePrompt(prompt: string) {
    this.chatService.question = prompt
  }

  get project() {
    return this.stateConfigService.getProject()
  }

  getProjectName(projectId: string) {
    let name = ''
    this.chatService.getProjects().forEach(project => {
      if (project.id === projectId) {
        name = project.name
      }
    })
    return name
  }

  scrollToBottom(): void {
    try {
      this.chatViewContainer.nativeElement.scrollTop = this.chatViewContainer.nativeElement.scrollHeight;
    } catch (err) {
    }
  }

  getSourceFiles(message: {
    id: string;
    message: string | undefined;
    createdOn: Date,
    metadata: Record<string, string> | undefined;
    sender: string | undefined;
  }): [] | { dataId: string, fileName: string, page: number }[] {
    const data = message.metadata?.['files']
    if (data) {
      let files = JSON.parse(data)
      files = files.filter((currentElement: any, index: any, array: any) => index === array.findIndex((findTest: any) =>
        findTest.dataId === currentElement.dataId
      ))
      return files
    }
    return []
  }

  getSourceFilesNew(message: any){
    let data = message.files
    if (data) {
      data = data.filter((currentElement: any, index: any, array: any) => index === array.findIndex((findTest: any) =>
        findTest.dataId === currentElement.dataId
      ))
      return data
    }
    return []
  }

  openSource(source: { dataId: string, fileName: string, page: number }) {
    const id = source.dataId
    const url = "projects/" + this.project?.id + "/files/" + id
    this.popOutService.open(url, source.fileName, true)
  }

  openFolder(source: { dataId: string, fileName: string }) {
    this.fileControllerService.getFile(source.dataId!).subscribe(file => {
      const url = "projects/" + this.project?.id + "/files?lph=" + file.lph + "&folder=" + file.folder
      this.popOutService.open(url, source.fileName, true)
    })
  }

  isNewDate(index: number): boolean {
    if (index === 0) return true;
    const currentDate = this.chatService.currentChatMessages[index].createdOn.toISOString().split('T')[0];
    const previousDate = this.chatService.currentChatMessages[index - 1].createdOn.toISOString().split('T')[0];
    return currentDate !== previousDate;
  }

  showTooltip(targetElId: string, triggerElId:string) {
    const targetEl = document.getElementById(targetElId);
    const triggerEl = document.getElementById(triggerElId);
    if (targetEl && triggerEl) {
      const tooltip =  new Tooltip(targetEl, triggerEl, {triggerType: 'hover', placement:'top'}, {id: targetElId, override: true});
      tooltip.show()
    }
  }

  getPrompts(){
    return this.chatService.ifcMessage ? this.chatService.definedIFCPrompts : this.chatService.definedPrompts
  }

  chooseFile(file: any, message:any){
    if(message.metadata && message.metadata['chooseFiles'] && message.metadata['chooseFiles'] == "true"){
      const index = this.chatService.chosenFilesForIFCFilter.findIndex(item => item.dataId === file.dataId);
      if (index === -1) {
        this.chatService.chosenFilesForIFCFilter = [file];
      } else {
        this.chatService.chosenFilesForIFCFilter.splice(index, 1);
      }
    }
  }

  fileIsSelected(file: any){
    return this.chatService.chosenFilesForIFCFilter.some(item=>item.dataId==file.dataId)
  }

  getColumnTypes(columns:string[]){
    return new Array(columns.length).fill('text')
  }

  getColumnAlignments(columns:string[]){
    return new Array(columns.length).fill('start')
  }

  getColumnWidth(columns:string[]){
    let width = this.calculateColumnWidth(columns.length)
    return new Array(columns.length).fill(width.toString()+'%')
  }

  calculateColumnWidth(x: number): number {
    const maxTotalWidth = 100.00;
    const step = 0.05;
    let widthPerItem = maxTotalWidth / x;

    // Round to the nearest 0.05
    widthPerItem = Math.round(widthPerItem / step) * step;

    // Ensure the total width does not exceed 100%
    if (widthPerItem * x > maxTotalWidth) {
      widthPerItem = Math.floor((maxTotalWidth / x) / step) * step;
    }

    return parseFloat(widthPerItem.toFixed(2));
  }

  protected readonly faX = faX;
  protected readonly Array = Array;
  protected readonly faChevronDown = faChevronDown;
  protected readonly faFolderOpen = faFolderOpen;
  protected readonly faFile = faFile;
  protected readonly faCopy = faCopy;
  protected readonly document = document;
  hovering: boolean[] = [];
}
