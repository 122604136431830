import {Injectable} from '@angular/core';
import {
  AuthControllerService, Organisation, Privilege, Project, RoleResponse,
  User, UserFullDto
} from "../../api";
import {ActivatedRoute, Router} from "@angular/router";
import {StateConfigService} from "../state/state-config.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import PrivilegesEnum = RoleResponse.PrivilegesEnum;

@Injectable({
  providedIn: 'root'
})
export class PermissionService {


  constructor(private authControllerService: AuthControllerService,
              private stateService: StateConfigService,
              public snackbar: MatSnackBar, private router: Router) {

  }

   public hasProjectPrivilege(role: RoleResponse.PrivilegesEnum, projectId?: number): boolean {
     if (!projectId) {
       return this.stateService.projects.get(this.stateService.getProjectId())?.privileges?.includes(role) ?? false
     }

     return this.stateService.projects.get(projectId)?.privileges?.includes(role) ?? false;
   }

  public hasOrganisationPrivilege(role: RoleResponse.PrivilegesEnum, organisationId?: number): boolean {
    if (!organisationId) {
      return this.stateService.organisations.get(this.stateService.getOrganisationId())?.privileges?.includes(role) ?? false
    }

    return this.stateService.organisations.get(organisationId)?.privileges?.includes(role) ?? false;
  }
}
