import {Pipe, PipeTransform} from '@angular/core';
import {CheckResults, Project, ProjectServiceNotification} from "../api";
import LphEnum = ProjectServiceNotification.LphEnum;
import PartialServiceNameEnum = CheckResults.PartialServiceNameEnum;
import {StateConfigService} from "../services/state/state-config.service";
import StateEnum = Project.StateEnum;

/**
 * Transforms LPH Enum Value to String
 */
@Pipe({
    name: 'lph'
})
export class LphPipe implements PipeTransform {

    /**
     * Transform Enum Value to String
     * @param value LPphEnum Value
     */
    transform(value: LphEnum | string | undefined): string {
        switch (value) {
            case "LPH1":
                return "1"
            case "LPH2":
                return "2"
            case "LPH3":
                return "3"
            case "LPH4":
                return "4"
            case "LPH5":
                return "5"
            case "LPH6":
                return "6"
            case "LPH7":
                return "7"
            case "LPH8":
                return "8"
            case "LPH9":
                return "9"
            default:
                return ""
        }
    }
}


@Pipe({
    name: 'lphName'
})
export class LphNamePipe implements PipeTransform {
  transform(value: LphEnum | string | undefined): string {
    switch (value) {
      case "LPH1":
        return "Grundlagenermittlung"
      case "LPH2":
        return "Vorplanung"
      case "LPH3":
        return "Entwurfsplanung"
      case "LPH4":
        return "Genehmigungsplanung"
      case "LPH5":
        return "Ausführungsplanung"
      case "LPH6":
        return "Vorbereitung der Vergabe"
      case "LPH7":
        return "Mitwirkung bei der Vergabe"
      case "LPH8":
        return "Objektüberwachung"
      case "LPH9":
        return "Objektbetreuung und Dokumentation"
      default:
        return ""
    }
  }
}

@Pipe({
    name: 'lphService'
})
export class LphServicePipe implements PipeTransform {

    currentState!: StateEnum

    constructor(stateConfigService: StateConfigService) {
        this.currentState = stateConfigService.selectedProject?.state!
    }

    transform(value: number | null | undefined): PartialServiceNameEnum [][] {
        switch (value) {
            case 1:
                return [
                    [
                        PartialServiceNameEnum.GL1A,
                        PartialServiceNameEnum.GL1B,
                        PartialServiceNameEnum.GL1C,
                        PartialServiceNameEnum.GL1D,
                        PartialServiceNameEnum.GL1E
                    ],
                    [
                        PartialServiceNameEnum.BL11,
                        PartialServiceNameEnum.BL13,
                        PartialServiceNameEnum.BL14,
                        PartialServiceNameEnum.BL15,
                        PartialServiceNameEnum.BL17,
                        PartialServiceNameEnum.BL18,
                        PartialServiceNameEnum.BL19,
                        PartialServiceNameEnum.BL110,
                        PartialServiceNameEnum.BL111,
                        PartialServiceNameEnum.BL112,
                        PartialServiceNameEnum.BL113,
                        PartialServiceNameEnum.BL114,
                        PartialServiceNameEnum.BL115,
                        PartialServiceNameEnum.BL116,
                        PartialServiceNameEnum.BL117
                    ] //BL12 und BL16 fehlen
                ]
            case 2:
                return [
                    [
                        PartialServiceNameEnum.GL2A,
                        PartialServiceNameEnum.GL2B,
                        PartialServiceNameEnum.GL2C,
                        PartialServiceNameEnum.GL2D,
                        PartialServiceNameEnum.GL2E,
                        PartialServiceNameEnum.GL2F,
                        PartialServiceNameEnum.GL2G,
                        PartialServiceNameEnum.GL2H,
                        PartialServiceNameEnum.GL2I1,
                        PartialServiceNameEnum.GL2I2
                    ],
                    [
                        PartialServiceNameEnum.BL21,
                        PartialServiceNameEnum.BL22,
                        PartialServiceNameEnum.BL23,
                        PartialServiceNameEnum.BL24,
                        PartialServiceNameEnum.BL26,
                        PartialServiceNameEnum.BL27,
                        PartialServiceNameEnum.BL28,
                        PartialServiceNameEnum.BL29,
                        PartialServiceNameEnum.BL210,
                        PartialServiceNameEnum.BL212,
                        PartialServiceNameEnum.BL213,
                        PartialServiceNameEnum.BL214,
                        PartialServiceNameEnum.BL215
                    ] //GL2I, BL25, BL211 fehlen
                ]
            case 3:
                return [
                    [
                        PartialServiceNameEnum.GL3A,
                        PartialServiceNameEnum.GL3B,
                        PartialServiceNameEnum.GL3C,
                        PartialServiceNameEnum.GL3D,
                        PartialServiceNameEnum.GL3E,
                        PartialServiceNameEnum.GL3F,
                        PartialServiceNameEnum.GL3G
                    ],
                    [
                        PartialServiceNameEnum.BL31,
                        PartialServiceNameEnum.BL32,
                        PartialServiceNameEnum.BL33,
                        PartialServiceNameEnum.BL34,
                    ] //GL3D fehlen
                ]
            case 4:
                return [
                    [
                      PartialServiceNameEnum.GL4AABAElse,
                      PartialServiceNameEnum.GL4AVKBWNW,
                      PartialServiceNameEnum.GL4AbstandsflaechenHBHESL,
                      PartialServiceNameEnum.GL4AbstandsflaechenuebernahmeBY,
                      PartialServiceNameEnum.GL4AngabenBeherbergungsbettenNW,
                      PartialServiceNameEnum.GL4AngabenBeherbergungsstaetteOhneBrandschutzkonzeptNW,
                      PartialServiceNameEnum.GL4AngabenCOWarnanlageNW,
                      PartialServiceNameEnum.GL4AngabenZuGewerblichenAnlagenBW,
                      PartialServiceNameEnum.GL4AngabenZumBetriebsraumNW,
                      PartialServiceNameEnum.GL4AngabenZumKrankenhausNW,
                      PartialServiceNameEnum.GL4AnlagenGebuehrenHH,
                      PartialServiceNameEnum.GL4AnsichtBWNW,
                      PartialServiceNameEnum.GL4AnsichtSL,
                      PartialServiceNameEnum.GL4AnsichtElse,
                      PartialServiceNameEnum.GL4AntragAufAbloesungNotwendigerEinstellplaetzeNI,
                      PartialServiceNameEnum.GL4AnzahlFahrradstellplaetzeRP,
                      PartialServiceNameEnum.GL4ArbeitsstaettenVORP,
                      PartialServiceNameEnum.GL4ArtDerBaulichenNutzungBBBEBWNWSL,
                      PartialServiceNameEnum.GL4ArtenschutzElse,
                      PartialServiceNameEnum.GL4AussenanlagenplanBE,
                      PartialServiceNameEnum.GL4AuszugBebauungsplanHB,
                      PartialServiceNameEnum.GL4AuszugBebauungsplanSL,
                      PartialServiceNameEnum.GL4BFKHENW,
                      PartialServiceNameEnum.GL4BRIUmbauterRaumHBHEMVNWRPSLST,
                      PartialServiceNameEnum.GL4BauartgenehmigungElse,
                      PartialServiceNameEnum.GL4BaubeschreibungFluessiggas1RP,
                      PartialServiceNameEnum.GL4BaubeschreibungFluessiggas3RP,
                      PartialServiceNameEnum.GL4BaubeschreibungHeizoel1RP,
                      PartialServiceNameEnum.GL4BaubeschreibungHeizoel3RP,
                      PartialServiceNameEnum.GL4BaubeschreibungElse,
                      PartialServiceNameEnum.GL4BaukostenHB,
                      PartialServiceNameEnum.GL4BaukostenwertHB,
                      PartialServiceNameEnum.GL4Baumbestandsbescheinigung1HB,
                      PartialServiceNameEnum.GL4Baumbestandsbescheinigung2HB,
                      PartialServiceNameEnum.GL4BaumbestandsbescheinigungHH,
                      PartialServiceNameEnum.GL4BaunebenrechtHB,
                      PartialServiceNameEnum.GL4BauvorlageberechtigungElse,
                      PartialServiceNameEnum.GL4BauwertHB,
                      PartialServiceNameEnum.GL4BenennungBauleitenderBWSH,
                      PartialServiceNameEnum.GL4BerechnungVerkaufsstaetteNW,
                      PartialServiceNameEnum.GL4BerichtUeberDenGeprueftenBrandschutznachweisBE,
                      PartialServiceNameEnum.GL4BerichtUeberDenGeprueftenStandsicherheitnachweisBE,
                      PartialServiceNameEnum.GL4BescheinigungNach67Abs4LBOSL,
                      PartialServiceNameEnum.GL4BeschreibungBaugrundstueckSL,
                      PartialServiceNameEnum.GL4BeschreibungBarrierefreieWohnungenBE,
                      PartialServiceNameEnum.GL4BeschreibungBaulicherAnlagenSL,
                      PartialServiceNameEnum.GL4BeschreibungDerBaumassnahmenGeringenUmfangsSL,
                      PartialServiceNameEnum.GL4BestuhlungswegplanUndRettungswegplanNW,
                      PartialServiceNameEnum.GL4BetriebsbeschreibungElse,
                      PartialServiceNameEnum.GL4BodenschutzkonzeptBW,
                      PartialServiceNameEnum.GL4BrandschutzkonzeptBYRPST,
                      PartialServiceNameEnum.GL4BrandschutzkonzeptSonderbautenBBBEBYHBHEHHMVNINWRPSHSLSNSTTH,
                      PartialServiceNameEnum.GL4BrandschutznachweisBBBEBYHBHEHHMVNINWRPSHSLSNSTTH,
                      PartialServiceNameEnum.GL4BrandschutznachweisInBauvorlagenSL,
                      PartialServiceNameEnum.GL4DarstellungZufahrtsverhaeltnisseST,
                      PartialServiceNameEnum.GL4EinfuegungsnachweisHE,
                      PartialServiceNameEnum.GL4EnergieausweisElse,
                      PartialServiceNameEnum.GL4ErgaenzungsplanHB,
                      PartialServiceNameEnum.GL4ErklaerungDerNachbarschaftSL,
                      PartialServiceNameEnum.GL4ErklaerungZumBarrierefreienBauenSL,
                      PartialServiceNameEnum.GL4ErlaeuterungGegenNachbarlicheZustimmungRP,
                      PartialServiceNameEnum.GL4ErschliessungssicherungBBBEBYHBHHMVSHSTTH,
                      PartialServiceNameEnum.GL4ErschuetterungsnachweisBEBYHBHHSHSNSTTH,
                      PartialServiceNameEnum.GL4FeuerungsanlagenBWRPSL,
                      PartialServiceNameEnum.GL4FlaechenRP,
                      PartialServiceNameEnum.GL4FoerderungsnachweisSH,
                      PartialServiceNameEnum.GL4FreiflaechengestaltungsplanSL,
                      PartialServiceNameEnum.GL4FreiflaechenplanHBHE,
                      PartialServiceNameEnum.GL4GebaeudeklasseHoeheBBBWHBHHMVNISH,
                      PartialServiceNameEnum.GL4GeschossberechnungHB,
                      PartialServiceNameEnum.GL4GrundbuchauszugBEHB,
                      PartialServiceNameEnum.GL4GrundrissBW,
                      PartialServiceNameEnum.GL4GrundrissHE,
                      PartialServiceNameEnum.GL4GrundrissNI,
                      PartialServiceNameEnum.GL4GrundrissNW,
                      PartialServiceNameEnum.GL4GrundrissRP,
                      PartialServiceNameEnum.GL4GrundrissSL,
                      PartialServiceNameEnum.GL4GrundrissSNST,
                      PartialServiceNameEnum.GL4GrundrissElse,
                      PartialServiceNameEnum.GL4Grundstuecksentwaesserung1BWRPSHSL,
                      PartialServiceNameEnum.GL4Grundstuecksentwaesserung2BWRPSHSL,
                      PartialServiceNameEnum.GL4HerstellungskostenBENINWRPST,
                      PartialServiceNameEnum.GL4HochwasserrisikoRP,
                      PartialServiceNameEnum.GL4HygienegutachtenHE,
                      PartialServiceNameEnum.GL4KriterienkatalogBBBEBYHBMVNWSHSLSNSTTH,
                      PartialServiceNameEnum.GL4LaermschutzkonzeptHE,
                      PartialServiceNameEnum.GL4LageplanBBBEBWHBHEHHMVNINWRPSHSLSNSTTH,
                      PartialServiceNameEnum.GL4LiegenschaftsplanBBBEBYHEHHMVNINWRPSHSLSNSTTH,
                      PartialServiceNameEnum.GL4LueftungsanlagenFeuerungsanlagenHE,
                      PartialServiceNameEnum.GL4MassDerBaulichenNutzungElse,
                      PartialServiceNameEnum.GL4MobilitaetsbedarfHB,
                      PartialServiceNameEnum.GL4NachbarlicheZustimmungBEBYHBRPSNTH,
                      PartialServiceNameEnum.GL4NachweisStellplaetzeFuerFahrraederBE,
                      PartialServiceNameEnum.GL4NachweisBarrierefreieStellplaetzeBE,
                      PartialServiceNameEnum.GL4OeffentlichkeitsbeteiligungBY,
                      PartialServiceNameEnum.GL4PerspektivenBE,
                      PartialServiceNameEnum.GL4PruefverzichtBrandschutznachweisHB,
                      PartialServiceNameEnum.GL4PruefverzichtStandsicherheitsnachweisHB,
                      PartialServiceNameEnum.GL4RohbaukostenNINW,
                      PartialServiceNameEnum.GL4SachverstaendigenbescheinigungBrandschutzBYMVNWRPSH,
                      PartialServiceNameEnum.GL4SachverstaendigenbescheinigungSchallschutzNW,
                      PartialServiceNameEnum.GL4SachverstaendigenbescheinigungWaermeschutzNW,
                      PartialServiceNameEnum.GL4SachverstaendigennachweisStandsicherheitBBBEBYHBMVNWRPSHSNTH,
                      PartialServiceNameEnum.GL4SachverstaendigennachweisStandsicherheitSLST,
                      PartialServiceNameEnum.GL4SchallschutznachweisBBBEBWBYHBHEHHMVNWRPSHSLSNSTTH,
                      PartialServiceNameEnum.GL4SchalltechnischeUntersuchungBBSL,
                      PartialServiceNameEnum.GL4SchnittBB,
                      PartialServiceNameEnum.GL4SchnittBEBWBYHBHHMVNI,
                      PartialServiceNameEnum.GL4SchnittHERPSHSLSNSTTH,
                      PartialServiceNameEnum.GL4SchnittNW,
                      PartialServiceNameEnum.GL4SpielplatznachweisElse,
                      PartialServiceNameEnum.GL4StandsicherheitsnachweisBW,
                      PartialServiceNameEnum.GL4StandsicherheitsnachweisElse,
                      PartialServiceNameEnum.GL4StatistikElse,
                      PartialServiceNameEnum.GL4StellplatznachweisElse,
                      PartialServiceNameEnum.GL4StellungnahmeBE,
                      PartialServiceNameEnum.GL4StellungnahmeBodenschutzbehoerdeHB,
                      PartialServiceNameEnum.GL4StoerfallbetriebRP,
                      PartialServiceNameEnum.GL4UVPElse,
                      PartialServiceNameEnum.GL4UebereinstimmungserklaerungBE,
                      PartialServiceNameEnum.GL4UebersichtsplanHERPSH,
                      PartialServiceNameEnum.GL4UnbedenklichkeitsbescheinigungFeuerwehrHB,
                      PartialServiceNameEnum.GL4UnterlagenUeberBrennstofflagerungNI,
                      PartialServiceNameEnum.GL4VereinfachtesAbfallverwertungskonzeptBW,
                      PartialServiceNameEnum.GL4VergleichsberechnungMV,
                      PartialServiceNameEnum.GL4VertretervollmachtBBBEHEMVNWSNST,
                      PartialServiceNameEnum.GL4WaermeschutznachweisBBBEBYHBHEHHNWRPSHSLSTTH,
                      PartialServiceNameEnum.GL4AmtlicheBasiskarteNW,
                      PartialServiceNameEnum.GL4AmtlicherLageplanBBBYNINWSL,
                      PartialServiceNameEnum.GL4AnrechenbareKostenMVSHST,
                      PartialServiceNameEnum.GL4AnrechenbarerBauwertHB,
                      PartialServiceNameEnum.GL4BeabsichtigteNutzungST,
                      PartialServiceNameEnum.GL4DenkmalschutzrechtlicheErlaubnisBY,
                      PartialServiceNameEnum.GL4ErgaenzendeBaubeschreibungMV,
                      PartialServiceNameEnum.GL4ErneuerbareEnergienBB,
                      PartialServiceNameEnum.GL4ErteilteBescheideBEHB,
                      PartialServiceNameEnum.GL4OeffentlichRechtlicheVorschriftenHBHH,
                      PartialServiceNameEnum.GL4OeffentlichkeitsbeteiligungBY,
                      PartialServiceNameEnum.GL4OertlicheBauvorschriftBBSL,
                      PartialServiceNameEnum.GL4QualifizierterLageplanHB,
                      PartialServiceNameEnum.GL4QualifizierterLageplanNI,
                      PartialServiceNameEnum.GL4WassergefaehrdendeStoffeElse,
                    ].filter((value) => value.toLowerCase().includes(this.currentState.toLowerCase()) || value.toString().includes("_else")),
                    [
                        PartialServiceNameEnum.BL41,
                        PartialServiceNameEnum.BL42,
                        PartialServiceNameEnum.BL43,
                    ]
                ]
            case 5:
                return [
                    [
                        PartialServiceNameEnum.GL5A,
                        PartialServiceNameEnum.GL5B,
                        PartialServiceNameEnum.GL5C,
                        PartialServiceNameEnum.GL5D
                    ],
                    [
                        PartialServiceNameEnum.BL51,
                        PartialServiceNameEnum.BL53,
                        PartialServiceNameEnum.BL54,
                    ]

                ]

            default:
                return [[], []]
        }
    }
}
