import {Component, OnInit} from '@angular/core';
import {faCircleXmark, faFolder} from "@fortawesome/free-regular-svg-icons";
import {faChevronLeft, faChevronRight, faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import {FileControllerService, ProjectFile, QualityCheck, QualityControllerService, QualityRequest} from "../../../api";
import {StateConfigService} from "../../../services/state/state-config.service";
import {FileTreeNode} from "../../util/files/files.component";
import {SelectionModel} from "@angular/cdk/collections";
import {ActivatedRoute, Router} from "@angular/router";
import FolderEnum = ProjectFile.FolderEnum;
import {PermissionService} from "../../../services/permission/permission.service";
import {faSquareXmark} from "@fortawesome/pro-duotone-svg-icons";
import {faXmark} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-quality-choose-file',
  templateUrl: './quality-choose-files.component.html',
  styleUrls: ['./quality-choose-files.component.scss']
})
export class QualityChooseFilesComponent implements OnInit {

  check: QualityCheck | undefined;

  filteredLV: ProjectFile[] = [];
  filteredRB: ProjectFile[] = [];
  filteredIFC: ProjectFile[] = [];
  selectedFolder: string = '';
  runningDocumentClassification: string[] = [];
  selectedLVFiles: any[] = [];
  selectedRBFiles: any[] = [];
  selectedIFCFiles: any[] = [];
  columns =['Name', 'Größe(MB)', 'Zuletzt geändert']
  column = ['name', 'size', 'lastModified']
  colTypes =['text', 'filesize', 'date']

  constructor(private fileControllerService: FileControllerService,
              private qualityControllerService: QualityControllerService,
              public permissionService: PermissionService,
              private activatedRoute: ActivatedRoute,
              public stateConfigService: StateConfigService, private router: Router) {

  }

  ngOnInit() {
    this.loadFiles();
    this.stateConfigService.runningClassificationsChange.subscribe((runningClassifications) => {
      this.runningDocumentClassification = runningClassifications;
      this.loadFiles();
    });

    this.check = this.activatedRoute.snapshot.parent?.data['qualityCheck']


  }


  get projectId(): number {
    return this.stateConfigService.getProjectId();
  }

  navigateToChoosingRules() {
    this.router.navigate(['projects', this.projectId, 'quality', 'rules'], { state: {
      ifcFiles: this.selectedIFCFiles,
      lvFiles: this.selectedLVFiles,
      roomBookFiles: this.selectedRBFiles
    } });
  }

  onFolderClick(folder: string) {
    this.selectedFolder = folder;
  }

  get shownFiles(): ProjectFile[] {
    switch (this.selectedFolder) {
      case 'LV':
        return this.filteredLV;
      case 'RB':
        return this.filteredRB;
      case 'IFC':
        return this.filteredIFC;
      default:
        return [];
    }
  }

  get shownSelectedFiles() {
    switch (this.selectedFolder) {
      case 'LV':
        return this.selectedLVFiles;
      case 'RB':
        return this.selectedRBFiles;
      case 'IFC':
        return this.selectedIFCFiles;
      default:
        return [];
    }
  }

  set shownSelectedFiles(files: any[]) {
    switch (this.selectedFolder) {
      case 'LV':
        this.selectedLVFiles = files;
        break;
      case 'RB':
        this.selectedRBFiles = files;
        break;
      case 'IFC':
        this.selectedIFCFiles = files;
        break;
      default:
        break;
    }
  }

  protected readonly faChevronLeft = faChevronLeft;

  getSelectedIFCFiles(): { name: string, size: string, lastModified: Date }[] {
    return this.selectedIFCFiles.map(node => ({name: node.name || '', size: this.calculateSize(node.size!), lastModified: node.lastModified, id: node.id}));
  }

  getSelectedLVFiles(): { name: string, size: string, lastModified: Date }[] {
    return this.selectedLVFiles.map(node => ({name: node.name || '', size: this.calculateSize(node.size!), lastModified: node.lastModified, id: node.id}));
  }

  getSelectedRBFiles(): { name: string, size: string, lastModified: Date }[] {
    return this.selectedRBFiles.map(node => ({name: node.name || '', size: this.calculateSize(node.size!), lastModified: node.lastModified, id: node.id}));
  }

  removeSelectedLVFile(file: any) {
    const index = this.selectedLVFiles.findIndex(selectedFiles=>file.id==selectedFiles.id)
    if (index !== -1) {
      this.selectedLVFiles.splice(index, 1);
    }
  }

  removeSelectedRBFile(file: any) {
    const index = this.selectedRBFiles.findIndex(selectedFiles=>file.id==selectedFiles.id)
    if (index !== -1) {
      this.selectedRBFiles.splice(index, 1);
    }
  }

  removeSelectedIFCFile(file: any) {
    const index = this.selectedIFCFiles.findIndex(selectedFiles=>file.id==selectedFiles.id)
    if (index !== -1) {
      this.selectedIFCFiles.splice(index, 1);
    }
  }

  loadFiles(): void {
    this.fileControllerService.getFiles(this.stateConfigService.getProjectId()).subscribe(files => {
      this.filteredLV = files.filter(file => file.folder === FolderEnum.LEISTUNGSVERZEICHNIS && !file.uploadFailed);
      this.filteredIFC = files.filter(file => file.name?.toLocaleLowerCase().endsWith('.ifc') && !file.uploadFailed);
      this.filteredRB = files.filter(file => file.folder === FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH && !file.uploadFailed && file.fileType=='EXCEL');

      if (this.check) {
        this.selectedIFCFiles = this.filteredIFC.filter(file => this.check!.projectFiles!['IFC'].map(file => file.id).includes(file.id)).map(file => file as FileTreeNode);
        this.selectedLVFiles = this.filteredLV.filter(file => this.check!.projectFiles!['LV'].map(file => file.id).includes(file.id)).map(file => file as FileTreeNode);
        this.selectedRBFiles = this.filteredRB.filter(file => this.check!.projectFiles!['ROOM_BOOK'].map(file => file.id).includes(file.id)).map(file => file as FileTreeNode);
      }
    });
  }

  get canCheckQuality(): boolean {
    return this.stateConfigService.canCheckQuality();
  }

  protected readonly faChevronRight = faChevronRight;
  protected readonly faFolder = faFolder;
  protected readonly faFolderOpen = faFolderOpen;

  private calculateSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size < kb) {
      return `${size} Bytes`;
    } else if (size < mb) {
      return `${(size / kb).toFixed(2)} KB`;
    } else if (size < gb) {
      return `${(size / mb).toFixed(2)} MB`;
    } else {
      return `${(size / gb).toFixed(2)} GB`;
    }
  }

  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faSquareXmark = faSquareXmark;
  protected readonly faXmark = faXmark;
}
