import {Directive, EventEmitter, HostListener, Output} from '@angular/core';
import {interval, Observable, Subject} from "rxjs";
import {filter, takeUntil, tap} from "rxjs/operators";

/**
 * Directive for holding an Button (delete Action)
 */
@Directive({
  selector: '[appHoldable]'
})
export class HoldableDirective {
  /**
   * Time the user hold down
   */
  @Output() holdTime: EventEmitter<number> = new EventEmitter<number>();

  /**
   * State of holding
   */
  state: Subject<string> = new Subject<string>();

  /**
   * cancel observable
   */
  cancel: Observable<string> = new Observable<string>();

  /**
   * Constructor
   */
  constructor() {
    this.cancel = this.state.pipe(
      filter(value => value === 'cancel'),
      tap(value => {
        this.holdTime.emit(0)
      })
    )
  }

  /**
   * exit method
   */
  @HostListener('mouseup', ['$event'])
  @HostListener('mouseleave', ['$event'])
  onExit() {
    this.state.next('cancel')
  }

  /**
   * action when holding
   */
  @HostListener('mousedown', ['$event'])
  onHold() {
    this.state.next('start')

    const n = 100;
    interval(n).pipe(
      takeUntil(this.cancel), tap(v => {
        this.holdTime.emit(v * n)
      })
    ).subscribe()
  }

}
