import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {
  faArrowUpRightFromSquare,
  faBars,
  faChevronDown,
  faChevronRight,
  faChevronUp, faClipboardCheck,
  faClipboardList,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import {ActivatedRoute} from "@angular/router";
import {IFCCheck, IfcControllerService, QualityRequest, Task} from "../../../../api";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {CircleState} from "../../../util/design/circle-state/circle-state.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {TaskDetailsService} from "../../../../services/task-details.service";
import {MatTableDataSource} from "@angular/material/table";
import {PopoutContentService} from "../../../../services/popout/popout.service";
import { ExchangeService } from '../../../../services/exchange/exchange.service';

export interface LoiElement {
  name: string
  count: number
  properties: LoiProperties[]
}

export interface LoiProperties {
  name: string
  result: ResponseType
  guidListCorrect: string[]
  guidListFail: string[]
}

@Component({
  selector: 'app-ifcCheckReport',
  templateUrl: './ifc-check-report.component.html',
  styleUrls: ['./ifc-check-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class IfcCheckReportComponent implements OnInit {

  check!: IFCCheck
  expandedElement: LoiElement | undefined = undefined;
  expandedElements: LoiElement[] = [];
  dataSource: MatTableDataSource<LoiElement> = new MatTableDataSource<LoiElement>();
  selectedDataSource: MatTableDataSource<LoiProperties> = new MatTableDataSource<LoiProperties>();
  selectedDataSources = new Map<LoiElement, MatTableDataSource<LoiProperties>>();


  data: any[] = []
  subData: any[] = []

  displayedColumns = ['name', 'count', 'result']
  displayedColumnsNames = ['Bauteiltyp', 'Anzahl Bauteile', 'Auswertungsergebnis']
  columnsWidths=['40%', '40%','20%']
  columnsTypes=['text', 'text','text']
  columnsAlignment = ['start', 'center', 'center']

  subDisplayedColumnsNames:string[]=['Attributname','','']
  subDisplayedColumns:string[] = ['name','count', 'calculated']
  subColumnsWidths:string[]=['40%', '40%', '20%']
  subColumnsTypes:string[]=['text', 'text', 'text']
  subColumnsAlignment: string[] = ['start', 'center', 'center']

  score = 0

  allCount = 0

  constructor(
    private activatedRoute: ActivatedRoute,
    private ifcControllerService: IfcControllerService,
    private stateConfigService: StateConfigService,
    private taskDetailService: TaskDetailsService,
    private popoutContentService: PopoutContentService,
    private exchangeService: ExchangeService
  ) { }

  numberOfProperties(element: LoiElement): number {
    return element.properties?.length ?? 0
  }

  medianOfPercentages(): number {
    let filteredData = this.dataSource.data.filter(element => element.count > 0);
    let totalElements = filteredData.reduce((total, element) => total + element.count, 0);
    let value = filteredData.map(element => this.medianOfPercentagesElement(element) * element.count).reduce((a, b) => a + b, 0) / totalElements;

    return isNaN(value) ? 0 :  Math.round(value)
  }

  medianOfPercentagesElement(element: LoiElement): number {
    let value =  (element.properties ?? []).map(property => this.percentCorrectProperty(property)).reduce((a, b) => a + b, 0) / (element.properties ?? []).length

    return isNaN(value) ? 0 : Math.round(value)
  }

  percentCorrectProperty(property: LoiProperties): number {
    let value = property.guidListCorrect.length / (property.guidListCorrect.length + property.guidListFail.length) * 100

    return isNaN(value) ? 0 : Math.round(value)
  }

  selectElement(element: LoiElement) {
    // this.expandedElement = this.expandedElement === element ? undefined : element;
    // this.selectedDataSource.data = element.properties;

    if(this.expandedElements.includes(element)){
      this.expandedElements.splice(this.expandedElements.indexOf(element), 1)
      this.selectedDataSources.delete(element)
      this.removeGuids(element)
    }else{
      this.expandedElements.push(element)
      this.selectedDataSources.set(element, new MatTableDataSource<LoiProperties>(element.properties))
      this.addGuids(element)
    }
  }

  get guidsFail(): string[] | undefined{
    return this.expandedElement?.properties
      .map(property => property.guidListFail)
      .flatMap(guids => guids) ?? undefined
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {

      if (params.reportId !== undefined) {
        this.check = {id: params.reportId}
      }
      this.exchangeService.clear()

      this.ifcControllerService.getCheck2(this.check.id!, this.stateConfigService.getProjectId()).subscribe(check => {
        this.check = check;

        const results = JSON.parse(this.check.results!);

        // @ts-ignore
        this.score = results.pop().score * 100

        let localData:any[] = []
        results.forEach((result:any) =>{
          localData.push({...result, result:this.medianOfPercentagesElement(result)+'%'})
        })

        this.data = localData
        this.data.forEach(element=>{
          this.allCount += element.count
        })
        console.log(this.data)
        this.dataSource.data = results
      });
    })
  }

  isError(): boolean {
    if(this.check.results){
      return JSON.parse(this.check.results!)[0].error !== undefined
    }else{
      return false
    }
  }

  errorDescription(): string {
    return JSON.parse(this.check.results!)[0].error
  }

  openFile() {
    let baseUrl = window.location.origin + '/projects/' + this.stateConfigService.selectedProject.id + '/files/' + this.check.projectFile!.id;
    this.popoutContentService.open(baseUrl, '', true);
  }





  createTask() {
    let task = {
      lph: this.check.lph,
    }
    this.taskDetailService.setOpenedTask(task as Task);

  }

  get failedGuids(): string[] {
    return this.expandedElements.flatMap(element => element.properties.flatMap(property => property.guidListFail))
  }

  addGuids(element : LoiElement){
    let guids = this.getGuidsFromElement(element)
    this.exchangeService.addToSuccessGuids(guids.successGuids);
    this.exchangeService.addToFailedGuids(guids.failedGuids);
  }

  removeGuids(element : LoiElement){
    let guids = this.getGuidsFromElement(element)
    this.exchangeService.removeFromSuccessGuids(guids.successGuids);
    this.exchangeService.removeFromFailedGuids(guids.failedGuids);
  }

  getGuidsFromElement(element: LoiElement) {
    let row = element.properties ? element.properties[0] : {guidListCorrect: [], guidListFail: [], name: 'Test', result: 'error'}
    let successGuids: string[] = row.guidListCorrect
    let failedGuids: string[] = row.guidListFail
    successGuids = successGuids.filter((item, index) => successGuids.indexOf(item) === index)
    failedGuids = failedGuids.filter((item, index) => failedGuids.indexOf(item) === index)
    return {successGuids, failedGuids}
  }

  changeSubData(row: any){
    console.log(row)
    let array: any[] = []
    row.properties.forEach((obj2:any)=>{
      //let count = obj2.guidListCorrect.length + obj2.guidListFail.length
      array.push({...obj2, calculated:this.percentCorrectProperty(obj2)+'%'})
    })
    this.subData = array
    console.log('subdata',this.subData)
  }



  protected readonly faBars = faBars;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faChevronDown = faChevronDown;
  protected readonly faChevronUp = faChevronUp;
  protected readonly CircleState = CircleState;
  protected readonly undefined = undefined;
  protected readonly faClipboardList = faClipboardList;
  protected readonly faDownload = faDownload;
  protected readonly faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  protected readonly faClipboardCheck = faClipboardCheck;
}
