/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Extraction } from './extraction';

export interface Check { 
    id?: string;
    fileUrl?: string;
    fileType?: Check.FileTypeEnum;
    parameters?: any;
    extraction?: Extraction;
    result?: any;
}
export namespace Check {
    export type FileTypeEnum = 'IFC' | 'LV' | 'RB';
    export const FileTypeEnum = {
        IFC: 'IFC' as FileTypeEnum,
        LV: 'LV' as FileTypeEnum,
        RB: 'RB' as FileTypeEnum
    };
}