import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-plan-result',
  templateUrl: './plan-result.component.html',
  styleUrls: ['./plan-result.component.scss']
})
export class PlanResultComponent {
  link: string | null = null;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(){
    this.link = this.activatedRoute.snapshot.queryParamMap.get("url")
  }

}
