import { Component } from '@angular/core';
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {CdkStepper} from "@angular/cdk/stepper";
import {matStepperAnimations} from "@angular/material/stepper";

@Component({
  selector: 'app-dashboard-stepper',
  templateUrl: './dashboard-stepper.component.html',
  styleUrls: ['./dashboard-stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: DashboardStepperComponent}],
  animations: [
    matStepperAnimations.horizontalStepTransition,
  ],
})
export class DashboardStepperComponent extends CdkStepper {


  onClick(index: number): void {
    this.selectedIndex = index;
    this._stateChanged();
  }

    protected readonly faChevronLeft = faChevronLeft;
    protected readonly faChevronRight = faChevronRight;
}
