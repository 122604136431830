<div class="relative rounded-xl p-8 border border-gray-300 max-w-md mx-auto"
     [ngClass]="{
     'bg-secondary-20': isActive,
     'shadow-full': !info
     }">
  <div class="flex flex-col justify-start items-center h-full">
    <div class="flex flex-col w-full justify-center items-center gap-4">
      <div class="text-[18px] text-gray-500" [class.invisible]="info || productPrice?.unitAmount == 0">{{ isRecurring() ? "ABO-MODELL": !info ? "EINMALIG": "" }}</div>
      <div class="text-xl font-semibold">{{ !info ? productPrice!.productObject!.name : "Features" }} {{isSpecial ? "(Custom)" : ""}}</div>
    </div>
    <div class="flex flex-row w-full pt-8 pb-4 justify-center items-end gap-2.5">
      <span *ngIf="!info" class="font-extrabold text-primary text-[48px]">{{ productSettings.credits }}</span>
      <span *ngIf="isRecurring()" class="text-gray-500 text-base font-light pb-3.5">
          Credits
        </span>
    </div>
    <div class="flex flex-row w-full pb-8 pt-4 justify-center items-center" *ngIf="productPrice?.unitAmount > 0">
        <span *ngIf="!info" class="font-extrabold text-gray-900 text-[36px]">{{ (isRecurring() ? productPrice.recurring?.interval == 'month' ? productPrice?.unitAmount! / 100: productPrice?.unitAmount! / 100 / 12 : productPrice?.unitAmount! / 100) | number }} € </span>
        <span *ngIf="isRecurring()" class="text-gray-500 text-base font-light">
          {{ productPrice?.recurring?.interval == 'month' ? '/Monat' : '/Monat' }}
        </span>
    </div>
    <div  class="flex flex-row w-full pb-8 pt-4 justify-center items-center" *ngIf="productPrice?.unitAmount == 0">
      <span class="font-extrabold text-gray-900 text-[36px]">KOSTENLOS</span>
    </div>




    <app-checkout-feature-list class="flex w-full pb-8" [isInfo]="info" [productSettings]="productSettings" [active]="!isAddOn && isActive" [contingent]="contingent">

    </app-checkout-feature-list>

    <button class="flex flex-row rounded-lg bg-primary justify-center items-center gap-2 h-[42px] text-sm px-4 py-2.5 hover:opacity-80 w-full" (click)="!isActive ? checkoutClicked.emit(price): {}"
            [ngClass]="{
            'bg-primary hover:bg-secondary': !isActive,
            'bg-secondary-45': isActive
           }">
      <span class="text-white">{{isAddOn && !isActive ? 'Hinzufügen' : isAddOn && isActive ? 'Nicht verfügbar' : isActive ? 'Ausgewählt' : 'Jetzt Starten'}}</span>
    </button>


  </div>

  <div class="flex flex-row w-full justify-end items-center gap-2 absolute top-0 pt-2 pr-10">
    <div *ngIf="!info && !productPrice?.livemode"
         class="max-w-fit px-2 text-yellow-800 bg-bic-yellow rounded-md text-xs flex flex-row justify-center items-center">
      TEST MODE
    </div>
  </div>
</div>
