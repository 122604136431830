import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CompletenessCheck, FileControllerService, ProjectControllerService, ProjectFile} from "../../../../../api";
import {SelectionModel} from "@angular/cdk/collections";
import {delayWhen, fromEvent, interval, of, Subscription} from "rxjs";
import {tap} from "rxjs/operators";
import {FileTreeNode} from "../../files.component";
import {DrawerContentService} from "../../../../../services/drawer-content.service";
import {MatSort} from "@angular/material/sort";
import {LphToNumberPipe} from "../../../../../pipes/lphToNumber.pipe";
import {PermissionService} from "../../../../../services/permission/permission.service";
import {ActivatedRoute, Router} from "@angular/router";
import {faCircleExclamation, faCopy, faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {StateConfigService} from "../../../../../services/state/state-config.service";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatMenuTrigger} from "@angular/material/menu";
import {PopoutContentService} from "../../../../../services/popout/popout.service";
import LphEnum = CompletenessCheck.LphEnum;
import {MoveFilesDialogComponent} from "../move-files-dialog/move-files-dialog.component";
import {CopyFilesDialogComponent} from "../copy-files-dialog/copy-files-dialog.component";
import {faSquareDashedCirclePlus} from "@fortawesome/pro-solid-svg-icons";

export interface RenameFileData {
  file: ProjectFile,
  newName: string
}

@Component({
  selector: 'app-files-table',
  templateUrl: './files-table.component.html',
  styleUrls: ['./files-table.component.scss']
})
export class FilesTableComponent implements OnInit, OnChanges {

  @Input() projectFiles: ProjectFile[] = [];
  @Input() moveDestinationSelection: boolean = false;

  @Input() viewOnly: boolean = false;
  @Input() disableSelection: boolean = false;

  @Input() isPopup: boolean = false;
  @Input() versioningSingleFile: boolean = false;
  @Input() isBim: boolean = false;
  @Input() isFileListView: boolean = false;
  @Input() isQualityView: boolean = false;

  @Output() deleteFile: EventEmitter<ProjectFile> = new EventEmitter<ProjectFile>();
  @Output() renameFile: EventEmitter<RenameFileData> = new EventEmitter<RenameFileData>();

  @Input() selectedFiles: SelectionModel<FileTreeNode> = new SelectionModel<FileTreeNode>(true, []);
  @Output() selectedFilesChange: EventEmitter<SelectionModel<FileTreeNode>> = new EventEmitter<SelectionModel<FileTreeNode>>();
  @Output() fileClicked: EventEmitter<FileTreeNode> = new EventEmitter<FileTreeNode>();

  @Input() breadcrumbNavigation: ProjectFile[] | FileTreeNode[] = [];
  @Output() breadcrumbNavigationChange: EventEmitter<ProjectFile[]> = new EventEmitter<ProjectFile[]>();

  dataSource: MatTableDataSource<FileTreeNode> = new MatTableDataSource<FileTreeNode>();
  displayedColumns = ["select", "name", "size", "lastModified"];
  displayedColumnsFileView = ["select", "name", "size", "lastModified", "actions"];


  @Input() uploadingFiles: Map<string, number> = new Map<string, number>();

  @Output() classifyDocument: EventEmitter<FileTreeNode> = new EventEmitter<FileTreeNode>();

  @Input() runningDocumentClassification: string[] = [];

  servicePhases: typeof LphEnum = LphEnum;

  projectId!: string | null;
  fileTrees: ProjectFile[] | undefined = undefined;

  hoveredRow: FileTreeNode | undefined

  private windowClickSubscription: Subscription | undefined;
  menuTopLeftPosition = {x: '0', y: '0'}
  menuSubscription: Subscription = new Subscription();

  @ViewChild("rightClickMenuTrigger", {read: MatMenuTrigger}) matMenuTrigger: MatMenuTrigger | undefined;
  @ViewChild('filesTbSort') filesTbSort = new MatSort();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private projectControllerService: ProjectControllerService,
    private lphToNumberPipe: LphToNumberPipe,
    private drawerContentService: DrawerContentService,
    public permissionService: PermissionService,
    private fileControllerService: FileControllerService,
    private stateConfigService: StateConfigService,
    private popoutContentService: PopoutContentService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {

    if (this.moveDestinationSelection) {
      this.projectFiles = this.filterFiles(this.projectFiles);
    }
    this.projectId = this.activatedRoute.snapshot.paramMap.get('id');

    this.dataSource.data = this.projectFiles as FileTreeNode[];



    if (this.versioningSingleFile) {
      this.displayedColumns = ["select", "name", "size", "operation", "lastModified", "type", "tags"];
    }

    if (this.isBim) {
      this.displayedColumns = ["select", "name", "size", "lp"];
    }

    if (this.disableSelection) {
      this.displayedColumns = ["name", "size", "lastModified"];
    }

    this.windowClickSubscription = fromEvent(window, 'click').subscribe((_) => {
      if (this.matMenuTrigger!.menuOpen) {
        this.matMenuTrigger!.closeMenu();
      }
    });
  }

  // ngAfterViewInit() {
  //   this.dataSource.sort = this.filesTbSort;
  // }

  projectFile(node: FileTreeNode): ProjectFile {
    return node!;
  }

  lphSelect(node: FileTreeNode) {
    let lphs = [];
    for (let lph of Object.values(LphEnum)) {
      if (!node.lph?.includes(lph) && lph !== LphEnum.PLANS && lph !== LphEnum.LPH6 && lph !== LphEnum.LPH5) {
        lphs.push(lph);
      }
    }
    return lphs;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("OnChanges", JSON.parse(JSON.stringify(this.projectFiles)));
    if (this.breadcrumbNavigation.length === 0) {

      if (this.fileTrees){
        this.dataSource.data = this.fileTrees as FileTreeNode[];
      }
      else {
        this.dataSource.data = this.projectFiles as FileTreeNode[];
      }
    }

    this.selectedFilesChange.emit(this.selectedFiles);

  }

  isPdf = (node: FileTreeNode) => {
    return node.name?.toLocaleLowerCase().endsWith(".pdf");
  }

  isDwg = (node: FileTreeNode) => {
    return node.name?.toLocaleLowerCase().endsWith(".dwg");
  }

  isIfc = (node: FileTreeNode) => {
    return node.name?.toLocaleLowerCase().endsWith(".ifc");
  }

  openFolder(node: FileTreeNode) {
    // this.dataSource.data = node.children! as FileTreeNode[];
    this.selectedFiles.clear();
    this.breadcrumbNavigation.push(node);
  }

  resetBreadcrumbTo(node: FileTreeNode) {
    // this.dataSource.data = node.children! as FileTreeNode[];

    const index = this.breadcrumbNavigation.indexOf(node);
    this.breadcrumbNavigation = this.breadcrumbNavigation.slice(0, index + 1);
    this.selectedFiles.clear();
    this.breadcrumbNavigationChange.emit(this.breadcrumbNavigation);
  }

  resetToBreadcrumbRoot() {
    this.dataSource.data = this.projectFiles as FileTreeNode[];
    this.breadcrumbNavigation = []
    this.selectedFiles.clear();
    this.breadcrumbNavigationChange.emit(this.breadcrumbNavigation);
  }

  isAllSelected() {
    const numSelected = this.selectedFiles.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  openMoveFilesDialog(file: FileTreeNode) {
    this.selectedFiles.select(file);
    let dialogRef = this.dialog.open(MoveFilesDialogComponent, {
      width: '30%',
      panelClass:"rounded-corners-dialog",
      data: {
        selectedFiles: this.selectedFiles.selected
      }
    });
    dialogRef.afterClosed().subscribe((result: FileTreeNode[]) => {
      let ids = result.map(file => file.id!);
      this.fileControllerService.moveFile(ids, result[0].lph!, result[0].folder!).subscribe(files => {
        this.dataSource.data = files as FileTreeNode[];
        this.fileTrees = files;
        this.selectedFiles.clear();
      });
    });
  }

  openCopyFilesDialog(file: FileTreeNode) {
    this.selectedFiles.select(file);
    let dialogRef = this.dialog.open(CopyFilesDialogComponent, {
      width: '30%',
      panelClass:"rounded-corners-dialog",
      data: {
        selectedFiles: this.selectedFiles.selected
      }
    });
    dialogRef.afterClosed().subscribe((result: FileTreeNode[]) => {
      let ids = result.map(file => file.id!);
      this.fileControllerService.copyFile(ids, result[0].lph!, result[0].folder!).subscribe(files => {
        this.dataSource.data = files as FileTreeNode[];
        this.fileTrees = files;
        this.selectedFiles.clear();
      });
    });
  }

  selectRow(row: FileTreeNode) {
    if (!this.isFileListView) {
        row.uploadFailed ? this.selectedFiles.deselect(row) : this.selectedFiles.toggle(row);
    } else {
      this.selectedFiles.toggle(row);
    }
    this.fileClicked.emit(row);
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectedFiles.clear();
      return;
    }

    if (!this.isFileListView) {
      this.selectedFiles.select(...this.dataSource.data.filter(row => !row.uploadFailed));
    }else {
      this.selectedFiles.select(...this.dataSource.data);
    }

    this.selectedFilesChange.emit(this.selectedFiles);
  }

  downloadFile(file: FileTreeNode) {
    // download file by a element
    const a = document.createElement('a');
    a.href = <string>file.link;
    a.target = '_blank';
    a.click();
  }

  openFile(file: FileTreeNode) {
    let baseUrl = window.location.origin + '/projects/' + this.projectId + '/files/' + file.id;
    this.popoutContentService.open(baseUrl, file.name ?? '');
    // if (this.isPdf(file)) {
    //
    //
    //     this.drawerContentService.open(PdfViewerComponent, {
    //         large: false,
    //         title: file.name ?? '',
    //         data: {
    //             link: file.link,
    //         }
    //     })
    // } else if (this.isIfc(file)) {
    //     this.drawerContentService.open(IfcViewerComponent, {
    //         large: true,
    //         fixedSize: true,
    //         title: file.name ?? '',
    //         data: {
    //             link: file.link,
    //
    //         }
    //     })
    // }

  }

  onRightClick(event: MouseEvent, node: FileTreeNode) {
    event.preventDefault();

    if (this.moveDestinationSelection) {
      return;
    }

    this.menuSubscription && this.menuSubscription.unsubscribe();
    this.menuSubscription = of(1)
      .pipe(
        tap(() => {
          if (this.matMenuTrigger!.menuOpen) {
            this.matMenuTrigger!.closeMenu();
          }
          this.menuTopLeftPosition.x = event.clientX + 'px';
          this.menuTopLeftPosition.y = event.clientY + 'px';
          this.matMenuTrigger!.menuData = {node: node}
        }),
        // delay(this.contextMenu.menuOpen ? 200 : 0),
        delayWhen((_) => (this.matMenuTrigger!.menuOpen ? interval(200) : of(undefined))),
        tap(async () => {
          this.matMenuTrigger!.openMenu();
          let backdrop: HTMLElement | undefined = undefined;
          do {
            await this.delay(100);
            backdrop = document.querySelector(
              'div.cdk-overlay-backdrop.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing'
            ) as HTMLElement;
          } while (backdrop === null);
          backdrop.style.pointerEvents = 'none';
        })
      )
      .subscribe();
  }

  delay(delayInms: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(2);
      }, delayInms);
    });

  }

  onLeftClick(event: MouseEvent, node: FileTreeNode) {
    if (node != undefined) {
      if (this.matMenuTrigger!.menuOpen) {
        this.matMenuTrigger!.closeMenu();
        this.onRightClick(event, node)
      } else {
        this.matMenuTrigger!.openMenu();
        this.onRightClick(event, node);
      }
    }

  }

  // recursive function to filter out all files that are not folders
  private filterFiles(fileTrees: ProjectFile[]) {
    return fileTrees.filter((fileTree) => {
      // if (fileTree.children!.length > 0) {
      //     fileTree.children = this.filterFiles(fileTree.children!);
      // }
      return fileTree.isFolder;
    })
  }

  startClassifyDocument(file: FileTreeNode) {
    this.classifyDocument.emit(file);
  }

  percent(file: FileTreeNode) {
    return this.uploadingFiles.get(file.name!) ?? 0;
  }

  isUploading(file: FileTreeNode) {
    const percent = this.uploadingFiles.get(file.name!);
    return this.uploadingFiles.get(file.name!) !== undefined && percent !== 100 && percent !== 0;
  }

  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }

  protected readonly faCircleExclamation = faCircleExclamation;
  protected readonly faCopy = faCopy;
  protected readonly faSquareDashedCirclePlus = faSquareDashedCirclePlus;
  protected readonly faEllipsisVertical = faEllipsisVertical;
}
