import {Component, Inject, OnInit, SecurityContext} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-pop-up-window',
  templateUrl: './pop-up-window.component.html',
  styleUrls: ['./pop-up-window.component.scss']
})
export class PopUpWindowComponent implements OnInit {

  holdable: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { header: string, message: string, approveButtonName: string, holdable: boolean }, public dialogRef: MatDialogRef<PopUpWindowComponent>) { }

  ngOnInit(): void {
    this.holdable = this.data.holdable;
  }

  holdHandler(e: any) {
    if (e === 3000) {
     this.approvePressed();
      }
  }

  approvePressed() {
    this.dialogRef.close({approvePressed: true});
  }

  declinePressed() {
    this.dialogRef.close({approvePressed: false});
  }

}
