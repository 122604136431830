/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DocumentTextExtractionResultChunk { 
    sourceBucketName?: string;
    sourceObjectKey?: string;
    targetBucketName?: string;
    targetObjectKeyPrefix?: string;
    splitIndex?: number;
    extractedTextResponse?: string;
    extractedText?: string;
    lemmatizedText?: string;
}