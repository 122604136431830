export * from './authController.service';
import { AuthControllerService } from './authController.service';
export * from './billingController.service';
import { BillingControllerService } from './billingController.service';
export * from './bimBaseCheckController.service';
import { BimBaseCheckControllerService } from './bimBaseCheckController.service';
export * from './checkController.service';
import { CheckControllerService } from './checkController.service';
export * from './dashboardController.service';
import { DashboardControllerService } from './dashboardController.service';
export * from './fileController.service';
import { FileControllerService } from './fileController.service';
export * from './ifcController.service';
import { IfcControllerService } from './ifcController.service';
export * from './notificationController.service';
import { NotificationControllerService } from './notificationController.service';
export * from './organisationController.service';
import { OrganisationControllerService } from './organisationController.service';
export * from './organisationsController.service';
import { OrganisationsControllerService } from './organisationsController.service';
export * from './partnerController.service';
import { PartnerControllerService } from './partnerController.service';
export * from './projectController.service';
import { ProjectControllerService } from './projectController.service';
export * from './projectsController.service';
import { ProjectsControllerService } from './projectsController.service';
export * from './qualityController.service';
import { QualityControllerService } from './qualityController.service';
export * from './serviceController.service';
import { ServiceControllerService } from './serviceController.service';
export * from './stateController.service';
import { StateControllerService } from './stateController.service';
export * from './swmController.service';
import { SwmControllerService } from './swmController.service';
export * from './taskController.service';
import { TaskControllerService } from './taskController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './usersController.service';
import { UsersControllerService } from './usersController.service';
export const APIS = [AuthControllerService, BillingControllerService, BimBaseCheckControllerService, CheckControllerService, DashboardControllerService, FileControllerService, IfcControllerService, NotificationControllerService, OrganisationControllerService, OrganisationsControllerService, PartnerControllerService, ProjectControllerService, ProjectsControllerService, QualityControllerService, ServiceControllerService, StateControllerService, SwmControllerService, TaskControllerService, UserControllerService, UsersControllerService];
