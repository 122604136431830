import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilesUploadErrorDialogComponent} from "./components/files-upload-error/files-upload-error-dialog.component";
import {MoveFilesDialogComponent} from "./components/move-files-dialog/move-files-dialog.component";
import {FilesComponent} from "./files.component";
import {FilesTableComponent} from "./components/files-table/files-table.component";
import {MaterialModule} from "../../../material.module";
import {PdfViewerComponent} from "./components/pdf-viewer/pdf-viewer.component";
import {IfcViewerComponent} from "./components/ifc-viewer/ifc-viewer.component";
import {BicIfcViewerModule} from "@buildinginformationcloud/bic-ifc-viewer";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule} from "@angular/forms";
import {ClassificationResultPipe} from "../../../pipes/classification-result.pipe";
import {AppModule} from "../../../app.module";
import {DocumentOperationPipe} from "../../../pipes/document-operation.pipe";
import {TagComponent} from "./components/tag/tag.component";
import {DragAndDropDirective} from "../../../directives/drag-and-drop.directive";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CdkTableModule} from "@angular/cdk/table";
import {DesignModule} from "../design/design.module";
import {NgxFileDropModule} from "ngx-file-drop";
import {MatIconButtonSizesModule} from "mat-icon-button-sizes";
import {DashboardModule} from "../../dashboard/dashboard.module";
import {FolderPipe} from "../../../pipes/folder.pipe";
import { CopyFilesDialogComponent } from './components/copy-files-dialog/copy-files-dialog.component';
import {BicUiComponentsModule} from "@buildinginformationcloud/bic-ui-components";


@NgModule({
  declarations: [
    FilesComponent,
    FilesTableComponent,
    FilesUploadErrorDialogComponent,
    MoveFilesDialogComponent,
    PdfViewerComponent,
    IfcViewerComponent,
    ClassificationResultPipe,
    DocumentOperationPipe,
    TagComponent,
    DragAndDropDirective,
    CopyFilesDialogComponent,
  ],
    imports: [
        CommonModule,
        MatIconModule,
        MaterialModule,
        BicIfcViewerModule,
        NgxExtendedPdfViewerModule,
        FormsModule,
        DesignModule,
        FontAwesomeModule,
        CdkTableModule,
        NgxFileDropModule,
        MatIconButtonSizesModule,
        DashboardModule,
        FolderPipe,
        BicUiComponentsModule

    ],
    exports: [
        FilesComponent,
        FilesTableComponent,
        FilesUploadErrorDialogComponent,
        MoveFilesDialogComponent,
        PdfViewerComponent,
        IfcViewerComponent,
        DocumentOperationPipe,
        DragAndDropDirective,
        TagComponent,
    ],
  providers: [
    ClassificationResultPipe,
  ]
})
export class FilesModule {
}
