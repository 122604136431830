import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {

  @Output() deleted: EventEmitter<void> = new EventEmitter();
  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
