import {inject, Injectable} from '@angular/core';
import {StateConfigService} from "./state/state-config.service";
import {CanActivateFn, Router} from '@angular/router';
import {Observable} from "rxjs";
import {StateControllerService} from "../api";

@Injectable()
export class FeatureFlagGuardService {

  constructor(private router: Router, private stateService: StateConfigService) { }

  canActivate(flagName: string, redirectRoute: string): boolean {
    return true
  }
}

export function featureFlagGuard(
  flagName: string,
  redirectRoute: string
): CanActivateFn {
  return () => {
    const stateService: StateConfigService =
      inject(StateConfigService);
    const stateControllerService = inject(StateControllerService);
    const router: Router = inject(Router);
    return new Observable<boolean>(observer => {
      stateControllerService.getFeatureConfigState(stateService.getOrganisationId()).subscribe( featureConfig => {
        return featureConfig.swmCheck ? observer.next(true) : router.navigate(['/dashboard']);
      })
    });

  };
}
