/**
 * BIC Quality API
 * Quality Services API - Use this API to access the Quality Services of the Building Information Cloud (BIC).
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type FileType = 'IFC' | 'LV' | 'RB';

export const FileType = {
    IFC: 'IFC' as FileType,
    LV: 'LV' as FileType,
    RB: 'RB' as FileType
};