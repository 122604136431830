import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, transferArrayItem} from "@angular/cdk/drag-drop";
import {Task, TaskControllerService} from "../../../api";
import StateEnum = Task.StateEnum;
import {ToolbarService} from "../../util/design/toolbar/toolbar.service";
import {TaskDetailsService} from "../../../services/task-details.service";
import {StateConfigService} from "../../../services/state/state-config.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TaskStatePipe} from "../../../pipes/task-state.pipe";
import {faPlus, faX} from "@fortawesome/free-solid-svg-icons";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import PriorityEnum = Task.PriorityEnum;
import {initFlowbite} from "flowbite";

interface TasksState {
  tasks: Task[],
  state: StateEnum,
}

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss']
})
export class TaskBoardComponent implements OnInit {

  @Input() projectId: number | undefined;
  @Output() importBCFClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportBCFClicked: EventEmitter<Task> = new EventEmitter<Task>();
  @Output() exportBCFsClicked: EventEmitter<Task[]> = new EventEmitter<Task[]>();

  tasks: Map<string, Task[]> = new Map<Task.StateEnum, Task[]>()

  newTaskForm!: UntypedFormGroup

  stateEnum: typeof StateEnum = StateEnum;

  selectedTasks: Task[] = []

  constructor(private snackBar: MatSnackBar,
              private taskControllerService: TaskControllerService,
              private toolbar: ToolbarService, private taskDetailService: TaskDetailsService,
              public stateConfigService: StateConfigService, public formBuilder: UntypedFormBuilder) {
    toolbar.config = {
      add: {
        disabled: () => false,
        routerLink: ['/tasks/create']
      }
    }
  }

  ngOnInit(): void {
    initFlowbite()

    this.newTaskForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      informedUsers: new UntypedFormControl([]),
      project: new UntypedFormControl(),
      projectID: new UntypedFormControl(),
      state: new UntypedFormControl(StateEnum.OPEN, Validators.required),
      lph: new UntypedFormControl(),
      priority: new UntypedFormControl(PriorityEnum.NORMAL), // New property
      ifcName: new UntypedFormControl(),
      ifcLink: new UntypedFormControl(),
      markup: this.formBuilder.group({
        id: new UntypedFormControl(),
        comment: this.formBuilder.array([]),
        topic: this.formBuilder.group({
          atGuid: new UntypedFormControl(""),
          atTopicType: new UntypedFormControl(),
          atTopicStatus: new UntypedFormControl(),
          referenceLink: new UntypedFormControl(),
          title: new UntypedFormControl(''),
          priority: new UntypedFormControl(),
          index: new UntypedFormControl(),
          labels: new UntypedFormControl(),
          creationDate: new UntypedFormControl(),
          creationAuthor: new UntypedFormControl(),
          modifiedDate: new UntypedFormControl(),
          modifiedAuthor: new UntypedFormControl(),
          dueDate: new UntypedFormControl(),
          startDate: new UntypedFormControl(),
          assignedTo: new UntypedFormControl(),
          description: new UntypedFormControl(),
          creationUser: new UntypedFormControl(),
          modifiedUser: new UntypedFormControl(),
          assignedToUser: new UntypedFormControl(),
        }),
      }),
    });
    this.refreshList();
  }

  taskStateFrom(tasks: Task[], state: StateEnum): TasksState {
    return {tasks: tasks, state: state};
  }

  refreshList() {
    this.taskDetailService.clearOpenedTask();
    this.taskControllerService.getTasks(this.stateConfigService.getProject()?.id, this.stateConfigService.selectedOrganisation?.id, this.stateConfigService.isPersonal).subscribe(tasks => {
      // tasks.forEach(t=>{t.state = this.stateEnum.OPEN})
      for (let stateEnumKey in this.stateEnum) {
        this.tasks.set(stateEnumKey, tasks.filter(t => t.state == stateEnumKey))
      }
      this.taskDetailService.currentTaskChanged.subscribe(task => {
        if (task) {
          let listForNewTask = this.tasks.get(task.state!);
          listForNewTask?.push(task);
          this.tasks.set(task.state!, listForNewTask!);
          this.taskChanged(task)
        }
      });
      this.taskDetailService.currentTaskDeleted.subscribe(task => {
        if (task) {
          let listForNewTask = this.tasks.get(task.state!);
          listForNewTask = listForNewTask?.filter(t => t.id !== task.id);
          this.tasks.set(task.state!, listForNewTask!);
        }
      });
    });
  }

  taskChanged(task: Task) {
    for (const state of this.tasks.keys()) {
      let taskValues = this.tasks.get(state);
      if (taskValues?.find(t => t.id === task.id)) {
        taskValues = taskValues!.filter(t => t.id !== task.id);
        this.tasks.set(state, taskValues!)
      }
    }

    // Den Task zur Liste mit dem neuen Status hinzufügen
    const newState = task.state ? task.state : StateEnum.OPEN;
    const newTaskValues = this.tasks.get(newState) || [];
    newTaskValues.push(task);
    this.tasks.set(newState, newTaskValues);
  }

  createNewTask() {
    this.taskDetailService.setOpenedTask(undefined);
  }


  drop(event: CdkDragDrop<TasksState, TasksState>) {
    transferArrayItem(
      event.previousContainer.data.tasks,
      event.container.data.tasks,
      event.previousIndex,
      event.currentIndex);

    event.container.data.tasks[event.currentIndex].state = event.container.data.state;
    this.taskControllerService.updateTask(event.container.data.tasks[event.currentIndex], "", this.stateConfigService.getProjectId() ?? (event.container.data.tasks[event.currentIndex].projectID ?? -1)).subscribe(
      value => {
        this.ngOnInit();
      });
  }

  tasksArray(): Task[] {
    let tasks: Task[] = [];
    for (let stateEnumKey in this.stateEnum) {
      if (this.tasks.has(stateEnumKey)) {
        tasks.push(...this.tasks.get(stateEnumKey)!)
      }
    }

    return tasks;
  }

  keepOrder = (a: any, b: any) => {
    return a;
  }

  selectTask(task: Task){
    const index: number = this.selectedTasks.indexOf(task);
    if (index !== -1) {
      this.selectedTasks.splice(index, 1);
    } else {
      this.selectedTasks.push(task)
    }
  }

  isTaskSelected(task:Task){
    return this.selectedTasks.includes(task)
  }

  protected readonly TaskStatePipe = TaskStatePipe;
  protected readonly faPlus = faPlus;
  protected readonly faX = faX;
}
