import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PopUpWindowComponent} from "./pop-up-window/pop-up-window.component";
import {Organisation} from "../../../api";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {

  @Input() header: string = "Header";
  @Input() message: string = "Message";
  @Input() approveButtonName: string = "Button";
  @Input() holdable: boolean = false;

  @Output () approvedPressed = new EventEmitter<void>();
  @Output () declinedPressed = new EventEmitter<void>();

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    let dialogRef = this.dialog.open(PopUpWindowComponent, {
      panelClass:"rounded-corners-dialog",
      data: {
        header: this.header,
        message: this.message,
        approveButtonName: this.approveButtonName,
        holdable: this.holdable
      },
      hasBackdrop: false,
      disableClose: true,
      width: '50%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approvePressed) {
        this.approvedPressed.emit();
      } else {
        this.declinedPressed.emit();
      }
    });
  }

}
