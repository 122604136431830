
  <cdk-table [dataSource]="dataSource" matSort #filesTbSort="matSort" class="overflow-y-scroll h-full w-full">
    <ng-container matColumnDef="select">
      <cdk-header-cell *matHeaderCellDef [class.w-[118px]]="!isQualityView" [class.w-[42px]]="isQualityView || isBim">
        <div class="flex flex-row p-3">
          <div [matTooltip]="selectedFiles.hasValue() && isAllSelected() ? 'Alle abwählen' : 'Alle auswählen'"
               class="w-[18px] h-[18px] overflow-hidden flex items-center justify-center">
            <div>
              <mat-checkbox (change)="$event ? toggleAllRows() : null"
                            [checked]="selectedFiles.hasValue() && isAllSelected()"
                            [indeterminate]="selectedFiles.hasValue() && !isAllSelected()"
                            [disabled]="viewOnly">
              </mat-checkbox>
            </div>
          </div>
        </div>
      </cdk-header-cell>

      <cdk-cell (click)="$event.stopPropagation()" *matCellDef="let row" [class.w-[118px]]="!isQualityView"
                [class.w-[42px]]="isQualityView || isBim">
        <div class="flex flex-row p-3">
          <div class="w-[18px] h-[18px] overflow-hidden flex items-center justify-center">
            <div>
              <mat-checkbox *ngIf="!versioningSingleFile || row.documentOperation != 'DELETE'"
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selectedFiles.toggle(row) : null"
                            [checked]="selectedFiles.isSelected(row)"
                            [disabled]="viewOnly || row.uploadFailed"
                            [class]="hoveredRow == row || selectedFiles.isSelected(row) ? 'visible' : 'invisible'">
              </mat-checkbox>
              <mat-checkbox *ngIf="versioningSingleFile && row.documentOperation == 'DELETE'" (click)="$event.stopPropagation()"
                            (change)="$event ? selectedFiles.toggle(row) : null"
                            [checked]="selectedFiles.isSelected(row) && isAllSelected()" [disabled]="true"
                            [disabled]="viewOnly || row.uploadFailed"
                            [class]="hoveredRow == row || selectedFiles.isSelected(row) ? 'visible' : 'invisible'">
              </mat-checkbox>
            </div>
          </div>
        </div>
      </cdk-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <cdk-header-cell *matHeaderCellDef [class.w-[60%]]="isQualityView || isBim" class="w-[55%] text-gray-500 text-xs" mat-sort-header>Name
      </cdk-header-cell>
      <cdk-cell (click)="element.renaming ? $event.stopPropagation(): {}" *matCellDef="let element" [class.w-[60%]]="isQualityView || isBim"
                class=" text-gray-900 text-sm w-[55%]">


        <ng-container *ngIf="!element.renaming" >
          <div class="break-all pr-2">
            {{element.name}}
          </div>
        </ng-container>

        <ng-container *ngIf="element.renaming">
          <mat-form-field appearance="outline">
            <input type="text" value="{{element.name}}" #rename>
          </mat-form-field>
          <button mat-icon-button
                  (click)="element.renaming = false; renameFile.emit({newName: rename.value, file: element})">
            <mat-icon>check</mat-icon>
          </button>
        </ng-container>

      </cdk-cell>
    </ng-container>




    <ng-container matColumnDef="size">
      <cdk-header-cell *matHeaderCellDef [class.w-[15%]]="!isQualityView" [class.w-[18%]]="isQualityView || isBim"
                       class="flex flex-row justify-start items-start text-gray-500 text-xs" mat-sort-header>Größe (MB)
      </cdk-header-cell>
      <cdk-cell *matCellDef="let element" [class.w-[15%]]="!isQualityView"
                [class.justify-center]="isQualityView || isBim" [class.w-[18%]="isQualityView || isBim"
                class="flex flex-row justify-start items-start text-gray-500 text-sm">
        {{calculateSizeInMB(element.size) | number: '.0-2'}}
      </cdk-cell>
    </ng-container>

    <ng-container matColumnDef="tags">
      <cdk-header-cell *matHeaderCellDef mat-sort-header>Tags</cdk-header-cell>
      <cdk-cell *matCellDef="let element">

      </cdk-cell>
    </ng-container>

    <ng-container *ngIf="versioningSingleFile" matColumnDef="operation">
      <cdk-header-cell *matHeaderCellDef>Operation</cdk-header-cell>
      <cdk-cell *matCellDef="let element; let i = index">
        {{element.documentOperation | documentOperation}}
      </cdk-cell>
    </ng-container>

    <ng-container matColumnDef="lastModified">
      <cdk-header-cell *matHeaderCellDef [class.w-[15%]]="!isQualityView" [class.w-[26%]]="isQualityView"
                       class="flex flex-row justify-start items-start break-all text-gray-500 text-xs" mat-sort-header>Zuletzt geändert
      </cdk-header-cell>
      <cdk-cell *matCellDef="let element" [class.w-[15%]]="!isQualityView"
                [class.justify-center]="isQualityView" [class.w-[26%]]="isQualityView"
                class="flex flex-row justify-start items-start text-gray-900 text-sm">
        <ng-container *ngIf="(element.lastModified | date: 'dd.MM.yyyy') == '01.01.1970'">
          -
        </ng-container>
        <ng-container *ngIf="(element.lastModified | date: 'dd.MM.yyyy') != '01.01.1970'">
          {{element.lastModified | date: 'dd.MM.yyyy HH:mm'}}
        </ng-container>
      </cdk-cell>
    </ng-container>

    <ng-container matColumnDef="lp">
      <cdk-header-cell *matHeaderCellDef [class.justify-center]="isQualityView || isBim"
                       class="flex flex-row w-[25%] justify-start items-start break-all"
                       mat-sort-header>Leistungsphase
      </cdk-header-cell>
      <cdk-cell *matCellDef="let element" [class.justify-center]="isQualityView || isBim"
                class="flex flex-row w-[25%] justify-start items-start">
        {{ element.lph }}
      </cdk-cell>
    </ng-container>

    <ng-container matColumnDef="actions" class="mat-column-actions">
      <cdk-header-cell *matHeaderCellDef aria-label="row actions" class="w-[10%]">&nbsp;</cdk-header-cell>
      <cdk-cell  *matCellDef="let element" (click)="$event.stopPropagation()" class="w-[10%]">
        <div class="flex flex-row justify-end items-center">
          <ng-container *ngIf="!element.isFolder">
            <div *ngIf="isUploading(element)">
              {{percent(element)}} %
            </div>
            <app-cloud-number
              *ngIf="!element.analyseCompleted || this.runningDocumentClassification.includes(element.id)"
              [loading]="true"
              [analysisLoading]="true" matTooltip="Analyse läuft"></app-cloud-number>
          </ng-container>
          <ng-container *ngIf="element.isFolder">
            <app-cloud-number
              *ngIf="this.runningDocumentClassification.includes(element.id)"
              [loading]="true"
              [analysisLoading]="true" matTooltip="Analyse läuft"></app-cloud-number>
          </ng-container>

          <div matTooltip="Upload fehlgeschlagen">
            <button mat-icon-button disabled *ngIf="element.uploadFailed">
              <mat-icon class="icon-red">error</mat-icon>
            </button>
          </div>


          <button mat-icon-button (click)="onLeftClick($event, element)" [disabled]="moveDestinationSelection">
<!--            <mat-icon>more_vert</mat-icon>-->
            <fa-icon [icon]="faEllipsisVertical" class="text-gray-200"  ></fa-icon>
          </button>
        </div>
      </cdk-cell>
    </ng-container>

    <cdk-header-row *matHeaderRowDef="displayedColumns; sticky:true"
                    class="p-2 flex flex-row justify-start items-center w-full border-b-2 border-b-gray-400  hover:cursor-pointer bg-white"></cdk-header-row>

    <cdk-row  *matRowDef="let row; columns: isFileListView ? displayedColumnsFileView : displayedColumns;" (click)="row.isFolder ? openFolder(row): {}; selectRow(row)"
              (contextmenu)="onRightClick($event, row)"
              class="p-2 flex flex-row justify-start items-center w-full border-t-2 border-b-gray-400 hover:bg-gray-200 hover:cursor-pointer" (mouseover)="hoveredRow = row" (mouseleave)="hoveredRow = undefined"></cdk-row>
  </cdk-table>

<div style="visibility: hidden; position: fixed;" #rightClickMenuTrigger
     [style.left]="menuTopLeftPosition.x"
     [style.top]="menuTopLeftPosition.y"
     [matMenuTriggerFor]="rightMenu"></div>

<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-node="node">
    <button *ngIf="node != undefined && !node.isFolder" (click)="openFile(node)" mat-menu-item>
      Öffnen
    </button>
    <button *ngIf="node != undefined && !node.isFolder" (click)="downloadFile(node)" mat-menu-item>
      Herunterladen
    </button>
    <button *ngIf="node != undefined && !isPopup && !viewOnly"
            (click)="node.renaming = true" mat-menu-item>
      Umbenennen
    </button>
    <button *ngIf="node != undefined && !isPopup && !viewOnly"
            (click)="openMoveFilesDialog(node)" mat-menu-item >
      Verschieben
    </button>
    <button *ngIf="node != undefined && !isPopup && !viewOnly"
            (click)="openCopyFilesDialog(node)" mat-menu-item >
      Kopieren
    </button>

    <button *ngIf="node != undefined && !isPopup && !viewOnly"
            (click)="deleteFile.emit(node)" mat-menu-item
            style="color: red">
      Löschen
    </button>
  </ng-template>
</mat-menu>

<!--<bic-dropdown #rightMenu >-->
<!--  <ng-template let-node="node" >-->
<!--    &lt;!&ndash; Delete button inside dropdown &ndash;&gt;-->
<!--    <bic-dropdown-element *ngIf="node != undefined && !isPopup && !viewOnly" (click)="deleteFile.emit(node)" class="w-full text-sm" [textColor]="'red'">-->
<!--      Löschen-->
<!--    </bic-dropdown-element>-->
<!--  </ng-template>-->
<!--</bic-dropdown>-->


