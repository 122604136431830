<div class="text-xl font-medium m-4">
  Sicherheitskategorien Tool
</div>

<div class="flex flex-row justify-start items-start h-full gap-4 p-2">
  <div class="flex-grow">
    <form [formGroup]="formSafetyCategory">
      <div>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Was soll berechnet werden?</mat-label>
          <mat-select formControlName="calculationTypeSafetyCategory" required (selectionChange)="setMissingValue()">
            <mat-option [value]="true">Sicherheitskategorie</mat-option>
            <mat-option [value]="false">Zulässige Größe der Brandabschnittsflächen</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Anzahl der oberirdischen Geschosse</mat-label>
          <mat-select formControlName="numberOfFloors" required>
            <mat-option [value]="1">Erdgeschossig</mat-option>
            <mat-option [value]="2">Zweigeschossig</mat-option>
            <mat-option [value]="3">Dreigeschossig</mat-option>
            <mat-option [value]="4">Viergeschossig</mat-option>
            <mat-option [value]="5">Fünfgeschossig</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngIf="formSafetyCategory.value.numberOfFloors != ''">
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Feuerwiderstandsfähigkeit der tragenden und aussteifenden Bauteile</mat-label>
            <mat-select formControlName="fireResistance" required>
              <mat-option [value]="1" *ngIf="formSafetyCategory.value.numberOfFloors == 1">Aus nichtbrennbare
                Baustoffe
              </mat-option>
              <mat-option [value]="2" *ngIf="formSafetyCategory.value.numberOfFloors < 3">Feuerhemmend</mat-option>
              <mat-option [value]="3"
                          *ngIf="formSafetyCategory.value.numberOfFloors == 2 || formSafetyCategory.value.numberOfFloors == 3 ">
                Hochfeuerhemmend und aus nichtbrennbaren Baustoffen
              </mat-option>
              <mat-option [value]="4" *ngIf="formSafetyCategory.value.numberOfFloors > 1">Feuerbeständig und aus
                nichtbrennbaren Baustoffen
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container
        *ngIf="formSafetyCategory.value.calculationTypeSafetyCategory == true && formSafetyCategory.value.numberOfFloors != ''">
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Brandabschnittsfläche in m²</mat-label>
            <input formControlName="spaceSize" matInput
                   [required]="formSafetyCategory.value.calculationTypeSafetyCategory">
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container
        *ngIf="formSafetyCategory.value.calculationTypeSafetyCategory == false && formSafetyCategory.value.numberOfFloors != ''">
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Sicherheitskategorie</mat-label>
            <mat-select formControlName="safetyCategory"
                        [required]="!formSafetyCategory.value.calculationTypeSafetyCategory">
              <mat-option [value]="'K1'">K1</mat-option>
              <mat-option [value]="'K2'">K2</mat-option>
              <mat-option [value]="'K3.1'">K3.1</mat-option>
              <mat-option [value]="'K3.2'">K3.2</mat-option>
              <mat-option [value]="'K3.3'">K3.3</mat-option>
              <mat-option [value]="'K3.4'">K3.4</mat-option>
              <mat-option [value]="'K4'">K4</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </form>


    <div class="text-l font-medium" *ngIf="safetyCategory">
      Die Brandabschnitte fallen unter die Sicherheitskategorie {{safetyCategory}}!
      <div class="text-l font-medium">
        <ng-container *ngIf="safetyCategory == 'K1'">
          Brandabschnitte oder Brandbekämpfungsabschnitte ohne besondere Maßnahmen für Brandmeldung und
          Brandbekämpfung.
        </ng-container>
        <ng-container *ngIf="safetyCategory == 'K2'">
          Brandabschnitte oder Brandbekämpfungsabschnitte mit automatischer Brandmeldeanlage.
        </ng-container>
        <ng-container *ngIf="safetyCategory == 'K3.1'">
          Brandabschnitte oder Brandbekämpfungsabschnitte mit automatischer Brandmeldeanlage in Industriebauten mit
          Werkfeuerwehr in mindestens Staffelstärke. Diese Staffel muss aus hauptberuflichen Kräften bestehen.
        </ng-container>
        <ng-container *ngIf="safetyCategory == 'K3.2'">
          Brandabschnitte oder Brandbekämpfungsabschnitte mit automatischer Brandmeldeanlage in Industriebauten mit
          Werkfeuerwehr in mindestens Gruppenstärke.
        </ng-container>
        <ng-container *ngIf="safetyCategory == 'K3.3'">
          Brandabschnitte oder Brandbekämpfungsabschnitte mit automatischer Brandmeldeanlage in Industriebauten mit
          Werkfeuerwehr mit mindestens 2 Staffeln.
        </ng-container>
        <ng-container *ngIf="safetyCategory == 'K3.4'">
          Brandabschnitte oder Brandbekämpfungsabschnitte mit automatischer Brandmeldeanlage in Industriebauten mit
          Werkfeuerwehr mit mindestens 3 Staffeln.
        </ng-container>
        <ng-container *ngIf="safetyCategory == 'K4'">
          Brandabschnitte oder Brandbekämpfungsabschnitte mit selbsttätiger Feuerlöschanlage.
        </ng-container>
      </div>
    </div>

    <div class="text-l font-medium" *ngIf="maximalSpaceSize > 0">
      Die maximal zulässige Brandabschnittsfläche beträgt {{maximalSpaceSize}} m²!
    </div>

    <div class="text-l font-medium">
      <ng-container *ngIf="safetyCategoryHint.includes(1)">
        Breite des Industriebaus &le; 40 m und Wärmeabzugsfläche &ge; 5 %.
        <br>
      </ng-container>
      <ng-container *ngIf="safetyCategoryHint.includes(2)">
        Wärmeabzugsfläche &ge; 5 %.
        <br>
      </ng-container>
      <ng-container *ngIf="safetyCategoryHint.includes(3)">
        Für Gebäude der Gebäudeklassen 3 und 4 ergibt sich nach § 27 Abs. 1 Satz 2 Nr. 2 und 3 i. V. m. § 30 Abs. 2
        Nr. 2 MBO eine zulässige Größe von 1 600 m².
        <br>
      </ng-container>
      <ng-container *ngIf="safetyCategoryHint.includes(4)">
        Die zulässige Größe darf um 10 % überschritten werden, wenn in dem Brandabschnitt die Produktions- und
        Lagerräume Rauchabzugsanlagen haben, bei denen
        <mat-list>
          <mat-list-item>je höchstens 200 m² der Grundfläche ein oder mehrere Rauchabzugsgeräte mit insgesamt
            mindestens 1,5 m² aerodynamisch wirksamer Fläche im Dach angeordnet wird,
          </mat-list-item>
          <mat-list-item>Zuluftflächen mit einem freien Querschnitt von mindestens 36 m² im unteren Raumdrittel
            vorhanden sind sowie
          </mat-list-item>
          <mat-list-item>die Anforderungen der Nrn. 5.7.4.3 und 5.7.4.4 erfüllt sind.</mat-list-item>
        </mat-list>
      </ng-container>
    </div>

  </div>
</div>


<div class="flex flex-row justify-start items-start gap-4 p-2">
  <div class="flex-grow">
    <table mat-table [dataSource]="dataSourceWGK">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <ng-container *ngIf="!element.isEdit">
            {{element.name}}
          </ng-container>

          <ng-container *ngIf="element.isEdit">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Stoffname</mat-label>
              <input type="text"
                     aria-label="Stoffname"
                     matInput
                     [formControl]="myControl"
                     [matAutocomplete]="auto"
                     [(ngModel)]="nameInput"
                     [value]="element.name"
              >
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name"
                            (onSelectionChange)="setSelectedElementAsItem(option, i)">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>

        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Menge in t</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.isEdit">
            <mat-form-field appearance="outline">
              <mat-label>Menge</mat-label>
              <input matInput type="number" [value]="element.amount" (change)="tempItem.amount = $event.target.value"
                     (keyup)="tempItem.amount = $event.target.value">
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!element.isEdit">
            {{element.amount}}
          </ng-container>

        </td>
        <td mat-footer-cell *matFooterCellDef>
          <div class="flex flex-col gap-1 items-start justify-start">
            <div>Summe WGK1: <span class="font-medium">{{calculateSumAmount(1)}}t</span></div>
            <div>Summe WGK2: <span class="font-medium">{{calculateSumAmount(2)}}t</span></div>
            <div>Summe WGK3: <span class="font-medium">{{calculateSumAmount(3)}}t</span></div>
          </div>

        </td>
      </ng-container>
      <ng-container matColumnDef="WGK">
        <th mat-header-cell *matHeaderCellDef> WGK</th>
        <td mat-cell *matCellDef="let element"> {{element.wgk}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell>Aktionen</th>
        <td *matCellDef="let element; let i = index" mat-cell>
          <ng-container *ngIf="!currentEditing">
            <ng-container *ngIf="element.isEdit">
              <ng-container *ngIf="element.name == ''">
                <button mat-icon-button matTooltip="Bestätigen"
                        [disabled]="!(tempItem.amount != 0 && tempItem.amount != undefined && element.wgk != undefined)"
                        (click)="addWGKEntry()">
                  <mat-icon>check</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="element.name != ''">
                <button mat-icon-button matTooltip="Bestätigen"
                        [disabled]="!(tempItem.amount != 0 && tempItem.amount != undefined && element.wgk != undefined)"
                        (click)="editWGKEntry(i)">
                  <mat-icon>check</mat-icon>
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!element.isEdit">
              <button mat-icon-button matTooltip="Bearbeiten" (click)="setEditable(i)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button matTooltip="Löschen" (click)="deleteWGKEntry(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="currentEditing && element.isEdit">
            <button mat-icon-button matTooltip="Bestätigen"
                    [disabled]="!(tempItem.amount != 0 && tempItem.amount != undefined && element.wgk != undefined)"
                    (click)="editWGKEntry(i)">
              <mat-icon>check</mat-icon>
            </button>
          </ng-container>


        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsWGK"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsWGK;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumnsWGK"></tr>
    </table>

    <div class="text-l font-medium mt-2" *ngIf="wgkAmount">
      <ng-container *ngIf="wgkAmount < 1">
        Die Menge der Stoffe unterschreitet den Grenzwert der Löschwasserrückhalterichtlinie.
      </ng-container>
      <ng-container *ngIf="wgkAmount >= 1">
        Die Menge der Stoffe überschreitet den Grenzwert der Löschwasserrückhalterichtlinie.
      </ng-container>
    </div>
  </div>
</div>
