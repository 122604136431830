/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClippingPlanes } from './clippingPlanes';
import { Components } from './components';
import { OrthogonalCamera } from './orthogonalCamera';
import { PerspectiveCamera } from './perspectiveCamera';

export interface VisualizationInfo { 
    atGuid?: string;
    components?: Components;
    perspectiveCamera?: PerspectiveCamera;
    orthogonalCamera?: OrthogonalCamera;
    clippingPlanes?: ClippingPlanes;
    visualizationInfoExtra?: string;
}