/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ProjectFile } from './projectFile';
import { User } from './user';

export interface BIMBaseCheck { 
    id?: string;
    uuid?: string;
    checkDate?: Date;
    projectFile?: ProjectFile;
    checker?: User;
    results?: string;
    completed?: boolean;
    lph?: BIMBaseCheck.LphEnum;
    projectID?: number;
    checkType?: BIMBaseCheck.CheckTypeEnum;
}
export namespace BIMBaseCheck {
    export type LphEnum = 'GENERAL' | 'LPH1' | 'LPH2' | 'LPH3' | 'LPH4' | 'LPH5' | 'LPH6' | 'PLANS';
    export const LphEnum = {
        GENERAL: 'GENERAL' as LphEnum,
        LPH1: 'LPH1' as LphEnum,
        LPH2: 'LPH2' as LphEnum,
        LPH3: 'LPH3' as LphEnum,
        LPH4: 'LPH4' as LphEnum,
        LPH5: 'LPH5' as LphEnum,
        LPH6: 'LPH6' as LphEnum,
        PLANS: 'PLANS' as LphEnum
    };
    export type CheckTypeEnum = 'QUALITY' | 'HOAI' | 'IFC' | 'BASE' | 'SWM';
    export const CheckTypeEnum = {
        QUALITY: 'QUALITY' as CheckTypeEnum,
        HOAI: 'HOAI' as CheckTypeEnum,
        IFC: 'IFC' as CheckTypeEnum,
        BASE: 'BASE' as CheckTypeEnum,
        SWM: 'SWM' as CheckTypeEnum
    };
}