/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Privilege } from './privilege';

export interface Role { 
    id?: string;
    name?: string;
    privileges?: Array<Privilege>;
    type?: Role.TypeEnum;
    emailName?: string;
}
export namespace Role {
    export type TypeEnum = 'PROJECT' | 'ORGANISATION' | 'CUSTOM';
    export const TypeEnum = {
        PROJECT: 'PROJECT' as TypeEnum,
        ORGANISATION: 'ORGANISATION' as TypeEnum,
        CUSTOM: 'CUSTOM' as TypeEnum
    };
}