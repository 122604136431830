import {AfterViewInit, Component, ElementRef, Inject, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {
  FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators
} from "@angular/forms";

import { ActivatedRoute, Router } from "@angular/router";
import {MatRipple} from "@angular/material/core";
import {Observable, Subject, Subscription} from "rxjs";
import StateEnum = Project.StateEnum;
import LphEnum = ProjectServiceNotification.LphEnum;
import {
  AuthControllerService,
  Organisation, OrganisationControllerService, Parameter,
  Project,
  ProjectControllerService,
  ProjectServiceNotification, ProjectSimpleDto, QualityControllerService, ServiceControllerService, SimplePlace,
  User, UserFullDto
} from "../../api";
import {CanDeactivateComponent} from "../../guards/unsaved-changes.guard";
import {SidebarService} from "../../services/sidebar/sidebar.service";
import {PermissionService} from "../../services/permission/permission.service";
import {ToolbarService} from "../util/design/toolbar/toolbar.service";
import {StateConfigService} from "../../services/state/state-config.service";
import {LoadingService} from "../../services/loading/loading.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {faChevronRight, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons";
import {ProjectStateReversePipe} from "../../pipes/project-state.pipe";
import CountryEnum = ProjectSimpleDto.CountryEnum;
import {KeyValue} from "@angular/common";
import SalutationEnum = User.SalutationEnum;
import TitleEnum = User.TitleEnum;

interface State {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-projectSettings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettings implements OnInit, CanDeactivateComponent {
  @ViewChild("fileDropRef", {static: false}) fileDropEl!: ElementRef;

  //form groups for new view
  generalInfoForm!: FormGroup;
  addressInfoForm!: FormGroup;
  builderInfoForm!: FormGroup;
  qualitySettingsInfoForm!: FormGroup;

  salutation: typeof SalutationEnum = SalutationEnum;
  title: typeof TitleEnum = TitleEnum;

  locationGuesses$!: Observable<SimplePlace[]>;
  // states: typeof StateEnum = StateEnum;
  countries: typeof CountryEnum = CountryEnum;


  //old form groups
  ownerForm!: FormGroup;
  generalForm!: FormGroup;
  placeForm!: FormGroup;
  timeForm!: FormGroup;

  qualityParams: Parameter[][] = [];
  qualityValue: any = {};

  project!: Project;
  user!: User;
  projectId!: number;
  organisations: Organisation[] = [];

  create!: boolean;
  isHovering!: boolean;
  files: Map<string, File[]> = new Map<string, File[]>();
  image: File[] = [];
  editImagePermission: boolean = true;

  states: typeof StateEnum = Project.StateEnum;
  projectKinds: typeof Project.ProjectKindEnum = Project.ProjectKindEnum;

  phasesSelected: Map<string, boolean> = new Map<string, boolean>();

  unsubscribe = new Subject<void>()

  valueAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return a.value.localeCompare(b.value);
  }

  constructor(private router: Router, private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private authControllerService: AuthControllerService,
              private projectControllerService: ProjectControllerService,
              private organisationControllerService: OrganisationControllerService,
              public sidebarService: SidebarService,
              public permissionService: PermissionService, public dialog: MatDialog,
              public toolbar: ToolbarService, private snackbar: MatSnackBar,
              public stateConfigService: StateConfigService, public loadingService: LoadingService,
              public serviceControllerService: ServiceControllerService,
              public qualityControllerService: QualityControllerService) {

    for (const lph in LphEnum) {
      this.phasesSelected.set(lph, false);
      this.files.set(lph, []);
    }
  }


  disableForm(): boolean {
    if(this.create) {
      return false;
    }

    return this.generalForm.disabled && this.placeForm.disabled && this.timeForm.disabled;
  }

  validForm(): boolean {
    if(this.create) {
      return true;
    }
    return (this.generalForm.valid || this.generalForm.disabled) && (this.placeForm.valid || this.placeForm.disabled) && (this.timeForm.valid || this.timeForm.disabled);
  }

  canDeactivate(): boolean {
    if (this.create) {
      return true;
    }
    return this.generalForm.pristine && this.placeForm.pristine && this.timeForm.pristine;
  }

  ngOnInit(): void {

    this.organisationControllerService.getOrganisations().subscribe(organisations => {
      this.organisations = organisations;
    })

    this.authControllerService.profile().subscribe(user => {
      this.user = user;
    });

    this.qualityControllerService.getRules1().subscribe(rules => {
      let params: Parameter[] = rules.map(rule => rule.parameters??[]).flat();
      params = params.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
      // fill array with arrays of 2 elements
      this.qualityParams = Array.from({length: Math.ceil(params.length / 2)}, (_, i) => params.slice(i * 2, i * 2 + 2));
      // console.log(this.qualityParams)
      // console.log(rules)
    });

    this.route.parent!.params.subscribe(params => {
      this.create = params.id === undefined;
      this.projectId = params.id;

      // new form inits
      if(!this.create) {

        // new form inits
        this.generalInfoForm = this.formBuilder.group({
          name: new UntypedFormControl("", [Validators.required]),
          ownerOrganisation: new FormControl({value: this.stateConfigService.selectedProject.owner, disabled: true}),
          projectIdIntern: new FormControl(undefined),
          projectKind: new UntypedFormControl(undefined),
          isBIM: new UntypedFormControl(false, [Validators.required]),
        });

        this.addressInfoForm = this.formBuilder.group({
          street: new UntypedFormControl(""),
          houseNumber: new UntypedFormControl(""),
          plotNumber: new UntypedFormControl(""),
          zipCode: new UntypedFormControl("", [Validators.required]),
          buildingPlace: new UntypedFormControl("", [Validators.required]),
          country: new UntypedFormControl("", [Validators.required]),
          state: new UntypedFormControl("", [Validators.required]),
        });


        this.builderInfoForm = this.formBuilder.group({
          builderSalutation: new UntypedFormControl(undefined),
          //builderTitle: new UntypedFormControl({value: this.stateConfigService.selectedProject.builderTitle},[Validators.required]),
          builderFirstName: new UntypedFormControl({value: this.stateConfigService.selectedProject.builderFirstName}),
          builderLastName: new UntypedFormControl({value: this.stateConfigService.selectedProject.builderLastName}),
          //builderZipCode: new UntypedFormControl({value: this.stateConfigService.selectedProject.builderZipCode},[Validators.required]),
          //builderCity: new UntypedFormControl({value: this.stateConfigService.selectedProject.builderCity},[Validators.required]),
          //builderCountry: new UntypedFormControl({value: this.stateConfigService.selectedProject.builderCountry},[Validators.required]),
          //builderState: new UntypedFormControl({value: this.stateConfigService.selectedProject.builderState},[Validators.required]),
          beginPlanning: new UntypedFormControl(undefined),
          endPlanning: new UntypedFormControl(undefined),
          startExecuting: new UntypedFormControl(undefined),
          endExecuting: new UntypedFormControl(undefined),
          software: new UntypedFormControl(undefined),
        });

        this.qualitySettingsInfoForm = this.formBuilder.group({
          workingPosture: new UntypedFormControl(""),
          workingLoad: new UntypedFormControl(""),
          numberOfEmployees: new UntypedFormControl("", [Validators.pattern(/^(10000|[0-9]{1,4})$/)]),
          groundwater_level: new UntypedFormControl("", [Validators.pattern(/^[-+]?([0]|[1-9][0-9]*)([,.][0-9]+)?$/)]),
          isFreestanding: new UntypedFormControl(""),
        });
      }



        // old form inits
        this.ownerForm = this.formBuilder.group({
          ownerOrganisation: new FormControl(undefined, [Validators.required]),
        })

        this.generalForm = this.formBuilder.group({
          id: new FormControl(),
          name: new UntypedFormControl("", [Validators.required]),
          software: new UntypedFormControl(undefined),
          projectIdIntern: new UntypedFormControl(undefined),
          projectKind: new UntypedFormControl(undefined),
          isBIM: new UntypedFormControl(false, [Validators.required])
        })

        this.placeForm = this.formBuilder.group({
          buildingPlace: new UntypedFormControl("", [Validators.required]),
          plotNumber: new UntypedFormControl(""),
          street: new UntypedFormControl(""),
          zipCode: new UntypedFormControl("", [Validators.required]),
          houseNumber: new UntypedFormControl(""),
          country: new UntypedFormControl("", [Validators.required]),
          state: new UntypedFormControl(undefined, [Validators.required]),
        })

        this.timeForm = this.formBuilder.group({
          beginPlanning: new UntypedFormControl(undefined),
          endPlanning: new UntypedFormControl(undefined),
          startExecuting: new UntypedFormControl(undefined),
          endExecuting: new UntypedFormControl(undefined),
        });


      if (!this.create) {

        //new forms
        this.generalInfoForm.patchValue(this.stateConfigService.selectedProject);
        this.addressInfoForm.patchValue(this.stateConfigService.selectedProject);
        this.builderInfoForm.patchValue(this.stateConfigService.selectedProject);
        this.qualitySettingsInfoForm.patchValue(this.stateConfigService.selectedProject);

        //old forms
        this.generalForm.patchValue(this.stateConfigService.selectedProject)
        this.placeForm.patchValue(this.stateConfigService.selectedProject)
        this.timeForm.patchValue(this.stateConfigService.selectedProject)
        this.project = this.stateConfigService.selectedProject;
        this.qualityValue = JSON.parse(this.stateConfigService.selectedProject.qualitySettings ?? "{}");

        //new forms
        this.generalInfoForm.valueChanges.subscribe(value => {
          this.generalInfoForm.markAsDirty();
          if (this.generalInfoForm.invalid) {
            this.snackbar.open("Bitte füllen Sie alle Pflichtfelder aus", "OK", {duration: 2000});
          }
        });

        this.addressInfoForm.valueChanges.subscribe(value => {
          this.addressInfoForm.markAsDirty();
          if (this.addressInfoForm.invalid) {
            this.snackbar.open("Bitte füllen Sie alle Pflichtfelder aus", "OK", {duration: 2000});
          }
        });

        this.builderInfoForm.valueChanges.subscribe(value => {
          this.builderInfoForm.markAsDirty();
          if (this.builderInfoForm.invalid) {
            this.snackbar.open("Bitte füllen Sie alle Pflichtfelder aus", "OK", {duration: 2000});
          }
        });

        this.qualitySettingsInfoForm.valueChanges.subscribe(value => {
          this.qualitySettingsInfoForm.markAsDirty();
          if (this.qualitySettingsInfoForm.invalid) {
            if(this.qualitySettingsInfoForm.get('numberOfEmployees')?.errors){
              this.snackbar.open("Bitte geben Sie eine Zahl zwischen 0 und 10000 an.", "OK", {duration: 2000});
            }else if(this.qualitySettingsInfoForm.get('groundwater_level')?.errors){
              this.snackbar.open("Bitte geben Sie eine gültige Zahl an", "OK", {duration: 2000});
            }
          }
        });

        //old forms
        this.generalForm.valueChanges.subscribe(value => {
          this.generalForm.markAsDirty();
          if (this.generalForm.invalid) {
            this.snackbar.open("Bitte füllen Sie alle Pflichtfelder aus", "OK", {duration: 2000});
          }
        });

        this.placeForm.valueChanges.subscribe(value => {
          this.placeForm.markAsDirty();
          if (this.placeForm.invalid) {
            this.snackbar.open("Bitte füllen Sie alle Pflichtfelder aus", "OK", {duration: 2000});
          }
        });

        this.timeForm.valueChanges.subscribe(value => {
          this.timeForm.markAsDirty();
          if (this.timeForm.invalid) {
            this.snackbar.open("Bitte füllen Sie alle Pflichtfelder aus", "OK", {duration: 2000});
          }
        });

        if (!this.permissionService.hasProjectPrivilege('PROJECT_SETTINGS', this.stateConfigService.getProjectId() ?? 0)) {

          this.generalInfoForm.disable();
          this.addressInfoForm.disable();
          this.builderInfoForm.disable();
          this.qualitySettingsInfoForm.disable();
          this.generalForm.disable();
          this.placeForm.disable();
          this.timeForm.disable();
          this.editImagePermission = false;
        }

      }

      if(this.addressInfoForm){
        this.onCountryChange(this.addressInfoForm!.get('country')?.value);
        this.addressInfoForm.get('country')?.valueChanges.subscribe(country => {
          this.onCountryChange(country);
        });
      }
    });

  }

  onCountryChange(country: string) {
    const stateControl = this.addressInfoForm.get('state');
    if (country === 'DEU') {
      stateControl?.enable();
    } else {
      stateControl?.disable();
      stateControl?.reset();
    }
  }

  createProject(): void {
    this.project = {...this.project, ...this.generalForm.value, ...this.placeForm.value, ...this.timeForm.value};

    let owner = this.ownerForm.value["ownerOrganisation"] != -1 ? this.ownerForm.value["ownerOrganisation"] : undefined;

    this.projectControllerService.createProject({project: this.project, organisationId: owner}).subscribe(p => {
      this.project = p;
      this.generalForm.patchValue(p);
      this.placeForm.patchValue(p);
      this.timeForm.patchValue(p);

      this.router.navigateByUrl("/projects");
    });
  }

  save(): void {
    if(!this.create) {
      this.saveWith(true);
    }

  }

  get saveDisabled() {
    return this.generalInfoForm.invalid || this.addressInfoForm.invalid || this.builderInfoForm.invalid || this.qualitySettingsInfoForm.invalid;
  }

  saveWith(navigate: boolean): void {

    if (this.create) {
      this.project = {...this.project, ...this.generalForm.value, ...this.placeForm.value, ...this.timeForm.value};
    } else {
      this.project = {...this.project, ...this.generalInfoForm.value, ...this.addressInfoForm.value, ...this.builderInfoForm.value};
      this.project.qualitySettings = JSON.stringify(this.qualityValue)
    }


    if (this.create) {
      this.projectControllerService.updateProject(this.project, this.projectId).subscribe(project => {
        this.generalForm.markAsPristine();
        this.placeForm.markAsPristine();
        this.timeForm.markAsPristine();
        if (navigate) {
          this.router.navigateByUrl("/projects");
        } else {
          this.project = project;
          this.generalForm.patchValue(project);
          this.placeForm.patchValue(project);
          this.timeForm.patchValue(project);
          this.snackbar.open("Änderungen gespeichert", "OK", {
            duration: 1000,
          });
        }

      });
    } else {
      this.projectControllerService.updateProject(this.project, this.projectId).subscribe(project => {
        this.generalForm.markAsPristine();
        this.placeForm.markAsPristine();
        this.timeForm.markAsPristine();
        this.project = project;
        this.stateConfigService.selectedProject = project;
        this.snackbar.open("Änderungen gespeichert", "OK", {
          duration: 1000,
        });

      });
    }
  }

  get hasEditPermission(): boolean {
    if(this.stateConfigService.getOrganisationId() != 0 && this.stateConfigService.getOrganisationId() != null && !this.stateConfigService.isPersonal){
      if(this.permissionService.hasOrganisationPrivilege('ORGANISATION_PROJECT_EDIT')){
        return true;
      }else{
        return this.permissionService.hasProjectPrivilege('PROJECT_SETTINGS');
      }
    }else{
      return this.permissionService.hasProjectPrivilege('PROJECT_SETTINGS');
    }
  }

  cancel(): void {
    if (this.create) {
      this.router.navigateByUrl("/projects");
    } else {
      this.router.navigateByUrl("/projects/" + this.project.id);
    }

  }

  setTwoNumberDecimal(event: any) {
    return event;
  }

  openDeleteDialog() {
    let dialogRef = this.dialog.open(ProjectConfigurationDeleteDialog, {
      panelClass:"rounded-corners-dialog",
      data: {project: this.project}
    });
  }

  updateLocation(location: SimplePlace) {
    let projectStateReversePipe = new ProjectStateReversePipe()

    this.addressInfoForm.get("street")?.setValue(location.street);
    this.addressInfoForm.get("houseNumber")?.setValue(location.addressNumber);
    this.addressInfoForm.get("zipCode")?.setValue(location.postalCode);
    this.addressInfoForm.get("buildingPlace")?.setValue(location.municipality);
    this.addressInfoForm.get("country")?.setValue(location.country);
    if (location.region != null && location.country == "DEU") {
      this.addressInfoForm.get("state")?.enable();
      this.addressInfoForm.get("state")?.setValue(projectStateReversePipe.transform(location.region));
    }else{
      this.addressInfoForm.get("state")?.setValue(undefined);
      this.addressInfoForm.get("state")?.disable();
    }
  }

  validateNumberInput(input: HTMLInputElement, parameterValue: string): void {

    console.log(parameterValue)

    if (parameterValue === 'numberOfEmployees') {
      const numericValue = parseInt(input.value, 10);
      this.qualitySettingsInfoForm.get('numberOfEmployees')?.setValue(numericValue);
      console.log(this.qualitySettingsInfoForm.get('numberOfEmployees')?.value);
      if ((numericValue < 0 || numericValue > 10000)) {
        this.qualitySettingsInfoForm.get('numberOfEmployees')?.setErrors({range: true});
      }else if (input.value == '' || input.value == ' ') {
        this.qualitySettingsInfoForm.get('numberOfEmployees')?.setErrors(null);
      }
    } else if (parameterValue === 'groundwater_level') {
      const numericValue = parseFloat(input.value);
      this.qualitySettingsInfoForm.get('groundwater_level')?.setValue(numericValue);
      console.log(this.qualitySettingsInfoForm.get('groundwater_level')?.value);
      console.log(input.value)
      console.log(input.value.length)
      if (numericValue >= 1  && input.value.startsWith('0') || numericValue <= -1 && input.value.startsWith('-0') || input.value == '--') {
        this.qualitySettingsInfoForm.get('groundwater_level')?.setErrors({range: true});
      }else if (input.value == '' || input.value == ' ') {
        this.qualitySettingsInfoForm.get('groundwater_level')?.setErrors(null);
      }
    }
    this.qualityValue[parameterValue] = input.value;
  }

    // protected readonly faUserPlus = faUserPlus;
    // protected readonly faCircleQuestion = faCircleQuestion;
  protected readonly Parameter = Parameter;
  protected readonly User = User;
  protected readonly SalutationEnum = SalutationEnum;
  protected readonly TitleEnum = TitleEnum;
  protected readonly faChevronRight = faChevronRight;
}

@Component({
  selector: 'projectSettings-deleteDialog',
  templateUrl: './project-settings-delete-dialog.html',
  styleUrls: ['./project-settings.component.scss'],
})
export class ProjectConfigurationDeleteDialog implements OnInit {
  @ViewChild(MatRipple) ripple!: MatRipple;


  constructor(private router: Router, private projectControllerService: ProjectControllerService, @Inject(MAT_DIALOG_DATA) public data: {
    project: Project
  }, private dialogRef: MatDialogRef<ProjectConfigurationDeleteDialog>) {
  }

  holdHandler(e: any) {
    if (e === 3000) {
      this.dialogRef.close();
      //this.popupDeletionExchangeService.sendDeleteEvent(this.data.project);
      this.projectControllerService.deleteProject(this.data.project.id!).subscribe(next => {
        this.router.navigateByUrl("/projects");
      });
    }
  }

  ngOnInit() {
  }


}
