import {Pipe, PipeTransform} from '@angular/core';
import {User} from "../api";

/**
 * Filters Users by another list of users
 */
@Pipe({
  name: 'filterByList'
})
export class FilterByListPipe implements PipeTransform {

  /**
   * Filter method for pipe
   * @param users users
   * @param filterArray filter users
   */
  transform(users: User[] | undefined, filterArray: User[] | undefined): User[] | undefined {

    if (users && filterArray) {
      let temp: User[] | undefined = users

      filterArray?.forEach(p => {
        temp = temp?.filter(s => s.id !== p.id);
      })

      return temp;
    } else {
      return users
    }
  }
}
