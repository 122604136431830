import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth/auth.service";
import {AuthControllerService, BillingControllerService, Organisation,
  OrganisationControllerService, Price, Product, ProductSettings, User} from "../../api";
import {StateConfigService} from "../../services/state/state-config.service";
import {Subscription} from "rxjs";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

enum PriceType {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  ADDON = 'addon',
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private authControllerService: AuthControllerService,
    private billingControllerService: BillingControllerService,
    public stateConfigService: StateConfigService,
    public organisationControllerService: OrganisationControllerService,
    public dialog: MatDialog,
  ) {
  }

  user!: User;
  subscribedPrices: Price[] = [];
  prices: Price[] = [];
  subscribedProducts: Product[] = [];

  productSettings: ProductSettings[] = [];

  priceType: PriceType = PriceType.MONTHLY;

  updateSub: Subscription | undefined;
  organisations: Organisation[] = [];


  ngOnInit(): void {
    this.stateConfigService.selectProject(undefined, false);

    this.authControllerService.profile().subscribe(next => {
      this.user = next;

      this.organisationControllerService.getOrganisations().subscribe(organisations => {
        this.organisations = organisations;
      })
    });

    this.updateSub = this.stateConfigService.updated.subscribe(next => {
      this.update();
      this.updateSub?.unsubscribe();
    });
  }

  update() {
    this.billingControllerService.getPrices(this.stateConfigService.selectedOrganisation?.id ?? undefined).subscribe(next => {
      this.prices = next;

      this.billingControllerService.getSubscriptions(this.stateConfigService.selectedOrganisation?.id ?? undefined).subscribe(next => {
        this.subscribedPrices = next;

        this.subscribedProducts = this.subscribedPrices
          .map(price => price.productObject!);
      });
    });

    this.billingControllerService.getProducts().subscribe(next => {
      this.productSettings = next;
    });
  }

  productSettingsBy(price: Price) {
    return this.productSettings.find(setting => setting.id == price.product);
  }

  get mail() {
    return this.user.mail;
  }

  get abonnements() {
    return (this.priceType == PriceType.YEARLY ? this.abonnementsYearly : this.abonnementsMonthly)
      .sort((a, b) => a.unitAmount! - b.unitAmount!);
  }

  isSpecial(price: Price) {
    return price.active == false;
  }

  private get abonnementsYearly() {
    return this.prices.filter(price => price.type == 'recurring' && price.recurring?.interval == 'year');
  }

  private get abonnementsMonthly() {
    return this.prices.filter(price => price.type == 'recurring' && price.recurring?.interval == 'month');
  }


  get onetime() {
    return this.prices
      .filter(price => price.type == 'one_time')
      .sort((a, b) => a.unitAmount! - b.unitAmount!);
  }

  isActivated(price: Price) {
    return this.subscribedPrices.map(p => p.id).includes(price.id);
  }

  oneTimeBy(price: Price) {
    return this.onetime.find(p => p.product == price.product);
  }


  intervalBy(price: Price) {
    return price.recurring?.interval == 'year' ? 'Jährlich' : 'Monatlich';
  }

  intervalByProduct(product: Product) {

    const price = this.subscribedPrices.find(price => price.product == product.id);

    return this.intervalBy(price!);
  }

  get activeAbonnementName(): string {
    return this.subscribedProducts.map(product => `${product.name} (${this.intervalByProduct(product)})`).join(', ');
  }


  checkout(price: Price) {
    const dialogRef = this.dialog.open(CheckoutConfirmationDialog, {
      panelClass: 'rounded-corners-dialog',
      width: '750px',
      data: {
        stateConfigService: {
          ...this.stateConfigService,
          productName: price.productObject?.name
        }
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.subscribedProducts.length != 0 && !this.onetime.map(onetime => onetime.id).includes(price.id)) {
          this.billingControllerService.getPortalUrl(this.stateConfigService.selectedOrganisation?.id ?? undefined).subscribe(next => {
            window.open(next.url)
          });
        }else {
          this.billingControllerService.checkout({priceId: price.id!}, this.stateConfigService.selectedOrganisation?.id ?? undefined).subscribe(next => {
            window.open(next.url)
          })
        }
      }
    });



  }

  get isYearly() {
    return this.priceType == PriceType.YEARLY;
  }

  get isMonthly() {
    return this.priceType == PriceType.MONTHLY;
  }

  get isAddon() {
    return this.priceType == PriceType.ADDON;
  }

  openCustomerPortal(){
    this.billingControllerService.getPortalUrl().subscribe(res => {
      window.open(res.url, '_blank');
    });
  }

  protected readonly PriceType = PriceType;

  togglePriceType($event: Event) {

  }
}


@Component({
  selector: 'app-checkoutConfirmationDialog',
  templateUrl: './checkout-confirmation-dialog.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutConfirmationDialog implements OnInit {
  productName: string;
  selectedOrganisation: string;
  constructor (@Inject(MAT_DIALOG_DATA) public data: { stateConfigService: any }, private dialogRef: MatDialogRef<CheckoutConfirmationDialog>) {
    this.productName = data.stateConfigService.productName;
    this.selectedOrganisation = data.stateConfigService.selectedOrganisation?.name ?? 'Persönlich';
  }

  ngOnInit() {
  }
}
