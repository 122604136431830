<div class="flex flex-col h-full">
  <app-swm-stepper class="w-full"></app-swm-stepper>

  <div class="flex flex-row space-x-4 px-4">

    <div class="flex flex-col w-1/2 p-2 justify-between items-start">

      <div class="flex text-xl font-medium pl-2">
        Prüfungskatalog
      </div>
      <div class="flex flex-row justify-between items-center pt-2 w-full" >
        <!--        <div class="flex flex-row justify-around items-center">-->
        <!--          <div class="m-2">-->
        <!--            <button type="button" disabled class="text-mid-gray text-xs font-medium rounded-lg cursor-not-allowed">Sortieren</button>-->
        <!--          </div>-->
        <!--          <div class="m-2">-->
        <!--            <button type="button" disabled class="text-mid-gray text-xs font-medium rounded-lg cursor-not-allowed">Filtern</button>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="flex flex-row justify-around items-center">
          <div class="m-2">
            <ng-container *ngIf="!areAllLSelected()">
              <div class="rounded-full bg-primary/10 border-2 border-primary hover:bg-primary/75 ">
                <div class="text-xs flex flex-row justify-center items-center text-primary p-2 hover:cursor-pointer hover:text-white text-center" (click)="addAllToSelectedServices()">
                  Alle Prüfregeln auswählen
                </div>
              </div>

            </ng-container>
            <ng-container *ngIf="areAllLSelected()">
              <div class="rounded-full bg-primary border-2 border-primary hover:bg-primary/50 ">
                <div class="text-xs flex flex-row justify-center items-center text-white p-2 hover:cursor-pointer hover:text-primary text-center" (click)="removeAllFromSelectedServices()">
                  Alle Prüfregeln abwählen
                </div>
              </div>
            </ng-container>
          </div>
        </div>
<!--        <button (click)="getCheckStatus()">TESTEN</button>-->
      </div>


    </div>
    <div class="flex flex-col w-1/2 p-2 justify-between items-start">
      <div class="flex text-xl font-medium pl-4">
        Ausgewählte Prüfregeln
      </div>
    </div>
  </div>

  <div class="flex-grow flex flex-row space-x-4 overflow-hidden px-4">

    <div class="flex flex-col w-full">
      <div class="overflow-y-scroll p-2 h-full"
           cdkDropList #lAll="cdkDropList"
           [cdkDropListData]="allServices"
           [cdkDropListConnectedTo]="[lSelected]"
           (cdkDropListDropped)="drop($event)">
        <div class="flex flex-row shadow-item w-full rounded-lg p-2 mb-4 justify-start items-center hover:cursor-grab"
             cdkDrag *ngFor="let s of allServices">
          <ng-container *ngIf="!selectedServices.includes(s)">
            <button mat-icon-button class="rotate-45" (click)="addToSelectedServices(s)">
              <fa-icon [icon]="faCircleXmark"></fa-icon>
            </button>
          </ng-container>
          <ng-container  *ngIf="selectedServices.includes(s)">
            <button mat-icon-button (click)="removeFromSelectedServices(s)">
              <fa-icon [icon]="faCircleXmark" class="text-bic-yellow"></fa-icon>
            </button>
          </ng-container>
          <div class="font-medium text-gray-700 flex flex-row justify-between items-center w-full">
            <div>
              {{s.description}}
            </div>
<!--            <app-bic-tag *ngIf="selectedLph != lphEnum.LPH4" [class_css]=" s.includes('GL') ? 'bg-primary rounded-md text-white font-medium px-4' : 'bg-primary/50 rounded-md text-white font-medium px-4'" >{{s.includes('GL') ? 'GL' : 'BL'}}</app-bic-tag>-->
<!--            <app-bic-tag *ngIf="selectedLph == lphEnum.LPH4" [class_css]=" s.includes('GL') ? '' : 'bg-primary/50 rounded-md text-white font-medium px-4'" >{{s.includes('GL') ? '' : 'BL'}}</app-bic-tag>-->
          </div>
        </div>
      </div>
    </div>

    <div class="w-1 bg-primary rounded-lg">
    </div>

    <div class="flex flex-col w-full justify-between items-start">

      <div class="w-full overflow-y-scroll h-full p-2"
           cdkDropList #lSelected="cdkDropList"
           [cdkDropListData]="selectedServices"
           [cdkDropListConnectedTo]="[lAll]"
           (cdkDropListDropped)="drop($event); fileLeave($event)"
           (cdkDropListEntered)="fileOver($event)"
           (cdkDropListExited)="fileLeave($event)">
        <div class=" flex flex-col h-full justify-between">
          <div class=" flex flex-col">
            <div class="flex flex-row shadow-item w-full rounded-lg p-2 mb-4 justify-start items-center hover:cursor-grab"
                 cdkDrag *ngFor="let s of selectedServices">
              <button mat-icon-button (click)="removeFromSelectedServices(s)">
                <fa-icon [icon]="faCircleXmark" class="text-bic-yellow"></fa-icon>
              </button>
              <div class="font-medium text-gray-700 flex flex-row justify-between items-center w-full">
                <div>
                  {{s.description}}
                </div>
<!--                <app-bic-tag *ngIf="selectedLph != lphEnum.LPH4" [class_css]=" s.includes('GL') ? 'bg-primary rounded-md text-white font-medium px-4' : 'bg-primary/50 rounded-md text-white font-medium px-4'" >{{s.includes('GL') ? 'GL' : 'BL'}}</app-bic-tag>-->
<!--                <app-bic-tag *ngIf="selectedLph == lphEnum.LPH4" [class_css]=" s.includes('GL') ? '' : 'bg-primary/50 rounded-md text-white font-medium px-4'" >{{s.includes('GL') ? '' : 'BL'}}</app-bic-tag>-->
              </div>
            </div>
          </div>
<!--          <div class="flex flex-row justify-center items-center h-full border-2 border-dashed border-[#6b7280] rounded-2xl" [class]="dropZoneClassName" *ngIf="allServices.length != 0">-->
<!--            <div class="flex flex-col justify-center items-start w-full h-full" [class]="contentClassName">-->
<!--              <div class="flex flex-row justify-center items-center w-full mb-16" *ngIf="!entered">-->
<!--                <mat-icon class="scale-[5]" [svgIcon]="'plus-circle'"></mat-icon>-->
<!--                &lt;!&ndash;                  <fa-icon class="text-mid-gray" [icon]="faCircleXmark"></fa-icon>&ndash;&gt;-->
<!--              </div>-->
<!--              <div class="flex flex-row justify-center items-center w-full" [class]="entered ? 'text-black' : 'text-[#6b7280]'" [textContent]="entered ? 'Hier loslassen' : 'Leistungen per Drag & Drop auswählen'">-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div
            class="flex flex-row justify-center items-center h-full border-2 border-dashed border-[#6b7280] rounded-2xl"
            [class]="dropZoneClassName" *ngIf="allServices.length != 0">
            <div class="flex flex-col justify-center items-start w-full h-full" [class]="contentClassName">
              <div class="flex flex-row justify-center items-center w-full my-16" *ngIf="!entered">
                <mat-icon class="scale-[5]" [svgIcon]="'plus-circle'"></mat-icon>
                <!--                  <fa-icon class="text-mid-gray" [icon]="faCircleXmark"></fa-icon>-->
              </div>
              <div class="flex flex-row justify-center items-center w-full pb-6"
                   [class]="entered ? 'text-black' : 'text-[#6b7280]'"
                   [textContent]="entered ? 'Hier loslassen' : 'Leistungen per Drag & Drop auswählen'">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="flex flex-row w-full justify-around items-center p-2" [matTooltip]="!stateConfigService.canCheck() ? 'Keine Prüfungen im Kontingent' : !permissionService.hasPermissionToCheck() ? 'Keine Berechtigungen': ''" >-->
      <!--        <button mat-button  [class.bg-primary]="stateConfigService.canCheck()" class="text-white rounded-lg" [disabled]="!stateConfigService.canCheck() || !permissionService.hasPermissionToCheck()" (click)="startCheck()">Überprüfen</button>-->
      <!--      </div>-->
      <div class="flex flex-row w-full justify-around items-center p-2" [matTooltip]="'Überprüfung starten'">
        <button mat-raised-button color="primary" [disabled]="selectedServices.length == 0" (click)="startCheck()">Überprüfen</button>
      </div>
    </div>
  </div>
</div>
