<div class="flex flex-row justify-start items-start gap-2">

</div>

<mat-dialog-actions>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <button mat-icon-button (click)="saveUser()">
    <mat-icon>save</mat-icon>
  </button>

</mat-dialog-actions>
