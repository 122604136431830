import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AuthControllerService } from './api/authController.service';
import { BillingControllerService } from './api/billingController.service';
import { BimBaseCheckControllerService } from './api/bimBaseCheckController.service';
import { CheckControllerService } from './api/checkController.service';
import { DashboardControllerService } from './api/dashboardController.service';
import { FileControllerService } from './api/fileController.service';
import { IfcControllerService } from './api/ifcController.service';
import { NotificationControllerService } from './api/notificationController.service';
import { OrganisationControllerService } from './api/organisationController.service';
import { OrganisationsControllerService } from './api/organisationsController.service';
import { PartnerControllerService } from './api/partnerController.service';
import { ProjectControllerService } from './api/projectController.service';
import { ProjectsControllerService } from './api/projectsController.service';
import { QualityControllerService } from './api/qualityController.service';
import { ServiceControllerService } from './api/serviceController.service';
import { StateControllerService } from './api/stateController.service';
import { SwmControllerService } from './api/swmController.service';
import { TaskControllerService } from './api/taskController.service';
import { UserControllerService } from './api/userController.service';
import { UsersControllerService } from './api/usersController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AuthControllerService,
    BillingControllerService,
    BimBaseCheckControllerService,
    CheckControllerService,
    DashboardControllerService,
    FileControllerService,
    IfcControllerService,
    NotificationControllerService,
    OrganisationControllerService,
    OrganisationsControllerService,
    PartnerControllerService,
    ProjectControllerService,
    ProjectsControllerService,
    QualityControllerService,
    ServiceControllerService,
    StateControllerService,
    SwmControllerService,
    TaskControllerService,
    UserControllerService,
    UsersControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
