import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {StateConfigService} from "../../../../../services/state/state-config.service";

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss']
})
export class SidebarItemComponent implements OnInit {

  @Input() opened: boolean = true;
  @Input() collapsed: boolean = false;
  @Input() title: string = '';
  @Input() icon!: IconProp;
  @Input() ifc: boolean = false;
  @Input() chatBIC: boolean = false;
  @Input() disabled: boolean = false;
  @Input() beta: boolean = false;
  @Input() comingSoon: boolean = false;
  @Input() routerLinkName: string = '';
  @Input() selected: string = '';
  selectedElement: string = '';
  hovering: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log(event.url)
        let end = event.url.split("/").pop();
        if(!isNaN(Number(end))){
          end = "dashboard";
        }
        end = end?.split("?")[0];
        this.selectedElement = end || '';

        // Check if the route contains the parent path

        this.selectedElement = event.url.includes(this.selected) ? this.selected : end || '';
      }
    });
  }

  onHover(): void {
    this.hovering = true;
  }

  onLeaveHover(): void {
    this.hovering = false;
  }

  protected readonly StateConfigService = StateConfigService;
}
