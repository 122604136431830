import {Component, Input, OnInit} from '@angular/core';

/**
 * Cloud SVG Component
 */
@Component({
  selector: 'app-cloud-number',
  templateUrl: './cloud-number.component.html',
  styleUrls: ['./cloud-number.component.scss']
})
export class CloudNumberComponent implements OnInit {

  /**
   * Value for Text in Cloud
   */
  @Input() value!: string;

  /**
   * Sets if ist Last in row of elements
   */
  @Input() last: boolean = false;

  /**
   * Active State used for Color of Cloud
   */
  @Input() active: boolean = false;

  /**
   * Activates Loading Animation
   */
  @Input() loading: boolean = false;

  /**
   * Activates Loading Animation for Analysis
   */
  @Input() analysisLoading: boolean = false;

  /**
   * Activates Upload Design
   */
  @Input() upload: boolean = false;

  /**
   * Activates Download Design
   */
  @Input() download: boolean = false;

  /**
   * MatIcon to show
   */
  @Input() matIcon: string = "";

  /**
   * Constructor
   */
  constructor() {
  }

  /**
   * Initialization of Component
   */
  ngOnInit(): void {
  }


}
