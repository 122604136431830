/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Comment } from './comment';
import { Topic } from './topic';
import { Viewpoint } from './viewpoint';

export interface Markup { 
    id?: string;
    topic?: Topic;
    viewpoints?: Array<Viewpoint>;
    comment?: Array<Comment>;
    markupExtra?: string;
}