import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AuthGuardService as AuthGuard} from "./services/auth/auth-guard.service";
import {RouterModule} from "@angular/router";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {JwtHelperService, JwtModule} from "@auth0/angular-jwt";
import {AuthModule} from "./components/auth/auth.module";
import {ApiModule} from "./api";
import {ProfileComponent, UserDeleteDialog} from './components/profile/profile.component';
import {ProjectsComponent} from './components/./projects/projects.component';
import {DatePipe, NgOptimizedImage, registerLocaleData} from "@angular/common";
import {
  ProjectConfigurationDeleteDialog,
  ProjectSettings
} from './components/projectSettings/project-settings.component';
import {HttpLoadingInterceptor} from "./interceptors/httpLoading/http-loading-interceptor.service";
import {LoadingService} from "./services/loading/loading.service";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {CloudNumberComponent} from './components/util/design/cloud-number/cloud-number.component';
import {CdkStepperModule} from "@angular/cdk/stepper";
import {FilterByListPipe} from './pipes/filterByList.pipe';
import {ProfileImageUploadComponent} from './components/util/profile-image-upload/profile-image-upload.component';
import {ProjectImageUploadComponent} from './components/util/project-image-upload/project-image-upload.component';
import {ImageUploadComponent} from './components/util/image-upload/image-upload.component';
import {HttpErrorInterceptor} from "./interceptors/httpError/http-error.interceptor";
import {OAuthModule} from "angular-oauth2-oidc";
import {DesignModule} from "./components/util/design/design.module";
import {HoldableDirective} from "./directives/holdable.directive";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule} from "@angular/material/core";
import {InvokeFunctionDirective} from "./directives/invokeFunction.directive";
import {
  AspectActivatedPipe,
  AspectPipe,
  ComplianceCheckPhrasesPipe,
  ComplianceCheckPipe,
  DescriptionPipe,
  PhrasesPipe,
  ServiceActivatedPipe,
  ServicePipe
} from './pipes/service.pipe';
import {OverlayModule} from "@angular/cdk/overlay";
import {FolderDirective} from './directives/folder.directive';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {LayoutModule} from '@angular/cdk/layout';
import {DashboardModule} from "./components/dashboard/dashboard.module";
import {environment} from '../environments/environment';
import {SearchFilterBarComponent} from './components/util/search-filter-bar/search-filter-bar.component';
import {TasksComponent} from "./components/tasks/tasks.component";
import {TaskListComponent} from './components/tasks/task-list/task-list.component';
import {TaskCardComponent} from './components/tasks/task-card/task-card.component';
import {TaskDeleteDialog, TaskDetailComponent} from './components/tasks/task-detail/task-detail.component';
import {TaskCommentsComponent} from './components/tasks/task-comments/task-comments.component';
import {TaskBoardComponent} from './components/tasks/task-board/task-board.component';
import {AngularEditorModule} from "@kolkov/angular-editor";
import {LphToNumberPipe, NumberToLphPipe} from "./pipes/lphToNumber.pipe";
import {UsersListComponent} from './components/util/users/users.component';
import {AddUserDialogComponent} from './components/util/users/add-user-dialog/add-user-dialog.component';
import {
  UserPermissionsDialogComponent
} from './components/util/users/user-permissions-dialog/user-permissions-dialog.component';
import {LphNamePipe, LphPipe, LphServicePipe} from "./pipes/lph.pipe";
import {MomentDateAdapter} from "@angular/material-moment-adapter";
import {DeleteDialogComponent} from './components/util/deleteDialog/delete-dialog.component';
import {TaskBoardColumnComponent} from './components/tasks/task-board/task-board-column/task-board-column.component';
import {PriorityPipe} from './pipes/priority.pipe';
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {BranchPipe} from './pipes/branch.pipe';
import {TitlePipe} from './pipes/title.pipe';
import {ProjectKindPipe} from './pipes/project-kind.pipe';
import {TaskStatePipe, TaskStateReversePipe} from './pipes/task-state.pipe';
import {MaterialModule} from "./material.module";
import {CheckoutComponent, CheckoutConfirmationDialog} from './components/checkout/checkout.component';
import {CheckoutPriceCardComponent} from './components/checkout/checkout-price-card/checkout-price-card.component';
import {ApiModule as BimApiModule} from "./bim-api";

import {ApplicationModule} from "./components/special/application/application.module";
import {StateConfigService} from "./services/state/state-config.service";
import {DrawerContentService} from "./services/drawer-content.service";
import {LvModule} from "./components/special/lv/lv.module";
import {FilesModule} from "./components/util/files/files.module";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";
import {FilesComponent} from './components/files/files.component';
import {BuildingClassComponent} from './components/special/buildingClass/building-class.component';
import {StorageClassComponent} from './components/special/storage-class/storage-class.component';
import {SafetyCategoriesComponent} from './components/special/safety-categories/safety-categories.component';
import {OrganisationComponent} from './components/organisation/organisation.component';
import {
  OrganisationSettingsComponent, OrgLeaveConfirmationDialog, OrgDeleteConfirmationDialog
} from './components/organisation/organisation-settings/organisation-settings.component';
import {HoaiCheckComponent, HoaiConfirmationDialog} from './components/hoaiCheck/hoai-check.component';
import {hoaiCheckDetailComponent} from './components/hoaiCheck/hoaiCheckDetail/hoai-check-detail.component';
import {PopUpComponent} from './components/util/pop-up/pop-up.component';
import {PopUpWindowComponent} from './components/util/pop-up/pop-up-window/pop-up-window.component';
import {AddressInputModule} from "./components/util/addressInput/address-input.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {IfcViewerComponent} from './components/special/ifc-viewer/ifc-viewer.component';
import {ReportComponent} from './components/report/report.component';
import {CdkTableModule} from "@angular/cdk/table";
import {HoaiCheckReportComponent} from './components/report/components/hoaiCheckReport/hoai-check-report.component';
import {TaskDetailsService} from "./services/task-details.service";
import {IfcCheckComponent} from './components/ifcCheck/ifc-check.component';
import {CheckTypePipe} from './pipes/check-type.pipe';
import {QualitySettingsComponent} from './components/qualityCheck/quality-settings/quality-settings.component';
import {IfcCheckReportComponent} from './components/report/components/ifcCheckReport/ifc-check-report.component';
import {LodPipe} from './pipes/lod.pipe';
import {
  OrganisationImageUploadComponent
} from './components/util/organisation-image-upload/organisation-image-upload.component';
import {OrganisationPopupComponent} from './components/organisation/organisation-popup/organisation-popup.component';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule} from "@angular/material/dialog";
import {MatPaginatorModule} from "@angular/material/paginator";
import {BicLoiCheckModule} from "@buildinginformationcloud/bic-loi-check";
import {LoiCheckEditorComponent} from './components/ifcCheck/components/loi-check-editor/loi-check-editor.component';
import {FilesHomeviewComponent} from './components/files/files-homeview/files-homeview.component';
import {PortalModule} from "@angular/cdk/portal";
import {FileViewerComponent} from './components/util/file-viewer/file-viewer.component';
import {BimToolsComponent} from './components/bim-tools/bim-tools.component';
import {BimBasisCheckComponent, BimConfirmationDialog} from './components/ifcCheck/components/bim-basis-check/bim-basis-check.component';
import {FileTooLargeDialog} from "./components/util/files/files.component";
import {SidebarFooterComponent} from './components/util/design/sidebar/sidebar-footer/sidebar-footer.component';
import {ChatModule} from "./components/chat/chat.module";
import {BicIfcViewerModule} from "@buildinginformationcloud/bic-ifc-viewer";
import {FeatureFlagGuardService as FeatureFlagGuard} from "./services/feature-flag-guard.service";
import {BicTableComponent} from './components/util/bic-table/bic-table.component';
import {
  QualityChooseFilesComponent
} from "./components/qualityCheck/quality-choose-files/quality-choose-files.component";
import {
  QualityChooseRulesComponent
} from './components/qualityCheck/quality-choose-rules/quality-choose-rules.component';
import {QualityFileReportComponent} from './components/qualityCheck/quality-file-report/quality-file-report.component';
import {
  QualityRulesReportComponent
} from './components/qualityCheck/quality-rules-report/quality-rules-report.component';
import {QualityStepperComponent} from './components/qualityCheck/quality-stepper/quality-stepper.component';
import {
  QualityComplianceReport
} from "./components/qualityCheck/quality-compliance-report/quality-compliance-report.component";
import {
  QualityResultReportComponent
} from "./components/qualityCheck/quality-result-report/quality-result-report.component";
import {ApiModule as QualityApiModule} from "./quality-api";
import {CustomRoleDetailComponent} from './components/util/custom-role-detail/custom-role-detail.component';
import {QualityLoadingComponent} from './components/qualityCheck/quality-loading/quality-loading.component';
import {OverviewReportComponent} from "./components/report/components/overviewReport/overview-report.component";
import {
  LoiCheckChooseRulesComponent, LoiConfirmationDialog
} from "./components/ifcCheck/components/loi-check-choose-rules/loi-check-choose-rules.component";
import {OrganisationListComponent} from "./components/organisationList/organisation-list.component";
import {BimCheckReportComponent} from "./components/report/components/bim-check-report/bim-check-report.component";
import {PlanChooseFilesComponent} from './components/planCheck/plan-choose-files/plan-choose-files.component';
import {PlanResultComponent} from './components/planCheck/plan-result/plan-result.component';
import {ApiModule as PlanApiModule} from "./hasselmann-mueller-api";
import {RoleAndPrivilegesPipe} from "./pipes/role-and-privileges.pipe";
import {HoaiStepperComponent} from "./components/hoaiCheck/hoai-stepper/hoai-stepper.component";
import {FirstStepsComponent} from './components/first-steps/first-steps.component';
import {
  QualityCheckConfirmationComponent
} from './components/qualityCheck/quality-check-confirmation/quality-check-confirmation.component';
import {FolderPipe} from "./pipes/folder.pipe";
import {LoiCheckStepperComponent} from './components/ifcCheck/components/loi-check-stepper/loi-check-stepper.component';
import {
  LoiCheckChooseFilesComponent
} from "./components/ifcCheck/components/loi-check-choose-files/loi-check-choose-files.component";
import {
  BimBaseCheckStepperComponent
} from "./components/ifcCheck/components/bim-base-check-stepper/bim-base-check-stepper.component";
import {MatSortModule} from "@angular/material/sort";
import {
  LoiDeleteStandardConfirmationDialogComponent
} from "./components/ifcCheck/components/loi-delete-standard-confirmation-dialog/loi-delete-standard-confirmation-dialog.component";
import {
  CheckoutFeatureListComponent
} from './components/checkout/checkout-feature-list/checkout-feature-list.component';
import {
  CheckoutFeatureListItemComponent
} from './components/checkout/checkout-feature-list/checkout-feature-list-item/checkout-feature-list-item.component';
import {UserProjectsComponent} from "./components/profile/user-projects/user-projects.component";
import {CircleStateComponent} from "./components/util/design/circle-state/circle-state.component";
import { OrganisationCreateComponent } from './components/organisation/organisation-create/organisation-create.component';
import { ImageCropperComponent } from './components/util/image-upload/image-cropper/image-cropper.component';
import { ViewImageComponent } from './components/util/image-upload/view-image/view-image.component';
import {BicUiComponentsModule} from "@buildinginformationcloud/bic-ui-components";
import { FtaPopupComponent } from './components/profile/fta-popup/fta-popup.component';
import { FtaPopupDeactivateComponent } from './components/profile/fta-popup/fta-popup-deactivate/fta-popup-deactivate.component';



export const DateFormats = {
  parse: {
    dateInput: ['DD.MM.YYYY']
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


/**
 * Root Module
 */
@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    ProjectsComponent,
    ProjectSettings,
    FilterByListPipe,
    ImageUploadComponent,
    ProfileImageUploadComponent,
    ProjectImageUploadComponent,
    ProjectConfigurationDeleteDialog,
    UserDeleteDialog,
    HoldableDirective,
    InvokeFunctionDirective,
    FolderDirective,
    SearchFilterBarComponent,
    TasksComponent,
    TaskListComponent,
    TaskCardComponent,
    TaskDetailComponent,
    TaskDeleteDialog,
    TaskCommentsComponent,
    TaskBoardComponent,
    LphToNumberPipe,
    NumberToLphPipe,
    AddUserDialogComponent,
    UserPermissionsDialogComponent,
    UsersListComponent,
    ServiceActivatedPipe,
    DeleteDialogComponent,
    TaskBoardColumnComponent,
    TaskBoardColumnComponent,
    PriorityPipe,
    AspectPipe,
    BranchPipe,
    TitlePipe,
    FilesComponent,
    CheckoutComponent,
    CheckoutConfirmationDialog,
    CheckoutPriceCardComponent,
    BuildingClassComponent,
    StorageClassComponent,
    SafetyCategoriesComponent,
    HoaiCheckComponent,
    hoaiCheckDetailComponent,
    OrganisationComponent,
    OrganisationSettingsComponent,
    OrgLeaveConfirmationDialog,
    OrgDeleteConfirmationDialog,
    PopUpComponent,
    PopUpWindowComponent,
    IfcViewerComponent,
    ReportComponent,
    HoaiCheckReportComponent,
    IfcCheckComponent,
    QualityResultReportComponent,
    QualitySettingsComponent,
    IfcCheckReportComponent,
    OrganisationImageUploadComponent,
    OrganisationPopupComponent,
    FilesHomeviewComponent,
    LphNamePipe,
    LoiCheckEditorComponent,
    FileViewerComponent,
    BimToolsComponent,
    BimBasisCheckComponent,
    BimConfirmationDialog,
    LoiCheckChooseFilesComponent,
    FileTooLargeDialog,
    HoaiConfirmationDialog,
    SidebarFooterComponent,
    QualityChooseFilesComponent,
    QualityChooseRulesComponent,
    QualityFileReportComponent,
    QualityRulesReportComponent,
    QualityStepperComponent,
    QualityComplianceReport,
    BicTableComponent,
    BimCheckReportComponent,
    LoiCheckChooseRulesComponent,
    LoiConfirmationDialog,
    CustomRoleDetailComponent,
    PlanChooseFilesComponent,
    PlanResultComponent,
    DescriptionPipe,
    PhrasesPipe,
    QualityLoadingComponent,
    HoaiStepperComponent,
    PhrasesPipe,
    FirstStepsComponent,
    QualityCheckConfirmationComponent,
     OrganisationListComponent,
    LoiCheckStepperComponent,
    BimBaseCheckStepperComponent,
    ComplianceCheckPipe,
    ComplianceCheckPhrasesPipe,
    AspectActivatedPipe,
    LoiCheckChooseFilesComponent,
    LoiDeleteStandardConfirmationDialogComponent,
    CheckoutFeatureListComponent,
    CheckoutFeatureListItemComponent,
    UserProjectsComponent,
    OrganisationCreateComponent,
    ImageCropperComponent,
    ViewImageComponent,
    FtaPopupComponent,
    FtaPopupDeactivateComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AuthModule,
    HttpClientModule,
    AppRoutingModule,
    DragDropModule,
    ApiModule,
    DesignModule,
    DashboardModule,
    FontAwesomeModule,
    QualityApiModule,
    PlanApiModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => window.localStorage.getItem('token'),
        allowedDomains: environment.allowedDomains,
        disallowedRoutes: ['/admin/**'],
        authScheme: () => 'Bearer ',
      },
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: environment.allowedUrls,
        sendAccessToken: true
      }
    }),
    CdkStepperModule,
    MatRippleModule,
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    NgxChartsModule,
    MaterialModule,
    MatPaginatorModule,
    LayoutModule,
    AngularEditorModule,
    LeafletModule,
    ApplicationModule,
    LvModule,
    FilesModule,
    NgxExtendedPdfViewerModule,
    AddressInputModule,
    BicIfcViewerModule,
    CdkTableModule,
    AddressInputModule,
    BimApiModule,
    BicLoiCheckModule,
    MatDialogModule,
    PortalModule,
    ChatModule, NgOptimizedImage, FolderPipe,
    MatSortModule,
    OverviewReportComponent, CircleStateComponent, BicUiComponentsModule,

  ],
  providers: [
    StateConfigService,
    HttpClientModule,
    MaterialModule,
    RouterModule,
    JwtHelperService,
    LoadingService,
    AuthGuard,
    DatePipe,
    ServicePipe,
    AspectPipe,
    ServiceActivatedPipe,
    FilterByListPipe,
    LphToNumberPipe,
    NumberToLphPipe,
    LphPipe,
    TaskStatePipe,
    TaskStateReversePipe,
    LphServicePipe,
    DrawerContentService,
    TaskDetailsService,
    ProjectKindPipe,
    CheckTypePipe,
    LodPipe,
    FeatureFlagGuard,
    DescriptionPipe,
    PhrasesPipe,
    RoleAndPrivilegesPipe,
    ComplianceCheckPipe,
    ComplianceCheckPhrasesPipe,
    AspectActivatedPipe,
    {provide: HTTP_INTERCEPTORS, useClass: HttpLoadingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        hasBackdrop: true,
        disableClose: true,

      } as MatDialogConfig,
    },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DateFormats},
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {provide: LOCALE_ID, useValue: 'de-DE'},
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        CloudNumberComponent,
        ProfileComponent,
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  }
}
