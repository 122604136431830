import {Component, OnInit} from '@angular/core';
import {SWMCheck} from "../../../../api";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-swm-stepper',
  templateUrl: './swm-stepper.component.html',
  styleUrls: ['./swm-stepper.component.scss']
})
export class SwmStepperComponent implements OnInit {

  selectedStep = 0;
  completedSteps: number[] = [0];
  selectedFiles = []
  editMap = new Map<number, boolean>

  swmCheck!: SWMCheck

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state?.files){
      this.selectedFiles = this.router.getCurrentNavigation()?.extras?.state?.files
    }

  }

  ngOnInit(): void {
    this.swmCheck = this.activatedRoute.snapshot.parent!.data.swmCheck

    this.editMap.set(0, false)
    this.editMap.set(1, false)
    this.editMap.set(2, false)
    this.editMap.set(3, false)

    if( this.selectedFiles.length > 0){
      this.editMap.set(0, true)
      this.completedSteps.push(0)
    }

    if(this.swmCheck){
      if(this.swmCheck.completed){
          this.editMap.set(0, false)
          this.editMap.set(1, false)
          this.editMap.set(2, true)
          this.editMap.set(3, false)
          this.selectedStep = 2;
          this.completedSteps = [0, 1]
      }
    }

    const url = this.activatedRoute.snapshot.pathFromRoot
      .map(v => v.url)
      .filter(v => v.length > 0)
      .slice(2)
      .flat()

    if(url.at(-1)?.path == "ruleSelection"){
      this.selectedStep = 1

    } else if(url.length == 2){
      this.selectedStep = 2

    }else if(url.length == 3){
      this.completedSteps.push(2)
      this.selectedStep = 3
    }


  }

  changeStep(index: number): void {
    this.selectedStep = index;
    const projectId = this.activatedRoute.snapshot.parent!.parent!.params.id;
    const url = this.activatedRoute.snapshot.pathFromRoot
      .map(v => v.url)
      .filter(v => v.length > 0)
      .slice(2)
      .flat()


    let swmCheckId = ''
    if(this.swmCheck){
      swmCheckId = this.swmCheck.id
    }

    if(this.selectedStep == 0){
      this.router.navigate(['projects/' + projectId +  '/fireprotection'])
    }else if(this.selectedStep == 1){
      // this.router.navigate(['projects/' + this.stateConfigService.getProjectId() +  '/fireprotection/ruleSelection'])
    }else if(this.selectedStep == 2){
      // @ts-ignore
      if(url.at(-3) == 'fireprotection'){
        this.router.navigate(['projects/' + projectId +  '/fireprotection/' + url.at(-2)])
      }
      if(swmCheckId){

      }
    }else if(this.selectedStep == 3){

    }

  }

}
