import { Component } from '@angular/core';
import {faBars, faChevronRight, faHouse} from "@fortawesome/free-solid-svg-icons";
import {faCircleQuestion, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {DashboardControllerService, ProjectFile, ProjectFileData} from "../../../api";
import LphEnum = ProjectFile.LphEnum;

import {ActivatedRoute, Router} from "@angular/router";
import {StateConfigService} from "../../../services/state/state-config.service";

@Component({
  selector: 'app-files-homeview',
  templateUrl: './files-homeview.component.html',
  styleUrls: ['./files-homeview.component.scss']
})
export class FilesHomeviewComponent {

  selectedLph: LphEnum | undefined = undefined;
  currentLph: LphEnum[] = [LphEnum.LPH1, LphEnum.LPH2, LphEnum.LPH3, LphEnum.LPH4, LphEnum.LPH5, LphEnum.LPH6]
  projectFileData: Map<LphEnum, {[p: string]: ProjectFileData }> = new Map<LphEnum, {[p: string]: ProjectFileData }>();


    constructor(private route: ActivatedRoute, private router: Router, public stateConfigService: StateConfigService, private dashboardControllerService: DashboardControllerService) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      this.selectedLph = this.route.snapshot.queryParams.lph;
    });
    for (let lph of this.currentLph) {
      this.dashboardControllerService.getProjectFileData(
        this.stateConfigService.getProjectId(), lph).subscribe(projectFilesData => {
        this.projectFileData.set(lph, projectFilesData);
      })
    }

  }


  lphData(lph: LphEnum): ProjectFileData {
      if(this.projectFileData.get(lph) != undefined) {
        let lphValue = this.projectFileData.get(lph);
        let lphValueSize = 0;
        // @ts-ignore
        for (let folder of Object.values(lphValue)) {
          // @ts-ignore
          lphValueSize += folder.size;
        }

        let lphValueNumberOfFiles = 0;
        // @ts-ignore
        for (let folder of Object.values(lphValue)) {
          // @ts-ignore
          lphValueNumberOfFiles += folder.numberOfFiles;
        }
        return {size: lphValueSize, numberOfFiles: lphValueNumberOfFiles};
      }else{
        return {size: 0, numberOfFiles: 0};
      }

  }

  navigateToLPH(lph: LphEnum): void {
    this.selectedLph = lph;
    this.router.navigate([], {queryParams: {lph: lph}});
  }

  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }

  calculateSizeInGB(size: number): number {
    return size / 1024 / 1024 / 1024;
  }


  protected readonly faChevronRight = faChevronRight;
    protected readonly faCircleQuestion = faCircleQuestion;
    protected readonly faBars = faBars;
    protected readonly faFolderOpen = faFolderOpen;
  protected readonly ProjectFile = ProjectFile;
  protected readonly LphEnum = LphEnum;
  protected readonly undefined = undefined;
  protected readonly faHouse = faHouse;
}
