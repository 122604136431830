import { Pipe, PipeTransform } from '@angular/core';
import {Project} from "../api";
import ProjectKindEnum = Project.ProjectKindEnum;

@Pipe({
  name: 'projectKind'
})
export class ProjectKindPipe implements PipeTransform {

  transform(value: ProjectKindEnum): String {
    switch (value) {
      case ProjectKindEnum.WOHNEN: return "Wohnen";
      case ProjectKindEnum.AUSBILDUNGWISSENSCHAFTFORSCHUNG: return "Ausbildung | Wissenschaft | Forschung";
      case ProjectKindEnum.BUEROVERWALTUNGSTAATKOMMUNE: return "Büro | Verwaltung | Staat | Kommune";
      case ProjectKindEnum.GESUNDHEITBETREUUNG: return "Gesundheit | Betreuung";
      case ProjectKindEnum.HANDELUNDVERKAUFGASTGEWERBE: return "Handel und Verkauf | Gastgewerbe";
      case ProjectKindEnum.FREIZEITSPORT: return "Freizeit | Sport";
      case ProjectKindEnum.GEWERBEINDUSTRIELANDWIRTSCHAFT: return "Gewerbe | Industrie | Landwirtschaft";
      case ProjectKindEnum.INFRASTRUKTUR: return "Infrastruktur";
      case ProjectKindEnum.KULTURUNDSAKRALES: return "Kultur- | Sakralbauten";
      default: return "";
    }
  }

}
