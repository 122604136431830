import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output, QueryList,
  SimpleChanges, TemplateRef,
  ViewChild, ViewChildren
} from '@angular/core';
import {
  AuthControllerService,
  Project,
  Task,
  User,
  ProjectControllerService,
  TaskControllerService,
} from "../../../api";
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MatOptionSelectionChange, MatRipple} from "@angular/material/core";
import StateEnum = Task.StateEnum;
import {TaskCommentsComponent} from "../task-comments/task-comments.component";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ServicePipe} from "../../../pipes/service.pipe";
import {LphPipe} from "../../../pipes/lph.pipe";
import {Observable, Subject} from "rxjs";
import {CanDeactivateComponent} from "../../../guards/unsaved-changes.guard";
import PriorityEnum = Task.PriorityEnum;
import {FilterByListPipe} from "../../../pipes/filterByList.pipe";
import {createEvent} from "./event-download.utils";
import {DrawerContentService} from "../../../services/drawer-content.service";
import {StateConfigService} from "../../../services/state/state-config.service";
import {CdkOverlayOrigin, Overlay, OverlayRef} from "@angular/cdk/overlay";
import {TaskDetailsService} from "../../../services/task-details.service";
import LphEnum = Task.LphEnum;
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSelect} from "@angular/material/select";
import {faEllipsisVertical, faUserGroup, faUserPlus, faX} from "@fortawesome/free-solid-svg-icons";
import {faCalendarDays, faPaperPlaneTop} from "@fortawesome/pro-solid-svg-icons";
import {formatDate} from "@angular/common";
import {BicDatepickerInputDirective} from "@buildinginformationcloud/bic-ui-components";

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit, CanDeactivateComponent, OnChanges, OnDestroy {

  @ViewChild('userSelection') userSelection!: MatSelect | undefined;

  @Input() overlayOrigin: CdkOverlayOrigin | undefined;
  @Input() openDetails: boolean = false;

  projects: Project[] = []

  @Input() taskId!: string;
  @Output() taskChange: EventEmitter<Task> = new EventEmitter<Task>();

  taskForm!: UntypedFormGroup;

  user!: User;
  users!: User[];
  selectedUser!: User;

  project!: Project;
  today: Date = new Date();

  create: boolean = false;
  currentDate: Date = new Date();

  usersObservable!: Observable<User[]> | undefined;

  informedUsersObservable!: Observable<User[]>;

  informedUsers: User[] = [];

  stateEnum: typeof StateEnum = StateEnum;

  priorityEnum: typeof PriorityEnum = PriorityEnum;

  @ViewChild('comments') commentsComponent: TaskCommentsComponent | undefined;

  @ViewChild('overlayTemplate') overlayTemplate!: TemplateRef<any>;
  overlayRef!: OverlayRef | null;

  @ViewChildren(BicDatepickerInputDirective) datePicker!: QueryList<BicDatepickerInputDirective>;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '40',
    maxHeight: '200px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Beschreibung einfügen...',
    fonts: [
      {class: 'raleway', name: 'Raleway'}
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize'],
      ['fontName']
    ]
  };

  constructor(private dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private taskControllerService: TaskControllerService,
              private formBuilder: UntypedFormBuilder, private projectControllerService: ProjectControllerService,
              private authControllerService: AuthControllerService, public servicePipe:
                ServicePipe, public lphPipe: LphPipe, private snackbar: MatSnackBar,
              public filterByList: FilterByListPipe,
              public drawer: DrawerContentService,
              public stateService: StateConfigService,
              private taskDetailsService: TaskDetailsService, public overlay: Overlay,
              @Inject(MAT_DIALOG_DATA) public data: Task,
              public dialogRef: MatDialogRef<TaskDetailComponent>) {


  }

  ngOnDestroy(): void {
    this.taskChange.emit(this.taskForm.value);
    this.taskDetailsService.closeAndSaveTask(this.taskForm.value)
    this.datePicker.forEach(datePicker => {
      datePicker.datepicker?.hide();
    });
  }

  disableForm(): boolean {
    return this.taskForm.disabled;
  }

  validForm(): boolean {
    return this.taskForm.valid || this.taskForm.disabled;
  }

  canDeactivate(): boolean {
    return this.taskForm.pristine;
  }


  ngOnInit(): void {
    this.init();
    // this.taskForm = new FormGroup({
    //   testForm: new FormGroup({
    //     test1: new UntypedFormControl(),
    //     test2: new UntypedFormControl()
    //   }),
    //   filter: new UntypedFormControl(''),
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.init();
  }

  compare(a: any, b: any): boolean {
    return a.id === b.id;
  }




  init() {
    this.currentDate = new Date();

    this.taskForm = this.formBuilder.group({
      id: new UntypedFormControl(),
      informedUsers: new UntypedFormControl([]),
      project: new UntypedFormControl(),
      projectID: new UntypedFormControl(),
      state: new UntypedFormControl(StateEnum.OPEN, Validators.required),
      lph: new UntypedFormControl(),
      priority: new UntypedFormControl(PriorityEnum.NORMAL), // New property
      ifcName: new UntypedFormControl(),
      ifcLink: new UntypedFormControl(),
      markup: this.formBuilder.group({
        id: new UntypedFormControl(),
        comment: this.formBuilder.array([]),
        topic: this.formBuilder.group({
          atGuid: new UntypedFormControl(""),
          atTopicType: new UntypedFormControl(),
          atTopicStatus: new UntypedFormControl(),
          referenceLink: new UntypedFormControl(),
          title: new UntypedFormControl(),
          priority: new UntypedFormControl(),
          index: new UntypedFormControl(),
          labels: new UntypedFormControl(),
          creationDate: new UntypedFormControl(),
          creationAuthor: new UntypedFormControl(),
          modifiedDate: new UntypedFormControl(),
          modifiedAuthor: new UntypedFormControl(),
          dueDate: new UntypedFormControl(),
          startDate: new UntypedFormControl(),
          assignedTo: new UntypedFormControl(),
          description: new UntypedFormControl(),
          creationUser: new UntypedFormControl(),
          modifiedUser: new UntypedFormControl(),
          assignedToUser: new UntypedFormControl(),
        }),
      }),
    });

    if (history.state.project && history.state.lph && history.state.service) {
      this.taskForm.get("project")!.setValue(history.state.project);
      this.taskForm.get("project")!.disable();
      this.taskForm.get("state")!.setValue(this.stateEnum.OPEN);
      this.taskForm.get("markup.topic.description")!.setValue("Leistungsphase " + this.lphPipe.transform(history.state.lph) + " - " + this.servicePipe.transform(history.state.service.partialServiceName));
    }

    if(this.data) {
      const commentControl = this.taskForm.get('markup.comment') as FormArray;
      if (this.data.markup && this.data.markup.comment) {
        this.data.markup.comment.forEach((comment) => {
          commentControl.push(this.createCommentGroup(comment));
        });
      }
    }

    this.taskForm.patchValue(this.taskDetailsService.getOpenedTask() ?? this.taskForm.value);
    //@ts-ignore
    // this.taskForm.get('markup.topic.startDate')?.setValue(this.taskDetailsService.getOpenedTask()?.markup.topic.startDate as string);
    // this.taskForm.get('markup.topic.startDate')?.setValue(this.formatDateForInput(this.taskForm.get('markup.topic.startDate')?.value));
    //
    // this.taskForm.get('markup.topic.dueDate')?.setValue(this.formatDateForInput(this.taskForm.get('markup.topic.dueDate')?.value));

    this.project = this.stateService.getProject()!;
    this.projectControllerService.getUsers(this.stateService.getProject()?.id ?? (this.task.projectID ?? -1)).subscribe(users => {
      this.users = users.filter(user => user.isActivated);
      this.users.sort((a, b) => {
        if (a.profileImage && !b.profileImage) {
          return -1;
        } else if (!a.profileImage && b.profileImage) {
          return 1;
        }
        return 0;
      });

      if(this.taskForm.get("markup.topic.assignedTo")?.value && !this.taskForm.get("markup.topic.assignedToUser")?.value){
        const dummyUser : User = {mail:this.taskForm.get("markup.topic.assignedTo")?.value, firstName:"", lastName:"", id:-1, isActivated:true}
        this.taskForm.get("markup.topic.assignedToUser")?.setValue(dummyUser)
        this.users.push(dummyUser)
      }
    });

    this.authControllerService.profile().subscribe(user => {
      this.user = user;
    });

    // if (this.project) {
    //   this.usersObservable = this.projectControllerService.getUsers(this.project.id!);
    //   //this.taskForm.controls.user.enable();
    // }

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Escape") {
        this.close();
      }
    });
    this.informedUsers = this.taskForm.get('informedUsers')?.value ?? [];

  }

  formatDateForInput(date: string): string {
    const parsedDate = new Date(date);
    return parsedDate.toISOString();
  }


  isSelected(user: User): boolean {
    return this.informedUsers.some((u: User) => u.id === user.id);
  }


  parseDateString(dateString: string): Date {
    const [day, month, year] = dateString.split('.').map(Number);
    return new Date(year, month - 1, day);
  }

  save() {
    if(!this.task.id){
      this.taskForm.get('markup.topic.creationAuthor')?.setValue(this.user.mail)
      this.taskForm.get('markup.topic.creationUser')?.setValue(this.user)
      this.taskForm.get('markup.topic.creationDate')?.setValue(new Date())
    } else {
      this.taskForm.get('markup.topic.modifiedAuthor')?.setValue(this.user.mail)
      this.taskForm.get('markup.topic.modifiedUser')?.setValue(this.user)
      this.taskForm.get('markup.topic.modifiedDate')?.setValue(new Date())
    }

    if(this.taskForm.get('markup.topic.assignedToUser') != null || this.taskForm.get('markup.topic.assignedToUser')?.value?.id != -1){
      this.taskForm.get('markup.topic.assignedTo')?.setValue(this.taskForm.get('markup.topic.assignedToUser')?.value?.mail)
    } else {
      this.taskForm.get('markup.topic.assignedToUser')?.setValue(null)
    }

    // this.taskForm.get('markup.topic.startDate')?.setValue(this.parseDateString(this.taskForm.get('markup.topic.startDate')?.value));
    // this.taskForm.get('markup.topic.dueDate')?.setValue(this.parseDateString(this.taskForm.get('markup.topic.dueDate')?.value));

    this.taskControllerService.updateTask(this.taskForm.value, this.createEventForCal(), this.stateService.getProject()?.id ?? (this.task.projectID ?? -1)).subscribe(next => {
      this.taskDetailsService.currentTaskChanged.next(next);
      this.dialogRef.close();
    })
  }

  saveFromComment(index: number) {
    /*if(!this.task.id){
      this.taskForm.get('markup.topic.creationAuthor')?.setValue(this.user.mail)
      this.taskForm.get('markup.topic.creationUser')?.setValue(this.user)
      this.taskForm.get('markup.topic.creationDate')?.setValue(new Date())
    }*/
    if(this.taskForm.get('id')?.value) {
      this.taskControllerService.getTask(this.taskForm.get('id')?.value).subscribe((task: Task) => {
        let comments = this.taskForm.get('markup.comment') as UntypedFormArray;
        let comment = comments.value[index];
        if (task.markup?.comment) {
          task.markup.comment[index] = comment
        } else {
          if (task.markup) {
            task.markup.comment = [comment]
          }
        }
        this.taskControllerService.updateTask(task, this.createEventForCal(), this.stateService.getProject()?.id ?? (this.task.projectID ?? -1)).subscribe(updatedTask => {
          // @ts-ignore
          comments.at(index).setValue(updatedTask.markup.comment[index])
          this.taskDetailsService.currentTaskChanged.next(this.taskForm.value)

        })
      })
    }
  }

  close() {
    this.taskDetailsService.clearOpenedTask();
    this.dialogRef.close();
  }

  createEventForCal() {
    if (!this.taskForm.get('markup.topic.dueDate')) {
      return "";
    }
    let events = [{
      deadline: new Date(this.taskForm.get('markup.topic.dueDate')?.value),
      summary: 'Deadline Aufgabe: ' + this.taskForm.get('markup.topic.title')?.value,
      description: 'Fertigstellung der Aufgabe: ' + this.taskForm.get('markup.topic.title')?.value,
      url: 'https://www.thinkbic.de/'
    }]
    let content = createEvent(events)
    return btoa(content)
  }


  get task(): Task {
    return this.taskForm.value
  }


  reload() {
    this.taskControllerService.getComments(this.task.id!).subscribe(comments => {
      // this.taskForm.patchValue({ comments: comments });
      comments?.forEach(comment => {
        this.commentsComponent?.addCommentFrom(comment);
      })
    });
  }

  compareProjects(p1: Project, p2: Project): boolean {
    return p1?.id === p2?.id;
  }

  compareUsers(u1: User, u2: User): boolean {
    return u1.id === u2.id;
  }

  keepOrder = (a: any, b: any) => {
    return a;
  }

  openDeleteDialog() {
    let dialogRef = this.dialog.open(TaskDeleteDialog, {
      panelClass: "rounded-corners-dialog",
      data: {task: this.task}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.close();
    });

  }

  resetUsers(project: Project) {
    if (project) {
      this.usersObservable = this.projectControllerService.getUsers(project.id!);
      this.taskForm.controls.users.enable()
    } else {
      this.usersObservable = undefined;
      this.taskForm.patchValue({users: this.user})
      this.userSelection?.focus();
    }

  }

  unsubscribe: Subject<void> = new Subject<void>();


  /**
   * Set assignedTo User
   * @param user
   * @param event
   */
  setSelectedUser(user: User, event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      this.selectedUser = user;
      for (let i = 0; i < this.taskForm.controls.informedUsers.value.length; i++) {
        if (this.taskForm.controls.informedUsers.value[i].id === user.id) {
          this.taskForm.controls.informedUsers.setValue(this.taskForm.controls.informedUsers.value.filter((u: User) => u.id !== user.id));
          return;
        }
      }
    }
  }

  toggleCheckbox(event: Event, user: User): void {
    event.preventDefault();
    if (this.informedUsers.some((u: User) => u.id === user.id)) {
      this.deselectUser(user);
    } else {
      this.selectUser(user);
    }
  }



  selectUser(user: User): void {
    this.informedUsers.push(user);
    this.taskForm.get('informedUsers')?.setValue(this.informedUsers);

    // let informedUsers = this.taskForm.get('informedUsers')?.value;
    // console.log("HIER")
    // console.log(this.taskForm.get('informedUsers') as UntypedFormArray)
    // console.log(informedUsers)
    // console.log("HIER ENDE")
    // if (!Array.isArray(informedUsers)) {
    //   informedUsers = [];
    // }
    // let test = [];
    // for (let i = 0; i < informedUsers.length; i++) {
    //   test.push(informedUsers[i]);
    // }
    // console.log(test)
    //
    //
    // if (!informedUsers.some((u: User) => u.id === user.id)) {
    //   informedUsers = [...informedUsers, user];
    // }
    // this.taskForm.get('informedUsers')?.setValue(informedUsers);
    // console.log(this.taskForm.get('informedUsers')?.value);
  }

  deselectUser(user: User): void {
    this.informedUsers = this.informedUsers.filter((u: User) => u.id !== user.id);
    this.taskForm.get('informedUsers')?.setValue(this.informedUsers);
  }

  private createCommentGroup(comment:any): FormGroup {
    return this.formBuilder.group({
      atGuid: new UntypedFormControl(),
      date: new UntypedFormControl(),
      author: new UntypedFormControl(),
      comment: new UntypedFormControl(),
      viewpoint: new UntypedFormControl(),
      modifiedDate: new UntypedFormControl(),
      modifiedAuthor: new UntypedFormControl(),
      authorUser: new UntypedFormControl(),
      modifiedAuthorUser: new UntypedFormControl(),
    });
  }

  protected readonly Task = Task;
  protected readonly LphEnum = LphEnum;

  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faX = faX;
  protected readonly faPaperPlaneTop = faPaperPlaneTop;
  protected readonly faCalendarDays = faCalendarDays;
  protected readonly faUserPlus = faUserPlus;
  protected readonly faUserGroup = faUserGroup;
}


@Component({
  selector: 'app-task-deleteDialog',
  templateUrl: './task-delete-dialog.component.html',
  styleUrls: ['./task-detail.component.scss'],
})
export class TaskDeleteDialog implements OnInit {
  @ViewChild(MatRipple) ripple!: MatRipple;


  constructor(private router: Router, private taskControllerService: TaskControllerService, @Inject(MAT_DIALOG_DATA) public data: {
    task: Task
  }, private dialogRef: MatDialogRef<TaskDeleteDialog>, private taskDetailsService: TaskDetailsService) {
  }

  holdHandler(e: any) {
    if (e === 3000) {
      this.dialogRef.close();
      this.taskControllerService.deleteTask(this.data.task.id!).subscribe(next => {
        // @ts-ignore
        this.taskDetailsService.currentTaskDeleted.next(this.data.task);
      });
    }
  }

  ngOnInit() {
  }




}
