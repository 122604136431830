<div class="flex flex-col h-full w-full justify-start items-start bg-[#FFF]">
  <app-bim-base-check-stepper class="w-full"></app-bim-base-check-stepper>
  <!--bic-button dropdownTriggerFor="dropdownBIM"
              [text]="'Alle Eigenschaften'"
              [textColor]="'gray-900'" [color]="'white'" [border]="true"></bic-button>


  <bic-dropdown dropdownId="dropdownBIM">
    <bic-dropdown-element>
      TEST
    </bic-dropdown-element>
    <bic-dropdown-element>
      TEST
    </bic-dropdown-element>
    <bic-dropdown-element>
      TEST
    </bic-dropdown-element>
    <bic-dropdown-element>
      TEST
    </bic-dropdown-element>
  </bic-dropdown>

  <bic-select-- text="Test" [iconRight]="faChevronLeft" dropdownDirection="right" textColor="primary" color="white">
    <bic-dropdown-element *ngFor="let columnFor of columns" [value]="columnFor">
      {{ columnFor }}
    </bic-dropdown-element>
  </bic-select-->

  <div class="flex flex-col h-full w-full justify-start items-start gap-6 px-6 py-12 overflow-hidden">
    <div class="flex flex-row w-full gap-6">
      <div class="flex flex-col w-1/2">
        <div class="flex flex-row">
          <p class="text-gray-900">Dateiauswahl:</p>
        </div>
      </div>

      <div class="flex flex-col w-1/2">
        <div class="flex flex-row">
          <p class="text-gray-900">Ausgewählte Dateien:</p>
        </div>
      </div>

    </div>

    <div class="flex flex-row overflow-hidden h-full w-full gap-6">
      <div class="flex flex-col w-1/2">
        <div class=" w-full h-full rounded-md">
          <div class="w-full h-full overflow-y-scroll">
            <!--app-files-table [isBim]="true"
                             [projectFiles]="ifcFiles"
                             [runningDocumentClassification]="runningDocumentClassification"
                             [selectedFiles]="selectedIFCFiles"
                             class="flex flex-row ">
            </app-files-table-->
          <bic-table [data]="ifcFiles" [columnNames]="columns" [columns]="column" [columnTypes]="colTypes"
                     [searchbar]="true" [selectionFields]="true" (selectedRowsChanged)="selectedIFCFiles=$event" [selectedRows]="selectedIFCFiles"
                     [columnWidths]="['55%', '20%', '25%']" [columnAlignments]="['start', 'center', 'center']"></bic-table>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-1/2 gap-4">
        <div class="flex flex-row h-full w-full">
          <div class="flex flex-col gap-4 h-full w-full">
            <div class="flex flex-col justify-start items-start rounded-lg border border-gray-200 h-full w-full">
              <div class="flex flex-row w-full text-gray-500 text-center justify-center items-center text-sm bg-gray-50 border-b border-gray-200 rounded-t-lg p-4">IFC-Modell</div>
              <div class="overflow-y-scroll w-full items-stretch">
                <div *ngFor="let file of getSelectedIFCFiles()"
                     class="flex flex-row justify-between items-center w-full p-4 border-b border-gray-200 hover:bg-gray-100">
                  <div class="flex flex-row justify-between w-[90%]">
                    <span class="w-[60%] text-sm">{{ file.name }}</span>
                    <span class="w-[20%] text-sm">{{ file.size }}</span>
                    <span class="w-[20%] text-sm">{{ file.lastModified | date: 'dd.MM.yyyy HH:mm'}}</span>
                  </div>
                  <div class="flex flex-row justify-end items-center w-[10%]">
                    <fa-icon class="text-gray-200 hover:text-error cursor-pointer" [icon]="faXmark" (click)="removeSelectedIFCFile(file)"></fa-icon>
                  </div>
                </div>
              </div>
              <div *ngIf="getSelectedIFCFiles().length === 0"
                   class="flex flex-row justify-center items-center w-full h-full text-gray rounded-lg pr-4">
                keine Datei ausgewählt
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="flex flex-row w-full justify-center items-center">
      <div
        [tooltip]="selectedIFCFiles.length != 1 ? 'Wählen Sie genau eine Datei aus!' : !permissionService.hasProjectPrivilege('PROJECT_BIM_TOOLS_CHECK') ? 'Keine Berechtigung' : !canCheck ? 'Kein Kontingent verfügbar' : undefined"
        [tooltipDirection]="'top'">
        <!--button (click)="startBimCheck()"
                [disabled]="selectedIFCFiles.selected.length != 1 || !permissionService.hasProjectPrivilege('PROJECT_BIM_TOOLS_CHECK') || !canCheck"
                color="primary"
                mat-raised-button>Überprüfen
          <fa-icon [icon]="faChevronRight" class="text-white"></fa-icon>
        </button-->
        <bic-button (click)="startBimCheck()" [text]="'Überprüfen'"
                    [disabled]="selectedIFCFiles.length != 1 || !permissionService.hasProjectPrivilege('PROJECT_BIM_TOOLS_CHECK') || !canCheck"
                    [iconRight]="faChevronRight">
        </bic-button>
      </div>
    </div>
  </div>
</div>
