<div class="text-xl font-medium m-4">
  Lagerklassen Tool
</div>

<div class="p-4 flex flex-row justify-start items-start gap-4">
  <div class="flex-grow">
    <form [formGroup]="formTRGS">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Lagerklasse 1</mat-label>
        <mat-select formControlName="storageClass1" required>
          <mat-option [value]="'1'"> Explosive Stoffe (1)</mat-option>
          <mat-option [value]="'2A'"> Gase (2A)</mat-option>
          <mat-option [value]="'2B'"> Aerosole (2B)</mat-option>
          <mat-option [value]="'3'"> Entzündbare flüssige Stoffe (3)</mat-option>
          <mat-option [value]="'4.1A'"> Sonstige explosionsgefährliche Stoffe (4.1A)</mat-option>
          <mat-option [value]="'4.1B'"> Entzündbare feste oder desensibilisierte Stoffe (4.1B)</mat-option>
          <mat-option [value]="'4.2'"> Selbstentzündliche Stoffe (4.2)</mat-option>
          <mat-option [value]="'4.3'"> Stoffe, die in Berührung mit Wasser entzündliche Gase bilden (4.3)</mat-option>
          <mat-option [value]="'5.1A'"> Stark oxidierend wirkende Stoffe (5.1A)</mat-option>
          <mat-option [value]="'5.1B'"> Oxidierend wirkende Stoffe (5.1B)</mat-option>
          <mat-option [value]="'5.1C'"> Ammoniumnitrat und ammoniumnitrathaltige Stoffe (5.1C)</mat-option>
          <mat-option [value]="'5.2'"> Organische Peroxide und selbstzersetzliche Stoffe (5.2)</mat-option>
          <mat-option [value]="'6.1A'"> Brennbare akut giftige Stoffe (6.1A)</mat-option>
          <mat-option [value]="'6.1B'"> Nichtbrennbare akut giftige Stoffe (6.1B)</mat-option>
          <mat-option [value]="'6.1C'"> Brennbare giftige oder chronisch wirkende Stoffe (6.1C)</mat-option>
          <mat-option [value]="'6.1D'"> Nichtbrennbare giftige oder chronisch wirkende Stoffe (6.1D)</mat-option>
          <mat-option [value]="'6.2'"> Ansteckungsgefährliche Stoffe (6.2)</mat-option>
          <mat-option [value]="'7'"> Radioaktive Stoffe (7)</mat-option>
          <mat-option [value]="'8A'"> Brennbare ätzende Stoffe (8A)</mat-option>
          <mat-option [value]="'8B'"> Nichtbrennbare ätzende Stoffe (8B)</mat-option>
          <mat-option [value]="'10'"> Brennbare Flüssigkeiten, soweit nicht LGK 3 (10)</mat-option>
          <mat-option [value]="'11'"> Brennbare Feststoffe (11)</mat-option>
          <mat-option [value]="'12'"> Nichtbrennbare Flüssigkeiten (12)</mat-option>
          <mat-option [value]="'13'"> Nichtbrennbare Feststoffe (13)</mat-option>
          <mat-option [value]="'10-13'"> Sonstige brennbare und nichtbrennbare Stoffe (10-13)</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-full">
        <mat-label>Lagerklasse 2</mat-label>
        <mat-select formControlName="storageClass2" required>
          <mat-option [value]="'1'"> Explosive Stoffe (1)</mat-option>
          <mat-option [value]="'2A'"> Gase (2A)</mat-option>
          <mat-option [value]="'2B'"> Aerosole (2B)</mat-option>
          <mat-option [value]="'3'"> Entzündbare flüssige Stoffe (3)</mat-option>
          <mat-option [value]="'4.1A'"> Sonstige explosionsgefährliche Stoffe (4.1A)</mat-option>
          <mat-option [value]="'4.1B'"> Entzündbare feste oder desensibilisierte Stoffe (4.1B)</mat-option>
          <mat-option [value]="'4.2'"> Selbstentzündliche Stoffe (4.2)</mat-option>
          <mat-option [value]="'4.3'"> Stoffe, die in Berührung mit Wasser entzündliche Gase bilden (4.3)</mat-option>
          <mat-option [value]="'5.1A'"> Stark oxidierend wirkende Stoffe (5.1A)</mat-option>
          <mat-option [value]="'5.1B'"> Oxidierend wirkende Stoffe (5.1B)</mat-option>
          <mat-option [value]="'5.1C'"> Ammoniumnitrat und ammoniumnitrathaltige Stoffe (5.1C)</mat-option>
          <mat-option [value]="'5.2'"> Organische Peroxide und selbstzersetzliche Stoffe (5.2)</mat-option>
          <mat-option [value]="'6.1A'"> Brennbare akut giftige Stoffe (6.1A)</mat-option>
          <mat-option [value]="'6.1B'"> Nichtbrennbare akut giftige Stoffe (6.1B)</mat-option>
          <mat-option [value]="'6.1C'"> Brennbare giftige oder chronisch wirkende Stoffe (6.1C)</mat-option>
          <mat-option [value]="'6.1D'"> Nichtbrennbare giftige oder chronisch wirkende Stoffe (6.1D)</mat-option>
          <mat-option [value]="'6.2'"> Ansteckungsgefährliche Stoffe (6.2)</mat-option>
          <mat-option [value]="'7'"> Radioaktive Stoffe (7)</mat-option>
          <mat-option [value]="'8A'"> Brennbare ätzende Stoffe (8A)</mat-option>
          <mat-option [value]="'8B'"> Nichtbrennbare ätzende Stoffe (8B)</mat-option>
          <mat-option [value]="'10'"> Brennbare Flüssigkeiten, soweit nicht LGK 3 (10)</mat-option>
          <mat-option [value]="'11'"> Brennbare Feststoffe (11)</mat-option>
          <mat-option [value]="'12'"> Nichtbrennbare Flüssigkeiten (12)</mat-option>
          <mat-option [value]="'13'"> Nichtbrennbare Feststoffe (13)</mat-option>
          <mat-option [value]="'10-13'"> Sonstige brennbare und nichtbrennbare Stoffe (10-13)</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="text-l font-medium p-2" *ngIf="trgs">
      <ng-container *ngIf="trgs == 0">
        Die Zusammenlagerung der zwei Stoffe ist zulässig!
      </ng-container>
      <ng-container *ngIf="trgs == 1">
        Die Zusammenlagerung der zwei Stoffe ist zulässig, wenn die Bedingungen wie angegeben eingehalten werden!
      </ng-container>
      <ng-container *ngIf="trgs == 2">
        Die Zusammenlagerung der zwei Stoffe ist nicht zulässig! Diese müssen separat gelagert werden!
      </ng-container>
    </div>

  </div>

  <div class="shadow-full rounded-sm w-1/3 overflow-auto" *ngIf="trgs == 1">
    <div>
      <div class="text-xl font-medium p-2">
        Hinweise
      </div>
      <div class="p-2">
        <ng-container *ngIf="trgsWarningNumber == 1">
          Für Gefahrstoffe der folgenden Lagerklassen sind die spezifischen gesetzlichen Vorschriften mit darin
          enthaltenen Anforderungen an die Zusammenlagerung zu beachten:<br>
          <ul>
            <li>LGK 1 und LGK 4.1A: 2. SprengV</li>
            <li>LGK 5.1C: GefStoffV Anhang I Nummer 5 sowie TRGS 511</li>
            <li>LGK 5.2: DGUV Vorschrift 13 (Die hier genannten Regelungen für die Zusammenlagerung können
              grundsätzlich auch für selbstzersetzliche Gefahrstoffe angewendet werden soweit dies ohne Zuordnung zu
              einer Gefahrgruppe möglich ist)
            </li>
            <li>AtG, StrlSchG und StrlSchV</li>
          </ul>
        </ng-container>
        <ng-container *ngIf="trgsWarningNumber == 2">
          Getrenntlagerung in Räumen (statt Separatlagerung) ist zulässig, wenn
          <ul>
            <li>maximal 50 gefüllte Druckgasbehälter gelagert werden, darunter nicht mehr als 25
              Druckgasbehälter mit akut toxischen Gasen, Kat. 3, H331 oder Kat. 4, H332 (nicht aber Kat. 1 oder Kat. 2,
              H330), entzündbaren Gasen oder oxidierenden Gasen und
            </li>
            <li>die Druckgasbehälter durch eine mindestens 2 m hohe Wand aus nichtbrennbaren Baustoffen
              abgetrennt sind und zwischen Wand und den anderen brennbaren Lager-gütern ein Abstand von mindestens 5 m
              eingehalten wird.
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="trgsWarningNumber == 3">
          Mit verschiedenen Gasen gefüllte Druckgasbehälter dürfen unter folgenden Bedingungen gemeinsam in einem
          Lagerraum gelagert werden:
          <ul>
            <li>Druckgasbehälter mit entzündbaren Gasen, oxidierenden Gasen und akut toxischen Gasen, Kat. 3,
              H331, wenn dabei die Gesamtzahl 150 Druckgasbehälter oder 15 Druckfässer nicht übersteigt. Zusätzlich
              dürfen Druckgasbehälter mit inerten Gasen in beliebiger Menge gelagert werden.
            </li>
            <li>Druckgasbehälter mit entzündbaren Gasen und Druckgasbehälter mit inerten Gasen in beliebiger
              Menge.
            </li>
            <li>Druckgasbehälter mit oxidierenden Gasen und Druckgasbehälter mit inerten Gasen in beliebiger
              Menge.
            </li>
            <li>Druckgasbehälter mit akut toxischen Gefahrstoffen und Druckgasbehälter mit inerten Gasen in
              beliebiger Menge.
            </li>
            <li> In den Fällen a) bis c) dürfen zusätzlich 15 Druckgasbehälter oder ein Druckfass mit akut
              toxischen Gasen, Kat. 1 und 2, H330 gelagert werden. Größere Mengen von Druckgasbehältern mit akut
              toxischen Gasen sind separat zu lagern.
            </li>
            <li>Zwischen Druckgasbehältern mit entzündbaren Gasen und Druckgasbehältern mit oxidierenden
              Gasen muss ein Abstand von mindestens 2 m eingehalten werden.
            </li>
          </ul>
          Für die Lagerung im Freien bestehen keine Einschränkungen.
        </ng-container>
        <ng-container *ngIf="trgsWarningNumber == 4">
          Bei einer Gesamtmenge bis 1 t bestehen für die Zusammenlagerung keine Einschränkungen. <br/>
          Bei einer Gesamtmenge bis 20 t darf eine Zusammenlagerung nur unter folgenden Bedingungen erfolgen: <br/>
          In Gebäuden ist eine automatische Feuerlöschanlage vorhanden oder eine automatische Brandmeldeanlage in
          Verbindung mit einer nicht automatischen Feuerlöschanlage und eine anerkannte Werkfeuerwehr.
        </ng-container>
        <ng-container *ngIf="trgsWarningNumber == 5">
          Im selben Lagerabschnitt dürfen Materialien, die ihrer Art und Menge nach geeignet sind, zur Entstehung oder
          schnellen Ausbreitung von Bränden beizutragen, wie z.B. Papier, Textilien, Holz, Holzwolle, Kartonagen,
          Folien oder brennbare Verpackungsfüllstoffe, nicht gelagert werden, sofern sie nicht für Lagerung und
          Transport eine Einheit mit den ortsbeweglichen Behältern bilden.
        </ng-container>
        <ng-container *ngIf="trgsWarningNumber == 6">
          Die Gefahrstoffe dürfen mit Gefahrstoffen anderer Lagerklassen, denen die Nr. 6 zugeordnet ist und mit anderen
          Materialien nur zusammen gelagert werden, wenn dadurch eine wesentliche Gefährdungserhöhung nicht eintreten
          kann.
          Eine wesentliche Gefährdungserhöhung kann durch eine Getrenntlagerung vermieden werden.
        </ng-container>
        <ng-container *ngIf="trgsWarningNumber == 7">
          Zusammenlagerung mit brennbaren Lagergütern darf unter den Bedingungen nach Erläuterung Nr. 4 und Erläuterung
          Nr. 5 erfolgen:<br/>
          „Bei einer Gesamtmenge bis 1 t bestehen für die Zusammenlagerung keine Einschränkungen.<br/>
          Bei einer Gesamtmenge bis 20 t darf eine Zusammenlagerung nur unter folgenden Bedingungen erfolgen:<br/>
          In Gebäuden ist eine automatische Feuerlöschanlage vorhanden oder eine automatische Brandmeldeanlage in
          Verbindung mit einer nicht automatischen Feuerlöschanlage und eine anerkannte Werkfeuerwehr.“<br/>
          und<br/>
          „Im selben Lagerabschnitt dürfen Materialien, die ihrer Art und Menge nach geeignet sind, zur Entstehung oder
          schnellen Ausbreitung von Bränden beizutragen, wie z.B. Papier, Textilien, Holz, Holzwolle, Kartonagen, Folien
          oder brennbare Verpackungsfüllstoffe, nicht gelagert werden, sofern sie nicht für Lagerung und Transport eine
          Einheit mit den ortsbeweglichen Behältern bilden."
        </ng-container>
        <ng-container *ngIf="trgsWarningNumber == 8">
          Bei einer Gesamtmenge bis 1 t bestehen für die Zusammenlagerung keine Einschränkungen.<br/>
          Bei einer Gesamtmenge bis 20 t darf eine Zusammenlagerung nur unter folgenden Bedingungen erfolgen:<br/>
          In Gebäuden ist eine automatische Brandmeldeanlage vorhanden.<br/>
          Im Freien ist eine automatische Brandmeldeanlage vorhanden oder Branderkennung und Brandmeldung durch
          stündliche Kontrollen mit Meldemöglichkeiten (wie z.B. Telefon, Feuermelder, Funkgerät) gewährleistet.<br/>
          Bei einer Gesamtmenge bis 50 t darf eine Zusammenlagerung nur unter folgenden Bedingungen erfolgen:<br/>
          Eine automatische Brandmeldeanlage ist vorhanden und die Feuerwehr erreicht die Brandstelle innerhalb von zehn
          Minuten nach Alarmierung.<br/>
          Bei einer Gesamtmenge bis 50 t darf eine Zusammenlagerung nur unter folgenden Bedingungen erfolgen:<br/>
          Eine automatische Brandmeldeanlage ist vorhanden und die Feuerwehr erreicht die Brandstelle innerhalb von zehn
          Minuten nach Alarmierung.<br/>
          Bei einer Gesamtmenge bis 100 t darf eine Zusammenlagerung nur unter folgenden Bedingungen erfolgen:<br/>
          Eine automatische Feuerlöschanlage ist vorhanden oder eine automatische Brandmeldeanlage in Verbindung mit
          einer nicht automatischen Feuerlöschanlage und einer anerkannten Werkfeuerwehr.
        </ng-container>
      </div>
    </div>
  </div>
</div>
