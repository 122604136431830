<div class="flex flex-col w-full pb-2">
  <div *ngIf="authService.isLoggedIn() || (oauthService.hasValidAccessToken && oauthService.hasValidIdToken())"
       class="flex flex-col inset-x-0 bottom-0 w-full">

    <div class="flex flex-row py-2 px-4 w-full">
      <div class=" w-full border-t border border-white rounded-lg opacity-100"></div>
    </div>

<!--Lower Actions Level-->
    <div *ngIf="authService.isLoggedIn()" class="flex flex-row w-full justify-between items-center px-6">


      <button class="bg-[rgba(255,255,255,0.35)] rounded-full h-8 w-8 hover:bg-[rgba(217,224,226,0.45)] hover:cursor-pointer" tooltip="Shop" tooltipDirection="top"
        (click)="performRouteLink('checkout')">
        <fa-icon class="text-white text-[18px]" [icon]="faBagShopping"></fa-icon>
      </button>

      <button class="bg-[rgba(255,255,255,0.35)] rounded-full h-8 w-8 hover:bg-[rgba(217,224,226,0.45)] hover:cursor-pointer" tooltip="Profil" tooltipDirection="top"
        (click)="performRouteLink('profile')">
        <fa-icon class="text-white text-[18px]" [icon]="faUser"></fa-icon>
      </button>

<!--  Cloud loading icon, currently disabled-->
      <div *ngIf="false && stateConfigService.runningChecks.length > 0">
        <div (mouseenter)="showAnalysisMenu = false" (mouseleave)="showAnalysisMenu = false" cdkOverlayOrigin routerLink="reports"
             #trigger="cdkOverlayOrigin" class="hover:bg-gray-500 hover:cursor-pointer hover:rounded-lg">
          <app-cloud-number [loading]="true"
                            [analysisLoading]="true" matTooltip="Mindestens eine Prüfung läuft" [matTooltipClass]="'blue-tooltip'"></app-cloud-number>
        </div>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="showAnalysisMenu"
        >
          <mat-card appearance="outlined">
            <h2>Laufende Analysen</h2>
            <mat-dialog-content>
              <div *ngFor="let analysis of stateConfigService.runningChecks | keyvalue" class="flex flex-row">
                <h4>{{ analysis.key }}:&nbsp;</h4>
                <ng-container *ngFor="let lphCheck of analysis.value">
                  <h4>{{ lphCheck }}&nbsp;</h4>
                </ng-container>
              </div>
            </mat-dialog-content>
          </mat-card>
        </ng-template>
      </div>


      <button class="bg-[rgba(255,255,255,0.35)] rounded-full h-8 w-8 hover:bg-[rgba(217,224,226,0.45)] hover:cursor-pointer" tooltip="Abmelden" tooltipDirection="top"
        (click)="performRouteLink('logout')">
        <fa-icon class="text-white text-[18px]" [icon]="faRightFromBracket"></fa-icon>
      </button>
    </div>
  </div>

</div>
