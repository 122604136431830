<form [formGroup]="formGroup" class="flex flex-col justify-between">
  <ng-container formGroupName="markup">
    <ng-container formArrayName="comment">

      <div [formGroup]="newComment" class="h-full flex flex-row">

        <div class="flex flex-row w-full gap-4 justify-center items-end">
<!--          <form class="w-full">-->
            <div class="w-full mb-4">
<!--              <div class="flex items-center justify-end px-3 py-2 border-b dark:border-gray-600">-->
<!--                <div class="flex ps-0 space-x-1 rtl:space-x-reverse">-->
<!--                  <button type="button" class="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">-->
<!--                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 20">-->
<!--                      <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M1 6v8a5 5 0 1 0 10 0V4.5a3.5 3.5 0 1 0-7 0V13a2 2 0 0 0 4 0V6"/>-->
<!--                    </svg>-->
<!--                    <span class="sr-only">Attach file</span>-->
<!--                  </button>-->
<!--                  <button type="button" class="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">-->
<!--                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">-->
<!--                      <path d="M8 0a7.992 7.992 0 0 0-6.583 12.535 1 1 0 0 0 .12.183l.12.146c.112.145.227.285.326.4l5.245 6.374a1 1 0 0 0 1.545-.003l5.092-6.205c.206-.222.4-.455.578-.7l.127-.155a.934.934 0 0 0 .122-.192A8.001 8.001 0 0 0 8 0Zm0 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>-->
<!--                    </svg>-->
<!--                    <span class="sr-only">Set location</span>-->
<!--                  </button>-->
<!--                  <button type="button" class="inline-flex justify-center items-center p-2 text-gray-500 rounded cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">-->
<!--                    <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">-->
<!--                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>-->
<!--                    </svg>-->
<!--                    <span class="sr-only">Upload image</span>-->
<!--                  </button>-->
<!--                </div>-->

<!--              </div>-->
<!--              <div class="px-4 py-2 bg-white rounded-b-lg dark:bg-gray-800">-->
<!--                <label for="comment" class="sr-only">Nachricht hier eingeben</label>-->
<!--                <textarea formControlName="comment" id="comment" rows="2" class="w-full p-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" placeholder="Nachricht hier eingeben" required ></textarea>-->
                <textarea id="comment" formControlName="comment"
                          placeholder="Nachricht hier eingeben"
                          class="select-none cursor-pointer focus:ring-[none] focus:border-secondary ring-[none] border text-sm rounded-lg placeholder-gray-400 block w-full p-2.5 border-gray-300 bg-gray-50 resize-none"></textarea>
<!--              </div>-->
            </div>
          <bic-button class="mb-4" [color]="'white'" [text]="'Senden'" [textColor]="'gray-900'"[border]="true" [iconRight]="faPaperPlaneTop" (onClick)="addComment(newComment.value)"></bic-button>
<!--          </form>-->
        </div>

<!--        -->
<!--        <angular-editor formControlName="comment" class="pr-5"-->
<!--                        [config]="editorConfig"></angular-editor>-->
<!--        <button mat-raised-button class="!bg-white" (click)="addComment(newComment.value)">Senden</button>-->
      </div>

      <div class="flex flex-col w-full h-[135px] overflow-hidden">
        <div class="flex flex-col w-full h-full overflow-y-scroll gap-5">
          <div *ngFor="let commentForm of comments.controls; let i = index">
            <div class="flex flex-col w-full gap-3">
              <div class="flex flex-row w-full justify-between">
                <div class="flex">
                  <span class="text-sm font-semibold text-gray-900 pr-3">{{ comment(i)?.authorUser?.firstName }} {{ comment(i)?.authorUser?.lastName }}</span>
                  <span class="text-sm text-gray-600">{{ comment(i)?.date | date: "dd.MM.yyyy" }}</span>
                  <span *ngIf="comment(i)?.modifiedDate" class="text-sm text-gray-600">• Geändert am {{ comment(i)?.modifiedDate | date: "dd.MM.yyyy" }}</span>
                </div>
                <div [bicDropdownTrigger]="dropdown" class="hover:cursor-pointer flex w-4 justify-center">
                  <fa-icon class="text-sm text-bic-gray" [icon]="faEllipsisVertical"></fa-icon>
                </div>
              </div>
              <div class="flex flex-row w-full text-base text-gray-500">
                {{ comment(i)?.comment }}
              </div>
              <!--            <div class="flex flex-row w-full gap-4">-->
              <!--              <span class="text-sm text-gray-500">Bearbeiten</span>-->
              <!--              <div class="flex flex-row gap-1">-->
              <!--                <fa-icon class="text-sm text-gray-400" [icon]="faReply"></fa-icon>-->
              <!--                <span class="text-sm text-gray-500">Antworten</span>-->
              <!--              </div>-->
              <!--            </div>-->

            </div>

            <bic-dropdown #dropdown [dropdownPlacement]="'bottom-start'">
              <!--            <bic-dropdown-element>-->
              <!--              <div class="flex flex-row text-sm gap-3 text-gray-700">-->
              <!--                <fa-icon class="text-gray-700" [icon]="faReply"></fa-icon>-->
              <!--                <span>Antworten</span>-->
              <!--              </div>-->
              <!--            </bic-dropdown-element>-->
              <!--            <bic-dropdown-element (click)="setEditComment(i,true)">-->
              <!--              <div class="flex flex-row text-sm gap-3 text-gray-700">-->
              <!--                <fa-icon class="text-gray-700" [icon]="faPenToSquare"></fa-icon>-->
              <!--                <span>Bearbeiten</span>-->
              <!--              </div>-->
              <!--            </bic-dropdown-element>-->
              <!--            <bic-seperator></bic-seperator>-->
              <bic-dropdown-element (onClick)="deleteComment(i)">
                <div class="flex flex-row text-sm gap-3 text-error">
                  <fa-icon class="text-error" [icon]="faTrash"></fa-icon>
                  <span>Löschen</span>
                </div>
              </bic-dropdown-element>
            </bic-dropdown>

          </div>
        </div>

      </div>



<!--      <div *ngFor="let commentForm of comments.controls; let i = index">-->
<!--        <div class="flex flex-row w-full" *ngIf="!empty"-->
<!--             [class.ownOuterDiv]="!comment(i).authorUser || comment(i).authorUser?.id == user.id"-->
<!--             [class.otherOuterDiv]="comment(i).authorUser?.id != user.id">-->
<!--        <mat-card appearance="outlined" class="w-[80%] !mx-0"-->
<!--                  [class.ownComment]="!comment(i).authorUser || comment(i).authorUser?.id == user.id"-->
<!--                  [class.otherComment]="comment(i).authorUser?.id != user.id">-->
<!--          <div class="flex flex-row justify-between items-center pl-2">-->
<!--            <mat-card-subtitle *ngIf="comment(i)?.authorUser">{{ comment(i)?.authorUser?.firstName }} {{ comment(i)?.authorUser?.lastName }}-->
<!--              - {{ comment(i)?.date | date: "dd.MM.yyyy - HH:mm" }}-->
<!--            </mat-card-subtitle>-->
<!--            <mat-card-subtitle *ngIf="!comment(i)?.authorUser">{{ comment(i)?.author }}-->
<!--              - {{ comment(i)?.date | date: "dd.MM.yyyy - HH:mm" }}-->
<!--            </mat-card-subtitle>-->
<!--            <div *ngIf="!comment(i).authorUser || comment(i).authorUser?.id == user.id">-->
<!--              <div class="flex flex-row">-->
<!--                <button mat-icon-button class="pr-2" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{index: i}">-->
<!--                  <fa-icon class="text-xl"-->
<!--                           [class.ownIcon]="!comment(i).authorUser || comment(i).authorUser?.id == user.id"-->
<!--                           [class.otherIcon]="comment(i).authorUser?.id != user.id"-->
<!--                           [icon]="faEllipsisVertical"></fa-icon>-->
<!--                </button>-->
<!--              </div>-->
<!--              &lt;!&ndash;button *ngIf="editComment(i)" mat-icon-button (click)="deleteComment(i)">-->
<!--                <mat-icon>delete</mat-icon>-->
<!--              </button>-->
<!--              <button *ngIf="editComment(i)" mat-icon-button (click)="saveTask.emit(); setEditComment(i, false)">-->
<!--                <mat-icon>save</mat-icon>-->
<!--              </button>-->
<!--              <button *ngIf="!editComment(i)" mat-icon-button (click)="setEditComment(i, true)">-->
<!--                <mat-icon>edit</mat-icon>-->
<!--              </button&ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flex flex-row justify-between pr-2 pb-2">-->
<!--          <ng-container *ngIf="editComment(i) && (!comment(i).authorUser || comment(i).authorUser?.id == user.id)">-->
<!--            <div [formGroup]="commentControl(i)" class="h-full px-2">-->
<!--              <angular-editor [id]="comment(i).atGuid" formControlName="comment"-->
<!--                              [config]="editorConfig"></angular-editor>-->
<!--            </div>-->
<!--          </ng-container>-->

<!--          <ng-container *ngIf="!editComment(i) || (comment(i).authorUser && comment(i).authorUser?.id != user.id)">-->
<!--            <div [formGroup]="commentControl(i)" class="pl-2">-->
<!--              <div [innerHTML]="comment(i).comment"></div>-->
<!--            </div>-->
<!--          </ng-container>-->
<!--          <img *ngIf="getSnapshotURL(comment(i))" (click)="openIFC()" [src]="getSnapshotURL(comment(i))" class="w-[20%] p-1 rounded-md bg-white">-->
<!--          </div>-->
<!--        </mat-card>-->

<!--        </div>-->

<!--      </div>-->


      <!--button mat-raised-button color="primary" (click)="addComment()">
        Kommentar hinzufügen
      </button-->
    </ng-container>
  </ng-container>
</form>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-i="index">
    <button mat-menu-item (click)="deleteComment(i)">
      Löschen
    </button>
    <button mat-menu-item *ngIf="editComment(i)" (click)="saveTask.emit(i); setEditComment(i, false)">
      Speichern
    </button>
    <button mat-menu-item (click)="setEditComment(i,true)">
      Bearbeiten
    </button>
  </ng-template>
</mat-menu>
