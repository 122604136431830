import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {
  Organisation,
  OrganisationControllerService,
  Project,
  ProjectControllerService,
  User,
  UserSimpleDto
} from "../../../../api";
import {ActivatedRoute} from "@angular/router";
import {COMMA, ENTER, SPACE} from "@angular/cdk/keycodes";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {ThemePalette} from "@angular/material/core";
import {faPlus, faUserPlus} from "@fortawesome/free-solid-svg-icons";



export interface ChipColor {
  name: string;
  color: ThemePalette;
}
@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {

  organisation!: Organisation;
  project!: Project;

  organisationUsers: User[] = [];
  projectUsers: User[] = [];
  filteredUsers: User[] = [];
  usersToAdd: string[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  emailRegx = /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,100}))$/;

  @ViewChild('userMail') userMail!: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { isProject?: boolean, project: Project, organisation: Organisation },
              public organisationControllerService: OrganisationControllerService,
              public projectControllerService: ProjectControllerService,
              public snackBar: MatSnackBar,
              public dialogRef: MatDialogRef<AddUserDialogComponent>,
              public route: ActivatedRoute,
              private stateConfigService: StateConfigService) {
  }

  ngOnInit(): void {
    this.project = this.stateConfigService.getProject()!;
    let organisationId = this.data.organisation?.id;
    if (this.stateConfigService.getOrganisationId() != 0  && organisationId != undefined) {
      this.organisationControllerService.getOrganisation(organisationId!).subscribe(organisation => {
        this.organisation = organisation;
        this.organisationControllerService.getOrganisationUsers(this.organisation.id!).subscribe(users => {
          this.organisationUsers = users;
          if(this.project != undefined) {
            this.projectControllerService.getUsers(this.project.id!).subscribe(users => {
              this.projectUsers = users;
              this.filteredUsers = this.organisationUsers.filter(user => !(this.projectUsers.filter(projectUser => projectUser.id == user.id).length > 0));
            });
          }
        });
      });
    }
  }

  addUserByMails(): void {
    if (this.emailRegx.test(this.userMail.nativeElement.value)) {
      this.usersToAdd.push(this.userMail.nativeElement.value);
      this.userMail.nativeElement.value = "";
    } else {
      if(this.userMail.nativeElement.value != ""){
        this.snackBar.open('Bitte geben Sie eine gültige Emailadresse an!', '', {duration: 2000});
      }
    }

    if (this.data.isProject) {
      var projectUsers: UserSimpleDto[] = [];
      this.projectControllerService.getUsers(this.project.id!).subscribe(users => {
        projectUsers = users;
        this.usersToAdd.forEach(mail => {
          if(projectUsers.filter(user => user.mail == mail).length == 0) {
              this.snackBar.open(`${mail} wurde zu Projekt ${this.project.name} eingeladen`, 'OK', {duration: 3000});
              if(this.usersToAdd.indexOf(mail) == this.usersToAdd.length - 1) {
                this.projectControllerService.inviteToProject(this.usersToAdd, this.project.id!).subscribe(() => {
                  this.dialogRef.close();
                });
              }
          }else{
            this.snackBar.open('Der Account mit der Email ' + mail + ' ist bereits Mitglied des Projekts!', '', {duration: 5000});
            this.usersToAdd.filter(user => user != mail);
            if(this.usersToAdd.indexOf(mail) == this.usersToAdd.length - 1) {
              this.dialogRef.close();
            }
          }
        })
      });

    } else {
      var organisationUsers: UserSimpleDto[] = [];
      this.organisationControllerService.getOrganisationUsers(this.organisation.id!).subscribe(users => {
        organisationUsers = users;
        this.usersToAdd.forEach(mail => {
          if(organisationUsers.filter(user => user.mail == mail).length == 0) {
              this.snackBar.open(`${mail} wurde zu Organisation ${this.organisation.name} eingeladen`, 'OK', {duration: 3000});
              if(this.usersToAdd.indexOf(mail) == this.usersToAdd.length - 1) {
                this.organisationControllerService.inviteToOrganisation(this.organisation.id!, this.usersToAdd).subscribe(() => {
                  this.dialogRef.close();
                });
              }
          }else{
            this.snackBar.open('Der Account mit der Email ' + mail + ' ist bereits Mitglied der Organisation!', '', {duration: 5000});
            this.usersToAdd.filter(user => user != mail);
            if(this.usersToAdd.indexOf(mail) == this.usersToAdd.length - 1) {
              this.dialogRef.close();
            }
          }
        })
      });
    }
  }

  removeFromToAdd(mail: string): void {
    const index = this.usersToAdd.indexOf(mail);

    if (index >= 0) {
      this.usersToAdd.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      if(this.emailRegx.test(value)) {
        this.usersToAdd.push(value);
        // Clear the input value
        event.chipInput!.clear();
      }
    }


  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.usersToAdd.push(event.option.viewValue);
    this.userMail.nativeElement.value = '';
  }

  protected readonly faTimes = faTimes;
  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faPlus = faPlus;
  protected readonly faUserPlus = faUserPlus;
}
