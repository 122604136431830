import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CircleState} from "../../util/design/circle-state/circle-state.component";
import {
  faBars,
  faChevronRight,
  faClipboardCheck,
  faClipboardList,
  faFilter,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import {MatTableDataSource} from "@angular/material/table";
import {HttpClient} from "@angular/common/http";
import {TaskDetailsService} from "../../../services/task-details.service";
import {ComplianceDocuments, ComplianceDocumentsDocuments, ProjectFile, QualityCheck, Task, QualityControllerService} from "../../../api";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute, Router} from "@angular/router";
import {StateConfigService} from "../../../services/state/state-config.service";
import {faCloud} from "@fortawesome/pro-solid-svg-icons";
import {faBicErrorCloud} from "@awesome.me/kit-6ed88ea8d1/icons/modules/kit/custom";

export interface ComplianceCheck {
  component: string,
  requiredInformation: string,
  status: string,
  response: string,
  documents: Document[]
}

export interface Document {
  extractionId: string;
  docType: string;
  required: boolean;
  values: string[] | number[];
}

interface ConsolidatedData {
  name: string | number;
  lv: Map<string, boolean>
  ifc: Map<string, boolean>
  rb: Map<string, boolean>
}


@Component({
  selector: 'app-confirmation-check-report',
  templateUrl: './quality-compliance-report.component.html',
  styleUrls: ['./quality-compliance-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class QualityComplianceReport implements OnInit {

  qualityCheck!: QualityCheck
  filesByExtractionIds: Map<string, ProjectFile> = new Map()

  displayedColumns: string[] = ['component', 'requiredInformation', 'state', 'result'];
  displayedSubColumns: string[] = [];
  dataSource: MatTableDataSource<ComplianceDocuments> = new MatTableDataSource<ComplianceDocuments>();
  selectedDataSources = new Map<ComplianceDocuments, MatTableDataSource<ConsolidatedData>>();
  expandedElements: ComplianceDocuments[] = [];
  widthString: string = '';

  filteredLVDocs: string[] = [];
  filteredRoomDocs: string[] = [];
  filteredIfcDocs: string[] = [];

  selectedLVFile: string = "";
  selectedRoomFile: string = "";
  selectedIfcFile: string = "";

  constructor(http: HttpClient,
              private activatedRoute: ActivatedRoute,
              private taskDetailService: TaskDetailsService,
              public qualityControllerService: QualityControllerService,
              private stateConfigService: StateConfigService,
              private router: Router,
              private cdr: ChangeDetectorRef) {
  }

  reloadCheck(): void {
    this.qualityControllerService.getCheck1(this.qualityCheck.id!, this.stateConfigService.getProjectId()).subscribe((check) => {
      this.qualityCheck = check;
    });
  }

  ngOnInit() {

    this.qualityCheck = this.activatedRoute.parent?.parent?.snapshot.data.qualityCheck;
    for (let fileId in this.qualityCheck.extractionIdsOfProjectFiles) {
      const extractionId = this.qualityCheck.extractionIdsOfProjectFiles[fileId]
      const projectFile = Object
        .values(this.qualityCheck.projectFiles!)
        .flat()
        .filter(p => p.id == fileId)
        .pop()!

      this.filesByExtractionIds.set(extractionId, projectFile)
    }

    this.widthString = '20';
    let temp =  this.qualityCheck.complianceResult!
    let docDocuments = temp.map((data: { documents: any; }) => data.documents).flat();
    let docTypes = new Set(docDocuments.map((data: { docType: any; }) => data.docType));
    //Originally 54% but for only 2 too big -> Needed for Telsa Presentation
    this.widthString = '' + 44/docTypes.size;




    let requiredInformation = new Map<String, ComplianceDocuments>
    temp.forEach((data) => {
      if(!requiredInformation.has(data.requiredInformation)){
        requiredInformation.set(data.requiredInformation, data)
      }else{
        let oldData = requiredInformation.get(data.requiredInformation)
        if(oldData){
          let newDocuments = oldData.documents.concat(data.documents);
          // @ts-ignore
          newDocuments = Array.from(new Set(newDocuments.map(JSON.stringify))).map(JSON.parse);
          oldData.documents = newDocuments
        }
      }
    });

    temp.forEach((data) => {
      this.filteredIfcDocs = this.getDocuments(data, "IFC", this.filteredIfcDocs);
      this.filteredLVDocs = this.getDocuments(data, "LV", this.filteredLVDocs);
      this.filteredRoomDocs = this.getDocuments(data, "RB", this.filteredRoomDocs);
    });
    if(this.filteredLVDocs.length > 0){
      this.displayedSubColumns.push("LV")
    }if(this.filteredRoomDocs.length > 0){
      this.displayedSubColumns.push("Room")
    }if (this.filteredIfcDocs.length > 0){
      this.displayedSubColumns.push("ifc")
    }


    this.displayedSubColumns.push("task")
    this.displayedColumns = this.displayedColumns.concat(this.displayedSubColumns)
    this.displayedSubColumns = ["dummy"].concat(this.displayedSubColumns)


    // set default files
    this.selectedIfcFile = this.setDefaultFile(this.filteredIfcDocs, this.selectedIfcFile);
    this.selectedRoomFile = this.setDefaultFile(this.filteredRoomDocs, this.selectedRoomFile);
    this.selectedLVFile = this.setDefaultFile(this.filteredLVDocs, this.selectedLVFile);


    this.dataSource.data = Array.from(requiredInformation.values())
  }

  get totalRules() {
    return this.dataSource.data.length;
  }

  projectFile(extractionId: string): ProjectFile {
    return this.filesByExtractionIds.get(extractionId)!
  }

  get numberOfCheckedFiles() {
    return this.filteredIfcDocs.length + this.filteredLVDocs.length + this.filteredRoomDocs.length;
  }


  selectElement(element: ComplianceDocuments) {
    if (this.expandedElements.includes(element)) {
      this.expandedElements.splice(this.expandedElements.indexOf(element), 1)
      this.selectedDataSources.delete(element)
    } else {
      this.expandedElements.push(element)
      this.selectedDataSources.set(element, new MatTableDataSource<ConsolidatedData>(this.prepareDataForSubTable(element)))
    }
  }

  updateExpandedElements() {
    this.selectedDataSources.forEach((value, key) => {
      this.selectedDataSources.set(key, new MatTableDataSource<ConsolidatedData>(this.prepareDataForSubTable(key)))
    });
    this.cdr.detectChanges()
  }

  prepareDataForSubTable(complianceCheck: ComplianceDocuments) {
    let consolidatedData: ConsolidatedData[] = [];

    // Create a map to consolidate all values from all documents
    const allValuesMap = new Map<string, Map<string, Set<string | number>>>();

    // Consolidate values from all documents by name of the document
    complianceCheck.documents.forEach(doc => {
      if (!allValuesMap.has(doc.docType ?? '')) {
        allValuesMap.set(doc.docType  ?? '', new Map<string, Set<string | number>>());
      }
      // @ts-ignore
      allValuesMap.get(doc.docType)?.set(doc.extractionId, new Set(doc.values));
    });

    // Create consolidated data based on the files found in the compliance check
    allValuesMap.forEach((maps, key, docType) => {
      maps.forEach((values, filename) => {
        if(filename == this.selectedIfcFile || filename == this.selectedRoomFile || filename == this.selectedLVFile) {
          values.forEach((value) => {

            const existingRow = consolidatedData.find(row => row.name === value);

            if (existingRow) {
              if (key == 'IFC')
                existingRow.ifc = existingRow.ifc.set(filename, true);
              if (key == 'LV')
                existingRow.lv = existingRow.lv.set(filename, true);
              if (key == 'RB')
                existingRow.rb = existingRow.rb.set(filename, true);
            } else {
              if (key == 'IFC')
                consolidatedData.push({name: value, ifc: new Map([[filename, true]]), lv: new Map(), rb: new Map()});
              if (key == 'LV')
                consolidatedData.push({name: value, ifc: new Map(), lv: new Map([[filename, true]]), rb: new Map()});
              if (key == 'RB')
                consolidatedData.push({name: value, ifc: new Map(), lv: new Map(), rb: new Map([[filename, true]])});
            }
          })
        }
      });
    });
    this.selectedDataSources.set(complianceCheck, new MatTableDataSource(consolidatedData));
    return consolidatedData;
  }

  setDefaultFile(docs: string[], selectedFile: string): string {
    return docs.length > 0 ? docs[0] : selectedFile;
  }

  totalCompleted() {
    let allCompleted = this.dataSource.data.filter(t => t.status === "success").length;
    return Math.round(allCompleted / this.totalRules * 100);
  }

  totalWarning() {
    let allWarning = this.dataSource.data.filter(t => t.status === "warning").length;
    return Math.round(allWarning / this.totalRules * 100);
  }

  totalError() {
    let allFailed = this.dataSource.data.filter(t => t.status === "error").length;
    return Math.round(allFailed / this.totalRules * 100);
  }

  createTask() {
    let task = {
      markup: {
        topic: {
          title: 'Überarbeiten notwendig für: '  //+ element.projectFile.name ?? ' ',
        }
      },
    }
    this.taskDetailService.setOpenedTask(task as Task);
  }

  onIfcFileChange(file: string) {
    this.selectedIfcFile = file;
    this.updateExpandedElements();
  }

  onRoomFileChange(file: string) {
    this.selectedRoomFile = file;
    this.updateExpandedElements();
  }

  onLVFileChange(file: string) {
    this.selectedLVFile = file;
    this.updateExpandedElements();
  }

  get documentsCount() {
    return this.filteredIfcDocs.length + this.filteredLVDocs.length + this.filteredRoomDocs.length;
  }

  getDocuments(complianceCheck: ComplianceDocuments, docType: string, filteredDocs: string[]) {
    const docs = complianceCheck.documents.filter(doc => doc.docType === docType).map(doc => doc.extractionId);
    //@ts-ignore
    filteredDocs.push(...docs);
    return Array.from(new Set(filteredDocs));
  }

  getValues(selectedFile: string, info: string, result: string) {

    let filteredRow = this.dataSource.data.find(data => data.requiredInformation === info && data.response === result);

    if (!filteredRow) {
      return "keine Angabe";
  }

    let filteredDoc: ComplianceDocumentsDocuments | undefined = filteredRow.documents.find(doc => doc.extractionId === selectedFile);

    if (!filteredDoc || (filteredDoc.values!.length ?? 0) === 0) {
      if(filteredRow.status === "success"){
        return "-";
      }
      return "keine Angabe";
  }

    //return filteredDoc.values;
    return "Vorhanden";

  }

  getResponse(row: ComplianceCheck): string {
    let allRelevantElements = this.qualityCheck.complianceResult!.filter((data: { requiredInformation: string; }) => data.requiredInformation === row.requiredInformation);
    // let allRelevantElements =JSON.parse(JSON.stringify(this.jsonFile["Compliance"])).filter((data: { requiredInformation: string; }) => data.requiredInformation === row.requiredInformation);
    let response = ""

    allRelevantElements.forEach((element: { documents: any[]; response: string; }) => {
      let documentsNames = element.documents.map(doc =>  doc.extractionId)
      if(this.selectedIfcFile != "" && this.selectedRoomFile != "" && this.selectedLVFile != ""){
        if(documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedRoomFile) && documentsNames.includes(this.selectedLVFile)){
          response = element.response
        }else if(documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedRoomFile) || documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedLVFile) || documentsNames.includes(this.selectedRoomFile) && documentsNames.includes(this.selectedLVFile)) {
          response = element.response
        }else if(documentsNames.includes(this.selectedIfcFile) || documentsNames.includes(this.selectedRoomFile) || documentsNames.includes(this.selectedLVFile)){
          response = element.response
        }
      }
      else if(this.selectedIfcFile != "" && this.selectedRoomFile != "" && this.selectedLVFile == ""){
        if(documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedRoomFile)){
          response = element.response
        }else if(documentsNames.includes(this.selectedIfcFile) || documentsNames.includes(this.selectedRoomFile)){
          response = element.response
        }
      }
      else if(this.selectedIfcFile != "" && this.selectedRoomFile == "" && this.selectedLVFile != ""){
        if(documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedLVFile)) {
          response = element.response
        }else if (documentsNames.includes(this.selectedIfcFile) || documentsNames.includes(this.selectedLVFile)){
          response = element.response
        }
      }
      else if(this.selectedIfcFile == "" && this.selectedRoomFile != "" && this.selectedLVFile != ""){
        if(documentsNames.includes(this.selectedRoomFile) && documentsNames.includes(this.selectedLVFile)){
          response = element.response
        }else if (documentsNames.includes(this.selectedRoomFile) || documentsNames.includes(this.selectedLVFile)){
          response = element.response
        }
      }
      else if(this.selectedIfcFile != "" && this.selectedRoomFile == "" && this.selectedLVFile == "") {
        if (documentsNames.includes(this.selectedIfcFile)) {
          response = element.response
        }
      }
      else if(this.selectedIfcFile == "" && this.selectedRoomFile != "" && this.selectedLVFile == "") {
        if (documentsNames.includes(this.selectedRoomFile)) {
          response = element.response
        }
      }
      else if(this.selectedIfcFile == "" && this.selectedRoomFile == "" && this.selectedLVFile != "") {
        if (documentsNames.includes(this.selectedLVFile)) {
          response = element.response
        }
      }else{
        response = element.response
      }
    })
    return response
  }

  getStatus(row: ComplianceCheck): string {
    let allRelevantElements = this.qualityCheck.complianceResult!.filter((data: { requiredInformation: string; }) => data.requiredInformation === row.requiredInformation);
    // let allRelevantElements =JSON.parse(JSON.stringify(this.jsonFile["Compliance"])).filter((data: { requiredInformation: string; }) => data.requiredInformation === row.requiredInformation);
    let response = ""

    allRelevantElements.forEach((element: { documents: any[]; status: string; }) => {
      let documentsNames = element.documents.map(doc =>  doc.extractionId)
      if(this.selectedIfcFile != "" && this.selectedRoomFile != "" && this.selectedLVFile != ""){
        if(documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedRoomFile) && documentsNames.includes(this.selectedLVFile)){
          response = element.status
        }else if(documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedRoomFile) || documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedLVFile) || documentsNames.includes(this.selectedRoomFile) && documentsNames.includes(this.selectedLVFile)) {
          response = element.status
        }else if(documentsNames.includes(this.selectedIfcFile) || documentsNames.includes(this.selectedRoomFile) || documentsNames.includes(this.selectedLVFile)){
          response = element.status
        }
      }
      else if(this.selectedIfcFile != "" && this.selectedRoomFile != "" && this.selectedLVFile == ""){
        if(documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedRoomFile)){
          response = element.status
        }else if(documentsNames.includes(this.selectedIfcFile) || documentsNames.includes(this.selectedRoomFile)){
          response = element.status
        }
      }
      else if(this.selectedIfcFile != "" && this.selectedRoomFile == "" && this.selectedLVFile != ""){
        if(documentsNames.includes(this.selectedIfcFile) && documentsNames.includes(this.selectedLVFile)) {
          response = element.status
        }else if (documentsNames.includes(this.selectedIfcFile) || documentsNames.includes(this.selectedLVFile)){
          response = element.status
        }
      }
      else if(this.selectedIfcFile == "" && this.selectedRoomFile != "" && this.selectedLVFile != ""){
        if(documentsNames.includes(this.selectedRoomFile) && documentsNames.includes(this.selectedLVFile)){
          response = element.status
        }else if (documentsNames.includes(this.selectedRoomFile) || documentsNames.includes(this.selectedLVFile)){
          response = element.status
        }
      }
      else if(this.selectedIfcFile != "" && this.selectedRoomFile == "" && this.selectedLVFile == "") {
        if (documentsNames.includes(this.selectedIfcFile)) {
          response = element.status
        }
      }
      else if(this.selectedIfcFile == "" && this.selectedRoomFile != "" && this.selectedLVFile == "") {
        if (documentsNames.includes(this.selectedRoomFile)) {
          response = element.status
        }
      }
      else if(this.selectedIfcFile == "" && this.selectedRoomFile == "" && this.selectedLVFile != "") {
        if (documentsNames.includes(this.selectedLVFile)) {
          response = element.status
        }
      }else{
        response = element.status
      }
    })
    return response
  }

  navigateToQuality(){
    this.router.navigate(["projects", this.stateConfigService.getProjectId(), "quality", this.qualityCheck.id, "report"]);
  }

  protected readonly faBars = faBars;
  protected readonly CircleState = CircleState;
  protected readonly faClipboardList = faClipboardList;
  protected readonly faChevronRight = faChevronRight;

  protected readonly faFilter = faFilter;
  protected readonly faSortDown = faSortDown;
  protected readonly faCloud = faCloud;
  protected readonly faBicErrorCloud = faBicErrorCloud;
  protected readonly faClipboardCheck = faClipboardCheck;
}
