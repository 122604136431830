import { Pipe, PipeTransform } from '@angular/core';
import {ProjectServiceNotification} from "../api";
import LphEnum = ProjectServiceNotification.LphEnum;

/**
 * Transforms LPH Enum Value to String
 */
@Pipe({
  name: 'lphToNumber'
})
export class LphToNumberPipe implements PipeTransform {

  /**
   * Transform Enum Value to String
   * @param value LPphEnum Value
   */
  transform(value: LphEnum | string | undefined): number {
    switch (value) {
      case "LPH1":
        return 0
      case "LPH2":
        return 1
      case "LPH3":
        return 2
      case "LPH4":
        return 3
      case "LPH5":
        return 4
      case "LPH6":
        return 5
      case "LPH7":
        return 6
      case "LPH8":
        return 7
      case "LPH9":
        return 8
      default:
        return -1
    }
  }

}

@Pipe({
  name: 'numberToLph'
})
export class NumberToLphPipe implements PipeTransform {

  /**
   * Transform Enum Value to String
   * @param value LPphEnum Value
   */
  transform(value: number | string | undefined): LphEnum {
    switch (value) {
      case "0" || 0:
        return LphEnum.LPH1
      case "1" || 1:
        return LphEnum.LPH2
      case "2" || 2:
        return LphEnum.LPH3
      case "3" || 3:
        return LphEnum.LPH4
      case "4" || 4:
        return LphEnum.LPH5
      case "5" || 5:
        return LphEnum.LPH6
      default:
        return LphEnum.LPH1
    }
  }

}
