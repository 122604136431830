import {Component} from '@angular/core';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {StateConfigService} from "../../services/state/state-config.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-bim-tools',
  templateUrl: './bim-tools.component.html',
  styleUrls: ['./bim-tools.component.scss']
})
export class BimToolsComponent {
  protected readonly faChevronRight = faChevronRight;

  constructor(private router: Router, public stateConfigService: StateConfigService) {

  }

  getProjectId() {
    return this.stateConfigService.selectedProject.id;
  }

  navigateToBimCheck() {
    this.router.navigate(['projects/' + this.getProjectId() + '/bim-basis-check']);
  }

  navigateToLoiCheck() {
    this.router.navigate(['projects/' + this.getProjectId() + '/loi-check']);
  }
}
