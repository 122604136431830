/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CameraDirection } from './cameraDirection';
import { CameraUpVector } from './cameraUpVector';
import { CameraViewPoint } from './cameraViewPoint';

export interface OrthogonalCamera { 
    id?: string;
    cameraViewPoint?: CameraViewPoint;
    cameraDirection?: CameraDirection;
    cameraUpVector?: CameraUpVector;
    viewToWorldScale?: string;
    aspectRatio?: string;
}