import {Component, OnInit} from '@angular/core';
import {FileControllerService, ProjectFile, Task} from "../../../api";
import {ActivatedRoute} from "@angular/router";
import {TaskDetailsService} from "../../../services/task-details.service";

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

  file!: ProjectFile

  constructor(private fileControllerService: FileControllerService,
              private activatedRoute: ActivatedRoute,
              private taskService: TaskDetailsService,) {
  }

  ngOnInit(): void {
    const fileId = this.activatedRoute.snapshot.paramMap.get('id');

    if(fileId==='0'){
      this.file = <ProjectFile>{};
      this.file.fileType = ProjectFile.FileTypeEnum.IFC
      // @ts-ignore
      this.file.link = localStorage.getItem("IFCURL")
      localStorage.removeItem("IFCURL")

    }else{

    this.fileControllerService.getFile(fileId!).subscribe(file => {
      this.file = file
    })
    }

  }

  createTask() {
    let task = {}
    this.taskService.setOpenedTask(task as Task);
  }

  protected readonly ProjectFile = ProjectFile;
  protected readonly FileTypeEnum = ProjectFile.FileTypeEnum;
}
