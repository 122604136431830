<!--<div class="account-image">-->
<!--  <mat-card (click)="imageInput.click()" [class.image-upload-disabled]="showOnly" [class.image-upload]="!showOnly"-->
<!--            appearance="outlined"-->
<!--            class="flex flex-row justify-center items-center overflow-hidden ring-4 ring-inset ring-black"-->
<!--            [matTooltip]="showOnly ? '' : 'Bild hochladen'">-->
<!--    <img (error)="image = undefined" *ngIf="image" alt="img" src="{{image}}">-->
<!--    <fa-icon *ngIf="!image" [icon]="faUser" class="text-[#042E3F] text-opacity-60 fa-5x -mb-6"></fa-icon>-->


<!--    &lt;!&ndash;    für eventuellen Rand&ndash;&gt;-->
<!--    &lt;!&ndash;<div *ngIf="!image" class="relative flex items-center justify-center w-24 h-24 rounded-full border-4 border-black overflow-hidden">-->
<!--      <fa-icon [icon]="faUser" class="text-gray-500 fa-5x -mb-6"></fa-icon>-->
<!--    </div>&ndash;&gt;-->

<!--    <input #imageInput (change)="uploadImage($event)" [disabled]="showOnly" accept="image/*" hidden id="file"-->
<!--           type="file">-->
<!--  </mat-card>-->
<!--</div>-->

<div class="relative w-[150px] h-[150px] hover:cursor-pointer">
  <div (mouseenter)="showEdit = true" (mouseleave)="showEdit = false" class="relative w-[150px] h-[150px] overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 shadow-full z-10 border-white border-4" >
    <div *ngIf="showEdit" class="absolute -left-4 -top-4 w-[180px] h-[180px] overflow-hidden bg-black bg-opacity-30 rounded-full z-20 border-white border-4" (click)="openImageOptions(image)">
      <div class="flex absolute h-[30px] w-[30px] rounded-full z-20 items-center justify-center right-[75px] bottom-[30px] bg-transparent">
        <fa-icon [icon]="faPen" class="flex text-white"> </fa-icon>
      </div>
    </div>
    <svg *ngIf="!image" (click)="openImageOptions(image)" class="absolute w-[180px] h-[180px] text-gray-400 -left-[18px]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
    <img (error)="image = undefined" *ngIf="image" alt="img" src="{{image}}" class="z-10" (click)="openImageOptions(image)">
    <input #imageInput (change)="uploadImage($event)" [disabled]="showOnly" accept="image/png, image/jpeg" type="file" hidden id="imageInput">


  </div>


</div>


