import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QualityCheck, QualityControllerService} from "../../../api";
import {StateConfigService} from "../../../services/state/state-config.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-quality-loading',
  templateUrl: './quality-loading.component.html',
  styleUrls: ['./quality-loading.component.scss']
})
export class QualityLoadingComponent implements OnInit {

  @Input() check: QualityCheck | undefined;
  @Output() checkChange = new EventEmitter<QualityCheck>();

  constructor(
    public stateConfig: StateConfigService,
    public qualityControllerService: QualityControllerService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.stateConfig.runningChecksChange.subscribe((checks) => {
      if (!checks.includes(this.check?.id!)) {
        this.qualityControllerService.getCheck1(this.check?.id!, this.stateConfig.getProjectId()).subscribe((check) => {
          this.checkChange.emit(check);
        });
      }
    });
  }

  protected readonly window = window;
}
