import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DashboardComponent} from "./dashboard.component";
import {DesignModule} from "../util/design/design.module";
import {MaterialModule} from "../../material.module";
import {CdkStepperModule} from "@angular/cdk/stepper";
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {RouterModule} from "@angular/router";
import {LphPipe} from "../../pipes/lph.pipe";
import {PowerBIEmbedModule} from "powerbi-client-angular";
import {CardComponent} from "./components/card/card.component";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {AddressInputModule} from "../util/addressInput/address-input.module";
import {TaskStatePipe, TaskStateReversePipe} from "../../pipes/task-state.pipe";
import {ProjectKindPipe} from "../../pipes/project-kind.pipe";
import {CheckTypePipe} from "../../pipes/check-type.pipe";
import {AppModule} from "../../app.module";
import {LodPipe} from "../../pipes/lod.pipe";
import {RoleAndPrivilegesPipe} from "../../pipes/role-and-privileges.pipe";
import {DashboardRingChartComponent} from './dashboard-checks/dashboard-ring-chart/dashboard-ring-chart.component';
import {DashboardCardComponent} from "./dashboard-card/dashboard-card.component";
import { DashboardProjectInformationComponent } from './dashboard-project-information/dashboard-project-information.component';
import { DashboardTasksComponent } from './dashboard-tasks/dashboard-tasks.component';
import { DashboardChecksComponent } from './dashboard-checks/dashboard-checks.component';
import {BicUiComponentsModule} from "@buildinginformationcloud/bic-ui-components";
import {DashboardTasksStateComponent} from "./dashboard-tasks/dashboard-tasks-state/dashboard-tasks-state.component";



@NgModule({
    declarations: [
        DashboardComponent,
        LphPipe,
        CardComponent,
      TaskStatePipe,
      TaskStateReversePipe,
      ProjectKindPipe,
      CheckTypePipe,
      LodPipe,
      RoleAndPrivilegesPipe,
      DashboardRingChartComponent,
      DashboardCardComponent,
      DashboardProjectInformationComponent,
      DashboardTasksComponent,
      DashboardChecksComponent,
      DashboardTasksStateComponent
    ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    NgxChartsModule,
    DesignModule,
    CdkStepperModule,
    PowerBIEmbedModule,
    FontAwesomeModule,
    AddressInputModule,
    NgOptimizedImage,
    BicUiComponentsModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    DashboardComponent,
    LphPipe,
    TaskStatePipe,
    TaskStateReversePipe,
    ProjectKindPipe,
    CheckTypePipe,
    LodPipe,
    RoleAndPrivilegesPipe
  ]
})
export class DashboardModule {
}
