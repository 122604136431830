// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Environment in debug mode
 */
export const environment = {
  production: false,
  chatbicCommunicationServiceEndpoint: 'https://chatbic-api-communicationservice-beta.communication.azure.com/',
  chatbicBotIdentity: '8:acs:f439cdc4-30ed-40de-81b2-c4e293c375cf_00000022-e7de-9878-6ba8-473a0d00de6c',
  allowedDomains: [
    "localhost:4200",
    "localhost:8080",
    "app.planbic.de",
    "api.planbic.de",
    "beta.planbic.de",
    "api.beta.planbic.de",
    "demo.planbic.de",
    "api.demo.planbic.de",
    "app.thinkbic.de",
    "api.thinkbic.de",
    "beta.thinkbic.de",
    "api.beta.thinkbic.de",
    "demo.thinkbic.de",
    "api.demo.thinkbic.de",
    "services.thinkbic.de",
    "de-thinkbic-fonts.s3.eu-central-1.amazonaws.com",
    "cdn.derivative.autodesk.com",
    "de-thinkbic-baf-tool.s3.eu-central-1.amazonaws.com",
    "de-thinkbic-hasselmann-mueller.s3.eu-central-1.amazonaws.com"],
  allowedUrls: [
    "http://localhost:4200",
    "http://localhost:8080",
    "https://app.thinkbic.de",
    "https://api.thinkbic.de",
    'https://beta.thinkbic.de',
    "https://api.beta.thinkbic.de",
    "https://services.thinkbic.de",
    "https://app.planbic.de",
    "https://api.planbic.de",
    'https://beta.planbic.de',
    "https://api.beta.planbic.de",
    "https://services.planbic.de"
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
