<div class="flex flex-col w-full justify-between items-center p-6 h-full" *ngIf="displayQrCode">
  <div class="flex flex-col w-full items-center gap-3">
    <span class="text-xl font-medium text-gray-700">2FA Einrichtung</span>
    <span class="text-sm text-gray-700">QR-Code mit Authentifizierungs-App scannen.</span>
  </div>
  <img *ngIf="qrCodeString" [src]="qrCodeString" alt="QR Code" width="300px">

  <div class="flex flex-row w-full justify-end gap-4 border-t border-gray-100">
    <bic-button class="pt-6" [text]="'Abbrechen'" [textColor]="'gray-900'" [color]="'white'" [border]="true" (onClick)="deactivate2FA()"></bic-button>
    <bic-button class="pt-6" [text]="'Weiter'" (onClick)="displayQrCode = false; displayMfaForm = true"></bic-button>

  </div>

</div>

<div class="flex flex-col w-full justify-between items-center p-6 h-full" *ngIf="displayMfaForm">
  <div class="flex flex-col w-full items-center gap-3">
    <span class="text-xl font-medium text-gray-700">2FA Einrichtung</span>
    <span class="text-sm text-gray-700" *ngIf="!data.isMail">Geben Sie den 6-stelligen Code aus Ihrer Authenticator-App ein.</span>
    <span class="text-sm text-gray-700" *ngIf="data.isMail">Geben Sie den 6-stelligen Code aus Ihrer Mail ein.</span>
  </div>
  <div class="flex flex-col w-full">
    <!--              <label for="code" class="block text-sm mb-1 font-medium text-white">2FA Code</label>-->
    <div class="flex flex-row justify-between w-full pb-6" id="code" [formGroup]="mfaForm">
      <input #otpInput formControlName="code1" type="text" maxlength="1" pattern="\d*" class="text-center text-sm rounded-lg bg-gray-100 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'focus:ring-white focus:border-white border-gray-300'" (input)="moveToNext($event, 0)" (keydown)="moveToNext($event, 0)">
      <input #otpInput formControlName="code2" type="text" maxlength="1" pattern="\d*" class="text-center text-sm rounded-lg bg-gray-100 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'focus:ring-white focus:border-white border-gray-300'" (input)="moveToNext($event, 1)" (keydown)="moveToNext($event, 1)">
      <input #otpInput formControlName="code3" type="text" maxlength="1" pattern="\d*" class="text-center text-sm rounded-lg bg-gray-100 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'focus:ring-white focus:border-white border-gray-300'" (input)="moveToNext($event, 2)" (keydown)="moveToNext($event, 2)">
      <input #otpInput formControlName="code4" type="text" maxlength="1" pattern="\d*" class="text-center text-sm rounded-lg bg-gray-100 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'focus:ring-white focus:border-white border-gray-300'" (input)="moveToNext($event, 3)" (keydown)="moveToNext($event, 3)">
      <input #otpInput formControlName="code5" type="text" maxlength="1" pattern="\d*" class="text-center text-sm rounded-lg bg-gray-100 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'focus:ring-white focus:border-white border-gray-300'" (input)="moveToNext($event, 4)" (keydown)="moveToNext($event, 4)">
      <input #otpInput formControlName="code6" type="text" maxlength="1" pattern="\d*" class="text-center text-sm rounded-lg bg-gray-100 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'focus:ring-white focus:border-white border-gray-300'" (input)="moveToNext($event, 5)" (keydown)="moveToNext($event, 5)">
    </div>
    <ng-container *ngIf="mfaForm.get('code1')?.touched && mfaForm.get('code1')?.invalid">
      <span class="text-xs text-error pt-1" *ngIf="mfaForm.get('code1')?.errors?.required">Sie müssen den 2FA Code eingeben.</span>
      <span class="text-xs text-error pt-1" *ngIf="mfaForm.get('code1')?.errors?.pattern">Der Code muss 6 Ziffern lang sein.</span>
    </ng-container>
    <ng-container *ngIf="mfaError">
      <span class="text-xs text-error pt-1">Der eingegebene Code ist nicht korrekt.</span>
    </ng-container>
    <span class="text-sm text-primary items-start" *ngIf="data.isMail" mat-dialog-close>Code nicht erhalten?</span>
    <span class="text-sm text-primary items-start" *ngIf="!data.isMail" mat-dialog-close>Keinen Zugriff auf die App?</span>
  </div>

  <div class="flex flex-row w-full justify-end gap-4 border-t border-gray-100">
    <bic-button class="pt-6" [text]="'Abbrechen'" [textColor]="'gray-900'" [color]="'white'" [border]="true" (onClick)="deactivate2FA()"></bic-button>
    <bic-button class="pt-6" [text]="'Weiter'" (onClick)="verify()"></bic-button>

  </div>

</div>

<div class="flex flex-col w-full justify-between items-center p-6 h-full" *ngIf="displayMfaSuccess">
  <div class="flex flex-col w-full items-center gap-3">
    <span class="text-xl font-medium text-gray-700">2FA Einrichtung</span>
    <span class="text-sm text-gray-700"></span>
  </div>
  <div class="flex flex-col justify-center items-center w-full gap-12">
    <fa-icon [icon]="faShieldCheck" class="text-primary text-[96px]"></fa-icon>

    <span class="text-xl text-primary font-semibold">2FA erfolgreich aktiviert</span>
  </div>

  <div class="flex flex-row w-full justify-end gap-4 border-t border-gray-100">
    <bic-button class="pt-6" [text]="'Fertig'" (onClick)="closeAfterSuccess()"></bic-button>

  </div>

</div>
