import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faInfoCircle} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent {
  @Input() tooltipText = ''


  protected readonly faInfoCircle = faInfoCircle;
}
