import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder} from "@angular/forms";
import {ErrorService} from "../../../../services/error/error.service";
import { AuthControllerService } from 'src/app/api';

@Component({
  selector: 'app-fta-popup-deactivate',
  templateUrl: './fta-popup-deactivate.component.html',
  styleUrls: ['./fta-popup-deactivate.component.scss']
})
export class FtaPopupDeactivateComponent implements OnInit {

  changeMethode: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {changeMethode: boolean }, private authControllerService: AuthControllerService,  public dialogRef: MatDialogRef<FtaPopupDeactivateComponent>) { }

  ngOnInit(): void {
    this.changeMethode = this.data.changeMethode;
  }

  deactivate2FA(): void {
    this.authControllerService.deactivate2FA().subscribe(() => {
      if(this.changeMethode){
        this.dialogRef.close({success: true, activateOtherMethod: true});
      }else{
        this.dialogRef.close({success: true, activateOtherMethod: false});
      }
    });
  }

  close(): void {
    this.dialogRef.close({success: false});
  }

}
