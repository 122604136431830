<ngx-extended-pdf-viewer
  [contextMenuAllowed]="false"
  [showToolbar]="true"
  [showSidebarButton]="false"
  [showFindButton]="false"
  [showPagingButtons]="false"
  [showZoomButtons]="false"
  [showPresentationModeButton]="false"
  [showOpenFileButton]="false"
  [showTextEditor]="false"
  [showDrawEditor]="false"
  [showPrintButton]="false"
  [showDownloadButton]="true"
  [showSecondaryToolbarButton]="false"
  [showRotateButton]="false"
  [showHandToolButton]="false"
  [showScrollingButton]="false"
  [showSpreadButton]="false"
  [showPropertiesButton]="false"
  [src]="link" [formData]="formData" formTheme="dark">
</ngx-extended-pdf-viewer>
