import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Task, User, TaskControllerService} from "../../../api";

import {MatSort} from "@angular/material/sort";
import PriorityEnum = Task.PriorityEnum;
import {ActivatedRoute, Router} from "@angular/router";
import {ToolbarService} from "../../util/design/toolbar/toolbar.service";
import {TaskDetailsService} from "../../../services/task-details.service";
import {StateConfigService} from "../../../services/state/state-config.service";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {TaskDeleteDialog} from "../task-detail/task-detail.component";

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit, AfterViewInit {

  @Input() projectId: number | undefined;
  @Output() importBCFClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportBCFClicked: EventEmitter<Task> = new EventEmitter<Task>();
  tasks: Task[] = [];

  /**
   * Displayed Columns for MatTable of Tasks
   */
  displayedColumns: string[] = ['title', 'prio', 'projectName', 'deadline', 'state', 'createdBy', 'people', 'date', 'actions'];

  /**
   * Data Source for MatTable of Tasks
   */
  dataSource!: Task[];//MatTableDataSource<Task>;

  /**
   * Paginator Child from MatPaginator
   */
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  /**
   * Sort Child for MatTable
   */
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('tasksTbSort') tasksTbSort = new MatSort();

  priorityEnum: typeof PriorityEnum = PriorityEnum;
  task_length: number = 0;
  default_page_size: number = 10;

  constructor(private dialog: MatDialog, private router: Router, private taskControllerService:
                TaskControllerService,
              private activatedRoute: ActivatedRoute,
              private taskDetailService: TaskDetailsService,
              private stateService: StateConfigService,) {

  }

  ngOnInit(): void {
    this.taskControllerService.getTasks(this.stateService.getProject()?.id, this.stateService.selectedOrganisation?.id, this.stateService.isPersonal).subscribe(tasks => {
      this.tasks = tasks;
      this.dataSource = this.tasks;

      if (!this.stateService.getProject()) {
        this.displayedColumns = ['title', 'prio', 'projectName', 'deadline', 'state', 'createdBy', 'people', 'date', 'project', 'actions']
      }


      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.tasksTbSort;

      this.task_length = this.tasks.length
    })
    this.taskDetailService.currentTaskChanged.subscribe(task => {
      this.refreshList();
    });
  }

  refreshList() {
    this.taskControllerService.getTasks(this.stateService.getProject()?.id, this.stateService.selectedOrganisation?.id, this.stateService.isPersonal).subscribe(tasks => {
      this.tasks = tasks;
      this.dataSource = this.tasks;
      this.task_length = this.tasks.length;

      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.tasksTbSort;
    })
  }

  ngAfterViewInit() {
    this.taskControllerService.getTasks(this.stateService.getProject()?.id, this.stateService.selectedOrganisation?.id, this.stateService.isPersonal).subscribe(tasks => {
      this.tasks = tasks;
      this.dataSource = this.tasks
      //this.dataSource.data = this.dataSource.data.concat(this.tasks.slice(this.default_page_size, this.tasks.length));

      this.task_length = this.tasks.length;

      //this.dataSource.paginator = this.paginator;
      //this.dataSource.sort = this.tasksTbSort;
      /*this.dataSource.sortingDataAccessor = (row: Task, columnName: string): string => {
        if (columnName == "prio") {
          return row.priority?.toString() ?? "";
        } else if (columnName == "projectName") {
          return  "";
        } else if (columnName == "date") {
          return row.markup?.topic?.creationDate?.toString() ?? "";
        } else if (columnName == "createdBy") {
          return row.markup?.topic?.creationUser?.firstName ?? row.markup?.topic?.creationAuthor ?? "";
        }
        var columnValue = row[columnName as keyof Task] as string;
        return columnValue;
      }*/
    })
  }

  /**
   * Applies filter of searchbar to datasource
   * @param event data from searchbar
   */
  /*applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }*/

  selectTask(task?: Task, create: boolean = false) {
    this.taskDetailService.setOpenedTask(task)


    // if (create) {
    //   this.router.navigate(['/tasks/create']);
    // }
    // if (task) {
    //   this.router.navigate(['/tasks/', task.id]);
    // }

  }

  openDeleteDialog(task: Task) {
    let dialogRef = this.dialog.open(TaskDeleteDialog, {
      panelClass:"rounded-corners-dialog",
      data: {task: task}
    });

  }

  createNewTask() {
    this.taskDetailService.setOpenedTask(undefined);
  }

  protected readonly faEllipsisVertical = faEllipsisVertical;
}
