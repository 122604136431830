import { Pipe, PipeTransform } from '@angular/core';
import {Project} from "../api";
import CountryEnum = Project.CountryEnum;

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  transform(value: CountryEnum): string {
    switch (value) {
      case CountryEnum.AND: return "Andorra";
      case CountryEnum.ARE: return "Vereinigte Arabische Emirate";
      case CountryEnum.AFG: return "Afghanistan";
      case CountryEnum.ATG: return "Antigua und Barbuda";
      case CountryEnum.AIA: return "Anguilla";
      case CountryEnum.ALB: return "Albanien";
      case CountryEnum.ARM: return "Armenien";
      case CountryEnum.AGO: return "Angola";
      case CountryEnum.ATA: return "Antarktis";
      case CountryEnum.ARG: return "Argentinien";
      case CountryEnum.ASM: return "Amerikanisch-Samoa";
      case CountryEnum.AUT: return "Österreich";
      case CountryEnum.AUS: return "Australien";
      case CountryEnum.ABW: return "Aruba";
      case CountryEnum.ALA: return "Ålandinseln";
      case CountryEnum.AZE: return "Aserbaidschan";
      case CountryEnum.BIH: return "Bosnien und Herzegowina";
      case CountryEnum.BRB: return "Barbados";
      case CountryEnum.BGD: return "Bangladesch";
      case CountryEnum.BEL: return "Belgien";
      case CountryEnum.BFA: return "Burkina Faso";
      case CountryEnum.BGR: return "Bulgarien";
      case CountryEnum.BHR: return "Bahrain";
      case CountryEnum.BDI: return "Burundi";
      case CountryEnum.BEN: return "Benin";
      case CountryEnum.BLM: return "St. Barthélemy";
      case CountryEnum.BMU: return "Bermuda";
      case CountryEnum.BRN: return "Brunei Darussalam";
      case CountryEnum.BOL: return "Bolivien";
      case CountryEnum.BES: return "Karibische Niederlande";
      case CountryEnum.BRA: return "Brasilien";
      case CountryEnum.BHS: return "Bahamas";
      case CountryEnum.BTN: return "Bhutan";
      case CountryEnum.BVT: return "Bouvetinsel";
      case CountryEnum.BWA: return "Botsuana";
      case CountryEnum.BLR: return "Belarus";
      case CountryEnum.BLZ: return "Belize";
      case CountryEnum.CAN: return "Kanada";
      case CountryEnum.CCK: return "Kokosinseln";
      case CountryEnum.COD: return "Kongo-Kinshasa";
      case CountryEnum.CAF: return "Zentralafrikanische Republik";
      case CountryEnum.COG: return "Kongo-Brazzaville";
      case CountryEnum.CHE: return "Schweiz";
      case CountryEnum.CIV: return "Côte d’Ivoire";
      case CountryEnum.COK: return "Cookinseln";
      case CountryEnum.CHL: return "Chile";
      case CountryEnum.CMR: return "Kamerun";
      case CountryEnum.CHN: return "China";
      case CountryEnum.COL: return "Kolumbien";
      case CountryEnum.CRI: return "Costa Rica";
      case CountryEnum.CUB: return "Kuba";
      case CountryEnum.CPV: return "Cabo Verde";
      case CountryEnum.CUW: return "Curaçao";
      case CountryEnum.CXR: return "Weihnachtsinsel";
      case CountryEnum.CYP: return "Zypern";
      case CountryEnum.CZE: return "Tschechien";
      case CountryEnum.DEU: return "Deutschland";
      case CountryEnum.DJI: return "Dschibuti";
      case CountryEnum.DNK: return "Dänemark";
      case CountryEnum.DMA: return "Dominica";
      case CountryEnum.DOM: return "Dominikanische Republik";
      case CountryEnum.DZA: return "Algerien";
      case CountryEnum.ECU: return "Ecuador";
      case CountryEnum.EST: return "Estland";
      case CountryEnum.EGY: return "Ägypten";
      case CountryEnum.ESH: return "Westsahara";
      case CountryEnum.ERI: return "Eritrea";
      case CountryEnum.ESP: return "Spanien";
      case CountryEnum.ETH: return "Äthiopien";
      case CountryEnum.FIN: return "Finnland";
      case CountryEnum.FJI: return "Fidschi";
      case CountryEnum.FLK: return "Falklandinseln";
      case CountryEnum.FSM: return "Mikronesien";
      case CountryEnum.FRO: return "Färöer";
      case CountryEnum.FRA: return "Frankreich";
      case CountryEnum.GAB: return "Gabun";
      case CountryEnum.GBR: return "Vereinigtes Königreich";
      case CountryEnum.GRD: return "Grenada";
      case CountryEnum.GEO: return "Georgien";
      case CountryEnum.GUF: return "Französisch-Guayana";
      case CountryEnum.GGY: return "Guernsey";
      case CountryEnum.GHA: return "Ghana";
      case CountryEnum.GIB: return "Gibraltar";
      case CountryEnum.GRL: return "Grönland";
      case CountryEnum.GMB: return "Gambia";
      case CountryEnum.GIN: return "Guinea";
      case CountryEnum.GLP: return "Guadeloupe";
      case CountryEnum.GNQ: return "Äquatorialguinea";
      case CountryEnum.GRC: return "Griechenland";
      case CountryEnum.SGS: return "Südgeorgien und die Südlichen Sandwichinseln";
      case CountryEnum.GTM: return "Guatemala";
      case CountryEnum.GUM: return "Guam";
      case CountryEnum.GNB: return "Guinea-Bissau";
      case CountryEnum.GUY: return "Guyana";
      case CountryEnum.HKG: return "Sonderverwaltungsregion Hongkong";
      case CountryEnum.HMD: return "Heard und McDonaldinseln";
      case CountryEnum.HND: return "Honduras";
      case CountryEnum.HRV: return "Kroatien";
      case CountryEnum.HTI: return "Haiti";
      case CountryEnum.HUN: return "Ungarn";
      case CountryEnum.IDN: return "Indonesien";
      case CountryEnum.IRL: return "Irland";
      case CountryEnum.ISR: return "Israel";
      case CountryEnum.IMN: return "Isle of Man";
      case CountryEnum.IND: return "Indien";
      case CountryEnum.IOT: return "Britisches Territorium im Indischen Ozean";
      case CountryEnum.IRQ: return "Irak";
      case CountryEnum.IRN: return "Iran";
      case CountryEnum.ISL: return "Island";
      case CountryEnum.ITA: return "Italien";
      case CountryEnum.JEY: return "Jersey";
      case CountryEnum.JAM: return "Jamaika";
      case CountryEnum.JOR: return "Jordanien";
      case CountryEnum.JPN: return "Japan";
      case CountryEnum.KEN: return "Kenia";
      case CountryEnum.KGZ: return "Kirgisistan";
      case CountryEnum.KHM: return "Kambodscha";
      case CountryEnum.KIR: return "Kiribati";
      case CountryEnum.COM: return "Komoren";
      case CountryEnum.KNA: return "St. Kitts und Nevis";
      case CountryEnum.PRK: return "Nordkorea";
      case CountryEnum.KOR: return "Südkorea";
      case CountryEnum.KWT: return "Kuwait";
      case CountryEnum.CYM: return "Kaimaninseln";
      case CountryEnum.KAZ: return "Kasachstan";
      case CountryEnum.LAO: return "Laos";
      case CountryEnum.LBN: return "Libanon";
      case CountryEnum.LCA: return "St. Lucia";
      case CountryEnum.LIE: return "Liechtenstein";
      case CountryEnum.LKA: return "Sri Lanka";
      case CountryEnum.LBR: return "Liberia";
      case CountryEnum.LSO: return "Lesotho";
      case CountryEnum.LTU: return "Litauen";
      case CountryEnum.LUX: return "Luxemburg";
      case CountryEnum.LVA: return "Lettland";
      case CountryEnum.LBY: return "Libyen";
      case CountryEnum.MAR: return "Marokko";
      case CountryEnum.MCO: return "Monaco";
      case CountryEnum.MDA: return "Republik Moldau";
      case CountryEnum.MNE: return "Montenegro";
      case CountryEnum.MAF: return "St. Martin";
      case CountryEnum.MDG: return "Madagaskar";
      case CountryEnum.MHL: return "Marshallinseln";
      case CountryEnum.MKD: return "Nordmazedonien";
      case CountryEnum.MLI: return "Mali";
      case CountryEnum.MMR: return "Myanmar";
      case CountryEnum.MNG: return "Mongolei";
      case CountryEnum.MAC: return "Sonderverwaltungsregion Macau";
      case CountryEnum.MNP: return "Nördliche Marianen";
      case CountryEnum.MTQ: return "Martinique";
      case CountryEnum.MRT: return "Mauretanien";
      case CountryEnum.MSR: return "Montserrat";
      case CountryEnum.MLT: return "Malta";
      case CountryEnum.MUS: return "Mauritius";
      case CountryEnum.MDV: return "Malediven";
      case CountryEnum.MWI: return "Malawi";
      case CountryEnum.MEX: return "Mexiko";
      case CountryEnum.MYS: return "Malaysia";
      case CountryEnum.MOZ: return "Mosambik";
      case CountryEnum.NAM: return "Namibia";
      case CountryEnum.NCL: return "Neukaledonien";
      case CountryEnum.NER: return "Niger";
      case CountryEnum.NFK: return "Norfolkinsel";
      case CountryEnum.NGA: return "Nigeria";
      case CountryEnum.NIC: return "Nicaragua";
      case CountryEnum.NLD: return "Niederlande";
      case CountryEnum.NOR: return "Norwegen";
      case CountryEnum.NPL: return "Nepal";
      case CountryEnum.NRU: return "Nauru";
      case CountryEnum.NIU: return "Niue";
      case CountryEnum.NZL: return "Neuseeland";
      case CountryEnum.OMN: return "Oman";
      case CountryEnum.PAN: return "Panama";
      case CountryEnum.PER: return "Peru";
      case CountryEnum.PYF: return "Französisch-Polynesien";
      case CountryEnum.PNG: return "Papua-Neuguinea";
      case CountryEnum.PHL: return "Philippinen";
      case CountryEnum.PAK: return "Pakistan";
      case CountryEnum.POL: return "Polen";
      case CountryEnum.SPM: return "St. Pierre und Miquelon";
      case CountryEnum.PCN: return "Pitcairninseln";
      case CountryEnum.PRI: return "Puerto Rico";
      case CountryEnum.PSE: return "Palästinensische Autonomiegebiete";
      case CountryEnum.PRT: return "Portugal";
      case CountryEnum.PLW: return "Palau";
      case CountryEnum.PRY: return "Paraguay";
      case CountryEnum.QAT: return "Katar";
      case CountryEnum.REU: return "Réunion";
      case CountryEnum.ROU: return "Rumänien";
      case CountryEnum.SRB: return "Serbien";
      case CountryEnum.RUS: return "Russland";
      case CountryEnum.RWA: return "Ruanda";
      case CountryEnum.SAU: return "Saudi-Arabien";
      case CountryEnum.SLB: return "Salomonen";
      case CountryEnum.SYC: return "Seychellen";
      case CountryEnum.SDN: return "Sudan";
      case CountryEnum.SWE: return "Schweden";
      case CountryEnum.SGP: return "Singapur";
      case CountryEnum.SHN: return "St. Helena";
      case CountryEnum.SVN: return "Slowenien";
      case CountryEnum.SJM: return "Spitzbergen und Jan Mayen";
      case CountryEnum.SVK: return "Slowakei";
      case CountryEnum.SLE: return "Sierra Leone";
      case CountryEnum.SMR: return "San Marino";
      case CountryEnum.SEN: return "Senegal";
      case CountryEnum.SOM: return "Somalia";
      case CountryEnum.SUR: return "Suriname";
      case CountryEnum.SSD: return "Südsudan";
      case CountryEnum.STP: return "São Tomé und Príncipe";
      case CountryEnum.SLV: return "El Salvador";
      case CountryEnum.SXM: return "Sint Maarten";
      case CountryEnum.SYR: return "Syrien";
      case CountryEnum.SWZ: return "Eswatini";
      case CountryEnum.TCA: return "Turks- und Caicosinseln";
      case CountryEnum.TCD: return "Tschad";
      case CountryEnum.ATF: return "Französische Süd- und Antarktisgebiete";
      case CountryEnum.TGO: return "Togo";
      case CountryEnum.THA: return "Thailand";
      case CountryEnum.TJK: return "Tadschikistan";
      case CountryEnum.TKL: return "Tokelau";
      case CountryEnum.TLS: return "Timor-Leste";
      case CountryEnum.TKM: return "Turkmenistan";
      case CountryEnum.TUN: return "Tunesien";
      case CountryEnum.TON: return "Tonga";
      case CountryEnum.TUR: return "Türkei";
      case CountryEnum.TTO: return "Trinidad und Tobago";
      case CountryEnum.TUV: return "Tuvalu";
      case CountryEnum.TWN: return "Taiwan";
      case CountryEnum.TZA: return "Tansania";
      case CountryEnum.UKR: return "Ukraine";
      case CountryEnum.UGA: return "Uganda";
      case CountryEnum.UMI: return "Amerikanische Überseeinseln";
      case CountryEnum.USA: return "Vereinigte Staaten";
      case CountryEnum.URY: return "Uruguay";
      case CountryEnum.UZB: return "Usbekistan";
      case CountryEnum.VAT: return "Vatikanstadt";
      case CountryEnum.VCT: return "St. Vincent und die Grenadinen";
      case CountryEnum.VEN: return "Venezuela";
      case CountryEnum.VGB: return "Britische Jungferninseln";
      case CountryEnum.VIR: return "Amerikanische Jungferninseln";
      case CountryEnum.VNM: return "Vietnam";
      case CountryEnum.VUT: return "Vanuatu";
      case CountryEnum.WLF: return "Wallis und Futuna";
      case CountryEnum.WSM: return "Samoa";
      case CountryEnum.YEM: return "Jemen";
      case CountryEnum.MYT: return "Mayotte";
      case CountryEnum.ZAF: return "Südafrika";
      case CountryEnum.ZMB: return "Sambia";
      case CountryEnum.ZWE: return "Simbabwe";
      default: return "";
    }
  }

}
