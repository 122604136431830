<div class="flex flex-col justify-start items-start gap-2 h-full p-4">
  <div class="text-secondary text-xl">
    <div class="flex flex-row justify-start items-center gap-2">
      <button class="flex flex-row justify-start items-center gap-2 p-2 -ml-2 hover:bg-gray-200 rounded-md"
              [matMenuTriggerFor]="moduleSelected">
        <fa-icon [icon]="faBars" class="text-primary"></fa-icon>
      </button>
      <button [disabled]="!selectedFolder" [ngClass]="{'hover:bg-white': !selectedFolder}" class="flex flex-row justify-start items-center gap-2 p-2 -ml-2 hover:bg-gray-200 rounded-md text-black text-base" (click)="navigateToFiles()">
        {{selectedModule | swmModule}}
      </button>

      <ng-container *ngIf="selectedFolder">
        <div >
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </div>
        <button class="flex flex-row justify-start items-center gap-2 p-2 hover:bg-gray-200 text-black text-base rounded-md" [matMenuTriggerFor]="folderMenu">
          {{selectedFolder | folder}}
        </button>
      </ng-container>
    </div>

    <div class="text-black text-[18px]" *ngIf="!selectedFolder">
      Die Prüfung basiert auf der Zuordnung der Bauprojektunterlagen zu den folgenden Ordnern. Bitte beachten Sie die hinterlegten Hinweise hinter den Fragezeichen.
    </div>

    <div *ngIf="selectedFolder" class="text-black text-[14px]"
         [innerHTML]="getInfoText(selectedFolder)">
    </div>

    <mat-menu #moduleSelected="matMenu">
      <ng-container *ngFor="let module of modules | keyvalue">
        <button class="hover:bg-gray-200" mat-menu-item
                (click)="navigateToModule(module.value)">
          {{module.value | swmModule}}
        </button>
      </ng-container>
    </mat-menu>

    <mat-menu #folderMenu="matMenu">
      <ng-container *ngFor="let folder of getFolders()">
        <button class="hover:bg-gray-200 text-black text-base" mat-menu-item (click)="openFolder(folder)">
          {{folder | folder}}
        </button>
      </ng-container>
    </mat-menu>
  </div>

  <div *ngIf="!selectedFolder" class="flex flex-row justify-start items-start flex-wrap gap-10 overflow-y-scroll p-2">
    <bic-card class="flex flex-col justify-start" (click)="openFolder(folder)" *ngFor="let folder of getFolders(); let i = index">

      <div class="flex flex-col justify-between h-full w-full">
        <!-- Folder Name and Menu Icon -->
        <div class="flex flex-row justify-between items-start">
          <div class="flex flex-row justify-start items-start gap-2 pl-2">
            <fa-icon [icon]="faFolderOpen" class="text-primary text-2xl"></fa-icon>

            <div class="text-lg font-medium">
              {{folder | folder}}
            </div>
          </div>

          <div class="text-lg pr-1" #overlayTrigger (mouseenter)="openedFolderOverlay = folder" (mouseleave)="openedFolderOverlay = null">
            <fa-icon [icon]="faCircleQuestion" class="text-primary text-2xl" #infoIcon></fa-icon>
          </div>



          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="overlayTrigger"
            [cdkConnectedOverlayOpen]="openedFolderOverlay == folder"
          >
            <div class="text-xs bg-white rounded-[5px] p-[5px] shadow-full break-words w-full">
              <div [innerHTML]="getInfoText(folder)"></div>
            </div>
          </ng-template>
        </div>



        <!-- Folder Information -->
        <div class="flex flex-col justify-center gap-3 pb-4">

          <div class="flex flex-row gap-2 justify-between items-center" *ngIf="calculateSizeInGB(folderData(folder).size) < 1">
            <div class="pl-2">Größe</div>
            <div class="pr-2">{{calculateSizeInMB(folderData(folder).size) | number: '.0-2'}} MB</div>
          </div>

          <div class="flex flex-row gap-2 justify-between items-center" *ngIf="calculateSizeInGB(folderData(folder).size) >= 1">
            <div class="pl-2">Größe</div>
            <div class="pr-2">{{calculateSizeInGB(folderData(folder).size) | number: '.0-2'}} GB</div>
          </div>

          <div class="flex flex-row gap-2 justify-between items-center">
            <div class="pl-2">Dateien</div>
            <div class="pr-2">{{folderData(folder).numberOfFiles}}</div>
          </div>

          <div class="flex flex-row gap-2 justify-between items-center" *ngIf="false">
            <div class="pl-2">Zuletzt geändert</div>
            <div class="pr-2">03.06.2023</div>
          </div>
        </div>
      </div>
    </bic-card>
  </div>

  <app-files class="w-full h-full overflow-hidden p-2" *ngIf="selectedFolder"
             [(breadcrumbNavigation)]="breadcrumbNavigation"
             [project]="stateConfigService.selectedProject"
             [folder]="selectedFolder"
             [lph]="stateConfigService.selectedServicePhase">
  </app-files>

</div>
