<div
  class="flex flex-col md:flex-row justify-start items-start w-full h-full gap-4 md:gap-10 p-10 overflow-auto">

  <!--BIM-Basis-Check-->
  <div class="flex flex-col shadow-full rounded-xl justify-between items-center">
    <div class="flex flex-col p-8">

      <div class="font-medium text-primary text-2xl p-2 pb-4 mb-6">BIM-Basis-Check</div>

      <div class="flex flex-col h-full max-w-md">
        <p class="text-black font-medium leading-8 line-clamp-4 overflow-hidden">
          Überprüfen Sie ihr IFC-Modell
          <span class="text-primary"> Basis-Modellierungsvorgaben</span>
          . Im BIM-Basis-Check werden
          <span class="text-primary"> formale Kriterien</span>
          geprüft, um die
          <span class="text-primary"> allgemeine Modellierqualität</span>
          zu sichern.
        </p>

        <div class="p-2">
          <hr class="border-primary border-t-2 my-4">
        </div>

        <div class="mb-6">
          <p class="text-black leading-9">
            Beispielhafte Prüfregeln:
          <ul class="list-disc pl-5 text-black leading-9">
            <li>Einzigartige GUIDs</li>
            <li>Sinnvolle Geschossflächen</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex justify-center items-center p-6 pt-8">
      <div class="text-primary rounded items-center ">
        <button (click)="navigateToBimCheck()"
                class="h-10 w-30 border-primary border-2 rounded-md items-center justify-center">
          <span class="items-center p-2">BIM-Check</span>
          <fa-icon [icon]="faChevronRight" class="text-primary pr-2"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <!--LOI-Check-->
  <div class="flex flex-col shadow-full rounded-xl items-center">
    <div class="flex flex-col p-8">

      <div class="font-medium text-primary text-2xl p-2 pb-4 mb-6">LOI-Check</div>

      <div class="flex flex-col h-full max-w-md">
        <p class="text-black font-medium leading-8 line-clamp-3 overflow-hidden mb-6">
          Der LOI-Check
          <span class="text-primary"> (Level of Information)</span>
          ermöglicht eine
          <span class="text-primary"> personalisierbare Überprüfung</span>
          des
          <span class="text-primary"> Informationsgehalts</span>
          von IFC-Modellen.
        </p>

        <div class="p-2">
          <hr class="border-primary border-t-2 my-4">
        </div>

        <p class="text-black leading-9 mb-0">
          Die Funktionen im LOI-Check:
        <ul class="list-disc pl-5 text-black leading-9">
          <li>Benutzerdefinierte Regelerstellung</li>
          <li>Anzeige im IFC-Viewer</li>
          <li>Nutzung des vordefinierten BIC-Standards</li>
        </ul>
      </div>
    </div>

    <div class="flex justify-center items-center p-6 pt-8">
      <div class="text-primary rounded items-center ">
        <button (click)="navigateToLoiCheck()"
                class="h-10 w-30 border-primary border-2 rounded-md items-center justify-center">
          <span class="items-center p-2">LOI-Check</span>
          <fa-icon [icon]="faChevronRight" class="text-primary pr-2"></fa-icon>
        </button>
      </div>
    </div>
  </div>

</div>
