import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {Project, ProjectControllerService} from "../../api";
import {SidebarService} from "../../services/sidebar/sidebar.service";
import {StateConfigService} from "../../services/state/state-config.service";
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProjectResolver  {

  constructor(private stateConfigService: StateConfigService,
              private projectControllerService: ProjectControllerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
    let id = Number(route.paramMap.get('id'));
    if (isNaN(id)) {
      id = this.stateConfigService.getProjectId();
    }

    return this.projectControllerService.getProject(id).pipe(
      tap(project => this.stateConfigService.selectedProject = project)
    );

  }
}
