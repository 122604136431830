/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Contingent { 
    credits?: number;
    addOnCredits?: number;
    currentPeriodStart?: Date;
    currentPeriodEnd?: Date;
    trialEnd?: Date;
    hoaiCheckTrial?: number;
    loiCheckTrial?: number;
    qualityCheckTrial?: number;
    bimBaseCheckTrial?: number;
    buildingApplicationTrial?: number;
    chatBICTrial?: number;
    hoaiChecks?: number;
    loiChecks?: number;
    qualityChecks?: number;
    bimBaseChecks?: number;
    buildingApplications?: number;
    chatBIC?: number;
}