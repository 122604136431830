import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {
  FileControllerService,
  IFCCheck,
  IfcControllerService,
  LOICheckRule,
  ProjectControllerService,
  ProjectFile,
  QualityCheck,
  QualityControllerService,
  SimpleCheck
} from "../../../../api";
import {DefaultService} from "../../../../bim-api";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {Router} from "@angular/router";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FileTreeNode} from "../../../util/files/files.component";
import {SelectionModel} from "@angular/cdk/collections";
import LphEnum = SimpleCheck.LphEnum;
import LodTypeEnum = IFCCheck.LodTypeEnum;
import {PermissionService} from "../../../../services/permission/permission.service";
import {faXmark} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-loi-check-choose-files',
  templateUrl: './loi-check-choose-files.component.html',
  styleUrls: ['./loi-check-choose-files.component.scss']
})
export class LoiCheckChooseFilesComponent implements OnInit {

  lph = LphEnum.LPH1;

  ifcFiles: ProjectFile[] = [];

  lodType: typeof LodTypeEnum = LodTypeEnum;

  loiRules: LOICheckRule[] = []

  checkForm!: UntypedFormGroup;
  result: any;
  runningDocumentClassification: string[] = [];
  selectedIFCFiles: any[]=[]//SelectionModel<FileTreeNode> = new SelectionModel<FileTreeNode>(true, []);

  columns =['Name', 'Größe(MB)', 'Zuletzt geändert']
  column = ['name', 'size', 'lastModified']
  colTypes =['text', 'filesize', 'date']

  constructor(private projectControllerService: ProjectControllerService,
              private qualityControllerService: QualityControllerService,
              private bimApiService: DefaultService,
              public permissionService: PermissionService,
              private fileControllerService: FileControllerService,
              private stateConfigService: StateConfigService,
              private ifcControllerService: IfcControllerService,
              private router: Router,
              ) {
  }
  protected readonly faChevronRight = faChevronRight;

  ngOnInit(): void {
    this.checkForm = new UntypedFormGroup({
      check: new UntypedFormControl(undefined, Validators.required),
      lodType: new UntypedFormControl(undefined),
      projectFile: new UntypedFormControl(undefined, Validators.required),
    });

    this.ifcControllerService.getRules2(this.stateConfigService.getProjectId()).subscribe(rules => {
      this.loiRules = rules;
    });

    this.fileControllerService.getIfcFiles(this.stateConfigService.selectedProject.id ?? 0).subscribe(files => {
      this.ifcFiles = files.filter(file=> !file.uploadFailed);
    })

    this.stateConfigService.runningClassificationsChange.subscribe((runningClassifications) => {
      this.runningDocumentClassification = runningClassifications;
    });
  }

  navigateToChoosingRules() {
    this.router.navigate(['projects', this.getProjectId(), 'loi-check', this.selectedIFCFiles.pop()!.id]);
  }

  getSelectedIFCFiles() {
    return this.selectedIFCFiles.map(node => ({name: node.name || '', size: this.calculateSize(node.size!), lastModified: node.lastModified, id: node.id}));
  }

  get selectedFiles(){
    return this.selectedIFCFiles
  }

  set selectedFiles(files: any []){
    this.selectedIFCFiles = files
  }

  removeSelectedIFCFile(file: any) {
    const index = this.selectedIFCFiles.findIndex(selectedFiles=>file.id==selectedFiles.id)
    if (index !== -1) {
      this.selectedIFCFiles.splice(index, 1);
    }
  }

  getProjectId(): number {
    return this.stateConfigService.getProjectId();
  }

  get canCheck() {
    return this.stateConfigService.canCheckLOI();
  }

  calculateSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size < kb) {
      return `${size} Bytes`;
    } else if (size < mb) {
      return `${(size / kb).toFixed(2)} KB`;
    } else if (size < gb) {
      return `${(size / mb).toFixed(2)} MB`;
    } else {
      return `${(size / gb).toFixed(2)} GB`;
    }
  }

  protected readonly faXmark = faXmark;
}
