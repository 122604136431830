<div class="flex flex-col z-40 h-full w-full bg-white shadow-full rounded-[10px] p-2">
  <!--  <div class="flex flex-row">-->
  <div class="flex flex-col">
    <div class="text-xl font-medium pt-6 text-center">Organisation löschen oder verlassen?</div>
    <div class="px-4 text-center pt-4">Beim <span class="underline">löschen</span>
      wird die Organisation "{{ data.organisation.name }}" unwiderruflich gelöscht. Damit verlieren Sie und alle
      Mitglieder ihre Angehörigkeit zu dieser Organisation.
    </div>

    <div class="px-4 text-center pt-4">Wählen Sie <span class="underline">Verlassen</span>, um selbst aus der
      Organisation auszutreten. Dabei bleibt die Organisation für alle anderen Mitglieder bestehen. Diese Option steht
      nur zur Verfügung wenn ein zusätzliches Mitglied über Administrationsrechte besitzt.
    </div>

    <div class="flex flex-row pt-4 justify-between">
      <mat-dialog-actions class="flex justify-between w-full">
        <button mat-raised-button class="bg-white w-28" (click)="onCancel()">Abbrechen</button>
        <div class="flex-grow"></div>
        <button mat-raised-button class="bg-white w-28" *ngIf="permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', data.organisation.id!) && existsOtherAdminMap.get(data.organisation)"
                (click)="leave()">Verlassen
        </button>
        <div class="flex-grow"></div>
        <button mat-raised-button color="primary" class="text-white w-28"
                (click)="delete()">
          Löschen
        </button>
      </mat-dialog-actions>
    </div>
  </div>


  <!--  </div>-->
</div>
