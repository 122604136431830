import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {StateConfigService} from "../state/state-config.service";

/**
 * Auth Guard for normal Users
 */
@Injectable()
export class AuthGuardService  {

  /**
   * Constructor
   * @param router Router
   * @param authService AuthService
   * @param jwtHelper JwtHelperServicee
   * @param stateService
   */
  constructor(private router: Router, private authService: AuthService, private jwtHelper: JwtHelperService, private stateService: StateConfigService) {
  }

  /**
   * Checks if the route can be activated
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.authService.isLoggedIn()) {
      return true;

    } else {
      this.authService.logout();
      this.router.navigate(['/login']);
      return false;
    }

  }
}
