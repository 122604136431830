/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { JsonObject } from './jsonObject';
import { MarketingFeature } from './marketingFeature';
import { PackageDimensions } from './packageDimensions';
import { Price } from './price';
import { StripeResponse } from './stripeResponse';
import { StripeResponseGetter } from './stripeResponseGetter';
import { TaxCode } from './taxCode';

export interface Product { 
    lastResponse?: StripeResponse;
    rawJsonObject?: JsonObject;
    responseGetter?: StripeResponseGetter;
    active?: boolean;
    created?: number;
    defaultPrice?: string;
    deleted?: boolean;
    description?: string;
    id?: string;
    images?: Array<string>;
    livemode?: boolean;
    marketingFeatures?: Array<MarketingFeature>;
    metadata?: { [key: string]: string; };
    name?: string;
    object?: string;
    packageDimensions?: PackageDimensions;
    shippable?: boolean;
    statementDescriptor?: string;
    taxCode?: string;
    type?: string;
    unitLabel?: string;
    updated?: number;
    url?: string;
    defaultPriceObject?: Price;
    taxCodeObject?: TaxCode;
}