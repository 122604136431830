import {Pipe, PipeTransform} from '@angular/core';
import {ProjectServiceNotification} from "../api";
import PartialServiceNameEnum = ProjectServiceNotification.PartialServiceNameEnum;

/**
 * Transforms PartialServicesEnum Value to String
 */
@Pipe({
  name: 'service'
})
export class ServicePipe implements PipeTransform {
  /**
   * Transform Enum Value to String
   * @param value PartialServicesEnum Value
   */
  transform(value: PartialServiceNameEnum | undefined | string): string {
    switch (value) {
      case "VERSIEGELUNGSFLAECHEN":
        return "Versiegelungsflächen";
      //LPH 1
      case "GL_1_A":
        return "Klären der Aufgabenstellung auf Grundlage der Vorgaben oder der Bedarfsplanung des/der Auftraggeber*in"
      case "GL_1_B":
        return "Ortsbesichtigung"
      case "GL_1_C":
        return "Beraten zum gesamten Leistungs- und Untersuchungsbedarf"
      case "GL_1_D":
        return "Formulieren der Entscheidungshilfen für die Auswahl anderer an der Planung fachlich Beteiligter"
      case "GL_1_E":
        return "Zusammenfassen, Erläutern und Dokumentieren der Ergebnisse"
      case "BL_1_1":
        return "Bedarfsplanung inkl. Bedarfsermittlung"
      case "BL_1_3":
        return "Aufstellen eines Funktionsprogramms"
      case "BL_1_4":
        return "Aufstellen eines Raumprogramms"
      case "BL_1_5":
        return "Standortanalyse"
      case "BL_1_7":
        return "Beschaffen von Unterlagen, die für das Vorhaben erheblich sind"
      case "BL_1_8":
        return "Bestandsaufnahme"
      case "BL_1_9":
        return "Technische Substanzerkundung"
      case "BL_1_10":
        return "Betriebsplanung"
      case "BL_1_11":
        return "Prüfen der Umwelterheblichkeit"
      case "BL_1_12":
        return "Prüfen der Umweltverträglichkeit"
      case "BL_1_13":
        return "Machbarkeitsstudie"
      case "BL_1_14":
        return "Wirtschaftlichkeitsuntersuchung"
      case "BL_1_15":
        return "Projektstrukturplanung"
      case "BL_1_16":
        return "Zusammenstellen der Anforderungen aus Zertifizierungssystemen"
      case "BL_1_17":
        return "Verfahrensbetreuung, Mitwirken bei der Vergabe von Planungs- und Gutachterleistungen"

      //LPH 2
      case "GL_2_A":
        return "Analysieren der Grundlagen, Abstimmen der Leistungen mit den fachlich an der Planung Beteiligten"
      case "GL_2_B":
        return "Abstimmen der Zielvorstellungen, Hinweisen auf Zielkonflikte"
      case "GL_2_C":
        return "Erarbeiten der Vorplanung, Untersuchen, Darstellen und Bewerten von Varianten nach gleichen Anforderungen, Zeichnung im Maßstab nach Art und Größe des Objekts"
      case "GL_2_D":
        return "Klären und Erläutern der wesentlichen Zusammenhänge, Vorgaben und Bedingungen"
      case "GL_2_E":
        return "Bereitstellen der Arbeitsergebnisse als Grundlage für die anderen an der Planung fachlich Beteiligten sowie Koordination und Integration von deren Leistungen"
      case "GL_2_F":
        return "Vorverhandlungen über die Genehmigungsfähigkeit"
      case "GL_2_G":
        return "Kostenschätzung nach DIN 276, Vergleich mit den finanziellen Rahmenbedingungen"
      case "GL_2_H":
        return "Erstellen eines Terminplans mit den wesentlichen Vorgängen des Planungs- und Bauablaufs"
      case "GL_2_I1":
        return "Zusammenfassen, Erläutern und Dokumentieren der Ergebnisse"
      case "GL_2_I2":
        return "Zusammenfassen, Erläutern und Dokumentieren der Ergebnisse"
      case "BL_2_1":
        return "Aufstellen eines Katalogs für die Planung und Abwicklung der Programmziele"
      case "BL_2_2":
        return "Untersuchen alternativer Lösungsansätze nach verschiedenen Anforderungen einschließlich Kostenbewertung"
      case "BL_2_3":
        return "Beachten der Anforderungen des vereinbarten Zertifizierungssystems"
      case "BL_2_4":
        return "Durchführen des Zertifizierungssystems"
      case "BL_2_6":
        return "Aufstellen eines Finanzierungsplanes"
      case "BL_2_7":
        return "Mitwirken bei der Kredit- und Fördermittelbeschaffung"
      case "BL_2_8":
        return "Durchführen von Wirtschaftlichkeitsuntersuchungen"
      case "BL_2_9":
        return "Durchführen der Voranfrage"
      case "BL_2_10":
        return "Anfertigen von besonderen Präsentationshilfen, die für die Klärung im Vorentwurfsprozess nicht notwendig sind"
      case "BL_2_12":
        return "Aufstellen einer vertieften Kostenschätzung nach Positionen einzelner Gewerke"
      case "BL_2_13":
        return "Fortschreiben des Projektstrukturplanes"
      case "BL_2_14":
        return "Aufstellen von Raumbüchern"
      case "BL_2_15":
        return "Erarbeiten und Erstellen von besonderen bauordnungsrechtlichen Nachweisen für den vorbeugenden und organisatorischen Brandschutz bei baulichen Anlagen besonderer Art und Nutzung, Bestandbauten oder im Falle von Abweichungen von der Bauordnung"

      //LPH 3
      case "GL_3_A":
        return "Erarbeiten der Entwurfsplanung, unter weiterer Berücksichtigung der wesentlichen Zusammenhänge, Vorgaben und Bedingungen"
      case "GL_3_A_1":
        return "Erarbeiten der Entwurfsplanung (Grundrisse), unter weiterer Berücksichtigung der wesentlichen Zusammenhänge, Vorgaben und Bedingungen"
      case "GL_3_A_2":
        return "Erarbeiten der Entwurfsplanung (Schnitte), unter weiterer Berücksichtigung der wesentlichen Zusammenhänge, Vorgaben und Bedingungen"
      case "GL_3_A_3":
        return "Erarbeiten der Entwurfsplanung (Ansichten), unter weiterer Berücksichtigung der wesentlichen Zusammenhänge, Vorgaben und Bedingungen"
      case "GL_3_B":
        return "Bereitstellen der Arbeitsergebnisse als Grundlage für die anderen an der Planung fachlich Beteiligten sowie Koordination und Integration von deren Leistungen"
      case "GL_3_C":
        return "Objektbeschreibung"
      case "GL_3_D":
        return "Verhandlungen über die Genehmigungsfähigkeit"
      case "GL_3_E":
        return "Kostenberechnung nach DIN 276 und Vergleich mit der Kostenschätzung"
      case "GL_3_F":
        return "Fortschreiben des Terminplans"
      case "GL_3_G":
        return "Zusammenfassen, Erläutern und Dokumentieren der Ergebnisse"
      case "BL_3_1":
        return "Alternative Lösungsansätze"
      case "BL_3_2":
        return "Wirtschaftlichkeitsberechnung"
      case "BL_3_3":
        return "Aufstellen und Fortschreiben einer vertieften Kostenberechnung"
      case "BL_3_4":
        return "Fortschreiben von Raumbüchern"

      //LPH 4
      case "GL_4_AABA_else":
        return "Antrag auf Abweichung, Befreiung und Ausnahme"

      case "GL_4_Abstandsflaechen_HB_HE_SL":
        return "Abstandsflächen"

      case "GL_4_Abstandsflaechenuebernahme_BY":
        return "Abstandsflächenübernahme"

      case "GL_4_amtliche_Basiskarte_NW":
        return "Amtliche Basiskarte"

      case "GL_4_amtlicher_Lageplan_BB_BY_NI_NW_SL":
        return "Amtlicher Lageplan"

      case "GL_4_Angaben_Beherbergungsbetten_NW":
        return "Angaben zu Beherbergungsbetten"

      case "GL_4_Angaben_Beherbergungsstaette_ohne_Brandschutzkonzept_NW":
        return "Angaben zu Beherbergungsbetten ohne Brandschutzkonzept"

      case "GL_4_Angaben_CO_Warnanlage_NW":
        return "Angaben zu CO2 Warnanlagen"

      case "GL_4_Angaben_zu_gewerblichen_Anlagen_BW":
        return "Angaben zu gewerblichen Anlagen"

      case "GL_4_Angaben_zum_Betriebsraum_NW":
        return "Angaben zum Betriebsraum"

      case "GL_4_Angaben_zum_Krankenhaus_NW":
        return "Angaben zum Krankenhaus"

      case "GL_4_Anlagen_Gebuehren_HH":
        return "Anlage zu Gebühren"

      case "GL_4_anrechenbare_Kosten_MV_SH_ST":
        return "Anrechenbare Kosten"

      case "GL_4_anrechenbarer_Bauwert_HB":
        return "Anrechenbarer Bauwert"

      case "GL_4_Ansicht_BW_NW":
        return "Ansicht"

      case "GL_4_Ansicht_else":
        return "Ansicht"

      case "GL_4_Ansicht_SL":
        return "Ansicht"

      case "GL_4_Antrag_auf_Abloesung_notwendiger_Einstellplaetze_NI":
        return "Antrag auf Ablösung notwendiger Einstellplätze"

      case "GL_4_Anzahl_Fahrradstellplaetze_RP":
        return "Angabe der Anzahl von Fahrradstellplätze"

      case "GL_4_ArbeitsstaettenVO_RP":
        return "Arbeitsstättenverordnung"

      case "GL_4_Art_der_baulichen_Nutzung_BB_BE_BW_NW_SL":
        return "Angaben zur Art der baulichen Nutzung"

      case "GL_4_Artenschutz_else":
        return "Artenschutz"

      case "GL_4_Aussenanlagenplan_BE":
        return "Außenanlagenplan"

      case "GL_4_Auszug_Bebauungsplan_HB":
        return "Auszug aus dem Bebauungsplan"

      case "GL_4_Auszug_Bebauungsplan_SL":
        return "Auszug aus dem Bebauungsplan"

      case "GL_4_AVK_BW_NW":
        return "Abfallverwertungskonzept"

      case "GL_4_Bauartgenehmigung_else":
        return "Bauartengenehmigung"

      case "GL_4_Baubeschreibung_else":
        return "Baubeschreibung"

      case "GL_4_Baubeschreibung_Fluessiggas_1_RP":
        return "Baubeschreibung zum Thema Flüssiggas"

      case "GL_4_Baubeschreibung_Fluessiggas_3_RP":
        return "Baubeschreibung zum Thema Flüssiggas"

      case "GL_4_Baubeschreibung_Heizoel_1_RP":
        return "Baubeschreibung zum Thema Heizöl"

      case "GL_4_Baubeschreibung_Heizoel_3_RP":
        return "Baubeschreibung zum Thema Heizöl"

      case "GL_4_Baukosten_HB":
        return "Baukosten"

      case "GL_4_Baukostenwert_HB":
        return "Baukostenwert"

      case "GL_4_Baumbestandsbescheinigung_1_HB":
        return "Baumbestandsbescheinigung"

      case "GL_4_Baumbestandsbescheinigung_2_HB":
        return "Baumbestandsbescheinigung"

      case "GL_4_Baumbestandsbescheinigung_HH":
        return "Baumbestandsbescheinigung"

      case "GL_4_Baunebenrecht_HB":
        return "Baunebenrecht"

      case "GL_4_Bauvorlageberechtigung_else":
        return "Bauvorlagenberechtigung"

      case "GL_4_Bauwert_HB":
        return "Bauwert"

      case "GL_4_beabsichtigte_Nutzung_ST":
        return "Angaben zur beabsichtigten Nutzung"

      case "GL_4_Benennung_Bauleitender_BW_SH":
        return "Benennung des Bauleiters"

      case "GL_4_Berechnung_Verkaufsstaette_NW":
        return "Berechnung von Verkaufsstätten"

      case "GL_4_Bericht_ueber_den_geprueften_Brandschutznachweis_BE":
        return "Bericht über den geprüften Brandschutznachweis"

      case "GL_4_Bericht_ueber_den_geprueften_Standsicherheitnachweis_BE":
        return "Bericht über den geprüften Standsicherheitsnachweis"

      case "GL_4_Bescheinigung_nach_67_Abs_4_LBO_SL":
        return "Bescheinigung über die Prüfung nach § 67 Abs. 4 LBO"

      case "GL_4_Beschreibung_barrierefreie_Wohnungen_BE":
        return "Beschreibung barrierefreier Wohnungen"

      case "GL_4_Beschreibung_Baugrundstueck_SL":
        return "Beschreibung des Baugrundstücks"

      case "GL_4_Beschreibung_baulicher_Anlagen_SL":
        return "Beschreibung der baulichen Anlagen"

      case "GL_4_Beschreibung_der_Baumassnahmen_geringen_Umfangs_SL":
        return "Beschreibung der Baumaßnahme geringen Umfangs"

      case "GL_4_Bestuhlungswegplan_und_Rettungswegplan_NW":
        return "Bestuhlungs- und Rettungswegplan"

      case "GL_4_Betriebsbeschreibung_else":
        return "Betriebsbeschreibung"

      case "GL_4_BFK_HE_NW":
        return "Barrierefreiheitskonzept"

      case "GL_4_Bodenschutzkonzept_BW":
        return "Bodenschutzkonzept"

      case "GL_4_Brandschutzkonzept_BY_RP_ST":
        return "Brandschutzkonzept"

      case "GL_4_Brandschutzkonzept_Sonderbauten_else":
        return "Brandschutzkonzept für Sonderbauten"

      case "GL_4_Brandschutzkonzept_Sonderbauten_BB_BE_BY_HB_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH":
        return "Brandschutzkonzept für Sonderbauten"

      case "GL_4_Brandschutznachweis_BB_BE_BY_HB_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH":
        return "Brandschutznachweis"

      case "GL_4_Brandschutznachweis_in_Bauvorlagen_SL":
        return "Brandschutznachweis in der Bauvorlage"

      case "GL_4_BRI_umbauter_Raum_HB_HE_MV_NW_RP_SL_ST":
        return "Angabe des umbauten Raumes als BRI"

      case "GL_4_Darstellung_Zufahrtsverhaeltnisse_ST":
        return "Darstellung der Zufahrtsverhältnisse"

      case "GL_4_denkmalschutzrechtliche_Erlaubnis_BY":
        return "Denkmalschutzrechtliche Erlaubnis"

      case "GL_4_Einfuegungsnachweis_HE":
        return "Einfügungsnachweis"

      case "GL_4_Energieausweis_else":
        return "Energieausweis"

      case "GL_4_ergaenzende_Baubeschreibung_MV":
        return "Ergänzende Baubeschreibung"

      case "GL_4_Ergaenzungsplan_HB":
        return "Ergänzungsplan"

      case "GL_4_Erklaerung_der_Nachbarschaft_SL":
        return "Erklärung der Nachbarschaft"

      case "GL_4_Erklaerung_zum_barrierefreien_Bauen_SL":
        return "Erklärung zum barrierefreien Bauen"

      case "GL_4_Erlaeuterung_gegen_nachbarliche_Zustimmung_RP":
        return "Erläuterungen gegen die nachbarliche Zustimmung"

      case "GL_4_erneuerbare_Energien_BB":
        return "Angaben zu erneuerbaren Energien"

      case "GL_4_Erschliessungssicherung_BB_BE_BY_HB_HH_MV_SH_ST_TH":
        return "Angaben zur Erschließungssicherung"

      case "GL_4_Erschuetterungsnachweis_BE_BY_SN_SH":
        return "Erschütterungsnachweis"

      case "GL_4_Erschuetterungsnachweis_BE_BY_HB_HH_SH_SN_ST_TH":
        return "Erschütterungsnachweis"

      case "GL_4_erteilte_Bescheide_BE_HB":
        return "Erteilte Bescheide"

      case "GL_4_Feuerungsanlagen_BW_RP_SL":
        return "Angaben zu Feuerungsanlagen"

      case "GL_4_Flaechen_RP":
        return "Angaben zu Flächen"

      case "GL_4_Foerderungsnachweis_SH":
        return "Förderungsnachweise"

      case "GL_4_Freiflaechengestaltungsplan_SL":
        return "Freiflächengestaltungsplan"

      case "GL_4_Freiflaechenplan_HB_HE":
        return "Freiflächenplan"

      case "GL_4_Gebaeudeklasse_Hoehe_BB_BW_HB_HH_MV_NI_SH":
        return "Angaben zur Gebäudeklasse"

      case "GL_4_Lueftungsanlagen_Feuerungsanlagen_HE":
        return "Lüftungsanlagen"

      case "GL_4_Geschossberechnung_HB":
        return "Geschossberechnung"

      case "GL_4_Grundbuchauszug_BE_HB":
        return "Grundbuchauszug"

      case "GL_4_Grundriss_BW":
        return "Grundriss"

      case "GL_4_Grundriss_else":
        return "Grundriss"

      case "GL_4_Grundriss_HE":
        return "Grundriss"

      case "GL_4_Grundriss_NI":
        return "Grundriss"

      case "GL_4_Grundriss_NW":
        return "Grundriss"

      case "GL_4_Grundriss_RP":
        return "Grundriss"

      case "GL_4_Grundriss_SL":
        return "Grundriss"

      case "GL_4_Grundriss_SN_ST":
        return "Grundriss"

      case "GL_4_Grundstuecksentwaesserung_1_BW_RP_SH_SL":
        return "Angaben zur Grundstücksentwässerung"

      case "GL_4_Grundstuecksentwaesserung_2_BW_RP_SH_SL":
        return "Angaben zur Grundstücksentwässerung"

      case "GL_4_Herstellungskosten_BE_NI_NW_RP_ST":
        return "Herstellungskosten"

      case "GL_4_Hochwasserrisiko_RP":
        return "Angaben zum Hochwasserrisiko"

      case "GL_4_Hygienegutachten_HE":
        return "Hygienegutachten"

      case "GL_4_Kriterienkatalog_BY_BE_BB_HB_MV_NW_SL_ST_SH_TH":
        return "Kriterienkatalog"

      case "GL_4_Kriterienkatalog_BB_BE_BY_HB_MV_NW_SH_SL_SN_ST_TH":
        return "Kriterienkatalog"

      case "GL_4_Laermschutzkonzept_HE":
        return "Lärmschutzkonzept"

      case "GL_4_Lageplan_BB_BE_BW_HB_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH":
        return "Lageplan"

      case "GL_4_Liegenschaftsplan_BB":
        return "Liegenschaftsplan"

      case "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH":
        return "Liegenschaftsplan"

      case "GL_4_Liegenschaftsplan_BE_BY_HE_MV_NI_NW_RP_SH_SL_TH":
        return "Liegenschaftsplan"

      case "GL_4_Liegenschaftsplan_HH":
        return "Liegenschaftsplan"

      case "GL_4_Liegenschaftsplan_SN_ST":
        return "Liegenschaftsplan"

      case "GL_4_Mass_der_baulichen_Nutzung_else":
        return "Maß der baulichen Nutzung"

      case "GL_4_Mobilitaetsbedarf_HB":
        return "Angaben zum Mobilitätsbedarf"

      case "GL_4_Nachbarliche_Zustimmung_BY_HB_RP_SN_TH":
        return "Nachbarliche Zustimmung"

      case "GL_4_Nachbarliche_Zustimmung_BE_BY_HB_RP_SN_TH":
        return "Nachbarliche Zustimmung"



      case "GL_4_Nachweis_barrierefreie_Stellplaetze_BE":
        return "Nachweis zu barrierefreien Stellplätzen"

      case "GL_4_Nachweis_Stellplaetze_fuer_Fahrraeder_BE":
        return "Nachweis zu Fahrradstellplätzen"

      case "GL_4_oeffentlich_rechtliche_Vorschriften_HB_HH":
        return "Öffentlich rechtliche Vorschriften"

      case "GL_4_oeffentliche_Bekanntmachung_BY":
        return "Öffentliche Bekanntmachung"

      case "GL_4_Oeffentlichkeitsbeteiligung_BY":
        return "Angaben zur Öffentlichkeitsbeteiligung"

      case "GL_4_oertliche_Bauvorschrift_BW_BY_BB_HB_HE_MV_NI_NW_RP_SL_SN_ST_SH_TH":
        return "Örtliche Bauvorschriften"

      case "GL_4_oertliche_Bauvorschrift_BB_SL":
        return "Örtliche Bauvorschriften"

      case "GL_4_Perspektiven_BE":
        return "Perspektiven"

      case "GL_4_Pruefverzicht_Brandschutznachweis_HB":
        return "Prüfverzicht des Brandschutznachweises"

      case "GL_4_Pruefverzicht_Standsicherheitsnachweis_HB":
        return "Prüfverzicht des Standsicherheitsnachweises"

      case "GL_4_qualifizierter_Lageplan_HB":
        return "Qualifizierter Lageplan"

      case "GL_4_qualifizierter_Lageplan_NI":
        return "Qualifizierter Lageplan"

      case "GL_4_Rohbaukosten_NI_NW":
        return "Rohbaukosten"

      case "GL_4_Sachverstaendigenbescheinigung_Brandschutz_BY_MV_NW_RP_SH":
        return "Sachverständigenbescheinigung Brandschutz"

      case "GL_4_Sachverstaendigenbescheinigung_Schallschutz_NW":
        return "Sachverständigenbescheinigung Schallschutz"

      case "GL_4_Sachverstaendigenbescheinigung_Waermeschutz_NW":
        return "Sachverständigenbescheinigung Wärmeschutz"

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_SL_ST":
        return "Sachverständigennachweis Standsicherheit"

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_BB_BE_BY_HB_MV_NW_RP_SH_SN_TH":
        return "Sachverständigennachweis Standsicherheit"

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_else":
        return "Sachverständigennachweis Standsicherheit"

      case "GL_4_Schallschutznachweis_BB_BE_BW_BY_HB_HE_HH_MV_NW_RP_SH_SL_SN_ST_TH":
        return "Schallschutznachweis"

      case "GL_4_Schallschutznachweis_else":
        return "Schallschutznachweis"

      case "GL_4_Schalltechnische_Untersuchung_else":
        return "Schalltechnische Untersuchung"

      case "GL_4_Schalltechnische_Untersuchung_BB_SL":
        return "Schalltechnische Untersuchung"

      case "GL_4_Schnitt_BB":
        return "Schnitt"

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI":
        return "Schnitt"

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH":
        return "Schnitt"

      case "GL_4_Schnitt_NW":
        return "Schnitt"

      case "GL_4_Spielplatznachweis_else":
        return "Spielplatznachweis"

      case "GL_4_Standsicherheitsnachweis_BW":
        return "Standsicherheitsnachweis"

      case "GL_4_Standsicherheitsnachweis_else":
        return "Standsicherheitsnachweis"

      case "GL_4_Statistik_else":
        return "Statistik"

      case "GL_4_Stellplatznachweis_else":
        return "Stellplatznachweis"

      case "GL_4_Stellungnahme_BE":
        return "Stellungnahme zum Bauvorhaben"

      case "GL_4_Stellungnahme_Bodenschutzbehoerde_HB":
        return "Stellungnahme der Bodenschutzbehörde"

      case "GL_4_Stoerfallbetrieb_RP":
        return "Angaben zum Störfallbetrieb"

      case "GL_4_Uebereinstimmungserklaerung_BE":
        return "Übereinstimmungserklärung"

      case "GL_4_Uebersichtsplan_HE_RP_SH":
        return "Übersichtsplan"

      case "GL_4_Unbedenklichkeitsbescheinigung_Feuerwehr_HB":
        return "Unbedenklichkeitsbescheinigung der Feuerwehr"

      case "GL_4_Unterlagen_ueber_Brennstofflagerung_NI":
        return "Angaben zur Brennstofflagerung"

      case "GL_4_UVP_else":
        return "Umweltverträglichkeitsprüfung"

      case "GL_4_Vereinfachtes_Abfallverwertungskonzept_BW":
        return "Vereinfachtes Abfallverwertungskonzept"

      case "GL_4_Vergleichsberechnung_MV":
        return "Vergleichsberechnung"

      case "GL_4_Vertretervollmacht_BB_BE_HE_MV_NW_SN_ST":
        return "Vertretervollmacht"

      case "GL_4_Waermeschutznachweis_BB_BE_BY_HB_HE_HH_NW_RP_SH_SL_ST_TH":
        return "Wärmeschutznachweis"

      case "GL_4_Waermeschutznachweis_else":
        return "Wärmeschutznachweis"

      case "GL_4_wassergefaehrdende_Stoffe_else":
        return "Angaben zu wassergefährdenden Stoffen"


      case "BL_4_1":
        return "Mitwirken bei der Beschaffung der nachbarlichen Zustimmung";

      case "BL_4_2":
        return "Nachweise zu technischen, konstruktiven und bauphysikalischen Belangen";

      case "BL_4_3":
        return "Unterstützung beim Widerspruchs-, Klage- oder ähnlichen Verfahren";

      //LPH 5

      case "BL_5_1":
        return "Objektbeschreibung";

      case "BL_5_3":
        return "Fortschreiben von Raumbüchern";

      case "BL_5_4":
        return "Mitwirken beim Anlagenkennzeichnungssystem (AKS)";

      case "GL_5_A":
        return "Erarbeitung der Ausführungsplanung";

      case "GL_5_B":
        return "Erstellung von Ausführungs-, Detail- und Konstruktionszeichnungen";

      case "GL_5_C":
        return "Bereitstellung der Arbeitsergebnisse ";

      case "GL_5_D":
        return "Fortschreiben des Terminplans";


      default:
        return String(value);
    }
  }

}

@Pipe({
  name: 'serviceActivated'
})
export class ServiceActivatedPipe implements PipeTransform {
  /**
   * Transform Enum Value to String
   * @param value PartialServicesEnum Value
   */
  transform(value: PartialServiceNameEnum | undefined | string): boolean {
    switch (value) {
      //LPH 1
      case "GL_1_A":
        return true
      case "GL_1_B":
        return true
      case "GL_1_C":
        return true
      case "GL_1_D":
        return true
      case "GL_1_E":
        return false
      case "BL_1_1":
        return true
      case "BL_1_3":
        return true
      case "BL_1_4":
        return true
      case "BL_1_5":
        return true
      case "BL_1_7":
        return true
      case "BL_1_7_1":
        return false
      case "BL_1_7_2":
        return false
      case "BL_1_7_3":
        return false
      case "BL_1_7_4":
        return false
      case "BL_1_8":
        return true
      case "BL_1_9":
        return true
      case "BL_1_10":
        return true
      case "BL_1_11":
        return true
      case "BL_1_12":
        return true
      case "BL_1_13":
        return true
      case "BL_1_14":
        return true
      case "BL_1_15":
        return true
      case "BL_1_16":
        return true
      case "BL_1_17":
        return true

      //LPH 2
      case "GL_2_A":
        return true
      case "GL_2_B":
        return true
      case "GL_2_C":
        return true
      case "GL_2_D":
        return true
      case "GL_2_E":
        return true
      case "GL_2_F":
        return true
      case "GL_2_G":
        return true
      case "GL_2_H":
        return true
      case "GL_2_I1":
        return true
      case "GL_2_I2":
        return false
      case "BL_2_1":
        return true
      case "BL_2_2":
        return true
      case "BL_2_3":
        return true
      case "BL_2_4":
        return true
      case "BL_2_6":
        return true
      case "BL_2_7":
        return true
      case "BL_2_8":
        return true
      case "BL_2_9":
        return true
      case "BL_2_10":
        return true
      case "BL_2_12":
        return true
      case "BL_2_13":
        return true
      case "BL_2_14":
        return true
      case "BL_2_15":
        return true

      //LPH 3
      case "GL_3_A":
        return true
      case "GL_3_A_1":
        return false
      case "GL_3_A_2":
        return false
      case "GL_3_A_3":
        return false
      case "GL_3_B":
        return true
      case "GL_3_C":
        return true
      case "GL_3_D":
        return true
      case "GL_3_E":
        return true
      case "GL_3_F":
        return true
      case "GL_3_G":
        return false
      case "BL_3_1":
        return true
      case "BL_3_2":
        return true
      case "BL_3_3":
        return true
      case "BL_3_4":
        return true

      case "BL_3_ENERGIEAUSWEIS":
        return false;

      //LPH 4
      case "GL_4_AVK_BW_NW":
        return true;

      case "GL_4_Abstandsflaechen_HB_HE_SL":
        return true;

      case "GL_4_Abstandsflaechenuebernahme_BY":
        return true;

      case "GL_4_Angaben_Beherbergungsbetten_NW":
        return true;

      case "GL_4_Angaben_Beherbergungsstaette_ohne_Brandschutzkonzept_NW":
        return true;

      case "GL_4_Angaben_CO_Warnanlage_NW":
        return true;

      case "GL_4_Angaben_zu_gewerblichen_Anlagen_BW":
        return true;

      case "GL_4_Angaben_zum_Betriebsraum_NW":
        return true;

      case "GL_4_Angaben_zum_Krankenhaus_NW":
        return true;

      case "GL_4_Anlagen_Gebuehren_HH":
        return true;

      case "GL_4_Ansicht_BW_NW":
        return true;

      case "GL_4_Ansicht_SL":
        return true;

      case "GL_4_Ansicht_else":
        return false;

      case "GL_4_Antrag_auf_Abloesung_notwendiger_Einstellplaetze_NI":
        return true;

      case "GL_4_Anzahl_Fahrradstellplaetze_RP":
        return true;

      case "GL_4_ArbeitsstaettenVO_RP":
        return true;

      case "GL_4_Art_der_baulichen_Nutzung_BB_BE_BW_NW_SL":
        return true;

      case "GL_4_Artenschutz_else":
        return true;

      case "GL_4_Aussenanlagenplan_BE":
        return true;

      case "GL_4_Auszug_Bebauungsplan_HB":
        return true;

      case "GL_4_Auszug_Bebauungsplan_SL":
        return true;

      case "GL_4_B":
        return true;

      case "GL_4_BFK_HE_NW":
        return true;

      case "GL_4_BRI_umbauter_Raum_HB_MV_NW_RP_SH_SL_ST_TH_1":
        return true;

      case "GL_4_Bauartgenehmigung_else":
        return true;

      case "GL_4_Baubeschreibung_Fluessiggas_1_RP":
        return true;

      case "GL_4_Baubeschreibung_Fluessiggas_3_RP":
        return false;

      case "GL_4_Baubeschreibung_Heizoel_1_RP":
        return true;

      case "GL_4_Baubeschreibung_Heizoel_3_RP":
        return false;

      case "GL_4_Baubeschreibung_else":
        return true;

      case "GL_4_Baukosten_HB":
        return true;

      case "GL_4_Baukostenwert_HB":
        return true;

      case "GL_4_Baumbestandsbescheinigung_1_HB":
        return true;

      case "GL_4_Baumbestandsbescheinigung_2_HB":
        return false;

      case "GL_4_Baumbestandsbescheinigung_HH":
        return true;

      case "GL_4_Baunebenrecht_HB":
        return true;

      case "GL_4_Bauvorlageberechtigung_else":
        return true;

      case "GL_4_Bauwert_HB":
        return true;

      case "GL_4_Benennung_Bauleitender_BW_SH":
        return true;

      case "GL_4_Berechnung_Verkaufsstaette_NW":
        return true;

      case "GL_4_Bericht_ueber_den_geprueften_Brandschutznachweis_BE":
        return true;

      case "GL_4_Bericht_ueber_den_geprueften_Standsicherheitnachweis_BE":
        return true;

      case "GL_4_Bescheinigung_nach_67_Abs_4_LBO_SL":
        return true;

      case "GL_4_Beschreibung_Baugrundstueck_SL":
        return true;

      case "GL_4_Beschreibung_barrierefreie_Wohnungen_BE":
        return true;

      case "GL_4_Beschreibung_baulicher_Anlagen_SL":
        return true;

      case "GL_4_Beschreibung_der_Baumassnahmen_geringen_Umfangs_SL":
        return true;

      case "GL_4_Bestuhlungswegplan_und_Rettungswegplan_NW":
        return true;

      case "GL_4_Betriebsbeschreibung_else":
        return true;

      case "GL_4_Bodenschutzkonzept_BW":
        return true;

      case "GL_4_Brandschutzkonzept_BY_RP_ST":
        return true;

      case "GL_4_Brandschutzkonzept_Sonderbauten_else_":
        return true;

      case "GL_4_Brandschutznachweis_BB_BE_BY_HB_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH":
        return true;

      case "GL_4_Brandschutznachweis_in_Bauvorlagen_SL":
        return true;

      case "GL_4_Darstellung_Zufahrtsverhaeltnisse_ST":
        return true;

      case "GL_4_Einfuegungsnachweis_HE":
        return true;

      case "GL_4_Energieausweis_else":
        return true;

      case "GL_4_Ergaenzungsplan_HB":
        return true;

      case "GL_4_Erklaerung_der_Nachbarschaft_SL":
        return true;

      case "GL_4_Erklaerung_zum_barrierefreien_Bauen_SL":
        return true;

      case "GL_4_Erläuterung_gegen_nachbarliche_Zustimmung_RP":
        return true;

      case "GL_4_Erschliessungssicherung_BB_BE_BY_HB_HH_MV_SH_ST_TH":
        return true;

      case "GL_4_Erschuetterungsnachweis_BE_BY_SN_SH":
        return true;

      case "GL_4_Feuerungsanlagen_BW_RP_SL":
        return true;

      case "GL_4_Flaechen_RP":
        return true;

      case "GL_4_Foerderungsnachweis_SH":
        return true;

      case "GL_4_Freiflaechengestaltungsplan_SL":
        return true;

      case "GL_4_Freiflaechenplan_HB_HE":
        return true;

      case "GL_4_Gebaeudeklasse_Hoehe_BB_BW_HB_HH_MV_NI_SH":
        return true;

      case "GL_4_Geschossberechnung_HB":
        return true;

      case "GL_4_Grundbuchauszug_BE_HB":
        return false;

      case "GL_4_Grundriss_BW":
        return false;

      case "GL_4_Grundriss_HE":
        return false;

      case "GL_4_Grundriss_NI":
        return false;

      case "GL_4_Grundriss_NW":
        return false;

      case "GL_4_Grundriss_RP":
        return false;

      case "GL_4_Grundriss_SL":
        return false;

      case "GL_4_Grundriss_SN_ST":
        return false;

      case "GL_4_Grundriss_else":
        return true;

      case "GL_4_Grundstuecksentwaesserung_1_BW_RP_SH_SL":
        return true;

      case "GL_4_Grundstuecksentwaesserung_2_BW_RP_SH_SL":
        return false;

      case "GL_4_Herstellungskosten_BE_NI_NW_RP_ST":
        return true;

      case "GL_4_Hochwasserrisiko_RP":
        return true;

      case "GL_4_Hygienegutachten_HE":
        return true;

      case "GL_4_Kriterienkatalog_BY_BE_BB_HB_MV_NW_SL_ST_SH_TH":
        return true;

      case "GL_4_Laermschutzkonzept_HE":
        return true;

      case "GL_4_Lageplan_else":
        return true;

      case "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH":
        return true;

      case "GL_4_Lueftungsanlagen_Feuerungsanlagen_HE":
        return true;

      case "GL_4_Mass_der_baulichen_Nutzung_else":
        return true;

      case "GL_4_Mobilitaetsbedarf_HB":
        return true;

      case "GL_4_Nachbarliche_Zustimmung_BY_HB_RP_SN_TH":
        return true;

      case "GL_4_Nachweis_Stellplaetze_fuer_Fahrraeder_BE":
        return true;

      case "GL_4_Nachweis_barrierefreie_Stellplaetze_BE":
        return true;

      case "GL_4_Oeffentlichkeitsbeteiligung_BY":
        return true;

      case "GL_4_Perspektiven_BE":
        return true;

      case "GL_4_Pruefverzicht_Brandschutznachweis_HB":
        return true;

      case "GL_4_Pruefverzicht_Standsicherheitsnachweis_HB":
        return true;

      case "GL_4_Rohbaukosten_NI_NW":
        return true;

      case "GL_4_Sachverstaendigenbescheinigung_Brandschutz_BE_BY_MV_NW_RP_SH":
        return true;

      case "GL_4_Sachverstaendigenbescheinigung_Schallschutz_NW":
        return true;

      case "GL_4_Sachverstaendigenbescheinigung_Waermeschutz_NW":
        return true;

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_BB_BE_BY_MV_NW_SH_SN_TH":
        return true;

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_else":
        return true;

      case "GL_4_Schallschutznachweis_else":
        return true;

      case "GL_4_Schalltechnische_Untersuchung_else":
        return true;

      case "GL_4_Schnitt_BB":
        return true;

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI":
        return true;

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH":
        return true;

      case "GL_4_Schnitt_NW":
        return true;

      case "GL_4_Spielplatznachweis_else":
        return true;

      case "GL_4_Standsicherheitsnachweis_BW":
        return false;

      case "GL_4_Standsicherheitsnachweis_else":
        return true;

      case "GL_4_Statistik_else":
        return true;

      case "GL_4_Stellplatznachweis_else":
        return true;

      case "GL_4_Stellungnahme_BE":
        return true;

      case "GL_4_Stellungnahme_Bodenschutzbehoerde_HB":
        return true;

      case "GL_4_Stoerfallbetrieb_RP":
        return true;

      case "GL_4_UVP_else":
        return true;

      case "GL_4_Uebereinstimmungserklaerung_BE":
        return true;

      case "GL_4_Uebersichtsplan_HE_RP_SH":
        return true;

      case "GL_4_Unbedenklichkeitsbescheinigung_Feuerwehr_HB":
        return true;

      case "GL_4_Unterlagen_ueber_Brennstofflagerung_NI":
        return true;

      case "GL_4_Vereinfachtes_Abfallverwertungskonzept_BW":
        return true;

      case "GL_4_Vergleichsberechnung_MV":
        return true;

      case "GL_4_Vertretervollmacht_BB_BE_HE_MV_NW_SN_ST":
        return true;

      case "GL_4_Waermeschutznachweis_else":
        return true;

      case "GL_4_amtliche_Basiskarte_NW":
        return true;

      case "GL_4_amtlicher_Lageplan_BB_BY_NI_NW_SL":
        return true;

      case "GL_4_anrechenbare_Kosten_MV_SH_ST":
        return true;

      case "GL_4_anrechenbarer_Bauwert_HB":
        return true;

      case "GL_4_beabsichtigte_Nutzung_ST":
        return true;

      case "GL_4_denkmalschutzrechtliche_Erlaubnis_BY":
        return true;

      case "GL_4_ergaenzende_Baubeschreibung_MV":
        return true;

      case "GL_4_erneuerbare_Energien_BB":
        return true;

      case "GL_4_erteilte_Bescheide_BE_HB":
        return true;

      case "GL_4_oeffentlich_rechtliche_Vorschriften_HB_HH_SN_ST":
        return true;

      case "GL_4_oeffentliche_Bekanntmachung_BY":
        return true;

      case "GL_4_oertliche_Bauvorschrift_BW_BY_BB_HB_HE_MV_NI_NW_RP_SL_SN_ST_SH_TH":
        return true;

      case "GL_4_qualifizierter_Lageplan_HB":
        return true;

      case "GL_4_qualifizierter_Lageplan_NI":
        return true;

      case "GL_4_wassergefaehrdende_Stoffe_else":
        return true;

      case "BL_4_1":
        return true
      case "BL_4_2":
        return true
      case "BL_4_3":
        return true
      default:
        return true


      //LPH 5

      case "BL_5_1":
        return true

      case "BL_5_3":
        return true

      case "BL_5_4":
        return true

      case "GL_5_A":
        return true

      case "GL_5_A_1":
        return false

      case "GL_5_A_2":
        return false

      case "GL_5_A_4":
        return false

      case "GL_5_B":
        return true

      case "GL_5_B_1":
        return false

      case "GL_5_B_2":
        return false

      case "GL_5_B_3":
        return false

      case "GL_5_B_4":
        return false

      case "GL_5_B_5":
        return false

      case "GL_5_B_6":
        return false

      case "GL_5_C":
        return true

      case "GL_5_C_3":
        return false

      case "GL_5_D":
        return true

    }
  }

}

@Pipe({
  name: 'aspectActivated'
})
export class AspectActivatedPipe implements PipeTransform {
  /**
   * Transform Enum Value to String
   * @param value PartialServicesEnum Value
   */
  transform(value: string): boolean {
    switch (value) {
      case "GL_5_B_1_2":
        return false
      case "GL_5_B_1_7":
        return false
      case "GL_5_B_2_2":
        return false
      case "GL_5_B_3_2":
        return false
      case "GL_5_B_4_2":
        return false
      case "GL_5_B_5_2":
        return false
      default:
        return true
    }
  }
}


@Pipe({
  name: 'aspect'
})
export class AspectPipe implements PipeTransform {
  /**
   * Transform Enum Value to String
   * @param value PartialServicesEnum Value
   */
  transform(value: string): string {
    switch (value) {
      //LPH 1
      case "BL_1_1_1":
        return "Projektkontext"

      case "BL_1_1_2":
        return "Projektziele"

      case "BL_1_1_3":
        return "Bedarfsangaben des Auftraggebers"

      case "BL_1_1_4":
        return "Erstellen eines Bedarfsplans"

      case "BL_1_1_5":
        return "Varianten der Bedarfsdeckung"

      case "BL_1_10_1":
        return "Planung der Belegschaft"

      case "BL_1_10_2":
        return "Planung des Produktionsapparates"

      case "BL_1_10_3":
        return "Planung des Personals"

      case "BL_1_10_4":
        return "Planung der Arbeitsdurchführung"

      case "BL_1_10_5":
        return "Planung der Arbeitszeit"

      case "BL_1_10_6":
        return "Planung der Unterlieferer"

      case "BL_1_11_1":
        return "Prüfen der Umwelterheblichkeit"

      case "BL_1_12_1":
        return "Beschreibung des Vorhabens"

      case "BL_1_12_2":
        return "Beschreibung der Umwelt"

      case "BL_1_12_3":
        return "Beschreibung der Maßnahmen gegen erhebliche Umweltauswirkungen"

      case "BL_1_12_4":
        return "Beschreibung der zu erwartenden erheblichen Umweltauswirkungen"

      case "BL_1_12_5":
        return "Beschreibung der Alternativen und Gründe für die getroffene Wahl"

      case "BL_1_12_6":
        return "Zusammenfassung des UVP-Berichts"

      case "BL_1_13_1":
        return "Klärung der Machbarkeitsstudie"

      case "BL_1_13_2":
        return "Zusammentragen aller Randbedingungen"

      case "BL_1_13_3":
        return "Auswertung und Vergleich der Randbedingungen mit den Projektzielen"

      case "BL_1_13_4":
        return "Bewertung über die Machbarkeit"

      case "BL_1_14_1":
        return "Funktionsabläufe und ihre wirtschaftlichen Auswirkungen"

      case "BL_1_15_1":
        return "Projektstrukturplan"

      case "BL_1_16_1":
        return "Anforderung des DGNB Zertifizierungssystem"

      case "BL_1_16_2":
        return "Anforderung des BNB Zertifizierungssystem"

      case "BL_1_16_3":
        return "Anforderung des BREEAM Zertifizierungssystem"

      case "BL_1_16_4":
        return "Anforderung des EU-Green-Building Zertifizierungssystem"

      case "BL_1_16_5":
        return "Anforderung des CASBEE Zertifizierungssystem"

      case "BL_1_17_1":
        return "Leistungsinhalte der Beteiligten"

      case "BL_1_17_2":
        return "Mitwirken bei der Beauftragung der Beteiligten"

      case "BL_1_3_1":
        return "Räumlich-funktionalen Anordnungen und Anforderungen"

      case "BL_1_3_2":
        return "Flächenbedürfnisse des Auftraggebers nach Nutzungsarten"

      case "BL_1_4_1":
        return "Raumprogramm"

      case "BL_1_5_1":
        return "Geografische Lage / Grundstücksstruktur"

      case "BL_1_5_2":
        return "Verkehrsstruktur"

      case "BL_1_5_3":
        return "Wirtschaftsstruktur und Umfeldnutzung"

      case "BL_1_5_4":
        return "Soziodemographische Struktur"

      case "BL_1_5_5":
        return "Image & Investitionsklima"

      case "BL_1_7_1_1":
        return "Grundbuch"

      case "BL_1_7_2_1":
        return "Baulastenverzeichnis der Bauaufsichtbehörde"

      case "BL_1_7_3_1":
        return "Katasterplan oder amtlicher Lageplan"

      case "BL_1_7_4_1":
        return "Bauunterlagen"

      case "BL_1_8_1":
        return "Bestandsunterlagen und -pläne"

      case "BL_1_8_2":
        return "Sichtprüfung"

      case "BL_1_8_3":
        return "Dokumentation vom Zustand"

      case "BL_1_9_1":
        return "Technische Untersuchung der Bausubstanz"

      case "BL_2_1_1":
        return "Katalogisierung der Programmziele"

      case "BL_2_10_1":
        return "Klärung der Präsentationshilfen"

      case "BL_2_10_2":
        return "Anfertigung der Präsentationshilfen"

      case "BL_2_12_1":
        return "Ermittlung der Flächen und Volumen"

      case "BL_2_12_3":
        return "Berechnung der Kosten"

      case "BL_2_13_1":
        return "Fortschreiben des Projektstrukturplans"

      case "BL_2_14_1":
        return "Aufstellen von Raumbüchern"

      case "BL_2_15_1":
        return "Erstellen von Nachweisen des vorbeugenden Brandschutzes bei baulichen Anlagen besonderer Art und Nutzung"

      case "BL_2_15_2":
        return "Erstellen von Nachweisen des vorbeugenden Brandschutzes bei Bestandsbauten"

      case "BL_2_15_3":
        return "Erstellen von Nachweisen des vorbeugenden Brandschutzes im Falle von Abweichungen"

      case "BL_2_2_1":
        return "Alternative Anforderungsprofile der Gebäudenutzung"

      case "BL_2_2_2":
        return "Durchführung einer Kostenbewertung der Lösungsansätze"

      case "BL_2_3_1":
        return "Beachten der Anforderungen des Zertifizierungssystems"

      case "BL_2_4_1":
        return "Durchführung des gewählten Zertifizierungssystems\n"

      case "BL_2_6_1":
        return "Klärung des Kapitalbedarfs"

      case "BL_2_6_2":
        return "Auflistung der Eigenmittel"

      case "BL_2_6_3":
        return "Auflistung des Fremdkapitals"

      case "BL_2_6_4":
        return "Erstellung der Mittelabflussplanung"

      case "BL_2_7_1":
        return "Beratung zur Kredit- und Fördermittelbeschaffung"

      case "BL_2_7_2":
        return "Erarbeitung von zusätzlichen Unterlagen"

      case "BL_2_8_1":
        return "Durchführung von Wirtschaftlichkeitsuntersuchungen"

      case "BL_2_9_1":
        return "Diskussion über die Voranfrage"

      case "BL_2_9_2":
        return "Zusammenstellen der Unterlagen"

      case "BL_2_9_3":
        return "Einreichen der Bauvoranfrage"

      case "BL_3_1_1":
        return "Analyse der  verschiedener Lösungsansätze"

      case "BL_3_1_2":
        return "Durchführung einer Kostenbewertung der Lösungsansätze"

      case "BL_3_2_1":
        return "Durchführung einer Wirtschaftlichkeitsberechnung"

      case "BL_3_3_1":
        return "Ermittlung der Mengen und Massen"

      case "BL_3_3_3":
        return "Berechnung der Kosten"

      case "BL_3_4_1":
        return "Fortschreiben von Raumbüchern"

      case "BL_3_ENERGIEAUSWEIS":
        return ""

      case "BL_4_1_1":
        return "Identifikation von nowendigene Ausnahmen und Befreiungen von bestehenden baurechtlichen Bestimmungen, die die Nachbarn betreffen"

      case "BL_4_1_2":
        return "Beschaffung der erforderlichen Zustimmung der Nachbaschaft"

      case "BL_4_2_1":
        return "Identifikation von technischen, konstruktiven und bauphysikalischen Baustoffen oder Bauteilen, die keine amtliche Zulassung haben"

      case "BL_4_2_2":
        return "Erarbeitung und Zusammenstellung der Unterlagen und Nachweise zur amtlichen Zulassung der Baustoffe und Bauteile für ein besonderes Prüfverfahren"

      case "BL_4_2_3":
        return "Erstellung und Einreichung des Antrags einer amtlichen Zulassung von Baustoffen und Bauteilen für das Prüfverfahren"

      case "BL_4_3_1":
        return "Aufarbeitung und Bereitstellung der baufachliche Sachverhalte für das Verfahren"

      case "BL_4_3_2":
        return "Bauherrn im Verfahren und bei schriftlichen Anträgen vorbereiten und beraten"

      case "BL_5_1_1":
        return "Lage und Standort"

      case "BL_5_1_2":
        return "Baugrundstück"

      case "BL_5_1_3":
        return "Erschließung"

      case "BL_5_1_4":
        return "Baukonstruktionen"

      case "BL_5_1_5":
        return "Technische Anlagen"

      case "BL_5_1_6":
        return "Brandschutz"

      case "BL_5_1_7":
        return "Außenanlagen"

      case "BL_5_1_8":
        return "Wirtschaftlichkeit"

      case "BL_5_1_9":
        return "Allgemeine Angaben"

      case "BL_5_3_1":
        return "Überprüfung der Basisangaben"

      case "BL_5_3_2":
        return "Fortschreiben der Raumbücher"

      case "BL_5_4_1":
        return "Definition der Ziele, Standards bzw. Normen"

      case "BL_5_4_2":
        return "Identifikation und Strukturierung"

      case "BL_5_4_3":
        return "Kennzeichnungsschema"

      case "CLASSIFICATION_1":
        return ""

      case "CLASSIFICATION_2":
        return ""

      case "CLASSIFICATION_3":
        return ""

      case "CLASSIFICATION_4":
        return ""

      case "CLASSIFICATION_5":
        return ""

      case "CLASSIFICATION_6":
        return ""

      case "CLASSIFICATION_7":
        return ""

      case "GL_1_A_1":
        return "Planungs- und Projektziele"

      case "GL_1_A_2":
        return "Planungsgrundlagen"

      case "GL_1_B_1":
        return "Ortbesichtigung"

      case "GL_1_C_1":
        return "Notwendigkeit von besonderen Leistungen"

      case "GL_1_C_2":
        return "Bauaufgabe und Art der Vergabe"

      case "GL_1_C_3":
        return "Baugrundtauglichkeit"

      case "GL_1_D_1":
        return "Auswahlmechanismen zur Auffindung der erforderlichen fachlichen Beteiligten"

      case "GL_1_D_2":
        return "Vertragsformen"

      case "GL_1_E_1":
        return "Zusammenfassen der Ergebnisse"

      case "GL_2_A_1":
        return "Klären der Ausstattungsanforderung"

      case "GL_2_A_2":
        return "Identifizieren der erforderlichen Fachplaner*innen"

      case "GL_2_A_3":
        return "Abstimmung der Leistungen der Fachplaner*innen"

      case "GL_2_B_1":
        return "Erörterung der Bauziele"

      case "GL_2_B_2":
        return "Identifizierung der potenziellen Zielkonflikte"

      case "GL_2_B_3":
        return "Beratung des Bauherrn"

      case "GL_2_C_1":
        return "Erstellung der Vorplanung"

      case "GL_2_C_2":
        return "Erstellung eines Plankopfs"

      case "GL_2_D_1":
        return "Klärung des Nutzungszwecks"

      case "GL_2_D_2":
        return "Klärung der Gestaltungsziele"

      case "GL_2_D_3":
        return "Klärung der Funktionalität"

      case "GL_2_D_4":
        return "Klärung der bautechnischen Anforderungen"

      case "GL_2_D_5":
        return "Klärung der Budgetziele"

      case "GL_2_D_6":
        return "Klärung der Bauzeitvorstellungen"

      case "GL_2_E_1":
        return "Aufbereitung der Ergebnisse der Vorplanung"

      case "GL_2_E_2":
        return "Koordination der Fachplaner*innen"

      case "GL_2_E_3":
        return "Integration der Fachplanung in die Vorplanung"

      case "GL_2_F_1":
        return "Prüfung der Genehmigungsfähigkeit aufgrund des Bebauungsplans"

      case "GL_2_F_2":
        return "Prüfung der Genehmigungsfähigkeit"

      case "GL_2_F_3":
        return "Zustimmung des Nachbarn"

      case "GL_2_F_4":
        return "Klären des Vorantrags auf Befreiung des Bebauungsplans"

      case "GL_2_G_1":
        return "Ermittlung der Flächen und Volumen"

      case "GL_2_G_3":
        return "Berechnung der Kosten"

      case "GL_2_H_2":
        return "Unterteilung in die wesentlichen Vorgänge"

      case "GL_2_H_3":
        return "Zeitangaben zu den Vorgängen"

      case "GL_2_I1_2":
        return "Zusammenfassen der Ergebnisse"

      case "GL_2_I1_3":
        return "Zusammenfassen der Ergebnisse"

      case "GL_2_I2_2":
        return "Zusammenfassen der Ergebnisse"

      case "GL_2_I2_3":
        return "Zusammenfassen der Ergebnisse"

      case "GL_2_I2_4":
        return "Zusammenfassen der Ergebnisse"

      case "GL_3_A_1_1":
        return "Grundriss - Planinhalt"

      case "GL_3_A_1_2":
        return "Grundriss - Plankopf"

      case "GL_3_A_2_1":
        return "Schnitt - Planinhalt"

      case "GL_3_A_2_2":
        return "Schnitt - Plankopf"

      case "GL_3_A_3_1":
        return "Ansicht - Planinhalt"

      case "GL_3_A_3_2":
        return "Ansicht - Plankopf"

      case "GL_3_A_a":
        return ""

      case "GL_3_B_1":
        return "Bereitstellung der Arbeitsergebnisse"

      case "GL_3_B_2":
        return "Koordination und Integration der Fachplanung"

      case "GL_3_C_1":
        return "Lage und Standort"

      case "GL_3_C_2":
        return "Baugrundstück"

      case "GL_3_C_3":
        return "Erschließung"

      case "GL_3_C_4":
        return "Baukonstruktionen"

      case "GL_3_C_5":
        return "Technischen Anlagen"

      case "GL_3_C_6":
        return "Brandschutz"

      case "GL_3_C_7":
        return "Außenanlagen"

      case "GL_3_C_8":
        return "Wirtschaftlichkeit"

      case "GL_3_D_1":
        return "Prüfung des Bebauungsplans"

      case "GL_3_D_2":
        return "Antrag auf Befreiung"

      case "GL_3_D_3":
        return "Genehmigung ohne Bebauungsplan"

      case "GL_3_D_4":
        return "Genehmigungsfragen zum Brandschutz"

      case "GL_3_D_5":
        return "Prüfung weiterer Genehmigungstatbestände"

      case "GL_3_D_6":
        return "Nachbarliche Zustimmung"

      case "GL_3_E_1":
        return "Mengenermittlung nach DIN 276"

      case "GL_3_E_3":
        return "Kostenberechnung nach DIN 276"

      case "GL_3_F_3":
        return "Anpassung des Terminplans entsprechend der aktuellen Anforderungen"

      case "GL_3_F_2":
        return "Abstimmung des Grobterminplans mit den fachlich Beteiligten"

      case "GL_3_G_1":
        return "Zusammenfassen, Erläutern und Dokumentieren der Ergebnisse"

      case "GL_3_G_2":
        return "Übergabe der Entwurfsplanung als Basis für die weitere Planungsvertiefung an den Bauherren"

      case "GL_4_AABA_else_1":
        return "Antrag auf Ausnahme, Befreiung und Abweichung"

      case "GL_4_Abstandsflaechen_HB_HE_SL_1":
        return "Abstandsflächen"

      case "GL_4_Abstandsflaechenuebernahme_BY_1":
        return "Abstandsflächenübernahme"

      case "GL_4_amtliche_Basiskarte_NW_1":
        return "Grundstück"

      case "GL_4_amtliche_Basiskarte_NW_3":
        return "Maßstab"

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_1":
        return "Projektvorhaben und Projektbeteiligte"

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_2":
        return "Gebäudenutzung"

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_3":
        return "Maßstab"

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_4":
        return "Flurstück"

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_5":
        return "Grundstück"

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_6":
        return "Stellplätze"

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_7":
        return "Höhenlagen"

      case "GL_4_Angaben_Beherbergungsbetten_NW_1":
        return "Beherbergungsbetten"

      case "GL_4_Angaben_Beherbergungsstaette_ohne_Brandschutzkonzept_NW_1":
        return "Beherbergungsbetten"

      case "GL_4_Angaben_CO_Warnanlage_NW_1":
        return "CO Warnanlage"

      case "GL_4_Angaben_zu_gewerblichen_Anlagen_BW_1":
        return "Gewerblichen Anlage"

      case "GL_4_Angaben_zum_Betriebsraum_NW_1":
        return "Betriebsraum"

      case "GL_4_Angaben_zum_Krankenhaus_NW_1":
        return "Krankenhaus"

      case "GL_4_Anlagen_Gebuehren_HH_1":
        return "Gebühren"

      case "GL_4_anrechenbare_Kosten_MV_ST_SH_1":
        return "anrechenbare Kosten"

      case "GL_4_anrechenbarer_Bauwert_HB_1":
        return "anrechenbarer Bauwert"

      case "GL_4_Ansicht_BW_NW_1":
        return "Maßstab"

      case "GL_4_Ansicht_BW_NW_2":
        return "Höhenlagen"

      case "GL_4_Ansicht_BW_NW_3":
        return "Fenster und Türen"

      case "GL_4_Ansicht_BW_NW_4":
        return "Gelände"

      case "GL_4_Ansicht_else_1":
        return "Maßstab"

      case "GL_4_Ansicht_else_2":
        return "Höhenlagen"

      case "GL_4_Ansicht_else_3":
        return "Fenster und Türen"

      case "GL_4_Ansicht_else_4":
        return "Material"

      case "GL_4_Ansicht_else_5":
        return "Gelände"

      case "GL_4_Ansicht_SL_1":
        return "Maßstab"

      case "GL_4_Ansicht_SL_2":
        return "Höhenmaße"

      case "GL_4_Ansicht_SL_3":
        return "Fenster und Türen"

      case "GL_4_Ansicht_SL_4":
        return "Dach"

      case "GL_4_Ansicht_SL_5":
        return "Gelände"

      case "GL_4_Antrag_auf_Abloesung_notwendiger_Einstellplaetze_NI_1":
        return "Ablösung notwendiger Einstellplätze"

      case "GL_4_Anzahl_Fahrradstellplaetze_RP_1":
        return "Fahrradstellplätze"

      case "GL_4_ArbeitsstaettenVO_RP_1":
        return "Arbeitsstättenverordnung"

      case "GL_4_Art_der_baulichen_Nutzung_else_1":
        return "Art der baulichen Nutztung"

      case "GL_4_Artenschutz_else_1":
        return "Artenschutz"

      case "GL_4_Aussenanlagenplan_BE_1":
        return "Außenanlagenplan"

      case "GL_4_Auszug_Bebauungsplan_HB_1":
        return "Maßstab"

      case "GL_4_Auszug_Bebauungsplan_HB_2":
        return "Legende"

      case "GL_4_Auszug_Bebauungsplan_HB_3":
        return "Umgebung des Grundstücks"

      case "GL_4_Auszug_Bebauungsplan_SL_1":
        return "Maßstab"

      case "GL_4_Auszug_Bebauungsplan_SL_2":
        return "Planzeichenerklärung"

      case "GL_4_Auszug_Bebauungsplan_SL_3":
        return "Planzeichnung"

      case "GL_4_Auszug_Bebauungsplan_SL_4":
        return "Festsetzungen"

      case "GL_4_Auszug_Bebauungsplan_SL_5":
        return "Gesetzliche Grundlage"

      case "GL_4_AVK_BW_NW_1":
        return "Allgemeine Angaben"

      case "GL_4_AVK_BW_NW_2":
        return "Verwertungskonzept Bodenaushub"

      case "GL_4_AVK_BW_NW_3":
        return "Verwertungskonzept Abbruch"

      case "GL_4_Bauartgenehmigung_else_1":
        return "Bauartgenehmigung"

      case "GL_4_Baubeschreibung_else_1":
        return "Lage und Standort"

      case "GL_4_Baubeschreibung_else_2":
        return "Baugrundstück"

      case "GL_4_Baubeschreibung_else_3":
        return "Erschließung"

      case "GL_4_Baubeschreibung_else_4":
        return "Baukonstruktion"

      case "GL_4_Baubeschreibung_else_5":
        return "Technische Anlagen"

      case "GL_4_Baubeschreibung_else_6":
        return "Brandschutz"

      case "GL_4_Baubeschreibung_else_7":
        return "Außenanlagen"

      case "GL_4_Baubeschreibung_else_8":
        return "Wirtschaftlichkeit"

      case "GL_4_Baubeschreibung_Fluessiggas_1_RP_1":
        return "Baubeschreibung für Flüssiggas"

      case "GL_4_Baubeschreibung_Fluessiggas_3_RP_1":
        return "Baubeschreibung für Flüssiggas"

      case "GL_4_Baubeschreibung_Heizoel_1_RP_1":
        return "Baubeschreibung für Heizöl"

      case "GL_4_Baubeschreibung_Heizoel_1_RP_2":
        return "Verwendbarkeitsnachweise"

      case "GL_4_Baubeschreibung_Heizoel_3_RP_1":
        return "Anlagen zur Baubeschreibung von Heizöl"

      case "GL_4_Baukosten_HB_1":
        return "Baukosten"

      case "GL_4_Baukostenwert_HB_1":
        return "Baukostenwert"

      case "GL_4_Baumbestandsbescheinigung_1_HB_1":
        return "Baumbestandsbescheinigung"

      case "GL_4_Baumbestandsbescheinigung_2_HB_1":
        return "Baumbestandsbescheinigung"

      case "GL_4_Baumbestandsbescheinigung_HH_1":
        return "Baumbestandsbescheinigung"

      case "GL_4_Baunebenrecht_HB_1":
        return "Baunebenrecht"

      case "GL_4_Bauvorlageberechtigung_else_1":
        return "Bauvorlageberechtigung"

      case "GL_4_Bauwert_HB_1":
        return "Bauwert"

      case "GL_4_beabsichtigte_Nutzung_ST_1":
        return "beabsichtigte Nutzung"

      case "GL_4_Benennung_Bauleitender_BW_SH_1":
        return "Benennung Bauleitender"

      case "GL_4_Berechnung_Verkaufsstaette_NW_1":
        return "Berechnung Verkaufsstätte"

      case "GL_4_Bericht_ueber_den_geprueften_Brandschutznachweis_BE_1":
        return "Bericht über den geprüften Brandschutznachweis"

      case "GL_4_Bericht_ueber_den_geprueften_Standsicherheitnachweis_BE_1":
        return "Bericht über den geprüften Standsicherheitsnachweis"

      case "GL_4_Bescheinigung_nach_67_Abs_4_LBO_SL_1":
        return "Bescheinigung nach 67 Abs. 4 LBO"

      case "GL_4_Beschreibung_barrierefreie_Wohnungen_BE_1":
        return "Beschreibung barrierefreie Wohnungen"

      case "GL_4_Beschreibung_Baugrundstueck_SL_1":
        return "Beschreibung Baugrundstück"

      case "GL_4_Beschreibung_baulicher_Anlagen_SL_1":
        return "Beschreibung der baulichen Anlage"

      case "GL_4_Beschreibung_der_Baumassnahmen_geringen_Umfangs_SL_1":
        return "Beschreibung der Baumaßnahme geringen Umfangs"

      case "GL_4_Bestuhlungswegplan_und_Rettungswegplan_NW_1":
        return "Bestuhlungswegplan und Rettungswegplan"

      case "GL_4_Betriebsbeschreibung_else_1":
        return "Belegschaft"

      case "GL_4_Betriebsbeschreibung_else_2":
        return "Produktionsapparat"

      case "GL_4_Betriebsbeschreibung_else_3":
        return "Personals"

      case "GL_4_Betriebsbeschreibung_else_4":
        return "Arbeitsdurchführung"

      case "GL_4_Betriebsbeschreibung_else_5":
        return "Arbeitszeit"

      case "GL_4_Betriebsbeschreibung_else_6":
        return "Unterlieferer"

      case "GL_4_BFK_HB_HE_NW_1":
        return "Erschließung"

      case "GL_4_BFK_HB_HE_NW_2":
        return "Stellplätze"

      case "GL_4_BFK_HB_HE_NW_3":
        return "Bedienelemente"

      case "GL_4_BFK_HB_HE_NW_4":
        return "Sanitärräume"

      case "GL_4_BFK_HB_HE_NW_5":
        return "Bewegungsflächen"

      case "GL_4_BFK_HB_HE_NW_6":
        return "Orientierung"

      case "GL_4_Bodenschutzkonzept_BW_1":
        return "Bodenschutzkonzept"

      case "GL_4_Brandschutzkonzept_BY_RP_ST_1":
        return "Brandschutzkonzept"

      case "GL_4_Brandschutzkonzept_Sonderbauten_else_1":
        return "Brandschutzkonzept Sonderbauten"

      case "GL_4_Brandschutznachweis_else_1":
        return "Brandschutznachweis"

      case "GL_4_Brandschutznachweis_in_Bauvorlagen_SL_1":
        return "Brandschutznachweis"

      case "GL_4_BRI_umbauter_Raum_HB_MV_NW_RP_SH_SL_ST_TH_1":
        return "Brutto-Rauminhalt"

      case "GL_4_Darstellung_Zufahrtsverhaeltnisse_ST_1":
        return "Darstellung Zufahrtsverhältnisse"

      case "GL_4_denkmalschutzrechtliche_Erlaubnis_BY_1":
        return "Antrag auf denkmalschutzrechtliche Erlaubnis"

      case "GL_4_Einfuegungsnachweis_HE_1":
        return "Einfügungsnachweis"

      case "GL_4_Energieausweis_else_1":
        return "Energieausweis"

      case "GL_4_ergaenzende_Baubeschreibung_MV_1":
        return "ergänzende Baubeschreibung"

      case "GL_4_Ergaenzungsplan_HB_1":
        return "Ergänzungsplan"

      case "GL_4_Erklaerung_der_Nachbarschaft_SL_1":
        return "Erklärung der Nachbarschaft"

      case "GL_4_Erklaerung_zum_barrierefreien_Bauen_SL_1":
        return "Erklärung zum barrierefreien Bauen"

      case "GL_4_Erläuterung_gegen_nachbarliche_Zustimmung_RP":
        return "nachbarliche Zustimmung"

      case "GL_4_erneuerbare_Energien_BB_1":
        return "erneuerbare Energien"

      case "GL_4_Erschliessungssicherung_BB_BE_BY_HB_HH_MV_NI_RP_SH_ST_TH_1":
        return "Erschließungssicherung"

      case "GL_4_Erschuetterungsnachweis_BE_BY_SN_SH_1":
        return "Erschütterungsnachweis"

      case "GL_4_erteilte_Bescheide_BE_HB_1":
        return "erteilte Bescheide"

      case "GL_4_Feuerungsanlagen_BW_RP_SL_1":
        return "Feuerungsanlagen"

      case "GL_4_Flaechen_RP_1":
        return "Flächenermittlung"

      case "GL_4_Foerderungsnachweis_SH_1":
        return "Förderungsnachweis"

      case "GL_4_Freiflaechengestaltungsplan_SL_1":
        return "Freiflächengestaltungsplan"

      case "GL_4_Freiflaechenplan_HB_HE_1":
        return "Bestandsplanung"

      case "GL_4_Freiflaechenplan_HB_HE_2":
        return "Ausgleichsplanung"

      case "GL_4_Freiflaechenplan_HB_HE_3":
        return "Freiflächenplanung"

      case "GL_4_Gebaeudeklasse_Hoehe_BB_BW_HB_HH_MV_NI_SH":
        return "Gebäudeklasse"

      case "GL_4_Geschossberechnung_HB_1":
        return "Geschossberechnung"

      case "GL_4_Grundbuchauszug_BE_HB_1":
        return "Grundbuchauszug"

      case "GL_4_Grundriss_BW_1":
        return "Plankopf"

      case "GL_4_Grundriss_BW_2":
        return "Raumnutzung"

      case "GL_4_Grundriss_BW_3":
        return "Bauteile und Brandschutzanforderungen"

      case "GL_4_Grundriss_else_1":
        return "Plankopf"

      case "GL_4_Grundriss_else_2":
        return "Raumnutzung"

      case "GL_4_Grundriss_else_3":
        return "Bauteile und Brandschutzanforderungen"

      case "GL_4_Grundriss_HE_1":
        return "Plankopf"

      case "GL_4_Grundriss_HE_2":
        return "Räume und Bauteile"

      case "GL_4_Grundriss_NI_1":
        return "Plankopf"

      case "GL_4_Grundriss_NI_2":
        return "Raumnutzung"

      case "GL_4_Grundriss_NI_3":
        return "Bauteile und Brandschutzanforderungen"

      case "GL_4_Grundriss_NW_1":
        return "Plankopf"

      case "GL_4_Grundriss_NW_2":
        return "Raumnutzung"

      case "GL_4_Grundriss_NW_3":
        return "Bauteile und Brandschutzanforderungen"

      case "GL_4_Grundriss_RP_1":
        return "Plankopf"

      case "GL_4_Grundriss_RP_2":
        return "Raumnutzung"

      case "GL_4_Grundriss_RP_3":
        return "Bauteilen und Brandschutzanforderungen"

      case "GL_4_Grundriss_SL_1":
        return "Plankopf"

      case "GL_4_Grundriss_SL_2":
        return "Raumnutzung"

      case "GL_4_Grundriss_SL_3":
        return "Bauteilen und Brandschutzanforderungen"

      case "GL_4_Grundriss_SN_ST_1":
        return "Plankopf"

      case "GL_4_Grundriss_SN_ST_2":
        return "Raumnutzung"

      case "GL_4_Grundriss_SN_ST_3":
        return "Bauteilen und Brandschutzanforderungen"

      case "GL_4_Grundstuecksentwaesserung_1_BW_RP_SH_SL_1":
        return "Entwässerungsplan"

      case "GL_4_Grundstuecksentwaesserung_2_BW_RP_SH_SL_1":
        return "Entwässerungskonzept"

      case "GL_4_Herstellungskosten_BE_NI_NW_RP_ST_1":
        return "Herstellungskosten"

      case "GL_4_Hochwasserrisiko_RP_1":
        return "Hochwasserrisiko"

      case "GL_4_Hygienegutachten_HE_1":
        return "Hygienegutachten"

      case "GL_4_Kriterienkatalog_BY_BE_BB_HB_MV_NW_SL_ST_SH_TH_1":
        return "Kriterienkatalog"

      case "GL_4_Laermschutzkonzept_HE_1":
        return "Lärmschutzkonzept"

      case "GL_4_Lageplan_else_1":
        return "Maßstab"

      case "GL_4_Lageplan_else_2":
        return "Projekt und Projektbeteiligte"

      case "GL_4_Lageplan_else_3":
        return "Gebäudenutzung"

      case "GL_4_Lageplan_else_4":
        return "Grundstück"

      case "GL_4_Lageplan_else_5":
        return "Stellplätze"

      case "GL_4_Lageplan_else_6":
        return "Höhenlagen"

      case "GL_4_Lageplan_else_7":
        return "Grundstücksgrenzen und Abstandsflächen"

      case "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH_1":
        return "Grundstück und Lage"

      case "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH_3":
        return "Maßstab"

      case "GL_4_Lueftungsanlagen_Feuerungsanlagen_HE":
        return "Lüftungsanlagen"

      case "GL_4_Mass_der_baulichen_Nutzung_else_1":
        return "Maß der baulichen Nutzung"

      case "GL_4_Mobilitaetsbedarf_HB_1":
        return "Bedarf der Mobilität"

      case "GL_4_Nachbarliche_Zustimmung_BY_HB_RP_SN_TH_1":
        return "nachbarliche Zustimmung"

      case "GL_4_Nachweis_barrierefreie_Stellplaetze_BE_1":
        return "barrierefreie Stellplätze"

      case "GL_4_Nachweis_Stellplaetze_fuer_Fahrraeder_BE_1":
        return "Stellplätze für Fahrräder"

      case "GL_4_oeffentlich_rechtliche_Vorschriften_HB_HH":
        return "öffentlich rechtliche Vorschriften"

      case "GL_4_oeffentliche_Bekanntmachung_BY_1":
        return "öffentliche Bekanntmachung"

      case "GL_4_Oeffentlichkeitsbeteiligung_BY_1":
        return "Öffentlichkeitsbeteiligung"

      case "GL_4_oertliche_Bauvorschrift_BW_BY_BB_HB_HE_MV_NI_NW_RP_SL_SN_ST_SH_TH_1":
        return "örtliche Bauvorschrift"

      case "GL_4_Perspektiven_BE_1":
        return "Perspektive"

      case "GL_4_Pruefverzicht_Brandschutznachweis_HB_1":
        return "Prüfverzicht Brandschutznachweis"

      case "GL_4_Pruefverzicht_Standsicherheitsnachweis_HB_1":
        return "Prüfverzicht Standsicherheitsnachweis"

      case "GL_4_qualifizierter_Lageplan_HB_1":
        return "Maßstab"

      case "GL_4_qualifizierter_Lageplan_HB_2":
        return "Baugrundstück"

      case "GL_4_qualifizierter_Lageplan_HB_3":
        return "Grundstücksgrenze und Abstandsflächen"

      case "GL_4_qualifizierter_Lageplan_HB_4":
        return "Höhenbezüge"

      case "GL_4_qualifizierter_Lageplan_HB_5":
        return "Baumbestand"

      case "GL_4_qualifizierter_Lageplan_NI_1":
        return "Maßstab"

      case "GL_4_qualifizierter_Lageplan_NI_2":
        return "Baugrundstück"

      case "GL_4_qualifizierter_Lageplan_NI_3":
        return "Grundstücksgrenze und Abstandsflächen"

      case "GL_4_qualifizierter_Lageplan_NI_4":
        return "Projektbeteiligte"

      case "GL_4_Rohbaukosten_NI_NW_1":
        return "Rohbaukosten"

      case "GL_4_Sachverstaendigenbescheinigung_Brandschutz_BE_BY_MV_NW_RP_SH_1":
        return "Sachverständigenbescheinigung Brandschutz"

      case "GL_4_Sachverstaendigenbescheinigung_Schallschutz_NW_1":
        return "Sachverständigenbescheinigung Schallschutz"

      case "GL_4_Sachverstaendigenbescheinigung_Waermeschutz_NW_1":
        return "Sachverständigennachweis Wärmeschutz"

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_SL_ST":
        return "Sachverständigennachweis Standsicherheit"

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_else":
        return "Sachverständigennachweis Standsicherheit"

      case "GL_4_Schallschutznachweis_else_1":
        return "Schallschutznachweis"

      case "GL_4_Schalltechnische_Untersuchung_else_1":
        return "Schalltechnische Untersuchung"

      case "GL_4_Schnitt_BB_1":
        return "Maßstab"

      case "GL_4_Schnitt_BB_2":
        return "Höhen"

      case "GL_4_Schnitt_BB_3":
        return "Treppen und Rampen"

      case "GL_4_Schnitt_BB_4":
        return "Gelände"

      case "GL_4_Schnitt_BB_5":
        return "Grundwasser"

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_1":
        return "Maßstab"

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_2":
        return "Höhen"

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_3":
        return "Treppen und Rampen"

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_4":
        return "Gelände"

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_1":
        return "Maßstab"

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_2":
        return "Höhen"

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_3":
        return "Treppen und Rampen"

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_4":
        return "Gelände"

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_5":
        return "Gebäudehöhen"

      case "GL_4_Schnitt_NW_1":
        return "Maßstab"

      case "GL_4_Schnitt_NW_2":
        return "Höhen"

      case "GL_4_Schnitt_NW_3":
        return "Gelände"

      case "GL_4_Schnitt_NW_4":
        return "Gebäudehöhen"

      case "GL_4_Spielplatznachweis_else_1":
        return "Spielplatznachweis"

      case "GL_4_Standsicherheitsnachweis_BW_1":
        return "Standsicherheitsnachweis"

      case "GL_4_Standsicherheitsnachweis_else_1":
        return "Standsicherheitsnachweis"

      case "GL_4_Statistik_else_1":
        return "Statistischer Erhebungsbogen"

      case "GL_4_Stellplatznachweis_else_1":
        return "Stellplatznachweis"

      case "GL_4_Stellungnahme_BE_1":
        return "Stellungsnahme zum Bauvorhaben"

      case "GL_4_Stellungnahme_Bodenschutzbehoerde_HB_1":
        return "Stellungnahme der Bodenschutzbehörde"

      case "GL_4_Stoerfallbetrieb_RP_1":
        return "Angaben zum Betriebsbereich"

      case "GL_4_Stoerfallbetrieb_RP_2":
        return "Anlagen zum Betriebsbereich"

      case "GL_4_Stoerfallbetrieb_RP_3":
        return "Angemessener Sicherheitsabstand"

      case "GL_4_Uebereinstimmungserklaerung_BE_1":
        return "Übereinstimmungserklärung"

      case "GL_4_Uebersichtsplan_HE_RP_SH_1":
        return "Maßstab"

      case "GL_4_Uebersichtsplan_HE_RP_SH_2":
        return "Grundstücksbezeichnung und Flurstücknummer"

      case "GL_4_Unbedenklichkeitsbescheinigung_Feuerwehr_HB_1":
        return "Unbedenktlichkeitsbescheinigung Feuerwehr"

      case "GL_4_Unterlagen_ueber_Brennstofflagerung_NI_1":
        return "Brennstofflagerung"

      case "GL_4_UVP_else_1":
        return "Allgemeine Angaben"

      case "GL_4_UVP_else_2":
        return "Beschreibung der Umwelt"

      case "GL_4_UVP_else_3":
        return "Merkmale und Maßnahmen des Standorts"

      case "GL_4_UVP_else_4":
        return "Beschreibung der Umweltauswirkung"

      case "GL_4_UVP_else_5":
        return "Schutzgütern und Materialien"

      case "GL_4_Vereinfachtes_Abfallverwertungskonzept_BW_1":
        return "Abfallverwertungskonzept"

      case "GL_4_Vergleichsberechnung_MV_1":
        return "Vergleichsrechnung"

      case "GL_4_Vertretervollmacht_BB_BE_HE_MV_NW_SN_ST_1":
        return "Vertretungsvollmacht"

      case "GL_4_Waermeschutznachweis_else_1":
        return "Gesamtenergiebedarf"

      case "GL_4_Waermeschutznachweis_else_2":
        return "Sommerlicher Wärmeschutz"

      case "GL_4_Waermeschutznachweis_else_3":
        return "Bauteilnachweis"

      case "GL_4_wassergefaehrdende_Stoffe_else_1":
        return "Wassergefährdende Stoffe"

      case "GL_5_A_1_1":
        return "Ausführungsplanung zum Rohbau"

      case "GL_5_A_2_1":
        return "Ausführungsplanung zum Ausbau"

      case "GL_5_A_4_1":
        return "Ausführungsplanung mit Angaben zum Material"

      case "GL_5_B_1_1":
        return "Grundriss"

      case "GL_5_B_2_1":
        return "Schnitt"

      case "GL_5_B_3_1":
        return "Detailzeichnung"

      case "GL_5_B_4_1":
        return "Aufbauten"

      case "GL_5_B_5_1":
        return "Ansicht"

      case "GL_5_B_6_1":
        return "Gründung"

      case "GL_5_C_1":
        return "Koordination der TGA Fachplanung"

      case "GL_5_C_2":
        return "Koordination der Tragwerksplanung"

      case "GL_5_C_3":
        return "Koordination der Planung bezüglich Wand- und Deckendurchbrüchen"

      case "GL_5_D_1":
        return "Unterteilung in die wesentlichen Vorgänge"

      case "GL_5_D_2":
        return "Zeitangaben zu den Vorgängen"


      default:
        return value
    }

  }
}

@Pipe({
  name: 'description'
})
export class DescriptionPipe implements PipeTransform {
  /**
   * Transform Enum Value to String
   * @param value PartialServicesEnum Value
   */
  transform(value: string): string {
    switch (value) {
      //LPH 1
      case "GL_1_A_1":
        return "Erfassung und Klärung sämtlicher Planungs- und Projektziele, einschließlich Angaben zum Objekttyp und den planerischen Anforderungen auf technischer, gestalterischer und funktionaler Ebene, sowie Festlegung der Ziele bezüglich Zeitrahmen, Wirtschaftlichkeit und Umweltverträglichkeit."

      case "GL_1_A_2":
        return "Erfassung und Klärung sämtlicher Planungsgrundlagen  des Projekts, darunter baurechtliche Grundlagen, die aus dem Planungsrecht, dem Bebauungsplan und dem Grundbuch ersichtlich sind. Ebenso werden Rahmenbedingungen des Standorts erfasst, wie die geografische Lage, die städtische Umgebung und die Eigenschaften des Grundstücks."

      case "GL_1_B_1":
        return "Begehung und Besichtigung des Projektgrundstücks sowie seiner unmittelbaren Umgebung, einschließlich Dokumentation der Ort- und Grundstücksbesichtigung."

      case "GL_1_C_1":
        return "Beratung bezüglich der Notwendigkeit besonderer Leistungen für das Projekt. Dies beinhaltet Leistungen zum Thema Kosten, Termine, Risiken, Ziele und Grundstück."

      case "GL_1_C_2":
        return "Beratung zur Realisierung der Bauaufgabe sowie zur Art der Vergabe und Aufteilung in Lose, unter Einbeziehung sämtlicher Aspekte der Vergabe, Lose und Ausschreibung."

      case "GL_1_C_3":
        return "Beratung zur Eignung des Baugrunds, einschließlich der Analyse von Baugrundbelastungen wie Kampfmittel und Schadstoffe, sowie Empfehlungen zur Baugrundtauglichkeit und Nutzungsempfehlungen für das Bauland."

      case "GL_1_D_1":
        return "Beratung über Auswahlmechanismen zur Identifikation der erforderlichen fachlichen Beteiligten, einschließlich die Beratung und Empfehlung der fachlich Beteiligten."

      case "GL_1_D_2":
        return "Beratung über die Vertragsform, in der die fachlich Beteiligten in das Projekt eingebunden werden sollen."

      case "GL_1_E_1":
        return "Die Ergebnisse der Leistungsphase 1 zusammenfassen, erläutern und dokumentieren."

      case "BL_1_1_1":
        return "Klären des Projektkontextes und der Vorbereitung der Bedarfsplanung, unter Berücksichtigung der räumlichen, finanziellen und zeitlichen Rahmenbedingungen."

      case "BL_1_1_2":
        return "Das Aufstellen und Festhalten der Projektziele umfasst die Definition und Dokumentation der ökologischen, ökonomischen, zeitlichen, funktionalen, technischen, soziokulturellen und gestalterischen Ziele."

      case "BL_1_1_3":
        return "Das Erfassen der Bedarfsangaben des Auftraggebers beinhaltet sowohl quantitativen als auch qualitativen Angaben."

      case "BL_1_1_4":
        return "Erstellen eines Bedarfsplans und Abstimmen mit dem Bedarfsträger."

      case "BL_1_1_5":
        return "Untersuchen verschiedener Varianten der Bedarfsdeckung sowie der Gesamtwirtschaftlichkeit."

      case "BL_1_10_1":
        return "Die Planung der Belegschaft umfasst die Festlegung der Anzahl und Zusammensetzung der Arbeitskräfte nach produktiven und unproduktiven Tätigkeiten, nach Berufen sowie nach gelernten, angelernten, ungelernten, männlichen, weiblichen Lehrlingen und Mitarbeitern."

      case "BL_1_10_2":
        return "Die Planung des Produktionsapparates umfasst die Festlegung der Leistungsfähigkeit, die Möglichkeit der Ausweitung oder Einengung sowie die Verbesserung und Zusammensetzung aus Maschinen, Werkzeugen und Vorrichtungen nach Art und Größe."

      case "BL_1_10_3":
        return "Die Planung des Personals zur Leitung, Überwachung und zum Anlernen umfasst die Projektbeteiligten Ingenieure, Chemiker, Meister, Vorarbeiter und Verwaltungspersonal."

      case "BL_1_10_4":
        return "Die Planung der Arbeitsdurchführung umfasst die Festlegung der Art und Reihenfolge der Arbeitsgänge, die Größe der Serien, die Abwägung zwischen mehr oder weniger rationeller Durchführung, die Wahl der Verfahren, die Einrichtung von Taktstraßen sowie die Verteilung der Arbeit auf die einzelnen Maschinen und Arbeitsplätze."

      case "BL_1_10_5":
        return "Die Planung der Arbeitszeit umfasst Angaben zur Anzahl der Schichten und den Arbeitspausen."

      case "BL_1_10_6":
        return "Die Planung der Vergabe an Unterlieferanten beinhaltet Angaben zu den selbst gefertigten und durchgeführten Leistungen sowie zu den an andere vergebenen Leistungen."

      case "BL_1_11_1":
        return "Das Prüfen der Umwelterheblichkeit des vorgesehenen Objekts erfolgt anhand von lagebezogenen und funktionsbezogenen Faktoren, darunter Projekt-, Standort- und Auswirkungsbezogene Aspekte wie Voruntersuchungen für Natur, Landschaft, Erholung, Klima, Luft, Boden, Wasser, Lärm sowie umweltrelevante Infrastruktur."

      case "BL_1_12_1":
        return "Die Beschreibung des Vorhabens beinhaltet Angaben zum Standort, zur Art, zum Umfang, zur Ausgestaltung und  zur Größe sowie zu anderen wesentlichen Merkmalen des Vorhabens."

      case "BL_1_12_2":
        return "Beschreibung der Umwelt und ihrer Bestandteile im Einwirkungsbereich des Vorhabens."

      case "BL_1_12_3":
        return "Beschreibung der Merkmale des Vorhabens und des Standortes sowie der geplanten Maßnahmen zur Vermeidung, Verminderung oder Ausgleichung erheblicher nachteiliger Umweltauswirkungen des Vorhabens."

      case "BL_1_12_4":
        return "Beschreibung der zu erwartenden erheblichen Umweltauswirkungen des Vorhabens."

      case "BL_1_12_5":
        return "Beschreibung der vernünftigen Alternativen, die für das Vorhaben und seine spezifischen Merkmale relevant und vom Vorhabenträger geprüft worden sind sowie die Angabe der wesentlichen Gründe für die getroffene Wahl unter Berücksichtigung der jeweiligen Umweltauswirkungen."

      case "BL_1_12_6":
        return "Erstellung einer allgemein verständlichen, nichttechnischen Zusammenfassung des UVP-Berichts."

      case "BL_1_13_1":
        return "Klärung der Aufgabenstellung und Zielsetzung der Machbarkeitsstudie."

      case "BL_1_13_2":
        return "Zusammentragen und Beschreiben aller relevanten Randbedingungen und Informationen des Vorhabens."

      case "BL_1_13_3":
        return "Auswertung sämtlicher bisher gesammelten Randbedingungen und Informationen sowie Vergleich mit den Projektzielen hinsichtlich ihrer Realisierbarkeit und Machbarkeit des Vorhabens."

      case "BL_1_13_4":
        return "Bewertung über die Machbarkeit zur Entscheidung über das weitere Vorgehen und über die Zusammenfassung und Handlungsempfehlung."

      case "BL_1_14_1":
        return "Klärung der Funktionsabläufe und deren wirtschaftlichen Auswirkungen."

      case "BL_1_15_1":
        return "Erstellung eines Projektstrukturplans unter Berücksichtigung des Projektablaufs, der Projektaufgaben, des Projektinhalts, des Projektkontext und  der Projekttermine."

      case "BL_1_16_1":
        return "Zusammenstellen der grundlegenden Anforderungen des angestrebten DGNB Zertifizierungssystems."

      case "BL_1_16_2":
        return "Zusammenstellen der grundlegenden Anforderungen des angestrebten BNB Zertifizierungssystems."

      case "BL_1_16_3":
        return "Zusammenstellen der grundlegenden Anforderungen des angestrebten BREEAM Zertifizierungssystems."

      case "BL_1_16_4":
        return "Zusammenstellen der grundlegenden Anforderungen des angestrebten EU-Green-Building Zertifizierungssystems."

      case "BL_1_16_5":
        return "Zusammenstellen der grundlegenden Anforderungen des angestrebten CASBEE Zertifizierungssystems."

      case "BL_1_17_1":
        return "Definition der Leistungsinhalte der Fachbeteiligten und Gutachter."

      case "BL_1_17_2":
        return "Vorschlagen und Mitwirken bei der Beauftragung der Fachplaner und Sonderfachleute."

      case "BL_1_3_1":
        return "Das Darstellen der räumlich-funktionalen Anordnungen und Anforderungen beinhaltet beispielsweise die Visualisierung der Funktionsabläufe von Nutzungsbereichen."

      case "BL_1_3_2":
        return "Gliederung der Flächenbedürfnisse des Auftraggebers nach Nutzungsarten beziehungsweise Darstellung der verschiedenen Nutzungsbereiche."

      case "BL_1_4_1":
        return "Das Aufstellen eines Raumprogramms, Raumliste oder Raumbuchs umfasst die Angaben zu den Flächen und Nutzungen des gesamten Gebäudes sowie der einzelnen Räume, Bereich oder Einheiten."

      case "BL_1_5_1":
        return "Analyse der Mikro- und Makrostandortfaktoren bezogen auf die geografische Lage und  Grundstücksstruktur. Dies beinhaltet Angaben zum Grundstück, Gelände und Dienstbarkeiten sowie Katasterplan, Ver- und Entsorgung, Lageplan und Straßen."

      case "BL_1_5_2":
        return "Analyse der Mikro- und Makrostandortfaktoren bezogen auf die  Verkehrsstruktur und Infrastruktur. Dies beinhaltet Angaben zum Verkehrsnetz und -anbindung, Entfernungen und Zugänglichkeit, sowie Straßen, Stellplätze, ÖPNV und Häfen."

      case "BL_1_5_3":
        return "Analyse der Mikro- und Makrostandortfaktoren bezogen auf die Wirtschaftsstruktur, den Steuern und die Umfeldnutzung. Dies beinhaltet die wirtschaftliche Situation, Unternehmen, Beschäftigung, Marktfähigkeit, steuerliche Bedingungen, Umfeld und öffentliche Einrichtungen."

      case "BL_1_5_4":
        return "Analyse der Mikro- und Makrostandortfaktoren bezogen auf die soziodemografische Struktur. Dies beinhaltet Angaben zur Bevölkerungsverteilung, -zahl und -entwicklung sowie die Kaufkraft der Bevölkerung."

      case "BL_1_5_5":
        return "Analyse der Mikro- und Makrostandortfaktoren bezogen auf das Image & Investitionsklima. Dies beinhaltet Angaben zu der Standortattraktivität und -qualität sowie zu dem Investitionsklima bezüglich politischen, steuerlichen, wirtschaftlichen Themen."

      case "BL_1_7_1_1":
        return "Beschaffen und Zusammentragen der erforderlichen Unterlagen bezüglich des Grundbuchs zwecks der Einsicht und Auswertung der Dokumente."

      case "BL_1_7_2_1":
        return "Beschaffen und Zusammentragen der erforderlichen Unterlagen bezüglich des Baulastenverzeichnis der Bauaufsichtbehörde"

      case "BL_1_7_3_1":
        return "Beschaffen und Zusammentragen der erforderlichen Unterlagen bezüglich des Katasterplans oder amtlichen Lageplans zwecks der Einsicht der Dokumente."

      case "BL_1_7_4_1":
        return "Beschaffen und Zusammentragen der erforderlichen Unterlagen bezüglich der Bauunterlagen zwecks der Einsicht der Dokumente."

      case "BL_1_8_1":
        return "Das Zusammenstellen der erforderlichen vorhandenen Bestandsunterlagen und Pläne sowie gegebenenfalls das Erstellen neuer Bestandsunterlagen und Pläne, welche alle relevanten Informationen enthalten, einschließlich Angaben zum Grundstück, Vorbesitzern, Schäden und Besonderheiten."

      case "BL_1_8_2":
        return "Die Sichtprüfung und Untersuchung des Grundstücks durch Experten umfasst die Beurteilung, Untersuchung und Analyse des Baugrundstücks."

      case "BL_1_8_3":
        return "Dokumentation des Zustandes und gegebenenfalls der Schäden aller Ergebnisse der Bestandsaufnahme."

      case "BL_1_9_1":
        return "Die Durchführung einer technischen Untersuchung zur Bewertung der Qualität der Bausubstanz beinhaltet Angaben zur Konstruktion, zum Tragverhalten und zum Wärmeschutz."

      //LPH 2
      case "GL_2_A_1":
        return "Klärung der Ausstattungsanforderungen sowie des Projektbedarfs und der Projektziele, einschließlich der Entwicklung einer Funktions- und Bedarfsanalyse für das Projekt."

      case "GL_2_A_2":
        return "Identifizierung der erforderlichen Fachplaner*innen für die umfassende Projektplanung. Dies betrifft Architekt*innen, Tragwerkplaner*innen und Fachingenieur*innen sowie andere relevante Fachkräfte."

      case "GL_2_A_3":
        return "Abstimmung der Leistungen mit den Fachplaner*innen, was unter anderem die Bereitstellung von Nachweisen, Bemaßungen, Darstellungen und Dokumentationen der Architekt*innen, Tragwerkplaner*innen und Fachingenieur*innen sowie anderer beteiligter Fachkräfte umfasst."

      case "GL_2_B_1":
        return "Erörterung und Diskussion der Bauziele zwischen dem Architekten und dem Bauherrn, wobei alle Erkenntnisse aus den zuvor durchgeführten Teilleistungen als Grundlage berücksichtigt werden."

      case "GL_2_B_2":
        return "Identifizierung potenzieller Zielkonflikte während der Abstimmung und dem Austausch, gefolgt von der Überlegung möglicher Lösungsvorschläge."

      case "GL_2_B_3":
        return "Beratung des Bauherrn durch den Architekten hinsichtlich der Zielvorstellungen und Zielkonflikte mit anschließenden Entscheidungen des Bauherrn."

      case "GL_2_C_1":
        return "Erstellung von Grundrissen, Schnitten oder Ansichten als Ausarbeitung und Bewertung von Varianten zur Lösungsfindung, wobei die Zustimmung des Auftraggebers gemäß den festgelegten Grundlagen und Zielvorstellungen eingeholt wird."

      case "GL_2_C_2":
        return "Erstellung eines Plankopfs, der die wesentlichen und relevanten Projektinformationen auf den Plänen darstellt."

      case "GL_2_D_1":
        return "Klärung des Nutzungszwecks und der Nutzungsziele des Bauvorhabens unter Berücksichtigung der sozialen und öffentlich-rechtlichen Vorgaben und Bedingungen."

      case "GL_2_D_2":
        return "Klärung der Gestaltungsziele unter Berücksichtigung städtebaulicher und gestalterischer Vorgaben und Bedingungen."

      case "GL_2_D_3":
        return "Klärung der Größe und Funktionalität des Projekts, einschließlich Angaben zum Raumprogramm, den Flächen und dem Verhältnis der Nutzungsfläche zu Verkehrsfläche."

      case "GL_2_D_4":
        return "Klärung der bautechnischen Anforderungen, welche Angaben zum Energie-, Schall- und Umweltschutz umfassen, unter Berücksichtigung technischer, ökologischer, bauphysikalischer und energiewirtschaftlicher Eigenschaften."

      case "GL_2_D_5":
        return "Klärung der Budgetziele des Bauherrn, welche es ermöglichen, die Wirtschaftlichkeit während des Projekts zu überprüfen. Im Falle einer Budgetüberschreitung werden entsprechende Gegenmaßnahmen ergriffen, um das Budget wieder einzuhalten."

      case "GL_2_D_6":
        return "Klärung der Bauzeitvorstellungen des Bauherrn, um im Verlauf des Projekts den Fertigstellungstermin zu überwachen. Im Fall einer Terminüberschreitung werden entsprechende Gegenmaßnahmen ergriffen, um den Fertigstellungstermin wieder einzuhalten."

      case "GL_2_E_1":
        return "Aufbereitung und Bereitstellung sämtlicher Ergebnisse der Vorplanung, welche alle Planungsergebnisse des betreffenden Objekts oder Gebäudes umfassen."

      case "GL_2_E_2":
        return "Koordination der Fachplaner*innen umfasst auch die Abstimmung und Integration aller beteiligten Planer wie Architekt*innen, Objektplaner*innen und weitere relevante Planungspartner."

      case "GL_2_E_3":
        return "Reibungslose Integration der Fachplanung in die Vorplanung beinhaltet die gründliche Prüfung möglicher Schnittstellen, Konflikte und Kollisionen zwischen der Tragwerkplanung, der technischen Gebäudeausrüstung (TGA) und der Objektplanung, um eine konfliktfreie Planung sicherzustellen."

      case "GL_2_F_1":
        return "Prüfung, ob die Planungsergebnisse und Bauabsichten des geplanten Projekts mit den Festsetzungen des Bebauungsplans vereinbar sind. Sollte dies nicht der Fall sein, muss für die Genehmigungsfähigkeit des Projekts eine Befreiung oder Abweichung geprüft und mit der Baubehörde geklärt werden."

      case "GL_2_F_2":
        return "Prüfung der Genehmigungsfähigkeit gemäß den Bestimmungen des Baugesetzbuches (§34 und §35) und Vorverhandlungen mit den Baubehörden, falls kein Bebauungsplan vorliegt."

      case "GL_2_F_3":
        return "Klären, ob die Zustimmung des Nachbarn erforderlich ist, nicht nur für die baurechtliche Zustimmung des Nachbarn, sondern auch für die Bauausführung, die ohne Einbezug der nachbarlichen Bebauung nicht möglich wäre."

      case "GL_2_F_4":
        return "Klären und Beraten, ob ein verbindlicher Vorantrag oder eine Voranfrage auf Befreiung vom Bebauungsplan erforderlich ist."

      case "GL_2_G_1":
        return "Ermittlung der detaillierten Flächen- und Volumen nach Bezugseinheiten der 2. Ebene der Kostengruppen gemäß DIN 276."

      case "GL_2_G_3":
        return "Berechnung der detaillierten Kosten bis zur 2. Ebene der Kostengruppen gemäß DIN 276. Dies umfasst die Ermittlung von Kostenkennwerten und -daten sowie die Berücksichtigung der Kosteneinflüsse aller Beteiligten an der Planung."

      case "GL_2_H_2":
        return "Unterteilung der Projekttermine in die wesentlichen Vorgänge der Planung und des Bauablaufs, wie beispielsweise Ausführungsplanung, Vergabe, Ausführungsvorbereitung, Ausführung, Projektabschluss und andere relevante Schritte."

      case "GL_2_H_3":
        return "Grobe Festlegung der Dauern und Fristen der wesentlichen Projektvorgänge wie beispielsweise Planungsabläufe und -meilensteine, Vergabe- und Genehmigungsverfahren sowie Bauphasen."

      case "GL_2_I1_2":
        return "Die Ergebnisse der Leistungsphase 2 zusammenfassen, erläutern und dokumentieren."

      case "GL_2_I1_3":
        return "Die Ergebnisse der Leistungsphase 2 zusammenfassen, erläutern und dokumentieren."

      case "GL_2_I2_2":
        return "Die Ergebnisse der Leistungsphase 2 zusammenfassen, erläutern und dokumentieren."

      case "GL_2_I2_3":
        return "Die Ergebnisse der Leistungsphase 2 zusammenfassen, erläutern und dokumentieren."

      case "GL_2_I2_4":
        return "Die Ergebnisse der Leistungsphase 2 zusammenfassen, erläutern und dokumentieren."

      case "BL_2_1_1":
        return "Erstellung einer Sammlung und Katalogisierung sämtlicher Programm- und Bauziele, einschließlich aller Zielsetzungen bezüglich Kosten, Zeit, Qualität und Nutzung."

      case "BL_2_10_1":
        return "Klärung der gewünschten Präsentationshilfen unter Berücksichtigung der Wünsche und Anforderungen der Bauherrschaft. Dabei wird über eine geeignete Darstellung für die Präsentation nachgedacht."

      case "BL_2_10_2":
        return "Anfertigung der gewünschten Präsentationshilfen unter Berücksichtigung der Wünsche und Anforderungen der Bauherrschaft. Diese Präsentationshilfen können Modelle, Collagen, Visualisierungen oder Perspektiven umfassen."

      case "BL_2_12_1":
        return "Ermittlung der Flächen- und Volumen nach Bezugseinheiten der 2. Ebene der Kostengruppen gemäß DIN 276."

      case "BL_2_12_3":
        return "Berechnung der geschätzten Kosten bis zur 2. Ebene der Kostengruppen gemäß DIN 276. Dies umfasst die Ermittlung von Kostenkennwerten und -daten sowie die Berücksichtigung der Kosteneinflüsse aller Beteiligten an der Planung."

      case "BL_2_13_1":
        return "Fortschreiben und Aktualisierung des Projektstrukturplans der Vorplanung, um sicherzustellen, dass alle relevanten Projektinformationen, -abläufe, -ergebnisse  und -entscheidungen darin enthalten sind."

      case "BL_2_14_1":
        return "Aufstellen und Ausarbreiten von  Raumbüchern. Dies beinhaltet die Erfassung der Räume und ihrer Nutzungen sowie die Ermittlung der Ausrichtung und Abmessungen der Räume."

      case "BL_2_15_1":
        return "Erstellen und Ausarbeiten von besonderen bauordnungsrechtlichen Nachweisen für den vorbeugenden und organisatorischen Brandschutz bei baulichen Anlagen besonderer Art und Nutzung."

      case "BL_2_15_2":
        return "Erstellen und Ausarbeiten von besonderen bauordnungsrechtlichen Nachweisen für den vorbeugenden und organisatorischen Brandschutz bei Bestandsbauten."

      case "BL_2_15_3":
        return "Erstellen und Ausarbeiten von besonderen bauordnungsrechtlichen Nachweisen für den vorbeugenden und organisatorischen Brandschutz im Falle von Abweichungen von der Bauordnung."

      case "BL_2_2_1":
        return "Ausarbeitung verschiedener Lösungsansätze für alternative Anforderungsprofile der Gebäudefunktion."

      case "BL_2_2_2":
        return "Durchführung einer entsprechenden Kostenbewertung der Lösungsansätze, um eine bessere Vergleichbarkeit der Varianten zu gewährleisten."

      case "BL_2_3_1":
        return "Beachten der Anforderungen, die von der Zertifizierungsstelle für die Bauplanung und Bauausführung im Rahmen des vereinbarten Zertifizierungssystems (z.B.:DGNB-, LEED-, BREEAM- und BNB-Zertifikat) gefordert werden."

      case "BL_2_4_1":
        return "Die Durchführung des gewählten Zertifizierungssystems hängt maßgeblich von der genauen Definition des Leistungsinhalts des Projekts ab und erfordert die umfassende Beachtung aller Anforderungen des gewählten Zertifizierungssystems."

      case "BL_2_6_1":
        return "Klärung und Ermittlung des Kapitalbedarfs und der geschätzten Gesamtkosten anhand der Kostenschätzung gemäß DIN 276."

      case "BL_2_6_2":
        return "Auflistung der Eigenmittel erstellen, die für das Projekt verwendet werden. Dabei wird zwischen dem eingesetzten Eigenkapital und der erbrachten Eigenleistung differenziert."

      case "BL_2_6_3":
        return "Auflistung des Fremdkapitals erstellen, welches für das Projekt zur Verfügung steht. Dabei werden alle verschiedenen Fremdkapitalgeber berücksichtigt und die entsprechenden Fremdkapitalkosten und -zinsen berechnet."

      case "BL_2_6_4":
        return "Erstellung einer Mittelabflussplanung für den Bauherrn, um die Liquidität während des Bauprojekts sicherzustellen. Dadurch ist der Bauherr zu jeder Zeit über die erforderlichen Auszahlungen informiert und kann sicherstellen, dass die entsprechenden Geldmittel verfügbar sind."

      case "BL_2_7_1":
        return "Unabhängige Beratung der Bauherr*in durch die Architekt*innen hinsichtlich möglicher Beschaffung von Kredit- und Fördermitteln."

      case "BL_2_7_2":
        return "Erarbeitung von zusätzlichen Unterlagen, die von der Bank für die Genehmigung eines Kredits oder Darlehens gefordert werden."

      case "BL_2_8_1":
        return "Durchführung von Wirtschaftlichkeitsuntersuchungen, bei denen zwischen verschiedenen Verfahren wie Kostenvergleichsrechnung, Kapitalwertberechnung oder Nutzwertanalyse gewählt werden kann. Dabei werden aus den Projektkosten Ergebniswerte ermittelt, um Projektrealisierungsentscheidungen zu treffen."

      case "BL_2_9_1":
        return "Diskussion und Festlegung der spezifischen Fragen, über die in der Voranfrage für das Projekt und Bauvorhaben entschieden werden müssen."

      case "BL_2_9_2":
        return "Zusammenstellen der benötigten Unterlagen, wobei die Anforderungen je nach Bundesland variieren können. Zu den Mindestanforderungen gehören jedoch Bauzeichnungen, Baubeschreibungen und Berechnungen."

      case "BL_2_9_3":
        return "Einreichen der Bauvoranfrage bei der zuständigen Behörde, wobei sämtliche erforderlichen Angaben vollständig und korrekt bereitgestellt werden."


      //LPH 3
      case "GL_3_A_1_1":
        return "Grundrisse mit äußeren Gesamtmaßen, lichten Raummaßen, Rohbaumaßen der Wanddicken, Fenster- und Türpositionen, Türöffnungsrichtungen, Treppenverlauf, Schornstein- und Schachtanordnung, sowie Möblierung und Raumnutzungsbezeichnungen mit Stellflächen und Terrassen."

      case "GL_3_A_1_2":
        return "Der Plankopf enhält alle wesentlichen Informationen zur Zeichnung, zum Bauobjekt und zum Projekt, darunter etwaige Bauprojektbezeichnungen, Maßstabsangaben, Bearbeitungsvermerke, sowie Angaben zum Auftraggeber und zum Ersteller der Planung."

      case "GL_3_A_2_1":
        return "Schnitt mit Geschosshöhen, lichten Raummaßen, Brüstungshöhen, Wanddicken, Treppenverläufen, Höhenlage des Gebäudes, Fundamentmaßen, First- und Schornsteinhöhen sowie Leitungspositionen."

      case "GL_3_A_2_2":
        return "Der Plankopf enhält alle wesentlichen Informationen zur Zeichnung, zum Bauobjekt und zum Projekt, darunter etwaige Bauprojektbezeichnungen, Maßstabsangaben, Bearbeitungsvermerke, sowie Angaben zum Auftraggeber und zum Ersteller der Planung."

      case "GL_3_A_3_1":
        return "Ansicht des Bauobjektes mit Darstellung von Fenstern, Türen, Dachrinnen, Fallrohren, Geländern, Dach- und Vorbauten, Schornsteinen und weiteren Details."

      case "GL_3_A_3_2":
        return "Der Plankopf enhält alle wesentlichen Informationen zur Zeichnung, zum Bauobjekt und zum Projekt, darunter etwaige Bauprojektbezeichnungen, Maßstabsangaben, Bearbeitungsvermerke, sowie Angaben zum Auftraggeber und zum Ersteller der Planung."

      case "GL_3_B_1":
        return "Bereitstellen der Arbeitsergebnisse als Grundlage für die an der Planung fachlich Beteiligten. Dies erfolgt in Form einer Zusammenstellung und, falls notwendig, Aufbereitung der bisherigen Planungsergebnisse aus den vorherigen Leistungsphasen."

      case "GL_3_B_2":
        return "Abstimmung mit bzw. Koordination der fachlich Beteiligten und ihrer Leistungen. Integration der Fachleistungen in die Gesamtplanung unter Berücksichtigung möglicher Kollisionen einzelner Fachdisziplinen."

      case "GL_3_C_1":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmalen und Tatbeständen des Bauobjekts bezogen auf Lage und Standport des Baugrundstückes.\n" +
          "Dies beinhaltet Angaben zur geografischen Lage des Objekts sowie seiner Umgebung wie Geländemerkmale oder besondere geografische Gegebenheiten.\n" +
          "Details zur Infrastruktur der Umgebung wie Straßen, Versorgungsleitungen und anderen wichtigen Einrichtungen."

      case "GL_3_C_2":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf das Baugrundstück.\n" +
          "Informationen über die Beschaffenheit des Bodens, mögliche Einschränkungen oder Besonderheiten des Geländes sowie die Größe, Form und spezifische Merkmale des Grundstücks, einschließlich eventueller Grenzen oder Geländekonturen."

      case "GL_3_C_3":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die Erschließung. Informationen über die vorhandene Infrastruktur, wie Straßenanbindung, Wasser-, Strom- und Abwasserversorgung sowie weitere technische Anbindungen wie Telekommunikationsnetze oder Gasleitungen, die für die Nutzung relevant sind."

      case "GL_3_C_4":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die Baukonstruktionen. Details über die Bauweise, Materialien, strukturellen Merkmale und technischen Standards des Gebäudes oder der Baustruktur, einschließlich der tragenden Elemente, Dachkonstruktion, Wände, Bodenbeläge und anderen relevanten Bauteile."

      case "GL_3_C_5":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die technischen Anlagen. Details zu den vorhandenen technischen Installationen im Gebäude, wie Heizungs- und Kühlsysteme, Sanitäranlagen, Elektroinstallationen, Sicherheitssysteme oder weitere spezielle technische Ausstattungen. "

      case "GL_3_C_6":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf den Brandschutz.\n" +
          "Informationen über vorhandene Brandschutzeinrichtungen wie Feuermelder, Feuerlöscher, Brandabschnitte oder Fluchtwege im Gebäude."

      case "GL_3_C_7":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf Außenanlagen.\n" +
          "Angaben über die Gestaltung und Ausstattung des Außenbereichs, einschließlich Gartenanlagen, Terrassen, Gehwege, Parkplätze oder andere spezifische Merkmale der Außenanlagen."

      case "GL_3_C_8":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf Wirtschaftlichkeit.\n" +
          "Informationen zu den Gesamtkosten des Objekts, betriebliche und Instandhaltungskosten sowie Einschätzungen zur Wirtschaftlichkeit durch mögliche Renditen oder langfristige Investitionsaussichten."

      case "GL_3_D_1":
        return "Detaillierte Prüfung und Sicherstellung der Konformität zwischen der Planungskonzeption des Bauprojekts und den Festsetzungen in Bebauungsplänen sowie einschlägigen Satzungen. Dies umfasst die Überprüfung, ob das Bauvorhaben mit den rechtlichen Vorgaben hinsichtlich Nutzung, Bebauungsdichte, Höhenbegrenzungen, Abstandsflächen und anderen planungsrechtlichen Bestimmungen übereinstimmt."

      case "GL_3_D_2":
        return "Die Beratung und Vorverhandlung über ggf. erforderliche Befreiungen und Abweichungen vom Bebauungsplan erfordert eine gründliche Prüfung der Planungskonzeption im Hinblick auf potenzielle Abweichungen von den festgesetzten Bauregeln.\n" +
          "Dies beinhaltet die Analyse der Möglichkeiten für Ausnahmen und die Abstimmung mit den zuständigen Behörden, um eine einvernehmliche Lösung zu erarbeiten und gegebenenfalls erforderliche Befreiungen oder Abweichungen zu beantragen."

      case "GL_3_D_3":
        return "Abstimmung der Planungskonzeption mit dem Flächennutzungsplan oder den Behördenangaben zur Genehmigung.\n" +
          "Beratung über erforderliche Anpassungen oder Maßnahmen zur Einhaltung der rechtlichen Vorgaben sowie gegebenenfalls Vorverhandlungen mit den Behörden, zur Sicherstellung einer genehmigungsfähigen Planung."

      case "GL_3_D_4":
        return "Die Klärung und gegebenenfalls Vorverhandlung zur Genehmigungsfähigkeit des Brandschutzes umfasst eine gründliche Überprüfung der geplanten Brandschutzmaßnahmen hinsichtlich ihrer Übereinstimmung mit den geltenden baurechtlichen Vorgaben und Brandschutzbestimmungen.\n" +
          "Dies beinhaltet die Beratung über erforderliche Maßnahmen zur Einhaltung der Sicherheitsstandards sowie gegebenenfalls Vorverhandlungen mit den zuständigen Behörden, um etwaige Anpassungen zu klären und die Genehmigungsfähigkeit des Brandschutzkonzepts sicherzustellen."

      case "GL_3_D_5":
        return "Beratung bzw. Vorverhandlung zu weiteren Genehmigungstatbestände, die sich aus verschiedenen Bedigungen der Umgebung und Nutzung ergeben können. Überprüfung des geplanten Bauvorhabens bezüglich der Einhaltung der entsprechenden gesetzlichen Vorgaben und Anforderungen."

      case "GL_3_D_6":
        return "Beratung oder gegebenenfalls Vorverhandlungen zur Genehmigungsfähigkeit von Fragen, die die nachbarliche Zustimmung erfordern, beinhaltet die Prüfung des geplanten Bauvorhabens im Hinblick auf potenzielle Auswirkungen auf benachbarte Grundstücke oder Eigentümer.\n" +
          "Beratung zur möglichen Maßnahmen zur Minimierung von Störungen oder Beeinträchtigungen, sowie ggf. Vorverhandlungen mit den betroffenen Nachbarn, um deren Zustimmung zu gewinnen."

      case "GL_3_E_1":
        return "Ermittlung der Mengen und Massen nach DIN 276 in der dritten Ebene der Kostengruppen. Dies beinhaltet die detaillierte Aufschlüsselung der benötigten Materialien und Arbeitsleistungen entsprechend den spezifischen Anforderungen des Bauvorhabens."

      case "GL_3_E_3":
        return "Durchführung einer Kostenberechnung gemäß DIN 276 unter Berücksichtigung der Kostengruppen bis zur dritten Ebene. Detaillierte Aufschlüsselung der voraussichtlichen Kosten für Materialien, Arbeitsleistungen und sonstige Ausgaben inklusive der Kosteneinflüsse aller an der Planung Beteiligter."

      case "GL_3_F_3":
        return "Fortschreiben des Terminplans gemäß den Anforderungen, die sich aus den aktuellen Planungsergebnissen ergeben."

      case "GL_3_F_2":
        return "Abstimmung des Grobterminplans mit den Fachbeteiligten zur Gewährleistung einer effektiven Koordination aller Projektphasen und Meilensteine. Frühzeitige Identifikation potenzieller Engpässe und geeignete Maßnahmen zur Einhaltung des Terminplans."

      case "GL_3_G_1":
        return "Das strukturierte Zusammenführung und Aufbereitung aller relevanten Informationen, Analysen und Entscheidungen, die während dieser Phase des Bauprojekts gesammelt wurden. Dies beinhaltet die klare Darstellung und Erläuterung der Ergebnisse in Form von Berichten, Präsentationen oder anderen Dokumentationsformen, um die Grundlage für weiterführende Planungs- und Entscheidungsprozesse zu schaffen."

      case "BL_3_1_1":
        return "Analyse und Vergleich der verschiedener Lösungsansätze für alternative Anforderungsprofile hinsichtlich der Gebäudefunktion und Gebäudenutzung."

      case "BL_3_1_2":
        return "Durchführung einer Kostenbewertung der verschiedenen Lösungsansätze. Dadurch wird die Wirtschaftlichkeit verglichen und beurteilt, um die optimale Lösung zu identifizieren."

      case "BL_3_2_1":
        return "Durchführung einer Wirtschaftlichkeitsberechnung, die sämtliche Kosten berücksichtigt, die während des gesamten Lebenszyklus eines Projekts anfallen. Ziel der Prognose ist es, dem Bauherrn eine Einschätzung darüber zu geben, ob das Projekt langfristig wirtschaftlich ist. Daher ist eine sorgfältige Durchführung der Berechnungsverfahren entscheidend, um ein aussagekräftiges Ergebnis zu erzielen."

      case "BL_3_3_1":
        return "Ermittlung der vertieften und detaillierten Mengen- und Massenberechnung nach Bezugseinheiten der 3. Ebene der Kostengruppen gemäß DIN 276."

      case "BL_3_3_3":
        return "Berechnung der vertieften und detaillierten Kosten bis in die dritte Ebene der Kostengruppen gemäß DIN 276.\n" +
          "Dies beinhaltet die Ermittlung von Kostenkennwerten und Kostenkenndaten sowie die Kosteneinflüsse aller Beteiligter an der Planung."

      case "BL_3_4_1":
        return "Fortschreiben und Ergänzen von Raumbüchern, die bereits in einer früheren Leistungsphase erarbeitet wurden.\n" +
          "Dies beinhaltet die Überprüfung der bereits vorhanden Rauminformationen und die Erweiterung der Rauminformationen aufgrund der detaillierteren  Planungsergebnisse."

      //LPH 4

      case "GL_4_AABA_else_1":
        return "Angabe aller relevanten Informationen zum Antrag auf Ausnahme, Befreiung und Abweichung von bauplanungsrechtlichen Vorschriften des Bauvorhabens."

      case "GL_4_Abstandsflaechen_HB_HE_SL_1":
        return "Die Planung beinhaltet die präzise Berechnung und Darstellung der erforderlichen Abstandsflächen gemäß den bauordnungsrechtlichen Vorgaben.\n" +
          "Es wird sichergestellt, dass alle Bauvorhaben die vorgeschriebenen Mindestabstände zu Nachbargrundstücken und öffentlichen Flächen einhalten, um eine rechtskonforme Planung und spätere Baugenehmigung zu gewährleisten."

      case "GL_4_Abstandsflaechenuebernahme_BY_1":
        return "Die Planung berücksichtigt die Einholung der erforderlichen Zustimmungen von Nachbarn, deren Grundstücke von den festgelegten Abstandsflächen betroffen sind.\n" +
          "Es wird sichergestellt, dass alle betroffenen Nachbarn schriftlich ihre Zustimmung geben, um die Einhaltung der gesetzlichen Anforderungen zu gewährleisten und die Genehmigungsfähigkeit des Bauvorhabens zu sichern."

      case "GL_4_amtliche_Basiskarte_NW_1":
        return "Angabe aller relevanter Informationen zur Lage des Grundstücks.\n" +
          "Dies beinhaltet Angaben zum Standort, der Gemeinde, Gemarkung und der Nachbarn."

      case "GL_4_amtliche_Basiskarte_NW_3":
        return "Die Basiskarte enthält eine Angabe des Maßstabs von der amtlichen Basiskarte im richtigen Format."

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_1":
        return "Der Plan enthält Angaben zu allen relevaten Projektbeteiligten, die in das Projekt direkt involviert sind."

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_2":
        return "Der Plan enthält Angaben zur der Nutzung des Gebäudes und der Anzahl der Geschosse."

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_3":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_4":
        return "Der Plan enthält Angaben zum Flurstück und zur Flustücksnummer des Projektgrundstücks."

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_5":
        return "Angabe aller relevanter Informationen zum Grundstücks bezüglich der Grundstücksgrenzen und der Abstände sowie Abstandsflächen."

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_6":
        return "Der Plan enthält Angaben zur  Stellplätzen für Fahrzeuge und Fahrräder, die für die Nutzung des Projekts auf dem Grundstück zur Verfügung gestellt werden."

      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_7":
        return "Der Plan enthält Angaben zur Höhenlage des Grundstücksgeländes und des Dachs bezüglich der Dachhöhe und Dachform."

      case "GL_4_Angaben_Beherbergungsbetten_NW_1":
        return "Die Planung enthält detaillierte Informationen zur Beherbergungsstätte und den Unterkunftsplätze, die in dem Betrieb zur Verfügung stehen."

      case "GL_4_Angaben_Beherbergungsstaette_ohne_Brandschutzkonzept_NW_1":
        return "Die Planung enthält detaillierte Informationen zur Beherbergungsstätte und den Unterkunftsplätze, sowie zur Sicherheit des Betriebs und der Gäste beim Brandfall."

      case "GL_4_Angaben_CO_Warnanlage_NW_1":
        return "Die Planung enthält genaue Angaben zur Positionierung und Spezifikation der CO Warnanlage, welche die geltenden Sicherheitsvorschriften und Normen berücksichtigen."

      case "GL_4_Angaben_zu_gewerblichen_Anlagen_BW_1":
        return "Die Planung enthält genaue Angaben bzgl. der gewerblichen Anlagen, die keine immissionsschutzrechtliche Genehmigung benötigen. Dies beinhaltet die Konformität aller Planungsunterlagen mit den geltenden bau- und gewerberechtlichen Vorschriften, sowie die detaillierte Darstellung der Anlagenteile, Betriebsabläufe und Sicherheitsmaßnahmen."


      case "GL_4_Angaben_zum_Betriebsraum_NW_1":
        return "Die Plaunung beinhaltet konkrete Angaben zur Art der elektrischen Anlagen und Lage des Betriebsraums."

      case "GL_4_Angaben_zum_Krankenhaus_NW_1":
        return "Die Planung enthält detaillierte Angaben über die Anzahl der Betten, sowie die Darstellung der Räume für Untersuchung und Behandlung mit ionisierenden Strahlen.\n" +
          "Dies umfasst die präzise Planung und Darstellung der räumlichen Anordnung, Ausstattung und Sicherheitsmaßnahmen dieser speziellen Bereiche, um die Einhaltung der gesetzlichen Vorgaben und Sicherheitsstandards zu gewährleisten und die Genehmigungsfähigkeit sicherzustellen."

      case "GL_4_Anlagen_Gebuehren_HH_1":
        return "Die Planung enthält Informationen sämtlichen Gebühren die für das Projekt relevant sind."

      case "GL_4_anrechenbare_Kosten_MV_ST_SH_1":
        return "Die Planung beinhaltet die Erfassung und Aufstellung aller Kosten der Baukonstruktion und technischen Anlagen.\n" +
          "Dies umfasst die detaillierte Aufschlüsselung und Dokumentation der anrechenbaren Kosten gemäß den entsprechenden Kostengruppen der DIN 276."

      case "GL_4_anrechenbarer_Bauwert_HB_1":
        return "Die Planung enhält konkrete Informationen zum anrechenbaren Bauwert aus dem Brutto-Rauminhalt des Gebäudes und der dazugehörigen Indexzahl."

      case "GL_4_Ansicht_BW_NW_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Ansicht_BW_NW_2":
        return "Der Plan enthält Angaben zu den Höhenlagen des Geländes und der Geschosse bezogen auf die lichten Raummaße.\n" +
          "Dabei wird zwischen Angaben der Rohbaumaße und Fertigmaße  unterscheiden."

      case "GL_4_Ansicht_BW_NW_3":
        return "Der Plan berücksichtigt die Darstellung der Öffnungen wie Fenster und Türen des Projektgebäudes, sowie jeweilige Besonderheiten z.B. in der Öffnungsart."

      case "GL_4_Ansicht_BW_NW_4":
        return "Der Plan enthält Informationen zur Höhenlage der Geländeoberfläche bezogen auf Normalhöhennull (NHN)."

      case "GL_4_Ansicht_else_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Ansicht_else_2":
        return "Der Plan enthält Angaben zu den Höhenlagen des Geländes und der Geschosse bezogen auf die lichten Raummaße.\n" +
          "Dabei wird zwischen Angaben der Rohbaumaße und Fertigmaße unterscheiden."

      case "GL_4_Ansicht_else_3":
        return "Der Plan berücksichtigt die Darstellung der Öffnungen wie Fenster und Türen des Projektgebäudes, sowie jeweilige Besonderheiten z.B. der Öffnungsart und Höhenkoten."

      case "GL_4_Ansicht_else_4":
        return "Der Plan enthält Angaben zum Material und Oberflächenbeschaffenheit aller relevanten Bauteile."

      case "GL_4_Ansicht_else_5":
        return "Der Plan enthält Informationen zur Höhenlage der Geländeoberfläche bezogen auf Normalhöhennull (NHN)."

      case "GL_4_Ansicht_SL_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Ansicht_SL_2":
        return "Der Plan enthält Angaben zu den Höhenlagen des Geländes und der Geschosse bezogen auf die lichten Raummaße.\n" +
          "Dabei wird zwischen Angaben der Rohbaumaße und Fertigmaße  unterscheiden."

      case "GL_4_Ansicht_SL_3":
        return "Der Plan berücksichtigt die Darstellung der Öffnungen wie Fenster und Türen des Projektgebäudes, sowie jeweilige Besonderheiten z.B. in der Öffnungsart."

      case "GL_4_Ansicht_SL_4":
        return "Der Plan enthält Höhenangaben zu maßgebenden Punkten der Dachkonkstruktion wie First, Traufen oder Attika."

      case "GL_4_Ansicht_SL_5":
        return "Der Plan enthält Informationen zur Höhenlage der Geländeoberfläche bezogen auf Normalhöhennull (NHN)."

      case "GL_4_Antrag_auf_Abloesung_notwendiger_Einstellplaetze_NI_1":
        return "Der Antrag auf Ablösung notwendiger Einstellplätze und deren Ersatz durch einen Geldbetrag an die Gemeinde beinhaltet die Prüfung und Dokumentation der rechtlichen und baulichen Voraussetzungen für eine solche Ablösung.\n" +
          "Es umfasst die Erstellung des entsprechenden Antragsformulars, die Berechnung des abzulösenden Betrags gemäß den kommunalen Vorschriften sowie die Abstimmung mit den zuständigen Behörden, um die Zustimmung zur Ablösung zu erhalten."

      case "GL_4_Anzahl_Fahrradstellplaetze_RP_1":
        return "Die Planung enthält alle relevanten Informationen zu Fahrradstellplätzen im Bezug auf die notwendige Anzahl für den jeweiligen Gebäudetyp und die örtlichen Transfermöglichkeiten."

      case "GL_4_ArbeitsstaettenVO_RP_1":
        return "Die Planung enthält alle relevanten Informationen zur Arbeitsstättenverordnung und zum Arbeitsschutz.\n" +
          "Dies beinhaltet die Angabe der für Arbeitstätten erforderliche Raumtypen, sowie konkrete Maßnahmen für Gesundheits- und Arbeitsschutz."

      case "GL_4_Art_der_baulichen_Nutzung_else_1":
        return "Die Planung macht Angaben zur Art der baulichen Nutzung für die Beschreibung der zulässigen Nutzung des Grundstücks oder einer sonstigen Fläche."

      case "GL_4_Artenschutz_else_1":
        return "Die Planung enthält Erläuterung zum Artenschutz oder zur artenschutzrechtlichen Prüfung.\n" + " Das beinhaltet Informationen über die Flora und Fauna des Biotops und die zu treffenden Maßnahmen zur Einhaltung des Tier- und Pflanzenschutzes."

      case "GL_4_Aussenanlagenplan_BE_1":
        return "Die Planung enthält Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf Außenanlagen.\n" +
          "Dies umfasst Angaben über die Gestaltung und Ausstattung des Außenbereichs, einschließlich Gartenanlagen, Terrassen, Gehwege, Parkplätze oder andere spezifische Merkmale der Außenanlagen."

      case "GL_4_Auszug_Bebauungsplan_HB_1":
        return "Der Plan enthält Angaben zum Maßstab des Bebauungsplans im richtigen Format."

      case "GL_4_Auszug_Bebauungsplan_HB_2":
        return "Der Plan beinhaltet eine Legende zur Planzeichenerklärung, insbesondere die Bezeichnung der verschiedenen Gebiete für die Art der baulichen Nutzung."

      case "GL_4_Auszug_Bebauungsplan_HB_3":
        return "Der Plan enthält Angaben zur baulichen und natürlichen Umgebung des Grundstücks, unter Anderem auch auch wichtige öffentliche Gebäude, Busstationen oder Angaben zu Flüssen, Seen etc."

      case "GL_4_Auszug_Bebauungsplan_SL_1":
        return "Der Plan enthält Angaben zum Maßstabs des Bebauungsplans im richtigen Format."

      case "GL_4_Auszug_Bebauungsplan_SL_2":
        return "Der Plan enthält eine Erklärung aller verwendeten Planzeichen, insbesondere die Bezeichnung der verschiedenen Gebiete für die Art der baulichen Nutzung."

      case "GL_4_Auszug_Bebauungsplan_SL_3":
        return "Angabe eines Bebauungsplans, Darstellung aller relevanten Informationen wie die Umgebung des Grundstücks und Bezeichnung aller Flächengebieten im Rahmen der Art der baulichen Nutzung."

      case "GL_4_Auszug_Bebauungsplan_SL_4":
        return "Der Plan beinhaltet alle relevanten Festsetzungen und gliedert dementsrepchend Flächen unterschiedlicher Nutzung."

      case "GL_4_Auszug_Bebauungsplan_SL_5":
        return "Die Planung benennt die dem Bebauungsplan zu Grunde liegenden rechtlichen Grundlagen, Vorschriften und Verordnungen."

      case "GL_4_AVK_BW_NW_1":
        return "Die Planung nennet der wesentlichen Informationen des Bauvorhabens wie die Beteiligen, Angaben zum Standort und weitere Randbedingungen, zur Einordnung des folgenden Konzepts."

      case "GL_4_AVK_BW_NW_2":
        return "Die Planung enthält Angaben zum Verwertungskonzept von Bodenaushubmaterialien.\n" +
          "Dazu gehören die geplante Anfallmenge, baugrundtechnische Informationen zum Bodenmaterial und der vorgesehene Entsorgungsweg."

      case "GL_4_AVK_BW_NW_3":
        return "Die Planung enthält Angaben zum Verwerungskonzept von Abfällen aus Abbruch oder Teilabbruch.\n" +
          "Dies umfasst eine Beschreibung des Unterfangens, das Prüfen auf Schadstoffe und deren Verwertungsmaßnahmen."

      case "GL_4_Bauartgenehmigung_else_1":
        return "Die Planung beinhaltet alle relevanten Angaben zur allgemeinen Bauartgenehmigung (aBG) hinsichtlich dergeltenden baurechtlichen und technischen Bestimmungen."

      case "GL_4_Baubeschreibung_else_1":
        return "\"Die Baubeschreibung enthält Angaben zu allen wesentlichen planungsrelevanten Merkmalen und Tatbeständen des Bauobjekts bezogen auf Lage und Standort des Baugrundstückes.\n" +
          "Dies beinhaltet Angaben zur geografischen Lage des Objekts sowie seiner Umgebung wie Geländemerkmale oder besondere geografische Gegebenheiten.\n" +
          "Details zur Infrastruktur der Umgebung wie Straßen, Versorgungsleitungen und anderen wichtigen Einrichtungen."

      case "GL_4_Baubeschreibung_else_2":
        return "Die Baubeschreibung enthält Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf das Baugrundstück.\n" +
          "Dies umfasst Angaben zur Beschaffenheit des Bodens, mögliche Einschränkungen oder Besonderheiten des Geländes, sowie die Größe, Form und spezifische Merkmale des Grundstücks, einschließlich eventueller Grenzen oder Geländekonturen."

      case "GL_4_Baubeschreibung_else_3":
        return "Die Baubeschreibung enthält Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die Erschließung.\n" +
          "Dies beinhaltet Informationen über die vorhandene Infrastruktur, wie Straßenanbindung, Wasser-, Strom- und Abwasserversorgung, sowie weitere technische Anbindungen wie Telekommunikationsnetze oder Gasleitungen, die für die Nutzung relevant sind."

      case "GL_4_Baubeschreibung_else_4":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die Baukonstruktionen.\n" +
          "Details über die Bauweise, Materialien, strukturellen Merkmale und technischen Standards des Gebäudes oder der Baustruktur, einschließlich der tragenden Elemente, Dachkonstruktion, Wände, Bodenbeläge und anderen relevanten Bauteile."

      case "GL_4_Baubeschreibung_else_5":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die technischen Anlagen.\n" +
          "Details zu den vorhandenen technischen Installationen im Gebäude, wie Heizungs- und Kühlsysteme, Sanitäranlagen, Elektroinstallationen, Sicherheitssysteme oder weitere spezielle technische Ausstattungen."

      case "GL_4_Baubeschreibung_else_6":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf den Brandschutz.\n" +
          "Informationen über vorhandene Brandschutzeinrichtungen wie Feuermelder, Feuerlöscher, Brandabschnitte oder Fluchtwege im Gebäude."

      case "GL_4_Baubeschreibung_else_7":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf Außenanlagen.\n" +
          "Angaben über die Gestaltung und Ausstattung des Außenbereichs, einschließlich Gartenanlagen, Terrassen, Gehwege, Parkplätze oder andere spezifische Merkmale der Außenanlagen."

      case "GL_4_Baubeschreibung_else_8":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf Wirtschaftlichkeit.\n" +
          "Informationen zu den Gesamtkosten des Objekts, betriebliche und Instandhaltungskosten sowie Einschätzungen zur Wirtschaftlichkeit durch mögliche Renditen oder langfristige Investitionsaussichten."

      case "GL_4_Baubeschreibung_Fluessiggas_1_RP_1":
        return "Die Planung enthält Anlagen zur Baubeschreibung über die Lagerung von 3 Tonnen (oder mehr) Flüssiggas.\n" +
          "Die Beschreibung umfasst detaillierte Informationen zur Menge und Art der Lagerung und der Beschreibung der Lagermöglichkeit."

      case "GL_4_Baubeschreibung_Fluessiggas_3_RP_1":
        return "Es finden sich detaillierte Angaben zur Lagerung von 3 und mehr t Flüssiggas in dem Plan.\n" +
          "Dazu gehörten die genaue Verortung des Sammelbehälters und dessen Abstand zum Gebäude inkl. Vermaßung."

      case "GL_4_Baubeschreibung_Heizoel_1_RP_1":
        return "Die Planung enthält Anlagen zur Baubeschreibung der Lagerung von mehr als 10 m³ Heizöl.\n" +
          "Es gibt detaillierte Angaben zur Menge und Art der Lagerung, Beschreibung der Sicherheitseinrichtungen und zu den Rohrleitungen und deren Ausführung."

      case "GL_4_Baubeschreibung_Heizoel_1_RP_2":
        return "Die Baubeschreibung enthält Nachweise zur Verwendbarkeit eines Bauprodukts, das weder über eine Technische Baubestimmung noch über eine allgemein anerkannte Regel der Technik verfügt.\n" +
          "Dies kann dadurch bedigt sein, dass das Produkt wesentlich von einer bestehenden Technischen Baubestimmung abweicht oder durch eine Verordnung gemäß § 87 Absatz 7 vorgeschrieben ist."

      case "GL_4_Baubeschreibung_Heizoel_3_RP_1":
        return "Die Baubeschreibung enthält eine Darstellung der Lagerung von mehr als 10 m³ Heizöl in den Plänen.\n" +
          "Die beinhaltet die Lagerung im Freien inkl. Vermaßung von Grundstücksgrenzen, Gebäuden oder der Behälter untereinander oder die Lagerung im Gebäude inkl. Vermaßung der Abstände von Behälter zu Wänden und untereinander."

      case "GL_4_Baukosten_HB_1":
        return "Die Planung beinhaltet die Berechnung der Baukosten gegleidert nach den Kostengruppen der DIN 276. Dem zu Grunde liegen Angaben der Brutto-Rauminhalte des Gebäudes gemäß DIN 277, sowie die dazugehörigen Baukostenkennwerte je Mengeneinheit."

      case "GL_4_Baukostenwert_HB_1":
        return "Die Planung enthält Angabe zum durchschnittlichen Baukostenwerts des aktuellen Jahres je m³ Brutto-Rauminhalt, unterteilt in die verschiedenen Gebäudearten bzw. Nutzungen."

      case "GL_4_Baumbestandsbescheinigung_1_HB_1":
        return "Die Planung ethält Anlagen zu Baumbestandsbescheinigung.\n" +
          "Dies beinhlatet Angaben zum Baum- bzw. Gehölzbestand und die dazu vorgesehenen Maßnahmen."

      case "GL_4_Baumbestandsbescheinigung_2_HB_1":
        return "Der Lageplan enthält die Darstellung des Baumbestands, inklusive der Nummerierung der einzelnen Bäume, sowie die Beschriftung der umliegenden Flächen und Kennzeichnung der geplanten Maßnahmen für jeden Baum."

      case "GL_4_Baumbestandsbescheinigung_HH_1":
        return "Die Planung ethält Anlagen zu Baumbestandsbescheinigung.\n" +
          "Dies beinhlatet Angaben zum Baum- bzw. Gehölzbestand und die dazu vorgesehenen Maßnahmen."

      case "GL_4_Baunebenrecht_HB_1":
        return "Die Planung ehthält Angaben und Erläuterungen zum Baunebenrecht bzgl. Rechtlicher Vorschriften und Regelungen, die neben dem Bau- und Planungsrecht in Bremen gelten."

      case "GL_4_Bauvorlageberechtigung_else_1":
        return "Die Berechtigung einer Person oder Institution bestimmte Bauvorlagen bei den Baubehörden einzureichen.\n" +
          "Sie dient zur Sicherstellung , dass Bauvorschriften und -normen die planerische und bauliche Qualität der Bauvorhaben gewährleisten."

      case "GL_4_Bauwert_HB_1":
        return "Die Planung enthält eine Berechnung über den Wert des Bauvorhabens unter Berücksichtigung der Gebäudeart und den Herstellungskosten, sowie dem aktuellen Preisindex."

      case "GL_4_beabsichtigte_Nutzung_ST_1":
        return "Die Planung enthält konkrete Erläuterung zu Art- und Umfang der beabsichtigten Nutzung des Gebäudes bzw. des Grundstückes."

      case "GL_4_Benennung_Bauleitender_BW_SH_1":
        return "Beschreibung einer Person oder einer Gruppe, die für die Leitung und Koordination eines Bauprojektes verantwortlich ist.\n" +
          "Ziel ist der reibungslose Ablauf und eine erfolgreiche Umsetzung des Bauvorhabens."

      case "GL_4_Berechnung_Verkaufsstaette_NW_1":
        return "Die Planung enthält die Flächenberechnung der Verkaufsräume und der Brandabschnitte sowie eine Berechnung der erforderlichen Breiten der Ausgänge aus den Geschossen ins Freie oder in notwendige Treppenräume."

      case "GL_4_Bericht_ueber_den_geprueften_Brandschutznachweis_BE_1":
        return "Die Planung enthält einen Bericht über die Prüfung des Brandschutznachweises für das Bauvohaben."

      case "GL_4_Bericht_ueber_den_geprueften_Standsicherheitnachweis_BE_1":
        return "Die Planung enthält einen Bericht über die Prüfung des Standsicherheitsnachweises für das Bauvohaben."

      case "GL_4_Bescheinigung_nach_67_Abs_4_LBO_SL_1":
        return "Die Planung enthält einen Bericht über die Prüfung der Nachweis bzgl. Standsicherheit und Brandschutz des Bauvohabens."

      case "GL_4_Beschreibung_barrierefreie_Wohnungen_BE_1":
        return "Die Planung enthält eine Beschreibung zu Barrierefreiheit der Wohnungen im Projekt."

      case "GL_4_Beschreibung_Baugrundstueck_SL_1":
        return "Die Planung enthält eine Beschreibung des Baugrundstücks hinsichtlich der Umgebungssituation, städtebaulicher und rechlicher Rahmenbedingungen, sowie Themen der Ver- und Entsorgung und Zugangsmöglichkeiten."

      case "GL_4_Beschreibung_baulicher_Anlagen_SL_1":
        return "Angaben zur Beschreibung der baulichen Anlage."

      case "GL_4_Beschreibung_der_Baumassnahmen_geringen_Umfangs_SL_1":
        return "Die Planung enthält eine Beschreibung der Baumaßnahmen geringen Umfangs.\n" +
          "Dies umfasst kleinere Renovierungen, Reparaturen oder Ein-/ Umbauten an bestehenden Gebäuden, wie z.B. die Installation neuer Fenster oder die Errichtung von Werbeanlagen.\n" +
          "Diese Maßnahmen haben typischerweise weniger komplexe Planungs- und Genehmigungsanforderungen im Vergleich zu größeren Bauprojekten."

      case "GL_4_Bestuhlungswegplan_und_Rettungswegplan_NW_1":
        return "Die Planung enthält detaillierte Angaben zum Bestuhlungswegplan und zum Rettungswegplan für Versammlungsräume.\n" +
          "Diese Pläne stellen sicher, dass die Bestuhlung gemäß den Sicherheitsvorschriften und Platzanforderungen angeordnet ist und dass ausreichend breite und gut zugängliche Fluchtwege vorhanden sind, um im Notfall eine schnelle Evakuierung zu ermöglichen."

      case "GL_4_Betriebsbeschreibung_else_1":
        return "Die Planung enthält eine Betriebsbeschreibung, die die Belegschaftsplanung umfasst.\n" +
          "Diese beinhaltet die Anzahl der Personen, ihre Zusammensetzung nach produktiven und unproduktiven Tätigkeiten, nach Berufen sowie nach gelernten Arbeitskräften, angelernten und ungelernten Lehrlingen."

      case "GL_4_Betriebsbeschreibung_else_2":
        return "Die Planung enthält eine Betriebsbeschreibung, die die Planung des Produktionsapparates umfasst.\n" +
          "Diese beinhaltet Angaben zur Leistungsfähigkeit, Ausweitung, Einengung oder Verbesserung des Produktionsapparates sowie zur Zusammensetzung aus Maschinen, Werkzeugen und Vorrichtungen nach Art, Größe und Leistungsfähigkeit."

      case "GL_4_Betriebsbeschreibung_else_3":
        return "Die Personalplanung beinhaltet die Planung des Personals zur Leitung, Überwachung und zum Anlernen: Ingenieure, Chemiker, Meister, Vorarbeiter und Verwaltungspersonal."

      case "GL_4_Betriebsbeschreibung_else_4":
        return "Die Planung enthält eine Betriebsbeschreibung zur Planung der Arbeitsdurchführung.\n" +
          "Diese umfasst die Art und Reihenfolge der Arbeitsgänge, die Größe der Serien, die rationelle Durchführung, Verfahren wie Taktstraßen sowie die Verteilung der Arbeit auf die einzelnen Maschinen und Arbeitsplätze."

      case "GL_4_Betriebsbeschreibung_else_5":
        return "Die Betriebsbeschreibung umfasst die Planung der Arbeitszeit, einschließlich der Anzahl der Schichten und der Arbeitspausen."

      case "GL_4_Betriebsbeschreibung_else_6":
        return "Die Betriebsbeschreibung umfasst die Planung der Vergabe an Unterlieferer, einschließlich der Entscheidung, welche Teile selbst gefertigt und bearbeitet werden sollen und welche Aufgaben an andere Unternehmen vergeben werden sollen."

      case "GL_4_BFK_HB_HE_NW_1":
        return "Der Plan enthält Angaben zur Zugänglichkeit von Grundstück und Gebäude unter dem Aspekt der Barrierefreiheit."

      case "GL_4_BFK_HB_HE_NW_2":
        return "Der Plan enthält Angaben zu Anzahl und Lage barrierefreier Stellplätze."

      case "GL_4_BFK_HB_HE_NW_3":
        return "Der Plan enthält Informationen zu Art und Positionierung sämtlicher Bedienelemente des Barrierefreiheitskozeptes."

      case "GL_4_BFK_HB_HE_NW_4":
        return "Der Plan enthält Angaben zu Lage und Ausstattung barrierefreier Sanitäreinrichtungen."

      case "GL_4_BFK_HB_HE_NW_5":
        return "Der Plan enthält Angaben zu Größe und Positionierung von erforderlichen Bewegungsflächen."

      case "GL_4_BFK_HB_HE_NW_6":
        return "Der Plan enthält Informationen zum Art und Bestandteilen des Leitsystems im Projektgebäude."

      case "GL_4_Bodenschutzkonzept_BW_1":
        return "Die Planung enthält ein Bodenschutzkonzept, das Maßnahmen zur Erhaltung und nachhaltigen Nutzung von Böden umfasst.\n" +
          "Dieses Konzept beinhaltet Bodenuntersuchungen, Schadensvermeidung, Sanierung geschädigter Böden, nachhaltige Nutzung und die Einhaltung rechtlicher Rahmenbedingungen."

      case "GL_4_Brandschutzkonzept_BY_RP_ST_1":
        return "Die Planung enthält einen detailliertes Brandschutzkonzept, welches alle relevanten Aspekte des konstruktiven Brandschutzes, der Brandbekämpfung und des Brandverhaltens umfasst.\n" +
          "Weiterhin finden sich in dem Konzept genaue Angaben zu Flucht- und Rettungsmöglichkeiten."

      case "GL_4_Brandschutzkonzept_Sonderbauten_else_1":
        return "Die Planung enthält einen detailliertes Brandschutzkonzept für Sonderbauten, der alle relevanten Aspekte der Verhütung, Brandbekämpfung und betriebliche Maßnahmen umfasst, sowie Flucht- und Rettungsmöglichkeiten beschreibt."

      case "GL_4_Brandschutznachweis_else_1":
        return "Die Planung enthält einen detaillierten Brandschutznachweis, der alle relevanten Aspekte der Standsicherheit und Brandbekämpfung umfasst, sowie Flucht- und Rettungsmöglichkeiten beschreibt."

      case "GL_4_Brandschutznachweis_in_Bauvorlagen_SL_1":
        return "Die Planung enthält einen detaillierten Brandschutznachweis, der alle relevanten Aspekte der Standsicherheit und Brandbekämpfung umfasst, sowie Flucht- und Rettungsmöglichkeiten beschreibt."

      case "GL_4_BRI_umbauter_Raum_HB_MV_NW_RP_SH_SL_ST_TH_1":
        return "Die Planung enthält eine Aufstellung und Berechnung zum umbauten Raum nach DIN 277."

      case "GL_4_Darstellung_Zufahrtsverhaeltnisse_ST_1":
        return "Der Plan enthält detaillierte Angaben zu den Zufahrtsverhältnissen der geplanten Baumaßnahme."

      case "GL_4_denkmalschutzrechtliche_Erlaubnis_BY_1":
        return "Die Planung enthält einen Antrag auf denkmalrechtliche Erlaubis für Arbeiten an einem denkmalgeschützten Gebäude oder Gelände.\n" +
          "Damit wird sichergestellt, dass die geplanten Maßnahmen den gesetzlichen Auflagen entsprechen."

      case "GL_4_Einfuegungsnachweis_HE_1":
        return "Die Planung enthält einen Einfügungsnachweis, der die Übereinstimmung eines Bauvorhabens mit geltenden Vorschriften, Regelungen und Anforderungen dokumentiert und bestätigt."

      case "GL_4_Energieausweis_else_1":
        return "Die Planung enthält einen Energieausweis für das Bauvorhaben mit Angabe der entsprechenden Kennwerte nach dem geltenden Gebäudeenergiegesetz (GEG)."

      case "GL_4_ergaenzende_Baubeschreibung_MV_1":
        return "Die Planung enthält Ergänzungen zur Baubeschreibung für land- oder forstwirtschaftliche Bauvorhaben."

      case "GL_4_Ergaenzungsplan_HB_1":
        return "Die Planung enthält einen Ergänzungsplan im richtigen Maßstab."

      case "GL_4_Erklaerung_der_Nachbarschaft_SL_1":
        return "Die Planung enthält einen Einverständniserklärung der Nachbarschaft für die Ausführung des geplanten Bauvorhabens."

      case "GL_4_Erklaerung_zum_barrierefreien_Bauen_SL_1":
        return "Die Planung enthält eine Erklärung zur Erfordernis und Umsetzung der Barrierefreiheit im Bauvorhaben."

      case "GL_4_Erläuterung_gegen_nachbarliche_Zustimmung_RP_1":
        return "Die Planung enthält Erläuterungen und Begründung gegen eine nachbarliche Zustimmung."

      case "GL_4_erneuerbare_Energien_BB_1":
        return "Die Planung enthält Angaben zur Nutzung erneuerbarer Energien nach dem Gebäudeenergiegesetz (GEG)."

      case "GL_4_Erschliessungssicherung_BB_BE_BY_HB_HH_MV_NI_RP_SH_ST_TH_1":
        return "Die Planung enthält eine detaillierte Aufstellung über die Erschließungssicherung hinsichtlich der Aspekte Wasser Ver- und Entsorgung, Energiebereitstellung, sowie Verkehrsanbindung bzw. Zugang zum Projektgrundstück."

      case "GL_4_Erschuetterungsnachweis_BE_BY_SN_SH_1":
        return "Der Plan umfasst detaillierte Angaben zum Erschütterungsnachweis sowie zum Erschütterungsschutz des Projekts.\n" +
          "Dies beinhaltet die Analyse und Berechnung von möglichen Erschütterungen, die während der Bauphase oder durch den Betrieb des Gebäudes verursacht werden könnten, sowie die festgelegten Maßnahmen zur Minimierung dieser Erschütterungen."

      case "GL_4_erteilte_Bescheide_BE_HB_1":
        return "Die Planung enthält Angaben zu erteilten Vorbescheiden."

      case "GL_4_Feuerungsanlagen_BW_RP_SL_1":
        return "Die Planung enthält eine detaillierte Beschreibung zur Art und Umfang der Feuerungsanlagen im Projekt."

      case "GL_4_Flaechen_RP_1":
        return "Die Planung enthält eine Austellung und Berechnung  von Nutz- und Wohnflächen nach DIN 277."

      case "GL_4_Foerderungsnachweis_SH_1":
        return "Die Planung enthält einen Nachweis im Falle öffentlicher Förderungen mit Angaben zur Baumaßnahme."

      case "GL_4_Freiflaechengestaltungsplan_SL_1":
        return "Die Dokumentation enthält Plan zur Darstellung der Freiflächengestaltung mit Angaben zum Vegetationsbestand, Tiefgaragen, Entwässerung, etc."

      case "GL_4_Freiflaechenplan_HB_HE_1":
        return "Darstellung des Bestandgrundstücks inklusive Schutzgebiete, Bepflanzung, Altlasten und Bodeneigenschaften."

      case "GL_4_Freiflaechenplan_HB_HE_2":
        return "Die Planung enthält Angaben über Maßnahmen zur Minderung, Ausgleich und Ersatz im Bezug auf die Umweltauswirkungen des Bauvorhabens."

      case "GL_4_Freiflaechenplan_HB_HE_3":
        return "Die Planung enthält eine Darstellung der Freiflächen hinsichtlich der Flächennutzung und wichtiger Infrastruktur."

      case "GL_4_Gebaeudeklasse_Hoehe_BW_BY_BE_BB_HB_HH_MV_NI_SL_SN_SH_1":
        return "Die Planung enthält eine Angabe zur Gebäudeklasse des Bauvorhabens für die Identifikation der geltenden Brandschutzanforderungen."

      case "GL_4_Geschossberechnung_HB_1":
        return "Die planung enthält eine Berechnung der Geschossfläche zur rechtmäßigen Einstufung der Geschosse."

      case "GL_4_Grundbuchauszug_BE_HB_1":
        return "Die Planung enthält einen Auszug aus dem Grundbuch mit Angaben zum Eigentümer und Grundstück."

      case "GL_4_Grundriss_BW_1":
        return "Der Plan enthält einen Plankopf mit allen relevanten Informationen wie Maßstab, Geschoss, Leistungsphase und Bauherr."

      case "GL_4_Grundriss_BW_2":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen."

      case "GL_4_Grundriss_BW_3":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen sowie umfassende Details zu den einzelnenen Bauteilen und ihren Brandschutzanforderungen."

      case "GL_4_Grundriss_else_1":
        return "Der Plan enthält einen Plankopf mit allen relevanten Informationen wie Maßstab, Geschoss, Leistungsphase und Bauherr."

      case "GL_4_Grundriss_else_2":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen."

      case "GL_4_Grundriss_else_3":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen sowie umfassende Details zu den einzelnenen Bauteilen und ihren Brandschutzanforderungen."

      case "GL_4_Grundriss_HE_1":
        return "Der Plan enthält einen Plankopf mit allen relevanten Informationen wie Maßstab, Geschoss, Leistungsphase und Bauherr."

      case "GL_4_Grundriss_HE_2":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen sowie umfassende Details zu den einzelnenen Bauteilen und ihren Brandschutzanforderungen."

      case "GL_4_Grundriss_NI_1":
        return "Der Plan enthält einen Plankopf mit allen relevanten Informationen wie Maßstab, Geschoss, Leistungsphase und Bauherr."

      case "GL_4_Grundriss_NI_2":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen."

      case "GL_4_Grundriss_NI_3":
        return "Der Plan enthält umfassende Beschriftung der Bauteile und Darstellung der jeweiligen Brandschutzanforderungen."

      case "GL_4_Grundriss_NW_1":
        return "Der Plan enthält einen Plankopf mit allen relevanten Informationen wie Maßstab, Geschoss, Leistungsphase und Bauherr."

      case "GL_4_Grundriss_NW_2":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen."

      case "GL_4_Grundriss_NW_3":
        return "Der Plan enthält umfassende Beschriftung der Bauteile und Darstellung der jeweiligen Brandschutzanforderungen."

      case "GL_4_Grundriss_RP_1":
        return "Der Plan enthält einen Plankopf mit allen relevanten Informationen wie Maßstab, Geschoss, Leistungsphase und Bauherr."

      case "GL_4_Grundriss_RP_2":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen."

      case "GL_4_Grundriss_RP_3":
        return "Der Plan enthält umfassende Beschriftung der Bauteile und Darstellung der jeweiligen Brandschutzanforderungen."

      case "GL_4_Grundriss_SL_1":
        return "Der Plan enthält einen Plankopf mit allen relevanten Informationen wie Maßstab, Geschoss, Leistungsphase und Bauherr."

      case "GL_4_Grundriss_SL_2":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen."

      case "GL_4_Grundriss_SL_3":
        return "Der Plan enthält umfassende Beschriftung der Bauteile und Darstellung der jeweiligen Brandschutzanforderungen."

      case "GL_4_Grundriss_SN_ST_1":
        return "Der Plan enthält einen Plankopf mit allen relevanten Informationen wie Maßstab, Geschoss, Leistungsphase und Bauherr."

      case "GL_4_Grundriss_SN_ST_2":
        return "Der Plan enthält die Darstellung und Beschriftung aller Räume mit Angabe der Raumnutzungen."

      case "GL_4_Grundriss_SN_ST_3":
        return "Der Plan enthält umfassende Beschriftung der Bauteile und Darstellung der jeweiligen Brandschutzanforderungen."

      case "GL_4_Grundstuecksentwaesserung_1_BW_RP_SH_SL_1":
        return "Die Planung enthält einen Entwässerungsplan, inkl. Darstellung aller relevanter Informationen zur Entwässerung des Gebäudes und des Grundstücks."

      case "GL_4_Grundstuecksentwaesserung_2_BW_RP_SH_SL_1":
        return "Erläuterungen zur Entwässerung des Gebäudes und des Grundstücks in Form eines Entwässerungskonzepts."

      case "GL_4_Herstellungskosten_BE_NI_NW_RP_ST_1":
        return "Die Planung enthält eine detaillierte Zusammenstellung der ermittelten Herstellungskosten gemäß DIN 276 für das betrachtete Projekt."

      case "GL_4_Hochwasserrisiko_RP_1":
        return "Die Planung enthält eine Zusammenstellung und Bewertung des Hochwasserrisikos für das betrachtete Projekte unter Berücksichtigung Wasserhaushaltsgesetzes (WHG)."

      case "GL_4_Hygienegutachten_HE_1":
        return "Die Planung enthält ein Hygienegutachtens für das betrachteten Projekt."

      case "GL_4_Kriterienkatalog_BY_BE_BB_HB_MV_NW_SL_ST_SH_TH_1":
        return "Die Planung enthält eine Zusammenstellung projektrelevanter Kriterien der Standsicherheit des Bauvorhabens."

      case "GL_4_Laermschutzkonzept_HE_1":
        return "Die Planung enthält ein Lärmschutzkonzept, das detaillierte Erläuterungen zur Lärmentwicklung während der Bauphase des betrachteten Projekts umfasst.\n" +
          "Dieses Konzept beschreibt die zu erwartenden Lärmquellen, deren Intensität und Dauer sowie die Maßnahmen zur Minimierung der Lärmbelastung für die Umgebung."

      case "GL_4_Lageplan_else_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Lageplan_else_2":
        return "Der Plan enthält Angaben zum Bauvorhaben, sowie zu den verantwortlichen Projektbeteiligten."

      case "GL_4_Lageplan_else_3":
        return "Der Plan enthält Angaben zur Nutzung und der Geschossigkeit des Gebäudes."

      case "GL_4_Lageplan_else_4":
        return "Der Plan enthält Angaben zu Flurstück und Flustücksnummer des Projektgrundstücks."

      case "GL_4_Lageplan_else_5":
        return "Der Plan enthält Angabe zu Stellplätze für Fahrzeuge und Fahrräder, die für die Nutzung des Projekts auf dem Grundstück zur Verfügung gestellt werden."

      case "GL_4_Lageplan_else_6":
        return "Der Plan enthält Angaben zur Höhenlage des Grundstücksgeländes und des Dachs bezüglich der Dachhöhe und Dachform."

      case "GL_4_Lageplan_else_7":
        return "Der Plan enthält Angaben zur Grundstücks- und Bebauungsgrenze, sowie zu Abstandsflächen des betrachteten Projekts."

      case "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH_1":
        return "Der Plan enthält Angaben zu Informationen über Grenzen, Abmessungen und Lage des Grundstücks."

      case "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH_3":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Lueftungsanlagen_Feuerungsanlagen_HE_1":
        return "Der Plan enthält Angaben zu den Lüftungsanlagen des Gebäudes.\n" +
          "Diese Anlagen und die dazugehörigen Leitungen müssen sowohl betriebssicher als auch brandsicher geplant werden und dürfen den ordnungsgemäßen Betrieb der Feuerungsanlagen nicht beeinträchtigen."

      case "GL_4_Lueftungsanlagen_Feuerungsanlagen_HE_2":
        return "Der Plan enthält Angaben zu den Feuerungsanlagen des Gebäudes.\n" +
          "Diese Anlagen und die dazugehörigen Leitungen müssen sowohl betriebssicher als auch brandsicher geplant werden, und dürfen auch sonst nicht zu Gefahren, unzumutbaren Nachteilen oder unzumutbaren Belästigungen führen."

      case "GL_4_Mass_der_baulichen_Nutzung_else_1":
        return "Die Planung enthält umfassende Angaben zu Maßen der baulichen Nutzung."

      case "GL_4_Mobilitaetsbedarf_HB_1":
        return "Angaben zum Bedarf der Verkehrbewegungen, um die Mobilität zu gewährleisten."

      case "GL_4_Nachbarliche_Zustimmung_BY_HB_RP_SN_TH_1":
        return "Die Planung enthält detaillierte Angaben über die nachbarliche Zustimmung.\n" +
          "Diese umfassen die Einwilligung des Nachbarn hinsichtlich des Bauvorhabens, einschließlich spezifischer Bedingungen und Auflagen, die im Rahmen der Zustimmung vereinbart wurden.\n" +
          "Zudem werden die relevanten rechtlichen Grundlagen und etwaige schriftliche Erklärungen des Nachbarn dokumentiert."

      case "GL_4_Nachweis_barrierefreie_Stellplaetze_BE_1":
        return "Die Planung enthält alle relevanten Informationen zu barreierefreien Stellplätzen im Bezug auf die notwendige Anzahl für den jeweiligen Gebäudetyp."

      case "GL_4_Nachweis_Stellplaetze_fuer_Fahrraeder_BE_1":
        return "Die Planung enthält alle relevanten Informationen zu Fahrradstellplätzen im Bezug auf die notwendige Anzahl für den jeweiligen Gebäudetyp und die örtlichen Transfermöglichkeiten."

      case "GL_4_oeffentlich_rechtliche_Vorschriften_HB_HH_SN_ST_1":
        return "Die Planung enthält Angaben zu öffentlich rechtlichen Vorschriften die das Bauvorhaben betreffen."

      case "GL_4_oeffentliche_Bekanntmachung_BY_1":
        return "Die Planung umfasst die öffentliche Bekanntmachung von Informationen über ein Bauvorhaben oder eine Baugenehmigung.\n" +
          "Dabei werden detaillierte Angaben wie das Aktenzeichen, eine Beschreibung des Bauvorhabens, die genaue Lage des Baugrundstücks sowie benachbarter Grundstücke, etwaige Abweichungen von baurechtlichen Vorgaben, eine Rechtsbehelfsbelehrung und die Kontaktdaten des zuständigen Bauordnungsamtes bereitgestellt."

      case "GL_4_Oeffentlichkeitsbeteiligung_BY_1":
        return "Die Planung enthält Angaben zur Öffentlichkeitsbeteiligung, welche die Einbeziehung von Zielgruppen bei Planungsentscheidungen von Bauvorhaben detailliert beschreiben.\n" +
          "Dies umfasst Methoden und Strategien, um die betroffene Öffentlichkeit in den Entscheidungsprozess einzubinden und deren Feedback zu berücksichtigen."

      case "GL_4_oertliche_Bauvorschrift_BW_BY_BB_HB_HE_MV_NI_NW_RP_SL_SN_ST_SH_TH_1":
        return "Die Planung enthält Angaben zu den für den Ort des Bauvorhabens geltenden Vorschriften und Regelungen.\n" +
          "Zudem umfasst die Planung Informationen zu den beteiligten Behörden und Amtspersonen sowie zur Bauordnung des betreffenden Bundeslandes."

      case "GL_4_Perspektiven_BE_1":
        return "Der Plan enthält eine perspektivische Darstellung des Projektgebäudes."

      case "GL_4_Pruefverzicht_Brandschutznachweis_HB_1":
        return "Die Planung enthält eine Erklärung zum Prüfverzicht des Brandschutznachweises, einschließlich der Begründung für diesen Verzicht."

      case "GL_4_Pruefverzicht_Standsicherheitsnachweis_HB_1":
        return "Die Planung enthält eine Erklärung zum Prüfverzicht des Standsicherheitsnachweises, einschließlich der Begründung für diesen Verzicht."

      case "GL_4_qualifizierter_Lageplan_HB_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_qualifizierter_Lageplan_HB_2":
        return "Der Plan enthält Angaben zur Grundstücksnummer und der Flurbezeichnung des Projektgrundstücks."

      case "GL_4_qualifizierter_Lageplan_HB_3":
        return "Der plan enthält Angaben zu Grundstücksgrenzen und Abstandsflächen."

      case "GL_4_qualifizierter_Lageplan_HB_4":
        return "Der Plan enthält Höhenbezüge für alle relevanten Grunstücksbereiche."

      case "GL_4_qualifizierter_Lageplan_HB_5":
        return "Der Plan enthält Angaben zum Baumbestand des Projektgrundstücks."

      case "GL_4_qualifizierter_Lageplan_NI_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_qualifizierter_Lageplan_NI_2":
        return "Der Plan enthält Angaben zur Grundstücksnummer und der Flurbezeichnung des Projektgrundstücks."

      case "GL_4_qualifizierter_Lageplan_NI_3":
        return "Der Plan enthält Angaben zu Grundstücksgrenzen und Abstandsflächen."

      case "GL_4_qualifizierter_Lageplan_NI_4":
        return "Der Plan enthält Angaben zu den Beteiligten des Projekts."

      case "GL_4_Rohbaukosten_NI_NW_1":
        return "Die Planung enthält Angaben zu den Rohbaukosten des Projekts, die detailliert die Kostengruppe 300, den Rohbauwert und die Berechnungen umfassen."

      case "GL_4_Sachverstaendigenbescheinigung_Brandschutz_BE_BY_MV_NW_RP_SH_1":
        return "Die Planung enthält eine Sachverständigenbescheinigung für den Brandschutz, die die Vollständigkeit und Richtigkeit der baulichen Anlagen gemäß den Brandschutzvorschriften bestätigt."

      case "GL_4_Sachverstaendigenbescheinigung_Schallschutz_NW_1":
        return "Die Planung enthält eine Sachverständigenbescheinigung zum Schallschutz, die durch ein Gutachten die Einhaltung der entsprechenden baulichen Anlagen gemäß den Schallschutzvorschriften bestätigt."

      case "GL_4_Sachverstaendigenbescheinigung_Waermeschutz_NW_1":
        return "Die Planung umfasst den Sachverständigennachweis zum Wärmeschutz durch qualifizierte Wärmeschutzplaner*innen, der die wärmeschutzrelevanten Aspekte des betrachteten Projekts detailliert erläutern."

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_BB_BE_BY_MV_NW_SH_SN_TH_1":
        return "Die Planung umfasst den Sachverständigennachweis zur Standsicherheit durch Tragwerksplaner*innen, der die standsicherheitsrelevanten Aspekte des betrachteten Projekts unter Berücksichtigung der Baumaßnahme, sowie äußerer Einwirkungen am Standort erläutert.\n" +
          "Es wird eine kurze Beschreibung des Bauvorhabens sowie eine Darstellung der Verantwortlichkeiten und des Kriterienkatalogs gegeben."

      case "GL_4_Sachverstaendigennachweis_Standsicherheit_else_1":
        return "Die Planung enthält den Sachverständigennachweis zur Standsicherheit.\n" +
          "Dieser umfasst Erklärungen von Tragwerksplaner*innen zu allen standsicherheitsrelevanten Aspekten des betrachteten Projekts."

      case "GL_4_Schallschutznachweis_else_1":
        return "Die Planung enthält detaillierte Angaben zum Nachweis des erforderlichen Schallschutzes für das betrachtete Projekt.\n" +
          "Dabei werden die schallschutztechnischen Eigenschaften einzelner Bauteile berücksichtigt, um sicherzustellen, dass die gesetzlichen Anforderungen und Normen zum Schallschutz eingehalten werden.\n" +
          "Diese Angaben umfassen unter anderem die verwendeten Materialien, Konstruktionsweisen und spezifischen Maßnahmen zur Lärmminderung."

      case "GL_4_Schalltechnische_Untersuchung_else_1":
        return "Die Planung enthält eine schalltechnische Untersuchung, die die Schallemission des Projekts entsprechend der geplanten Nutzung des Gebäudes beschreibt.\n" +
          "Diese Untersuchung analysiert die potenziellen Lärmbelastungen und deren Auswirkungen auf die Umgebung."

      case "GL_4_Schnitt_BB_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Schnitt_BB_2":
        return "Der Plan enthält Angaben zu den Höhenlagen des Geländes und der Geschosse bezogen auf die lichten Raummaße.\n" +
          "Dabei können die Geschosshöhen als Rohbau- bzw. Fertigmaße  angegeben werden."

      case "GL_4_Schnitt_BB_3":
        return "Der Plan enthält die Darstellung von Treppen und/oder Rampen, sowie die relevanten Angaben zu deren Abmessungen und Steigungen."

      case "GL_4_Schnitt_BB_4":
        return "Der Plan enthält Informationen zu Verlauf und Höhenlage der Geländeoberfläche."

      case "GL_4_Schnitt_BB_5":
        return "Der Plan enthält Angaben zu Lage und Verlauf des Grundwasserspiegels."

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_2":
        return "Der Plan enthält Angaben zu den Höhenlagen des Geländes und der Geschosse bezogen auf die lichten Raummaße.\n" +
          "Dabei können die Geschosshöhen als Rohbau- bzw. Fertigmaße  angegeben werden."

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_3":
        return "Der Plan enthält die Darstellung von Treppen und/oder Rampen sowie die relevanten Angaben zu deren Abmessungen und Steigungen."

      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_4":
        return "Der Plan enthält Informationen zu Verlauf und Höhenlage der Geländeoberfläche."

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_2":
        return "Der Plan enthält Angaben zu den Höhenlagen des Geländes und der Geschosse bezogen auf die lichten Raummaße.\n" +
          "Dabei können die Geschosshöhen als Rohbau- bzw. Fertigmaße  angegeben werden."

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_3":
        return "Der Plan enthält die Darstellung von Treppen und/oder Rampen, sowie die relevanten Angaben zu deren Abmessungen und Steigungen."

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_4":
        return "Der Plan enthält Informationen zu Verlauf und Höhenlage der Geländeoberfläche."

      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_5":
        return "Die Planung enthält Angaben zur Gebäudehöhe, einschließlich der Höhenangaben zu maßgebenden Punkten der Dachkonstruktion wie First, Traufen und Attika."

      case "GL_4_Schnitt_NW_1":
        return "Der Plan enthält eine Angaben des Maßstabs im richtigen Format."

      case "GL_4_Schnitt_NW_2":
        return "Der Plan enthält Angaben zu den Höhenlagen des Geländes und der Geschosse bezogen auf die lichten Raummaße.\n" +
          "Dabei können die Geschosshöhen als Rohbau- bzw. Fertigmaße  angegeben werden."

      case "GL_4_Schnitt_NW_3":
        return "Der Plan enthält Informationen zu Verlauf und Höhenlage der Geländeoberfläche."

      case "GL_4_Schnitt_NW_4":
        return "Die Planung enthält Angaben zur Gebäudehöhe, einschließlich der Höhenangaben zu maßgebenden Punkten der Dachkonstruktion wie First, Traufen und Attika."

      case "GL_4_Spielplatznachweis_else_1":
        return "Die Planung enthält Angaben zum Spielplatzbedarf und zur Spielplatzplanung in der nahen Umgebung.\n" +
          "Zudem werden mögliche Ablösungsvereinbarungen beschrieben, die im Zusammenhang mit dem Spielplatznachweis stehen."

      case "GL_4_Standsicherheitsnachweis_BW_1":
        return "Angaben zur Standsicherheit und zu statischen Berechnungen des betrachteten Projekts in einem prüfbaren Standsicherheitsnachweis."

      case "GL_4_Standsicherheitsnachweis_else_1":
        return "Die Planung enthält Angaben zur Standsicherheit und zu statischen Berechnungen des betrachteten Projekts in einem prüfbaren Standsicherheitsnachweis."

      case "GL_4_Statistik_else_1":
        return "Die Planung enthält Angaben über das Bauvorhaben zur Übermittlung der Statistik und der Beurteilung der Wirtschaftsentwicklung im Bausektor."

      case "GL_4_Stellplatznachweis_else_1":
        return "Die Planung enthält detaillierte Informationen über die Anzahl und Lage der Stellplätze für Kraftfahrzeuge.\n" +
          "Diese Angaben sind wichtig, um die ausreichende Versorgung und Erreichbarkeit für zukünftige Nutzer sicherzustellen."

      case "GL_4_Stellungnahme_BE_1":
        return "Die Planung enthält eine Stellungnahme zu städteplanerischen und planungsrechtlichen Aspekten des Bauvorhabens."

      case "GL_4_Stellungnahme_Bodenschutzbehoerde_HB_1":
        return "Die Planung enthält die schriftliche Stellungnahme der Bodenschutzbehörde im Bezug auf Eingriffe in den Baugrund.\n" +
          "Diese Stellungnahme umfasst Empfehlungen und Auflagen, die zum Schutz des Bodens und zur Vermeidung von Kontaminationen erforderlich sind."

      case "GL_4_Stoerfallbetrieb_RP_1":
        return "Die Planung beinhaltet Angaben zum geplanten Vorhaben und dessen Gefahrenpotential.\n" +
          "Hier werden mögliche Risiken und erforderliche Schutzmaßnahmen detailliert beschrieben, um die Sicherheit während der Bau- und Nutzungsphase zu gewährleisten."

      case "GL_4_Stoerfallbetrieb_RP_2":
        return "Die Planung enthält Angaben über gefährliche Stoffe, die im Störfallbetrieb verwendet oder gelagert werden.\n" +
          "Diese Informationen umfassen die Art, Menge und Lagerbedingungen der Stoffe sowie die vorgesehenen Sicherheitsmaßnahmen zur Verhinderung und Bewältigung möglicher Unfälle."

      case "GL_4_Stoerfallbetrieb_RP_3":
        return "Die Planung enthält Angaben zum Sicherheitsabstand zum benachbarten Schutzobjekt.\n" +
          "Diese Informationen umfassen die vorgeschriebenen Mindestabstände sowie die Maßnahmen zur Sicherstellung der Einhaltung dieser Abstände, um den Schutz des benachbarten Objekts zu gewährleisten."

      case "GL_4_Uebereinstimmungserklaerung_BE_1":
        return "Die Planung enthält eine Übereinstimmungserklärung, die die fachgerechte Einhaltung der Bestimmungen bezüglich einzelner Bauprodukte und Herstellungsgegenstände bestätigt.\n" +
          "Diese Erklärung stellt sicher, dass alle verwendeten Materialien und Produkte den relevanten Vorschriften und Standards entsprechen."

      case "GL_4_Uebersichtsplan_HE_RP_SH_1":
        return "Der Plan enthält Angaben zum des Maßstab im richtigen Format."

      case "GL_4_Uebersichtsplan_HE_RP_SH_2":
        return "Der Übersichtsplan enthält Angaben zur Grundstücksnummer und  Flurbezeichnung des Projektgrundstücks."

      case "GL_4_Unbedenklichkeitsbescheinigung_Feuerwehr_HB_1":
        return "Die Planung enthält Angaben der örtlich ansässigen Feuerwehr bezüglich der Unbedenklichkeit der geplanten Personenrettungsmaßnahmen des betrachteten Projekts.\n" +
          "Diese Bescheinigung bewertet die Rettungswege, Evakuierungspläne und allgemeinen Brandschutzmaßnahmen im Hinblick auf ihre Übereinstimmung mit den geltenden Sicherheitsstandards und Vorschriften."

      case "GL_4_Unterlagen_ueber_Brennstofflagerung_NI_1":
        return "Die Planung enthält detaillierte Informationen zur Lagerung brennbarer Stoffe, einschließlich Art und Menge der Materialien sowie der vorgesehenen Lagerorte."

      case "GL_4_UVP_else_1":
        return "Die Planung enthält Angaben zu Art und Umfang des betrachteten Gebäudes inkl. damit korrespondierender Auswirkungen auf die Umwelt."

      case "GL_4_UVP_else_2":
        return "Die Planung beschreibt detailliert die angrenzende Umweltsituation des betrachteten Gebäudes, sowie Benennung entsprechendes Umweltfaktoren."

      case "GL_4_UVP_else_3":
        return "Die Planung beschreibt detailliert die Merkmale des betrachteten Standorts und damit korrespondierender Maßnahmen bezüglich der Einflüsse auf die Umwelt."

      case "GL_4_UVP_else_4":
        return "Die Planung beschreibt detailliert die Umweltauswirkungen des betrachteten Gebäudes auf die beschriebenen Umweltfaktoren."

      case "GL_4_UVP_else_5":
        return "Die Planung beschreibt detailliert die verwendetet Materialien und potentielle Gefährdung des Bauvorhabens, sowie Angaben zu Ergebnisse festgestellter Schutzgüter."

      case "GL_4_Vereinfachtes_Abfallverwertungskonzept_BW_1":
        return "Die Planung enthält detaillierte Angaben zur Abfallverwertung während der Bauphase des betrachteten Projekts.\n" +
          "Diese sind in einem schlüssigen Konzept zusammengefasst, das Maßnahmen zur nachhaltigen Entsorgung und Wiederverwertung von Bauabfällen darlegt."

      case "GL_4_Vergleichsberechnung_MV_1":
        return "Die Planung beinhaltet eine Vergleichsrechnung für das betrachtete Projekt.\n" +
          "Diese legt dar, ob das Vorhaben wirtschaftlich zumutbar oder unzumutbar ist, indem die anfallenden Kosten den zu erwartenden Erlösen gegenübergestellt werden."

      case "GL_4_Vertretervollmacht_BB_BE_HE_MV_NW_SN_ST_1":
        return "Die Planung enthält Angaben zur Vertretungsvollmacht im betrachteten Projekt und legt dar, wer neben der Bauherrenschaft in welchem Ausmaß Entscheidungsbefugnisse hat.\n" +
          "Häufig erhalten Architekt*innen bzw. Planer*innen eine Vertretungsvollmacht."

      case "GL_4_Waermeschutznachweis_else_1":
        return "Die Planung entält Angaben zum Gesamtenergiebedarf des betrachteten Projekts, der für die technischen Anlagen (Heizung, Lüftung, Warmwasserbereitung, Kühlung) zur Verfügung gestellt werden muss."

      case "GL_4_Waermeschutznachweis_else_2":
        return "Die Planung enthält Informationen zum sommerlichen Wärmeschutz.\n" +
          "Hierbei wird die Reduzierung des Sonneneintrags als Faktor berücksichtigt, um den Gesamtenergiebedarf des Projekts zu senken."

      case "GL_4_Waermeschutznachweis_else_3":
        return "Die Planung entält Angaben zu Nachweisen einzelner Bauteile bezüglich wärmeschutztechnischer Aspekte."

      case "GL_4_wassergefaehrdende_Stoffe_else_1":
        return "Die Planung entält Angaben zur Einstufung und Kennzeichnung möglicher wassergefährdender Stoffe, die während der Bauphase sowie der Betriebsphase des betrachteten Projekts verwendet werden."

      //LPH 5

      case "GL_5_A_1_1":
        return "Erarbeiten der Ausführungsplanung mit den für die Ausführung relevanten Angaben zum Rohbau basierend auf der Genehmigungsplanung und unter Berücksichtigung der technischen und statischen Anforderungen sowie der Eigenschaften der ausgewählten Materialien."

      case "GL_5_A_2_1":
        return "Erarbeiten der Ausführungsplanung mit den für die Ausführung relevanten Angaben zum Ausbau basierend auf der Rohbauplanung, insbesondere hinsichtlich der Beläge, Dämmungen, Oberflächen sowie des Wand- und Dachaufbaus."

      case "GL_5_A_4_1":
        return "Erarbeiten der Ausführungsplanung mit den für die Ausführung relevanten Angaben zu Materialien in den Ausführungsplänen"

      case "GL_5_B_1_1":
        return "Erarbeiten von Grundrissen für die Ausführungsplanung mit Angaben zu Raumnutzungen, Bauteilen, Maßen, Tragwerk, Materialien, TGA, Öffnungen wie Türen, Fenster und Treppen sowie zur Einrichtung und Ausstattung.\n" +
          "Zudem muss ein Plankopf vorhanden sein, der sowohl allgemeine Projektinformationen als auch spezifische Angaben zum gezeichneten Plan enthält."

      case "GL_5_B_2_1":
        return "Erarbeiten von Schnitten für die Ausführungsplanung mit Angaben zur Schnittrichtung und den Höhenangaben aller Bauteile und Materialien. Zudem muss ein Plankopf vorhanden sein, der sowohl allgemeine Projektinformationen als auch spezifische Angaben zum gezeichneten Plan enthält."

      case "GL_5_B_3_1":
        return "Erarbeiten von Detailzeichnungen für die Ausführungsplanung mit Angaben zur Art des Details, den Maßen wie Höhe, Breite und Länge der Bauteile sowie den verwendeten Materialien.\n" +
          "Zudem muss ein Plankopf vorhanden sein, der sowohl allgemeine Projektinformationen als auch spezifische Angaben zum gezeichneten Plan enthält."

      case "GL_5_B_4_1":
        return "Erarbeiten von Ausbauplänen und -details für die Ausführungsplanung mit Angaben zur Art der Details, den Bauteilen sowie den verwendeten Materialien wie Dämmungen, Folien und Beläge.\n" +
          "Zudem muss ein Plankopf vorhanden sein, der sowohl allgemeine Projektinformationen als auch spezifische Angaben zum gezeichneten Plan enthält."

      case "GL_5_B_5_1":
        return "Erarbeiten von Ansichten für die Ausführungsplanung mit Angaben zur Ausrichtung der Ansicht, den Höhen der wesentlichen Außenmaße des Gebäudes sowie den Positionen der Bauteile wie Fenster und Türen.\n" + "" +
          "Zudem muss ein Plankopf vorhanden sein, der sowohl allgemeine Projektinformationen als auch spezifische Angaben zum gezeichneten Plan enthält."

      case "GL_5_B_6_1":
        return "Erarbeiten von Gründungs- und Fundamentenpläne für die Ausführungsplanung mit Angaben zur Art und Position der Gründungen und Fundamente.\n" +
          "Zudem muss ein Plankopf vorhanden sein, der sowohl allgemeine Projektinformationen als auch spezifische Angaben zum gezeichneten Plan enthält."

      case "GL_5_C_1":
        return "Koordination und Integration der Ausführungsplanung der TGA-Fachplanung mit Angaben zu TGA-Anlagen wie Lüftung, Strom und Heizung sowie zu Leitungen wie Regenrohren, Elektrokabeln und Gasleitungen."

      case "GL_5_C_2":
        return "Koordination und Integration der Ausführungsplanung der Tragwerksplanung mit Angaben zu den Bauteilen der Konstruktion hinsichtlich ihrer Eigenschaften wie Festigkeit, Elastizität und Expositionsklasse sowie den Abmessungen der Bauteile in Höhe, Breite, Länge oder Durchmesser."

      case "GL_5_C_3":
        return "Koordination und Integration der Ausführungsplanung der Tragwerksplanung und TGA-Planung mit Angaben zu den Öffnungen wie Wand- und Deckendurchbrüchen, Schächten, Aussparungen, Revisionsöffnungen und Rohrdurchführungen."

      case "GL_5_D_1_1":
        return "Unterteilung der Projekttermine in die wesentlichen Vorgänge der Planung und des Bauablaufs, wie beispielsweise Ausführungsplanung, Vergabe, Ausführungsvorbereitung, Ausführung, Projektabschluss und andere relevante Schritte."

      case "GL_5_D_2_1":
        return "Kalkulierte Festlegung der Dauern und Fristen der alle Projektvorgänge wie beispielsweise Planungsabläufe, Meilensteine, Vergabe- und Genehmigungsverfahren sowie Bauphasen."

      case "BL_5_1_1":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmalen und Tatbeständen des Bauobjekts bezogen auf Lage und Standort des Baugrundstückes.\n" +
          "Dies beinhaltet Angaben zur geografischen Lage des Objekts sowie seiner Umgebung wie Geländemerkmale oder besondere geografische Gegebenheiten.\n" +
          "Details zur Infrastruktur der Umgebung wie Straßen, Versorgungsleitungen und anderen wichtigen Einrichtungen."

      case "BL_5_1_2":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf das Baugrundstück.\n" +
          "Informationen über die Beschaffenheit des Bodens, mögliche Einschränkungen oder Besonderheiten des Geländes sowie die Größe, Form und spezifische Merkmale des Grundstücks, einschließlich eventueller Grenzen oder Geländekonturen."

      case "BL_5_1_3":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die Erschließung.\n" +
          "Informationen über die vorhandene Infrastruktur, wie Straßenanbindung, Wasser-, Strom- und Abwasserversorgung sowie weitere technische Anbindungen wie Telekommunikationsnetze oder Gasleitungen, die für die Nutzung relevant sind."

      case "BL_5_1_4":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die Baukonstruktionen.\n" +
          "Details über die Bauweise, Materialien, strukturellen Merkmale und technischen Standards des Gebäudes oder der Baustruktur, einschließlich der tragenden Elemente, Dachkonstruktion, Wände, Bodenbeläge und anderen relevanten Bauteile."

      case "BL_5_1_5":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf die technischen Anlagen.\n" +
          "Details zu den vorhandenen technischen Installationen im Gebäude, wie Heizungs- und Kühlsysteme, Sanitäranlagen, Elektroinstallationen, Sicherheitssysteme oder weitere spezielle technische Ausstattungen."

      case "BL_5_1_6":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf den Brandschutz.\n" +
          "Informationen über vorhandene Brandschutzeinrichtungen wie Feuermelder, Feuerlöscher, Brandabschnitte oder Fluchtwege im Gebäude."

      case "BL_5_1_7":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf Außenanlagen.\n" +
          "Angaben über die Gestaltung und Ausstattung des Außenbereichs, einschließlich Gartenanlagen, Terrassen, Gehwege, Parkplätze oder andere spezifische Merkmale der Außenanlagen."

      case "BL_5_1_8":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmale und Tatbestände des Bauobjekts bezogen auf Wirtschaftlichkeit.\n" +
          "Informationen zu den Gesamtkosten des Objekts, betriebliche und Instandhaltungskosten sowie Einschätzungen zur Wirtschaftlichkeit durch mögliche Renditen oder langfristige Investitionsaussichten."

      case "BL_5_1_9":
        return "Erläuterung zu allen wesentlichen planungsrelevanten Merkmalen und Tatbeständen des Bauobjekts bezogen auf allgemeine Angaben.\n" +
          "Informationen zu Vorbemerkungen, Logistik, Baucontrolling, Terminplanung, Bauablauf, Arbeitsvorbereitung, Sicherheitskoordination, Dokumentation und Abfallentsorgung."

      case "BL_5_3_1":
        return "Raumbücher enthalten spezifische Angaben zu jedem Raum im Gebäude.\n" +
          "Dies beinhaltet die klare Benennung und Beschreibung der Nutzung jedes Raumes, präzise Maßangaben wie Raumflächen und Raumhöhen sowie die genaue Verortung der Räume innerhalb des Gebäudes."

      case "BL_5_3_2":
        return "Die Fortschreibung der Raumbücher umfasst eine detaillierte Beschreibung von Ausbau- und Ausstattungselementen, wie Bodenbelägen, Oberflächen und Sanitäreinrichtungen, sowie die Erfassung von Raumkapazitäten und technischen Anlagen.\n" +
          "Dies beinhaltet die genaue Dokumentation der verwendeten Materialien und Einrichtungsgegenstände, sowie die Spezifikation von Heizungs-, Klima-, Lüftungs- und Sanitäranlagen (HKLS) und anderen technischen Systemen."

      case "BL_5_4_1":
        return "Die Festlegung der Kennzeichnungsziele und die Definition der einzuhaltenden Standards bzw. Normen.\n" +
          "Dies umfasst die Bestimmung der Ziele, die durch das Kennzeichnungssystem erreicht werden sollen, wie etwa die Verbesserung der Anlagenverwaltung und Wartungseffizienz.\n" +
          "Zudem werden die relevanten Standards und Normen identifiziert und festgelegt, um eine einheitliche und konsistente Kennzeichnung aller Anlagenteile sicherzustellen"

      case "BL_5_4_2":
        return "Identifikation, Strukturierung (z.B. nach DIN 6779 oder DIN 276) und (tabellarische) Zusammenstellung der zu kennzeichnenden Anlagen, Komponenten und Systeme mit Klartext bzw. Beschreibung"

      case "BL_5_4_3":
        return "Entwicklung eines Kennzeichnungsschemas mit Ortsschlüssel und Anlagenschlüssel (AKS-Schlüssel), das die Identifizierung und Klassifizierung der Anlagen ermöglicht"

      default:
        return value
    }

  }
}

@Pipe({
  name: 'phrases'
})
export class PhrasesPipe implements PipeTransform {
  /**
   * Transform Enum Value to String
   * @param value PartialServicesEnum Value
   */
  transform(value: string): string {
    switch (value) {
      //LPH 1
      case "GL_1_A_1":
        return "<ul>\n" +
          "<li>• Planungsziele wie z.B. <b>Projektaufgabe</b>, <b>Leistungsumfang</b>, <b>technisch</b>, <b>gestalterisch</b> und <b>funktional</b></li>\n" +
          "<li>• Projektziele wie z.B. <b>Bauziel</b>, <b>zeitlich</b>, <b>ökonomisch</b> und <b>ökologisch</b></li>\n" +
          "<li>• Nutzung wie z.B. <b>Objekttyp</b>, <b>Nutzungstyp</b>, <b>Wohngebäude</b>, <b>Gewerbe</b> und <b>Büro</b></li>\n" +
          "<li>• Standort wie z.B. <b>Baugrundstück</b> und <b>Bauort</b></li>\n" +
          "</ul>"
      case "GL_1_A_2":
        return "<ul>\n" +
          "<li>• Baurechtliche Grundlagen wie z.B. <b>Planungsrecht</b>, <b>Bebauungsplan</b>, <b>Grundbuch</b>, <b>Festsetzungen</b>, <b>Einschränkungen</b> und <b>Dienstbarkeiten</b></li>\n" +
          "<li>• Standortrahmenbedingungen wie z.B. <b>Stadt</b>, <b>Standort</b>, <b>Lage</b>, <b>Adresse</b>, <b>Grundstück</b> und <b>Grundstückskauf</b></li>\n" +
          "</ul>"
      case "GL_1_B_1":
        return "<ul>\n" +
          "<li>• Ortbesichtigung wie z.B. <b>Ort</b>, <b>Ortstermin</b>, <b>Standort</b>, <b>Grundstück</b> und <b>Flurstück</b></li>\n" +
          "<li>• Dokumentation wie z.B. <b>Protokoll</b>, <b>Notizen</b> und <b>Fotosammlung</b></li>\n" +
          "</ul>"
      case "GL_1_C_1":
        return "<ul>\n" +
          "<li>• Beratung wie z.B. <b>besondere Leistungen</b>, <b>Kosten</b>, <b>Termine</b>, <b>Risiken</b>, <b>Ziele</b> und <b>Grundstück</b></li>\n" +
          "</ul>"
      case "GL_1_C_2":
        return "<ul>\n" +
          "<li>• Vergabe wie z.B. <b>Vergabeart</b>, <b>Ausschreibung</b>, <b>Verfahren</b>, <b>Verhandlung</b>, <b>Wettbewerb</b> und <b>Lose</b></li>\n" +
          "</ul>"
      case "GL_1_C_3":
        return "<ul>\n" +
          "<li>• Baugrundbelastung wie z.B. <b>Schadstoffbelastung</b>, <b>Kampfmittel</b>, <b>Fundstelle</b>, <b>Luftbilder</b> und <b>Untersuchungsgebiet</b></li>\n" +
          "<li>• Baugrundtauglichkeit wie z.B. <b>Baugrund</b>, <b>Boden</b>, <b>Bauland</b>, <b>Tauglichkeit</b> und <b>Eignung</b></li>\n" +
          "</ul>"
      case "GL_1_D_1":
        return "<ul>\n" +
          "<li>• Auswahlmechanismen wie z.B. <b>Auftragnehmer*in</b>, <b>Fachplaner*in</b>, <b>Architekt*in</b>, <b>Ingenieur*in</b>, <b>Planer*in</b> und <b>Gutachter*in</b></li>\n" +
          "</ul>"
      case "GL_1_D_2":
        return "<ul>\n" +
          "<li>• Vertagsform wie z.B. <b>Vertrag</b>, <b>Verhältnis</b> und <b>Zuständigkeit</b></li>\n" +
          "</ul>"
      case "GL_1_E_1":
        return ""
      case "BL_1_1_1":
        return "<ul>\n" +
          "<li>• Räumlich wie z.B. <b>Projektziele</b>, <b>Planungsziele</b>, <b>Projektinhalt</b> und <b>Bauvorhaben</b></li>\n" +
          "<li>• Finanziell wie z.B. <b>Kosten</b>, <b>Gesamtkosten</b>, <b>Finanzrahmen</b> und <b>Baubudget</b></li>\n" +
          "<li>• Zeitlich wie z.B. <b>Bauzeit</b>, <b>Projekttermine</b>, <b>Projektstart</b> und <b>Projektabschluss</b></li>\n" +
          "</ul>"
      case "BL_1_1_2":
        return "<ul>\n" +
          "<li>• Projektziele wie z.B. <b>Ziele</b>, <b>Vorhaben</b>, <b>ökologisch</b>, <b>ökonomisch</b>, <b>zeitlich</b>, <b>funktional</b>, <b>technisch</b>, <b>soziokulturell</b> und <b>gestalterisch</b></li>\n" +
          "</ul>"
      case "BL_1_1_3":
        return "<ul>\n" +
          "<li>• Bedarfsangaben wie z.B. <b>Flächenbedarf</b>, <b>Nutzungstyp</b>, <b>Einheiten</b> und <b>Funktion</b></li>\n" +
          "</ul>"
      case "BL_1_1_4":
        return "<ul>\n" +
          "<li>• Bedarfsplan wie z.B. <b>Fläche</b>, <b>Quadratmeter</b>, <b>m<sup>2</sup></b>, <b>Plätze</b>, <b>Räume</b> und <b>Bereich</b></li>\n" +
          "</ul>"
      case "BL_1_1_5":
        return "<ul>\n" +
          "<li>• Bedarfsdeckung wie z.B. <b>Bedarf</b>, <b>Deckung</b>, <b>Bedürfnis</b> und <b>Fläche</b></li>\n" +
          "<li>• Gesamtwirtschlichkeit wie z.B. <b>Wirtschaftlichkeit</b>, <b>Kostenvergleich</b>, <b>Analyse</b>, <b>Kosten</b> und <b>Auswertung</b></li>\n" +
          "</ul>"
      case "BL_1_3_1":
        return "<ul>\n" +
          "<li>• Räumlich wie z.B. <b>Raum</b>, <b>Einheit</b>, <b>Bereich</b> und <b>Abteilung</b></li>\n" +
          "</ul>"
      case "BL_1_3_2":
        return "<ul>\n" +
          "<li>• Funktional wie z.B. <b>Nutzung</b>, <b>Funktion</b>, <b>Wohnen</b>, <b>Gewerbe</b> und <b>Büro</b></li>\n" +
          "</ul>"
      case "BL_1_4_1":
        return "<ul>\n" +
          "<li>• Fläche wie z.B. <b>Grundfläche</b>, <b>BGF</b>, <b>Nutzfläche</b>, <b>Konstruktionsfläche</b>, <b>Verkehrsfläche</b>, <b>technische Funktionsfläche</b>, <b>Quadradmeter</b>, <b>m<sup>2</sup></b> und <b>Größe</b></li>\n" +
          "<li>• Nutzung wie z.B. <b>Nutzungstyp</b>, <b>Raumtyp</b>, <b>Geschoss</b>, <b>Raum</b>, <b>Einheit</b>, <b>Bereich</b>, <b>Wohnen</b>, <b>Gewerbe</b> und <b>Büro</b></li>\n" +
          "</ul>"
      case "BL_1_5_1":
        return "<ul>\n" +
          "<li>• Mikrostandortfaktoren wie z.B. <b>Grundstück</b>, <b>Gelände</b>, <b>Erschließung</b>, <b>Dienstbarkeiten</b> und <b>Festsetzungen</b></li>\n" +
          "<li>• Makrostandortfaktoren wie z.B. <b>Katasterplan</b>, <b>Lageplan</b>, <b>Versorgung</b>, <b>Entsorgung</b>, <b>Straßen</b>, <b>Bahnhof</b> und <b>Autobahn</b></li>\n" +
          "</ul>"
      case "BL_1_5_2":
        return "<ul>\n" +
          "<li>• Verkehrsstruktur wie z.B. <b>Verkehr</b>, <b>Verkehrsnetz</b>, <b>Verkehrsanbindung</b>, <b>innerstädtisch</b>, <b>regional</b>, <b>Entfernung</b> und <b>Zugänglichkeit</b></li>\n" +
          "<li>• Infrastruktur wie z.B. <b>Straßennetz</b>, <b>Straßen</b>, <b>Bahnhof</b>, <b>ÖPNV</b>, <b>Haltestelle</b>, <b>Stellplätze</b> und <b>Hafen</b></li>\n" +
          "</ul>"
      case "BL_1_5_3":
        return "<ul>\n" +
          "<li>• Wirtschaftsstruktur wie z.B. <b>wirtschaftliche Situation</b>, <b>Unternehmen</b>, <b>Beschäftigung</b>, <b>Marktfähigkeit</b> und <b>Wettbewerb</b></li>\n" +
          "<li>• Steuer wie z.B. <b>Umsatzsteuer</b>, <b>Gewinnsteuer</b>, <b>Grundsteuer</b> und <b>Steuersatz</b></li>\n" +
          "<li>• Umfeldnutzung wie z.B. <b>Umfeld</b>, <b>Einrichtung</b>, <b>Bildungseinrichtung</b>, <b>Krankenhaus</b>, <b>öffentlich</b>, <b>kulturell</b> und <b>Möglichkeiten</b></li>\n" +
          "</ul>"
      case "BL_1_5_4":
        return "<ul>\n" +
          "<li>• Soziodemografie wie z.B. <b>Bevölkerrungsstruktur</b>, <b>Bevölkerungsverteilung</b>, <b>Bevölkerungszahl</b>, <b>Kaufkraft</b>, <b>demografische Entwicklung</b> und <b>Migration</b></li>\n" +
          "</ul>"
      case "BL_1_5_5":
        return "<ul>\n" +
          "<li>• Image wie z.B. <b>Standortattraktivität</b>, <b>Standortqualität</b>, <b>Stadtbild</b> und <b>Verwaltungsstruktur</b></li>\n" +
          "<li>• Investitionsklima wie z.B. <b>Politisch</b>, <b>steuerlich</b> und <b>wirtschaftlich</b></li>\n" +
          "</ul>"
      case "BL_1_7_1_1":
        return "<ul>\n" +
          "<li>• Grundbuchinformationen wie z.B. <b>Grundbuch</b>, <b>Aufschrift</b>, <b>Abschrift</b>, <b>Bestandsverzeichnis</b>, <b>Grundbuchblatt</b>, <b>Auszug</b> und <b>Grundbuchamt</b></li>\n" +
          "<li>• Grundstücksinformationen wie z.B. <b>Grundstück</b>, <b>Gemarkung</b>, <b>Flur</b> und <b>Flurstück-Nr</b></li>\n" +
          "<li>• Grundbucheinträge wie z.B. <b>Eintragung</b>, <b>Einträge</b>, <b>Belastungen</b>, <b>Lasten</b>, <b>Eigentümer*in</b>, <b>Wegerecht</b>, <b>Grundschuld</b>, <b>Hypothek</b> und <b>Rechte</b></li>\n" +
          "</ul>"
      case "BL_1_7_2_1":
        return "<ul>\n" +
          "<li>• Baulastenverzeichnis wie z.B. <b>Bauaufsichtsbehörde</b>, <b>Baulasten</b>, <b>Abstandsflächen</b>, <b>Nachbarbebauung</b>, <b>Nachbargrundstück</b> und <b>belastet</b></li>\n" +
          "<li>• Grundstücksinformationen wie z.B. <b>Grundstück</b>, <b>Gemarkung</b>, <b>Flur</b> und <b>Flurstück-Nr</b></li>\n" +
          "</ul>"
      case "BL_1_7_3_1":
        return "<ul>\n" +
          "<li>• Katasterplan wie z.B. <b>Katasterkarte</b>, <b>Liegenschaftskataster</b>, <b>Liegenschaftskarte</b>, <b>Katasteramt</b> und <b>Vermessungsbehörde</b></li>\n" +
          "<li>• amtlicher Lageplan wie z.B. <b>Übersichtsplan</b>, <b>Bebauungsplan</b>, <b>Planinhalt</b>, <b>Maßstab</b> und <b>Vermessungsamt</b></li>\n" +
          "<li>• Grundstücksinformationen wie z.B. <b>Grundstück</b>, <b>Ort</b>, <b>Lage</b>, <b>Gemarkung</b>, <b>Flur</b>, <b>Flurstück-Nr</b> und <b>Eigentümer</b></li>\n" +
          "</ul>"
      case "BL_1_7_4_1":
        return "<ul>\n" +
          "<li>• Bauvorhaben wie z.B. <b>Neubau</b>, <b>Bestand</b>, <b>Abbruch</b>, <b>Umnutzung</b>, <b>Rückbau</b>, <b>Grundriss</b> und <b>Aktenzeichen</b></li>\n" +
          "<li>• Bodengutachten wie z.B. <b>Baugrundgutachten</b>, <b>Baugrund</b>, <b>Gutachten</b>, <b>Baugrundbeschaffenheit</b>, <b>Bodenuntersuchung</b>, <b>Grundwasser</b>, <b>Baugrundschnitte</b>, <b>Baschaffenheit</b>, <b>Deckschichten</b> und <b>Material</b></li>\n" +
          "<li>• Kampfmittelfreiheitsbescheinigung wie z.B. <b>Kampfmittel</b>, <b>Bombentrichter</b>, <b>Kampfmittelfreiheit</b>, <b>Kampfmittelerkundung</b>, <b>Kampfmittelsondierung</b>, <b>Fundstelle</b>, <b>Maßnahmen</b>, <b>Luftbilder</b>, <b>Blindgänger</b> und <b>Bericht</b></li>\n" +
          "<li>• Grundstücksinformationen wie z.B. <b>Grundstück</b>, <b>Gemarkung</b>, <b>Flur</b>, <b>Flurstück-Nr</b>, <b>Bestandserfassung</b> und <b>Baugrenze</b></li>\n" +
          "<li>• Behörden wie z.B. <b>Stadtbauamt</b>, <b>Stadtplanungsamt</b>, <b>Umweltamt</b>, <b>Wasserbehörde</b> und <b>Kampfmittelbeseitigungsdienst</b></li>\n" +
          "</ul>"
      case "BL_1_8_1":
        return "<ul>\n" +
          "<li>• Gebäude wie z.B. <b>Bestandsanalyse</b>, <b>Gebäudeunterlagen</b>, <b>Grundriss</b>, <b>Schnitt</b>, <b>Ansicht</b>, <b>Baubeschreibung</b> und <b>Barrierefreiheit</b></li>\n" +
          "<li>• Bauteil wie z.B. <b>Schäden</b>, <b>Mängel</b> und <b>Besonderheiten</b></li>\n" +
          "</ul>"
      case "BL_1_8_2":
        return "<ul>\n" +
          "<li>• Sichtprüfung wie z.B. <b>Sichtung</b>, <b>Untersuchung</b>, <b>Zustandsbeurteilung</b>, <b>Schadensfeststellung</b> und <b>Laboranalyse</b></li>\n" +
          "</ul>"
      case "BL_1_8_3":
        return "<ul>\n" +
          "<li>• Dokumentation wie z.B. <b>Ergebnisse</b>, <b>Prüfberichte</b>, <b>Befund</b>, <b>Substanzerkunung</b> und <b>Zustandsfeststellung</b></li>\n" +
          "</ul>"
      case "BL_1_9_1":
        return "<ul>\n" +
          "<li>• Technische Untersuchung wie z.B. <b>Bausubstanz</b>, <b>Qualitätsuntersuchung</b>, <b>Konstruktion</b>, <b>Tragverhalten</b> und <b>Wärmeschutz</b></li>\n" +
          "</ul>"
      case "BL_1_10_1":
        return "<ul>\n" +
          "<li>• Belegschaftsplanung wie z.B. <b>Mitarbeiter</b>, <b>Beschäftigte</b> und <b>Schichtbetrieb</b></li>\n" +
          "</ul>"
      case "BL_1_10_2":
        return "<ul>\n" +
          "<li>• Produktionsapparate wie z.B. <b>Erzeugnisse</b>, <b>Lagergut</b>, <b>Maschine</b> und <b>Rohstoffe</b></li>\n" +
          "</ul>"
      case "BL_1_10_3":
        return "<ul>\n" +
          "<li>• Personalplanung wie z.B. <b>Beteiligte</b>, <b>Administration</b>, <b>operative Steuerung</b>, <b>Leitung</b> und <b>Überwachung</b></li>\n" +
          "</ul>"
      case "BL_1_10_4":
        return "<ul>\n" +
          "<li>• Planung der Arbeitsdurchführung wie z.B. <b>Arbeitsabläufe</b>, <b>Arbeitsverfahren</b> und <b>Taktstraße</b></li>\n" +
          "</ul>"
      case "BL_1_10_5":
        return "<ul>\n" +
          "<li>• Planung der Arbeitszeit wie z.B. <b>Arbeitszeit</b>, <b>Schichtbetrieb</b>, <b>Betriebszeit</b> und <b>Werktage</b></li>\n" +
          "</ul>"
      case "BL_1_10_6":
        return "<ul>\n" +
          "<li>• Planung der Unterlieferer wie z.B. <b>Unterlieferer</b></li>\n" +
          "</ul>"
      case "BL_1_11_1":
        return "<ul>\n" +
          "<li>• Umwelterheblichkeit wie z.B. <b>Klima</b>, <b>erneuerbare Energie</b>, <b>Tiere</b>, <b>Pflanzen</b>, <b>Grundwasser</b>, <b>Versiegelungsgrad</b> und <b>Emission</b></li>\n" +
          "</ul>"
      case "BL_1_12_1":
        return "<ul>\n" +
          "<li>• Vorhaben wie z.B. <b>Art</b>, <b>Umfang</b>, <b>Ausgestaltung</b> und <b>Größe</b></li>\n" +
          "<li>• Standort wie z.B. <b>Bezugsraum</b>, <b>Lage</b>, <b>Geologie</b> und <b>Vegetation</b></li>\n" +
          "</ul>"
      case "BL_1_12_2":
        return "<ul>\n" +
          "<li>• Umwelt wie z.B. <b>Untersuchungsgebiet</b>, <b>Lebensraum</b>, <b>Arten</b>, <b>Tiere</b>, <b>Pflanzen</b>, <b>Boden</b>, <b>Landwirtschaft</b> und <b>biologische Vielfalt</b></li>\n" +
          "</ul>"
      case "BL_1_12_3":
        return "<ul>\n" +
          "<li>• Maßnahmen gegen Umweltauswirkungen wie z.B. <b>Merkamale</b>, <b>Maßnahmen</b>, <b>Vermeidung</b>, <b>Minderung</b>, <b>Luftverunreinigung</b> und <b>Schutzkonzept</b></li>\n" +
          "</ul>"
      case "BL_1_12_4":
        return "<ul>\n" +
          "<li>• Erwartende Umweltauswirkungen wie z.B. <b>Auswirkungen</b>, <b>Prognose</b>, <b>Natur</b> und <b>Klima</b></li>\n" +
          "</ul>"
      case "BL_1_12_5":
        return "<ul>\n" +
          "<li>• Alternativen wie z.B. <b>Variante</b>, <b>Vergleich</b> und <b>Möglichkeit</b></li>\n" +
          "<li>• Getroffene Wahl wie z.B. <b>Gründe</b>, <b>Begründung</b>, <b>Berücksichtigung</b> und <b>Auswahl</b></li>\n" +
          "</ul>"
      case "BL_1_12_6":
        return "<ul>\n" +
          "<li>• Zusammenfassung wie z.B. <b>allgemein</b>, <b>verständlich</b>, <b>nichttechnisch</b>, <b>Ergebnis</b> und <b>Bewertung</b></li>\n" +
          "</ul>"
      case "BL_1_13_1":
        return "<ul>\n" +
          "<li>• Aufgabenstellung wie z.B. <b>Grundlagen</b>, <b>Ablauf</b>, <b>Verfahren</b> und <b>Rahmenbedingungen</b></li>\n" +
          "<li>• Zielsetzung wie z.B. <b>Projektziele</b>, <b>Bauziele</b> und <b>Anforderungen</b></li>\n" +
          "</ul>"
      case "BL_1_13_2":
        return "<ul>\n" +
          "<li>• relevante Randbedingungen wie z.B. <b>Baubeschreibung</b>, <b>Umgebung</b>, <b>Standort</b>, <b>Planung</b> und <b>Altlast</b></li>\n" +
          "</ul>"
      case "BL_1_13_3":
        return "<ul>\n" +
          "<li>• Auswertung der Informationen wie z.B. <b>Raumprogramm</b>, <b>Flächenberechnung</b> und <b>Kostenschätzung</b></li>\n" +
          "<li>• Vergleich der Informationen wie z.B. <b>Machbarkeitsstudie</b>, <b>Variantenstudie</b>, <b>Nutzwertanalyse</b>, <b>Wirtschaftlichkeitsanalyse</b> und <b>Kostenvergleich</b></li>\n" +
          "</ul>"
      case "BL_1_13_4":
        return "<ul>\n" +
          "<li>• Bewertung wie z.B. <b>Zusammenfassung</b>, <b>Ergebnis</b>, <b>Dokumentation</b>, <b>Empfehlung</b>, <b>Machbarkeit</b> und <b>Fazit</b></li>\n" +
          "</ul>"
      case "BL_1_14_1":
        return "<ul>\n" +
          "<li>• Wirtschaftlichkeitsuntersuchung wie z.B. <b>Kostenberechnung</b>, <b>Renditeberechnung</b>, <b>Kostenvergleichsrechnung</b>, <b>Baukosten</b>, <b>Projektkosten</b> und <b>Lebenszykluskosten</b></li>\n" +
          "</ul>"
      case "BL_1_15_1":
        return "<ul>\n" +
          "<li>• Projektstrukturplan wie z.B. <b>Projektabläufe</b>, <b>Projektaufgabe</b>, <b>Projektinhalt</b>, <b>Projektkontext</b>, <b>Projekttermine</b>, <b>Projektbeteiligte</b>, <b>Verantwortung</b>, <b>Ziele</b> und <b>Entscheidungen</b></li>\n" +
          "</ul>"
      case "BL_1_16_1":
        return "<ul>\n" +
          "<li>• Kriterien wie z.B. <b>Übersicht</b>, <b>Erfüllungsgrad</b> und <b>Kriteriengruppe</b></li>\n" +
          "<li>• Ökologie wie z.B. <b>Umwelt</b>, <b>Auswirkungen</b>, <b>Folgen</b> und <b>Ökobilanz</b></li>\n" +
          "<li>• Ökonomie wie z.B. <b>Lebenszykluskosten</b>, <b>Wertentwicklung</b> und <b>Marktfähigkeit</b></li>\n" +
          "<li>• Soziokulturelle und funktionale  Qualität wie z.B. <b>Komfort</b>, <b>Aufenthaltsqualität</b>, <b>Sicherheit</b>, <b>Funktionalität</b> und <b>Barrierefreiheit</b></li>\n" +
          "<li>• Technische Qualität wie z.B. <b>Schallschutz</b>, <b>Gebäudehülle</b>, <b>Brandschutz</b> und <b>Recyclingfreundlichkeit</b></li>\n" +
          "<li>• Planungsqualität wie z.B. <b>Konzeption</b>, <b>Bauausführung</b>, <b>Vergabe</b> und <b>Inbetriebnahme</b></li>\n" +
          "<li>• Standortqualität wie z.B. <b>Mikrostandort</b>, <b>Makrostandort</b>, <b>Image</b> und <b>Anbindung</b></li>\n" +
          "</ul>"
      case "BL_1_16_2":
        return "<ul>\n" +
          "<li>• BNB Zertifizierungssystem wie z.B. <b>Übersicht</b>, <b>Kriterien</b>, <b>Anforderung</b> und <b>Zertifikat</b></li>\n" +
          "</ul>"
      case "BL_1_16_3":
        return "<ul>\n" +
          "<li>• BREEAM Zertifizierungssystem wie z.B. <b>Übersicht</b>, <b>Kriterien</b>, <b>Anforderung</b> und <b>Zertifikat</b></li>\n" +
          "</ul>"
      case "BL_1_16_4":
        return "<ul>\n" +
          "<li>• EU-Green-Building Zertifizierungssystem wie z.B. <b>Übersicht</b>, <b>Kriterien</b>, <b>Anforderung</b> und <b>Zertifikat</b></li>\n" +
          "</ul>"
      case "BL_1_16_5":
        return "<ul>\n" +
          "<li>• CASBEE Zertifizierungssystem wie z.B. <b>Übersicht</b>, <b>Kriterien</b>, <b>Anforderung</b> und <b>Zertifikat</b></li>\n" +
          "</ul>"
      case "BL_1_17_1":
        return "<ul>\n" +
          "<li>• Leistung wie z.B. <b>Leistungsumfang</b>, <b>Leistungsinhalt</b> und <b>Planungsleistung</b></li>\n" +
          "</ul>"
      case "BL_1_17_2":
        return "<ul>\n" +
          "<li>• Beauftragung wie z.B. <b>Vergabe</b>, <b>Lose</b>, <b>Beratung</b> und <b>Empfehlung</b></li>\n" +
          "</ul>"

      //LPH 2
      case "GL_2_A_1":
        return "<ul>\n" +
          "<li>• Anforderungen wie z.B. <b>Fachplaner</b>, <b>Bedarf</b>, <b>Ausstattungsanforderung</b> und <b>Gesamtziel</b></li>\n" +
          "<li>• Analyse wie z.B. <b>Funktionsanalyse</b>, <b>Bedarfsanalyse</b> und <b>Auswirkung</b></li>\n" +
          "</ul>"
      case "GL_2_A_2":
        return "<ul>\n" +
          "<li>• Beteiligte wie z.B. <b>Fachplaner*innen</b>, <b>Tragwerksplaner*innen</b>, <b>Architekt*innen</b>, <b>Fachingenieur*innen</b>, <b>TGA-Planer*innen</b>, <b>Elektroplaner*innen</b>, <b>Außenanlagenplaner*innen</b> und <b>Tiefbauplaner*innen</b></li>\n" +
          "</ul>"
      case "GL_2_A_3":
        return "<ul>\n" +
          "<li>• Leistungen wie z.B. <b>Nachweis</b>, <b>Planungsthema</b>, <b>Bauteilangaben</b>, <b>Einheiten</b>, <b>Gebäudeklasse</b>, <b>Kennzahlen</b>, <b>Bemaßungen</b>, <b>Dokumentation</b> und <b>Darstellungen</b></li>\n" +
          "<li>• Fachplaner wie z.B. <b>Tragwerksplaner*in</b>, <b>Architekt*in</b>, <b>Fachingenieur*in</b>, <b>TGA-Planer*innen</b>, <b>Elektroplaner*in</b>, <b>Außenanlagenplaner*in</b> und <b>Tiefbauplaner*in</b></li>\n" +
          "</ul>"
      case "GL_2_B_1":
        return "<ul>\n" +
          "<li>• Ziele wie z.B. <b>Bauziele</b>, <b>Nutzungsziele</b>, <b>Gestaltungsziele</b>, <b>Budgetziele</b> und <b>Bauzeit</b></li>\n" +
          "<li>• Erörterung wie z.B. <b>Abstimmung</b>, <b>Gespräch</b>, <b>Erläuterung</b> und <b>Erklärung</b></li>\n" +
          "</ul>"
      case "GL_2_B_2":
        return "<ul>\n" +
          "<li>• Zielkonflikte wie z.B. <b>Ziele</b>, <b>Bauziele</b> und <b>Lösung</b></li>\n" +
          "<li>• Identifizierung wie z.B. <b>Beratung</b>, <b>Abstimmung</b>, <b>Gespräch</b> und <b>Analyse</b></li>\n" +
          "</ul>"
      case "GL_2_B_3":
        return "<ul>\n" +
          "<li>• Beratung wie z.B. <b>Abstimmung</b>, <b>Gespräch</b>, <b>Optimierung</b> und <b>Entscheidung</b></li>\n" +
          "</ul>"
      case "GL_2_C_1":
        return "<ul>\n" +
          "<li>• Grundriss wie z.B. <b>Erdgeschoss</b>, <b>Obergeschoss</b>, <b>Kellergeschoss</b>, <b>Untergeschoss</b>, <b>EG</b>, <b>OG</b>, <b>KG</b>, <b>Wohnen</b>, <b>Küche</b>, <b>Schlafen</b>, <b>Bad</b>, <b>WC</b>, <b>Technik</b> und <b>Arbeiten</b></li>\n" +
          "<li>• Schnitt wie z.B. <b>Längsschnitt</b>, <b>Querschnitt</b>, <b>Schnitt A-A</b> und <b>Schnitt 1-1</b></li>\n" +
          "<li>• Ansicht wie z.B. <b>Ausrichtung</b>, <b>Himmelsrichtung</b>, <b>Fenster</b>, <b>Fassade</b> und <b>Tür</b></li>\n" +
          "</ul>"
      case "GL_2_C_2":
        return "<ul>\n" +
          "<li>• Projektinformationen wie z.B. <b>Projektname</b>, <b>Bauherr*in</b>, <b>Projektsteuerer*in</b>, <b>Objektplaner*in</b>, <b>Fachplaner*in</b>, <b>Adresse</b> und <b>Datum</b></li>\n" +
          "<li>• Planinhalt wie z.B. <b>Maßstab</b>, <b>Format</b>, <b>Plannummer</b>, <b>Blattnummer</b>, <b>Blattgröße</b>, <b>Grundriss</b>, <b>Schnitt</b>, <b>Ansicht</b> und <b>Positionsplan</b></li>\n" +
          "</ul>"
      case "GL_2_D_1":
        return "<ul>\n" +
          "<li>• Nutzungszweck wie z.B. <b>Nutzung</b>, <b>Nutzungsziel</b>, <b>Neubau</b>, <b>Umbau</b>, <b>Erweiterung</b> und <b>Sanierung</b></li>\n" +
          "</ul>"
      case "GL_2_D_2":
        return "<ul>\n" +
          "<li>• Gestaltungsziel wie z.B. <b>Gestaltung</b>, <b>Ziele</b>, <b>städtebaulich</b>, <b>gestalterisch</b>, <b>Dachform</b>, <b>Fassade</b> und <b>Form</b></li>\n" +
          "</ul>"
      case "GL_2_D_3":
        return "<ul>\n" +
          "<li>• Raumprogramm wie z.B. <b>Raumübersicht</b>, <b>Raumliste</b>, <b>Nutzung</b>, <b>Funktion</b>, <b>Raum</b> und <b>Nutzungseinheit</b></li>\n" +
          "<li>• Raumbezeichnung wie z.B. <b>Wohnen</b>, <b>Aufenthalt</b>, <b>Büro</b>, <b>Bad</b>, <b>WC</b>, <b>Technik</b>, <b>Lager</b> und <b>Flur</b></li>\n" +
          "<li>• Flächen wie z.B. <b>m<sup>2</sup></b>, <b>qm</b>, <b>Bruttogrundfläche</b>, <b>BGF</b>, <b>Nutzfläche</b>, <b>NF</b>, <b>Verkehrsfläche</b>, <b>VF</b>, <b>Technikfläche</b> und <b>TF</b></li>\n" +
          "</ul>"
      case "GL_2_D_4":
        return "<ul>\n" +
          "<li>• Bautechnische Anforderungen wie z.B. <b>Bauphysik</b>, <b>technische Gebäudeausrüstung</b>, <b>TGA</b>, <b>Brandschutz</b> und <b>Schallschutz</b></li>\n" +
          "</ul>"
      case "GL_2_D_5":
        return "<ul>\n" +
          "<li>• Budgetziele wie z.B. <b>Budget</b>, <b>Ziele</b>, <b>Wirtschaft</b>, <b>wirtschaftlich</b>, <b>Maximum</b> und <b>Minimum</b></li>\n" +
          "</ul>"
      case "GL_2_D_6":
        return "<ul>\n" +
          "<li>• Bauzeitvorstellungen wie z.B. <b>Bauzeit</b>, <b>Ziele</b>, <b>Planung</b>, <b>Frist</b>, <b>Fertigstellung</b>, <b>Limit</b> und <b>Zeitplan</b></li>\n" +
          "</ul>"
      case "GL_2_E_1":
        return "<ul>\n" +
          "<li>• Vorplanung wie z.B. <b>Fachplanung</b>, <b>Objektplanung</b>, <b>Ergebnis</b> und <b>Planungsergebnis</b></li>\n" +
          "<li>• Aufbereitung wie z.B. <b>Zusammenfassung</b> und <b>Bereitstellung</b></li>\n" +
          "</ul>"
      case "GL_2_E_2":
        return "<ul>\n" +
          "<li>• Beteiligte wie z.B. <b>Fachplaner*in</b>, <b>fachlich Beteiligte</b>, <b>Fachleistung</b>, <b>Fachplanung</b>, <b>Architekt*in</b>, <b>Objektplaner*in</b> und <b>Fachingenieur*in</b></li>\n" +
          "<li>• Koordination wie z.B. <b>Koordinierung</b>, <b>Integration</b> und <b>Abstimmung</b></li>\n" +
          "</ul>"
      case "GL_2_E_3":
        return "<ul>\n" +
          "<li>• Fachplanung wie z.B. <b>Tragwerksplanung</b>, <b>technische Ausrüstung</b>, <b>TA</b>, <b>technische Gebäudeausrüstung</b> und <b>TGA</b></li>\n" +
          "<li>• Vorplanung wie z.B. <b>Fachplanung</b>, <b>Objektplanung</b>, <b>Ergebnis</b> und <b>Planungsergebnis</b></li>\n" +
          "<li>• Integration wie z.B. <b>Prüfung</b>, <b>Konflikt</b>, <b>Kollision</b> und <b>Schnittstellen</b></li>\n" +
          "</ul>"
      case "GL_2_F_1":
        return "<ul>\n" +
          "<li>• Genehmigungsfähigkeit wie z.B. <b>Bebauungsplan</b>, <b>Festsetzung</b>, <b>Befreiung</b> und <b>Abweichung</b></li>\n" +
          "</ul>"
      case "GL_2_F_2":
        return "<ul>\n" +
          "<li>• Genehmigungsfähigkeit wie z.B. <b>Baugesetzbuch</b>, <b>BauGB</b>, <b>§34</b> und <b>§35</b></li>\n" +
          "<li>• Vorverhandlung wie z.B. <b>Klärung</b>, <b>Beratung</b>, <b>Prüfung</b>, <b>Verhandlung</b>, <b>Gespräch</b> und <b>Informieren</b></li>\n" +
          "<li>• Behörde wie z.B. <b>Baubehörde</b>, <b>Bauaufsichtsamt</b> und <b>Bauamt</b></li>\n" +
          "</ul>"
      case "GL_2_F_3":
        return "<ul>\n" +
          "<li>• Beteiligte wie z.B. <b>Nachbar*in</b> und <b>Öffentlichkeit</b></li>\n" +
          "<li>• Bebauung wie z.B. <b>nachbarliche Bebauung</b>, <b>Nachbarbebauung</b>, <b>Randbebauung</b>, <b>benachbarte Gebäude</b> und <b>Nachbargrundstück</b></li>\n" +
          "<li>• Zustimmung wie z.B. <b>Erlaubnis</b>, <b>baurechtliche Zustimmung</b>, <b>Gespräch</b>, <b>Informieren</b> und <b>Verhandlung</b></li>\n" +
          "</ul>"
      case "GL_2_F_4":
        return "<ul>\n" +
          "<li>• Genehmigungsfähigkeit wie z.B. <b>Bebauungsplan</b>, <b>Bauvorschriften</b>, <b>Bauordung</b>, <b>Landesordnung</b> und <b>LBO</b></li>\n" +
          "<li>• Antrag wie z.B. <b>Befreiung</b>, <b>Festsetzung</b>, <b>Ausnahme</b> und <b>Abweichung</b></li>\n" +
          "<li>• Abstände wie z.B. <b>Abstandsfläche</b>, <b>Baukörper</b>, <b>Grundstück</b>, <b>Grenze</b> und <b>Grenzabstand</b></li>\n" +
          "</ul>"
      case "GL_2_G_1":
        return "<ul>\n" +
          "<li>• Fläche und Volumen wie z.B. <b>Flächeninhalt</b>, <b>Rauminhalt</b>, <b>Grundfläche</b>, <b>Bruttogrundfläche</b>, <b>BGF</b>, <b>Bruttorauminhalt</b>, <b>BGI</b> und <b>Grundstücksfläche</b></li>\n" +
          "<li>• Kostengruppe wie z.B. <b>KG</b>, <b>Kostengliederung</b>, <b>Ebene</b>, <b>Baukonstruktion</b>, <b>technische Anlagen</b> und <b>Baunebenkosten</b></li>\n" +
          "</ul>"
      case "GL_2_G_3":
        return "<ul>\n" +
          "<li>• Berechnung wie z.B. <b>Gesamtkosten</b>, <b>Gesamtsumme</b>, <b>Kennwert</b>, <b>Kenndaten</b>, <b>Zuschlag</b>, <b>Euro</b>, <b>€</b>, <b>brutto</b> und <b>netto</b></li>\n" +
          "<li>• Kostengruppe wie z.B. <b>KG</b>, <b>Kostengliederung</b>, <b>Ebene</b>, <b>Baukonstruktion</b>, <b>technische Anlagen</b> und <b>Baunebenkosten</b></li>\n" +
          "</ul>"
      case "GL_2_H_2":
        return "<ul>\n" +
          "<li>• Gliederung wie z.B. <b>Position</b>, <b>Pos.</b>, <b>Gewerk</b>, <b>Leistung</b>, <b>LPH</b>, <b>Nummer</b>, <b>NR.</b> und <b>Bezeichnung</b></li>\n" +
          "<li>• Vorgangsnamen wie z.B. <b>Ausführungsplanung</b>, <b>Vergabe</b>, <b>Herstellung</b>, <b>Gründung</b>, <b>Objektplanung</b> und <b>Erdbau</b></li>\n" +
          "</ul>"
      case "GL_2_H_3":
        return "<ul>\n" +
          "<li>• Gliederung wie z.B. <b>Ablauf</b>, <b>Meilenstein</b>, <b>Dauer</b>, <b>Vorgangsdauer</b>, <b>Gewerk</b>, <b>Leistung</b>, <b>Startdatum</b> und <b>Enddatum</b></li>\n" +
          "<li>• Vorgänge wie z.B. <b>Ausführungsvorbereitung</b>, <b>Ausschreibung</b>, <b>Leistungsphasen</b>, <b>LPH</b>, <b>Baugenehmigung</b>, <b>Herstellung Fertigstellung</b> und <b>Abnahme</b></li>\n" +
          "<li>• Zeitangaben wie z.B. <b>Anfang</b>, <b>Ende</b>, <b>Arbeitstage</b>, <b>AT</b>, <b>Kalenderwoche</b>, <b>KW</b>, <b>Monat</b>, <b>Wochentag</b> und <b>Quartal</b></li>\n" +
          "</ul>"
      case "GL_2_I1_2":
        return ""

      case "GL_2_I1_3":
        return ""

      case "GL_2_I2_2":
        return ""

      case "GL_2_I2_3":
        return ""

      case "GL_2_I2_4":
        return ""

      case "BL_2_1_1":
        return "<ul>\n" +
          "<li>• Ziele wie z.B. <b>Kosten</b>, <b>Budget</b>, <b>Zeit</b>, <b>Meilensteine</b>, <b>Qualität</b>, <b>Anforderungen</b>, <b>Nutzung</b> und <b>Gebäudefunktion</b></li>\n" +
          "<li>• Sammlung wie z.B. <b>Katalog</b> und <b>Zusammenstellung</b></li>\n" +
          "</ul>"
      case "BL_2_2_1":
        return "<ul>\n" +
          "<li>• Lösungsansätze wie z.B. <b>Lösung</b>, <b>Variante</b>, <b>Alternativen</b>, <b>Ausarbeitung</b> und <b>Vergleich</b></li>\n" +
          "<li>• Anforderungsprofil wie z.B. <b>Anforderung</b>, <b>Nutzungsart</b>, <b>Gebäudefunktion</b>, <b>Profil</b> und <b>Funktion</b></li>\n" +
          "</ul>"
      case "BL_2_2_2":
        return "<ul>\n" +
          "<li>• Kostenbewertung wie z.B. <b>Kosten</b>, <b>Kostenuntersuchung</b>, <b>Kostenschätzung</b>, <b>Kostenberechnung</b>, <b>Gesamtkosten</b> und <b>Kostengruppe</b></li>\n" +
          "</ul>"
      case "BL_2_3_1":
        return "<ul>\n" +
          "<li>• Zertifizierungssystem wie z.B. <b>Zertifikat</b>, <b>Zertifizierung</b>, <b>DGNB</b>, <b>BNB</b>, <b>BREEAM</b>, <b>LEED</b>, <b>CASBEE</b> und <b>EU-Green-Building</b></li>\n" +
          "<li>• Kriterien wie z.B. <b>Kriteriengruppe</b>, <b>Kriterienbezeichnung</b>, <b>ökologische Qualität</b>, <b>ökonomische Qualität</b>, <b>soziokulturelle Qualität</b>, <b>technische Qualität</b>, <b>Prozessqualität</b> und <b>Standortqualität</b></li>\n" +
          "<li>• Bewertung wie z.B. <b>Punkte</b>, <b>Werte</b>, <b>gewichtet</b>, <b>Gewichtsfaktoren</b> und <b>Bewertungsstufe</b></li>\n" +
          "</ul>"
      case "BL_2_4_1":
        return "<ul>\n" +
          "<li>• Zertifizierungssystem wie z.B. <b>Zertifikat</b>, <b>Zertifizierung</b>, <b>DGNB</b>, <b>BNB</b>, <b>BREEAM</b>, <b>LEED</b>, <b>CASBEE</b> und <b>EU-Green-Building</b></li>\n" +
          "<li>• Kriterien wie z.B. <b>Kriteriengruppe</b>, <b>Kriterienbezeichnung</b>, <b>ökologische Qualität</b>, <b>ökonomische Qualität</b>, <b>soziokulturelle Qualität</b>, <b>technische Qualität</b>, <b>Prozessqualität</b> und <b>Standortqualität</b></li>\n" +
          "<li>• Bewertung wie z.B. <b>Medaille</b>, <b>Gold</b>, <b>Silber</b>, <b>Bronze</b>, <b>Note</b>, <b>%</b> und <b>Gesamterfüllungsgrad</b></li>\n" +
          "</ul>"
      case "BL_2_6_1":
        return "<ul>\n" +
          "<li>• Kapitalbedarf wie z.B. <b>Kosten</b>, <b>Kostenschätung</b>, <b>DIN 276</b>, <b>Baukosten</b>, <b>sonstige Kosten</b>, <b>Baunebenkosten</b> und <b>Budget</b></li>\n" +
          "</ul>"
      case "BL_2_6_2":
        return "<ul>\n" +
          "<li>• Eigenmittel wie z.B. <b>Eigenkapital</b>, <b>Eigenkapitalkosten</b>, <b>Eigenleistung</b> und <b>Jahresüberschuss</b></li>\n" +
          "<li>• Rücklagen wie z.B. <b>Kapitalrücklagen</b> und <b>Gewinnrücklagen</b></li>\n" +
          "<li>• Vortrag wie z.B. <b>Gewinnvortrag</b> und <b>Verlustvortrag</b></li>\n" +
          "</ul>"
      case "BL_2_6_3":
        return "<ul>\n" +
          "<li>• Fremdmittel wie z.B. <b>Fremdkapital</b>, <b>Fremdkapitalkosten</b> und <b>Fremdleistung</b></li>\n" +
          "<li>• Rückstellung wie z.B. <b>Verbindlichkeiten</b> und <b>Rechnungsabgrenzung</b></li>\n" +
          "</ul>"
      case "BL_2_6_4":
        return "<ul>\n" +
          "<li>• Mittelabflussplanung wie z.B. <b>Mittelabfluss</b>, <b>Ablaufplanung</b>, <b>Finanzierungsplanung</b>, <b>Liquidität</b>, <b>Tilgung</b>, <b>Zinsen</b>, <b>Einzahlung</b> und <b>Auszahlung</b></li>\n" +
          "<li>• Kosten wie z.B. <b>Gesamtkosten</b>, <b>Kosteneingang</b>, <b>Kostenausgang</b>, <b>Kostenströme</b> und <b>Kostenverlauf</b></li>\n" +
          "<li>• Termine wie z.B. <b>Vorgangsdauer</b>, <b>Frist</b>, <b>Datum</b>, <b>Zeit</b>, <b>Laufzeit</b>, <b>Starttermin</b> und <b>Fertigstellungstermin</b></li>\n" +
          "<li>• Bauherrschaft wie z.B. <b>Bauherr*in</b> und <b>Auftraggeber*in</b></li>\n" +
          "</ul>"
      case "BL_2_7_1":
        return "<ul>\n" +
          "<li>• Fördermittel wie z.B. <b>Landesmittel</b>, <b>Bundesmittel</b>, <b>EU-Mittel</b>, <b>Zuschuss</b> und <b>Fördermittelbeschaffung</b></li>\n" +
          "<li>• Antrag wie z.B. <b>Finanzierungsantrag</b>, <b>Förderantrag</b>, <b>Zuschussantrag</b> und <b>Kreditantrag</b></li>\n" +
          "<li>• Beteiligte wie z.B. <b>Architekt*in</b>, <b>Generalplaner*in</b>, <b>Ingenieur*in</b> und <b>Auftragnehmer*in</b></li>\n" +
          "</ul>"
      case "BL_2_7_2":
        return "<ul>\n" +
          "<li>• Unterlagen wie z.B. <b>Dokumente</b>, <b>Antrag</b>, <b>Beantragung</b> und <b>Baupläne</b></li>\n" +
          "<li>• Verträge wie z.B. <b>Grundstückskaufvertrag</b>, <b>Architektenvertrag</b> und <b>Werkliefervertrag</b></li>\n" +
          "<li>• Kredit/Darlehen wie z.B. <b>Genehmigung</b>, <b>Budget</b>, <b>Bonität</b>, <b>Zinsen</b>, <b>Sicherheit</b>, <b>Liquidität</b> und <b>Laufzeit</b></li>\n" +
          "</ul>"
      case "BL_2_8_1":
        return "<ul>\n" +
          "<li>• Wirtschaftlichkeituntersuchung wie z.B. <b>Kostenvergleichsrechnung</b>, <b>Kapitalwertberechnung</b>, <b>Nutzwertanalyse</b>, <b>Kosten-Nutzen-Analyse</b> und <b>Amortisationszeit</b></li>\n" +
          "<li>• Werte wie z.B. <b>Barwert</b>, <b>Kapitalwert</b>, <b>Zeitwert</b>, <b>Zinsen</b> und <b>dynamisch</b></li>\n" +
          "<li>• Kosten wie z.B. <b>Investitionskosten</b>, <b>Anschaffungskosten</b>, <b>Herstellungskosten</b>, <b>Projektkosten</b>, <b>Leerstandskosten</b> und <b>Opportunitätskosten</b></li>\n" +
          "</ul>"
      case "BL_2_9_1":
        return "<ul>\n" +
          "<li>• Themen zur Diskussion wie z.B. <b>Bauvorhaben</b>, <b>Projekt</b>, <b>Baumaßnahme</b>, <b>Fragestellungen</b> und <b>konkrete Fragen</b></li>\n" +
          "</ul>"
      case "BL_2_9_2":
        return "<ul>\n" +
          "<li>• Anlagen wie z.B. <b>Bauzeichnungen</b>, <b>Baubeschreibung</b>, <b>Berechnungen</b>, <b>Kalkulationen</b>, <b>Basiskarte</b>, <b>Lageplan</b> und <b>Liegenschaftskarte</b></li>\n" +
          "</ul>"
      case "BL_2_9_3":
        return "<ul>\n" +
          "<li>• Behörde wie z.B. <b>Bauaufsichtsbehörde</b>, <b>Baubehörde</b>, <b>Bauordnungsamt</b>, <b>Bauausschuss</b>, <b>Aktenzeichen</b> und <b>Ordnungsnummer</b></li>\n" +
          "<li>• Angaben wie z.B. <b>Bauherrschaft</b>, <b>Name</b>, <b>Anschrift</b>, <b>Grundstück</b>, <b>Gemeinde</b>, <b>Straße</b>, <b>Flurstück</b>, <b>Bauvorlage</b>, <b>Anlage</b> und <b>Anhang</b></li>\n" +
          "</ul>"
      case "BL_2_10_1":
        return "<ul>\n" +
          "<li>• Bauherrschaft wie z.B. <b>Bauherr*in</b>, <b>Auftraggeber*in</b>, <b>Kunde*in</b> und <b>Besteller*in</b></li>\n" +
          "<li>• Wunsch wie z.B. <b>Anforderung</b>, <b>Verlangen</b>, <b>Leistung</b> und <b>Anfertigung</b></li>\n" +
          "<li>• Präsentationsdarstellung wie z.B. <b>Präsentationsmodell</b>, <b>Modell</b>, <b>Collage</b>, <b>Visualisierung</b> und <b>Perspektive</b></li>\n" +
          "</ul>"
      case "BL_2_10_2":
        return "<ul>\n" +
          "<li>• Präsentationsdarstellung wie z.B. <b>Präsentationsmodell</b>, <b>Modell</b>, <b>Collage</b>, <b>Visualisierung</b> und <b>Perspektive</b></li>\n" +
          "</ul>"
      case "BL_2_12_1":
        return "<ul>\n" +
          "<li>• Fläche und Volumen wie z.B. <b>Flächeninhalt</b>, <b>Rauminhalt</b>, <b>Grundfläche</b>, <b>Bruttogrundfläche</b>, <b>BGF</b>, <b>Bruttorauminhalt</b>, <b>BGI</b> und <b>Grundstücksfläche</b></li>\n" +
          "<li>• Kostengruppe wie z.B. <b>KG</b>, <b>Kostengliederung</b>, <b>Ebene</b>, <b>Baukonstruktion</b>, <b>technische Anlagen</b> und <b>Baunebenkosten</b></li>\n" +
          "</ul>"
      case "BL_2_12_3":
        return "<ul>\n" +
          "<li>• Berechnung wie z.B. <b>Gesamtkosten</b>, <b>Gesamtsumme</b>, <b>Kennwert</b>, <b>Kenndaten</b>, <b>Zuschlag</b>, <b>Euro</b>, <b>€</b>, <b>brutto</b> und <b>netto</b></li>\n" +
          "<li>• Kostengruppe wie z.B. <b>KG</b>, <b>Kostengliederung</b>, <b>Ebene</b>, <b>Baukonstruktion</b>, <b>technische Anlagen</b> und <b>Baunebenkosten</b></li>\n" +
          "</ul>"
      case "BL_2_13_1":
        return "<ul>\n" +
          "<li>• Projektinformationen wie z.B. <b>Projektstruktur</b>, <b>Projektorganisation</b>, <b>Projektaufgabe</b>, <b>Projektkontext</b>, <b>Planungsabläufe</b>, <b>Projektmanagement</b> und <b>Projektsteuerung</b></li>\n" +
          "<li>• Projektentscheidungen wie z.B. <b>Projektziele</b>, <b>Projektstart</b>, <b>Projektabschluss</b>, <b>Bekanntmachung</b>, <b>Ausschreibung</b> und <b>Planfreigabe</b></li>\n" +
          "<li>• Projektergebnisse wie z.B. <b>Bedarfsanalyse</b>, <b>Standortanalyse</b>, <b>Machbarkeitsstudie</b>, <b>Terminplan</b>, <b>Kostenberechnung</b>, <b>Genehmigung</b>, <b>Schlussrechnung</b>, <b>Verträge</b>, <b>Prüfstatik</b>, <b>Gutachten</b> und <b>Abnahme</b></li>\n" +
          "<li>• Beteiligte wie z.B. <b>Verantwortung</b>, <b>Zuständigkeit</b>, <b>Projektleitung</b>, <b>Bauleiter</b>, <b>Architekt</b>, <b>Ingenieur</b>, <b>Mieter</b>, <b>Fachplaner</b> und <b>Bauhörde</b></li>\n" +
          "</ul>"
      case "BL_2_14_1":
        return "<ul>\n" +
          "<li>• Gliederung wie z.B. <b>Raumbuch</b>, <b>Raumprogramm</b>, <b>Raumart</b>, <b>Raumbedarf</b>, <b>Raumliste</b> und <b>Funktion</b></li>\n" +
          "<li>• Ausrichtung wie z.B. <b>Himmelsrichtung</b>, <b>Geschoss</b>, <b>EG</b>, <b>OG</b>, <b>DG</b>, <b>Keller</b> und <b>Garage</b></li>\n" +
          "<li>• Abmessungen wie z.B. <b>Fläche</b>, <b>Raumfläche</b>, <b>Raumhöhe</b>, <b>Umfang</b>, <b>Grundfläche</b>, <b>Wohnfläche</b>, <b>Büro-NF</b>, <b>Verkaufsfläche</b> und <b>Geschossfläche</b></li>\n" +
          "<li>• Nutzungstyp wie z.B. <b>Wohnen</b>, <b>Schlafen</b>, <b>Büro</b>, <b>Küche</b>, <b>Bad</b>, <b>WC</b>, <b>Technik</b>, <b>Lager</b>, <b>Flur</b> und <b>Verkaufsräume</b></li>\n" +
          "</ul>"
      case "BL_2_15_1":
        return "<ul>\n" +
          "<li>• Brandschutznachweis wie z.B. <b>Nachweis</b>, <b>Brandschutz</b>, <b>vorbeugend</b>, <b>organisatorisch</b>, <b>bauordnungsrechtlich</b> und <b>besonders</b></li>\n" +
          "<li>• Bauliche Anlagen wie z.B. <b>Bauten</b> und <b>Gebäude</b></li>\n" +
          "</ul>"
      case "BL_2_15_2":
        return "<ul>\n" +
          "<li>• Brandschutznachweis wie z.B. <b>Nachweis</b>, <b>Brandschutz</b>, <b>vorbeugend</b>, <b>organisatorisch</b>, <b>bauordnungsrechtlich</b>, <b>bestehend</b> und <b>vorhanden</b></li>\n" +
          "<li>• Bestandsbauten wie z.B. <b>Bestand</b>, <b>Bestandsbau</b> und <b>Bestandsgebäude</b></li>\n" +
          "</ul>"
      case "BL_2_15_3":
        return "<ul>\n" +
          "<li>• Brandschutznachweis wie z.B. <b>Nachweis</b>, <b>Brandschutz</b>, <b>vorbeugend</b>, <b>organisatorisch</b>, <b>bauordnungsrechtlich</b> und <b>abweichend</b></li>\n" +
          "<li>• Abweichung wie z.B. <b>Bauordnung</b> und <b>Sonderfall</b></li>\n" +
          "</ul>"

      //LPH 3
      case "GL_3_A_1_1":
        return "<ul>\n" +
          "<li>• Planart und Inhalt wie z.B. <b>Grundriss</b>, <b>Geschoss</b>, <b>Erdgeschoss</b>, <b>Kellergeschoss</b>, <b>Dachgeschoss</b> und <b>OG</b></li>\n" +
          "<li>• Raumart bzw. Nutzung wie z.B. <b>Raum-Nummer</b>, <b>Büro</b>, <b>Flur. Treppenhaus</b>, <b>Eingang</b>, <b>Brandschutzwand</b>, <b>F30</b> und <b>F90</b></li>\n" +
          "</ul>"
      case "GL_3_A_1_2":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr*in</b>, <b>Planer und Projekt</b>, <b>Planinhalt sowie Projekt-Nr. und Liegenschaft</b>, <b>Plannummer</b> und <b>Fomat und Maßstab</b></li>\n" +
          "</ul>"
      case "GL_3_A_2_1":
        return "<ul>\n" +
          "<li>• Planart und Inhalt wie z.B. </li>\n" +
          "<li>• Maße wie z.B. <b>Angaben zu Ober- und Unterkanten von Bauteilen</b>, <b>Öffnungsmaße</b>, <b>Brüstungshöhen</b> und <b>Gefälle</b></li>\n" +
          "<li>• Bauteile und Aufbau wie z.B. <b>Fassade</b>, <b>Dachaufbau</b>, <b>Fußbodenaufbau</b> und <b>Position</b></li>\n" +
          "</ul>"
      case "GL_3_A_2_2":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr*in</b>, <b>Planer und Projekt</b>, <b>Planinhalt sowie Projekt-Nr. und Liegenschaft</b>, <b>Plannummer</b>, <b>Fomat</b> und <b>Maßstab</b></li>\n" +
          "</ul>"
      case "GL_3_A_3_1":
        return "<ul>\n" +
          "<li>• Planart und Inhalt wie z.B. <b>Ansicht</b>, <b>Nord</b>, <b>Süd</b>, <b>West</b>, <b>Ost</b> und <b>Nordost</b></li>\n" +
          "<li>• Maße wie z.B. <b>Angaben zu Ober- und Unterkanten von Bauteilen</b>, <b>Brüstungshöhen</b> und <b>Geländeniveaus</b></li>\n" +
          "</ul>"
      case "GL_3_A_3_2":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr*in</b>, <b>Planer und Projekt</b>, <b>Planinhalt sowie Projekt-Nr. und Liegenschaft</b>, <b>Plannummer</b>, <b>Fomat</b> und <b>Maßstab</b></li>\n" +
          "</ul>"
      case "GL_3_B_1":
        return "<ul>\n" +
          "<li>• Beteiligte wie z.B. <b>Architekt*in</b>, <b>Objektplaner*in</b>, <b>Fachplaner*in</b>, <b>Ingenieur*in</b> und <b>Fachingenieur*in</b></li>\n" +
          "<li>• Dokumentation wie z.B. <b>Planungsergebnis</b>, <b>Gesamtplanung</b>, <b>Entwurfsplanung</b>, <b>Objektplanung</b>, <b>Arbeitsergebnis</b> und <b>Zusammenfassung</b></li>\n" +
          "</ul>"
      case "GL_3_B_2":
        return "<ul>\n" +
          "<li>• Planung wie z.B. <b>Fachplanung</b>, <b>Fachleistung</b>, <b>Kernleistung</b>, <b>Planungsgegenstände</b> und <b>Planungsleistung</b></li>\n" +
          "<li>• Beteiligte wie z.B. <b>Architekt*in</b>, <b>Objektplaner*in</b>, <b>Fachplaner*in</b>, <b>Ingenieur*in</b> und <b>Fachingenieur*in</b></li>\n" +
          "<li>• Integration wie z.B. <b>Abstimmung</b>, <b>Koordination</b>, <b>Planungsablauf</b>, <b>Schnittstelle</b>, <b>Konfliktprüfung</b> und <b>Kollisionsprüfung</b></li>\n" +
          "</ul>"
      case "GL_3_C_1":
        return "<ul>\n" +
          "<li>• Lage und Region wie z.B. <b>Bundesland</b>, <b>Stadt</b>, <b>Gemeinde</b> und <b>Adresse</b></li>\n" +
          "<li>• Positionierung wie z.B. <b>Ausrichtung</b>, <b>Grenzabstände</b> und <b>Nachbarbebauung</b></li>\n" +
          "<li>• Grundstücksmerkmale wie z.B. <b>Flurstücknummer</b></li>\n" +
          "<li>• Infrastruktur wie z.B. <b>Straßen</b> und <b>öffentliche Verkehrsmittel- und Einrichtungen</b></li>\n" +
          "</ul>"
      case "GL_3_C_2":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Baugrenze</b>, <b>Gelände</b>, <b>Gefälle</b>, <b>Grunstücksbeshaffenheit</b>, <b>Rasenfläche</b>, <b>Waldung</b>, <b>Moorfläche</b>, <b>Heidefläche</b>, <b>Plangebiet</b> und <b>Ackerfläche</b></li>\n" +
          "<li>• Baugrund wie z.B. <b>Bodenklasse</b>, <b>Homogenbereich</b>, <b>Gestein</b>, <b>Kies</b>, <b>Sand</b>, <b>Schluff</b>, <b>Ton</b>, <b>Grundwasser</b> und <b>wassergefährdende Stoffe</b></li>\n" +
          "</ul>"
      case "GL_3_C_3":
        return "<ul>\n" +
          "<li>• Zugang und Barrierefreiheit wie z.B. <b>Erschließung</b>, <b>Zufahrt</b>, <b>Ausfahrt</b>, <b>Rampe</b>, <b>Fahrweg</b>, <b>Eingang</b> und <b>ÖPNV</b></li>\n" +
          "<li>• Ver- und Entsorgung wie z.B. <b>Wasseranschluss</b>, <b>Gasanschluss</b>, <b>Stromanschluss</b>, <b>Kanalisation</b>, <b>Abfallkonzept</b> und <b>Hausanschlusskasten</b></li>\n" +
          "</ul>"
      case "GL_3_C_4":
        return "<ul>\n" +
          "<li>• Gründung wie z.B. <b>Gründungsart</b>, <b>Falchgründung</b>, <b>Pfahlgründung</b>, <b>Streifenfundament</b>, <b>Einzelfundament</b> und <b>Bodenplatte</b></li>\n" +
          "<li>• Tragwerk wie z.B. <b>Baukonstruktion</b>, <b>Skelettbauweise</b>, <b>Massivbauweise</b>, <b>Tragkonstruktion</b>, <b>Bodenbelastbarkeit</b>, <b>Lastabtrag</b> und <b>Aussteifung</b></li>\n" +
          "<li>• Bauteile und Aufbauten wie z.B. <b>Außenwand</b>, <b>Lochfassade</b>, <b>Sandwichelemente</b>, <b>Innenwand</b>, <b>Brandwand</b>, <b>Stütze</b>, <b>Decke</b>, <b>Dachtragwerk</b>, <b>Dachaufbau</b> und <b>Fertigteil</b></li>\n" +
          "<li>• Material wie z.B. <b>Baustoffart</b>, <b>Ortbeton</b>, <b>WU-Beton</b>, <b>Stahlfaserbeton </b> und <b>Mauerwerk</b></li>\n" +
          "</ul>"
      case "GL_3_C_5":
        return "<ul>\n" +
          "<li>• Elektroinstallation wie z.B. <b>Elektroplanung</b>, <b>Stromversorgung</b>, <b>Beleuchtung</b>, <b>Kabelkanäle</b> und <b>Stromleitungen</b></li>\n" +
          "<li>• Wärmeerzeugung und Verteilung wie z.B. <b>Wärmeversorgung</b>, <b>Heizungsanlagen</b>, <b>Fußbodenheizung</b>, <b>Feuerstätte</b>, <b>Abgasanlage</b>, <b>Fernwärme</b>, <b>Brennstoff</b> und <b>Elektroheizung</b></li>\n" +
          "<li>• Kälteanlagen und Lüftungssysteme wie z.B. <b>Lüftung</b>, <b>Wärmerückgewinngung</b>, <b>Kühlung</b>, <b>Klimatechnik</b> und <b>HKL-Anlage</b></li>\n" +
          "<li>• weitere Gebäudetechnik wie z.B. <b>Aufzuganlage</b>, <b>Blitzschutzanlage</b> und <b>Störmeldesysteme</b></li>\n" +
          "<li>• Sanitäranlagen wie z.B. <b>Wasseranschluss</b>, <b>Warmwasser</b>, <b>Duchlauferhitzer</b>, <b>Entwässerung</b>, <b>Abwasser</b>, <b>Schmutzwasser</b>, <b>Sanitärräume</b> und <b>WC-Anlagen</b></li>\n" +
          "</ul>"
      case "GL_3_C_6":
        return "<ul>\n" +
          "<li>• Brandschutzkonzept wie z.B. <b>Brandschutznachweis</b>, <b>Brandabschnitt</b>, <b>Fluchplan</b>, <b>Rettungsplan</b>, <b>Notausgangstür</b>, <b>Brandschutzmaterial</b>, <b>F30</b> und <b>F90</b></li>\n" +
          "<li>• Melde- und Löscheinrichtungen wie z.B. <b>Branderkennung</b>, <b>Sprinkeranlage</b>, <b>Wandhydranten</b>, <b>Feuerlöscher Rauchmelder</b>, <b>Wärmeabzugsanlage</b> und <b>Entrauchung</b></li>\n" +
          "</ul>"
      case "GL_3_C_7":
        return "<ul>\n" +
          "<li>• Außenanlagen wie z.B. <b>Stellplatz</b>, <b>Garage</b>, <b>Carport</b>, <b>Zaunanlage</b>, <b>Terasse</b>, <b>Landschaftsgestaltung</b>, <b>Spielplatz</b>, <b>Rasenfläche</b>, <b>Bepflanzung</b> und <b>Außenbeleuchtung</b></li>\n" +
          "</ul>"
      case "GL_3_C_8":
        return "<ul>\n" +
          "<li>• Kosten wie z.B. <b>Rohbaukosten</b>, <b>Herstellungskosten</b> und <b>Finanzierung</b></li>\n" +
          "</ul>"
      case "GL_3_D_1":
        return "<ul>\n" +
          "<li>• Bebauungsplan wie z.B. <b>Bauvorschriften</b>, <b>Bauordnung</b>, <b>Planungskonzept</b>, <b>Flächennutzung</b>, <b>Gebäudenutzung</b> und <b>Grenzabstand</b></li>\n" +
          "</ul>"
      case "GL_3_D_2":
        return "<ul>\n" +
          "<li>• Antrag auf Befreiung wie z.B. <b>Abweichung</b>, <b>Änderung</b>, <b>§31 BauGB</b>, <b>Bauordnung</b>, <b>Satzung</b>, <b>Bebauungsplan</b> und <b>Baubehörde</b></li>\n" +
          "</ul>"
      case "GL_3_D_3":
        return "<ul>\n" +
          "<li>• Kein Bebauungsplan wie z.B. <b>Flächennutzungsplan</b>, <b>§35 BauGB</b>, <b>Baubehörde</b>, <b>Gebietscharakter</b>, <b>Maß der baulichen Nutzung</b> und <b>Versiegelung</b></li>\n" +
          "</ul>"
      case "GL_3_D_4":
        return "<ul>\n" +
          "<li>• Brandschutz wie z.B. <b>Brandschutzvorschriften</b>, <b>Brandschutznachweis</b>, <b>Brandschutzabteilung</b>, <b>Feuwerwehr</b>, <b>Rettungsweg</b>, <b>Feuwerwiderstand</b> und <b>Brandmeldeanlage</b></li>\n" +
          "</ul>"
      case "GL_3_D_5":
        return "<ul>\n" +
          "<li>• Weitere Genehmigungen wie z.B. <b>Denkmalschutz</b>, <b>Umweltschutz</b>, <b>Naturschutz</b>, <b>Lärmschutz</b>, <b>Wasserschutz</b>, <b>Arbeitsstätten</b>, <b>Gaststätten</b> und <b>Versammlungsstätten</b></li>\n" +
          "</ul>"
      case "GL_3_D_6":
        return "<ul>\n" +
          "<li>• Nachbarliche Zustimmung wie z.B. <b>Grundstücksgrenze</b>, <b>Abstandsfläche</b>, <b>Nachbargrundstück</b>, <b>Grenzbebauung</b>, <b>Nutzungsrecht</b> und <b>Freigabe</b></li>\n" +
          "</ul>"
      case "GL_3_E_1":
        return "<ul>\n" +
          "<li>• Grundflächen und Rauminhalte wie z.B. <b>Nettoraumflächen (NRF)</b>, <b>Bruttogeschossflächen (BGF)</b>, <b>Geschosshöhe</b>, <b>Fassadenfläche</b> und <b>Brutto- (BRI) und Nettorauminhalte (NRI)</b></li>\n" +
          "<li>• Kostengruppen wie z.B. <b>Grundstück</b>, <b>Baukonstruktion</b>, <b>Technische Anlagen</b>, <b>Kostengliederung</b>, <b>dritte Ebene</b>, <b>KG 330</b> und <b>KG 340.</b></li>\n" +
          "</ul>"
      case "GL_3_E_3":
        return "<ul>\n" +
          "<li>• Kostenelemente wie z.B. <b>Einheitspreise (EP)</b>, <b>Zwischensumme</b>, <b>Gesamtpreis (GP)</b>, <b>Angebotssumme</b>, <b>brutto</b> und <b>netto</b></li>\n" +
          "</ul>"
      case "GL_3_F_3":
        return "<ul>\n" +
          "<li>• Planung und Dokumentation wie z.B. <b>Fachplanung</b>, <b>Ausführungsplanung</b>, <b>Konzeptplanung</b>, <b>Objektplanung</b>, <b>Tragwerksplanung</b>, <b>Werkplanung</b>, <b>Montageplanung</b> und <b>Durchbruchsplanung</b></li>\n" +
          "<li>• Vorgänge und Gewerke wie z.B. <b>Rohbau</b>, <b>Ausbau</b>, <b>TGA</b>, <b>Erdbau</b>, <b>Abbruch</b>, <b>Elektro</b>, <b>Sanitär</b>, <b>HLS</b> und <b>Malerarbeiten</b></li>\n" +
          "<li>• Terminplan wie z.B. <b>Meilenstein</b>, <b>Beginn</b>, <b>Fertigstellung</b>, <b>Abnahme</b>, <b>Datum</b>, <b>Vorgangsdauer</b>, <b>Bauablauf</b>, <b>Vergabe</b> und <b>Ausführung</b></li>\n" +
          "</ul>"
      case "GL_3_F_2":
        return "<ul>\n" +
          "<li>• Beteiligte wie z.B. <b>Fachplaner*in</b>, <b>Gutachter*in</b> und <b>Tragwerksplaner*in</b></li>\n" +
          "<li>• Vorgänge und Meilensteine wie z.B. <b>Gründung</b>, <b>Rohbau</b>, <b>Aubau</b>, <b>Außenanlagen</b>, <b>Fertigstellung</b>, <b>Inbetriebnahme</b> und <b>Abnahme</b></li>\n" +
          "<li>• Planung wie z.B. <b>Objektplanung</b>, <b>Durchbruchsplanung</b>, <b>Schal- und Bewehrungspläne</b>, <b>Tragwerksplanung</b> und <b>Werkplanung</b></li>\n" +
          "</ul>"
      case "BL_3_1_1":
        return "<ul>\n" +
          "<li>• Lösungsansätze wie z.B. <b>Alternative</b>, <b>Variante</b>, <b>Nutzung</b>, <b>Funktion</b> und <b>Projektziel</b></li>\n" +
          "<li>• Gebäude wie z.B. <b>Nutzung</b>, <b>Nutzungsart</b>, <b>Gebäudefunktion</b> und <b>Nutzungskonzept</b></li>\n" +
          "</ul>"
      case "BL_3_1_2":
        return "<ul>\n" +
          "<li>• Lösungsansätze wie z.B. <b>Alternative</b>, <b>Variante</b>, <b>Nutzung</b>, <b>Funktion</b> und <b>Projektziel</b></li>\n" +
          "<li>• Kostenbewertung wie z.B. <b>Kostenuntersuchungen</b>, <b>Kostenprüfung</b>, <b>Kostenauswertung</b>, <b>Kostenzusammenstellung</b> und <b>Kostenvergleich</b></li>\n" +
          "<li>• Kosten wie z.B. <b>Gesamtkosten</b>, <b>Kostengruppe</b>, <b>Betrag</b> und <b>Pauschal</b></li>\n" +
          "</ul>"
      case "BL_3_2_1":
        return "<ul>\n" +
          "<li>• Berechnungs- und Analyseverfahren wie z.B. <b>Kostenvergleichsberechnung</b>, <b>Kapitalwertberechnung</b>, <b>Nutzwertanalyse</b>, <b>Kosten-Nutzen-Analyse</b>, <b>Amortisationsrechnung</b> und <b>Liquiditätsberechnung</b></li>\n" +
          "<li>• Berechnungen wie z.B. <b>Kostenberechnung</b>, <b>Renditeberechnung</b>, <b>Liquiditätsberechnung</b> und <b>Gesamtkostenberechnung</b></li>\n" +
          "<li>• Berechnungsergebnisse wie z.B. <b>Mehrkosten</b>, <b>Minderkosten</b>, <b>Barwert</b>, <b>Kapitalwert</b>, <b>Zeitwert</b>, <b>Abzinsungsfaktor</b>, <b>Jahresbetrag</b>, <b>Mieteinnahmen</b>, <b>Nutzwert</b>, <b>Einsparung</b> und <b>Amortisationszeit</b></li>\n" +
          "<li>• Kosten wie z.B. <b>Investitionskosten</b>, <b>Anschaffungskosten</b>, <b>Herstellungskosten</b>, <b>Projektkosten</b>, <b>Personalkosten</b>, <b>Sachkosten</b>, <b>Opportunitätskosten</b>, <b>Leerstandskosten</b>, <b>Einmalkosten</b> und <b>jährliche Kosten</b></li>\n" +
          "</ul>"
      case "BL_3_3_1":
        return "<ul>\n" +
          "<li>• Grundflächen und Rauminhalte wie z.B. <b>Nettoraumflächen (NRF)</b>, <b>Bruttogeschossflächen (BGF)</b>, <b>Geschosshöhe</b>, <b>Fassadenfläche</b> und <b>Brutto- (BRI) und Nettorauminhalte (NRI)</b></li>\n" +
          "<li>• Kostengruppen wie z.B. <b>Grundstück</b>, <b>Baukonstruktion</b>, <b>Technische Anlagen</b>, <b>Kostengliederung</b>, <b>dritte Ebene</b>, <b>KG 330</b> und <b>KG 340</b></li>\n" +
          "</ul>"
      case "BL_3_3_3":
        return "<ul>\n" +
          "<li>• Kostenelemente wie z.B. <b>Einheitspreise (EP)</b>, <b>Zwischensummen</b>, <b>Gesamtpreis (GP)</b>, <b>Angebotssumme</b>, <b>brutto</b> und <b>netto</b></li>\n" +
          "</ul>"
      case "BL_3_4_1":
        return "<ul>\n" +
          "<li>• Gliederung wie z.B. <b>Raumbuch</b>, <b>Raumprogramm</b>, <b>Raumliste</b>, <b>Raum-Nummer</b>, <b>Raumbezeichnung</b>, <b>Raumart</b>, <b>Raumbedarf</b> und <b>Funktion</b></li>\n" +
          "<li>• Ausrichtung wie z.B. <b>Himmelsrichtung</b>, <b>Geschoss</b>, <b>EG</b>, <b>OG</b>, <b>DG</b>, <b>Keller</b> und <b>Garage</b></li>\n" +
          "<li>• Abmessungen wie z.B. <b>Fläche</b>, <b>Raumfläche</b>, <b>Raumhöhe</b>, <b>Umfang</b>, <b>Grundfläche</b>, <b>Wohnfläche</b>, <b>Büro-NF</b>, <b>Verkaufsfläche</b> und <b>Geschossfläche</b></li>\n" +
          "<li>• Nutzungstypen wie z.B. <b>Wohnen</b>, <b>Schlafen</b>, <b>Büro</b>, <b>Küche</b>, <b>Bad</b>, <b>WC</b>, <b>Technik</b>, <b>Lager</b>, <b>Flur</b> und <b>Verkaufsräume</b></li>\n" +
          "</ul>"

        //LPH 4

      case "GL_4_AABA_else_1":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Baugrundstück</b>, <b>Gemarkung</b>, <b>Flurstück</b>, <b>Straße</b> und <b>Hausnummer</b></li>\n" +
          "<li>• Befreiungsgründe wie z.B. <b>Ausnahmegründe</b>, <b>Gegenstand der Befreiung</b>, <b>Kompensationsmaßnahmen</b> und <b>Genehmigungsfreistellung</b></li>\n" +
          "<li>• Bauherrenschaft wie z.B. <b>Antragsteller</b>, <b>Bauherr</b>, <b>Bauherrenschaft</b>, <b>Firma</b> und <b>Name</b></li>\n" +
          "</ul>"
      case "GL_4_Abstandsflaechen_HB_HE_SL_1":
        return "<ul>\n" +
          "<li>• Abstandsflächen wie z.B. <b>Bebauungsgrenze</b>, <b>Bauordnungsrecht</b>, <b>Grenzabstand</b> und <b>Abstandsflächenberechnung</b></li>\n" +
          "</ul>"
      case "GL_4_Abstandsflaechenuebernahme_BY_1":
        return "<ul>\n" +
          "<li>• Abstandsflächenübernahme wie z.B. <b>Nachbargrundstück</b>, <b>Baugrundstück</b>, <b>Abstand</b> und <b>Erklärung</b></li>\n" +
          "</ul>"
      case "GL_4_amtliche_Basiskarte_NW_1":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Baugrundstück</b>, <b>Standort</b>, <b>Lage</b>, <b>Gemeinde</b>, <b>Umfeld</b>, <b>Gemarkung</b>, <b>Flur</b> und <b>Nachbar</b></li>\n" +
          "</ul>"
      case "GL_4_amtliche_Basiskarte_NW_3":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100</b>, <b>1:250</b>, <b>1:500</b>, <b>1:1000</b> und <b>1:2000</b></li>\n" +
          "</ul>"
      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_1":
        return "<ul>\n" +
          "<li>• Projektbeteiligte wie z.B. <b>Bauherr*in</b>, <b>Architekt*in</b>, <b>Planer*in</b>, <b>Projekt</b> und <b>Bauvorhaben</b></li>\n" +
          "</ul>"
      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_2":
        return "<ul>\n" +
          "<li>• Gebäudenutzung wie z.B. <b>Nutzung</b> und <b>Gebäude</b></li>\n" +
          "<li>• Geschossanzahl wie z.B. <b>Geschoss</b> und <b>Anzahl</b></li>\n" +
          "</ul>"
      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_3":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100</b>, <b>1:250</b>, <b>1:500</b>, <b>1:1000</b> und <b>1:2000</b></li>\n" +
          "</ul>"
      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_4":
        return "<ul>\n" +
          "<li>• Flurstück wie z.B. <b>Flur</b>, <b>Flurstück</b>, <b>Flurstücksnummer</b> und <b>Straße</b></li>\n" +
          "</ul>"
      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_5":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Grundstücksgrenze</b>, <b>Baugrenze</b>, <b>Baulinie</b>, <b>Abstand</b> und <b>Abstandsflächen</b></li>\n" +
          "</ul>"
      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_6":
        return "<ul>\n" +
          "<li>• Stellplätze wie z.B. <b>PKW-Stellplätze</b>, <b>Fahrrad</b> und <b>Fahrradstellplätze</b></li>\n" +
          "</ul>"
      case "GL_4_amtlicher_Lageplan_BB_BE_BY_NI_SL_ST_7":
        return "<ul>\n" +
          "<li>• Höhenlage wie z.B. <b>Höhen</b>, <b>Gelände</b>, <b>Höhenlage</b>, <b>NN</b>, <b>NHN</b>, <b>üNN</b> und <b>Höhenbezugssystem</b></li>\n" +
          "<li>• Dach wie z.B. <b>Dachhöhe</b>, <b>First</b>, <b>Attika</b>, <b>Form</b> und <b>Gebäudehöhe</b></li>\n" +
          "</ul>"
      case "GL_4_Angaben_Beherbergungsbetten_NW_1":
        return "<ul>\n" +
          "<li>• Beherbergungsstätte wie z.B. <b>Beherbergungsbetrieb</b>, <b>Betrieb</b> und <b>Versammlungsstätte</b></li>\n" +
          "</ul>"
      case "GL_4_Angaben_Beherbergungsbetten_NW_1":
        return "<ul>\n" +
          "<li>• Unterkunftsplätze wie z.B. <b>Betten</b>, <b>Auslastungsgrad</b>, <b>Übernachtungen</b>, <b>Gäste</b> und <b>Aufenthaltsdauer</b></li>\n" +
          "</ul>"
      case "GL_4_Angaben_Beherbergungsstaette_ohne_Brandschutzkonzept_NW_1":
        return "<ul>\n" +
          "<li>• Betrieb wie z.B. <b>Beherbergungsbetrieb</b>, <b>Beherbergungsstätte</b> und <b>Versammlungsstätte</b></li>\n" +
          "<li>• Belegung wie z.B. <b>Betten</b>, <b>Auslastungsgrad</b>, <b>Übernachtungen</b>, <b>Gäste</b> und <b>Aufenthaltsdauer</b></li>\n" +
          "<li>• Sicherheit wie z.B. <b>Brandschutz</b>, <b>Brandmeldeanlage</b>, <b>Rettungswege</b>, <b>Rettungsplan</b>, <b>Beleuchtung</b> und <b>Stromversorgung</b></li>\n" +
          "</ul>"
      case "GL_4_Angaben_CO_Warnanlage_NW_1":
        return "<ul>\n" +
          "<li>• Anlage wie z.B. <b>Warnanlage</b>, <b>Überwachungsanlage</b>, <b>Abluftanlage</b>, <b>Zuluftanlage</b> und <b>Feuerlöschanlage</b></li>\n" +
          "<li>• Positionierung wie z.B. <b>Lage</b>, <b>Ort</b>, <b>Standort</b> und <b>Garage</b></li>\n" +
          "</ul>"
      case "GL_4_Angaben_zu_gewerblichen_Anlagen_BW_1":
        return "<ul>\n" +
          "<li>• Gewerbe wie z.B. <b>Branche</b>, <b>gewerbliche Tätigkeit</b>, <b>Sozialanlagen</b> und <b>Zahl der Beschäftigten</b></li>\n" +
          "<li>• Anlage wie z.B. <b>Maschine</b>, <b>Schutzmaßnahmen</b>, <b>Lärmemissionen</b>, <b>Schalemissionen</b>, <b>Gefahrstoffe</b>, <b>Erschütterungen</b> und <b>Gase</b></li>\n" +
          "</ul>"
      case "GL_4_Angaben_zum_Betriebsraum_NW_1":
        return "<ul>\n" +
          "<li>• Lage wie z.B. <b>Bereich</b>, <b>Geschoss</b> und <b>Anordnung</b></li>\n" +
          "<li>• Raumbezeichnung wie z.B. <b>Haustechnik</b>, <b>Technikraum</b>, <b>elektrische Anlagen</b> und <b>elektrischer Betriebsraum</b></li>\n" +
          "<li>• Technik wie z.B. <b>elektrische Anlagen</b>, <b>Schaltanlage</b>, <b>Sprinkleranlage</b>, <b>Rauchabzugsanlage</b>, <b>Hauptverteiler</b> und <b>Zentralbatterie</b></li>\n" +
          "</ul>"
      case "GL_4_Angaben_zum_Krankenhaus_NW_1":
        return "<ul>\n" +
          "<li>• Belegung wie z.B. <b>Anzahl der Personen</b>, <b>Patienten</b> und <b>Betten</b></li>\n" +
          "<li>• Behandlungsräume wie z.B. <b>Behandlungszimmer</b>, <b>Nutzung</b>, <b>Stahlung</b>, <b>Röntgen</b> und <b>CT</b></li>\n" +
          "</ul>"
      case "GL_4_Anlagen_Gebuehren_HH_1":
        return "<ul>\n" +
          "<li>• Gebühren wie z.B. <b>Gebührenzustimmungsantrag</b> und <b>Anlage 2 BauGeBo</b></li>\n" +
          "</ul>"
      case "GL_4_anrechenbare_Kosten_MV_ST_SH_1":
        return "<ul>\n" +
          "<li>• Kosten wie z.B. <b>anrechenbare Kosten</b>, <b>Rohbaukosten</b>, <b>Fertigungskosten</b>, <b>KG 300</b> und <b>KG 400</b></li>\n" +
          "</ul>"
      case "GL_4_anrechenbarer_Bauwert_HB_1":
        return "<ul>\n" +
          "<li>• Einheit wie z.B. <b>Brutto-Rauminhalt (BRI)</b>, <b>Euro / m<sup>3</sup></b>, <b>pro Stück</b> und <b>pro Tonne</b></li>\n" +
          "<li>• Gebäudetyp wie z.B. <b>Wohngebäude</b>, <b>Schule</b>, <b>Verwaltungsgebäude</b>, <b>Verkaufsstätten</b>, <b>Sportstätten</b> und <b>Garagen</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_BW_NW_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>Gelände</b>, <b>1:100</b>, <b>1:200</b>, <b>1:50</b> und <b>1:250</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_BW_NW_2":
        return "<ul>\n" +
          "<li>• Höhenlage Gelände wie z.B. <b>OK Gelände</b>, <b>OKG</b>, <b>Höhe</b> und <b>Höhenknoten</b></li>\n" +
          "<li>• Höhenlage Fußboden wie z.B. <b>Fertigfußboden</b>, <b>Rohfußboden</b>, <b>OKFFB</b>, <b>OK FB</b>, <b>OK RF</b>, <b>EG</b>, <b>OG</b>, <b>UG</b> und <b>DG</b></li>\n" +
          "<li>• Höhenlage Decke wie z.B. <b>Fertigdecke</b>, <b>Rohdecke</b>, <b>UK</b>, <b>UK RD</b> und <b>OK RD</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_BW_NW_3":
        return "<ul>\n" +
          "<li>• Fensteröffnungen wie z.B. <b>Fenster</b>, <b>Öffnung</b>, <b>Dreh</b>, <b>Kipp</b>, <b>Lichtband</b>, <b>Fensterrahmen</b> und <b>Rolladen</b></li>\n" +
          "<li>• Türenöffnungen wie z.B. <b>Tür</b>, <b>Öffnung</b>, <b>Tor</b> und <b>Sektionaltor</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_BW_NW_4":
        return "<ul>\n" +
          "<li>• Gelände wie z.B. <b>Geländeoberfläche</b>, <b>NHN</b> und <b>NN</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_else_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100</b>, <b>1:200</b>, <b>1:50</b> und <b>1:250</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_else_2":
        return "<ul>\n" +
          "<li>• Höhenlage Gelände wie z.B. <b>OK Gelände</b>, <b>OKG</b>, <b>Höhe</b> und <b>Höhenknoten</b></li>\n" +
          "<li>• Höhenlage Decke wie z.B. <b>Fertigdecke</b> und <b>Rohdecke</b></li>\n" +
          "<li>• Höhenlage Fußboden wie z.B. <b>Fertigfußboden</b>, <b>Rohfußboden</b>, <b>OKFFB</b>, <b>EG</b>, <b>OG</b>, <b>UG</b> und <b>DG</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_else_3":
        return "<ul>\n" +
          "<li>• Fensteröffnungen wie z.B. <b>Fenster</b>, <b>Öffnung</b>, <b>Dreh</b>, <b>Kipp</b>, <b>Lichtband</b>, <b>Fensterrahmen</b> und <b>Rolladen</b></li>\n" +
          "<li>• Türöffnungen wie z.B. <b>Tür</b>, <b>Öffnung</b>, <b>Tor</b> und <b>Sektionaltor</b></li>\n" +
          "<li>• Lage wie z.B. <b>OK FFB</b>, <b>OK Geländer</b> und <b>OK Brüstung</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_else_4":
        return "<ul>\n" +
          "<li>• Material wie z.B. <b>Baustoff</b>, <b>Fassade</b>, <b>Dachhaut</b>, <b>Mauerwerk</b>, <b>Stahl</b>, <b>Beton</b> und <b>Holz</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_else_5":
        return "<ul>\n" +
          "<li>• Geländeoberfläche wie z.B. <b>Gelände</b>, <b>NHN</b> und <b>NN</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_SL_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100</b>, <b>1:200</b>, <b>1:50</b> und <b>1:250</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_SL_2":
        return "<ul>\n" +
          "<li>• Höhenlage Gelände wie z.B. <b>OK Gelände</b>, <b>OKG</b>, <b>Höhe</b> und <b>Höhenknoten</b></li>\n" +
          "<li>• Höhenlage Fußboden wie z.B. <b>Fertigdecke</b> und <b>Rohdecke</b></li>\n" +
          "<li>• Höhenlage Decke wie z.B. <b>Fertigfußboden</b>, <b>Rohfußboden</b>, <b>OKFFB</b>, <b>EG</b>, <b>OG</b>, <b>UG</b> und <b>DG</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_SL_3":
        return "<ul>\n" +
          "<li>• Fensteröffnungen wie z.B. <b>Fenster</b>, <b>Öffnung</b>, <b>Dreh</b>, <b>Kipp</b>, <b>Lichtband</b>, <b>Fensterrahmen</b> und <b>Rolladen</b></li>\n" +
          "<li>• Türöffnungen wie z.B. <b>Tür</b>, <b>Öffnung</b>, <b>Tor</b> und <b>Sektionaltor</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_SL_4":
        return "<ul>\n" +
          "<li>• Dach wie z.B. <b>Gelände</b>, <b>OK First</b>, <b>Höhe des Firsts</b> und <b>OK Attika</b></li>\n" +
          "</ul>"
      case "GL_4_Ansicht_SL_5":
        return "<ul>\n" +
          "<li>• Geländeoberfläche wie z.B. <b>Gelände</b>, <b>NHN</b> und <b>NN</b></li>\n" +
          "</ul>"
      case "GL_4_Antrag_auf_Abloesung_notwendiger_Einstellplaetze_NI_1":
        return "<ul>\n" +
          "<li>• Antrag auf Ablösung wie z.B. <b>Gemeindliche Zustimmung</b>, <b>Ablösesumme</b>, <b>Stellplatzverpflichtung</b> und <b>Garage</b></li>\n" +
          "</ul>"
      case "GL_4_Anzahl_Fahrradstellplaetze_RP_1":
        return "<ul>\n" +
          "<li>• Fahrradstellplätze wie z.B. <b>Stellplatz (Stp.)</b>, <b>Zugänglichkeit</b>, <b>Fahrradhalter</b> und <b>fußläufige Entfernung</b></li>\n" +
          "</ul>"
      case "GL_4_ArbeitsstaettenVO_RP_1":
        return "<ul>\n" +
          "<li>• Räume wie z.B. <b>Pausenräume</b>, <b>Erste-Hilfe-Räume</b> und <b>Waschräume</b></li>\n" +
          "<li>• Raumtemperaturen wie z.B. <b>Heizung</b>, <b>Wärmestrahler</b> und <b>Kältearbeitsplatz</b></li>\n" +
          "<li>• Beleuchtung wie z.B. <b>Künstliche Beleuchtung</b>, <b>Sicherheitsbeleuchtung</b> und <b>Rettungswege</b></li>\n" +
          "<li>• Lüftung wie z.B. <b>Belüftung</b>, <b>Lüftungstechnische Anlagen</b>, <b>Mindestluftwechsel</b> und <b>Arbeitsbereich</b></li>\n" +
          "<li>• Schallschutz wie z.B. <b>Lärm am Arbeitsplatz</b>, <b>Beurteilungspegel</b> und <b>db(A)</b></li>\n" +
          "</ul>"
      case "GL_4_Art_der_baulichen_Nutzung_else_1":
        return "<ul>\n" +
          "<li>• Art der baulichen Nutzung wie z.B. <b>Kleinsiedlungsgebiet</b>, <b>Mischgebiet</b> und <b>Urbane Gebiete</b></li>\n" +
          "</ul>"
      case "GL_4_Artenschutz_else_1":
        return "<ul>\n" +
          "<li>• Grundlagen wie z.B. <b>Bebauung</b>, <b>Plangebiet</b> und <b>Lebensraum</b></li>\n" +
          "<li>• Methodik wie z.B. <b>geschützte Arten</b>, <b>Biotope</b>, <b>Flora</b> und <b>Fauna</b></li>\n" +
          "<li>• Auswirkungen wie z.B. <b>Boden</b>, <b>Klima</b>, <b>Revier</b> und <b>Fortpflanzungs- und Ruhestätten</b></li>\n" +
          "<li>• Maßnahmen wie z.B. <b>siedeln</b>, <b>Verminderungsmaßnahme</b> und <b>Verbotstatbestand</b></li>\n" +
          "</ul>"
      case "GL_4_Aussenanlagenplan_BE_1":
        return "<ul>\n" +
          "<li>• Außenanlagenplan wie z.B. <b>Rasen</b>, <b>Wasserfläche</b>, <b>Asphalt</b>, <b>Müllplatz</b>, <b>Fahrradstellplatz</b>, <b>Baumpflanzung</b> und <b>Entwässerung</b></li>\n" +
          "</ul>"
      case "GL_4_Auszug_Bebauungsplan_HB_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:1000</b></li>\n" +
          "</ul>"
      case "GL_4_Auszug_Bebauungsplan_HB_2":
        return "<ul>\n" +
          "<li>• Legende wie z.B. <b>Zeichenerklärung</b>, <b>Art der baulichen Nutzung</b>, <b>Gewerbeklasse</b> und <b>Mischgebiet</b></li>\n" +
          "</ul>"
      case "GL_4_Auszug_Bebauungsplan_HB_3":
        return "<ul>\n" +
          "<li>• Umgebung des Grundstücks wie z.B. <b>Straße</b>, <b>Post</b>, <b>Brücke</b>, <b>Straßenbahn</b> und <b>Weserufer</b></li>\n" +
          "</ul>"
      case "GL_4_Auszug_Bebauungsplan_SL_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:500</b></li>\n" +
          "</ul>"
      case "GL_4_Auszug_Bebauungsplan_SL_2":
        return "<ul>\n" +
          "<li>• Planzeichenerklärung wie z.B. <b>Art der baulichen Nutzung</b>, <b>Baugrenze</b>, <b>Höhe über NN</b> und <b>Planzeichen</b></li>\n" +
          "</ul>"
      case "GL_4_Auszug_Bebauungsplan_SL_3":
        return "<ul>\n" +
          "<li>• Planzeichnung wie z.B. <b>Straße</b>, <b>Brücke</b>, <b>Straßenbahn</b>, <b>Rathaus</b> und <b>Sportanlage</b></li>\n" +
          "</ul>"
      case "GL_4_Auszug_Bebauungsplan_SL_4":
        return "<ul>\n" +
          "<li>• Festetzungen wie z.B. <b>Maß der baulichen Nutzung</b>, <b>Verkehrsflächen</b>, <b>Pflanzen von Sträuchern</b> und <b>Baumpflanzung</b></li>\n" +
          "</ul>"
      case "GL_4_Auszug_Bebauungsplan_SL_5":
        return "<ul>\n" +
          "<li>• Gesetzliche Grundlage wie z.B. <b>Bund</b>, <b>Saarländisches Landplaungsgesetz</b> und <b>LBO</b></li>\n" +
          "</ul>"
      case "GL_4_AVK_BW_NW_1":
        return "<ul>\n" +
          "<li>• Allgemeine Angaben wie z.B. <b>Bauherr</b>, <b>Bauvorhaben</b>, <b>Gemeinde</b>, <b>Flurstück</b> und <b>Postleitzahl</b></li>\n" +
          "</ul>"
      case "GL_4_AVK_BW_NW_2":
        return "<ul>\n" +
          "<li>• Abfallverwertungskonzept für den Bodenaushub wie z.B. <b>Bodenaushubmaterial</b>, <b>Anfallmenge</b> und <b>Deponie</b></li>\n" +
          "</ul>"
      case "GL_4_AVK_BW_NW_3":
        return "<ul>\n" +
          "<li>• Abfallverwertungskonzept wie z.B. <b>Maßnahmen</b>, <b>Baubeginn</b>, <b>Gebäudeteile</b> und <b>Nutzung</b></li>\n" +
          "<li>• Schadstoffuntersuchung wie z.B. <b>asbesthaltig</b>, <b>teerhaltig</b>, <b>Glas</b>, <b>Keramik</b>, <b>Abfälle</b> und <b>Recycling</b></li>\n" +
          "</ul>"
      case "GL_4_Bauartgenehmigung_else_1":
        return "<ul>\n" +
          "<li>• Technische Bestimmungen wie z.B. <b>technische Regel</b>, <b>abZ</b>, <b>aBg</b> und <b>abP</b></li>\n" +
          "<li>• Beschreibung wie z.B. <b>Beschreibung</b>, <b>Abweichung</b>, <b>Erleichterung</b> und <b>Anforderungen</b></li>\n" +
          "<li>• Zeichnerische Darstellung wie z.B. <b>Zeichnerische Darstellung</b>, <b>Grundriss</b>, <b>Schnitt</b>, <b>Lageplan</b> und <b>Konstruktionszeichnung</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_else_1":
        return "<ul>\n" +
          "<li>• Lage und Region wie z.B. <b>Bundesland</b>, <b>Stadt</b>, <b>Gemeinde</b> und <b>Adresse</b></li>\n" +
          "<li>• Positionierung wie z.B. <b>Landesgrenze</b>, <b>Nachbarbebauung</b>, <b>Abstand</b>, <b>Ausrichtung</b>, <b>Nord</b>, <b>West</b> und <b>Süd</b></li>\n" +
          "<li>• Grundstücksmerkmale wie z.B. <b>Flurstücknummer</b>, <b>bebaubare Fläche</b>, <b>Gelände</b> und <b>Gemarkung</b></li>\n" +
          "<li>• Infrastruktur wie z.B. <b>Straßen</b> und <b>öffentliche Verkehrsmittel- und Einrichtungen</b></li>\n" +
          "<li>• Beteiligte wie z.B. <b>Architekt</b>, <b>Auftraggeber</b>, <b>Entwurfsverfasser</b> und <b>Bauherr</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_else_2":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Baugrenze</b>, <b>Gelände</b>, <b>Gefälle</b>, <b>Grunstücksbeshaffenheit</b>, <b>Rasenfläche</b>, <b>Waldung</b>, <b>Moorfläche</b>, <b>Heidefläche</b>, <b>Plangebiet</b> und <b>Ackerfläche</b></li>\n" +
          "<li>• Baugrund wie z.B. <b>Bodenklasse</b>, <b>Homogenbereich</b>, <b>Gestein</b>, <b>Kies</b>, <b>Sand</b>, <b>Schluff</b>, <b>Ton</b>, <b>Grundwasser</b> und <b>wassergefährdende Stoffe</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_else_3":
        return "<ul>\n" +
          "<li>• Erschließung wie z.B. <b>Erschließung</b>, <b>Zufahrt</b>, <b>Ausfahrt</b>, <b>Rampe</b>, <b>Fahrweg</b>, <b>Eingang</b>, <b>ÖPNV</b> und <b>Autobahn</b></li>\n" +
          "<li>• Ver-und Entsorgung wie z.B. <b>Versorgung</b>, <b>entsorgung</b>, <b>Wassserversorgungsnetz</b>, <b>Gasversorgungsnetz</b>, <b>Stromanschluss</b>, <b>Kanalisation</b>, <b>Abwasserbeseitigung</b> und <b>Abfallkonzept</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_else_4":
        return "<ul>\n" +
          "<li>• Gründung wie z.B. <b>Gründungsart</b>, <b>Flachgründung</b>, <b>Pfahlgründung</b>, <b>Streifenfundament</b>, <b>Einzelfundament</b> und <b>Bodenplatte</b></li>\n" +
          "<li>• Tragwerk wie z.B. <b>Baukonstruktion</b>, <b>Skelettbauweise</b>, <b>Massivbauweise</b>, <b>Tragkonstruktion</b>, <b>Bodenbelastbarkeit</b>, <b>Lastabtrag</b> und <b>Aussteifung</b></li>\n" +
          "<li>• Bauteile und Aufbauten wie z.B. <b>Außenwand</b>, <b>Lochfassade</b>, <b>Sandwichelemente</b>, <b>Innenwand</b>, <b>Brandwand</b>, <b>Stütze</b>, <b>Decke</b>, <b>Dachtragwerk</b>, <b>Dachaufbau</b> und <b>Fertigteil</b></li>\n" +
          "<li>• Material wie z.B. <b>Baustoffart</b>, <b>Ortbeton</b>, <b>WU-Beton</b>, <b>Stahlfaserbeton</b> und <b>Mauerwerk</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_else_5":
        return "<ul>\n" +
          "<li>• Elektroinstallation wie z.B. <b>Elektroplanung</b>, <b>Stromversorgung</b>, <b>Beleuchtung</b>, <b>Kabelkanäle</b> und <b>Stromleitungen</b></li>\n" +
          "<li>• Wärmeerzeugung und -verteilung wie z.B. <b>Wärmeversorgung</b>, <b>Heizungsanlagen</b>, <b>Fußbodenheizung</b>, <b>Feuerstätte</b>, <b>Abgasanlage</b>, <b>Fernwärme</b>, <b>Brennstoff</b> und <b>Elektroheizung</b></li>\n" +
          "<li>• Kälteanlagen und Lüftungssysteme wie z.B. <b>Lüftung</b>, <b>Wärmerückgewinngungsanlage</b>, <b>Kühlung</b>, <b>Klimatechnik</b> und <b>HKL-Anlage</b></li>\n" +
          "<li>• weitere Gebäudetechnik wie z.B. <b>Aufzuganlage</b>, <b>Blitzschutzanlage</b> und <b>Störmeldesysteme</b></li>\n" +
          "<li>• Sanitäranlagen wie z.B. <b>Wasseranschluss</b>, <b>Warmwasser</b>, <b>Duchlauferhitzer</b>, <b>Entwässerung</b>, <b>Abwasser</b>, <b>Schmutzwasser</b>, <b>Sanitärräume</b> und <b>WC-Anlagen</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_else_6":
        return "<ul>\n" +
          "<li>• Brandschutzkonzept wie z.B. <b>Brandschutznachweis</b>, <b>Brandabschnitt</b>, <b>Fluchplan</b>, <b>Rettungsplan</b>, <b>Notausgangstür</b>, <b>Brandschutzmaterial</b>, <b>F30</b> und <b>F90</b></li>\n" +
          "<li>• Melde- und Löscheinrichtungen wie z.B. <b>Branderkennung</b>, <b>Sprinkeranlage</b>, <b>Wandhydranten</b>, <b>Feuerlöscher Rauchmelder</b>, <b>Wärmeabzugsanlage</b> und <b>Entrauchung</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_else_7":
        return "<ul>\n" +
          "<li>• Außenanlagen wie z.B. <b>Stellplatz</b>, <b>Garage</b>, <b>Carport</b>, <b>Zaunanlage</b>, <b>Terasse</b>, <b>Landschaftsgestaltung</b>, <b>Spielplatz</b>, <b>Rasenfläche</b>, <b>Bepflanzung</b> und <b>Außenbeleuchtung</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_else_8":
        return "<ul>\n" +
          "<li>• Kosten wie z.B. <b>Rohbaukosten</b>, <b>Herstellungskosten</b>, <b>Finanzierung</b>, <b>Wirtschaftlichkeit</b> und <b>Euro</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_Fluessiggas_1_RP_1":
        return "<ul>\n" +
          "<li>• Lagerung wie z.B. <b>Art der Lagerung</b>, <b>Sicherung gegen Auftrieb</b>, <b>Sicherheitseinrichtung</b>, <b>Schutzmaßnahmen</b> und <b>Wasserschutzgebiet</b></li>\n" +
          "<li>• Behälter wie z.B. <b>Lagermenge</b>, <b>Fassungsvermögen</b>, <b>einwandig</b>, <b>doppelwandig</b> und <b>Betriebsüberdruck</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_Fluessiggas_3_RP_1":
        return "<ul>\n" +
          "<li>• Lagerung wie z.B. <b>Flüssiggas</b>, <b>Grundstücksgrenze</b>, <b>Schutzgebiete</b>, <b>Erddeckung</b>, <b>Sicherung gegen Auftrieb</b> und <b>Schutzwand</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_Heizoel_1_RP_1":
        return "<ul>\n" +
          "<li>• Lagerung wie z.B. <b>Lagermenge</b>, <b>Anzahl der Behälter</b>, <b>Auffangraum</b>, <b>einwandig</b>, <b>doppelwandig</b>, <b>Rohrleitungen</b> und <b>Füll- und Netlüftungseinrichrungen</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_Heizoel_1_RP_2":
        return "<ul>\n" +
          "<li>• Verwendbarkeitsnachweise wie z.B. <b>Sicherheitseinrichtungen</b>, <b>Formstücke</b>, <b>Technische Baubestimmungen</b> und <b>bauaufsichtliche Zulassungen</b></li>\n" +
          "</ul>"
      case "GL_4_Baubeschreibung_Heizoel_3_RP_1":
        return "<ul>\n" +
          "<li>• Lagerung wie z.B. <b>Abstände der Behälter untereinander</b>, <b>Behälter</b>, <b>Auffangraum</b>, <b>Grunstücksgrenzen</b> und <b>Dicke der Erddeckung</b></li>\n" +
          "</ul>"
      case "GL_4_Baukosten_HB_1":
        return "<ul>\n" +
          "<li>• Baukosten wie z.B. <b>Berechnung</b>, <b>BRI</b>, <b>Preisindex</b>, <b>Baukostenwert</b>, <b>Herstellung</b>, <b>KG 330</b>, <b>KG340</b>, <b>KG</b>, <b>420</b> und <b>Euro</b></li>\n" +
          "</ul>"
      case "GL_4_Baukostenwert_HB_1":
        return "<ul>\n" +
          "<li>• Baukostenwert wie z.B. <b>Euro pro Kubikmeter</b>, <b>Gebäudeart</b>, <b>Wohngebäude</b> und <b>Fabrikgebäude</b></li>\n" +
          "</ul>"
      case "GL_4_Baumbestandsbescheinigung_1_HB_1":
        return "<ul>\n" +
          "<li>• Informationen zum Bestand wie z.B. <b>Baumbestand</b>, <b>Kronendurchmesser</b>, <b>Vitalitätsschaden</b>, <b>Stammumfang</b> und <b>Wurzelbereich</b></li>\n" +
          "<li>• vorgesehene Maßnahmen wie z.B. <b>Fällung</b>, <b>Fortbestand</b> und <b>Entfernung</b></li>\n" +
          "</ul>"
      case "GL_4_Baumbestandsbescheinigung_2_HB_1":
        return "<ul>\n" +
          "<li>• Baumbestand wie z.B. <b>Fällarbeiten</b>, <b>Nummerierung</b>, <b>Fortbestand</b> und <b>Baumfällung</b></li>\n" +
          "</ul>"
      case "GL_4_Baumbestandsbescheinigung_HH_1":
        return "<ul>\n" +
          "<li>• Baumbestandsbescheinigung wie z.B. <b>Stammdurchmesser</b>, <b>Kronendurchmesser</b>, <b>Baumart</b>, <b>Fällen</b>, <b>Bescheiden</b> und <b>Rodung</b></li>\n" +
          "</ul>"
      case "GL_4_Baunebenrecht_HB_1":
        return "<ul>\n" +
          "<li>• Baunebenrecht wie z.B. <b>3 Nummer 9 BremBauVorlV</b>, <b>Arbeitsstätten- und Anlagenrecht</b>, <b>besonderes Städtebaurecht</b>, <b>öffentlich-rechtliche Vorschriften</b>, <b>Denkmalschutzrecht</b> und <b>Naturschutzrecht</b></li>\n" +
          "</ul>"
      case "GL_4_Bauvorlageberechtigung_else_1":
        return "<ul>\n" +
          "<li>• Berufsbezeichnung wie z.B. <b>Ingenieur*in</b>, <b>Architekt*in</b>, <b>Landschaftsarchitekt*in</b> und <b>Entwurfsverfasser*in</b></li>\n" +
          "<li>• Bundesland wie z.B. <b>Nordrhein-Westfalen</b>, <b>Bayern</b>, <b>Berlin</b>, <b>Bremen</b> und <b>Hamburg</b></li>\n" +
          "<li>• Bescheinigung wie z.B. <b>Bauvorlageberechtigung</b>, <b>Ingenieurkammer-Bau</b>, <b>Architektenkammer</b>, <b>Baukammer</b> und <b>Mitgliedsnummer</b></li>\n" +
          "</ul>"
      case "GL_4_Bauwert_HB_1":
        return "<ul>\n" +
          "<li>• Bauwert wie z.B. <b>anrechenbare Bauwerte</b>, <b>umbauter Raum</b>, <b>BRI</b>, <b>Indexzahl</b>, <b>Gebäudeart</b> und <b>Euro</b></li>\n" +
          "</ul>"
      case "GL_4_beabsichtigte_Nutzung_ST_1":
        return "<ul>\n" +
          "<li>• Nutzung wie z.B. <b>Bauherr</b>, <b>Eigentümerin</b>, <b>Bauvorhaben</b>, <b>Baugrundstück</b> und <b>Nutzungsaufnahme</b></li>\n" +
          "</ul>"
      case "GL_4_Benennung_Bauleitender_BW_SH_1":
        return "<ul>\n" +
          "<li>• Projektangaben wie z.B. <b>Bauvorhaben</b>, <b>Baugrundstück</b>, <b>Bauherr</b> und <b>Kunde</b></li>\n" +
          "<li>• Behörde wie z.B. <b>Bauaufsichtsbehörde</b>, <b>Baurechtsamt</b> und <b>Baurechtsbehörde</b></li>\n" +
          "<li>• Bauleitung wie z.B. <b>Name</b>, <b>Anschrift</b>, <b>Unterschrift</b> und <b>Datum</b></li>\n" +
          "</ul>"
      case "GL_4_Berechnung_Verkaufsstaette_NW_1":
        return "<ul>\n" +
          "<li>• Verkaufsräume wie z.B. <b>Verkaufsstätte</b>, <b>Flächenberechnung</b>, <b>Brandabschnitte</b>, <b>Breiten</b>, <b>Ausgänge</b> und <b>Treppenräume</b></li>\n" +
          "</ul>"
      case "GL_4_Bericht_ueber_den_geprueften_Brandschutznachweis_BE_1":
        return "<ul>\n" +
          "<li>• Bericht wie z.B. <b>Prüfingenieur</b>, <b>Gebäudeklasse</b>, <b>Technische Bestimmungen</b>, <b>Brandschutznachweis</b>, <b>Abweichungen</b>, <b>bauordnungsrechtliche Anforderungen</b>, <b>§67 Abs</b>, <b>1 BauO Bln</b> und <b>§ 51 BauO Bln</b></li>\n" +
          "</ul>"
      case "GL_4_Bericht_ueber_den_geprueften_Standsicherheitnachweis_BE_1":
        return "<ul>\n" +
          "<li>• Bauprojekt wie z.B. <b>Bauvorhaben</b>, <b>Lage</b>, <b>Grundstück</b>, <b>Bauweise</b> und <b>Baubeschreibung</b></li>\n" +
          "<li>• Standsicherheit wie z.B. <b>Standsicherheitsnachweis</b>, <b>statische Berechnung</b>, <b>tragende Bauteile</b>, <b>Baugrundgutachten</b>, <b>Feuerwiderstandsfähigkeit</b>, <b>Gründung</b> und <b>Aussteifung</b></li>\n" +
          "<li>• Dokumentation wie z.B. <b>Prüfbericht</b>, <b>Prüfbescheinigung</b>, <b>Typenprüfung</b>, <b>Technische Bewertung</b>, <b>Prüfsachverständiger</b> und <b>Konstruktionszeichnungen</b></li>\n" +
          "</ul>"
      case "GL_4_Bescheinigung_nach_67_Abs_4_LBO_SL_1":
        return "<ul>\n" +
          "<li>• Bescheinigung über die Prüfung des Prüfsachverständigen wie z.B. <b>Bauvorhaben</b>, <b>Bauherr</b>, <b>Baugrundstück</b>, <b>Standsicherheitsnachweis</b>, <b>Konstruktionszeichnungen</b>, <b>Tragwerksplaner</b>, <b>Brandschutznachweis</b>, <b>Feuerwiderdstandsdauer</b> und <b>brandschutztechnische Vorschriften</b></li>\n" +
          "</ul>"
      case "GL_4_Beschreibung_barrierefreie_Wohnungen_BE_1":
        return "<ul>\n" +
          "<li>• Barrierefreiheit wie z.B. <b>Bauaufsichtsbehörde</b>, <b>§9 Satz 3</b>, <b>Formular 170</b>, <b>Entwurfsverfasser</b> und <b>barrierefreie Erschließung innerhalb des Gebäudes</b></li>\n" +
          "</ul>"
      case "GL_4_Beschreibung_Baugrundstueck_SL_1":
        return "<ul>\n" +
          "<li>• Grundstücks- und Baubeschreibung wie z.B. <b>Bauherr</b>, <b>Antragsteller</b>, <b>Flurstück</b>, <b>Projektadresse</b> und <b>Grundstücksbeschreibung</b></li>\n" +
          "<li>• Rechtliche und planungsrechtliche Aspekte wie z.B. <b>Teilungsgenehmigung</b>, <b>Vorbescheid</b>, <b>städtebauliche Satzung</b>, <b>Baulastenverzeichnis</b> und <b>Bebauungsplan</b></li>\n" +
          "<li>• Infrastruktur und Umwelt wie z.B. <b>Versorgungsleitungen</b>, <b>Trinwasserversorgunge</b>, <b>Abwasserbeseitigung</b>, <b>Leitungsträger</b>, <b>Zufahrt</b>, <b>Grundwasser</b> und <b>Kontamination</b></li>\n" +
          "</ul>"
      case "GL_4_Beschreibung_baulicher_Anlagen_SL_1":
        return "<ul>\n" +
          "<li>• Projektangaben wie z.B. <b>Name</b>, <b>Bauherr</b>, <b>Anschrift</b> und <b>Nutzungsart</b></li>\n" +
          "<li>• Bauausführung wie z.B. <b>Gebäudeklasse</b>, <b>Fundamente</b>, <b>Bauweise</b>, <b>Tragkonstruktion</b>, <b>TGA</b> und <b>Fassade</b></li>\n" +
          "<li>• Energieversorgung wie z.B. <b>Wärmeenergiebedarf</b>, <b>Solarthermie</b>, <b>Geothermie</b> und <b>Fernwärme</b></li>\n" +
          "</ul>"
      case "GL_4_Beschreibung_der_Baumassnahmen_geringen_Umfangs_SL_1":
        return "<ul>\n" +
          "<li>• Beschreibung wie z.B. <b>Anlagestandort</b>, <b>Art der baulichen Anlage</b>, <b>Ausführung</b>, <b>Antragsteller</b>, <b>Werbeanlagen</b> und <b>Warenautomaten</b></li>\n" +
          "</ul>"
      case "GL_4_Bestuhlungswegplan_und_Rettungswegplan_NW_1":
        return "<ul>\n" +
          "<li>• Bestuhlungs- und Rettungsplan wie z.B. <b>Fluchtweg</b>, <b>Notausgang</b>, <b>Gesamtsitzplätze</b>, <b>Haupteingang</b>, <b>Feuerlöscher</b> und <b>Treppenhaus</b></li>\n" +
          "</ul>"
      case "GL_4_Betriebsbeschreibung_else_1":
        return "<ul>\n" +
          "<li>• Belegschaft wie z.B. <b>Mitarbeiter</b>, <b>Personal</b>, <b>Herren</b>, <b>Damen</b>, <b>Sanitärräume</b>, <b>Pausenräume</b>, <b>Büro</b> und <b>Sanitätsräume</b></li>\n" +
          "</ul>"
      case "GL_4_Betriebsbeschreibung_else_2":
        return "<ul>\n" +
          "<li>• Lagergut wie z.B. <b>Ersatzteile</b>, <b>Material</b>, <b>Lagerbehälter</b>, <b>Regalanlage</b> und <b>Paletten</b></li>\n" +
          "<li>• Maschinnen wie z.B. <b>Aufzüge</b>, <b>Fördertechnik</b>, <b>Betriebsfahrzeuge</b>, <b>Beladefläche</b>, <b>PKW</b> und <b>LKW</b></li>\n" +
          "<li>• Rohstoffe und Waren wie z.B. <b>Erzeugnisse</b>, <b>Lagergut</b>, <b>Ersatzteile</b>, <b>Zubehör</b>, <b>Material</b>, <b>Maschine</b>, <b>Fördertechnik</b>, <b>Aufzüge</b>, <b>Betriebsfahrzeuge</b> und <b>Rohstoffe</b></li>\n" +
          "</ul>"
      case "GL_4_Betriebsbeschreibung_else_3":
        return "<ul>\n" +
          "<li>• Personals wie z.B. <b>Personalplanung</b>, <b>Administration</b>, <b>Leitung</b>, <b>Überwachung</b>, <b>Anlernen</b> und <b>Ausbildung</b></li>\n" +
          "</ul>"
      case "GL_4_Betriebsbeschreibung_else_4":
        return "<ul>\n" +
          "<li>• Arbeitsdurchführung wie z.B. <b>Arbeitsläufe</b>, <b>Arbeitsverfahren</b>, <b>Taktstraßen</b>, <b>Entladung</b>, <b>Beladung</b>, <b>Lagerung</b> und <b>Lieferung</b></li>\n" +
          "</ul>"
      case "GL_4_Betriebsbeschreibung_else_5":
        return "<ul>\n" +
          "<li>• Arbeitszeit wie z.B. <b>Schichtbetrieb</b>, <b>Pausenregelung</b>, <b>Betriebszeit</b>, <b>Werktage</b> und <b>Arbeitsschicht</b></li>\n" +
          "</ul>"
      case "GL_4_Betriebsbeschreibung_else_6":
        return "<ul>\n" +
          "<li>• Unterlieferer wie z.B. <b>Eigenfertigung</b>, <b>Vergabe an Unterlieferer</b>, <b>Outsourcing</b>, <b>Unterlieferantenmanagement</b> und <b>Subunternehmer</b></li>\n" +
          "</ul>"
      case "GL_4_BFK_HB_HE_NW_1":
        return "<ul>\n" +
          "<li>• Erschließung wie z.B. <b>Erreichbarkeit</b>, <b>Haupteingang</b>, <b>Wegführung</b>, <b>schwellenlos</b>, <b>stufenlos</b> und <b>Neigung</b></li>\n" +
          "</ul>"
      case "GL_4_BFK_HB_HE_NW_2":
        return "<ul>\n" +
          "<li>• Stellplätze wie z.B. <b>Lage</b>, <b>Grundstücksüberfahrten</b>, <b>Bewegungsflächen</b>, <b>Absenkung</b> und <b>Kennzeichnung</b></li>\n" +
          "</ul>"
      case "GL_4_BFK_HB_HE_NW_3":
        return "<ul>\n" +
          "<li>• Bedienelemente wie z.B. <b>Taster</b>, <b>Drücker</b>, <b>Türöffner</b>, <b>Fenstergriffe</b>, <b>Beleuchtung</b> und <b>Bedienhöhe</b></li>\n" +
          "</ul>"
      case "GL_4_BFK_HB_HE_NW_4":
        return "<ul>\n" +
          "<li>• Sanitärräume wie z.B. <b>Toilettenanlage</b>, <b>Haltegriffen</b>, <b>Waschplätze</b> und <b>Notrufanlage</b></li>\n" +
          "</ul>"
      case "GL_4_BFK_HB_HE_NW_5":
        return "<ul>\n" +
          "<li>• Bewegungsflächen wie z.B. <b>Flächenbedaraf</b>, <b>Wegbreite</b> und <b>Rollstuhlnutzer</b></li>\n" +
          "</ul>"
      case "GL_4_BFK_HB_HE_NW_6":
        return "<ul>\n" +
          "<li>• Orientierung wie z.B. <b>Leitsystem</b>, <b>Wegführung</b>, <b>Bodenindikatoren</b>, <b>taktil</b> und <b>visuell</b></li>\n" +
          "</ul>"
      case "GL_4_Bodenschutzkonzept_BW_1":
        return "<ul>\n" +
          "<li>• Projektbeschreibung wie z.B. <b>Bauablauf</b>, <b>Arbeitsprozesse</b>, <b>Bodenbewegung</b>, <b>Baustraße</b> und <b>Maschineneinsatz</b></li>\n" +
          "<li>• Bodenzusammensetzung wie z.B. <b>Bodenart</b>, <b>Schichtung</b>, <b>San</b>, <b>Lehm</b>, <b>Ton</b>, <b>Schluff</b> und <b>Humusgehalt</b></li>\n" +
          "<li>• Auswirkungen wie z.B. <b>Beeinträchtigung</b>, <b>Versiegelung</b>, <b>Verdichtung</b>, <b>Gefügestörung</b>, <b>Schadstoffeinträge</b> und <b>Fremdstoffeinträge</b></li>\n" +
          "<li>• Maßnahmen wie z.B. <b>Vermeidungsmaßnahmen</b>, <b>Bodenschutz</b>, <b>Bodenschutzplan</b>, <b>Bauzeitplan</b>, <b>Folgebewirtschaftung</b> und <b>Rekultivierung</b></li>\n" +
          "</ul>"
      case "GL_4_Brandschutzkonzept_BY_RP_ST_1":
        return "<ul>\n" +
          "<li>• Brandverhalten wie z.B. <b>Baustoffklasse</b>, <b>Feuerwiderstandsfähigkeit</b>, <b>Feuerwiderstandklasse</b>, <b>feuerbeständig</b>, <b>schwerentflammbar</b>, <b>F30</b>, <b>F60</b> und <b>F90</b></li>\n" +
          "<li>• Flucht- und Rettungswege wie z.B. <b>Fluchttreppe</b>, <b>Fluchtweg</b>, <b>Notausgang</b> und <b>Fluchtwegbreite</b></li>\n" +
          "<li>• Brandbekämpfung wie z.B. <b>Feuerlöscher</b>, <b>Löschdecke</b>, <b>Löschwasser</b> und <b>Hydrant</b></li>\n" +
          "<li>• Feuerwehr wie z.B. <b>Feuerwehrzufahrt</b>, <b>Anleiterstelle</b>, <b>Zugang</b>, <b>Bewegungsfläche</b> und <b>Aufstellungsfläche</b></li>\n" +
          "<li>• Kontruktiver Brandschutz wie z.B. <b>Brandabschnitt</b>, <b>Rauchabschnitt</b> und <b>Brandwand</b></li>\n" +
          "</ul>"
      case "GL_4_Brandschutzkonzept_Sonderbauten_else_1":
        return "<ul>\n" +
          "<li>• Nutzung wie z.B. <b>Personen</b>, <b>Anzahl</b>, <b>Brandlasten</b> und <b>Gefahrenstoffe</b></li>\n" +
          "<li>• Flucht und Rettung wie z.B. <b>Rettungswegführung</b>, <b>Rettungsweglänge</b>, <b>Sicherheitsbeleuchtung</b>, <b>Sicherheitskennzeichnung</b>, <b>Stromsicherheitsversorgung</b> und <b>Notnetz</b></li>\n" +
          "<li>• technische Anlagen zum Brandschutz wie z.B. <b>Branderkennung</b>, <b>Brandmelder</b>, <b>Alarmierung</b> und <b>Rauchabteilung</b></li>\n" +
          "<li>• Maßnahmen wie z.B. <b>Brandverhütung</b>, <b>Feuerwehrplan</b>, <b>Brandschutzbeauftragter</b> und <b>Selbstrettung</b></li>\n" +
          "<li>• Brandbekämpfung wie z.B. <b>Löschwasserversorgung</b> und <b>Löschwasserrückhaltung</b></li>\n" +
          "</ul>"
      case "GL_4_Brandschutznachweis_else_1":
        return "<ul>\n" +
          "<li>• Projektangaben wie z.B. <b>Bauvorhaben</b>, <b>Bauherr</b>, <b>Anschrift</b>, <b>Geäudeklasse</b> und <b>Nutzung</b></li>\n" +
          "<li>• Konzept wie z.B. <b>Löschwasserversorgung</b>, <b>Brandmeldung</b>, <b>Rettungswege</b>, <b>Brandabschnitte</b>, <b>Notausgang</b> und <b>Hydranten</b></li>\n" +
          "<li>• Bauwerk wie z.B. <b>Bauteile</b>, <b>Wände</b>, <b>Stützen</b>, <b>Durchbrüche</b>, <b>Brandwände</b>, <b>Branddecken</b>, <b>Lüftungsanlagen</b> und <b>Treppenhaus</b></li>\n" +
          "<li>• Normen und Vorschriften wie z.B. <b>BauO</b>, <b>DIN 4102</b>, <b>DIN 4844</b>, <b>DIN 18085</b> und <b>DIN 4066</b></li>\n" +
          "</ul>"
      case "GL_4_Brandschutznachweis_in_Bauvorlagen_SL_1":
        return "<ul>\n" +
          "<li>• Projektangaben wie z.B. <b>Bauvorhaben</b>, <b>Bauherr</b>, <b>Anschrift</b>, <b>Geäudeklasse</b> und <b>Nutzung</b></li>\n" +
          "<li>• Konzept wie z.B. <b>Löschwasserversorgung</b>, <b>Brandmeldung</b>, <b>Rettungswege</b>, <b>Brandabschnitte</b>, <b>Notausgang</b> und <b>Hydranten</b></li>\n" +
          "<li>• Bauwerk wie z.B. <b>Bauteile</b>, <b>Wände</b>, <b>Stützen</b>, <b>Durchbrüche</b>, <b>Brandwände</b>, <b>Branddecken</b>, <b>Lüftungsanlagen</b> und <b>Treppenhaus</b></li>\n" +
          "<li>• Brandschutzanforderungen wie z.B. <b>feuerbeständig</b>, <b>feuerhemmend</b>, <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>Feuerwiderstandsfähigkeit</b>, <b>A1</b>, <b>A2</b> und <b>B1</b></li>\n" +
          "</ul>"
      case "GL_4_BRI_umbauter_Raum_HB_MV_NW_RP_SH_SL_ST_TH_1":
        return "<ul>\n" +
          "<li>• Brutto-Rauminhalt wie z.B. <b>DIN 277</b>, <b>Geschosshöhe</b>, <b>Rauminhalt</b>, <b>Brutto-Grundfläche</b>, <b>Nutzungsart</b> und <b>Teilfläche</b></li>\n" +
          "</ul>"
      case "GL_4_Darstellung_Zufahrtsverhaeltnisse_ST_1":
        return "<ul>\n" +
          "<li>• Zufahrt wie z.B. <b>Durchfahrt</b>, <b>Bewegungsflächen</b>, <b>Feuerwehrzufahrt</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_denkmalschutzrechtliche_Erlaubnis_BY_1":
        return "<ul>\n" +
          "<li>• Bauvorhaben wie z.B. <b>Antragsteller</b>, <b>Eigentümer</b>, <b>Unterschrift</b>, <b>Grundstück</b> und <b>Baumaßnahme</b></li>\n" +
          "<li>• Denkmalschutz wie z.B. <b>Denkmalschutzgesetz</b>, <b>Denkmalpflege</b> und <b>Denkmalschutzbehörde</b></li>\n" +
          "</ul>"
      case "GL_4_Einfuegungsnachweis_HE_1":
        return "<ul>\n" +
          "<li>• Einfügungsnachweis wie z.B. <b>§34</b>, <b>Bebauungsplan</b>, <b>Umgebungsbebauung</b>, <b>Grundstücksfläche</b>, <b>bauliche Nutzung</b>, <b>Kubatur</b>, <b>Wohngebiet</b> und <b>Mischgebiet</b></li>\n" +
          "</ul>"
      case "GL_4_Energieausweis_else_1":
        return "<ul>\n" +
          "<li>• Energieausweis wie z.B. <b>Jahres-Primärenergiebedarf</b>, <b>Transmissionswärmeverlust</b>, <b>sommerlicher Wärmeschutz</b> und <b>Gesamtenergieeffizienz</b></li>\n" +
          "</ul>"
      case "GL_4_ergaenzende_Baubeschreibung_MV_1":
        return "<ul>\n" +
          "<li>• Projektangaben wie z.B. <b>Name</b>, <b>Bauherr</b>, <b>Antragsteller</b>, <b>Anschrift</b> und <b>Flurstück</b></li>\n" +
          "<li>• Nutzung wie z.B. <b>Betriebsflächen</b>, <b>Ackerland</b>, <b>forstwirtschaftliche Nutzfläche</b>, <b>Düngermittel</b>, <b>Betriebsstoffe</b> und <b>Vollerwerbsbetrieb</b></li>\n" +
          "</ul>"
      case "GL_4_Ergaenzungsplan_HB_1":
        return "<ul>\n" +
          "<li>• Ergänzungsplan wie z.B. <b>1:100</b>, <b>1:200</b>, <b>1:500</b>, <b>Festsetzungen</b> und <b>Vorschriften</b></li>\n" +
          "</ul>"
      case "GL_4_Erklaerung_der_Nachbarschaft_SL_1":
        return "<ul>\n" +
          "<li>• Projekt wie z.B. <b>Anschrift</b>, <b>Flurstück</b>, <b>Telefon</b> und <b>Bauherr</b></li>\n" +
          "<li>• Nachbarschaft wie z.B. <b>Eigentümer</b>, <b>Rechtsnachfolger</b>, <b>Baugrenze</b>, <b>baurechtliche Bestimmungen</b> und <b>Abstandsflächen</b></li>\n" +
          "</ul>"
      case "GL_4_Erklaerung_zum_barrierefreien_Bauen_SL_1":
        return "<ul>\n" +
          "<li>• Barrierefreiheit wie z.B. <b>§50 Abs. 1 LBO</b>, <b>barrierefrei</b>, <b>Rollstuhl</b>, <b>Bad</b> und <b>Freisitz</b></li>\n" +
          "</ul>"
      case "GL_4_Erläuterung_gegen_nachbarliche_Zustimmung_RP_1":
        return "<ul>\n" +
          "<li>• Nachbar wie z.B. <b>Nachbar</b>, <b>Grundstück</b>, <b>Anschrift</b>, <b>Unterschrift</b>, <b>Gemeinde</b>, <b>Ort</b> und <b>Datum</b></li>\n" +
          "<li>• Begründung wie z.B. <b>Dienstbarkeit</b>, <b>Wegerecht</b>, <b>Abstandsflächen</b>, <b>Grenzbebauung</b>, <b>Grenzabstand</b> und <b>Überbaurecht</b></li>\n" +
          "</ul>"
      case "GL_4_erneuerbare_Energien_BB_1":
        return "<ul>\n" +
          "<li>• erneuerbare Energien wie z.B. <b>Solarthermie</b>, <b>Strom aus erneuerbareren Energien</b>, <b>gasförmiger Biomasse</b>, <b>Umweltwärme</b>, <b>Geothermie</b> und <b>Kraft-Wärme-Kopplungsanlagen</b></li>\n" +
          "</ul>"
      case "GL_4_Erschliessungssicherung_BB_BE_BY_HB_HH_MV_NI_RP_SH_ST_TH_1":
        return "<ul>\n" +
          "<li>• Ver- und Entsorgung wie z.B. <b>Trinkwasserbedarf</b>, <b>Trinkwasserversorgung</b>, <b>Abswasser</b>, <b>Regenwasser</b>, <b>Kanalisation</b> und <b>Schmutzwasser</b></li>\n" +
          "<li>• Energie wie z.B. <b>Energieversorgung</b>, <b>Strom</b> und <b>Nennleistung Verbrauch</b></li>\n" +
          "<li>• Zugang wie z.B. <b>Zufahrt</b>, <b>Verkehr Gehweg</b>, <b>Straße</b> und <b>Parkplatz</b></li>\n" +
          "</ul>"
      case "GL_4_Erschuetterungsnachweis_BE_BY_SN_SH_1":
        return "<ul>\n" +
          "<li>• Erschütterungsnachweis wie z.B. <b>Nachweis</b>, <b>Bauvorhaben</b>, <b>Baugrundstück</b>, <b>Baugenehmigung</b>, <b>Lärmvorsorge</b>, <b>Lärmschutz</b>, <b>Immisionswerte</b>, <b>Erschütterungen</b>, <b>Nutzungsänderung</b> und <b>Bauaufsichtsbehörde</b></li>\n" +
          "</ul>"
      case "GL_4_erteilte_Bescheide_BE_HB_1":
        return "<ul>\n" +
          "<li>• Bescheid wie z.B. <b>Bauvorbescheid</b>, <b>planungsrechtlicher Bescheid</b>, <b>75 Abs. 1 BauO Bln</b> und <b>75 Bremische Landesbauordnung</b></li>\n" +
          "</ul>"
      case "GL_4_Feuerungsanlagen_BW_RP_SL_1":
        return "<ul>\n" +
          "<li>• Feuerungsanlage wie z.B. <b>Art der Feuerstätte</b>, <b>Brenner</b>, <b>Zentralheizung</b>, <b>Abgasanlagen</b>, <b>Schornstein</b>, <b>Brennstoff</b> und <b>Belüftung</b></li>\n" +
          "</ul>"
      case "GL_4_Flaechen_RP_1":
        return "<ul>\n" +
          "<li>• Flächenermittlung wie z.B. <b>Flächenermittlung</b>, <b>Nutzfläche</b>, <b>NF</b>, <b>Wohnfläche</b>, <b>WF</b>, <b>DIN 277</b>, <b>Ermittlung</b>, <b>Berechnung</b> und <b>Wohnflächenverordnung</b></li>\n" +
          "</ul>"
      case "GL_4_Foerderungsnachweis_SH_1":
        return "<ul>\n" +
          "<li>• Allgemeine Angaben wie z.B. <b>Bewilligungsdatum</b>, <b>Aktenzeichen</b>, <b>Summe</b>, <b>Rechnung</b> und <b>Bewilligung</b></li>\n" +
          "<li>• Baumaßnahme wie z.B. <b>Zuwendungsempfänger</b>, <b>Ergebnis</b> und <b>Verfügung</b></li>\n" +
          "</ul>"
      case "GL_4_Freiflaechengestaltungsplan_SL_1":
        return "<ul>\n" +
          "<li>• Freiflächengestaltungsplan wie z.B. <b>überbaute Flächen</b>, <b>Stellplätze</b>, <b>Grünflächen</b>, <b>Bepflanzung</b>, <b>Gas</b>, <b>Wärme</b>, <b>Strom</b> und <b>Abwasserentsorgung</b></li>\n" +
          "</ul>"
      case "GL_4_Freiflaechenplan_HB_HE_1":
        return "<ul>\n" +
          "<li>• Baugrundstück wie z.B. <b>Grundstück</b>, <b>Bestand</b>, <b>Schutzgebiete</b>, <b>Bepflanzung</b>, <b>Anlagen</b>, <b>Vegetation</b>, <b>Altlasten</b>, <b>Bodeneigenschaften</b> und <b>Gewässerverhältnisse</b></li>\n" +
          "</ul>"
      case "GL_4_Freiflaechenplan_HB_HE_2":
        return "<ul>\n" +
          "<li>• Ausgleichsplanung wie z.B. <b>Ersatzplanung</b>, <b>Maßnahmen</b>, <b>Ersatzpflanung</b>, <b>Bodenversieglung</b> und <b>Artenschutz</b></li>\n" +
          "</ul>"
      case "GL_4_Freiflaechenplan_HB_HE_3":
        return "<ul>\n" +
          "<li>• Freiflächenplanung wie z.B. <b>Grundstück</b>, <b>Spielplatz</b>, <b>Garage</b>, <b>Stellplätze</b>, <b>Verkehrsfläche</b>, <b>Abfall</b>, <b>Grünfläche</b>, <b>Bepfanzung</b> und <b>Versickerungsfläche</b></li>\n" +
          "</ul>"
      case "GL_4_Gebaeudeklasse_Hoehe_BW_BY_BE_BB_HB_HH_MV_NI_SL_SN_SH_1":
        return "<ul>\n" +
          "<li>• Gebäudeklasse wie z.B. <b>Gebäudeklasse</b>, <b>GK1</b>, <b>GK2</b>, <b>GK3</b>, <b>GK4</b>, <b>GK5</b>, <b>freistehend</b>, <b>Nutzungseinheit</b>, <b>landwirtschaflich</b>, <b>forstwirtschaftlich</b>, <b>Höhe</b> und <b>unterirdisch</b></li>\n" +
          "</ul>"
      case "GL_4_Geschossberechnung_HB_1":
        return "<ul>\n" +
          "<li>• Geschoss wie z.B. <b>Vollgeschoss</b>, <b>Staffelgeschoss</b>, <b>Halbgeschoss</b>, <b>Oberkante</b>, <b>Straßenhöhe</b>, <b>Dachkonstruktion</b>, <b>Grundfläche</b> und <b>Quadratmeter</b></li>\n" +
          "</ul>"
      case "GL_4_Grundbuchauszug_BE_HB_1":
        return "<ul>\n" +
          "<li>• Eigentümer wie z.B. <b>Eigentümer*in</b>, <b>Stadt</b>, <b>Gemeinde</b> und <b>Amtsgericht</b></li>\n" +
          "<li>• Grundstück wie z.B. <b>Flur</b>, <b>Flurstück</b>, <b>Lage</b>, <b>Grundlasten</b>, <b>Abschreibungen</b> und <b>Bestandsverzeichnis</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_BW_1":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>LPH4</b>, <b>Genehmigungsplanung</b>, <b>Bauherr</b>, <b>Planer</b>, <b>Objektplaner</b>, <b>1:100</b>, <b>1:200</b>, <b>Maßstab</b>, <b>Plannummer</b>, <b>Index</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_BW_2":
        return "<ul>\n" +
          "<li>• Räume wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_BW_3":
        return "<ul>\n" +
          "<li>• Bauteile wie z.B. <b>Innenwand</b>, <b>Trockenbauwand</b>, <b>Innenstütze</b>, <b>Unterzug</b>, <b>Pfette</b>, <b>Sturz</b>, <b>Bodenplatte</b> und <b>Treppe</b></li>\n" +
          "<li>• Brandschutzanforderungen wie z.B. <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>F120</b>, <b>Brandwand</b>, <b>RWA</b>, <b>Brandschutztür</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_else_1":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>LPH4</b>, <b>Genehmigungsplanung</b>, <b>Bauherr</b>, <b>Planer</b>, <b>Objektplaner</b>, <b>1:100</b>, <b>1:200</b>, <b>Maßstab</b>, <b>Plannummer</b>, <b>Index</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_else_2":
        return "<ul>\n" +
          "<li>• Raumnutzung wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_else_3":
        return "<ul>\n" +
          "<li>• Bauteile wie z.B. <b>Innenwand</b>, <b>Trockenbauwand</b>, <b>Innenstütze</b>, <b>Unterzug</b>, <b>Pfette</b>, <b>Sturz</b>, <b>Bodenplatte</b> und <b>Treppe</b></li>\n" +
          "<li>• Brandschutzanforderungen wie z.B. <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>F120</b>, <b>Brandwand</b>, <b>RWA</b>, <b>Brandschutztür</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_HE_1":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>LPH4</b>, <b>Genehmigungsplanung</b>, <b>Bauherr</b>, <b>Planer</b>, <b>Objektplaner</b>, <b>1:100</b>, <b>1:200</b>, <b>Maßstab</b>, <b>Plannummer</b>, <b>Index</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_HE_2":
        return "<ul>\n" +
          "<li>• Raumnutzung wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "<li>• Bauteile wie z.B. <b>Innenwand</b>, <b>Trockenbauwand</b>, <b>Innenstütze</b>, <b>Unterzug</b>, <b>Pfette</b>, <b>Sturz</b>, <b>Bodenplatte</b> und <b>Treppe</b></li>\n" +
          "<li>• Brandschutzanforderungen wie z.B. <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>F120</b>, <b>Brandwand</b>, <b>RWA</b>, <b>Brandschutztür</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_NI_1":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>LPH4</b>, <b>Genehmigungsplanung</b>, <b>Bauherr</b>, <b>Planer</b>, <b>Objektplaner</b>, <b>1:100</b>, <b>1:200</b>, <b>Maßstab</b>, <b>Plannummer</b>, <b>Index</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_NI_2":
        return "<ul>\n" +
          "<li>• Raumnutzung wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_NI_3":
        return "<ul>\n" +
          "<li>• Bauteile wie z.B. <b>Innenwand</b>, <b>Trockenbauwand</b>, <b>Innenstütze</b>, <b>Unterzug</b>, <b>Pfette</b>, <b>Sturz</b>, <b>Bodenplatte</b> und <b>Treppe</b></li>\n" +
          "<li>• Brandschutz wie z.B. <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>F120</b>, <b>Brandwand</b>, <b>RWA</b>, <b>Brandschutztür</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_NW_1":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>LPH4</b>, <b>Genehmigungsplanung</b>, <b>Bauherr</b>, <b>Planer</b>, <b>Objektplaner</b>, <b>1:100</b>, <b>1:200</b>, <b>Maßstab</b>, <b>Plannummer</b>, <b>Index</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_NW_2":
        return "<ul>\n" +
          "<li>• Raumnutzung wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_NW_3":
        return "<ul>\n" +
          "<li>• Bauteile wie z.B. <b>Innenwand</b>, <b>Trockenbauwand</b>, <b>Innenstütze</b>, <b>Unterzug</b>, <b>Pfette</b>, <b>Sturz</b>, <b>Bodenplatte</b> und <b>Treppe</b></li>\n" +
          "<li>• Brandschutzanforderungen wie z.B. <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>F120</b>, <b>Brandwand</b>, <b>RWA</b>, <b>Brandschutztür</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_RP_1":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>LPH4</b>, <b>Genehmigungsplanung</b>, <b>Bauherr</b>, <b>Planer</b>, <b>Objektplaner</b>, <b>1:100</b>, <b>1:200</b>, <b>Maßstab</b>, <b>Plannummer</b>, <b>Index</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_RP_2":
        return "<ul>\n" +
          "<li>• Raumnutzung wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_RP_3":
        return "<ul>\n" +
          "<li>• Bauteile wie z.B. <b>Innenwand</b>, <b>Trockenbauwand</b>, <b>Innenstütze</b>, <b>Unterzug</b>, <b>Pfette</b>, <b>Sturz</b>, <b>Bodenplatte</b> und <b>Treppe</b></li>\n" +
          "<li>• Brandschutzanforderungen wie z.B. <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>F120</b>, <b>Brandwand</b>, <b>RWA</b>, <b>Brandschutztür</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_SL_1":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>LPH4</b>, <b>Genehmigungsplanung</b>, <b>Bauherr</b>, <b>Planer</b>, <b>Objektplaner</b>, <b>1:100</b>, <b>1:200</b>, <b>Maßstab</b>, <b>Plannummer</b>, <b>Index</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_SL_2":
        return "<ul>\n" +
          "<li>• Raumnutzung wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_SL_3":
        return "<ul>\n" +
          "<li>• Bauteile wie z.B. <b>Innenwand</b>, <b>Trockenbauwand</b>, <b>Innenstütze</b>, <b>Unterzug</b>, <b>Pfette</b>, <b>Sturz</b>, <b>Bodenplatte</b> und <b>Treppe</b></li>\n" +
          "<li>• Brandschutzanforderungen wie z.B. <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>F120</b>, <b>Brandwand</b>, <b>RWA</b>, <b>Brandschutztür</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_SN_ST_1":
        return "<ul>\n" +
          "<li>• Plankopf wie z.B. <b>LPH4</b>, <b>Genehmigungsplanung</b>, <b>Bauherr</b>, <b>Planer</b>, <b>Objektplaner</b>, <b>1:100</b>, <b>1:200</b>, <b>Maßstab</b>, <b>Plannummer</b>, <b>Index</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_SN_ST_2":
        return "<ul>\n" +
          "<li>• Raumnutzung wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "</ul>"
      case "GL_4_Grundriss_SN_ST_3":
        return "<ul>\n" +
          "<li>• Bauteile wie z.B. <b>Innenwand</b>, <b>Trockenbauwand</b>, <b>Innenstütze</b>, <b>Unterzug</b>, <b>Pfette</b>, <b>Sturz</b>, <b>Bodenplatte</b> und <b>Treppe</b></li>\n" +
          "<li>• Brandschutzanforderungen wie z.B. <b>F30</b>, <b>F60</b>, <b>F90</b>, <b>F120</b>, <b>Brandwand</b>, <b>RWA</b>, <b>Brandschutztür</b> und <b>Rettungsweg</b></li>\n" +
          "</ul>"
      case "GL_4_Grundstuecksentwaesserung_1_BW_RP_SH_SL_1":
        return "<ul>\n" +
          "<li>• Entwässerungsobjekte wie z.B. <b>Dachflächen</b>, <b>Verkehrsflächen</b>, <b>Versickerungsmulde</b>, <b>Regenwasserkanal</b>, <b>Schmutzwasserkanal</b> und <b>Grundleitung</b></li>\n" +
          "</ul>"
      case "GL_4_Grundstuecksentwaesserung_2_BW_RP_SH_SL_1":
        return "<ul>\n" +
          "<li>• Konzept wie z.B. <b>Grundstücksentwässerung</b>, <b>Hydraulische Berechnung</b> und <b>Entwässerungskonzept</b></li>\n" +
          "<li>• Leitung wie z.B. <b>Abflussmengen</b>, <b>Grundleitung</b>, <b>Rohrleitung</b>, <b>Durchfluss</b> und <b>Fließgeschwindigkeit</b></li>\n" +
          "<li>• Flächen und Rückhaltung wie z.B. <b>Dachflächen</b>, <b>befestigte Flächen</b>, <b>unbefestigte Flächen</b>, <b>Rückhaltevolumen</b> und <b>Rückhalteanlagen</b></li>\n" +
          "</ul>"
      case "GL_4_Herstellungskosten_BE_NI_NW_RP_ST_1":
        return "<ul>\n" +
          "<li>• Herstellungskosten wie z.B. <b>DIN 276</b>, <b>Kostengruppe</b>, <b>Baukonstruktion</b>, <b>Technische Anlagen</b>, <b>Außenanlagen</b>, <b>Neubau</b> und <b>Umbau</b></li>\n" +
          "</ul>"
      case "GL_4_Hochwasserrisiko_RP_1":
        return "<ul>\n" +
          "<li>• Risikobewertung wie z.B. <b>Hochwasserrisiko</b>, <b>Risikomanagementspläne</b>, <b>Objektschutz</b> und <b>NHWSP</b></li>\n" +
          "<li>• Vorschriften wie z.B. <b>Wasserhaushaltsgesetz</b>, <b>WHG</b>, <b>Wasserrecht</b> und <b>Nutzungsbeschränkungen</b></li>\n" +
          "<li>• Maßnahmen wie z.B. <b>Eigenvorsorge</b>, <b>Risikovorsorge</b>, <b>Kastatrophenschutz</b>, <b>Schutzeinrichtung</b>, <b>Hochwasserrückhalt</b>, <b>Hochwasserwarnsysteme</b> und <b>Stauanlagen</b></li>\n" +
          "</ul>"
      case "GL_4_Hygienegutachten_HE_1":
        return "<ul>\n" +
          "<li>• Hygienegutachten wie z.B. <b>Hygieneverordnung</b>, <b>Hygieneplan</b>, <b>Funktionsbereich</b>, <b>Lüftungsanlagen</b>, <b>Klimaanlagen</b>, <b>Krankenhäuser</b>, <b>Kliniken</b> und <b>Rehabilitationseinrichtungen</b></li>\n" +
          "</ul>"
      case "GL_4_Kriterienkatalog_BY_BE_BB_HB_MV_NW_SL_ST_SH_TH_1":
        return "<ul>\n" +
          "<li>• Verantwortlichkeit wie z.B. <b>Kriterienkatalog</b>, <b>Bauaufsichtsbehörde</b>, <b>Genehmigungsbehörde</b>, <b>Bauherr*in</b>, <b>Entwurfsverfasser*in</b> und <b>Tragwerksplaner*in</b></li>\n" +
          "<li>• Standsicherheitsaspekte wie z.B. <b>Baugrundverhältnisse</b>, <b>Gründung</b>, <b>Fundament</b>, <b>Geschossdecken</b>, <b>Bauweise</b> und <b>tragende Wände</b></li>\n" +
          "</ul>"
      case "GL_4_Laermschutzkonzept_HE_1":
        return "<ul>\n" +
          "<li>• Lärmschutz wie z.B. <b>Baustellenlärmkozept</b>, <b>Immission</b>, <b>Lärmquelle</b>, <b>Betriebszeiten</b>, <b>Lärmverminderung</b>, <b>Schalleistungspegel</b> und <b>Richtwert</b></li>\n" +
          "</ul>"
      case "GL_4_Lageplan_else_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:1000</b>, <b>1:500</b> und <b>1:250</b></li>\n" +
          "</ul>"
      case "GL_4_Lageplan_else_2":
        return "<ul>\n" +
          "<li>• Projekt und Projektbeteiligte wie z.B. <b>Bauherr*in</b>, <b>Planer*in</b>, <b>Architekt*in</b>, <b>Bauvorhaben</b> und <b>Projekt</b></li>\n" +
          "</ul>"
      case "GL_4_Lageplan_else_3":
        return "<ul>\n" +
          "<li>• Gebäudenutzung wie z.B. <b>Gebäudenutzung</b>, <b>Neubau</b>, <b>Umbau</b>, <b>Büro</b>, <b>Wohnen</b>, <b>Lager</b>, <b>Verkauf</b> und <b>Gewerbe</b></li>\n" +
          "<li>• Geschossigkeit wie z.B. <b>Geschoss</b> und <b>Geschossanzahl</b></li>\n" +
          "</ul>"
      case "GL_4_Lageplan_else_4":
        return "<ul>\n" +
          "<li>• Flurstück wie z.B. <b>Flur</b>, <b>Flurstück</b>, <b>Flurstücksnummer</b>, <b>Flurnummer</b> und <b>Straße</b></li>\n" +
          "</ul>"
      case "GL_4_Lageplan_else_5":
        return "<ul>\n" +
          "<li>• Stellplätze wie z.B. <b>Stellplatz</b>, <b>Fahrradstellplatz</b> und <b>PKW-Stellplatz</b></li>\n" +
          "</ul>"
      case "GL_4_Lageplan_else_6":
        return "<ul>\n" +
          "<li>• Höhenlage wie z.B. <b>Dachhöhe</b>, <b>First</b>, <b>Attika</b>, <b>Geländehöhe</b> und <b>Höhenbezugssystem</b></li>\n" +
          "</ul>"
      case "GL_4_Lageplan_else_7":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Baugrundstück</b>, <b>Grundstücksgrenze</b>, <b>Baugrenze</b>, <b>Bebauungsgrenze</b>, <b>Abstandsfläche</b>, <b>Lage</b> und <b>Standort</b></li>\n" +
          "</ul>"
      case "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH_1":
        return "<ul>\n" +
          "<li>• Liegenschaftsplan wie z.B. <b>Baugrundstück</b> und <b>Lage</b></li>\n" +
          "</ul>"
      case "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH_3":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100</b>, <b>1:250</b>, <b>1:500</b>, <b>1:1000</b>, <b>1:2000</b>, <b>1:2500</b>, <b>1:3000</b> und <b>1:5000</b></li>\n" +
          "</ul>"
      case "GL_4_Lueftungsanlagen_Feuerungsanlagen_HE_1":
        return "<ul>\n" +
          "<li>• Lüftung wie z.B. <b>Lüftungsanlage</b>, <b>Klimaanlage</b>, <b>Zuluft</b>, <b>Abluft</b>, <b>Belüftung</b>, <b>Entlüftung</b>, <b>Frischluft</b> und <b>Klimatisierung</b></li>\n" +
          "<li>• Leitung wie z.B. <b>Lüftungsleitung</b>, <b>Lüftungskanal</b>, <b>Zuluftventil</b>, <b>Abluftventil</b>, <b>Zuluftleitung</b> und <b>Abluftleitung</b></li>\n" +
          "</ul>"
      case "GL_4_Lueftungsanlagen_Feuerungsanlagen_HE_2":
        return "<ul>\n" +
          "<li>• Feuerstätte wie z.B. <b>Feuerungsanalge</b>, <b>Verbrennungsanalge</b>, <b>Feuerung</b>, <b>Ölheizkessel</b>, <b>Gasheizkessel</b> und <b>Kamin</b></li>\n" +
          "</ul>"
      case "GL_4_Mass_der_baulichen_Nutzung_else_1":
        return "<ul>\n" +
          "<li>• Maßangaben wie z.B. <b>Grundflächenzahl</b>, <b>Anzahl der Geschosse</b>, <b>Geschossflächenzahl</b>, <b>Baumassenzahl</b> und <b>Höhe der baulichen Anlage</b></li>\n" +
          "</ul>"
      case "GL_4_Mobilitaetsbedarf_HB_1":
        return "<ul>\n" +
          "<li>• Bedarfe wie z.B. <b>Mobilitätsbedarf</b>, <b>Stellplatzbedarf</b>, <b>Fahrradstellplätze</b> und <b>Kfz-Stellplätze</b></li>\n" +
          "<li>• Lage wie z.B. <b>Standort</b>, <b>Innenstadt</b>, <b>äußeres Stadtgebiet</b>, <b>Neubau</b>, <b>Umbau</b> und <b>Gebietszone</b></li>\n" +
          "<li>• Maßnahmen wie z.B. <b>Mobilitätskonzept</b>, <b>Infrastruktur</b>, <b>Herstellung von Stellplätzen</b>, <b>ÖPNV</b>, <b>Nahverkehr</b>, <b>Fußverkehr</b> und <b>Carsharing</b></li>\n" +
          "</ul>"
      case "GL_4_Nachbarliche_Zustimmung_BY_HB_RP_SN_TH_1":
        return "<ul>\n" +
          "<li>• Beteiligte wie z.B. <b>Beuherr</b>, <b>Eigentürmer</b>, <b>Antragsteller</b> und <b>Nachbar</b></li>\n" +
          "<li>• Basisangaben wie z.B. <b>Gemeinde</b>, <b>Flurstück</b>, <b>Anschrift</b> und <b>Bauumaßnahme</b></li>\n" +
          "<li>• Grenzen und Abstände wie z.B. <b>Grenzbebauung</b>, <b>Abstandsflächen</b>, <b>Randbebauung</b>, <b>Nachbarbebauung</b>, <b>Ausnahme</b>, <b>Befreiung</b>, <b>Zustimmung</b>, <b>Zulässigkeit</b> und <b>Vorschriften</b></li>\n" +
          "<li>• Dienstbarkeiten wie z.B. <b>Wegerecht</b>, <b>Überbaurecht</b>, <b>Stellplätze</b>, <b>nachbarliche Zustimmung</b> und <b>Einigung</b></li>\n" +
          "</ul>"
      case "GL_4_Nachweis_barrierefreie_Stellplaetze_BE_1":
        return "<ul>\n" +
          "<li>• Stellplätze wie z.B. <b>barrierefrei</b>, <b>Kraftfahrzeuge</b>, <b>Richtzahlen</b>, <b>Büro- und Verwaltungsgebäude</b>, <b>Bildungseinrichtung</b>, <b>Veranstaltungsgebäude</b> und <b>Nutzungsfläche</b></li>\n" +
          "</ul>"
      case "GL_4_Nachweis_Stellplaetze_fuer_Fahrraeder_BE_1":
        return "<ul>\n" +
          "<li>• Stellplätze wie z.B. <b>Abstellplätze</b>, <b>Fahrrad</b>, <b>Richtzahlen</b>, <b>Nutzungsfläche</b>, <b>Wohngebäude</b> und <b>Sportstätten</b></li>\n" +
          "</ul>"
      case "GL_4_oeffentlich_rechtliche_Vorschriften_HB_HH_SN_ST_1":
        return "<ul>\n" +
          "<li>• Vorschriften wie z.B. <b>Bescheid</b>, <b>Baulasten</b> und <b>öffentliche Grundlasten</b></li>\n" +
          "<li>• Behörden und Ämter wie z.B. <b>Bauamt</b>, <b>Standtplanungsamt</b> und <b>zuständige Bauaufsichtsbehörde</b></li>\n" +
          "<li>• Beteiligte wie z.B. <b>Entwurfsverfasser</b>, <b>Bauherr</b> und <b>Fachplaner</b></li>\n" +
          "</ul>"
      case "GL_4_oeffentliche_Bekanntmachung_BY_1":
        return "<ul>\n" +
          "<li>• öffentliche Bekanntmachung wie z.B. <b>Art. 66</b>, <b>Bayerische Bauordnung</b>, <b>Nachbarbeteiligung</b>, <b>Nachbargrundstück</b>, <b>Abweichung</b>, <b>Aktenzeichen</b>, <b>Bauvorhaben</b> und <b>Bauordnungsamt</b></li>\n" +
          "</ul>"
      case "GL_4_Oeffentlichkeitsbeteiligung_BY_1":
        return "<ul>\n" +
          "<li>• Öffentlichkeitsbeteiligung wie z.B. <b>Bekanntmachung</b>, <b>Stellungnahme</b>, <b>Planungsdialog</b> und <b>Beteiligung der Öffentlichkeit</b></li>\n" +
          "</ul>"
      case "GL_4_oertliche_Bauvorschrift_BW_BY_BB_HB_HE_MV_NI_NW_RP_SL_SN_ST_SH_TH_1":
        return "<ul>\n" +
          "<li>• Vorschriften wie z.B. <b>Satzung</b>, <b>Bauvorschrift</b>, <b>Geltungsbereich</b>, <b>Bekanntmachung</b>, <b>Baugestaltung</b> und <b>Amtsblatt</b></li>\n" +
          "<li>• Beteiligte wie z.B. <b>Gemeinde</b>, <b>bauaufsichtsbehörde</b>, <b>Landesbaubehörde</b> und <b>Bürgermeister</b></li>\n" +
          "<li>• Landesbauordnungen wie z.B. <b>LBO</b>, <b>BayBO</b>, <b>BauO NRW</b>, <b>Niedersächsischen Bauordnung</b> und <b>BremLBO</b></li>\n" +
          "</ul>"
      case "GL_4_Perspektiven_BE_1":
        return "<ul>\n" +
          "<li>• Perspektive wie z.B. <b>Zentralperspektive</b> und <b>Fluchtpunktperspektive</b></li>\n" +
          "</ul>"
      case "GL_4_Pruefverzicht_Brandschutznachweis_HB_1":
        return "<ul>\n" +
          "<li>• Brandschutz wie z.B. <b>Gebäudeklasse 4</b>, <b>Brandschutznachweis</b>, <b>Bauaufsichtliche Pürfungen</b> und <b>verfahrensfreies Vorhaben</b></li>\n" +
          "</ul>"
      case "GL_4_Pruefverzicht_Standsicherheitsnachweis_HB_1":
        return "<ul>\n" +
          "<li>• Standsicherheit wie z.B. <b>Prüfverzicht</b>, <b>§66 Abs. 5</b>, <b>Gebäudeklasse 1</b>, <b>Erklärung des Tragwerkplaners</b> und <b>Standsicherheitsnachweis</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_HB_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100</b> und <b>1:200</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_HB_2":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Grundstücksbezeichnung</b>, <b>Flurstücknummer</b>, <b>Lagebezeichnung</b> und <b>Gemarkung</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_HB_3":
        return "<ul>\n" +
          "<li>• Grenze und Abstandsfläche wie z.B. <b>Grundstückgrenze</b>, <b>Grenzpunkt</b>, <b>Baugrenze</b> und <b>Mindestabstand</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_HB_4":
        return "<ul>\n" +
          "<li>• Höhenbezug wie z.B. <b>Höhenlage</b>, <b>NN</b>, <b>NHN</b> und <b>Höhenkote</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_HB_5":
        return "<ul>\n" +
          "<li>• Baumbestand wie z.B. <b>Baum</b>, <b>Baumerhaltung</b> und <b>Baumschutzverordnung</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_NI_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100</b> und <b>1:200</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_NI_2":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Grundstücksbezeichnung</b>, <b>Flurstücknummer</b>, <b>Lagebezeichnung</b> und <b>Gemarkung</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_NI_3":
        return "<ul>\n" +
          "<li>• Grenze und Abstandsfläche wie z.B. <b>Grundstückgrenze</b>, <b>Grenzpunkt</b>, <b>Baugrenze</b> und <b>Mindestabstand</b></li>\n" +
          "</ul>"
      case "GL_4_qualifizierter_Lageplan_NI_4":
        return "<ul>\n" +
          "<li>• Projektbeteiligte wie z.B. <b>Bauherr</b>, <b>Eigentümer</b> und <b>Planer</b></li>\n" +
          "</ul>"
      case "GL_4_Rohbaukosten_NI_NW_1":
        return "<ul>\n" +
          "<li>• Baukosten wie z.B. <b>Berechnung</b>, <b>DIN 276</b>, <b>Kostengruppe</b>, <b>KG300</b> und <b>Rohbauwert</b></li>\n" +
          "</ul>"
      case "GL_4_Sachverstaendigenbescheinigung_Brandschutz_BE_BY_MV_NW_RP_SH_1":
        return "<ul>\n" +
          "<li>• Allgemeine Projektangaben wie z.B. <b>Baugrundstück</b>, <b>Gebäudeklasse</b>, <b>Bauabschnitt</b>, <b>Baugenehmigung </b>, <b>Bauaufsichtsbehörde</b>, <b>Bauunterlagen</b>, <b>Anlage</b>, <b>Errichtung</b>, <b>Nutzungsänderung</b> und <b>Kurzbeschreibung des Bauvorhabens</b></li>\n" +
          "<li>• Rechtsgrundlagen und Verordnungen wie z.B. <b>66 Absatz 3 Satz 2 BauO Bln</b>, <b>Art. 62 Abs. 3 und 4</b>, <b>83 Abs 2. Nr. 3</b>, <b>16 Absatz 1 SV-VO über die Prüfung des Brandschutzes</b>, <b>Landesverordnung für Sachverständige</b>, <b>Bautechnischen Prüfungsverordnung</b> und <b>Bauverfahrensverordnung</b></li>\n" +
          "<li>• Bauausführung und -überwachung wie z.B. <b>Bescheinigung über die Bauausführung</b>, <b>Überprüfung der Bauausführung</b>, <b>Bauüberwachung</b>, <b>Abweichungen von bauaufsichtlichen Anforderungen</b>, <b>Besondere Anforderungen</b>, <b>Brandschutzbestimmungen</b> und <b>brandschutztechnisch geprüfte Bauvorlagen</b></li>\n" +
          "<li>• Brandschutznachweis wie z.B. <b>Prüfung des Brandschutznachweises</b>, <b>Sachverständigenbescheinigung für den Brandschutz</b>, <b>Bescheinigung Brandschutz</b> und <b>Brandschutzbescheinigung</b></li>\n" +
          "<li>• Beteiligte wie z.B. <b>Brandschutzplaner</b>, <b>Nachweisersteller</b>, <b>Prüfsachverständiger</b> und <b>bauaufsichtlich anerkannte/-r Sachverständige/-r für baulichen Brandschutz</b></li>\n" +
          "</ul>"
      case "GL_4_Sachverstaendigenbescheinigung_Schallschutz_NW_1":
        return "<ul>\n" +
          "<li>• Basisangaben wie z.B. <b>Bauvorhaben</b>, <b>Baugrundstück</b>, <b>Fachplaner*in</b>, <b>Entwurfsverfasser</b> und <b>Bauherr*in</b></li>\n" +
          "<li>• Schallschutz wie z.B. <b>Schallschutzbescheinigung</b>, <b>Ergebnis der Prüfung</b> und <b>Nachweis über den Schallschutz</b></li>\n" +
          "</ul>"
      case "GL_4_Sachverstaendigenbescheinigung_Waermeschutz_NW_1":
        return "<ul>\n" +
          "<li>• Basisangaben wie z.B. <b>Bauvorhaben</b>, <b>Baugrundstück</b>, <b>Fachplaner*in</b>, <b>Entwurfsverfasser</b> und <b>Bauherr*in</b></li>\n" +
          "<li>• Nachweis wie z.B. <b>Wärmeschutz</b>, <b>energetische Prüfung</b>, <b>Wärmeschutzbescheinigung</b> und <b>Ergebnis der Prüfung</b></li>\n" +
          "</ul>"
      case "GL_4_Sachverstaendigennachweis_Standsicherheit_BB_BE_BY_MV_NW_SH_SN_TH_1":
        return "<ul>\n" +
          "<li>• Basisangaben wie z.B. <b>Bauvorhaben</b>, <b>Bauort</b>, <b>Errichtung</b>, <b>Änderung</b>, <b>Nutzungsänderung</b>, <b>Baugrundstück</b>, <b>Gemarkung</b> und <b>Lagebezeichnung</b></li>\n" +
          "<li>• Verantwortlichkeiten wie z.B. <b>Bauaufsichtsbehörde</b>, <b>Aktenzeichen</b>, <b>Bauherr*innen</b>, <b>Ersteller*in des Standsicherheitsnachweises</b>, <b>Tragwerksplaner*in</b> und <b>Prüfsachverständige*r für Standsicherheit</b></li>\n" +
          "<li>• Kriterienkatalog wie z.B. <b>Baugrundverhältnisse</b>, <b>Höhendifferenz</b>, <b>Wasserdruck</b>, <b>Tragstruktur</b>, <b>Verformungsuntersuchung</b>, <b>Gründung</b>, <b>Konstruktion</b> und <b>Prüfbemerkung</b></li>\n" +
          "</ul>"
      case "GL_4_Sachverstaendigennachweis_Standsicherheit_else_1":
        return "<ul>\n" +
          "<li>• Standsicherheit wie z.B. <b>Erklärung zum Standsicherheitsnachweis</b>, <b>Erklärung zum Kriterienkatalog</b>, <b>Kurzbezeichnung</b>, <b>Bauvorhaben</b>, <b>Bestätigung</b> und <b>Tragwerksplaner*innen</b></li>\n" +
          "</ul>"
      case "GL_4_Schallschutznachweis_else_1":
        return "<ul>\n" +
          "<li>• Normen wie z.B. <b>DIN 4109</b> und <b>DIN 4109:2018/2016</b></li>\n" +
          "<li>• Bauteile wie z.B. <b>Decke</b>, <b>Wand</b>, <b>Aufzug</b>, <b>Treppe</b>, <b>Tor</b>, <b>haustechnische Anlagen</b>, <b>schutzbedürftiger Raum</b>, <b>Wohnungstrenndecken</b> und <b>Balkon</b></li>\n" +
          "<li>• Schallschutz wie z.B. <b>Luftschall</b>, <b>Trittschall</b>, <b>Schalldämmmaß</b>, <b>Luftschwalldämmung</b>, <b>Trittschalldämmung</b>, <b>Außenlärmpegel</b> und <b>Lärmpegelbereich</b></li>\n" +
          "</ul>"
      case "GL_4_Schalltechnische_Untersuchung_else_1":
        return "<ul>\n" +
          "<li>• Normen wie z.B. <b>TA Lärm</b>, <b>DIN ISO 9613-2</b>, <b>DIN 45691</b>, <b>RLS-90</b>, <b>DIN Iso 9613</b> und <b>DIN 4109</b></li>\n" +
          "<li>• Schallemmissionen wie z.B. <b>Nutzung</b>, <b>Geräuschart</b>, <b>Schallemission</b>, <b>Immissionsrichtwert</b>, <b>Geräuschimmission</b>, <b>Dezibel</b>, <b>Schalldämmaß</b>, <b>Schalldruckpegel</b> und <b>Spitzenpegel</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BB_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100; 1:200; 1:50</b> und <b>1:250</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BB_2":
        return "<ul>\n" +
          "<li>• Höhe wie z.B. <b>Höhenlage</b>, <b>Höhenkote</b>, <b>Erdgeschoss</b>, <b>Obergeschoss</b>, <b>OKFF</b>, <b>OKG</b>, <b>OKFD</b> und <b>Geschosshöhe</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BB_3":
        return "<ul>\n" +
          "<li>• Treppen und Rampen wie z.B. <b>Treppe</b>, <b>Rampe</b>, <b>Steigung</b>, <b>Steigungsverhältnis</b>, <b>Treppenhaus</b> und <b>TRH</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BB_4":
        return "<ul>\n" +
          "<li>• Gelände wie z.B. <b>Gelände</b>, <b>OKG</b>, <b>Aufschüttung</b>, <b>Abgrabung</b>, <b>Geländeverlauf</b> und <b>OK Gelände</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BB_5":
        return "<ul>\n" +
          "<li>• Grundwasser wie z.B. <b>Grundwasser</b>, <b>Grundwasserstand</b> und <b>NN</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100; 1:200; 1:50</b> und <b>1:250</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_2":
        return "<ul>\n" +
          "<li>• Höhe wie z.B. <b>Höhenlage</b>, <b>Höhenkote</b>, <b>Erdgeschoss</b>, <b>Obergeschoss</b>, <b>OKFF</b>, <b>OKG</b>, <b>OKFD</b> und <b>Geschosshöhe</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_3":
        return "<ul>\n" +
          "<li>• Treppen und Rampen wie z.B. <b>Treppe</b>, <b>Rampe</b>, <b>Steigung</b>, <b>Steigungsverhältnis</b>, <b>Treppenhaus</b> und <b>TRH</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI_4":
        return "<ul>\n" +
          "<li>• Gelände wie z.B. <b>Gelände</b>, <b>OKG</b>, <b>Aufschüttung</b>, <b>Abgrabung</b>, <b>Geländeverlauf</b> und <b>OK Gelände</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100; 1:200; 1:50</b> und <b>1:250</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_2":
        return "<ul>\n" +
          "<li>• Höhe wie z.B. <b>Höhenlage</b>, <b>Höhenkote</b>, <b>Erdgeschoss</b>, <b>Obergeschoss</b>, <b>OKFF</b>, <b>OKG</b>, <b>OKFD</b> und <b>Geschosshöhe</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_3":
        return "<ul>\n" +
          "<li>• Treppen und Rampen wie z.B. <b>Treppe</b>, <b>Rampe</b>, <b>Steigung</b>, <b>Steigungsverhältnis</b>, <b>Treppenhaus</b> und <b>TRH</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_4":
        return "<ul>\n" +
          "<li>• Gelände wie z.B. <b>Gelände</b>, <b>OKG</b>, <b>Aufschüttung</b>, <b>Abgrabung</b>, <b>Geländeverlauf</b> und <b>OK Gelände</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH_5":
        return "<ul>\n" +
          "<li>• Gebäudehöhe wie z.B. <b>Firsthöhe</b>, <b>Traufhöhe</b>, <b>OKF</b>, <b>OK First</b> und <b>OK Traufe</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_NW_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:100; 1:200; 1:50</b> und <b>1:250</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_NW_2":
        return "<ul>\n" +
          "<li>• Höhe wie z.B. <b>Höhenlage</b>, <b>Höhenkote</b>, <b>Erdgeschoss</b>, <b>Obergeschoss</b>, <b>OKFF</b>, <b>OKG</b>, <b>OKFD</b> und <b>Geschosshöhe</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_NW_3":
        return "<ul>\n" +
          "<li>• Gelände wie z.B. <b>Gelände</b>, <b>OKG</b>, <b>Aufschüttung</b>, <b>Abgrabung</b>, <b>Geländeverlauf</b> und <b>OK Gelände</b></li>\n" +
          "</ul>"
      case "GL_4_Schnitt_NW_4":
        return "<ul>\n" +
          "<li>• Gebäudehöhe wie z.B. <b>Firsthöhe</b>, <b>Traufhöhe</b>, <b>OKF</b>, <b>OK First</b> und <b>OK Traufe</b></li>\n" +
          "</ul>"
      case "GL_4_Spielplatznachweis_else_1":
        return "<ul>\n" +
          "<li>• Nachweis wie z.B. <b>Spielplatzplanung</b>, <b>Spielflächenbedarf</b>, <b>Spielgeräte</b>, <b>öffentliche Spielflächen</b>, <b>Wegverbindung</b> und <b>Ablösungsvertrag</b></li>\n" +
          "</ul>"
      case "GL_4_Standsicherheitsnachweis_BW_1":
        return "<ul>\n" +
          "<li>• Lasten wie z.B. <b>Lastfall</b>, <b>Lastannamen</b>, <b>Eigenlast</b>, <b>Windlast</b>, <b>Nutzlast</b> und <b>gewöhnliche und außergewöhnliche Einwirkung</b></li>\n" +
          "<li>• Berechnung wie z.B. <b>Standsicherheitsnachweis</b>, <b>statische Berechnung</b>, <b>Grenzzustand</b>, <b>Tragfähigkeit</b>, <b>Gebrauchstauglichkeit Rissbegrenzung</b>, <b>Durchstanznachweis</b>, <b>Querkraftnachweis</b> und <b>Lagersicherung</b></li>\n" +
          "<li>• Bauteile wie z.B. <b>Decke</b>, <b>Stahlbetonstütze</b>, <b>Mauerwer</b>, <b>Sohle</b>, <b>Gründung</b>, <b>tragende Wand</b>, <b>Bodenplatte</b>, <b>Rampe</b>, <b>Fundament</b>, <b>Riegel</b>, <b>Unterzug</b> und <b>Konsole</b></li>\n" +
          "<li>• Normen wie z.B. <b>Eurocode</b>, <b>DIN EN 1990</b>, <b>DIN EN 1991</b>, <b>DIN EN 1992</b> und <b>DIN EN 1993</b></li>\n" +
          "<li>• Brandschutz wie z.B. <b>Brandschutzanforderungen</b>, <b>Brandschutzordnung</b>, <b>feuerbeständig</b>, <b>feuerhemmend</b>, <b>dicht- und selbstschleßend</b>, <b>nicht brennbar</b>, <b>F30</b>, <b>F60</b> und <b>F90</b></li>\n" +
          "</ul>"
      case "GL_4_Standsicherheitsnachweis_else_1":
        return "<ul>\n" +
          "<li>• Lasten wie z.B. <b>Lastfall</b>, <b>Lastannamen</b>, <b>Eigenlast</b>, <b>Windlast</b>, <b>Nutzlast</b> und <b>gewöhnliche und außergewöhnliche Einwirkung</b></li>\n" +
          "<li>• Berechnung wie z.B. <b>Standsicherheitsnachweis</b>, <b>statische Berechnung</b>, <b>Grenzzustand</b>, <b>Tragfähigkeit</b>, <b>Gebrauchstauglichkeit Rissbegrenzung</b>, <b>Durchstanznachweis</b>, <b>Querkraftnachweis</b> und <b>Lagersicherung</b></li>\n" +
          "<li>• Bauteile wie z.B. <b>Decke</b>, <b>Stahlbetonstütze</b>, <b>Mauerwer</b>, <b>Sohle</b>, <b>Gründung</b>, <b>tragende Wand</b>, <b>Bodenplatte</b>, <b>Rampe</b>, <b>Fundament</b>, <b>Riegel</b>, <b>Unterzug</b> und <b>Konsole</b></li>\n" +
          "<li>• Normen wie z.B. <b>Eurocode</b>, <b>DIN EN 1990</b>, <b>DIN EN 1991</b>, <b>DIN EN 1992</b> und <b>DIN EN 1993</b></li>\n" +
          "</ul>"
      case "GL_4_Statistik_else_1":
        return "<ul>\n" +
          "<li>• Basisangaben wie z.B. <b>Bauherr</b>, <b>Bauherrin</b>, <b>Name</b>, <b>Firma</b>, <b>Anschrift</b>, <b>Straße</b> und <b>Nummer</b></li>\n" +
          "<li>• Art der Bautätigkeit wie z.B. <b>Neubau</b>, <b>Fertigbauweise</b>, <b>konventionelle Bauten</b>, <b>Baumaßnahmen</b> und <b>bestehende Gebäude</b></li>\n" +
          "<li>• Gebäude wie z.B. <b>Wohngebäude</b>, <b>Wohnheim</b>, <b>Nichtwohngebäude</b>, <b>Haustyp</b>, <b>Raum</b>, <b>Räume</b>, <b>Zustand</b>, <b>Stahlbeton</b>, <b>Ziegel</b>, <b>Porenbeton</b> und <b>Stahl</b></li>\n" +
          "<li>• Größe wie z.B. <b>Nutzfläche</b>, <b>Wohnfläche</b>, <b>Wohnungen</b>, <b>Anzahl</b>, <b>Räume</b>, <b>Vollgeschosse</b> und <b>Rauminhalt</b></li>\n" +
          "<li>• Kosten wie z.B. <b>Veranschlagte Kosten des Bauwerks</b>, <b>Kostengruppe</b> und <b>Euro</b></li>\n" +
          "</ul>"
      case "GL_4_Stellplatznachweis_else_1":
        return "<ul>\n" +
          "<li>• Gebäudenutzung wie z.B. <b>Wohngebäude</b>, <b>Einfamilienhäuser</b>, <b>Mehrfamilienhäuser</b>, <b>Kinderheime</b>, <b>Jugendwohnheime</b>, <b>Bürogebäude</b>, <b>Kirchen</b>, <b>Läden</b>, <b>Verkaufsstätten</b>, <b>Theater</b>, <b>Sportplätze</b> und <b>Hallenbad</b></li>\n" +
          "<li>• Anzahl und Größe wie z.B. <b>Nenngröße</b>, <b>Bemessungsgrundlage</b>, <b>Richtzahlen</b>, <b>Ermittlung</b>, <b>Nachweis</b>, <b>PKW</b>, <b>Auto</b>, <b>Garage</b>, <b>Behindertenstellplatz</b> und <b>Stellplatzbedarf</b></li>\n" +
          "<li>• Standortbewertung wie z.B. <b>ÖPNV</b>, <b>Erreichbarkeit</b>, <b>Verkehrsmittel</b>, <b>Haltestelle</b>, <b>Bus</b> und <b>Bahn</b></li>\n" +
          "</ul>"
      case "GL_4_Stellungnahme_BE_1":
        return "<ul>\n" +
          "<li>• Stellungnahme wie z.B. <b>planungsrechtliche Rahmendaten</b>, <b>Flächennutzungsplan</b>, <b>Bebauungsplan</b>, <b>unbeplanter Innenbereich</b>, <b>Außenbereich</b>, <b>besonderes Städtebaurecht</b>, <b>Bürgschaftsanfrage</b>, <b>Förderungsantrag</b> und <b>Standortbeurteilung</b></li>\n" +
          "</ul>"
      case "GL_4_Stellungnahme_Bodenschutzbehoerde_HB_1":
        return "<ul>\n" +
          "<li>• Projektangaben wie z.B. <b>Grundstück</b>, <b>Bauvorhaben</b>, <b>Vorhaben</b>, <b>Projekt</b>, <b>Bauherr</b> und <b>Baugrundstück</b></li>\n" +
          "<li>• Bodenschutz wie z.B. <b>Altlasten</b>, <b>Kontamination</b>, <b>Kontaminationsverdacht</b>, <b>schädliche Bodenveränderung</b>, <b>Grundwasserverunreinigung</b>, <b>Grundwassermessstellen</b> und <b>altlastenverdächtig</b></li>\n" +
          "</ul>"
      case "GL_4_Stoerfallbetrieb_RP_1":
        return "<ul>\n" +
          "<li>• Projektangaben wie z.B. <b>Betreiber</b>, <b>Antragstelle</b>, <b>Antragstitel</b> und <b>Antragsdatum</b></li>\n" +
          "<li>• Gefahrenpotential wie z.B. <b>Hochwassergebiet</b>, <b>Erdbebengebiet</b>, <b>Bergbaugebiet</b>, <b>Gefahrenpotentiale</b> und <b>Stoffinventar</b></li>\n" +
          "</ul>"
      case "GL_4_Stoerfallbetrieb_RP_2":
        return "<ul>\n" +
          "<li>• Porojektangaben wie z.B. <b>Betreiber</b>, <b>Antragsteller</b>, <b>Antragstitel</b> und <b>Antragsdatum</b></li>\n" +
          "<li>• Gefahrenstoffe wie z.B. <b>Anlagenbezeichnung</b>, <b>gefährliche Stoffe</b>, <b>Aggregatzustand</b>, <b>Stoffmenge</b> und <b>Stoffe</b></li>\n" +
          "</ul>"
      case "GL_4_Stoerfallbetrieb_RP_3":
        return "<ul>\n" +
          "<li>• Projektangaben wie z.B. <b>Betreiber</b>, <b>Betreiberin</b>, <b>Antragsteller</b>, <b>Antragstellerin</b>, <b>Antragstitel</b> und <b>Antragsdatum</b></li>\n" +
          "<li>• Sicherheitsabstand wie z.B. <b>Sicherheitsabstand</b>, <b>Schutzobjekt</b>, <b>Schutzmaßnahme</b>, <b>gutachterlich</b>, <b>pauschaliert</b> und <b>max. Entfernung</b></li>\n" +
          "</ul>"
      case "GL_4_Uebereinstimmungserklaerung_BE_1":
        return "<ul>\n" +
          "<li>• Übereinstimmungserklärung wie z.B. <b>Bauprodukt</b>, <b>Bauart</b>, <b>Produkttyp</b>, <b>Hersteller</b>, <b>Anlagenerrichter</b>, <b>Bauunternehmen</b>, <b>Verwendungszweck</b> und <b>Zulassung</b></li>\n" +
          "</ul>"
      case "GL_4_Uebersichtsplan_HE_RP_SH_1":
        return "<ul>\n" +
          "<li>• Maßstab wie z.B. <b>1:1000</b>, <b>1:500</b> und <b>1:200</b></li>\n" +
          "</ul>"
      case "GL_4_Uebersichtsplan_HE_RP_SH_2":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Flur</b>, <b>Flurstück</b>, <b>Flurstücksnummer</b>, <b>Grundstückslage</b>, <b>GB-Nr.</b>, <b>Grundstücksnummer</b> und <b>Übersichtsplan</b></li>\n" +
          "</ul>"
      case "GL_4_Unbedenklichkeitsbescheinigung_Feuerwehr_HB_1":
        return "<ul>\n" +
          "<li>• Bescheinigung Feuerwehr wie z.B. <b>Unbedenklichkeitsbescheinigung</b>, <b>Berufsfeuerwehr</b>, <b>Personenrettung</b> und <b>Rettung von Personen</b></li>\n" +
          "</ul>"
      case "GL_4_Unterlagen_ueber_Brennstofflagerung_NI_1":
        return "<ul>\n" +
          "<li>• Brennstofflagerung wie z.B. <b>CO2</b>, <b>Rohstoff</b>, <b>Pellets</b>, <b>Holz</b>, <b>Brennstoffqualität</b>, <b>Feuerstätte</b>, <b>Feuerungsanlage</b> und <b>Wandstärken</b></li>\n" +
          "</ul>"
      case "GL_4_UVP_else_1":
        return "<ul>\n" +
          "<li>• Allgemeine Angaben wie z.B. <b>Bauvorhaben</b>, <b>Umfang</b> und <b>Ausgestaltung</b></li>\n" +
          "</ul>"
      case "GL_4_UVP_else_2":
        return "<ul>\n" +
          "<li>• Umwelt wie z.B. <b>Bezugsraum</b>, <b>Naturräumliche Gliederung</b>, <b>Geologie</b>, <b>Vegetation</b>, <b>Großklimatische Lage</b>, <b>Untersuchungsraum</b>, <b>Lebensraum</b>, <b>Schutzgut</b>, <b>Arten</b>, <b>Tiere</b>, <b>Boden</b>, <b>Nutzung</b>, <b>Regionalplanung</b> und <b>Forstwirtschaft</b></li>\n" +
          "</ul>"
      case "GL_4_UVP_else_3":
        return "<ul>\n" +
          "<li>• Standort und Maßnahmen wie z.B. <b>Kompensationsbedarf</b>, <b>Flächenbedarf</b>, <b>Raumanalyse</b>, <b>Schutzgüter</b>, <b>Beeinträchtgung</b>, <b>Schutzkonzept</b>, <b>Ausgleichsmaßnahme</b>, <b>Vermeidungsmaßnahme</b>, <b>Überwachungsmaßnahme</b> und <b>Immissionsschutzmaßnahme</b></li>\n" +
          "</ul>"
      case "GL_4_UVP_else_4":
        return "<ul>\n" +
          "<li>• Umweltauswirkung wie z.B. <b>Auswirkung</b>, <b>Prognose</b>, <b>Schadstoffe</b>, <b>Wirkfaktoren</b>, <b>Nachhaltigkeit</b> und <b>Baustellenbetrieb</b></li>\n" +
          "</ul>"
      case "GL_4_UVP_else_5":
        return "<ul>\n" +
          "<li>• Schutzgüter wie z.B. <b>Biologische Vielfalt</b>, <b>Pflanzen</b>, <b>Tiere</b>, <b>Luftqualität</b> und <b>kulturelles Erbe</b></li>\n" +
          "<li>• Gefährdung wie z.B. <b>Wärmeerzeuger</b>, <b>Industrieanlagen</b>, <b>Mineralöl</b>, <b>Arzneimittel</b>, <b>Metalle</b> und <b>chemische Erzeugnisse</b></li>\n" +
          "</ul>"
      case "GL_4_Vereinfachtes_Abfallverwertungskonzept_BW_1":
        return "<ul>\n" +
          "<li>• Verantwortliche wie z.B. <b>Bauherr*in</b>, <b>Ersteller*in</b> und <b>Bauleiter*in</b></li>\n" +
          "<li>• Bauvorhaben wie z.B. <b>Neubau</b>, <b>Umbau</b> und <b>Abbruch</b></li>\n" +
          "<li>• Lage wie z.B. <b>Gemeinde</b>, <b>Gemarkung</b>, <b>Flur</b>, <b>Flurstück</b>, <b>Straße</b> und <b>Hausnummer</b></li>\n" +
          "<li>• Schadstoffe wie z.B. <b>Materialien</b>, <b>Einbauteile</b>, <b>Türen</b>, <b>Fenster</b>, <b>Böden</b>, <b>Schadstoffhaltig</b> und <b>Schadstoff</b></li>\n" +
          "<li>• Entsorgung wie z.B. <b>Deponie</b>, <b>Recycling</b>, <b>Abfallentsorgungsbetrieb</b> und <b>Verbrennungsanlage</b></li>\n" +
          "</ul>"
      case "GL_4_Vergleichsberechnung_MV_1":
        return "<ul>\n" +
          "<li>• Vergleichsrechnung wie z.B. <b>wirtschaftliche Zumutbarkeit</b>, <b>Gesamtbaukosten</b>, <b>jährlicher Gesamtertrag</b>, <b>Bewirtschaftungskosten</b>, <b>Liquidität</b>, <b>Finanzierung</b>, <b>Tilgung</b> und <b>Erträge</b></li>\n" +
          "</ul>"
      case "GL_4_Vertretervollmacht_BB_BE_HE_MV_NW_SN_ST_1":
        return "<ul>\n" +
          "<li>• Vertretervollmacht wie z.B. <b>Nachname</b>, <b>Vorname</b>, <b>Unterschrift</b>, <b>Vollmachtgeber*in</b>, <b>Bevollmächtigte*r</b> und <b>Bauherrenvertreter*in</b></li>\n" +
          "</ul>"
      case "GL_4_Waermeschutznachweis_else_1":
        return "<ul>\n" +
          "<li>• Energiebedarf wie z.B. <b>Nutzenergiebedarf</b>, <b>Endenergiebedarf</b>, <b>Primärenergiebedarf</b> und <b>Jahresprimärenergiebedarf</b></li>\n" +
          "<li>• Normen wie z.B. <b>GEG</b>, <b>DIN 4108-2</b>, <b>BEG</b>, <b>DIN V 18599-2</b> und <b>EnEV</b></li>\n" +
          "</ul>"
      case "GL_4_Waermeschutznachweis_else_2":
        return "<ul>\n" +
          "<li>• Sommerlicher Wärmeschutz wie z.B. <b>Sonneneintragskennwert</b>, <b>Sonnenschutz</b>, <b>Fensterflächenanteil</b> und <b>Glasanteil</b></li>\n" +
          "</ul>"
      case "GL_4_Waermeschutznachweis_else_3":
        return "<ul>\n" +
          "<li>• Bauteilnachweis wie z.B. <b>Mindestwärmeschutz</b>, <b>Gebäudezone</b>, <b>Wärmedurchlasswiderstand</b>, <b>Lüftungswärmetransfer</b>, <b>Wärmebrücke</b>, <b>Wärmedurchgangskoeffizient</b>, <b>Bauteildicke</b> und <b>Schichtdicke</b></li>\n" +
          "</ul>"
      case "GL_4_wassergefaehrdende_Stoffe_else_1":
        return "<ul>\n" +
          "<li>• Wassergefährdende Stoffe wie z.B. <b>WGK</b>, <b>Säuren</b>, <b>Laufen</b>, <b>Öl</b>, <b>Kohlenwasserstoff</b>, <b>Gifte</b> und <b>Alkalimetalle</b></li>\n" +
          "<li>• Menge wie z.B. <b>Volumen</b>, <b>Wechselvorgang</b>, <b>Austauschvorgang</b> und <b>Menge</b></li>\n" +
          "<li>• Bewertung wie z.B. <b>Einstufung</b>, <b>Kennzeichnung</b>, <b>Einatmen</b>, <b>Augenkontakt</b>, <b>Erste-Hilfe-Maßnahmen</b> und <b>Gutachten zum Verzicht auf eine Eigungsfeststellung</b></li>\n" +
          "<li>• Anlagen wie z.B. <b>Behälter</b>, <b>Leckanzeigegerät</b>, <b>Batterieanlage</b>, <b>Heizölanlage</b>, <b>Transformator</b> und <b>Rohrleitungsanlage</b></li>\n" +
          "</ul>"



        //LPH 5
      case "GL_5_A_1_1":
        return "<ul>\n" +
          "<li>• Beton wie z.B. <b>Betondecke</b>, <b>Betonbodenplatte</b>, <b>Gründung</b>, <b>Betonstütze</b>, <b>Sichtbeton</b> und <b>Betonfestigkeit</b></li>\n" +
          "<li>• Stahlprofil wie z.B. <b>Profil</b>, <b>Bewehrung</b>, <b>Bewehrungsstahl</b>, <b>Stabstahl</b>, <b>Spannstahl</b>, <b>Aussteifung</b>, <b>Stahlstütze</b> und <b>Stahleigenschaften</b></li>\n" +
          "<li>• Holzträger wie z.B. <b>Dachpfette</b>, <b>Dachbinder</b>, <b>Balken</b>, <b>Sparren</b> und <b>Holzfestigkeit</b></li>\n" +
          "</ul>"
      case "GL_5_A_2_1":
        return "<ul>\n" +
          "<li>• Bodenbeläge wie z.B. <b>Boden</b>, <b>Belag</b>, <b>Estrich</b>, <b>Fliesen</b>, <b>Parkett</b>, <b>Laminat</b>, <b>Dielen</b> und <b>Fußbodenaufbau</b></li>\n" +
          "<li>• Dämmung wie z.B. <b>Dämmlage</b>, <b>Wärmedämmung</b>, <b>Trittschalldämmung</b>, <b>Mineralwolle</b>, <b>Steinwolle</b>, <b>Glaswolle</b>, <b>Schaumstoff</b>, <b>Dampfsperre</b>, <b>Randstreifen</b>, <b>Folienabdichtung</b>, <b>Feuchtigkeitsschutzfolie</b> und <b>Bitumen</b></li>\n" +
          "<li>• Oberflächen wie z.B. <b>Bekleidung</b>, <b>Fassade</b>, <b>Abdeckung</b>, <b>Trockenbau</b>, <b>Gipskartonplatte</b>, <b>Fliesen</b>, <b>Beschichtung</b>, <b>Farbton</b>, <b>Verglasung</b> und <b>Verputz</b></li>\n" +
          "<li>• Wandkonstruktion wie z.B. <b>Außenwände</b>, <b>Innenwand</b>, <b>Kelleraußenwände</b>, <b>Trennwand</b>, <b>Sandwichelement</b>, <b>Ständerwerk</b>, <b>Metallständerwerk</b>, <b>Rigipswand</b>, <b>Montagewand</b> und <b>Isopaneel</b></li>\n" +
          "<li>• Dach wie z.B. <b>Dachtragwerk</b>, <b>Flachdach</b>, <b>Aufbau</b>, <b>Dachgefälle</b>, <b>Attika</b>, <b>First</b>, <b>Dachbinder</b> und <b>Dacheindeckung</b></li>\n" +
          "</ul>"
      case "GL_5_A_4_1":
        return "<ul>\n" +
          "<li>• Material wie z.B. <b>Beton</b>, <b>Stahl</b>, <b>Stahlbeton</b>, <b>Holz</b>, <b>Glas</b>, <b>Stein</b>, <b>Kunststoff</b>, <b>Dämmstoff</b>, <b>Putz</b>, <b>Fliesen</b> und <b>Folie</b></li>\n" +
          "<li>• Materialkennwerte wie z.B. <b>Druckfestigkeit</b>, <b>Zugfestigkeit</b>, <b>Expositionsklasse</b>, <b>diffusionsoffen</b> und <b>diffusionsdicht</b></li>\n" +
          "</ul>"
      case "GL_5_B_1_1":
        return "<ul>\n" +
          "<li>• Planinhalt wie z.B. <b>Grundriss</b>, <b>Geschoss</b>, <b>Aufsicht</b>, <b>Dachaufsicht</b> und <b>Deckenspiegel</b></li>\n" +
          "<li>• Raumnutzung wie z.B. <b>Wohnen</b>, <b>Büro</b>, <b>Lager</b>, <b>Verkauf</b>, <b>Gastronomie</b>, <b>Küche</b>, <b>WC</b> und <b>Technik</b></li>\n" +
          "<li>• Bauteil wie z.B. <b>Wand</b>, <b>Decke</b>, <b>Stütze</b>, <b>Mauerwerk</b>, <b>Träger</b>, <b>Fertigteil</b>, <b>Treppe</b>, <b>Aufzug</b>, <b>Fenster</b> und <b>Tür</b></li>\n" +
          "<li>• Maße wie z.B. <b>Höhe</b>, <b>Breite</b>, <b>Außenmaß</b>, <b>Abstand</b>, <b>Fläche</b>, <b>Oberkante</b>, <b>Unterkante</b>, <b>Bemessung</b> und <b>Gefälle</b></li>\n" +
          "<li>• Tragwerk wie z.B. <b>Raster</b>, <b>Querschnitt</b>, <b>Durchmesser</b>, <b>Radius</b> und <b>Spannweite</b></li>\n" +
          "<li>• Material wie z.B. <b>Beton</b>, <b>Stahl</b>, <b>Stahlbeton</b>, <b>Holz</b>, <b>Glas</b>, <b>Stein</b>, <b>Kunststoff</b>, <b>Dämmstoff</b>, <b>Putz</b>, <b>Fliesen</b> und <b>Folie</b></li>\n" +
          "<li>• TGA wie z.B. <b>Leitung</b>, <b>Rohr</b>, <b>Abluft</b>, <b>Zuluft</b>, <b>Strom</b>, <b>Wasser</b> und <b>Schmutzwasser</b></li>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr</b>, <b>Planer</b>, <b>Projekt</b>, <b>Planinhalt</b>, <b>Projekt-Nr.</b>, <b>Liegenschaft</b>, <b>Plannummer</b>, <b>Format</b> und <b>Maßstab</b></li>\n" +
          "</ul>"
      case "GL_5_B_2_1":
        return "<ul>\n" +
          "<li>• Planinhalt wie z.B. <b>Schnitt</b>, <b>Querschnitt</b>, <b>Längsschnitt</b> und <b>Systemschnitt</b></li>\n" +
          "<li>• Bauteile wie z.B. <b>Decke</b>, <b>Dach</b>, <b>Bodenplatte</b>, <b>Wand</b>, <b>Träger</b>, <b>Stütze</b>, <b>Fundament</b> und <b>Treppe</b></li>\n" +
          "<li>• Höhenangaben wie z.B. <b>Höhe. Höhenknoten</b>, <b>Oberkante</b>, <b>Unterkante</b>, <b>OK</b>, <b>UK</b> und <b>Brüstungshöhe</b></li>\n" +
          "<li>• Material wie z.B. <b>Beton</b>, <b>Stahl</b>, <b>Stahlbeton</b>, <b>Holz</b>, <b>Glas</b>, <b>Stein</b>, <b>Kunststoff</b>, <b>Dämmstoff</b>, <b>Putz</b>, <b>Fliesen</b> und <b>Folie</b></li>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr</b>, <b>Planer</b>, <b>Projekt</b>, <b>Planinhalt</b>, <b>Projekt-Nr.</b>, <b>Liegenschaft</b>, <b>Plannummer</b>, <b>Format</b> und <b>Maßstab</b></li>\n" +
          "</ul>"
      case "GL_5_B_3_1":
        return "<ul>\n" +
          "<li>• Planinhalt wie z.B. <b>Detailplan</b>, <b>Detailansicht</b>, <b>Detailschnitt</b>, <b>Regeldetail</b>, <b>Leitdetail</b>, <b>Anschluss</b> und <b>Wandaufbau</b></li>\n" +
          "<li>• Bauteilen wie z.B. <b>Decke</b>, <b>Dach</b>, <b>Bodenplatte</b>, <b>Wand</b>, <b>Träger</b>, <b>Stütze</b>, <b>Fundament</b> und <b>Treppe</b></li>\n" +
          "<li>• Maße wie z.B. <b>Höhe</b>, <b>Breite</b>, <b>Länge</b>, <b>Fläche</b>, <b>Querschnitt</b>, <b>Durchmesser</b>, <b>Radius</b>, <b>Abstand</b>, <b>Achse</b>, <b>Größe</b>, <b>Neigung</b>, <b>Meter</b>, <b>m</b>, <b>cm</b> und <b>mm</b></li>\n" +
          "<li>• Material wie z.B. <b>Beton</b>, <b>Stahl</b>, <b>Stahlbeton</b>, <b>Holz</b>, <b>Glas</b>, <b>Stein</b>, <b>Kunststoff</b>, <b>Dämmstoff</b>, <b>Putz</b>, <b>Fliesen</b> und <b>Folie</b></li>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr</b>, <b>Planer</b>, <b>Projekt</b>, <b>Planinhalt</b>, <b>Projekt-Nr.</b>, <b>Liegenschaft</b>, <b>Plannummer</b>, <b>Format</b> und <b>Maßstab</b></li>\n" +
          "</ul>"
      case "GL_5_B_4_1":
        return "<ul>\n" +
          "<li>• Planinhalt wie z.B. <b>Anschlussdetail</b>, <b>Fassadenschnitt</b>, <b>Fußbodenaufbau</b>, <b>Dachaufbau</b> und <b>Wandaufbau</b></li>\n" +
          "<li>• Bauteilen wie z.B. <b>Decke</b>, <b>Dach</b>, <b>Bodenplatte</b>, <b>Wand</b>, <b>Träger</b>, <b>Stütze</b>, <b>Fundament</b>, <b>Treppe</b>, <b>Blech</b> und <b>Ziegel</b></li>\n" +
          "<li>• Dämmung wie z.B. <b>Wärmedämmung</b>, <b>Trittschalldämmung</b>, <b>Dampfbremse</b>, <b>Abdichtung</b>, <b>Randstreifen</b> und <b>Gefälledämmung</b></li>\n" +
          "<li>• Material wie z.B. <b>Beton</b>, <b>Stahl</b>, <b>Stahlbeton</b>, <b>Holz</b>, <b>Glas</b>, <b>Stein</b>, <b>Kunststoff</b>, <b>Dämmstoff</b>, <b>Putz</b>, <b>Fliesen</b>, <b>Folie</b>, <b>Estrich</b> und <b>Bodenbelag</b></li>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr</b>, <b>Planer</b>, <b>Projekt</b>, <b>Planinhalt</b>, <b>Projekt-Nr.</b>, <b>Liegenschaft</b>, <b>Plannummer</b>, <b>Format</b> und <b>Maßstab</b></li>\n" +
          "</ul>"
      case "GL_5_B_5_1":
        return "<ul>\n" +
          "<li>• Planinhalt wie z.B. <b>Ansicht</b>, <b>Frontansicht</b> und <b>Süd-Ansicht</b></li>\n" +
          "<li>• Ausrichtung wie z.B. <b>Nord</b>, <b>Süd</b>, <b>Ost</b>, <b>West</b>, <b>Südwest</b> und <b>Nordost</b></li>\n" +
          "<li>• Bauteilen wie z.B. <b>Fenster</b>, <b>Türen</b>, <b>Dach</b>, <b>Fassade</b>, <b>Attika</b>, <b>Traufe</b> und <b>First</b></li>\n" +
          "<li>• Höhenangaben wie z.B. <b>Höhe</b>, <b>Gebäudehöhe</b>, <b>Oberkante</b>, <b>Unterkante</b>, <b>OK</b>, <b>UK</b>, <b>Außenmaß</b> und <b>Höhenknoten</b></li>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr</b>, <b>Planer</b>, <b>Projekt</b>, <b>Planinhalt</b>, <b>Projekt-Nr.</b>, <b>Liegenschaft</b>, <b>Plannummer</b>, <b>Format</b> und <b>Maßstab</b></li>\n" +
          "</ul>"
      case "GL_5_B_6_1":
        return "<ul>\n" +
          "<li>• Planinhalt wie z.B. <b>Fundamentenplan</b>, <b>Fundamentenübersicht</b> und <b>Gründungssituation</b></li>\n" +
          "<li>• Bauteilen wie z.B. <b>Gründung</b>, <b>Fundament</b>, <b>Einzelfundament</b>, <b>Streifenfundament</b>, <b>Bodenplatte</b>, <b>Bohrphahl</b> und <b>Sockel</b></li>\n" +
          "<li>• Plankopf wie z.B. <b>Bauherr</b>, <b>Planer</b>, <b>Projekt</b>, <b>Planinhalt</b>, <b>Projekt-Nr.</b>, <b>Liegenschaft</b>, <b>Plannummer</b>, <b>Format</b> und <b>Maßstab</b></li>\n" +
          "</ul>"
      case "GL_5_C_1":
        return "<ul>\n" +
          "<li>• Anlagen wie z.B. <b>Lüftung</b>, <b>Klimaanlage</b>, <b>Heizung</b>, <b>Fußbodenheizung</b>, <b>Steueranlage</b> und <b>Sicherungskasten</b></li>\n" +
          "<li>• Leitungen wie z.B. <b>Rohr</b>, <b>Elektrokabel</b>, <b>Gasleitung</b> und <b>Regenrohr</b></li>\n" +
          "</ul>"
      case "GL_5_C_2":
        return "<ul>\n" +
          "<li>• Bauteile wie z.B. <b>Decke</b>, <b>Bodenplatte</b>, <b>Wand</b>, <b>Träger</b>, <b>Balken</b>, <b>Profil</b>, <b>Stütze</b>, <b>Fundament</b> und <b>Treppe</b></li>\n" +
          "<li>• Eigenschaften wie z.B. <b>Festigkeit</b>, <b>C25/30</b>, <b>Expositionsklasse</b>, <b>XC2</b>, <b>frostfrei</b>, <b>feuerverzinkt</b> und <b>dauerelastisch</b></li>\n" +
          "<li>• Abmessungen wie z.B. <b>Breite</b>, <b>Höhe</b>, <b>Länge</b>, <b>Radius</b>, <b>Durchmesser</b>, <b>Spannweite</b>, <b>Meter</b>, <b>m</b>, <b>cm</b> und <b>mm</b></li>\n" +
          "</ul>"
      case "GL_5_C_3":
        return "<ul>\n" +
          "<li>• Öffnungen wie z.B. <b>Wanddurchbruch</b>, <b>Deckendurchbruch</b>, <b>Schacht</b>, <b>Aussparung</b>, <b>Revisionsöffnung</b> und <b>Rohrdurchführung</b></li>\n" +
          "</ul>"
      case "GL_5_D_1":
        return "<ul>\n" +
          "<li>• Gliederung wie z.B. <b>Position</b>, <b>Pos.</b>, <b>Gewerk</b>, <b>Leistung</b>, <b>LPH</b>, <b>Nummer</b>, <b>NR.</b> und <b>Bezeichnung</b></li>\n" +
          "<li>• Vorgangsnamen wie z.B. <b>Ausführungsplanung</b>, <b>Vergabe</b>, <b>Herstellung</b>, <b>Gründung</b>, <b>Objektplanung</b> und <b>Erdbau</b></li>\n" +
          "<li>• Gewerke wie z.B. <b>Rohbau</b>, <b>Trockenbau</b>, <b>Tragwerk</b>, <b>Statik</b>, <b>TGA</b>, <b>Fassade</b>, <b>Erdarbeiten</b> und <b>Abbruch</b></li>\n" +
          "</ul>"
      case "GL_5_D_2":
        return "<ul>\n" +
          "<li>• Gliederung wie z.B. <b>Ablauf</b>, <b>Meilenstein</b>, <b>Dauer</b>, <b>Vorgangsdauer</b>, <b>Gewerk</b>, <b>Leistung</b>, <b>Startdatum</b> und <b>Enddatum</b></li>\n" +
          "<li>• Vorgänge wie z.B. <b>Ausführungsvorbereitung</b>, <b>Ausschreibung</b>, <b>Leistungsphasen</b>, <b>LPH</b>, <b>Baugenehmigung</b>, <b>Herstellung Fertigstellung</b> und <b>Abnahme</b></li>\n" +
          "<li>• Gewerke wie z.B. <b>Rohbau</b>, <b>Trockenbau</b>, <b>Tragwerk</b>, <b>Statik</b>, <b>TGA</b>, <b>Fassade</b>, <b>Erdarbeiten</b> und <b>Abbruch</b></li>\n" +
          "<li>• Zeitangaben wie z.B. <b>Anfang</b>, <b>Ende</b>, <b>Arbeitstage</b>, <b>AT</b>, <b>Kalenderwoche</b>, <b>KW</b>, <b>Monat</b>, <b>Wochentag</b> und <b>Quartal</b></li>\n" +
          "</ul>"
      case "BL_5_1_1":
        return "<ul>\n" +
          "<li>• Lage und Region wie z.B. <b>Bundesland</b>, <b>Stadt</b>, <b>Gemeinde</b> und <b>Adresse</b></li>\n" +
          "<li>• Positionierung wie z.B. <b>Ausrichtung</b>, <b>Grenzabstände</b> und <b>Nachbarbebauung</b></li>\n" +
          "<li>• Grundstücksmerkmale wie z.B. <b>Flurstücknummer</b></li>\n" +
          "<li>• Infrastruktur wie z.B. <b>Straßen</b> und <b>öffentliche Verkehrsmittel- und Einrichtungen</b></li>\n" +
          "</ul>"
      case "BL_5_1_2":
        return "<ul>\n" +
          "<li>• Grundstück wie z.B. <b>Baugrenze</b>, <b>Gelände</b>, <b>Gefälle</b>, <b>Grunstücksbeshaffenheit</b>, <b>Rasenfläche</b>, <b>Waldung</b>, <b>Moorfläche</b>, <b>Heidefläche</b>, <b>Plangebiet</b> und <b>Ackerfläche</b></li>\n" +
          "<li>• Baugrund wie z.B. <b>Bodenklasse</b>, <b>Homogenbereich</b>, <b>Gestein</b>, <b>Kies</b>, <b>Sand</b>, <b>Schluff</b>, <b>Ton</b>, <b>Grundwasser</b> und <b>wassergefährdende Stoffe</b></li>\n" +
          "</ul>"
      case "BL_5_1_3":
        return "<ul>\n" +
          "<li>• Zugang und Barrierefreiheit wie z.B. <b>Erschließung</b>, <b>Zufahrt</b>, <b>Ausfahrt</b>, <b>Rampe</b>, <b>Fahrweg</b>, <b>Eingang</b> und <b>ÖPNV</b></li>\n" +
          "<li>• Ver- und Entsorgung wie z.B. <b>Wasseranschluss</b>, <b>Gasanschluss</b>, <b>Stromanschluss</b>, <b>Kanalisation</b>, <b>Abfallkonzept</b> und <b>Hausanschlusskasten</b></li>\n" +
          "</ul>"
      case "BL_5_1_4":
        return "<ul>\n" +
          "<li>• Gründung wie z.B. <b>Gründungsart</b>, <b>Flachgründung</b>, <b>Pfahlgründung</b>, <b>Streifenfundament</b>, <b>Einzelfundament</b> und <b>Bodenplatte</b></li>\n" +
          "<li>• Tragwerk wie z.B. <b>Baukonstruktion</b>, <b>Skelettbauweise</b>, <b>Massivbauweise</b>, <b>Tragkonstruktion</b>, <b>Bodenbelastbarkeit</b>, <b>Lastabtrag</b> und <b>Aussteifung</b></li>\n" +
          "<li>• Bauteile und Aufbauten wie z.B. <b>Außenwand</b>, <b>Lochfassade</b>, <b>Sandwichelemente</b>, <b>Innenwand</b>, <b>Brandwand</b>, <b>Stütze</b>, <b>Decke</b>, <b>Dachtragwerk</b>, <b>Dachaufbau</b> und <b>Fertigteil</b></li>\n" +
          "<li>• Material wie z.B. <b>Baustoffart</b>, <b>Ortbeton</b>, <b>WU-Beton</b>, <b>Stahlfaserbeton </b> und <b>Mauerwerk</b></li>\n" +
          "</ul>"
      case "BL_5_1_5":
        return "<ul>\n" +
          "<li>• Elektroinstallation wie z.B. <b>Elektroplanung</b>, <b>Stromversorgung</b>, <b>Beleuchtung</b>, <b>Kabelkanäle</b> und <b>Stromleitungen</b></li>\n" +
          "<li>• Wärmeerzeugung und Verteilung wie z.B. <b>Wärmeversorgung</b>, <b>Heizungsanlagen</b>, <b>Fußbodenheizung</b>, <b>Feuerstätte</b>, <b>Abgasanlage</b>, <b>Fernwärme</b>, <b>Brennstoff</b> und <b>Elektroheizung</b></li>\n" +
          "<li>• Kälteanlagen und Lüftungssysteme wie z.B. <b>Lüftung</b>, <b>Wärmerückgewinngung</b>, <b>Kühlung</b>, <b>Klimatechnik</b> und <b>HKL-Anlage</b></li>\n" +
          "<li>• weitere Gebäudetechnik wie z.B. <b>Aufzuganlage</b>, <b>Blitzschutzanlage</b> und <b>Störmeldesysteme</b></li>\n" +
          "<li>• Sanitäranlagen wie z.B. <b>Wasseranschluss</b>, <b>Warmwasser</b>, <b>Duchlauferhitzer</b>, <b>Entwässerung</b>, <b>Abwasser</b>, <b>Schmutzwasser</b>, <b>Sanitärräume</b> und <b>WC-Anlagen</b></li>\n" +
          "</ul>"
      case "BL_5_1_6":
        return "<ul>\n" +
          "<li>• Brandschutzkonzept wie z.B. <b>Brandschutznachweis</b>, <b>Brandabschnitt</b>, <b>Fluchplan</b>, <b>Rettungsplan</b>, <b>Notausgangstür</b>, <b>Brandschutzmaterial</b>, <b>F30</b> und <b>F90</b></li>\n" +
          "<li>• Melde- und Löscheinrichtungen wie z.B. <b>Sprinkeranlage</b>, <b>Wandhydranten</b>, <b>Feuerlöscher Rauchmelder</b>, <b>Wärmeabzugsanlage</b> und <b>Entrauchung</b></li>\n" +
          "</ul>"
      case "BL_5_1_7":
        return "<ul>\n" +
          "<li>• Außenanlagen wie z.B. <b>Stellplatz</b>, <b>Garage</b>, <b>Carport</b>, <b>Zaunanlage</b>, <b>Terasse</b>, <b>Landschaftsgestaltung</b>, <b>Spielplatz</b>, <b>Rasenfläche</b>, <b>Bepflanzung</b> und <b>Außenbeleuchtung</b></li>\n" +
          "</ul>"
      case "BL_5_1_8":
        return "<ul>\n" +
          "<li>• Kosten wie z.B. <b>Rohbaukosten</b>, <b>Herstellungskosten</b> und <b>Finanzierung</b></li>\n" +
          "</ul>"
      case "BL_5_1_9":
        return "<ul>\n" +
          "<li>• Planung wie z.B. <b>Logistik</b>, <b>Bauablauf</b> und <b>Baucontrolling</b></li>\n" +
          "<li>• Dokumentation wie z.B. <b>Terminplan</b>, <b>Revisionsunterlagen</b> und <b>Schlussdokumentetion</b></li>\n" +
          "<li>• Baubetrieb wie z.B. <b>Beschäftigtenanzahl</b>, <b>Sicherheitskoordination</b> und <b>Weisungsberechtigtes Personal</b></li>\n" +
          "</ul>"
      case "BL_5_3_1":
        return "<ul>\n" +
          "<li>• Nutzung wie z.B. <b>Raumprogramm</b>, <b>Raumnummer</b>, <b>Raumbezeichnung</b>, <b>Büro</b>, <b>Flur</b>, <b>Aufenthaltsraum</b> und <b>Technikraum</b></li>\n" +
          "<li>• Lage wie z.B. <b>Ausrichtung</b>, <b>Nord</b>, <b>Süd</b>, <b>Geschoss</b>, <b>Erdgeschoss</b>, <b>Kellergeschoss</b>, <b>Untergeschoss </b>, <b>OG</b> und <b>DG</b></li>\n" +
          "<li>• Geometrie wie z.B. <b>Raumhöhe</b>, <b>Fläche</b>, <b>Raumfläche</b>, <b>Abmessung</b>, <b>Grundfläche</b>, <b>Wohnfläche</b>, <b>Raumumfang</b>, <b>BRH</b> und <b>Fensterfläche</b></li>\n" +
          "</ul>"
      case "BL_5_3_2":
        return "<ul>\n" +
          "<li>• Ausstattung wie z.B. <b>Raumausstattung</b>, <b>Einrichtung</b>, <b>Oberflächen</b>, <b>Bodenbeläge</b>, <b>technische Anforderungen</b> und <b>Einbauten</b></li>\n" +
          "<li>• Kapazität wie z.B. <b>Arbeitsplätze</b>, <b>Personen</b>, <b>Belegung</b> und <b>Sitzplätze</b></li>\n" +
          "</ul>"
      case "BL_5_4_1":
        return "<ul>\n" +
          "<li>• Standards wie z.B. <b>DIN 6779</b>, <b>Kennzeichnungssystem</b> und <b>DIN 276</b></li>\n" +
          "</ul>"
      case "BL_5_4_2":
        return "<ul>\n" +
          "<li>• Struktur wie z.B. <b>Gliederungsstufe</b>, <b>Ebene</b>, <b>Kennzeichen</b> und <b>Anlagenliste</b></li>\n" +
          "</ul>"
      case "BL_5_4_3":
        return "<ul>\n" +
          "<li>• Schlüssel wie z.B. <b>Ortskennzeichen</b>, <b>Ortsschlüssel</b>, <b>AKS-Schlüssel</b> und <b>Anlagenkennzeichen</b></li>\n" +
          "</ul>"


      default:
        return value
    }

  }
}

@Pipe({
  name: 'compliance'
})
export class ComplianceCheckPipe implements PipeTransform {
  /**
   * Transform Enum Value to String
   * @param value PartialServicesEnum Value
   */
  transform(value: string): string {
    switch (value) {
      case "projectName":
        return "<b>Projektname</b>"
      case "owner":
        return "<b>Besitzer</b>"
      case "floor":
        return "<b>Flur</b>"
      case "zipCode":
        return "<b>Postleitzahl</b>"
      case "street":
        return "<b>Straße</b>"
      case "city":
        return "<b>Stadt</b>"

      default:
        return String(value);
    }
  }
}

@Pipe({
  name: 'compliancePhrases'
})
export class ComplianceCheckPhrasesPipe implements PipeTransform {
  /**
   * Transform Enum Value to String
   * @param value PartialServicesEnum Value
   */
  transform(value: string): string {
    switch (value) {
      case "projectName":
        return "Es wurde im Dokument der <b>Projektname</b> aus den Projektinfos gefunden."
      case "owner":
        return "Es wurde im Dokument der <b>Besitzer</b> aus den Projektinfos gefunden."
      case "floor":
        return "Es wurde im Dokument der <b>Flur</b> aus den Projektinfos gefunden."
      case "street":
        return "Es wurde im Dokument die <b>Straße</b> aus den Projektinfos gefunden."
      case "zipCode":
        return "Es wurde im Dokument die <b>Postleitzahl</b> aus den Projektinfos gefunden."
      case "city":
        return "Es wurde im Dokument die <b>Stadt</b> aus den Projektinfos gefunden."

      case "projectNameFalse":
        return "Es wurde im Dokument der <b>Projektname</b> aus den Projektinfos nicht gefunden."
      case "ownerFalse":
        return "Es wurde im Dokument der <b>Besitzer</b> aus den Projektinfos nicht gefunden."
      case "floorFalse":
        return "Es wurde im Dokument der <b>Flur</b> aus den Projektinfos nicht gefunden."
      case "streetFalse":
        return "Es wurde im Dokument die <b>Straße</b> aus den Projektinfos nicht gefunden."
      case "zipCodeFalse":
        return "Es wurde im Dokument die <b>Postleitzahl</b> aus den Projektinfos nicht gefunden."
      case "cityFalse":
        return "Es wurde im Dokument die <b>Stadt</b> aus den Projektinfos nicht gefunden."

      default:
        return String(value);
    }
  }
}
