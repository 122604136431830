<div class="flex flex-col h-full justify-between items-start">
  <div class="h-44 justify-start items-center gap-4 inline-flex">
    <div class="w-52 flex-col justify-start items-start inline-flex">
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-medium font-['Inter'] leading-none line-clamp-1">Projektname:</div>
      </div>
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-medium font-['Inter'] leading-none line-clamp-1">Bauprojektart:</div>
      </div>
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-medium font-['Inter'] leading-none line-clamp-1">Adresse:</div>
      </div>
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-medium font-['Inter'] leading-none line-clamp-1">Ort:</div>
      </div>
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-medium font-['Inter'] leading-none line-clamp-1">Bundesland:</div>
      </div>
      <!--    <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">-->
      <!--      <div class="text-gray-500 text-xs font-medium font-['Inter'] leading-none line-clamp-1">Baujahr:</div>-->
      <!--    </div>-->
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-medium font-['Inter'] leading-none line-clamp-1">Projektbeteiligte:</div>
      </div>
    </div>
    <div class="w-full flex-col justify-start items-start inline-flex">
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-normal font-['Inter'] leading-none line-clamp-1">{{ project.name }}</div>
      </div>
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div
          class="text-gray-500 text-sm font-normal font-['Inter'] leading-none line-clamp-1">{{ project.projectKind | projectKind }}
        </div>
      </div>
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-normal font-['Inter'] leading-none line-clamp-1">{{ project.street }}
        </div>
      </div>
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div
          class="grow shrink basis-0 text-gray-500 text-sm font-normal font-['Inter'] leading-none line-clamp-1">{{ project.zipCode }} {{ project.buildingPlace }}
        </div>
      </div>
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div
          class="text-gray-500 text-sm font-normal font-['Inter'] leading-none line-clamp-1">{{ project.state | projectState }}
        </div>
      </div>
      <!--    <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">-->
      <!--      <div class="text-gray-500 text-xs font-normal font-['Inter'] leading-none line-clamp-1">{{ project.buildingYear }}</div>-->
      <!--    </div>-->
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div
          class="text-gray-500 text-sm font-normal font-['Inter'] leading-none line-clamp-1">{{ project.users!.length }}
        </div>
      </div>
    </div>
  </div>

  <div class="h-6 w-full justify-between items-start inline-flex pt-2 border-t border-gray-200">
    <div class="flex-col justify-start items-start inline-flex">
      <div class="justify-start items-center gap-2.5 inline-flex">
        <div class="py-1 justify-start items-center gap-4 flex">
          <div class="text-gray-500 text-sm font-medium font-['Inter'] leading-none">{{ project.owner }}</div>
        </div>
      </div>
    </div>
    <div class="flex-col justify-start items-end inline-flex">
      <div class="self-stretch py-1 justify-start items-center gap-4 inline-flex">
        <div class="text-gray-500 text-sm font-normal font-['Inter'] leading-none">Projektbesitzer</div>
      </div>
    </div>
  </div>

</div>
