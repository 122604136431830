import {Injectable} from '@angular/core';

/**
 * Controls the Sidebar and Title
 */
@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  /**
   * Title of application page
   */
  title?: string = undefined;

  /**
   * Sidebar Hint title
   */
  hintTitle?: string = undefined;

  /**
   * hint text
   */
  hint?: string = undefined;

  /**
   * state if view is project
   */
  isProject: boolean = false;

  /**
   * state if view is register
   */
  isRegister: boolean = false;

  isCollapsed = false;

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }


  /**
   *  Constructor
   */
  constructor() {}
}
