import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {Project, ProjectControllerService, User} from "../../../api";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit {

  @Input() user!: User;

  projects!: Project[];

  displayedColumns: string[] = ['name', 'role', 'actions'];
  dataSource!: MatTableDataSource<Project>;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('orgTbSort') orgTbSort = new MatSort();
  protected readonly faEllipsisVertical = faEllipsisVertical;

  constructor(private projectControllerService: ProjectControllerService) {
  }

  ngOnInit(): void {
    this.projectControllerService.getProjects().subscribe(projects => {
      this.projects = projects;
      this.dataSource = new MatTableDataSource(this.projects);
      this.dataSource.sort = this.sort;
    });
  }

}
