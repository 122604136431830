import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, UntypedFormControl, Validators} from "@angular/forms";
import {Project} from "../../../api";
import StateEnum = Project.StateEnum;
import {BYBauvorhaben} from "@buildinginformationcloud/bic-baf-tool/lib/components/BY/model/bYBauvorhaben";

@Component({
  selector: 'app-buildingClass',
  templateUrl: './building-class.component.html',
  styleUrls: ['./building-class.component.scss']
})
export class BuildingClassComponent implements OnInit {

  states: typeof StateEnum = Project.StateEnum;
  formBuildingClass!: FormGroup;
  buildingClass!: any;
  infoStringBuildingClass!: string;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formBuildingClass = this.formBuilder.group({
      state: new UntypedFormControl("", [Validators.required]),
      agricultural: new UntypedFormControl("", [Validators.required]),
      detached: new UntypedFormControl("", [Validators.required]),
      height: new UntypedFormControl("", [Validators.required]),
      usageUnitsMoreThanTwo: new UntypedFormControl("", [Validators.required]),
      floorSpace: new UntypedFormControl("", [Validators.required]),
      underground: new UntypedFormControl("", [Validators.required]),
    });

    this.formBuildingClass.statusChanges.subscribe(() => {
      if (this.formBuildingClass.valid) {
        this.calculateBuildingClass();
    }
    });
  }

  get state() {
    return this.formBuildingClass.get('state')!;
  }

  calculateBuildingClass() {
    if(this.formBuildingClass.valid) {
      if(this.formBuildingClass.value.state == this.states.RP) {
        this.buildingClass = this.calculateBuildingClassRP(this.formBuildingClass.value.agricultural, this.formBuildingClass.value.detached, this.formBuildingClass.value.height, this.formBuildingClass.value.usageUnitsMoreThanTwo, this.formBuildingClass.value.floorSpace, this.formBuildingClass.value.underground)
      }else{
        this.buildingClass = this.calculateBuildingClassDefault(this.formBuildingClass.value.agricultural, this.formBuildingClass.value.detached, this.formBuildingClass.value.height, this.formBuildingClass.value.usageUnitsMoreThanTwo, this.formBuildingClass.value.floorSpace, this.formBuildingClass.value.underground)
      }
    }
  }


  calculateBuildingClassDefault(agricultural: Boolean, detached: Boolean, height: number, usageUnitsMoreThanTwo: Boolean, floorSpace: number, underground: Boolean) {

    if (detached && !underground && (agricultural || !agricultural && height == 0 && !usageUnitsMoreThanTwo && floorSpace == 0)) {
      return "GK1"
    } else if (!detached && height == 0 && !usageUnitsMoreThanTwo && floorSpace == 0 && !underground) {
      return "GK2"
    } else if (agricultural !== !detached && height == 0 && (floorSpace !== 0 || usageUnitsMoreThanTwo) && !underground) {
      return "GK3"
    } else if ((agricultural && !detached && height == 1 || !agricultural && detached && height == 0) && floorSpace == 1 && !underground) {
      return "GK4"
    } else {
      return "GK5"
    }
  }
  calculateBuildingClassRP(buildingType: number, detached: Boolean, height: number, numberOfApartments: number, floorSpaceGreater400qm: Boolean, position: number) {

    if (position == 0 && detached && (buildingType != 0 && height == 0 && numberOfApartments == 0 || buildingType == 0 )) {
      return "GK1"
    } else if (buildingType == 1 && height == 0 && (!detached && numberOfApartments <= 1 || detached && (numberOfApartments == 1 && position == 0 || numberOfApartments == 2 && position == 2)) ||
      buildingType == 2 && height == 0 && !floorSpaceGreater400qm && (detached && numberOfApartments == 2 && position == 2 || numberOfApartments <= 1 && position == 0)){
      return "GK2"
    } else if (buildingType != 0 && height == 0) {
      return "GK3"
    } else if (buildingType != 0 && height == 1) {
      return "GK4"
    } else {
      return "GK5"
    }
  }

}
