import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators} from "@angular/forms";
import * as Stoff from "../../../../assets/stoff.json";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";

export interface WGKItem {
  name: string;
  amount: number | undefined;
  wgk: number | undefined;
  isEdit: boolean | undefined;
}
@Component({
  selector: 'app-safety-categories',
  templateUrl: './safety-categories.component.html',
  styleUrls: ['./safety-categories.component.scss']
})
export class SafetyCategoriesComponent implements OnInit {

  formSafetyCategory!: FormGroup;
  safetyCategory!: string;
  safetyCategoryHint: number[] = [];
  maximalSpaceSize!: number;

  dataSourceWGK: MatTableDataSource<WGKItem> = new MatTableDataSource<WGKItem>();
  displayedColumnsWGK: string[] = ['name', 'amount', 'WGK', 'actions'];
  wgkEntries: WGKItem[] = [];

  myControl = new FormControl('');
  myMap = new Map(Object.entries(Stoff));
  options: WGKItem[] = [];
  // options: WGKItem[] = [
  //   {name: 'One', amount: undefined, wgk: 1, isEdit: false},
  //   {name: 'Two', amount: undefined, wgk: 2, isEdit: false},
  //   {name: 'Three', amount: undefined, wgk: 3, isEdit: false},
  // ];
  filteredOptions!: Observable<WGKItem[]>;

  tempItem: WGKItem = {name: "", amount: undefined, wgk: undefined, isEdit: false};
  nameInput = "";
  currentEditing = false;
  wgkAmount!: number;


  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.myMap.forEach((value, key) => {
      if(parseInt(key) >= 0) {
        // @ts-ignore
        this.options.push({name: value.EINSTUFUNGSBEZEICHNUNG, amount: undefined, wgk: parseInt(value.WGK.substring(4,5)), isEdit: false})
      }
    });

    this.formSafetyCategory = this.formBuilder.group({
      calculationTypeSafetyCategory: new UntypedFormControl("", [Validators.required]),
      numberOfFloors: new UntypedFormControl("", [Validators.required]),
      fireResistance: new UntypedFormControl("", [Validators.required]),
      spaceSize: new UntypedFormControl("", [Validators.required]),
      safetyCategory: new UntypedFormControl("", [Validators.required]),

    });

    this.formSafetyCategory.valueChanges.subscribe(() => {
      if (this.formSafetyCategory.valid) {
        if (this.formSafetyCategory.value.calculationTypeSafetyCategory) {
          this.calculateSafetyCategory()
        }

        if (!this.formSafetyCategory.value.calculationTypeSafetyCategory) {
          this.calculateMaximalSpaceSize()
        }
      }
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.wgkEntries.push({name: "", amount: undefined, wgk: undefined, isEdit: true});
    this.dataSourceWGK.data = this.wgkEntries;
  }

  private _filter(value: string): WGKItem[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  setSelectedElementAsItem(element: WGKItem, i: number){
    this.dataSourceWGK.data[i].wgk = element.wgk;
    this.tempItem.name = element.name;
    this.tempItem.wgk = element.wgk;
  }

  addWGKEntry(){
    this.dataSourceWGK.data[this.dataSourceWGK.data.length-1].name = this.tempItem.name;
    this.dataSourceWGK.data[this.dataSourceWGK.data.length-1].amount = this.tempItem.amount;
    this.dataSourceWGK.data[this.dataSourceWGK.data.length-1].isEdit = false;
    this.wgkEntries = this.dataSourceWGK.data;
    let newItem: WGKItem = {name: "", amount: undefined, wgk: undefined, isEdit: true};
    this.wgkEntries.push(newItem);
    this.dataSourceWGK.data = this.wgkEntries;
    this.resetTempItem();

    this.calculateWGK();
  }

  editWGKEntry(i: number){
    this.dataSourceWGK.data[i].name = this.tempItem.name;
    this.dataSourceWGK.data[i].amount = this.tempItem.amount;
    this.dataSourceWGK.data[i].isEdit = false;
    this.resetTempItem();
    this.dataSourceWGK.data[this.dataSourceWGK.data.length-1].isEdit = true;
    this.currentEditing = false;
  }

  setEditable(i: number){
    this.dataSourceWGK.data[i].isEdit = true;
    this.nameInput = this.dataSourceWGK.data[i].name;
    this.tempItem.name = this.dataSourceWGK.data[i].name;
    this.tempItem.amount = this.dataSourceWGK.data[i].amount;
    this.tempItem.wgk = this.dataSourceWGK.data[i].wgk;
    this.dataSourceWGK.data[this.dataSourceWGK.data.length-1].isEdit = false;
    this.currentEditing = true;
  }

  deleteWGKEntry(i: number){
    this.wgkEntries.splice(i, 1);
    this.dataSourceWGK.data = this.wgkEntries;
  }

  resetTempItem(){
    this.tempItem.name = "";
    this.tempItem.amount = undefined;
    this.tempItem.wgk = undefined;
    this.nameInput =  "";
  }

  calculateSumAmount(i : number){
    let sum = 0;
    this.dataSourceWGK.data.forEach(element => {
      if(element.amount != undefined && element.wgk != undefined && element.wgk == i){
        sum += parseInt(String(element.amount));
      }
    });
    return sum;
  }

  calculateWGK(){
    let sum = 0;
    this.dataSourceWGK.data.forEach(element => {
      if(element.amount != undefined && element.wgk != undefined){
        if(element.wgk == 1){
          sum += parseInt(String(element.amount)) * 0.01;
        }else if(element.wgk == 2){
          sum += parseInt(String(element.amount)) * 0.1;
        }else if(element.wgk == 3){
          sum += parseInt(String(element.amount)) ;
        }
      }
    });
    this.wgkAmount = sum;

  }

  calculateMaximalSpaceSize(){
    let fl = this.formSafetyCategory.value.numberOfFloors;
    let fr = this.formSafetyCategory.value.fireResistance;
    let sc = this.formSafetyCategory.value.saftyCategory;
    this.safetyCategory = "";
    this.safetyCategoryHint = [];

    if(fl == 1){
      if(fr == 1) {
        if(sc == 'K1') {
          this.maximalSpaceSize = 1800;
          this.safetyCategoryHint = [1];
        }else if(sc == 'K2') {
          this.maximalSpaceSize = 2700;
          this.safetyCategoryHint = [1, 4];
        }else if(sc == 'K3.1') {
          this.maximalSpaceSize = 3200;
          this.safetyCategoryHint = [1];
        }else if(sc == 'K3.2') {
          this.maximalSpaceSize = 3600;
          this.safetyCategoryHint = [1];
        }else if(sc == 'K3.3') {
          this.maximalSpaceSize = 4200;
          this.safetyCategoryHint = [1];
        }else if(sc == 'K3.4') {
          this.maximalSpaceSize = 4500;
          this.safetyCategoryHint = [1];
        }else if(sc == 'K4') {
          this.maximalSpaceSize = 10000;
        }else{
          this.maximalSpaceSize = 0;
        }
      }else{
        if(sc == 'K1') {
          this.maximalSpaceSize = 3000;
        }else if(sc == 'K2') {
          this.maximalSpaceSize = 4500;
          this.safetyCategoryHint = [4];
        }else if(sc == 'K3.1') {
          this.maximalSpaceSize = 5400;
        }else if(sc == 'K3.2') {
          this.maximalSpaceSize = 6000;
        }else if(sc == 'K3.3') {
          this.maximalSpaceSize = 7000;
        }else if(sc == 'K3.4') {
          this.maximalSpaceSize = 7500;
        }else if(sc == 'K4') {
          this.maximalSpaceSize = 10000;
        }else{
          this.maximalSpaceSize = 0;
        }
      }

    }else if (fl == 2){
      if(fr == 2){
        if(sc == 'K1') {
          this.maximalSpaceSize = 800;
          this.safetyCategoryHint = [2,3];
        }else if(sc == 'K2') {
          this.maximalSpaceSize = 1200;
          this.safetyCategoryHint = [2,3];
        }else if(sc == 'K3.1') {
          this.maximalSpaceSize = 1400;
          this.safetyCategoryHint = [2,3];
        }else if(sc == 'K3.2') {
          this.maximalSpaceSize = 1600;
          this.safetyCategoryHint = [2];
        }else if(sc == 'K3.3') {
          this.maximalSpaceSize = 1800;
          this.safetyCategoryHint = [2];
        }else if(sc == 'K3.4') {
          this.maximalSpaceSize = 2000;
          this.safetyCategoryHint = [2];
        }else if(sc == 'K4') {
          this.maximalSpaceSize = 8500;
        }else{
          this.maximalSpaceSize = 0;
        }
      }else if(fr == 3) {
        if (sc == 'K1') {
          this.maximalSpaceSize = 1600;
          this.safetyCategoryHint = [2];
        } else if (sc == 'K2') {
          this.maximalSpaceSize = 2400;
          this.safetyCategoryHint = [2];
        } else if (sc == 'K3.1') {
          this.maximalSpaceSize = 2900;
          this.safetyCategoryHint = [2];
        } else if (sc == 'K3.2') {
          this.maximalSpaceSize = 3200;
          this.safetyCategoryHint = [2];
        } else if (sc == 'K3.3') {
          this.maximalSpaceSize = 3600;
          this.safetyCategoryHint = [2];
        } else if (sc == 'K3.4') {
          this.maximalSpaceSize = 4000;
          this.safetyCategoryHint = [2];
        } else if (sc == 'K4') {
          this.maximalSpaceSize = 8500;
        } else {
          this.maximalSpaceSize = 0;
        }
      }else{
        if (sc == 'K1') {
          this.maximalSpaceSize = 2400;
        } else if (sc == 'K2') {
          this.maximalSpaceSize = 3600;
        } else if (sc == 'K3.1') {
          this.maximalSpaceSize = 4300;
        } else if (sc == 'K3.2') {
          this.maximalSpaceSize = 4800;
        } else if (sc == 'K3.3') {
          this.maximalSpaceSize = 5500;
        } else if (sc == 'K3.4') {
          this.maximalSpaceSize = 6000;
        } else if (sc == 'K4') {
          this.maximalSpaceSize = 8500;
        } else {
          this.maximalSpaceSize = 0;
        }
      }

    }else if (fl == 3){
      if(fr == 3){
        if(sc == 'K1') {
          this.maximalSpaceSize = 1200;
          this.safetyCategoryHint = [2,3];
        }else if(sc == 'K2') {
          this.maximalSpaceSize = 1800;
          this.safetyCategoryHint = [2];
        }else if(sc == 'K3.1') {
          this.maximalSpaceSize = 2100;
          this.safetyCategoryHint = [2];
        }else if(sc == 'K3.2') {
          this.maximalSpaceSize = 2400;
          this.safetyCategoryHint = [2];
        }else if(sc == 'K3.3') {
          this.maximalSpaceSize = 2800;
          this.safetyCategoryHint = [2];
        }else if(sc == 'K3.4') {
          this.maximalSpaceSize = 3000;
          this.safetyCategoryHint = [2];
        }else if(sc == 'K4') {
          this.maximalSpaceSize = 6500;
        }else{
          this.maximalSpaceSize = 0;
        }
      }else {
        if (sc == 'K1') {
          this.maximalSpaceSize = 1800;
        } else if (sc == 'K2') {
          this.maximalSpaceSize = 2700;
        } else if (sc == 'K3.1') {
          this.maximalSpaceSize = 3200;
        } else if (sc == 'K3.2') {
          this.maximalSpaceSize = 3600;
        } else if (sc == 'K3.3') {
          this.maximalSpaceSize = 4100;
        } else if (sc == 'K3.4') {
          this.maximalSpaceSize = 4500;
        } else if (sc == 'K4') {
          this.maximalSpaceSize = 6500;
        } else {
          this.maximalSpaceSize = 0;
        }
      }

    }else if (fl == 4){
      if(sc == 'K1') {
        this.maximalSpaceSize = 1500;
      }else if(sc == 'K2') {
        this.maximalSpaceSize = 2300;
      }else if(sc == 'K3.1') {
        this.maximalSpaceSize = 2700;
      }else if(sc == 'K3.2') {
        this.maximalSpaceSize = 3000;
      }else if(sc == 'K3.3') {
        this.maximalSpaceSize = 3500;
      }else if(sc == 'K3.4') {
        this.maximalSpaceSize = 3800;
      }else if(sc == 'K4') {
        this.maximalSpaceSize = 5000;
      }else{
        this.maximalSpaceSize = 0;
      }

    }else {
      if(sc == 'K1') {
        this.maximalSpaceSize = 1200;
      }else if(sc == 'K2') {
        this.maximalSpaceSize = 1800;
      }else if(sc == 'K3.1') {
        this.maximalSpaceSize = 2200;
      }else if(sc == 'K3.2') {
        this.maximalSpaceSize = 2400;
      }else if(sc == 'K3.3') {
        this.maximalSpaceSize = 2800;
      }else if(sc == 'K3.4') {
        this.maximalSpaceSize = 3000;
      }else if(sc == 'K4') {
        this.maximalSpaceSize = 4000;
      }else{
        this.maximalSpaceSize = 0;
      }

    }

  }

  calculateSafetyCategory(){
    let fl = this.formSafetyCategory.value.numberOfFloors;
    let fr = this.formSafetyCategory.value.fireResistance;
    let ss = this.formSafetyCategory.value.spaceSize;
    this.maximalSpaceSize = 0;
    this.safetyCategoryHint = [];


    if(fl == 1){
      if(fr == 1){
        if(ss <= 1800) {
          this.safetyCategory = 'K1';
          this.safetyCategoryHint = [1];
        }else if(ss <= 2700) {
          this.safetyCategory = 'K2';
          this.safetyCategoryHint = [1, 4];
        }else if(ss <= 3200) {
          this.safetyCategory = 'K3.1';
          this.safetyCategoryHint = [1];
        }else if(ss <= 3600) {
          this.safetyCategory = 'K3.2';
          this.safetyCategoryHint = [1];
        }else if(ss <= 4200) {
          this.safetyCategory = 'K3.3';
          this.safetyCategoryHint = [1];
        }else if(ss <= 4500) {
          this.safetyCategory = 'K3.4';
          this.safetyCategoryHint = [1];
        }else if(ss <= 10000) {
          this.safetyCategory = 'K4';
        }else{
          this.safetyCategory = 'ERROR';
        }
      }else {
        if(ss <= 3000) {
          this.safetyCategory = 'K1';
        }else if(ss <= 4500) {
          this.safetyCategory = 'K2';
        }else if(ss <= 5400) {
          this.safetyCategory = 'K3.1';
        }else if(ss <= 6000) {
          this.safetyCategory = 'K3.2';
        }else if(ss <= 7000) {
          this.safetyCategory = 'K3.3';
        }else if(ss <= 7500) {
          this.safetyCategory = 'K3.4';
        }else if(ss <= 10000) {
          this.safetyCategory = 'K4';
        }else{
          this.safetyCategory = 'ERROR';}
      }

    }else if(fl == 2){
      if(fr == 2){
        if(ss <= 800) {
          this.safetyCategory = 'K1';
          this.safetyCategoryHint = [2,3];
        }else if(ss <= 1200) {
          this.safetyCategory = 'K2';
          this.safetyCategoryHint = [2,3];
        }else if(ss <= 1400) {
          this.safetyCategory = 'K3.1';
          this.safetyCategoryHint = [2,3];
        }else if(ss <= 1600) {
          this.safetyCategory = 'K3.2';
          this.safetyCategoryHint = [2];
        }else if(ss <= 1800) {
          this.safetyCategory = 'K3.3';
          this.safetyCategoryHint = [2];
        }else if(ss <= 2000) {
          this.safetyCategory = 'K3.4';
          this.safetyCategoryHint = [2];
        }else if(ss <= 8500) {
          this.safetyCategory = 'K4';
        }else{
          this.safetyCategory = 'ERROR';
        }
      }else if(fr == 3){
        if(ss <= 1600) {
          this.safetyCategory = 'K1';
          this.safetyCategoryHint = [2];
        }else if(ss <= 2400) {
          this.safetyCategory = 'K2';
          this.safetyCategoryHint = [2];
        }else if(ss <= 2900) {
          this.safetyCategory = 'K3.1';
          this.safetyCategoryHint = [2];
        }else if(ss <= 3200) {
          this.safetyCategory = 'K3.2';
          this.safetyCategoryHint = [2];
        }else if(ss <= 3600) {
          this.safetyCategory = 'K3.3';
          this.safetyCategoryHint = [2];
        }else if(ss <= 4000) {
          this.safetyCategory = 'K3.4';
          this.safetyCategoryHint = [2];
        }else if(ss <= 8500) {
          this.safetyCategory = 'K4';
        }else{
          this.safetyCategory = 'ERROR';
        }
      }else{
        if(ss <= 2400) {
          this.safetyCategory = 'K1';
        }else if(ss <= 3600) {
          this.safetyCategory = 'K2';
        }else if(ss <= 4300) {
          this.safetyCategory = 'K3.1';
        }else if(ss <= 4800) {
          this.safetyCategory = 'K3.2';
        }else if(ss <= 5500) {
          this.safetyCategory = 'K3.3';
        }else if(ss <= 6000) {
          this.safetyCategory = 'K3.4';
        }else if(ss <= 8500) {
          this.safetyCategory = 'K4';
        }else{
          this.safetyCategory = 'ERROR';
        }
      }
    }else if(fl == 3){
      if(fr == 3) {
        if (ss <= 1200) {
          this.safetyCategory = 'K1';
          this.safetyCategoryHint = [2, 3];
        } else if (ss <= 1800) {
          this.safetyCategory = 'K2';
          this.safetyCategoryHint = [2];
        } else if (ss <= 2100) {
          this.safetyCategory = 'K3.1';
          this.safetyCategoryHint = [2];
        } else if (ss <= 2400) {
          this.safetyCategory = 'K3.2';
          this.safetyCategoryHint = [2];
        } else if (ss <= 2800) {
          this.safetyCategory = 'K3.3';
          this.safetyCategoryHint = [2];
        } else if (ss <= 3000) {
          this.safetyCategory = 'K3.4';
          this.safetyCategoryHint = [2];
        } else if (ss <= 6500) {
          this.safetyCategory = 'K4';
        } else {
          this.safetyCategory = 'ERROR';
        }
      }else{
        if (ss <= 1800) {
          this.safetyCategory = 'K1';
        } else if (ss <= 2700) {
          this.safetyCategory = 'K2';
        } else if (ss <= 3200) {
          this.safetyCategory = 'K3.1';
        } else if (ss <= 3600) {
          this.safetyCategory = 'K3.2';
        } else if (ss <= 4100) {
          this.safetyCategory = 'K3.3';
        } else if (ss <= 4500) {
          this.safetyCategory = 'K3.4';
        } else if (ss <= 6500) {
          this.safetyCategory = 'K4';
        } else {
          this.safetyCategory = 'ERROR';
        }
      }
    }else if(fl == 4){
      if (ss <= 1500) {
        this.safetyCategory = 'K1';
      } else if (ss <= 2300) {
        this.safetyCategory = 'K2';
      } else if (ss <= 2700) {
        this.safetyCategory = 'K3.1';
      } else if (ss <= 3000) {
        this.safetyCategory = 'K3.2';
      } else if (ss <= 3500) {
        this.safetyCategory = 'K3.3';
      } else if (ss <= 3800) {
        this.safetyCategory = 'K3.4';
      } else if (ss <= 5000) {
        this.safetyCategory = 'K4';
      } else {
        this.safetyCategory = 'ERROR';
      }
    }else{
      if (ss <= 1200) {
        this.safetyCategory = 'K1';
      } else if (ss <= 1800) {
        this.safetyCategory = 'K2';
      } else if (ss <= 2200) {
        this.safetyCategory = 'K3.1';
      } else if (ss <= 2400) {
        this.safetyCategory = 'K3.2';
      } else if (ss <= 2800) {
        this.safetyCategory = 'K3.3';
      } else if (ss <= 3000) {
        this.safetyCategory = 'K3.4';
      } else if (ss <= 4000) {
        this.safetyCategory = 'K4';
      } else {
        this.safetyCategory = 'ERROR';
      }
    }
  }

  setMissingValue(){
    if(this.formSafetyCategory.value.calculationTypeSafetyCategory){
      // @ts-ignore
      this.formSafetyCategory.get('safetyCategory').setValue(0);
    }else{
      // @ts-ignore
      this.formSafetyCategory.get('spaceSize').setValue(0);
    }
  }

}
