import { Injectable } from '@angular/core';
import {local} from "d3-selection";


interface Exchange {
  guids: string[];
  successGuids: string[];
  warningGuids: string[];
  failedGuids: string[];
  successColor: number;
  waringColor: number;
  failedColor: number;
  overrideOriginalColor: boolean;
}



@Injectable({
  providedIn: 'root'
})
export class ExchangeService {

  constructor() { }

  public start(){
    let emptyExchange: Exchange = {
      guids: [],
      successGuids: [],
      warningGuids: [],
      failedGuids: [],
      successColor: 0x009999,
      waringColor: 0xFFC000,
      failedColor: 0xEE3B3B,
      overrideOriginalColor: false
    }
    localStorage.setItem('exchange', JSON.stringify(emptyExchange));
  }

  public clear(){
    let emptyExchange: Exchange = {
      guids: [],
      successGuids: [],
      warningGuids: [],
      failedGuids: [],
      successColor: 0x009999,
      waringColor: 0xFFC000,
      failedColor: 0xEE3B3B,
      overrideOriginalColor: false
    }
    localStorage.setItem('exchange', JSON.stringify(emptyExchange));
  }

  public setGuids(guids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.guids = guids;
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public setSuccessGuids(successGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.successGuids = successGuids;
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public setWarningGuids(warningGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.warningGuids = warningGuids;
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public setFailedGuids(failedGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.failedGuids = failedGuids;
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public setSuccessColor(color: string){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.successColor = color;
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public setWarningColor(color: string){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.waringColor = color;
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public setFailedColor(color: string){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.failedColor = color;
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public setOverrideOriginalColor(override: boolean){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.overrideOriginalColor = override;
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public getGuids(){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    return exchange.guids;
  }

  public getSuccessGuids(){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    return exchange.successGuids;
  }

  public getWarningGuids(){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    return exchange.warningGuids;
  }

  public getFailedGuids(){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    return exchange.failedGuids;
  }

  public getSuccessColor(){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    return exchange.successColor;
  }

  public getWarningColor(){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    return exchange.waringColor;
  }

  public getFailedColor(){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    return exchange.failedColor;
  }

  public getOverrideOriginalColor(){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    return exchange.overrideOriginalColor;
  }

  public addToGuids(guids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.guids = exchange.guids.concat(guids);
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public addToSuccessGuids(successGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.successGuids = exchange.successGuids.concat(successGuids);
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public addToWarningGuids(warningGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.warningGuids = exchange.warningGuids.concat(warningGuids);
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public addToFailedGuids(failedGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.failedGuids = exchange.failedGuids.concat(failedGuids);
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public removeFromGuids(guids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.guids = exchange.guids.filter((guid: string) => !guids.includes(guid));
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public removeFromSuccessGuids(successGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.successGuids = exchange.successGuids.filter((guid: string) => !successGuids.includes(guid));
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public removeFromWarningGuids(warningGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.warningGuids = exchange.warningGuids.filter((guid: string) => !warningGuids.includes(guid));
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }

  public removeFromFailedGuids(failedGuids: string[]){
    let exchange = JSON.parse(localStorage.getItem('exchange') as string);
    exchange.failedGuids = exchange.failedGuids.filter((guid: string) => !failedGuids.includes(guid));
    localStorage.setItem('exchange', JSON.stringify(exchange));
  }


}
