import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faChevronLeft, faChevronRight, faGauge} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input() opened: boolean = true;
  @Output() openedChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  open() {
    this.opened = true;
    this.openedChange.emit(this.opened);
  }

  close() {
    this.opened = false;
    this.openedChange.emit(this.opened);
  }


  protected readonly faChevronRight = faChevronRight;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faGauge = faGauge;
}
