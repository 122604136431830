/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CurrencyOption } from './currencyOption';
import { CustomUnitAmount } from './customUnitAmount';
import { JsonObject } from './jsonObject';
import { Product } from './product';
import { Recurring } from './recurring';
import { StripeResponse } from './stripeResponse';
import { StripeResponseGetter } from './stripeResponseGetter';
import { Tier } from './tier';
import { TransformQuantity } from './transformQuantity';

export interface Price { 
    lastResponse?: StripeResponse;
    rawJsonObject?: JsonObject;
    responseGetter?: StripeResponseGetter;
    active?: boolean;
    billingScheme?: string;
    created?: number;
    currency?: string;
    currencyOptions?: { [key: string]: CurrencyOption; };
    customUnitAmount?: CustomUnitAmount;
    deleted?: boolean;
    id?: string;
    livemode?: boolean;
    lookupKey?: string;
    metadata?: { [key: string]: string; };
    nickname?: string;
    object?: string;
    product?: string;
    recurring?: Recurring;
    taxBehavior?: string;
    tiers?: Array<Tier>;
    tiersMode?: string;
    transformQuantity?: TransformQuantity;
    type?: string;
    unitAmount?: number;
    unitAmountDecimal?: number;
    productObject?: Product;
}