<div class="flex flex-col h-full w-full">
  <app-swm-stepper class="w-full"></app-swm-stepper>

  <div class="flex flex-col justify-start items-start gap-2 h-full overflow-hidden">


    <div class="flex flex-col w-full p-4">
      <app-overview-report [checkType]="'Brandschutz-Check'"
                           [completedValue]="completed"
                           [date]="fixedCheckDate"
                           [errorValue]="error"
                           [rules]="total"
                           [warningValue]="warning"
                           [fileDeactivated]="false"
                           [fileId]="fileId"
                           [whatWasChecked]=" total == 1 ? 'Anforderung' : 'Anforderungen'"
                           [activateDownload]="true"
                           (download)="downloadReport()"
                           class="w-full">
      </app-overview-report>

    </div>


    <div class="flex flex-row w-full h-full pl-4 pr-2 overflow-y-scroll">
      <div class="flex flex-col h-full w-full">
        <cdk-table [dataSource]="dataSource" class="w-full" multiTemplateDataRows>

          <ng-container cdkColumnDef="actions">
            <cdk-header-cell class=" w-[1%] h-full py-1 justify-center items-center px-2" cdk-header-cell
                             *cdkHeaderCellDef></cdk-header-cell>
            <cdk-cell class="w-[1%] h-full justify-center items-center px-2" cdk-cell *cdkCellDef="let element">
              <fa-icon class="text-primary w-2"
                       [icon]="element == expandedElement ? faChevronDown : faChevronRight"></fa-icon>
            </cdk-cell>
          </ng-container>

          <!--        <ng-container cdkColumnDef="task">-->
          <!--          <cdk-header-cell class="w-[10%] flex flex-row justify-center h-full py-2"-->
          <!--                           *cdkHeaderCellDef></cdk-header-cell>-->
          <!--          <cdk-cell class="w-[10%] flex flex-row justify-center h-full"-->
          <!--                    *cdkCellDef="let element">-->
          <!--            <fa-icon [matTooltip]="'Aufgabe erstellen'" (click)="createTask(element); $event.stopPropagation()" [icon]="faClipboardList"-->
          <!--                     class="text-2xl w-6 text-primary hidden group-hover:block"></fa-icon>-->
          <!--          </cdk-cell>-->
          <!--        </ng-container>-->


          <!--        <ng-container cdkColumnDef="type">-->
          <!--          <cdk-header-cell class="w-[19%] flex flex-row justify-center h-full py-2 font-medium" *cdkHeaderCellDef>Typ-->
          <!--          </cdk-header-cell>-->
          <!--          <cdk-cell class="w-[19%] flex flex-row justify-center h-full" *cdkCellDef="let element">-->
          <!--            <div class="flex flex-row justify-center items-center" [matTooltip]=" element.type ==='success' ? 'Erfüllt' : element.type ==='warning' ? 'Teilweise Erfüllt' : 'Nicht Erfüllt'">-->
          <!--              <ng-container *ngIf="element.type == 'error'" >-->
          <!--                <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"-->
          <!--                                  [state]="CircleState.RED"></app-circle-state>-->
          <!--              </ng-container>-->
          <!--              <ng-container *ngIf="element.type == 'warning'">-->
          <!--                <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"-->
          <!--                                  [state]="CircleState.YELLOW"></app-circle-state>-->
          <!--              </ng-container>-->
          <!--              <ng-container *ngIf="element.type == 'success'">-->
          <!--                <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"-->
          <!--                                  [state]="CircleState.GREEN"></app-circle-state>-->
          <!--              </ng-container>-->
          <!--            </div>-->
          <!--          </cdk-cell>-->
          <!--        </ng-container>-->

          <ng-container cdkColumnDef="name">
            <cdk-header-cell class="w-[20%] flex flex-row justify-start h-full py-2 px-3.5 font-medium"
                             *cdkHeaderCellDef>
              Nummer
            </cdk-header-cell>
            <mat-cell class="w-[20%] flex flex-row justify-start h-full py-2 px-3.5"
                      *cdkCellDef="let element"> {{ element.name }}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="description">
            <cdk-header-cell class="flex flex-row w-[50%] justify-start h-full py-2 font-medium" *cdkHeaderCellDef>
              Name
            </cdk-header-cell>
            <mat-cell class="flex flex-row w-[50%] justify-start h-full py-2"
                      *cdkCellDef="let element"> {{ element.description }}
            </mat-cell>
          </ng-container>

          <ng-container cdkColumnDef="task">
            <cdk-header-cell class="w-[10%] flex flex-row justify-center h-full py-2"
                             *cdkHeaderCellDef></cdk-header-cell>
            <cdk-cell class="w-[10%] flex flex-row justify-center h-full"
                      *cdkCellDef="let element">
              <fa-icon [matTooltip]="'Aufgabe erstellen'" (click)="createTask(element); $event.stopPropagation()"
                       [icon]="faClipboardList"
                       class="text-2xl w-6 text-primary hidden group-hover:block"></fa-icon>
            </cdk-cell>
          </ng-container>


          <ng-container cdkColumnDef="type">
            <cdk-header-cell class="w-[19%] flex flex-row justify-center h-full py-2 font-medium" *cdkHeaderCellDef>Typ
            </cdk-header-cell>
            <cdk-cell class="w-[19%] flex flex-row justify-center h-full" *cdkCellDef="let element">
              <div class="flex flex-row justify-center items-center">
                <ng-container *ngIf="calculateResult(element) == 'error'">
                  <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"
                                    [state]="CircleState.RED"></app-circle-state>
                </ng-container>
                <ng-container *ngIf="calculateResult(element) == 'warning'">
                  <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"
                                    [state]="CircleState.YELLOW"></app-circle-state>
                </ng-container>
                <ng-container *ngIf="calculateResult(element) == 'success'">
                  <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"
                                    [state]="CircleState.GREEN"></app-circle-state>
                </ng-container>
              </div>
            </cdk-cell>
          </ng-container>


          <ng-container matColumnDef="expandedDetail">
            <mat-cell *cdkCellDef="let element" [attr.colspan]="displayedColumnsDetail.length">
              <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                   class="flex w-full overflow-hidden">
                <!--                  <cdk-table [dataSource]="expandedElement?.k1Rules!" class="w-full p-2 z-0">-->
                <!--                    <ng-container cdkColumnDef="id">-->
                <!--                      <cdk-header-cell-->
                <!--                        [class]="'flex flex-row justify-start items-start h-full font-medium px-2 w-full'"-->
                <!--                        *cdkHeaderCellDef>ID-->
                <!--                      </cdk-header-cell>-->
                <!--                      <cdk-cell-->
                <!--                        [class]="'flex flex-row justify-start items-start h-full w-full px-2'"-->
                <!--                        *cdkCellDef="let element">{{ element.id }}-->
                <!--                      </cdk-cell>-->
                <!--                    </ng-container>-->

                <!--                    <ng-container cdkColumnDef="k1">-->
                <!--                      <cdk-header-cell-->
                <!--                        [class]="'flex flex-row justify-start items-start h-full font-medium px-2 w-full'"-->
                <!--                        *cdkHeaderCellDef>K1-->
                <!--                      </cdk-header-cell>-->
                <!--                      <cdk-cell-->
                <!--                        [class]="'flex flex-row justify-start items-start h-full w-full px-2'"-->
                <!--                        *cdkCellDef="let element">{{ element.result.length }}-->
                <!--                      </cdk-cell>-->
                <!--                    </ng-container>-->

                <!--&lt;!&ndash;                    <ng-container *ngFor="let column of rule.tableColumns" [cdkColumnDef]="column.name">&ndash;&gt;-->
                <!--&lt;!&ndash;                      <cdk-header-cell&ndash;&gt;-->
                <!--&lt;!&ndash;                        [class]="'flex flex-row justify-start items-start h-full font-medium px-2 w-full'"&ndash;&gt;-->
                <!--&lt;!&ndash;                        *cdkHeaderCellDef>&ndash;&gt;-->
                <!--&lt;!&ndash;                        {{ column.description ?? column.name }}&ndash;&gt;-->
                <!--&lt;!&ndash;                      </cdk-header-cell>&ndash;&gt;-->
                <!--&lt;!&ndash;                      <cdk-cell&ndash;&gt;-->
                <!--&lt;!&ndash;                        [class]="'flex flex-row justify-start items-start h-full w-full px-2'"&ndash;&gt;-->
                <!--&lt;!&ndash;                        *cdkCellDef="let element">{{ element.attributes[column.name] }}&ndash;&gt;-->
                <!--&lt;!&ndash;                      </cdk-cell>&ndash;&gt;-->

                <!--&lt;!&ndash;                    </ng-container>&ndash;&gt;-->


                <!--                    <cdk-header-row *cdkHeaderRowDef="displayedColumnsDetail; sticky: true"-->
                <!--                                    class="flex flex-row justify-start items-start w-full border-b-2 border-b-gray-400 bg-white"></cdk-header-row>-->

                <!--                    <cdk-row *cdkRowDef="let row; columns: displayedColumnsDetail;"-->
                <!--                             class="flex flex-row justify-start items-start w-full border-t-2 border-t-gray-300 py-2 px-2"></cdk-row>-->
                <!--                  </cdk-table>-->
                <div class="flex flex-col w-full">
                  <!--                          <div class="flex flex-row w-full border-2 border-black rounded-md">-->
                  <!--                            <div  class="flex flex-col w-full ">-->
                  <!--                            Welche Kapitel / Themen wurden gefunden?-->
                  <!--                              <ng-container *ngFor="let rule of element.k1Rules">-->
                  <!--                                <ng-container *ngIf="rule.id.endsWith('__0')">-->
                  <!--                                  <div *ngFor="let chapter of rule.result" class="flex flex-row ">-->
                  <!--                                {{element.description}} wurde in Kapitel  {{chapter.chapter_number}} thematisiert-->
                  <!--                              </div>-->
                  <!--                                </ng-container>-->
                  <!--                              </ng-container>-->
                  <!--                            </div>-->
                  <!--                          </div>-->

                  <!--                          <div class="flex flex-row w-full border-2 border-black rounded-md">-->
                  <!--                            <div class="flex flex-col w-full">-->
                  <!--                                Wurden die Anforderungen und Inhalte an das Thema erfüllt? (K1 und K2 Prüfregeln)-->
                  <!--                              <ng-container *ngFor="let rule of element.k1Rules">-->
                  <!--                                <ng-container *ngIf="!rule.id.endsWith('__0')">-->

                  <!--                                  <ng-container *ngIf="rule.k2Rules.length === 0">-->
                  <!--                                    <div *ngFor="let chapter of rule.result" class="flex flex-row ">-->
                  <!--                                      Inhalt {{element.description}} wurde in Kapitel  {{chapter.chapter_number}} gefunden-->
                  <!--                                    </div>-->
                  <!--                                  </ng-container>-->

                  <!--                                  <ng-container *ngIf="rule.k2Rules.length !== 0">-->
                  <!--                                    <ng-container *ngIf="rule.result.length !== 0" class="flex flex-row ">-->
                  <!--                                     <ng-container *ngFor="let k2 of rule.k2Rules">-->
                  <!--                                       <ng-container *ngIf="k2.result.length === 0">-->
                  <!--                                         Inhalt „Name K1 Prüfregel“ wurde nicht gefunden und die entsprechenden Anforderungen „Name K2 Prüfregel“ wurde nicht erfüllt-->
                  <!--                                       </ng-container>-->

                  <!--                                     </ng-container>-->
                  <!--                                    </ng-container>-->

                  <!--                                  </ng-container>-->


                  <!--                                <div *ngFor="let chapter of rule.result" class="flex flex-row ">-->
                  <!--                                {{element.description}} wurde in Kapitel  {{chapter.chapter_number}} thematisiert-->
                  <!--                              </div>-->
                  <!--                                  <div *ngFor="let chapter of rule.result" class="flex flex-row ">-->
                  <!--                                {{element.description}} wurde in Kapitel  {{chapter.chapter_number}} thematisiert-->
                  <!--                              </div>-->
                  <!--                                </ng-container>-->


                  <!--                              </ng-container>-->

                  <!--                            </div>-->
                  <!--                          </div>-->
                  <div class="flex flex-col w-full gap-2 p-2">
                    <div *ngFor="let k1rule of element.k1Rules"
                         class="flex flex-col w-full gap-2 shadow-full rounded-md p-2">
                      <div class="flex flex-row" *ngIf="k1rule.result?.length == 0">
                        Laut Überprüfung wurde das Thema &nbsp;

                        <p class="font-semibold">{{ k1rule.name }}</p> &nbsp; in keinem Kapiteln explizit
                        thematisiert.
                      </div>


                      <div class="flex flex-row" *ngIf="k1rule.result?.length > 0">

                        <p class="font-medium">{{ k1rule.name }}</p> &nbsp;wurde in den nachfolgenden Kapiteln
                        thematisiert:
                      </div>

                      <ng-container *ngFor="let chapter of  k1rule.result">
                        <ng-container *ngIf="k2Map.get(element.id).get(k1rule)">
                          <ng-container *ngIf="resultMap.get(element.id)?.get(k1rule)?.has(chapter)">
                            <div *ngIf="resultMap.get(element.id)?.get(k1rule)?.get(chapter)?.length > 0 "
                                 class="flex flex-col p-2 border-gray-300 border-2 rounded-md gap-2 bg-white">
                              <div class="flex flex-row items-center gap-2">
                                <div class="flex flex-col font-medium">
                                  {{ chapter.chapter_number!.replaceAll('_', '.') }} {{ chapter.chapter_title }}
                                </div>
                                <div class="flex flex-row justify-between items-start">
                                  <div class="text-lg pr-1 scale-75" #overlayTrigger
                                       (mouseenter)="hoveredChapter = chapter" (mouseleave)="hoveredChapter = null">
                                    <fa-icon [icon]="faCircleQuestion" class="text-primary text-2xl"
                                             #infoIcon></fa-icon>
                                  </div>
                                  <ng-template
                                    cdkConnectedOverlay
                                    [cdkConnectedOverlayOrigin]="overlayTrigger"
                                    [cdkConnectedOverlayOpen]="hoveredChapter == chapter"
                                  >
                                    <div class="text-xs bg-white rounded-[5px] p-[5px] shadow-full break-words w-full">
                                      <div [innerHTML]="getInfoText(chapter)"></div>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>

                              <div class="flex flex-col bg-green-200 rounded-md p-2"
                                   *ngIf="resultMap.get(element.id).get(k1rule).get(chapter).length > 0">
                                Zusätzlich wurden folgende Anforderungen in diesem Kapitel gefunden:
                                <div class="flex flex-row"
                                     *ngFor="let k2rule of resultMap.get(element.id).get(k1rule).get(chapter)">
                                  - {{ k2rule.name }}
                                </div>
                              </div>
                              <div class="flex flex-col bg-red-200 rounded-md p-2"
                                   *ngIf="k1rule.k2Rules.length > 0 && resultMap.get(element.id).get(k1rule).get(chapter).length != k1rule.k2Rules.length">
                                Folgende Anforderungen wies das Kapitel nicht auf:
                                <ng-container *ngFor="let k2rule of k2Map.get(element.id).get(k1rule)">
                                  <div class="flex flex-row" *ngIf="noFullfilledK2s(k2rule, element, k1rule, chapter)">
                                    - {{ k2rule.name }}
                                  </div>


                                </ng-container>

                              </div>
                            </div>


                          </ng-container>
                        </ng-container>
                      </ng-container>


                      <div class="border-gray-300 border-2 rounded-md p-2" *ngIf="k1rule.result?.length > 0">
                        <ng-container *ngIf="isThereAChapterWithoutK2(element, k1rule)">
                          Weitere Kapitel, die das Thema behandeln, jedoch keine der Anforderungen erfüllen:
                          <button class="text-primary mt-2" (click)="switchExpand(k1rule)">
                            {{ expandedMap.get(k1rule) ? 'Weniger anzeigen' : 'Mehr anzeigen' }}
                          </button>
                          <div [@detailExpand]="expandedMap.get(k1rule) ? 'expanded' : 'collapsed'">
                            <div class="flex flex-col p-2 bg-gray-300 rounded-md"
                                 [class]=" expandedMap.get(k1rule) ? 'visible' :  'invisible'   ">
                              <p>Anforderungen:</p>
                              <div class="flex flex-row " *ngFor="let k2 of k2Map.get(element.id)?.get(k1rule)">
                                - {{ k2.name }}
                              </div>
                            </div>
                            <div class="p-2" [class]=" expandedMap.get(k1rule) ? 'visible' :  'invisible'   ">
                              <ng-container *ngFor="let chapter of  k1rule.result" class="p-2">
                                <div class="flex flex-row items-center gap-2"
                                     *ngIf="resultMap.get(element.id)?.get(k1rule)?.get(chapter)?.length == 0 ">
                                  <div>
                                    {{ chapter.chapter_number!.replaceAll('_', '.') }} {{ chapter.chapter_title }}
                                  </div>
                                  <div class="flex flex-row justify-between items-start">
                                    <div class="text-lg pr-1 scale-75" #overlayTrigger
                                         (mouseenter)="hoveredChapter = chapter" (mouseleave)="hoveredChapter = null">
                                      <fa-icon [icon]="faCircleQuestion" class="text-primary text-2xl"
                                               #infoIcon></fa-icon>
                                    </div>
                                    <ng-template
                                      cdkConnectedOverlay
                                      [cdkConnectedOverlayOrigin]="overlayTrigger"
                                      [cdkConnectedOverlayOpen]="hoveredChapter == chapter"
                                    >
                                      <div
                                        class="text-xs bg-white rounded-[5px] p-[5px] shadow-full break-words w-full">
                                        <div [innerHTML]="getInfoText(chapter)"></div>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="!isThereAChapterWithoutK2(element, k1rule)">
                          <div *ngIf="k2Map.get(element.id)?.get(k1rule).length == 0">
                            <ng-container *ngIf="k1rule.result.length > 0">
                              Die nachfolgenden Kapitel müssen keine zusätzlichen Anforderungen erfüllen:
                            </ng-container>
                             <ng-container *ngIf="k1rule.result.length == 0">
                              Es wurde kein Kapitel gefunden, welches das Thema der Prüfregel behandelt.
                            </ng-container>

                          </div>
                          <div class="p-2">
                            <ng-container *ngFor="let chapter of  k1rule.result" class="p-2">
                              <div class="flex flex-row items-center gap-2"
                                   *ngIf="resultMap.get(element.id)?.get(k1rule)?.get(chapter)?.length == 0 ">
                                <div>
                                  {{ chapter.chapter_number!.replaceAll('_', '.') }} {{ chapter.chapter_title }}
                                </div>
                                <div class="flex flex-row justify-between items-start">
                                  <div class="text-lg pr-1 scale-75" #overlayTrigger
                                       (mouseenter)="hoveredChapter = chapter" (mouseleave)="hoveredChapter = null">
                                    <fa-icon [icon]="faCircleQuestion" class="text-primary text-2xl"
                                             #infoIcon></fa-icon>
                                  </div>
                                  <ng-template
                                    cdkConnectedOverlay
                                    [cdkConnectedOverlayOrigin]="overlayTrigger"
                                    [cdkConnectedOverlayOpen]="hoveredChapter == chapter"
                                  >
                                    <div class="text-xs bg-white rounded-[5px] p-[5px] shadow-full break-words w-full">
                                      <div [innerHTML]="getInfoText(chapter)"></div>
                                    </div>
                                  </ng-template>
                                </div>

                              </div>

                            </ng-container>
                          </div>

                        </ng-container>


                      </div>


                      <!--                        <div *ngFor="let chapter of  k1rule.result" class="flex flex-row w-full border-2">-->
                      <!--                          <div class="flex flex-col gap-2 w-full">-->
                      <!--                            {{chapter.chapter_number!.replaceAll('_', '.')}} {{chapter.chapter_title}}-->
                      <!--                            <div class="flex flex-col bg-green-200" *ngIf="resultMap.get(element.id).get(k1rule).get(chapter).length > 0">-->
                      <!--                              Zusätzlich wurden folgende Anforderungen in diesem Kapitel gefunden:-->
                      <!--                                <div class="flex flex-row" *ngFor="let k2rule of resultMap.get(element.id).get(k1rule).get(chapter)">-->
                      <!--                                  {{k2rule.name}}-->
                      <!--                                </div>-->
                      <!--                            </div>-->
                      <!--                            <div class="flex flex-col bg-red-200" *ngIf="k1rule.k2Rules.length > 0 && resultMap.get(element.id).get(k1rule).get(chapter).length != k1rule.k2Rules.length">-->
                      <!--                              Folgende Anforderungen wies das Kapitel nicht auf:-->
                      <!--                              <ng-container *ngFor="let k2rule of k2Map.get(element.id).get(k1rule)">-->
                      <!--                                <div  class="flex flex-row" *ngIf="noFullfilledK2s(k2rule, element, k1rule, chapter)">-->
                      <!--                                  {{k2rule.name}}-->
                      <!--                                </div>-->


                      <!--                              </ng-container>-->

                      <!--                            </div>-->
                      <!--                          </div>-->
                      <!--                        </div>-->


                    </div>
                    <ng-container *ngFor="let k1rule of element.k1Rules">
                      <div class="flex flex-col w-full shadow-full gap-2 rounded-md p-2"
                           *ngIf="k1rule.deviations.length > 0">
                        <div class="flex flex-row">
                          <p class="font-medium">Abweichungen</p> &nbsp;im Bezug auf dieses Thema wurden in den
                          nachfolgenden Kapiteln gefunden:
                        </div>
                        <div class="border-gray-300 border-2 rounded-md p-2">
                          <div *ngFor="let chapter of k1rule.deviations">
                            {{ chapter.chapter_number!.replaceAll('_', '.') }} {{ chapter.chapter_title }}
                          </div>
                        </div>

                      </div>
                    </ng-container>

                  </div>


                </div>


              </div>
            </mat-cell>
          </ng-container>

          <cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"
                          class="flex flex-row justify-start items-start w-full border-b-2 border-b-gray-400 bg-white px-2"></cdk-header-row>
          <cdk-row *cdkRowDef="let row; columns: displayedColumns;"
                   (click)="row.count != 0 ? (expandedElement = expandedElement === row ? null : row) : {}"
                   class="flex flex-row justify-start items-center w-full border-b-2 border-b-gray-300 hover:bg-gray-200 hover:cursor-pointer px-2 group"></cdk-row>
          <cdk-row *cdkRowDef="let row; columns: ['expandedDetail']"></cdk-row>


        </cdk-table>


      </div>

    </div>


  </div>

</div>



