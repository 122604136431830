<div class="flex flex-col z-40 h-full w-full bg-white shadow-full rounded-[10px] p-2">
  <!--  <div class="flex flex-row">-->
  <div class="flex flex-col">
    <div class="text-xl font-medium pt-6 text-center">Organisation verlassen?</div>
    <div class="px-4 text-center pt-4">Beim <span class="underline">verlassen</span>
      verlieren Sie mit sofortiger Wirkung den Zugriff auf sämtliche Organisationsprofile und Mitglieder.
    </div>

    <div class="px-4 text-center pt-4">Um der Organisation erneut beitreten zu können benötigen Sie die Einladung eines
      Administrators
    </div>

    <div class="flex flex-row pt-4 justify-between">
      <mat-dialog-actions class="flex justify-between w-full">
        <button mat-raised-button class="bg-white w-28" mat-dialog-close>Abbrechen</button>
        <div class="flex-grow"></div>
        <button mat-raised-button color="primary" class="text-white w-28" [mat-dialog-close]="true">
          Verlassen
        </button>
      </mat-dialog-actions>
    </div>
  </div>


  <!--  </div>-->
</div>
