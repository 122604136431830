import { Pipe, PipeTransform } from '@angular/core';
import TitleEnum = User.TitleEnum;
import {User} from "../api";

@Pipe({
  name: 'title'
})
export class TitlePipe implements PipeTransform {

  transform(value: TitleEnum): String {
    switch (value) {
      case TitleEnum.DR: return "Dr.";
      case TitleEnum.PROF: return "Prof.";
      case TitleEnum.PROFDR: return "Prof. Dr.";
      case TitleEnum.NONE: return "(kein Titel)";
      default: return "";
    }

  }

}
