import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import ApexCharts from 'apexcharts';

@Component({
  selector: 'app-dashboard-ring-chart',
  templateUrl: './dashboard-ring-chart.component.html',
  styleUrls: ['./dashboard-ring-chart.component.scss']
})
export class DashboardRingChartComponent implements AfterViewInit {

  @ViewChild('radialChart') radialChart!: ElementRef;

  @Input() succeeded: number = 0;
  @Input() warning: number = 0;
  @Input() failed: number = 0;
  @Input() url: string = '';

  get total(): number {
    return this.succeeded + this.warning + this.failed;
  }

  get succeededPercentage(): number {
    return this.total === 0 ? 0 : this.succeeded / this.total * 100;
  }

  get warningPercentage(): number {
    return this.total === 0 ? 0 : this.warning / this.total * 100;
  }

  get failedPercentage(): number {
    return this.total === 0 ? 0 : this.failed / this.total * 100;
  }

  getChartOptions = () => {
    return {
      series: [this.succeededPercentage, this.warningPercentage, this.failedPercentage],
      colors: ["rgba(0,153,153,0.8)", "rgba(250,202,21,0.8)", "rgba(238,59,59,0.8)"],
      states: {
        normal: {
          filter: {
            type: 'lighten',
            value: 0.15,
          }
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          }
        },
      },
      chart: {
        height: "45%",
        width: "100%",
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          track: {
            background: '#E5E7EB',
          },
          dataLabels: {
            show: false,
          },
          hollow: {
            margin: 0,
            size: "32%",
          }
        },
      },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: -23,
          bottom: -20,
        },
      },
      labels: ["Erfüllt", "Warnung", "Fehler"],
      legend: {
        show: false,
        position: "bottom",
        fontFamily: "Inter, sans-serif",
      },
      tooltip: {
        enabled: false,
        style: {
          fontSize: '12px',
          fontFamily: 'Inter, sans-serif',
          background: '#fff',
          color: '#000'
        },
        x: {
          show: false,
        },
      },
      yaxis: {
        show: false,
        labels: {
          formatter: (value: any) => {
            return value + '%';
          }
        }
      }
    }
  }

  openReport() {
    if(this.url!=''){
      window.open(this.url, '_blank');
    }
  }

  ngAfterViewInit(): void {

    if (this.radialChart && typeof ApexCharts !== 'undefined') {
      const chart = new ApexCharts(this.radialChart.nativeElement, this.getChartOptions());
      chart.render();
    }
  }

}
