<div class="flex flex-col w-full h-full justify-start items-center overflow-hidden">
  <div class="flex flex-row w-full px-6 py-3.5 text-xl text-black items-center font-medium shadow-full-new">
    IFC Viewer
  </div>



  <div class="flex flex-col w-full h-full px-6 py-12">
    <div class="w-full border p-6 border-gray-200 rounded-lg">
      <div class="flex flex-col w-full gap-2">
        <span class="text-xl text-gray-900 font-medium">Datei im IFC-Viewer öffnen</span>
        <span class="text-base text-gray-500">Wählen Sie die IFC-Datei aus, die Sie untersuchen möchten. Im nächsten Schritt wird der Viewer geöffnet.</span>
      </div>
    </div>

    <div class="flex flex-row gap-12 h-full w-full pt-16 pb-12">
                <bic-table [class]="preselectedFile ? 'w-[66%]' : 'w-full'" [data]="ifcFiles" [columnNames]="columns" [columns]="column" [columnTypes]="colTypes"
                           [backbutton]="false" [backButtonText]="'Zurück'" [paginator]="false"
                           [columnWidths]="['55%', '20%', '25%']" [searchbar]="true" [columnAlignments]="['start', 'start', 'start']" (rowClick)="preselectedFile = $event"></bic-table>




      <div class="flex flex-col w-[33%] h-full justify-between rounded-lg border border-gray-200" *ngIf="preselectedFile">
<!--        <img src="assets/IFC-Placeholder.jpg" alt="IFC Viewer" class="w-full h-full object-contain">-->
        <div class="flex flex-row h-full justify-center items-center rounded-t-lg w-full bg-gray-700">
          <fa-icon [icon]="faIfc" class="text-white text-[250px]"></fa-icon>
        </div>
        <div class="flex flex-row w-full p-4">
          <div class="flex flex-col gap-3 h-full w-full">
            <div class="flex flex-row w-full h-full">
              <div class="flex flex-col h-full w-full">
                <span class="text-gray-900 text-xl font-bold pb-2">{{ preselectedFile.name ?? '' }}</span>
                <span class="text-gray-500 text-base">Projekt: {{ stateConfigService.selectedProject?.name ?? '' }}</span>
                <span class="text-gray-500 text-base">Datum: {{ preselectedFile.lastModified | date: 'dd.MM.yyyy HH:mm' ?? '' }}</span>
                <span class="text-gray-500 text-base">Größe: {{ calculateSizeInMB(preselectedFile.size) | number: '.0-2' }} {{'MB'}}</span>
              </div>
            </div>

            <div class="flex flex-row justify-end">
              <bic-button [text]="'Datei öffnen'" [iconRight]="faArrowRight" (onClick)="openIFCViewer(preselectedFile)"></bic-button>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>

</div>


