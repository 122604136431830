<mat-dialog-content class="bg-white">
  <div class="flex flex-col">
    <div class="flex flex-row justify-between items-center pb-5 text-xl" style="color: red">
      Sind Sie sicher, dass Sie diese Aufgabe löschen wollen?
    </div>
    <div class="flex flex-row justify-center items-center pb-3">
      <!--mat-chip-listbox class="justify-between items-center">
        <ng-container *ngIf="data.task">
          <mat-chip-option>Aufgabe: <i>{{data.task.markup.topic.title}}</i></mat-chip-option>
        </ng-container>

      </mat-chip-listbox-->
      Aufgabe: <i>{{data.task?.markup?.topic?.title}}</i>
    </div>
    <div class="flex flex-row justify-center items-center">
      <u>Hinweis:</u>
    </div>
    <div class="flex flex-row justify-center items-center pb-8">
      Bei Bestätigung wird die Aufgabe unwiderruflich gelöscht
    </div>

    <div class="flex flex-row justify-between items-center">
      <button  mat-dialog-close mat-raised-button class="!bg-white">
        <span>Abbrechen</span>
      </button>
      <button (holdTime)="holdHandler($event)" appHoldable color="primary" id="deleteButton" mat-raised-button
              matTooltip="Zum Löschen gedrückt halten">
        <span>Löschen</span>
      </button>

    </div>

  </div>

</mat-dialog-content>
