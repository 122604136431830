

<div *ngIf="!toolbar.isEmpty()" class="flex flex-row justify-end items-center p-2">
  <mat-form-field *ngIf="toolbar.config.search" id="searchbar" appearance="outline">
    <mat-label>Suche</mat-label>
    <input #input (keyup)="toolbar.config.search.search(($event.target)?.value)" matInput>
  </mat-form-field>

  <div *ngIf="toolbar.config.delete">
    <button mat-mini-fab type="button" (click)="toolbar.config.delete.action()" [disabled]="toolbar.config.delete.disabled()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div *ngIf="toolbar.config.add">
    <button mat-mini-fab type="button" [routerLink]="toolbar.config.add.routerLink" (click)="toolbar.config.add.action()" [disabled]="toolbar.config.add.disabled()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <div *ngIf="toolbar.config.edit">
    <button mat-mini-fab type="button" (click)="toolbar.config.edit.action()" [routerLink]="toolbar.config.edit.routerLink" [disabled]="toolbar.config.edit.disabled()">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <div *ngIf="toolbar.config.save">
    <button mat-mini-fab type="button" (click)="toolbar.config.save.action()" [disabled]="toolbar.config.save.disabled()">
      <mat-icon>save</mat-icon>
    </button>
  </div>
</div>
