import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import {CheckControllerService, Project, ProjectControllerService, QualityCheck, QualityControllerService} from "../../api";
import {SidebarService} from "../../services/sidebar/sidebar.service";
import {StateConfigService} from "../../services/state/state-config.service";
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HoaiCheckResolver {

  constructor(private stateConfigService: StateConfigService,
              private checkControllerService: CheckControllerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QualityCheck> {
    const id = route.paramMap.get('checkId')

    return this.checkControllerService.getCheck3(this.stateConfigService.getProjectId(), id!)

  }
}
