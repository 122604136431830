import {Component, Input, OnInit} from '@angular/core';
import {Project, ServiceControllerService, SimplePlace} from "../../../api";
import {Observable, Subscription} from "rxjs";
import {FormGroup} from "@angular/forms";
import StateEnum = Project.StateEnum;
import CountryEnum = Project.CountryEnum;
import {KeyValue} from "@angular/common";
import {ProjectStateReversePipe} from "../../../pipes/project-state.pipe";

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit {

  @Input() formGroup!: FormGroup;
  @Input() formControlNameStreet = 'street';
  @Input() formControlNameHouseNumber = 'houseNumber';
  @Input() formControlNameCity = 'city';
  @Input() formControlNameZipCode = 'zipCode';
  @Input() formControlNameCountry = 'country';
  @Input() formControlNameState = 'state';

  @Input() zipCodeDisabled = false;
  @Input() cityDisabled = false;
  @Input() countryDisabled = false;
  @Input() stateDisabled = true;
  @Input() displayState = true;
  @Input() displayPlotNumber = false;
  @Input() formControlNamePlotNumber = 'plotNumber'

  locationGuesses$!: Observable<SimplePlace[]>;

  states: typeof StateEnum = StateEnum;
  countries: typeof CountryEnum = CountryEnum;


  constructor(public serviceControllerService: ServiceControllerService) {
  }


  ngOnInit(): void {
    this.onCountryChange(this.formGroup.get('country')?.value);
    this.formGroup.get('country')?.valueChanges.subscribe(country => {
      this.onCountryChange(country);
    });
    if(this.stateDisabled) {
      this.formGroup.get('state')?.disable();
    }else{
      this.formGroup.get('state')?.enable();
    }
  }

  onCountryChange(country: string) {
    const stateControl = this.formGroup.get('state');
    if (country === 'DEU') {
      stateControl?.enable();
    } else {
      stateControl?.disable();
      stateControl?.reset();
    }
  }

  updateLocation(location: SimplePlace) {
    let projectStateReversePipe = new ProjectStateReversePipe()
    console.log(location)

    this.formGroup.get(this.formControlNameStreet)?.setValue(location.street);
    this.formGroup.get(this.formControlNameHouseNumber)?.setValue(location.addressNumber);
    this.formGroup.get(this.formControlNameCity)?.setValue(location.municipality);
    this.formGroup.get(this.formControlNameZipCode)?.setValue(location.postalCode);
    this.formGroup.get(this.formControlNameCountry)?.setValue(location.country);
    if (location.region != null && location.country == "DEU") {
      this.formGroup.get(this.formControlNameState)?.enable();
      this.formGroup.get(this.formControlNameState)?.setValue(projectStateReversePipe.transform(location.region));
    } else {
      this.formGroup.get(this.formControlNameState)?.setValue(undefined);
      this.formGroup.get(this.formControlNameState)?.disable();
    }
  }

  valueAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return a.value.localeCompare(b.value);
  }

  test(value: string){
    console.log(value)
    this.serviceControllerService.getLocationSuggestions(value).subscribe(value => console.log(value))
  }

}
