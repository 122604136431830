import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faBars, faDownload} from "@fortawesome/free-solid-svg-icons";
import {CircleState} from "../../../util/design/circle-state/circle-state.component";

@Component({
  selector: 'app-HoaiCheckReport',
  templateUrl: './hoai-check-report.component.html',
  styleUrls: ['./hoai-check-report.component.scss']
})
export class HoaiCheckReportComponent implements OnInit {

  @Input() compact: boolean = false
  @Input() title: string = ""
  @Input() date: Date = new Date()
  @Input() total: number = 0
  @Input() completed: number = 0
  @Input() warning: number = 0
  @Input() error: number = 0

  @Output() download: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();

  hoveredOn: boolean = true
  selected: string = "all"
  completedSelected: boolean = false
  warningSelected: boolean = false
  errorSelected: boolean = false

  constructor() {
  }

  ngOnInit(): void {
  }

  completedPercentage(): number {
    return Math.round(this.completed / this.total * 100)
  }

  warningPercentage(): number {
    return Math.round(this.warning / this.total * 100)
  }

  errorPercentage(): number {
    return Math.round(this.error / this.total * 100)
  }

  toggleSelectionCompleted() {
    this.completedSelected = !this.completedSelected;
    this.errorSelected = false;
    this.warningSelected = false;
  }
  toggleSelectionWarning() {
    this.warningSelected = !this.warningSelected;
    this.errorSelected = false;
    this.completedSelected = false;
  }
  toggleSelectionError() {
    this.errorSelected = !this.errorSelected;
    this.warningSelected = false;
    this.completedSelected = false;
  }

  setCompleted(){
    this.toggleSelectionCompleted()
    if (this.selected == "completed"){
        this.selected = "all"
      } else {
        this.selected = "completed"
      }
      this.selectionChanged.emit(this.selected)
  }
  setWarning(){
    this.toggleSelectionWarning()
    if (this.selected == "warning"){
      this.selected = "all"
    } else {
      this.selected = "warning"
    }
    this.selectionChanged.emit(this.selected)
  }
  setError(){
    this.toggleSelectionError()
    if (this.selected == "error"){
      this.selected = "all"
    } else {
      this.selected = "error"
    }
    this.selectionChanged.emit(this.selected)
  }
  protected readonly faBars = faBars;
  protected readonly CircleState = CircleState;
  protected readonly faDownload = faDownload;
}
