<div class="flex flex-row w-full px-6 py-3.5 text-xl text-black items-center font-medium shadow-full-new">
  <div>Berichte</div>
  <div class=" rounded-lg flex flex-row justify-end items-center gap-2 h-10 ml-auto">
    <div class=" rounded flex flex-row justify-end items-center ml-auto">
      <bic-button [color]="'white'" [textColor]="'gray-900'" [border]="true" [disabled]="true" [routerLink]="" [text]="'Herunterladen'" [iconLeft]="faArrowDownToBracket" class="mr-2"></bic-button>
    </div>
  </div>
</div>

<div class="w-full h-full p-8">
  <div class="rounded-md shadow-full overflow-hidden ml-1 h-full">
    <div class="overflow-y-scroll h-full">
          <bic-table class="w-full"
               [data]="data" [columnNames]="displayedColumnsNames" [columns]="displayedColumns"
               [columnTypes]="columnsTypes" [columnWidths]="columnsWidths"
               [paginator]="false" [columnAlignments]="columnsAlignment"
                     (rowClick)="openCheck($event.check)"
               [backbutton]="false"
               [searchbar]="false"
               [textfield]="false"
               [createTicket]="false">
    </bic-table>
  </div>

  <mat-menu #rightMenu="matMenu">
    <ng-template matMenuContent let-node="node">
      <button disabled mat-menu-item>
        Herunterladen
      </button>
    </ng-template>
  </mat-menu>

</div>
