<div class="flex flex-col h-full w-full justify-start items-start gap-4 font-medium">
  <app-swm-stepper class="w-full"></app-swm-stepper>

  <div class="flex flex-col h-full w-full justify-start items-start gap-4 px-4 pb-4 overflow-hidden">
    <div class="flex flex-row w-full">
      <div class="flex flex-col px-2 w-1/2">
        <div class="flex flex-row">
          <p class="text-black">Dateiauswahl:</p>
        </div>
      </div>

      <div class="flex flex-col px-2 w-1/2">
        <div class="flex flex-row">
          <p class="text-black">Ausgewählte Dateien:</p>
        </div>
      </div>

    </div>

    <div class="flex flex-row overflow-hidden h-full w-full gap-4 p-2">


      <div class="flex flex-col w-1/2">
        <!--      <div class="flex flex-row pb-2">-->
        <!--        <p class="text-black">Dateiauswahl:</p>-->
        <!--      </div>-->
        <ng-container *ngIf="selectedFolder === ''">
          <div class="flex flex-col justify-start items-start rounded-lg h-full shadow-full w-full p-4">
            <button (click)="onFolderClick('BSK')"
                    class="flex flex-row justify-start items-center rounded-lg shadow-full w-full p-4 mb-4 hover:bg-gray-200">
              <fa-icon [icon]="faFolderOpen" class="text-primary text-2xl mr-4"></fa-icon>
              Brandschutzkonzept
              <fa-icon [icon]="faChevronRight" class="text-primary text-2xl ml-auto"></fa-icon>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedFolder !== ''">
          <div class="rounded-md shadow-full w-full h-full p-0.5">
            <div class="overflow-y-scroll w-full h-full">
              <div class="flex flex-row w-full ">
                <button (click)="onFolderClick('')" class=" p-2 hover:bg-gray-200 rounded-md">
                  <fa-icon [icon]="faChevronLeft" class="text-primary"></fa-icon>
                  <span class="text-primary"> Zurück</span>
                </button>
              </div>
              <app-files-table [projectFiles]="shownFiles"
                               [runningDocumentClassification]="runningDocumentClassification"
                               [selectedFiles]="shownSelectedFiles"
                               [isQualityView]="true"
                               class="flex flex-row overflow-hidden">
              </app-files-table>
            </div>
          </div>
        </ng-container>


      </div>

      <div class="flex flex-col w-1/2 gap-4">
        <!--      <div class="flex flex-row pb-2">-->
        <!--        <p class="text-black">Ausgewählte Dateien:</p>-->
        <!--      </div>-->
        <div class="flex flex-row h-full w-full">
          <div class="flex flex-col gap-4 h-full w-full">
            <div class="flex flex-col justify-start items-start rounded-lg shadow-full h-full w-full p-4">
              <div class="flex flex-row w-full text-black mb-2">Brandschutzkonzept</div>
              <div class="overflow-y-scroll w-full items-stretch">
                <div *ngFor="let file of getSelectedBSKFiles()"
                     class="flex flex-row justify-between items-center w-full pr-2">
                  <div class="flex flex-row justify-between w-[75%]">
                    <span>{{ file.name }}</span>
                    <span>{{ file.size }}</span>
                  </div>
                  <div class="flex flex-row justify-end items-center w-[25%]">
                    <button mat-icon-button (click)="removeSelectedBSKFile(file)">
                      <fa-icon [icon]="faCircleXmark"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="getSelectedBSKFiles().length === 0"
                   class="flex flex-row justify-center items-center w-full h-full text-gray">
                Keine Datei ausgewählt
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="flex flex-row w-full justify-center items-center">
      <div>
<!--        [matTooltip]="!canCheckQuality ? 'Kein Kontingent verfügbar' : ''">-->
<!--        <button (click)="navigateToChoosingRules()" color="primary" mat-raised-button-->
<!--                [disabled]="!permissionService.hasProjectPrivilege('PROJECT_QUALITY_CHECK') || check || !canCheckQuality || (selectedIFCFiles.isEmpty() && selectedLVFiles.isEmpty() && selectedRBFiles.isEmpty())">-->
        <button (click)="navigateToChoosingRules()"  color="primary" mat-raised-button>
          Prüfungsauswahl
          <fa-icon [icon]="faChevronRight" class="text-white"></fa-icon>
        </button>
      </div>
    </div>
  </div>

</div>






