import {Component, Inject, OnInit} from '@angular/core';
import {Project, ProjectControllerService} from "../../../api";
import {Router} from "@angular/router";
import {forkJoin, Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


@Component({
  selector: 'app-deleteDialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { projects?: [Project] },
              private router: Router,
              private dialogRef: MatDialogRef<DeleteDialogComponent>,
              private projectControllerService: ProjectControllerService
  ) { }

  ngOnInit(): void {
  }

  holdHandler(e: any) {
    if (e === 3000) {

      let observables: Observable<any>[] = [];

      if(this.data.projects) {
        this.data.projects.forEach(project => observables.push(this.projectControllerService.deleteProject(project.id!)))
      }

      forkJoin(observables).subscribe(next => {
        this.dialogRef.close();
      });
    }
  }

}
