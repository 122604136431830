/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ParameterEnums } from './parameterEnums';

export interface Parameter { 
    id?: number;
    name?: string;
    value?: string;
    type?: Parameter.TypeEnum;
    enums?: Array<ParameterEnums>;
}
export namespace Parameter {
    export type TypeEnum = 'ENUM' | 'INT' | 'BOOL' | 'FLOAT';
    export const TypeEnum = {
        ENUM: 'ENUM' as TypeEnum,
        INT: 'INT' as TypeEnum,
        BOOL: 'BOOL' as TypeEnum,
        FLOAT: 'FLOAT' as TypeEnum
    };
}