import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-loi-check-stepper',
  templateUrl: './loi-check-stepper.component.html',
  styleUrls: ['./loi-check-stepper.component.scss']
})
export class LoiCheckStepperComponent implements OnInit {
  selectedStep = 0;

  completedSteps: number[] = [0];

  loiCheck!: any

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.loiCheck = this.activatedRoute.snapshot.parent!.data.qualityCheck;

    if(this.loiCheck) {
      this.completedSteps.push(0);

      if(Object.values(this.loiCheck.extractionIdsOfProjectFiles!)?.length > 0) {
        this.completedSteps.push(1);
      }

      if(this.loiCheck.completed) {
        this.selectedStep = 2;
        this.completedSteps = [0, 1, 2];
      }
    }


    const url = this.activatedRoute.snapshot.pathFromRoot
      .map(v => v.url)
      .filter(v => v.length > 0)
      .slice(2)
      .flat()

    if (url.length == 1 || url.at(-1)?.path == 'files') {
      this.completedSteps = []
      this.selectedStep = 0;
    }

    if (url.at(-1)?.path == 'rules') {
      this.completedSteps = [0];
      this.selectedStep = 1;
    }

    if (url.at(-3)?.path == 'reports') {
      this.completedSteps = [0, 1, 2];
      this.selectedStep = 2;
    }
  }

  changeStep(index: number): void {
    const projectId = this.activatedRoute.snapshot.parent!.parent!.params.id;
    const qualityCheckId = this.loiCheck.id;

    const fileId = this.activatedRoute.snapshot.params.fileId;
    const ruleId = this.activatedRoute.snapshot.params.ruleId;

  }
}
