import { Injectable } from '@angular/core';
import {StateConfigService} from "../../services/state/state-config.service";
import {SwmControllerService} from "../../api";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SwmCheckResolverService {

  constructor(private stateConfigService: StateConfigService, private swmControllerService: SwmControllerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.paramMap.get('checkId')

    return this.swmControllerService.getCheck(id!)
  }

}
