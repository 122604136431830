<div class="shadow-full rounded-md flex flex-row justify-start items-center p-4 gap-20 w-full">
  <div class="flex flex-col justify-start items-start gap-4">
    <button class="flex flex-row justify-start items-center gap-2 p-2 -ml-2 rounded-md" disabled>
      <fa-icon [icon]="faBars" class="text-black"></fa-icon>
      Gebäude und Innenräume
    </button>

    <div class="text-primary text-2xl font-medium">{{title}}</div>

    <div *ngIf="!compact" class="flex flex-row justify-start items-center gap-10">
<!--      <div class="flex flex-row justify-start items-center gap-4">-->

<!--        <div class="">{{date | date: 'dd.MM.y - HH:mm'}}</div>-->
<!--        <div class="text-gray-400 text-xs">Zeitpunkt der<br>Prüfung</div>-->
<!--      </div>-->
      <div class="flex flex-row justify-start items-center gap-4">

        <div class="">{{total}}</div>
        <div class="text-gray-400 text-xs">geprüfte<br>Leistungen</div>
      </div>
    </div>
    <div>
      <button mat-raised-button class="m-0" (click)="download.emit()" color="basic" [matTooltip]="'PDF-Bericht herunterladen'">
        <mat-icon class="scale-150" svgIcon="download"></mat-icon>
        Herunterladen
      </button>
    </div>


  </div>

  <div class="flex flex-row justify-between items-center flex-grow gap-4 px-20">
    <div (click)="setCompleted()">
      <div [ngClass]="{'bg-primary-bg rounded-lg shadow-full': completedSelected}"
           class="relative w-full flex flex-row justify-start items-center gap-4 font-medium p-2 px-4 hover:rounded-lg hover:shadow-full hover:cursor-pointer">
        <app-circle-state [compact]="compact" class="w-32" [value]="completedPercentage()" [state]="CircleState.GREEN">
          <div *ngIf="!compact" class="flex flex-col justify-start items-center">
            <div class="text-primary font-medium mt-2">
              {{completedPercentage()}} %
            </div>
            <div class="text-gray-400 text-xs">
              Bestanden: {{completed}}
            </div>
          </div>
        </app-circle-state>
      </div>
    </div>
    <div (click)="setWarning()">
      <div [ngClass]="{'bg-warning-bg rounded-lg shadow-full': warningSelected}"
           class="relative w-full flex flex-row justify-start items-center gap-4 font-medium p-2 px-4 hover:rounded-lg hover:shadow-full hover:cursor-pointer">
        <app-circle-state [compact]="compact" class="w-32" [value]="warningPercentage()" [state]="CircleState.YELLOW">
          <div *ngIf="!compact" class="flex flex-col justify-start items-center">
            <div class="text-warning font-medium mt-2">
              {{warningPercentage()}} %
            </div>
            <div class="text-gray-400 text-xs">
              Warnung: {{warning}}
            </div>
          </div>
        </app-circle-state>
      </div>
    </div>
    <div (click)="setError()">
      <div [ngClass]="{'bg-error-bg rounded-lg shadow-full': errorSelected}"
           class="relative w-full flex flex-row justify-start items-center gap-4 font-medium p-2 px-4 hover:rounded-lg hover:shadow-full hover:cursor-pointer">
        <app-circle-state [compact]="compact" class="w-32" [value]="errorPercentage()" [state]="CircleState.RED">
          <div *ngIf="!compact" class="flex flex-col justify-start items-center">
            <div class="text-error font-medium mt-2">
              {{errorPercentage()}} %
            </div>
            <div class="text-gray-400 text-xs">
              Fehler: {{error}}
            </div>
          </div>
        </app-circle-state>
      </div>
    </div>
  </div>
</div>
