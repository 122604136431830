<div *ngIf="dataSource">
  <cdk-table #orgTbSort="matSort" [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[40%] h-full pl-7 py-4 font-medium" mat-sort-header> Name
      </cdk-header-cell>
      <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[40%] pl-7 py-1 break-words"> {{ element.name }}</cdk-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[50%] h-full py-4 font-medium" mat-sort-header> Berechtigung
      </cdk-header-cell>
      <cdk-cell *cdkCellDef="let element"
                class="shrink-0 w-[50%] py-1 break-words">{{ user.roleInOrganisations[element.id].name | roleAndPrivileges }}
      </cdk-cell>
    </ng-container>

    <!--<ng-container matColumnDef="actions">
    <th *matHeaderCellDef  mat-header-cell>Aktionen</th>
    <td *matCellDef="let element" mat-cell>
      <button mat-button matTooltip="Öffnen" (click)="navigateToOrganisationSettings(element)">
        Bearbeiten
      </button>
      <button *ngIf="permissionService.hasOrganisationPrivilege(PrivilegesEnum.ORGANISATIONSETTINGS, element.id) && existsOtherAdminMap.get(element) || permissionService.hasOrganisationPrivilege(PrivilegesEnum.ORGANISATIONSETTINGS, element.id)" mat-button color="warn" matTooltip="Verlassen" (click)="openLeaveOrganisation(element)">
        Verlassen
      </button>
      <button *ngIf="permissionService.hasOrganisationPrivilege(PrivilegesEnum.ORGANISATIONSETTINGS, element.id) && existsOtherAdminMap.get(element)" mat-button color="warn" matTooltip="Verlassen" (click)="openDeleteOrganisation(element)">
        Löschen
      </button>
      <button *ngIf="permissionService.hasOrganisationPrivilege(PrivilegesEnum.ORGANISATIONSETTINGS, element.id) && !existsOtherAdminMap.get(element)" mat-button color="warn" matTooltip="Löschen und Verlassen" (click)="openDeleteAndLeaveOrganisation(element)">
        Löschen und Verlassen
      </button>
    </td>
  </ng-container>-->

    <!--<ng-container matColumnDef="actions">
      <cdk-header-cell *cdkHeaderCellDef class="flex flex-row grow w-[10%] h-full justify-end py-4 font-medium pr-4"></cdk-header-cell>
      <cdk-cell *cdkCellDef="let element" class="flex flex-row grow w-[10%] justify-end items-center py-1 pr-4">
        <button matTooltip="Bearbeiten">
          <fa-icon [icon]="faEllipsisVertical"></fa-icon>
        </button>
      </cdk-cell>
    </ng-container>-->

    <cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"
                    class="flex flex-row justify-left items-center w-full border-b-2 border-b-gray-400 bg-white rounded-t-md overflow-hidden"></cdk-header-row>
    <cdk-row *cdkRowDef="let row; columns: displayedColumns;"
             class="flex flex-row justify-left items-center w-full py-1 border-t-2 border-b-gray-400"></cdk-row>

  </cdk-table>
  <!--<div class="flex flex-row justify-end mb-4 mt-2">
    <button (click)="newOrgaPressed()" color="basic" mat-raised-button matTooltip="Neue Organisation erstellen">
      <mat-icon>add</mat-icon>
      Hinzufügen
    </button>
  </div>-->


  <app-pop-up *ngIf="leaveOrganisationBoolean" [header]="'Unternehmen verlassen?'" [message]="'Durch diesen Vorgang verlassen Sie das Unternehmen ' + selectedOrganisation.name + '.' +
   '<br> <br> Sie verlieren somit den Zugang zu allen Projekten ihres Unternehmens!'" [approveButtonName]="'Bestätigen'" (approvedPressed)="leaveOrganisation(selectedOrganisation)" (declinedPressed)="leaveOrganisationBoolean=false"></app-pop-up>

  <app-pop-up *ngIf="deleteOrganisationBoolean" [header]="'Unternehmen löschen?'" [holdable]="true" [message]="'Durch diesen Vorgang wird das Unternehmen ' + selectedOrganisation.name +
   ' unwiderruflich gelöscht! <br> <br> Alle Personen werden aus dem Unternehmen entfernt und dieses anschließend gelöscht!'" [approveButtonName]="'Bestätigen'" (approvedPressed)="deleteOrganisation(selectedOrganisation)" (declinedPressed)="deleteOrganisationBoolean = false"></app-pop-up>

  <app-pop-up *ngIf="deleteAndLeaveOrganisationBoolean" [header]="'Unternehmen lösche und verlassen?'" [holdable]="true" [message]="'Durch diesen Vorgang wird das Unternehmen ' + selectedOrganisation.name +
   ' unwiderruflich gelöscht. <br> <br> Da Sie die administrative Rolle des Unternehmens besitzen, wird dieses beim Verlassen gelöscht! <br> Fall Sie lediglich das Unternehmen verlassen wollen, ernennen Sie zuvor einen zusätzlichen Administrator, der Ihre Position übernimmt! '" [approveButtonName]="'Bestätigen'" (approvedPressed)="deleteOrganisation(selectedOrganisation)" (declinedPressed)="deleteAndLeaveOrganisationBoolean = false"></app-pop-up>


  <!--  <mat-paginator [length]="dataSource.data.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>-->


</div>
