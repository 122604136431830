/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Check } from './check';
import { ProjectFile } from './projectFile';
import { QualityCheckProjectFilesKey } from './qualityCheckProjectFilesKey';

export interface QualityCheckProjectFiles { 
    id?: QualityCheckProjectFilesKey;
    projectFile?: ProjectFile;
    fileType?: QualityCheckProjectFiles.FileTypeEnum;
    extractionId?: string;
    extractionDate?: Date;
    checkId?: string;
    completed?: boolean;
    check?: Check;
}
export namespace QualityCheckProjectFiles {
    export type FileTypeEnum = 'IFC' | 'LV' | 'RB';
    export const FileTypeEnum = {
        IFC: 'IFC' as FileTypeEnum,
        LV: 'LV' as FileTypeEnum,
        RB: 'RB' as FileTypeEnum
    };
}