import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CloudNumberComponent} from "./cloud-number/cloud-number.component";
import {StepperComponent} from "./stepper/stepper.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CdkStepperModule} from "@angular/cdk/stepper";
import {ToolbarComponent} from './toolbar/toolbar.component';
import {RouterModule} from "@angular/router";
import {AvatarComponent} from './avatar/avatar.component';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import {UnsavedChangesDialogComponent} from './unsavedChangesDialog/unsaved-changes-dialog.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {SidebarItemComponent} from './sidebar/sidebar-item/sidebar-item.component';
import {NavbarComponent} from './navbar/navbar.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BicCardComponent} from './bic-card/bic-card.component';
import {MatStepperModule} from "@angular/material/stepper";
import {CircleStateComponent} from './circle-state/circle-state.component';
import {BicTagComponent} from './bic-tag/bic-tag.component';
import {MaterialModule} from "../../../material.module";
import {BicRowComponent} from "./bic-row/bic-row.component";
import { DashboardStepperComponent } from './stepper/dashboard-stepper/dashboard-stepper.component';
import {BicUiComponentsModule} from "@buildinginformationcloud/bic-ui-components";

@NgModule({
  declarations: [
    CloudNumberComponent,
    StepperComponent,
    ToolbarComponent,
    AvatarComponent,
    UnsavedChangesDialogComponent,
    SidebarComponent,
    SidebarItemComponent,
    NavbarComponent,
    BicCardComponent,
    BicTagComponent,
    BicRowComponent,
    DashboardStepperComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        MatStepperModule,
        CdkStepperModule,
        NgxChartsModule,
        RouterModule,
        FontAwesomeModule,
        CircleStateComponent,
        BicUiComponentsModule
    ],

  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    CdkStepperModule,
    CloudNumberComponent,
    StepperComponent,
    ToolbarComponent,
    AvatarComponent,
    SidebarComponent,
    SidebarItemComponent,
    NavbarComponent,
    BicCardComponent,
    BicRowComponent,
    BicTagComponent,
    DashboardStepperComponent,
  ]

})
export class DesignModule {
}
