/**
 * BIC Quality API
 * Quality Services API - Use this API to access the Quality Services of the Building Information Cloud (BIC).
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocType } from './docType';
import { Parameter } from './parameter';
import { TableColumn } from './tableColumn';

export interface Rule { 
    id: string;
    codeId: string;
    name: string;
    active: boolean;
    hint?: string;
    required_model_information?: string;
    parts?: string;
    docTypesText?: string;
    parametersText?: string;
    docTypes?: Array<DocType>;
    parameters?: Array<Parameter>;
    tableColumns?: Array<TableColumn>;
}