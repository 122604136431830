/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { User } from './user';

export interface Notification { 
    id?: number;
    created?: Date;
    deleted?: boolean;
    hadRead?: boolean;
    title?: string;
    body?: string;
    icon?: string;
    user?: User;
}