<div class="flex flex-row relative group pl-4 pr-3.5 ">
<!--Whole line-->
  <div *ngIf="!collapsed" (mouseenter)="hovering=true" (mouseleave)="hovering=false"
       [ngClass]="{
       'hover:bg-[rgba(255,255,255,0.35)] hover:cursor-pointer hover:rounded-lg': !disabled,
       'cursor-not-allowed': disabled,
       }"
       class="relative group flex flex-row justify-between items-center pl-2 py-1 select-none w-full"
       [class]="selectedElement == selected ? 'bg-[rgba(217,224,226,0.45)] rounded-lg' : ''">

    <div class="flex flex-row justify-start items-center gap-2 h-[32px]">
<!--  Icon-->
      <div class="flex flex-row w-[32px] justify-center items-center">
        <div class="flex justify-center items-center text-center h-8 w-8"
          [ngClass]="{'rounded-full bg-[rgba(255,255,255,0.35)]': !hovering && selectedElement != selected, 'pl-[2px]': selected == 'files', 'pl-1': selected == 'hoai', 'pb-[1px]': selected == 'tasks'}">
          <fa-icon class="w-8 text-white"  [icon]="icon" [ngClass]="{
            'text-svg-18': selected == 'dashboard',
            'text-base': selected == 'files',
            'icon-size-tasks': selected == 'tasks',
            'text-svg-20': selected == 'reports',
            'text-[16px]': selected == 'hoai',
            'text-[18px]': selected == 'bim-basis-check',
            'text-lg': selected == 'loi-check',
            'text-[20px]': selected == 'quality',
            'icon-size-application': selected == 'application',
            'icon-size-ifc-viewer': selected == 'ifc-viewer',
            'text-[16px]': selected == 'chat',
            }">
          </fa-icon>
        </div>
      </div>

      <div *ngIf="opened && !collapsed" [ngClass]="{'text-gray-400': disabled}"
           class="text-nowrap whitespace-nowrap" style="font-size: 16px;">
          {{ title }}
      </div>
    </div>

    <div  *ngIf="!collapsed && (beta || comingSoon)" class="absolute flex flex-row justify-end items-center -top-2 right-1.5 text-[8px]">
      <div *ngIf="beta&&!collapsed" class="bg-bic-yellow p-0.5 rounded font-extrabold text-white">BETA</div>
      <div *ngIf="comingSoon&&!collapsed" class="bg-primary p-0.5 rounded font-extrabold text-white">Bald verfügbar
      </div>
    </div>
  </div>
</div>


