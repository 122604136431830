/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomUnitAmount } from './customUnitAmount';
import { JsonObject } from './jsonObject';
import { StripeResponse } from './stripeResponse';
import { Tier } from './tier';

export interface CurrencyOption { 
    lastResponse?: StripeResponse;
    rawJsonObject?: JsonObject;
    customUnitAmount?: CustomUnitAmount;
    taxBehavior?: string;
    tiers?: Array<Tier>;
    unitAmount?: number;
    unitAmountDecimal?: number;
}