/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { User } from './user';

export interface Topic { 
    atGuid?: string;
    atTopicType?: string;
    atTopicStatus?: string;
    referenceLink?: string;
    title?: string;
    priority?: string;
    index?: string;
    labels?: Array<string>;
    creationDate?: Date;
    creationAuthor?: string;
    modifiedDate?: Date;
    modifiedAuthor?: string;
    dueDate?: Date;
    startDate?: Date;
    assignedTo?: string;
    description?: string;
    stage?: string;
    creationUser?: User;
    modifiedUser?: User;
    assignedToUser?: User;
}