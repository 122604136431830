import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {
  AbstractControl,
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {AuthControllerService, User, JwtResponse} from "../../api";
import {StateConfigService} from "../../services/state/state-config.service";
import {ErrorService} from "../../services/error/error.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {faBars, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

/**
 * Authentication Component
 */
@Component({
  selector: 'app-login',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  loginForm!: UntypedFormGroup;
  registerForm!: UntypedFormGroup;
  passwordForm!: UntypedFormGroup;
  resetPasswordForm!: UntypedFormGroup;
  mfaForm!: UntypedFormGroup;

  loginError: boolean = false;
  mfaError: boolean = false;
  notRegisteredError: boolean = false;
  verificationError: boolean = false;
  alreadyRegisteredError: boolean = false;


  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef>;

  isRegister: boolean = false;
  isVerify: boolean = false;
  isPasswordReset: boolean = false;
  isNewVerification: boolean = false;
  isProfile: boolean = false;
  isDeletedAccount: boolean = false;
  isMfa: boolean = false;
  response!: JwtResponse;


  showPassword: boolean = false;
  showPasswordRepeat: boolean = false;

  user!: User;
  successSent!: Boolean;

  /**
   * Constructor
   * @param router Router
   * @param route ActivatedRoute
   * @param authControllerService
   * @param formBuilder
   * @param stateConfig
   * @param errorService
   * @param snackBar
   * @param dialog MatDialog
   * @param authService @param authService
   */
  constructor(private router: Router, private route: ActivatedRoute,
              private authControllerService: AuthControllerService,
              public formBuilder: UntypedFormBuilder,
              public stateConfig: StateConfigService,
              private errorService: ErrorService,
              private snackBar: MatSnackBar, public authService: AuthService) {
    if(this.router.getCurrentNavigation()!.extras!.state){
      if(this.router.getCurrentNavigation()!.extras!.state!.response) {
        this.response = this.router.getCurrentNavigation()!.extras!.state!.response!;
      }
    }
  }

  /**
   * Init Component
   */
  ngOnInit(): void {
    // this.openDialog()
    this.loginForm = this.formBuilder.group({
      mail: new UntypedFormControl("", [Validators.required, Validators.email]),
      password: new UntypedFormControl("", [Validators.required]),
    });

    this.registerForm = this.formBuilder.group({
      mail: new UntypedFormControl("", [Validators.required, Validators.email]),
      firstName: new UntypedFormControl("", [Validators.required, this.validateName()]),
      lastName: new UntypedFormControl("", [Validators.required, this.validateName()]),
      termsAndConditions: new UntypedFormControl(false, [Validators.required]),
    });

    this.passwordForm = this.formBuilder.group({
      password: new FormControl('', [Validators.minLength(8), Validators.required]),
      passwordRepeat: new FormControl('', [Validators.required, this.matchPasswords()]),
      token: this.route.snapshot.queryParams.token
    });

    this.resetPasswordForm = this.formBuilder.group({
      mail: new UntypedFormControl("", [Validators.required, Validators.email]),
    });

    this.mfaForm = this.formBuilder.group({
      code1: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code2: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code3: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code4: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code5: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code6: ['', [Validators.required, Validators.pattern(/^\d$/)]]
    });


    this.route.queryParams.subscribe(params => {
      this.isDeletedAccount = params.isDeletedAccount;
    })

    this.route.data.subscribe(data => {
      this.isRegister = data.register ?? false;
      this.isVerify = data.verify ?? false;
      this.isPasswordReset = data.resetPassword ?? false;
      this.isNewVerification = data.newVerification ?? false;
      this.isProfile = data.profile ?? false;
      this.isMfa = data.isMfa ?? false;

      if(this.isVerify && !this.authService.isLoggedIn()){
        this.passwordForm = this.formBuilder.group({
          password: new FormControl('', [Validators.minLength(8), Validators.required, this.passwordValidator()]),
          passwordRepeat: new FormControl('', [Validators.required, this.matchPasswords()]),
          token: this.route.snapshot.queryParams.token
        });

        // @ts-ignore
        this.passwordForm.get('password').valueChanges.subscribe(() => {
          // @ts-ignore
          this.passwordForm.get('passwordRepeat').updateValueAndValidity();
        });
      }

      if (data.logout) {
        this.authService.logout();
        this.router.navigateByUrl("/login");
      }

      if(this.authService.isLoggedIn()){
        this.authControllerService.profile().subscribe((user) => {
          this.user = user;
          if (!this.user.street) {
            this.router.navigate(["/profile"], {queryParams: {init: true}}).then(() => {

            })
          } else {
            this.stateConfig.selectProject(undefined)
          }
        });
      }
    });
  }

  login(): void {
    this.loginError = false
    this.notRegisteredError = false
    this.verificationError = false
    this.errorService.errorEvent$.subscribe((error) => {
      if (error.status == 401) {
        this.loginError = true
      }
      if(error.status == 404){
        this.notRegisteredError = true
      }
      if(error.status == 403){
        this.verificationError = true
      }
    });

    let success = false
    this.loginError = false

    this.authService.clearStorage()
    this.authService.login(
      this.loginForm.get('mail')?.value,
      this.loginForm.get('password')?.value
    ).subscribe((next) => {
      if (next) {
        this.authControllerService.profile().subscribe((user) => {
          this.user = user;
          if (!this.user.street) {
            this.router.navigate(["/profile"], {queryParams: {init: true}}).then(() => {

            })
          } else {
            this.stateConfig.selectOrganisation(undefined, true, false, true)
          }
        });
      }
    });
  }

  validateName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value.trim().length >= 2 ? null : { 'invalidName': true };
    };
  }

  register(): void {
    this.alreadyRegisteredError = false;
    this.errorService.errorEvent$.subscribe((error) => {
      if (error.status == 400) {
        this.alreadyRegisteredError = true;
      }
    });
    this.registerForm.value.firstName = this.registerForm.value.firstName.trim();
    this.registerForm.value.lastName = this.registerForm.value.lastName.trim();

    if(this.registerForm.valid && this.registerForm.get('termsAndConditions')?.value == true){
      this.authControllerService.register({
        user: this.registerForm.value
      }).subscribe({
        next: () => {
          this.snackBar.open("Registrierung erfolgreich.\nBitte verifizieren Sie Ihren Account über die Ihnen zugesandte E-Mail. Sie sollten diese innerhalb weniger Minuten erhalten.", "OK", {
            panelClass: ['snackbar-success'],
          });
          this.router.navigateByUrl("/login");
        },
        error: (err) => {
          this.snackBar.open("Registrierung fehlgeschlagen.\nUnter dieser E-Mail Adresse ist bereits ein Account vorhanden.", "OK",{
              panelClass: ['snackbar-error'],
            });
        },
      });
    }
  }


  matchPasswords(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      if (this.passwordForm) {
        return this.passwordForm.get('password')?.value == control.value ? null : {matchValue: false};
      } else {
        return {matchValue: false};
      }

    };
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      const hasUpperCase = /[A-Z]+/.test(value);
      const hasLowerCase = /[a-z]+/.test(value);
      const hasNumeric = /[0-9]+/.test(value);
      const hasSpecialCharacter = /[!\?&%=.\-:*]+/.test(value);
      const hasNoSpaces = !/\s/.test(value);


      const passwordValid = hasUpperCase && hasLowerCase && hasNumeric && hasSpecialCharacter && hasNoSpaces;

      return !passwordValid ? {passwordInvalid: true} : null;
    };
  }

  testLength(str: string): boolean {
    return str.length >= 8;
  }

  testLowerCase(str: string): boolean {
    return /[a-z]+/.test(str);
  }

  testUpperCase(str: string): boolean {
    return /[A-Z]+/.test(str);
  }

  testNumeric(str: string): boolean {
    return /[0-9]+/.test(str);
  }

  testSpecialCharacter(str: string): boolean {
    return /[!\?&%=.\-:*]+/.test(str);
  }


  setPassword(): void {
    if(this.passwordForm.get('password')?.errors == null &&  this.passwordForm.get('passwordRepeat')?.errors == null){
      this.authControllerService.resetPassword(this.passwordForm.value).subscribe(() => {
        this.router.navigateByUrl("/login");
      })
    }
  }

  resetPassword(): void {
    this.resetPasswordForm.get('mail')?.markAsTouched();
    if(this.resetPasswordForm.get('mail')?.errors === null){
      this.authControllerService.resetPasswordRequest(this.resetPasswordForm.get("mail")?.value).subscribe(() => {
        this.snackBar.open("Passwort erfolgreich zurückgesetzt.\nBitte wählen Sie ein neues Passwort über die Ihnen zugesandte E-Mail. Sie sollten diese innerhalb weniger Minuten erhalten.\nSie können dieses Fenster nun schließen", "OK", {
          panelClass: ['snackbar-success'],
          duration: 10000
        });
      });
    }
  }

  verifyMfa(): void {
    this.errorService.errorEvent$.subscribe((error) => {
      if (error.status == 401) {
        this.mfaError = true
      }
    });
    this.mfaError = false
    const code = '' + this.mfaForm.get('code1')?.value + this.mfaForm.get('code2')?.value + this.mfaForm.get('code3')?.value + this.mfaForm.get('code4')?.value + this.mfaForm.get('code5')?.value + this.mfaForm.get('code6')?.value;
    this.authService.loginMfa(this.response!.mail! ?? '', '', code).subscribe(() => {
      this.stateConfig.selectOrganisation(undefined, true, false, true)
    });


  }


  moveToNext(event: any, index: number): void {
    const input = event.target;
    const key = event.key;

    if (key === 'Backspace' && input.value.length === 0 && index > 0) {
      this.otpInputs.toArray()[index - 1].nativeElement.focus();
    } else if (input.value.length === 1 && index < 5) {
      this.otpInputs.toArray()[index + 1].nativeElement.focus();
    }
  }

  resendMail(): void {
    this.authControllerService.resendCode(this.response!.mail! ?? '').subscribe(() => {
      if(this.response.isMailMfa){
        this.snackBar.open("Code erfolgreich erneut angefordert.\nIhnen wurde eine E-Mail mit einem neuen Code per Mail zugesendet. Sie sollten diese innerhalb weniger Minuten erhalten.", "OK", {
          panelClass: ['snackbar-success'],
          duration: 10000
        });
      }else{
        this.snackBar.open("Code per E-Mail angefordert.\nDa Sie keinen Zugriff mehr auf den Code innerhalb Ihrer Authentifikations-App haben, wurde Ihnen ein Code per E-Mail zugesandt." +
          " \nSie sollten diese innerhalb weniger Minuten erhalten.", "OK", {
          panelClass: ['snackbar-success'],
          duration: 10000
        });
      }
    });
  }


  protected readonly window = window;
  protected readonly faBars = faBars;
}
