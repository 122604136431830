import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Contingent, Price, Product, ProductSettings} from "../../../api";

@Component({
  selector: 'app-checkout-price-card',
  templateUrl: './checkout-price-card.component.html',
  styleUrls: ['./checkout-price-card.component.scss']
})
export class CheckoutPriceCardComponent implements OnInit {

  @Input() price!: Price;
  @Input() addOn: Price | null = null;
  @Input() isAddOn: boolean = false;
  @Input() isSpecial: boolean = false;
  @Input() active: boolean = false;
  @Input() productSettings!: ProductSettings;
  @Output() checkoutClicked: EventEmitter<Price> = new EventEmitter<Price>();
  @Input() contingent!: Contingent

  @Input() info: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

  isRecurring() {
    return this.productPrice?.recurring != undefined;
  }

  get productPrice() {
    return this.isAddOn ? this.addOn : this.price
  }

  get isActive() {
    return this.isAddOn ? !this.active : this.active;
  }

}
