import { Injectable } from '@angular/core';
import {interval, Subscription} from "rxjs";
import {StateConfigService} from "./state-config.service";
import {DefaultService} from "../../bim-api";
import {IfcControllerService} from "../../api";

@Injectable({
  providedIn: 'root'
})
export class IfcStateService {

  private ifcStateSubscription!: Subscription;
  private runningChecks: string[] = [];
  constructor(private stateConfigService: StateConfigService, private bimService: DefaultService, private ifcControllerService: IfcControllerService) {


  }

  public start(): void {
    this.stateConfigService.runningIfcChecksChange.subscribe((runningIfcChecks: string[] | undefined) => {
      this.runningChecks = runningIfcChecks ?? [];

      if (this.ifcStateSubscription) {
        this.ifcStateSubscription.unsubscribe();
      }

      // Subscribe to all running checks to get their status in 20s intervals and update the state
      this.ifcStateSubscription = interval(20000).subscribe(() => {
        for (let check of this.runningChecks) {
          this.bimService.status(check).subscribe((status) => {
            if(status.status == "Completed") {
              // @ts-ignore
              const result = status.output;

              this.ifcControllerService.completeCheck(result, check).subscribe();
            }
          })
        }
      });

    })
  }

  public stop(): void {
    if(this.ifcStateSubscription){
        this.ifcStateSubscription.unsubscribe();
    }
  }


}
