<div class="w-full">
  <app-stepper [linear]="true" (selectedIndexChange)="changeStep($event)" [selectedIndex]="selectedStep">
    <cdk-step [label]="'Dateiauswahl'" [completed]="completedSteps.includes(0)" [editable]="editMap.get(0)" >
    </cdk-step>
    <cdk-step [label]="'Prüfregelauswahl'" [completed]="completedSteps.includes(1)" [editable]="editMap.get(1)" >
    </cdk-step>
    <cdk-step [label]="'Ergebnis - Dateien'" [completed]="completedSteps.includes(2)" [editable]="editMap.get(2)" >
    </cdk-step>
    <cdk-step [label]="'Ergebnis - Prüfregeln'" [completed]="completedSteps.includes(3)" [editable]="editMap.get(3)" >
    </cdk-step>
  </app-stepper>

</div>
