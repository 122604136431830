<div class="flex flex-col h-full w-full justify-start items-start gap-6 pb-6 font-medium bg-[#FFF]">
  <app-quality-stepper class="w-full"></app-quality-stepper>

  <div class="flex flex-col w-full justify-start items-start gap-6 px-4 overflow-hidden">
    <app-overview-report [checkType]="'Qualitätsprüfung'"
                         [completedValue]="completed"
                         [date]="qualityCheck.checkDate"
                         [errorValue]="error"
                         [rules]="total"
                         [warningValue]="warning"
                         [fileDeactivated]="false"
                         [fileId]="fileId"
                         [whatWasChecked]=" total == 1 ? 'Anforderung' : 'Anforderungen'"
                         class="w-full">
    </app-overview-report>

    <div class="overflow-hidden h-full w-full">
      <div class="flex w-full overflow-y-scroll h-full">
      <bic-table class="w-full" [data]="data" [columnNames]="displayedColumnsNames" [columns]="displayedColumns" [columnTypes]="columnsTypes" [columnWidths]="columnsWidths" [columnAlignments]="columnsAlignment"
                 [subtable]="true" [subtableData]="subData" [subtableColumns]="subDisplayedColumns" [subtableColumnNames]="subDisplayedColumnsNames" [subtableColumnTypes]="subColumnsTypes" [subtableColumnWidths]="subColumnsWidths" [subtableColumnAlignments]="subColumnsAlignment"
                 [paginator]="false" [subtablePaginator]="false" (rowClick)="changeSubData($event)" [createTicket]="false" (createTicketClicked)="createTask($event)"
      [backbutton]="true" [backButtonText]="'Zurück'" (backButtonClicked)="navigateBack()" [title]="rule.name"></bic-table>
<!--        <bic-table-->
<!--          class="w-full"-->
<!--          [data]="subData"-->
<!--        [columnNames]="subDisplayedColumnsNames"-->
<!--        [columns]="subDisplayedColumns"-->
<!--        [columnTypes]="subColumnsTypes"-->

<!--        [paginator]="false"-->
<!--        [createTicket]="false"-->
<!--        [title]="'Sub Data Table'"></bic-table>-->

      </div>

    </div>
  </div>
</div>
