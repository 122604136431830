<div class="flex flex-row w-full px-6 py-3.5 text-xl text-black items-center font-medium shadow-full-new">
  <div class="flex flex-row justify-start items-center gap-2 w-full h-10">
    <button class="flex flex-row justify-start items-center gap-2 p-2 -ml-2 hover:bg-gray-200 rounded-md text-black text-base" (click)="navigateToHome()">
      Dateien
    </button>
    <div>
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </div>
    <button *ngIf="selectedFolder" class="flex flex-row justify-start items-center gap-2 p-2 hover:bg-gray-200 rounded-md text-black text-base" (click)="navigateToLPH(stateConfigService.selectedServicePhase)">
      Leistungsphase {{stateConfigService.selectedServicePhase | lph}}
    </button>
    <button *ngIf="!selectedFolder" class="flex flex-row justify-start items-center gap-2 p-2 hover:bg-gray-200 rounded-md text-black text-base" [matMenuTriggerFor]="lphSelected">
      Leistungsphase {{stateConfigService.selectedServicePhase | lph}}
    </button>

    <ng-container *ngIf="selectedFolder">
      <div>
        <fa-icon [icon]="faChevronRight"></fa-icon>
      </div>
      <button class="flex flex-row justify-start items-center gap-2 p-2 hover:bg-gray-200 text-black text-base rounded-md" [matMenuTriggerFor]="folderMenu">
        {{selectedFolder | folderName: lph}}
      </button>
    </ng-container>
  </div>
  <div *ngIf="!selectedFolder" class="rounded-lg flex flex-row justify-end items-center ml-auto h-[42px] border border-gray-200" >
    <!-- Grid View Button -->
    <button (click)="setCardView()" class="flex flex-row p-2 rounded-l">
      <div class="flex h-5 w-5 justify-center items-center">
        <fa-icon [icon]="faGrid2" [ngClass]="isCardView ? 'text-gray-900' : 'text-gray-300'"></fa-icon>
      </div>
    </button>
    <div class="border-l-2 border-gray-300 h-6"></div>
    <!-- List View Button -->
    <button (click)="setListView()" class="flex flex-row p-2 rounded-l">
      <div class="flex h-5 w-5 justify-center items-center">
        <fa-icon [icon]="faBars" [ngClass]="isListView ? 'text-gray-900' : 'text-gray-300'"></fa-icon>
      </div>
    </button>
  </div>


</div>
<div class="flex flex-col justify-start items-start gap-2 h-full p-4">

  <div class="text-secondary text-xl">

    <div class="text-black text-[18px] pl-2" *ngIf="!selectedFolder">
      Die Prüfung basiert auf der Zuordnung der Bauprojektunterlagen zu den folgenden Ordnern. Bitte beachten Sie die hinterlegten Hinweise hinter den Fragezeichen.
    </div>

    <div *ngIf="selectedFolder" class="text-gray-500 text-sm pl-2">
      <div [innerHTML]="getPreviewText(selectedFolder)" class="preview-text"></div>
      <button *ngIf="showToggle" class="text-primary mt-2" (click)="toggleExpanded()">
        {{ isExpanded ? 'Weniger anzeigen' : 'weitere...' }}
      </button>
    </div>

    <mat-menu #lphSelected="matMenu">
      <ng-container *ngFor="let lph of stateConfigService.lphEnum | keyvalue">
        <button class="hover:bg-gray-200" mat-menu-item
                *ngIf="lph.value != stateConfigService.lphEnum.PLANS && lph.value != stateConfigService.lphEnum.GENERAL"
                (click)="navigateToLPH(lph.value)">
          Leistungsphase {{lph.value | lph}}
        </button>
      </ng-container>
    </mat-menu>

    <mat-menu #folderMenu="matMenu">
      <ng-container *ngFor="let folder of getFoldersFor(lph)">
        <button class="hover:bg-gray-200 text-black text-base" mat-menu-item (click)="openFolder(folder)">
          {{folder | folderName: lph}}
        </button>
      </ng-container>
    </mat-menu>
  </div>


  <div *ngIf="!selectedFolder && isCardView" class="flex flex-row justify-start items-start flex-wrap gap-10 overflow-y-scroll p-2  -ml-2">
    <bic-card class="flex flex-col justify-start p-2" (click)="openFolder(folder)" *ngFor="let folder of getFoldersFor(this.stateConfigService.selectedServicePhase); let i = index">

      <div class="flex flex-col justify-between h-full w-full">
        <!-- Folder Name and Menu Icon -->
        <div class="flex flex-row justify-between items-start">
          <div class="flex flex-row justify-start items-center gap-4 pl-2 pt-2">
            <fa-icon [icon]="faFolderOpen" class="text-primary text-2xl"></fa-icon>

            <div class="text-lg font-medium">
              {{folder | folderName: this.stateConfigService.selectedServicePhase}}
            </div>
          </div>

          <div class="text-lg pr-1 pt-2" #overlayTrigger (mouseenter)="openedFolderOverlay = folder" (mouseleave)="openedFolderOverlay = null">
            <fa-icon [icon]="faCircleQuestion" class="text-primary text-2xl" #infoIcon></fa-icon>
          </div>



          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="overlayTrigger"
            [cdkConnectedOverlayOpen]="openedFolderOverlay == folder"
          >
            <div class="text-xs bg-white rounded-[5px] p-[5px] shadow-full break-words w-full">
              <div [innerHTML]="getInfotext(folder)"></div>
            </div>
          </ng-template>
        </div>

        <!-- Folder Information -->
        <div class="flex flex-col justify-center gap-3 pb-4">

          <div class="flex flex-row gap-2 justify-between items-center" *ngIf="calculateSizeInGB(folderData(folder).size) < 1">
            <div class="pl-2">Größe</div>
            <div class="pr-2">{{calculateSizeInMB(folderData(folder).size) | number: '.0-2'}} MB</div>
          </div>

          <div class="flex flex-row gap-2 justify-between items-center" *ngIf="calculateSizeInGB(folderData(folder).size) >= 1">
            <div class="pl-2">Größe</div>
            <div class="pr-2">{{calculateSizeInGB(folderData(folder).size) | number: '.0-2'}} GB</div>
          </div>

          <div class="flex flex-row gap-2 justify-between items-center">
            <div class="pl-2">Dateien</div>
            <div class="pr-2">{{folderData(folder).numberOfFiles}}</div>
          </div>

          <div class="flex flex-row gap-2 justify-between items-center" *ngIf="false">
            <div class="pl-2">Zuletzt geändert</div>
            <div class="pr-2">03.06.2023</div>
          </div>
        </div>
      </div>
    </bic-card>
  </div>

  <div *ngIf="!selectedFolder && isListView"
       class="flex flex-row justify-start items-start flex-wrap gap-5 overflow-y-scroll p-2">
    <div class="flex flex-row justify-end items-end w-full">
      <div class="flex flex-row items-end w-1/2"></div> <!-- gap for folder name -->
      <div class="flex flex-row items-end w-1/4 text-zinc-400 font-medium">Größe</div>
      <div class="flex flex-row items-end w-1/4 text-zinc-400 font-medium">Dateien</div>
      <div *ngIf="false" class="flex flex-row items-end w-1/3 ">Zuletzt geändert</div>
      <div class="flex flex-row items-end w-10"></div> <!-- gap for info icon -->
    </div>
    <bic-row (click)="openFolder(folder)"
             *ngFor="let folder of getFoldersFor(this.stateConfigService.selectedServicePhase)"
             class="flex flex-row justify-start w-full">

      <div class="flex flex-row justify-center items-center h-full w-full">
        <!-- Folder Name and Menu Icon -->
        <div class="flex flex-row w-1/2">
          <div class="flex flex-row justify-start items-start gap-4 pl-2">
            <fa-icon [icon]="faFolderOpen" class="text-primary text-2xl"></fa-icon>
            <div class="text-lg font-medium">
              {{folder | folderName: this.stateConfigService.selectedServicePhase}}
            </div>
          </div>

          <ng-template
            [cdkConnectedOverlayOpen]="openedFolderOverlay == folder"
            [cdkConnectedOverlayOrigin]="overlayTrigger"
            cdkConnectedOverlay
          >
            <div class="text-xs bg-white rounded-[5px] p-[5px] shadow-full break-words w-full">
              <div [innerHTML]="getInfotext(folder)"></div>
            </div>
          </ng-template>
        </div>

        <!-- Folder Information -->
        <div *ngIf="calculateSizeInGB(folderData(folder).size) < 1" class="flex flex-row w-1/4 text-black font-medium">
          <div class="pr-2">{{calculateSizeInMB(folderData(folder).size) | number: '.0-2'}} MB</div>
        </div>
        <div *ngIf="calculateSizeInGB(folderData(folder).size) >= 1" class="flex flex-row w-1/4 text-black font-medium">
          <div class="pr-2">{{calculateSizeInGB(folderData(folder).size) | number: '.0-2'}} GB</div>
        </div>
        <div class="flex flex-row w-1/4 text-black font-medium">
          <div class="pr-2">{{folderData(folder).numberOfFiles}}</div>
        </div>
        <div *ngIf="false" class="flex flex-row w-1/4 text-black font-medium">
          <div class="pr-2">03.06.2023</div>
        </div>
        <div #overlayTrigger (mouseenter)="openedFolderOverlay = folder" (mouseleave)="openedFolderOverlay = null"
             class="text-lg pr-1">
          <fa-icon [icon]="faCircleQuestion" class="text-primary text-2xl"></fa-icon>
        </div>
      </div>
    </bic-row>
  </div>
  <app-files class="w-full h-full overflow-hidden p-2" *ngIf="selectedFolder"
             [(breadcrumbNavigation)]="breadcrumbNavigation"
             [project]="stateConfigService.selectedProject"
             [folder]="selectedFolder"
             [lph]="stateConfigService.selectedServicePhase">
  </app-files>

</div>
