<div class="flex flex-col z-40 h-full w-full bg-white shadow-full rounded-[10px]">
  <div class="flex flex-col p-2">
    <h1 class="text-2xl p-2 font-medium">Datei verschieben:</h1>
    <div class="flex flex-col p-2 font-medium justify-start">
      <ul>
        <li *ngFor="let file of selectedFiles">
          {{ file.name }}
        </li>
      </ul>
    </div>
    <div class="flex flex-row gap-14">
      <mat-form-field appearance="outline" class="p-2 w-full flex flex-row pt-4">
        <mat-label>Leistungsphase</mat-label>
        <mat-select [(value)]="selectedLph" (selectionChange)="onLphChange($event.value)">
          <ng-container *ngFor="let lph of stateConfigService.lphEnum | keyvalue">
            <mat-option *ngIf="isValidLph(lph.value)" [value]="lph.value" >
              LPH {{lph.value | lph}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="p-2 w-full flex flex-row pt-4">
        <mat-label>Ordner</mat-label>
        <mat-select [(value)]="selectedFolder" [disabled]="!selectedLph">
          <mat-option *ngFor="let folder of getFoldersFor(selectedLph)" [value]="folder">
            {{ folder | folderName: selectedLph }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
  <div class="flex flex-row py-2 justify-between">
    <mat-dialog-actions class="flex justify-between w-full">
      <button mat-raised-button style=" background-color: #FFFFFF;" mat-dialog-close>Abbrechen</button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" class="text-white" [mat-dialog-close]="true" [disabled]="!(selectedFolder && selectedLph)" (click)="moveFiles()">
        Verschieben
      </button>
    </mat-dialog-actions>
  </div>
</div>


