<!--<ng-template-->
<!--  cdkConnectedOverlay-->
<!--  [cdkConnectedOverlayOpen]="openDetails"-->
<!--  class="z-40"-->
<!--  #overlayTemplate-->
<!--&gt;-->
<div *ngIf="task && user && users" class="p-6 bg-white rounded-lg shadow-full overflow-auto">
  <div class="flex flex-row pb-4 border-b border-gray-200 justify-between items-center">
    <span class="font-semibold text-lg text-gray-900"> {{ !task.id ? 'Task erstellen:' : 'Task bearbeiten:' }}</span>
    <fa-icon [icon]="faX" class="text-sm text-gray-500 hover:cursor-pointer" (click)="close()"></fa-icon>

  </div>


  <!--  <div class="flex flex-row text-xl font-medium pt-5 pl-2 pb-2 text-primary items-start justify-between w-full sticky top-0 bg-white" style="z-index: 1;">-->
  <!--    <div class="pt-2">-->
  <!--      {{ !task.id ? 'Aufgabe erstellen:' : 'Aufgabe bearbeiten:' }}-->
  <!--    </div>-->
  <!--    <button mat-icon-button class="pr-2" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu">-->
  <!--      <fa-icon class="text-primary text-xl" [icon]="faEllipsisVertical"></fa-icon>-->
  <!--    </button>-->
  <!--  </div>-->
  <div class="overflow-y-scroll flew-grow">
    <form [formGroup]="taskForm" class="h-full">
      <div class="flex flex-col justify-center items-start gap-4">
        <div class="flex flex-row w-full pt-4">
          <div formGroupName="markup" class="w-full">
            <div formGroupName="topic" class="w-full">
              <bic-input type="text" formControlName="title" [placeholder]="'Task-Name * (erforderlich)'"
                         [label]="'Name'" required class="w-full border-0 p-0"></bic-input>
            </div>
          </div>


          <!--        <mat-form-field appearance="outline" class="flex w-full py-2" subscriptSizing="fixed">-->
          <!--          <mat-label>Titel</mat-label>-->
          <!--          <div formGroupName="markup">-->
          <!--            <div formGroupName="topic"><input name="title" formControlName="title" matInput required></div>-->
          <!--          </div>-->
          <!--          <mat-hint *ngIf="task.markup?.topic?.creationUser && !task.markup.topic.modifiedUser" style="font-size:small;">-->
          <!--            Erstellt am {{task.markup.topic.creationDate | date}} von {{ task.markup?.topic?.creationUser.firstName }} {{ task.markup.topic.creationUser.lastName }}-->
          <!--          </mat-hint>-->
          <!--          <mat-hint *ngIf="!task.markup?.topic?.creationUser && task.markup.topic.creationAuthor && !task.markup.topic.modifiedAuthor" style="font-size:small;">-->
          <!--            Erstellt am {{task.markup.topic.creationDate | date}} von {{ task.markup?.topic?.creationAuthor }}-->
          <!--          </mat-hint>-->
          <!--          <mat-hint *ngIf="task.markup?.topic?.modifiedUser" style="font-size:small;">-->
          <!--            Zuletzt geändert am {{task.markup.topic.modifiedDate | date}} von {{ task.markup?.topic?.modifiedUser.firstName }} {{ task.markup.topic.modifiedUser.lastName }}-->
          <!--          </mat-hint>-->
          <!--          <mat-hint *ngIf="!task.markup?.topic?.modifiedUser && task.markup.topic.modifiedAuthor" style="font-size:small;">-->
          <!--            Zuletzt geändert am {{task.markup.topic.modifiedDate | date}} von {{ task.markup?.topic?.modifiedAuthor }}-->
          <!--          </mat-hint>-->
          <!--        </mat-form-field>-->

        </div>

        <div class="flex flex-row w-full gap-6">
                      <bic-select formControlName="priority" label="Priorität" class="w-1/3">
                        <bic-dropdown-element *ngFor="let priority of priorityEnum | keyvalue: keepOrder"
                                    [value]="priority.value">{{ priority.value | priority }}
                        </bic-dropdown-element>
                      </bic-select>

                      <bic-select formControlName="state" label="Status" class="w-1/3">
                        <ng-container *ngFor="let state of stateEnum | keyvalue">
                          <bic-dropdown-element *ngIf="(state.value == stateEnum.VERIFIED)"
                                      [value]="state.value" [disabled]="user.id != taskForm.value['creator']?.id">
                            {{ state.value | taskState }}
                          </bic-dropdown-element>
                          <bic-dropdown-element *ngIf="(state.value != stateEnum.VERIFIED)"
                                      [value]="state.value">
                            {{ state.value | taskState }}
                          </bic-dropdown-element>
                        </ng-container>
                      </bic-select>

                      <bic-select formControlName="lph" label="LPH" class="w-1/3">
                        <ng-container *ngFor="let lph of LphEnum | keyvalue">
                          <bic-dropdown-element *ngIf="lph.value != 'GENERAL' && lph.value != 'PLANS'"
                                      [value]="lph.value"> LPH {{ lph.value | lph }}
                          </bic-dropdown-element>
                        </ng-container>
                      </bic-select>


        </div>

        <div class="flex flex-row w-full">
          <div formGroupName="markup" class="w-full">
            <div formGroupName="topic" class="w-full">
              <label for="task_description" class="block text-sm mb-1 font-medium">Beschreibung</label>
              <textarea id="task_description" formControlName="description"
                        placeholder="Hier eine Beschreibung eingeben oder Notizen hinzufügen"
                        class="select-none cursor-pointer focus:ring-[none] ring-[none] focus:border-secondary border text-sm rounded-lg placeholder-gray-400 block w-full p-2.5 border-gray-300 bg-gray-50 resize-none"></textarea>
            </div>
          </div>
        </div>

        <div class="flex flex-row w-full gap-8">
          <div formGroupName="markup" class="w-1/3">
            <div formGroupName="topic" class="w-full">
              <bic-input #test bicDatepickerInput [iconStart]="faCalendarDays" formControlName="startDate" [label]="'Startdatum'" [isDatepicker]="true"
                         class="w-full" (dateChange)="taskForm.get('markup.topic.startDate')?.setValue($event)"></bic-input>
            </div>
          </div>

          <div formGroupName="markup" class="w-1/3">
            <div formGroupName="topic" class="w-full">
              <bic-select formControlName="assignedToUser" label="Verantwortlich" class="w-full" #testen [comparator]="compare" [dropdownWithSelectionWidth]="true" [iconStart]="faUserPlus" [onlyShowPlaceholder]="true" [placeholder]="'Person auswählen'">
                <ng-container *ngFor="let user of users">
                  <bic-dropdown-element  [value]="user"> {{ user.firstName }} {{ user.lastName }}
                  </bic-dropdown-element>
                </ng-container>
              </bic-select>
            </div>
          </div>

          <div formGroupName="markup" class="w-1/3 flex flex-row items-end gap-3">
            <ng-container *ngIf="taskForm.get('markup.topic.assignedToUser')!.value">
              <img *ngIf="taskForm.get('markup.topic.assignedToUser')!.value?.profileImage" class="w-10 h-10 rounded-full" src="{{taskForm.get('markup.topic.assignedToUser')!.value?.profileImage}}" alt="Verantwortlich">
              <div *ngIf="!taskForm.get('markup.topic.assignedToUser')!.value?.profileImage" class="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                <span class="font-medium text-gray-600 dark:text-gray-300">{{taskForm.get('markup.topic.assignedToUser')!.value?.firstName.charAt(0).toUpperCase()}}{{taskForm.get('markup.topic.assignedToUser')!.value?.lastName.charAt(0).toUpperCase()}}</span>
              </div>
              <div class="flex flex-col">
                <span class="text-base font-semibold">
                  <span class="text-base font-semibold" *ngIf="taskForm.get('markup.topic.assignedToUser')!.value?.title && taskForm.get('markup.topic.assignedToUser')!.value?.title != 'NONE'">
                    {{taskForm.get('markup.topic.assignedToUser')!.value?.title}}
                  </span>
                  {{taskForm.get('markup.topic.assignedToUser')!.value?.firstName}} {{taskForm.get('markup.topic.assignedToUser')!.value?.lastName}}</span>
                <span class="text-sm text-bic-gray">{{taskForm.get('markup.topic.assignedToUser')!.value?.roleInProjects[stateService.getProjectId()].emailName}}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="flex flex-row w-full gap-8">
          <div formGroupName="markup" class="w-1/3">
            <div formGroupName="topic" class="w-full">
              <bic-input [iconStart]="faCalendarDays" bicDatepickerInput formControlName="dueDate" [label]="'Fälligkeitsdatum'" [isDatepicker]="true"
                         class="w-full" (dateChange)="taskForm.get('markup.topic.dueDate')?.setValue($event)"></bic-input>
            </div>
          </div>

          <div class="w-1/3">
            <div class="w-full">
              <bic-select formControlName="informedUsers" label="Benachrichtigen" class="w-full" [compareWith]="compareUsers" [dropdownWithSelectionWidth]="true" [iconStart]="faUserGroup" [onlyShowPlaceholder]="true" [placeholder]="'Personen auswählen'">
                <bic-dropdown-element *ngFor="let user of users" class="flex" (onClick)="test.click()">
                  <div class="flex gap-2">
                    <input type="checkbox" #test [checked]="isSelected(user)" (change)="toggleCheckbox($event, user)" (click)="test.click()" class="w-4 h-4 rounded text-primary-600 checked:bg-primary indeterminate:bg-primary indeterminate:hover:bg-primary focus:ring-[none] bg-gray-100 border-gray-300">
                    {{ user.firstName }} {{ user.lastName }}
                  </div>
                </bic-dropdown-element>
              </bic-select>
            </div>
          </div>

          <div class="w-1/3 flex pb-1 items-end">
            <div class="flex -space-x-4 rtl:space-x-reverse" *ngIf="taskForm.get('informedUsers')">
              <ng-container *ngIf="informedUsers.length > 0">
                <img *ngIf="informedUsers[0].profileImage" class="w-10 h-10 z-10 border-2 border-white rounded-full dark:border-gray-800" src="{{informedUsers[0].profileImage}}" alt="Verantwortlich">
                <div *ngIf="!informedUsers[0].profileImage" class="relative z-10 inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 border-2 border-white rounded-full dark:border-gray-800 dark:bg-gray-600">
                  <span class="font-medium text-gray-600 dark:text-gray-300">{{informedUsers[0].firstName.charAt(0).toUpperCase()}}{{informedUsers[0].lastName.charAt(0).toUpperCase()}}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="informedUsers.length > 1">
                <img *ngIf="informedUsers[1].profileImage" class="w-10 h-10 z-20 border-2 border-white rounded-full dark:border-gray-800" src="{{informedUsers[1].profileImage}}" alt="Verantwortlich">
                <div *ngIf="!informedUsers[1].profileImage" class="relative z-20 inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 border-2 border-white rounded-full dark:border-gray-800 dark:bg-gray-600">
                  <span class="font-medium text-gray-600 dark:text-gray-300">{{informedUsers[1].firstName.charAt(0).toUpperCase()}}{{informedUsers[1].lastName.charAt(0).toUpperCase()}}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="informedUsers.length > 2">
                <img *ngIf="informedUsers[2].profileImage" class="w-10 z-30 h-10 border-2 border-white rounded-full dark:border-gray-800" src="{{informedUsers[2].profileImage}}" alt="Verantwortlich">
                <div *ngIf="!informedUsers[2].profileImage" class="relative z-30 inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 border-2 border-white rounded-full dark:border-gray-800 dark:bg-gray-600">
                  <span class="font-medium text-gray-600 dark:text-gray-300">{{informedUsers[2].firstName.charAt(0).toUpperCase()}}{{informedUsers[2].lastName.charAt(0).toUpperCase()}}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="informedUsers.length > 3">
                <a class="flex items-center justify-center w-10 h-10 text-sm font-medium z-40 text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800" href="#">+{{informedUsers!.length - 3}}</a>
              </ng-container>

            </div>
<!--            <ng-container *ngIf="taskForm.get('informedUsers')">-->
<!--              {{taskForm.get('informedUsers').value}}-->
<!--            </ng-container>-->
          </div>

        </div>


      </div>
    </form>

<!--    <div class="pb-12"></div>-->

      <div *ngIf="task.markup.comment" class="pt-4">
        <span class="block text-sm mb-1 font-medium">Kommentare</span>
        <app-task-comments #comments [user]="user" [formGroup]="taskForm" (saveTask)="saveFromComment($event);"></app-task-comments>
      </div>

    <div class="flex flex-row flex-grow justify-end items-center pt-3 sticky bottom-0 pb-3 bg-white gap-4">
      <bic-button [text]="'Speichern'" (onClick)="save()" [disabled]="taskForm.invalid"></bic-button>
      <bic-button [border]="true" [color]="'white'" [text]="'Abbrechen'" [textColor]="'gray-900'" (onClick)="close()"></bic-button>
<!--      <button mat-raised-button class="!bg-white m-0" (click)="close()">Abbrechen</button>-->
<!--      &lt;!&ndash;button mat-raised-button color="warn" *ngIf="task.id" (click)="openDeleteDialog()">Löschen</button&ndash;&gt;-->
<!--      <button mat-raised-button class="m-0" color="primary" [disabled]="taskForm.invalid" (click)="save()">Speichern-->
<!--      </button>-->
    </div>
  </div>


<!--  <mat-menu #menu="matMenu">-->
<!--    <ng-template matMenuContent>-->
<!--      <button mat-menu-item [disabled]="taskForm.invalid" (click)="save()">-->
<!--        Speichern-->
<!--      </button>-->
<!--      <button mat-menu-item *ngIf="task.id" style="color: red" (click)="openDeleteDialog()">-->
<!--        Löschen-->
<!--      </button>-->
<!--      <button mat-menu-item (click)="close()">-->
<!--        Abbrechen-->
<!--      </button>-->
<!--    </ng-template>-->
<!--  </mat-menu>-->
</div>


<!--<div [formGroup]="taskForm">-->
<!--  <bic-input formControlName="filter"></bic-input>-->
<!--  <div formGroupName="testForm" class="z-40">-->
<!--                      <bic-input bicDatepickerInput formControlName="test1" [label]="'Startdatum'"></bic-input>-->
<!--                  <bic-input bicDatepickerInput formControlName="test2" [label]="'Enddatum'"></bic-input>-->
<!--  </div>-->

<!--</div>-->
