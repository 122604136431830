import { Pipe, PipeTransform } from '@angular/core';
import {IFCCheck} from "../api";
import LodTypeEnum = IFCCheck.LodTypeEnum;

@Pipe({
  name: 'lod'
})
export class LodPipe implements PipeTransform {

  transform(value: LodTypeEnum): string {
    switch (value) {
      case "LOD_100":
        return "LOI 100";
      case "LOD_200":
        return "LOI 200";
      case "LOD_300":
        return "LOI 300";
      case "LOD_400":
        return "LOI 400";
      case "LOD_500":
        return "LOI 500"
    }
  }

}
