<!--<div appDragAndDrop [(hovered)]="dragAndDropHovered" class="h-full flex flex-col justify-start items-start gap-4 p-2" (dropped)="upload($event, true)">-->
<div class="flex flex-col h-full">
  <mat-toolbar-row class="flex flex-row justify-between items-end pb-4 w-full">
    <div class="flex flex-row justify-start items-center gap-2 m-0 w-[50%]">
<!--      <button mat-raised-button [matMenuTriggerFor]="uploadMenu" color="basic" [disabled]="!permissionService.hasProjectPrivilege('PROJECT_FILE_EDIT')">-->
<!--        <mat-icon svgIcon="upload" class="scale-150" ></mat-icon>-->
<!--        Hochladen-->
<!--      </button>-->
      <bic-button [matMenuTriggerFor]="uploadMenu" [color]="'white'" [textColor]="'gray-900'" [border]="true" [disabled]="!permissionService.hasProjectPrivilege('PROJECT_FILE_EDIT')" [routerLink]="" [text]="'Hochladen'" [iconLeft]="faArrowUpFromBracket" class="mr-2"></bic-button>


      <button mat-raised-button matTooltip="Dateien/Ordner verschieben" [matMenuTriggerFor]="newFolderMenu"
              [disabled]="!permissionService.hasProjectPrivilege('PROJECT_FILE_EDIT')"
              *ngIf="false">
        <mat-icon>add</mat-icon>
        Neuer Ordner
      </button>

<!--      <button *ngIf="!selectedFiles.isEmpty()" (click)="downloadSelectedFiles()" mat-raised-button-->
<!--              [disabled]="!permissionService.hasProjectPrivilege('PROJECT_FILE_EDIT')"-->
<!--              matTooltip="Herunterladen" color="basic">-->
<!--        <mat-icon class="scale-150" svgIcon="download"></mat-icon>-->
<!--        Herunterladen-->
<!--      </button>-->
      <bic-button
        *ngIf="!selectedFiles.isEmpty()" [color]="'white'" [border]="true"
        [textColor]="'gray-900'"
        [disabled]="!permissionService.hasProjectPrivilege('PROJECT_FILE_EDIT')"
        [text]="'Herunterladen'"
        [iconLeft]="faArrowDownToBracket"
        (click)="downloadSelectedFiles()"
        class="mr-2">
      </bic-button>
<!--      <button *ngIf="!this.selectedFiles.isEmpty()" mat-raised-button-->
<!--              [matMenuTriggerFor]="editMenu"-->
<!--              [disabled]="!permissionService.hasProjectPrivilege('PROJECT_FILE_EDIT')"-->
<!--              matTooltip="Bearbeiten" color="basic">-->
<!--        <mat-icon>edit</mat-icon>-->
<!--        Bearbeiten-->
<!--      </button>-->

      <bic-button
        *ngIf="!selectedFiles.isEmpty()"
        [color]="'white'"
        [border]="true"
        [textColor]="'gray-900'"
        [disabled]="!permissionService.hasProjectPrivilege('PROJECT_FILE_EDIT')"
        [text]="'Bearbeiten'"
        [iconLeft]="faEdit"
        [matMenuTriggerFor]="editMenu"
        class="mr-2">
      </bic-button>

    </div>
    <div class="flex flex-row justify-end items-center w-[60%] gap-8">
      <div *ngIf="uploadingDocuments.size > 0 || uploadingPercentage == 100" class="w-1/2 flex flex-col justify-end items-center gap-2">
        <!--        <div *ngIf="uploadingDocuments.size > 0 || uploadingPercentage == 100" class="w-1/2 flex flex-col justify-end items-center gap-2">-->
        <div>Upload: {{uploadingPercentage}}%</div>
        <mat-progress-bar [value]="uploadingPercentage"></mat-progress-bar>
<!--        <div class="text-primary">Bitte verlassen Sie die Seite nicht während Ihre Dateien hochgeladen werden</div>-->
        <div class="text-primary">Während des Hochladens die Seite nicht verlassen</div>
      </div>
      <div class="flex w-1/2">
        <!-- Search field for the files -->
        <div class="flex flex-row justify-end items-center gap-2 w-full dense-1 text-xs">
<!--          <mat-form-field appearance="outline" class="w-full flex flex-row pt-4" subscriptSizing="dynamic">-->
<!--            <mat-label class="pl-2 gap-2">-->
<!--              <fa-icon [icon]="faMagnifyingGlass" matPrefix></fa-icon>-->
<!--              Dateien durchsuchen...</mat-label>-->
<!--            <input matInput [(ngModel)]="searchTerm" (ngModelChange)="searchFiles(searchTerm)">-->
<!--          </mat-form-field>-->
          <bic-input [(ngModel)]="searchTerm" (input)="searchFiles(searchTerm)" [placeholder]="'Dateien durchsuchen...'" [iconStart]="faMagnifyingGlass" [isPassword]="false" [type]="'text'" [showX]="true" class="w-[364px]"> </bic-input>

        </div>
      </div>
    </div>


    <input #fileInput (change)="upload($event)" hidden id="file" multiple type="file" [accept]="folder == 'PLANUNG_UND_BIM' ? '.pdf, .ifc, .xlsx, .xls, .pptx, .ppt, .docx, .doc' : '.pdf, .xlsx, .xls, .pptx, .ppt, .docx, .doc'">


    <mat-menu #uploadMenu="matMenu">
      <button mat-menu-item (click)="fileInput.webkitdirectory=false; fileInput.click();" id="inputButtonFiles">
        Dateien auswählen
      </button>
      <button mat-menu-item (click)="fileInput.webkitdirectory=true; fileInput.click();" id="inputButtonFolders">
        Ordner auswählen
      </button>
    </mat-menu>

    <mat-menu #editMenu="matMenu">
      <div class="flex flex-col justify-center items-center mx-2">
        <button (click)="openMoveFilesDialog()" mat-menu-item >
          <mat-icon>folder_copy</mat-icon>
          Verschieben
        </button>
        <button (click)="openCopyFilesDialog()"  mat-menu-item >
          <fa-icon [icon]="faCopy" class="text-primary mr-3.5" ></fa-icon>
          Kopieren
        </button>
        <button (click)="removeSelectedFilesTrees()" mat-menu-item [disabled]="!permissionService.hasProjectPrivilege('PROJECT_FILE_DELETE')">
          <mat-icon>delete</mat-icon>
          Löschen
        </button>
      </div>
    </mat-menu>

    <mat-menu #newFolderMenu="matMenu">
      <div class="flex flex-row justify-center items-center mx-2">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput #newFolderInput (click)="$event.stopPropagation()">
          <button mat-icon-button matSuffix
                  (click)="createFolder(newFolderInput.value); newFolderInput.value = ''"
                  [disabled]="newFolderInput.value.length == 0">
            <mat-icon>check</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </mat-menu>
  </mat-toolbar-row>


  <div class="flex flex-row w-full pb-4">
    <ngx-file-drop (onFileDrop)="dropped($event)" [dropZoneClassName]="dropZoneClassName" [contentClassName]="contentClassName"
                   (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" [accept]="folder == 'PLANUNG_UND_BIM' ? '.pdf, .ifc, .xlsx, .xls, .pptx, .ppt, .docx, .doc' : '.pdf, .xlsx, .xls, .pptx, .ppt, .docx, .doc'" class="flex w-full">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" >
        <div class="flex flex-col justify-center items-start w-full h-full py-12">

          <div *ngIf="false" class="flex flex-row justify-center items-center w-full">
            <div class="rotate-45 text-5xl mt-6">
              <fa-icon class="text-mid-gray" [icon]="faCircleXmark"></fa-icon>
            </div>
          </div>

          <div *ngIf="!dragged" class="text-black flex-col justify-center items-center w-full h-full pt-4">

            <div class="flex flex-col items-center text-gray-400 text-sm gap-2 pb-2.5">
              <fa-icon [icon]="faSquareDashedCirclePlus" class="text-gray-400 text-[48px]"  ></fa-icon>
              <!--<mat-icon class="scale-150" svgIcon="upload"></mat-icon>-->

              <span>Dateien oder Ordner per Drag & Drop in das Feld ziehen</span>


              <div class="text-center text-xs text-gray-400 pt-2">
                max. Dateigröße: 6 GB
              </div>
              <div *ngIf="folder == 'PLANUNG_UND_BIM'" class="text-center text-xs text-gray-400">
                unterstützte Formate: PDF, IFC, Excel, PowerPoint, Word
              </div>
              <div *ngIf="folder != 'PLANUNG_UND_BIM'" class="text-center text-xs text-gray-400">
                unterstützte Formate: PDF, Excel, PowerPoint, Word
              </div>
            </div>
          </div>
          <div *ngIf="dragged" class="text-black flex flex-row justify-center items-center w-full h-full">
                      Datei loslassen
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>




    <div class="rounded-md shadow-full w-full h-full overflow-y-scroll">
        <app-files-table [projectFiles]="searchFiles(searchTerm)"
                         [isFileListView]="true"
                         (deleteFile)="removeFile($event)"
                         (renameFile)="renameFile($event)"
                         [runningDocumentClassification]="runningDocumentClassifications"
                         [(selectedFiles)]="selectedFiles"
                         [uploadingFiles]="uploadingDocuments"
                         (classifyDocument)="classifyDocument($event)"
                         (breadcrumbNavigationChange)="breadcrumbChanged($event)"
                         [breadcrumbNavigation]="breadcrumbNavigation">
        </app-files-table>

    </div>

</div>
