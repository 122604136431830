<div class="flex flex-col h-full w-full justify-start items-start gap-4 font-medium bg-[#FFF]">
  <app-quality-stepper class="w-full"></app-quality-stepper>

  <div class="flex flex-col w-full px-4">
      <app-overview-report *ngIf="qualityCheck.completed" [checkType]="'Konformitäts-Check'"
                        [completedValue]="totalCompleted()"
                        [documents]="documentsCount"
                        [errorValue]="totalError()"
                        [rules]="totalRules"
                        [warningValue]="totalWarning()"
                       [date]="qualityCheck.checkDate"
                       [whatWasChecked]=" totalRules == 1 ? 'Regel' : 'Regeln'"
                           [showOpenFile]="false"
                           [activateDownload]="true"
                        class="w-full">
  </app-overview-report>
  </div>



  <app-quality-loading *ngIf="!qualityCheck.completed" class="w-full h-full" [check]="qualityCheck" (checkChange)="reloadCheck()"></app-quality-loading>

  <div *ngIf="qualityCheck.completed && numberOfCheckedFiles <= 1" class="flex flex-col w-full h-full justify-end items-center overflow-hidden pb-12">
    <div class="flex flex-col items-center justify-center gap-12 flex-grow">
      <fa-icon class="text-[144px] text-gray-300" [icon]="faBicErrorCloud"></fa-icon>
      <div class="w-[514px] flex flex-col items-center">
        <div class="text-center">Für diese Prüfung wurde nur <span class="font-semibold underline">eine Datei</span> ausgewählt.
        Der Konformitäts-Check ist ein Vergleich für den
          <span class="font-semibold underline">mindestens zwei Dateien</span> ausgewählt bzw. geprüft
          werden müssen.</div>
      </div>
    </div>
    <bic-button [text]="'Quality-Check'" [iconRight]="faChevronRight" (click)="navigateToQuality()"></bic-button>
  </div>

  <div *ngIf="qualityCheck.completed && numberOfCheckedFiles > 1" class="flex flex-col w-full h-full justify-start items-start gap-4 px-4 pb-4 overflow-hidden">
    <div class="flex flex-col w-full rounded-md overflow-y-scroll border border-gray-200 border-b-0">
      <cdk-table [dataSource]="dataSource" multiTemplateDataRows>

<!--        Original  Bauteil, Informationsanforderung w16% und Ergebnis w13%  und Status w7%-->

        <ng-container cdkColumnDef="component">
          <cdk-header-cell *cdkHeaderCellDef
                           class="flex flex-row h-full w-[16%] justify-start items-center font-medium py-4 pl-7">
            BAUTEIL
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let element"
                    class=" flex flex-row h-full w-[16%] justify-start items-start pl-7"> {{ element.component }}
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="requiredInformation">
          <cdk-header-cell *cdkHeaderCellDef
                           class="flex flex-row h-full w-[16%] justify-start items-center font-medium py-4 pl-7">
            ATTRIBUT
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let element"
                    class=" flex flex-row h-full w-[16%] justify-start items-start pl-7"> {{ element.requiredInformation }}
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="state">
          <cdk-header-cell *cdkHeaderCellDef
                           class=" flex flex-row w-[7%] h-full justify-center items-center font-medium py-4">
            PRÜFSTATUS
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="flex flex-row justify-center items-center w-[7%] h-full">

            <ng-container *ngIf="getStatus(element) ==='success'">
              <app-circle-state [state]="CircleState.GREEN" [useAsIcon]="true" [value]="100"
                                class="w-5"></app-circle-state>
            </ng-container>
            <ng-container *ngIf="getStatus(element) ==='warning'">
              <app-circle-state [state]="CircleState.YELLOW" [useAsIcon]="true" [value]="100"
                                class="w-5"></app-circle-state>
            </ng-container>
            <ng-container *ngIf="getStatus(element) === 'error'">
              <app-circle-state [state]="CircleState.RED" [useAsIcon]="true" [value]="100"
                                class="w-5"></app-circle-state>
            </ng-container>
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="result">
          <cdk-header-cell *cdkHeaderCellDef
                           class=" flex flex-row h-full w-[18%] justify-start items-center font-medium py-4">
            ERGEBNIS
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let element"
                    class=" flex flex-row h-full w-[18%] justify-start items-center">
            <ng-container *ngIf="getStatus(element) ==='success'">
              <div class="text-primary rounded bg-[#E6F5F5] px-2.5 py-0.5"> {{ getResponse(element) }}</div>
            </ng-container>
            <ng-container *ngIf="getStatus(element) ==='warning'">
              <div class="text-yellow-500 rounded bg-[#FFEFB073] px-2.5 py-0.5"> {{ getResponse(element) }}</div>
            </ng-container>
            <ng-container *ngIf="getStatus(element) ==='error'">
              <div class="text-red-500 rounded bg-[#FCE2E273] px-2.5 py-0.5"> {{ getResponse(element) }}</div>
            </ng-container>

          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="LV">
          <cdk-header-cell *cdkHeaderCellDef
                           [class]="'flex flex-row h-min justify-center items-center w-[' + widthString + '%] font-medium py-2'">
            <div class="flex flex-row rounded-lg" [bicDropdownTrigger]="lvDropdown">
              <p class="text-gray-900 border border-r-0 border-gray-200 bg-[#FFF] rounded-l-md py-2 px-3">Leistungsverzeichnis</p>
              <div class="bg-gray-100 border border-gray-200 rounded-r-md flex items-center pb-2 px-3">
                <fa-icon class="text-lg flex text-gray-900" [icon]="faSortDown"></fa-icon>
              </div>
              <bic-dropdown #lvDropdown [dropdownPlacement]="'bottom-start'">
                <ng-container *ngFor="let file of filteredLVDocs">
                  <bic-dropdown-element (click)="onLVFileChange(file)">{{filesByExtractionIds.get(file).name}}</bic-dropdown-element>
                </ng-container>
              </bic-dropdown>
            </div>
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let row"
                    [class]="'flex flex-row h-full justify-center items-center w-[' + widthString + '%]'"  [tooltip]="getValues(selectedLVFile, row.requiredInformation, row.response) == '-' ? 'Nicht erforderlich' : ''">
            {{getValues(selectedLVFile, row.requiredInformation, row.response)}}
          </cdk-cell>

        </ng-container>

        <ng-container cdkColumnDef="Room">
          <cdk-header-cell *cdkHeaderCellDef
                           [class]="'flex flex-row h-full justify-center items-center w-[' + widthString + '%] font-medium py-2'">
            <div class="flex flex-row rounded-lg" [bicDropdownTrigger]="rbDropdown">
              <p class="text-gray-900 border border-r-0 border-gray-200 bg-[#FFF] rounded-l-md py-2 px-3">Raumbuch</p>
              <div class="bg-gray-100 border border-gray-200 rounded-r-md flex items-center pb-2 px-3">
                <fa-icon class="text-lg flex text-gray-900" [icon]="faSortDown"></fa-icon>
              </div>
              <bic-dropdown #rbDropdown [dropdownPlacement]="'bottom-start'">
                <ng-container *ngFor="let file of filteredRoomDocs">
                  <bic-dropdown-element (click)="onRoomFileChange(file)">{{filesByExtractionIds.get(file).name}}</bic-dropdown-element>
                </ng-container>
              </bic-dropdown>
            </div>
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let row"
                    [class]="'flex flex-row h-full justify-center items-center  w-[' + widthString + '%] '"  [tooltip]="getValues(selectedLVFile, row.requiredInformation, row.response) == '-' ? 'Nicht erforderlich' : ''">
            {{getValues(selectedRoomFile, row.requiredInformation, row.response)}}
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="ifc">
          <cdk-header-cell *cdkHeaderCellDef
                           [class]="'flex flex-row h-full justify-center items-center  w-[' + widthString + '%] font-medium py-2'">
            <div class="flex flex-row rounded-lg" [bicDropdownTrigger]="ifcDropdown">
              <p class="text-gray-900 border border-r-0 border-gray-200 bg-[#FFF] rounded-l-md py-2 px-3">IFC-Modell</p>
              <div class="bg-gray-100 border border-gray-200 rounded-r-md flex items-center pb-2 px-3">
                <fa-icon class="text-lg flex text-gray-900" [icon]="faSortDown"></fa-icon>
              </div>
              <bic-dropdown #ifcDropdown [dropdownPlacement]="'bottom-start'">
                <ng-container *ngFor="let file of filteredIfcDocs">
                  <bic-dropdown-element class="w-[inherit]" (click)="onIfcFileChange(file)">{{filesByExtractionIds.get(file).name}}</bic-dropdown-element>
                </ng-container>
              </bic-dropdown>
            </div>
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let row"
                    [class]="'flex flex-row h-full justify-center items-center  w-[' + widthString + '%]'"  [tooltip]="getValues(selectedLVFile, row.requiredInformation, row.response) == '-' ? 'Nicht erforderlich' : ''">
            {{ getValues(selectedIfcFile, row.requiredInformation, row.response) }}
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="task">
          <cdk-header-cell *cdkHeaderCellDef
                           class=" flex flex-row h-full w-[2%] justify-center items-center  font-medium py-4"></cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="flex flex-row justify-center items-center h-full w-[2%]">
            <fa-icon [tooltip]="'Aufgabe erstellen'" (click)="createTask(); $event.stopPropagation()" [icon]="faClipboardCheck"
                     class="w-10 hidden-button text-lg"></fa-icon>

          </cdk-cell>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <cdk-cell *cdkCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div [@detailExpand]="expandedElements.includes(element) ? 'expanded' : 'collapsed'"
                 class="flex overflow-hidden w-full">
              <cdk-table [dataSource]="selectedDataSources.get(element)!" class="w-full">

                <ng-container cdkColumnDef="LV">
                  <cdk-cell *cdkCellDef="let element"
                            [class]="' flex flex-row justify-center items-center  w-[' + widthString + '%]  h-full'">
                    <ng-container *ngIf="element.lv.size > 0">
                      <ng-container *ngIf="element.lv.get(selectedLVFile)">
                        {{element.name}}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="element.lv.size == 0">
                      <div class="flex ">
                        {{" -"}}
                      </div></ng-container>
                  </cdk-cell>
                </ng-container>

                <ng-container cdkColumnDef="Room">
                  <cdk-cell *cdkCellDef="let element"
                            [class]="'flex flex-row justify-center items-center text-center  w-[' + widthString + '%]  h-full'">
                    <ng-container *ngIf="element.rb.size > 0">
                      <ng-container *ngIf="element.rb.get(selectedRoomFile)">
                        {{element.name}}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="element.rb.size == 0">{{" -"}}</ng-container>
                  </cdk-cell>
                </ng-container>

<!--   <div class="w-[54%] w-[27%] w-[18%] w-[22%] w-[14%]  w-[44%]"></div>>-->

                <ng-container cdkColumnDef="ifc">
                  <cdk-cell *cdkCellDef="let element"
                            [class]="'flex flex-row justify-center items-center text-center  w-[' + widthString + '%]  h-full'">
                    <ng-container *ngIf="element.ifc.size > 0">
                      <ng-container *ngIf="element.ifc.get(selectedIfcFile)">
                        {{element.name}}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="element.ifc.size == 0">{{" -"}}</ng-container>
                  </cdk-cell>
                </ng-container>

                <ng-container cdkColumnDef="dummy">
                  <cdk-cell *cdkCellDef="let element"
                            class="flex flex-row justify-center items-center m-1 h-full w-[54%]"></cdk-cell>

                </ng-container>

                <ng-container cdkColumnDef="task">
                  <cdk-cell *cdkCellDef="let element"
                            class="flex flex-row justify-center items-center m-1 h-full w-[2%]"></cdk-cell>
                </ng-container>

                <cdk-row *cdkRowDef="let row; columns: displayedSubColumns;"
                         class="flex flex-row justify-start items-center w-full border-b border-b-gray-200 py-2"></cdk-row>
              </cdk-table>
            </div>
          </cdk-cell>
        </ng-container>


        <cdk-header-row *cdkHeaderRowDef="displayedColumns sticky: true;"
                        class="flex flex-row justify-start items-center w-full border-b border-gray-200 bg-gray-50 text-gray-500 text-xs text-semibold">
        </cdk-header-row>
        <cdk-row *cdkRowDef="let row; columns: displayedColumns;"
                 (click)="selectElement(row)"
                 [class]="expandedElements.includes(row) ? 'flex flex-row justify-start items-center w-full border-b border-b-gray-200 hover:bg-gray-100 py-4 hover:cursor-pointer font-medium rounded text-sm' :'flex flex-row justify-center items-center w-full border-b border-b-gray-200 hover:bg-gray-100 py-4 hover:cursor-pointer text-sm'">
        </cdk-row>
        <cdk-row *cdkRowDef="let row; columns: ['expandedDetail']"></cdk-row>
      </cdk-table>
    </div>
  </div>
</div>
