import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {
  BimBaseCheckControllerService,
  FileControllerService,
  ProjectControllerService,
  ProjectFile,
  QualityControllerService
} from "../../../../api";
import {DefaultService} from "../../../../bim-api";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {SelectionModel} from "@angular/cdk/collections";
import {FileTreeNode} from "../../../util/files/files.component";
import {Router} from "@angular/router";
import {PermissionService} from "../../../../services/permission/permission.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LoiConfirmationDialog} from "../loi-check-choose-rules/loi-check-choose-rules.component";
import {faXmark} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-bim-basis-check',
  templateUrl: './bim-basis-check.component.html',
  styleUrls: ['./bim-basis-check.component.scss']
})
export class BimBasisCheckComponent implements OnInit {

  ifcFiles: ProjectFile[] = [];
  result: any[] = [];
  check: any;
  runningDocumentClassification: string[] = [];
  selectedIFCFiles: ProjectFile[] = []//SelectionModel<FileTreeNode> = new SelectionModel<FileTreeNode>(true, []);

  columns =['Name', 'Größe(MB)', 'Zuletzt geändert']
  column = ['name', 'size', 'lastModified']
  colTypes =['text', 'filesize', 'date']

  constructor(private projectControllerService: ProjectControllerService,
              private qualityControllerService: QualityControllerService,
              private bimApiService: DefaultService,
              public permissionService: PermissionService,
              private bimBaseCheckControllerService: BimBaseCheckControllerService,
              private fileControllerService: FileControllerService,
              private stateConfigService: StateConfigService,
              private router: Router,
              public dialog: MatDialog
              ) {
  }
  protected readonly faFolderOpen = faFolderOpen;

  startBimCheck(){
    const dialogRef = this.dialog.open(BimConfirmationDialog, {
      panelClass: 'rounded-corners-dialog',
      width: '538px',
      data: {ifcFiles: this.selectedIFCFiles},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let projectFile = this.selectedIFCFiles.map(file => file as ProjectFile)[0];
        this.bimBaseCheckControllerService.createCheck1(projectFile, this.stateConfigService.getProjectId()).subscribe(check => {
          this.bimApiService.baseCheck({
            url: projectFile.link,
          }).subscribe((res) => {
            check.uuid = res.instanceId;
            this.bimBaseCheckControllerService.updateCheck3(check).subscribe(() => {
              this.router.navigate(['reports'])
            });
          })
        });
      }
    });
  }

  get canCheck() {
    return this.stateConfigService.canCheckBimBase();
  }
  protected readonly faChevronRight = faChevronRight;
  protected readonly faChevronLeft = faChevronLeft;

  ngOnInit(): void {
    this.fileControllerService.getIfcFiles(this.stateConfigService.selectedProject.id ?? 0).subscribe(files => {
      this.ifcFiles = files;
    })
    this.stateConfigService.runningClassificationsChange.subscribe((runningClassifications) => {
      this.runningDocumentClassification = runningClassifications;
    });

    //this.selectedIFCFiles = new SelectionModel<FileTreeNode>(true, this.ifcFiles.filter(file => this.check!.projectFiles!['IFC'].map(file => file.id).includes(file.id)).map(file => file as FileTreeNode));
  }

  getSelectedIFCFiles() {
    return this.selectedIFCFiles.map(node => ({name: node.name || '', size: this.calculateSize(node.size!), lastModified: node.lastModified, id: node.id}));
  }

  removeSelectedIFCFile(file: any){
    const index = this.selectedIFCFiles.findIndex(selectedFiles=>file.id==selectedFiles.id)
    if (index !== -1) {
      this.selectedIFCFiles.splice(index, 1);
    }

  }

  get selectedFiles(){
    return this.selectedIFCFiles
  }

  set selectedFiles(files: any){
    this.selectedIFCFiles = files
  }

  private calculateSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size < kb) {
      return `${size} Bytes`;
    } else if (size < mb) {
      return `${(size / kb).toFixed(2)} KB`;
    } else if (size < gb) {
      return `${(size / mb).toFixed(2)} MB`;
    } else {
      return `${(size / gb).toFixed(2)} GB`;
    }
  }

  protected readonly faXmark = faXmark;
}
@Component({
  selector: 'app-bim-basis-check-confirmation-dialog',
  templateUrl: './bim-basis-check-confirmation-dialog.html',
  styleUrls: ['./bim-basis-check.component.scss'],
})
export class BimConfirmationDialog implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { ifcFiles : ProjectFile[] }, private dialogRef: MatDialogRef<BimConfirmationDialog>) {
  }

  ngOnInit() {
  }

}

