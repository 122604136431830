<div class="flex flex-col justify-between overflow-hidden gap-2">
  <div class="font-medium text-gray-400 pl-6 text-xs">{{ stateService.selectedProject ? "Organisation & Projekt" : "Organisation & Projekte" }}</div>

<!--  Organisation-->
  <div class="flex flex-row" (mouseenter)="hoveringOrga = true" (mouseleave)="hoveringOrga = false" >
    <div class="flex w-full pl-4 pr-3.5">
      <div
        class="flex flex-row justify-between items-center py-1 pl-1.5 w-full hover:bg-[rgba(255,255,255,0.35)] cursor-pointer hover:rounded-lg h-[36px]">

<!--        [matTooltip]="'Organisation'"-->
        <div (click)="routeToOrganisations()" class="flex flex-row items-center w-full gap-[6px] break-all line-clamp-1" style="font-size: 16px;">
<!--      Icon-->
          <div class="flex flex-row w-[36px] justify-center items-center">
            <div class="flex justify-center items-center text-center h-8 w-8"
              [ngClass]="{'rounded-full bg-[rgba(255,255,255,0.35)]': !hoveringOrga}">
              <fa-icon [icon]="faUsers" class="text-base text-white"></fa-icon>
            </div>
          </div>
<!--      Text-->
          <div class="line-clamp-1 text-base text-white">
              {{ stateService.selectedOrganisation ? stateService.selectedOrganisation?.name : (stateService.isPersonal ? "Persönlich" : "Organisationsübersicht") }}
          </div>
        </div>
        <div *ngIf="hoveringOrga && stateService.selectedOrganisation?.name" class="flex flex-row px-2 py-1.5" [routerLink]="['organisations', stateService.selectedOrganisation.id!, 'settings']" tooltip="Organisationseinstellungen" tooltipDirection="right">
          <fa-icon [icon]="faGear" class=" text-white"></fa-icon>
        </div>
      </div>
    </div>
  </div>


<!--  Projekt-->
  <div class="flex flex-row" (mouseenter)="hovering = true" (mouseleave)="hovering = false" >
    <div class="flex w-full px-4">
      <div class="flex flex-row justify-between items-center py-1 pl-1.5 w-full hover:bg-[rgba(255,255,255,0.35)] cursor-pointer hover:rounded-lg h-[36px]">

<!--        [matTooltip]="stateService.selectedProject?.name ? 'Projekt' : ''"-->
        <div (click)="stateService.selectProject(undefined)" class="flex flex-row items-center w-full gap-[6px] break-all line-clamp-1" style="font-size: 16px;">
<!--      Icon-->
          <div class="flex flex-row w-[36px] justify-center items-center">
            <div class="flex justify-center items-center text-center h-8 w-8"
              [ngClass]="{'rounded-full bg-[rgba(255,255,255,0.35)]': !hovering}">
              <fa-icon [icon]="faBuilding" class="text-base text-white w-8"></fa-icon>
            </div>
          </div>
          <p class="line-clamp-1">
            {{ stateService.selectedProject?.name ?? "Projektübersicht" }}
          </p>
        </div>
        <div *ngIf="hovering && stateService.selectedProject?.name" class="flex flex-row px-2 py-1.5" [routerLink]="['projects', stateService.selectedProject.id!, 'settings']" tooltip="Projekteinstellungen" tooltipDirection="top">
          <fa-icon [icon]="faGear" class=" text-white"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
