import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { FileTreeNode} from "../../files.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {StateConfigService} from "../../../../../services/state/state-config.service";
import {SimpleCheck} from 'src/app/api';
import LphEnum = SimpleCheck.LphEnum;
import {FilesComponent} from "../../../../files/files.component";
import {ProjectFile, ProjectFileData} from "src/app/api";
import FolderEnum = ProjectFile.FolderEnum;



@Component({
  selector: 'app-move-files-dialog',
  templateUrl: './move-files-dialog.component.html',
  styleUrls: ['./move-files-dialog.component.scss']
})
export class MoveFilesDialogComponent implements OnInit {

  constructor(public stateConfigService: StateConfigService, @Inject(MAT_DIALOG_DATA) public data: { selectedFiles: FileTreeNode[] }, public dialogRef: MatDialogRef<MoveFilesDialogComponent>) {

  }


  selectedLph: any = null;
  foldersForSelectedLph: string[] = [];
  selectedFiles: FileTreeNode[] = [];
  selectedFolder!: FolderEnum | undefined;

  ngOnInit(): void {
    this.selectedFiles = this.data.selectedFiles;
  }

  isIfcFilePresent(): boolean {
    return this.selectedFiles.some(file => file && file.name && file.name.endsWith('.ifc'));
  }


  isValidLph(lphValue: LphEnum): boolean {
    if (lphValue === 'GENERAL' || lphValue === 'PLANS') {
      return false;
    }

    if (this.isIfcFilePresent()) {
      return lphValue !== LphEnum.LPH1 && lphValue !== LphEnum.LPH6;
    }
    return true;
  }


  getFoldersFor(lph: LphEnum): Set<FolderEnum> {
    if (this.isIfcFilePresent()) {
      return new Set([FolderEnum.PLANUNGUNDBIM]);
    }
    switch (lph) {
      case LphEnum.LPH1:
        return FilesComponent.lph1;
      case LphEnum.LPH2:
        return FilesComponent.lph2;
      case LphEnum.LPH3:
        return FilesComponent.lph3;
      case LphEnum.LPH4:
        return FilesComponent.lph4;
      case LphEnum.LPH5:
        return FilesComponent.lph5;
      case LphEnum.LPH6:
        return FilesComponent.lph6;
      default:
        return new Set();
    }
  }

  onLphChange(lph: any) {
    this.selectedLph = lph;
    this.foldersForSelectedLph = lph.folders;
    this.selectedFolder = undefined;
  }

  get lph(): LphEnum {
    return this.stateConfigService.selectedServicePhase;
  }

  moveFiles() {
    this.selectedFiles.forEach(file => {
      file.folder = this.selectedFolder;
      file.lph = this.selectedLph;
    });
    this.dialogRef.close(this.selectedFiles);
  }
}
