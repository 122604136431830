import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Task} from "../../../../api";
import StateEnum = Task.StateEnum;
import {CdkDragDrop, CdkDropList} from "@angular/cdk/drag-drop";
import {TaskStatePipe} from "../../../../pipes/task-state.pipe";

@Component({
  selector: 'app-task-board-column',
  templateUrl: './task-board-column.component.html',
  styleUrls: ['./task-board-column.component.scss']
})
export class TaskBoardColumnComponent implements OnInit{

  @Input() state!: Task.StateEnum;
  @Input() tasks!: Task[];
  @Output() taskDropped = new EventEmitter<CdkDragDrop<Task[], any>>();

  constructor() { }

  ngOnInit(): void {
  }
}
