import { Component, OnInit } from '@angular/core';
import {CompletenessCheck, ProjectControllerService, ProjectFile} from "../../../api";
import LphEnum = CompletenessCheck.LphEnum;
import {DrawerContentService} from "../../../services/drawer-content.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-lv',
  templateUrl: './lv.component.html',
  styleUrls: ['./lv.component.scss']
})
export class LvComponent implements OnInit {

  lph = LphEnum.LPH1;
  checkRunning = false;
  selectedFiles: ProjectFile[] = [];

  constructor(private projectControllerService: ProjectControllerService, private dialog: MatDialog, private drawer: DrawerContentService) { }

  ngOnInit(): void {
  }

  startConcreteCheck() {
    let documentName = this.selectedFiles[0].name ?? "";

    // if(documentName.toLowerCase().endsWith(".ifc")) {
    //   this.projectControllerService.checkConcreteIfc(0, documentName).subscribe((res) => {
    //     this.drawer.open(ProjectBetonCheckComponent, {
    //       data: {
    //         result: res,
    //         fileUrl: this.selectedFiles[0].link ?? "",
    //       },
    //       large: true,
    //       fixedSize: true,
    //       title: 'Betonprüfung'
    //     })
    //   })
    // }else if(documentName.toLowerCase().endsWith(".pdf")) {
    //   this.projectControllerService.checkConcreteLV(0, documentName).subscribe((res) => {
    //     this.drawer.open(ProjectBetonCheckComponent, {
    //       data: {
    //         result: res,
    //         ifcUrl: '',
    //       },
    //       large: true,
    //       fixedSize: true,
    //       title: 'Betonprüfung'
    //     })
    //   })
    // }
  }

  updateSelectedFiles(files: ProjectFile[]) {
    this.selectedFiles = files.filter(file => !file.isFolder)
  }

}
