<div
  class="flex flex-row justify-around items-center w-full h-full bg-gradient-to-br from-secondary from-0% via-[#042E3F] via-15% to-primary to-90% min-w-[912px]">
  <div class="w-[800px] h-[640px] rounded-md flex flex-row items-center"
       [class]="isRegister ? 'justify-start' : !isRegister && !isVerify && !isPasswordReset ? 'justify-end' : 'justify-center'">

    <ng-container *ngIf="isDeletedAccount">
      <div class="flex flex-row justify-center items-center w-full relative">
        <div class="flex flex-col justify-between items-center z-40 w-1/2 h-full bg-white rounded-md relative pt-4">
          <div class="flex w-full h-1/2 flex-col justify-center items-center gap-1">
            <img class="w-72 h-72" height="16" ngSrc="assets/logo_gradient.svg" width="16">
            <a class="text-[40px] font-bold font-brand text-secondary -mt-6">planBIC</a>
          </div>


          <div class="flex flex-col justify-center items-center pt-8 gap-3">
            <div class="text-xl font-medium text-secondary">Schade, dass Sie uns Verlassen!</div>
            <div class="text-[18px]/5 text-center text-secondary">Ihr Konto wurde deaktiviert<br>und die Löschung
              beauftragt.
            </div>
          </div>

          <div class="flex flex-col justify-center items-center pt-10">
            <div class="text-center text-primary">Hinweis:</div>
            <div class="text-center text-base/5">Die endgültige Löschung Ihrer Nutzerdaten erfolgt nach<br>
              Ablauf einer Frist von 30 Tagen. In diesem Zeitraum haben<br>
              Sie die Möglichkeit die Löschung zu widerrufen. Wenden<br>
              Sie sich dazu an unser <a class="text-secondary underline"
                                        href="mailto:support@thinkbic.de">Support-Team</a>.
            </div>
          </div>

          <div class="flex flex-col justify-center items-center pt-10 pb-12">
            <div (click)="isDeletedAccount = false" class="text-base text-primary font-medium cursor-pointer">Zurück
              zum Login
            </div>
          </div>

        </div>
      </div>
    </ng-container>

<!--    <ng-container *ngIf="!isRegister && !isVerify && !isPasswordReset && !isDeletedAccount && !isMfa">-->

    <ng-container *ngIf="isMfa">
      <div class="flex flex-col justify-center items-start w-[800px] h-[640px] bg-secondary rounded-md absolute">
        <div class="flex flex-col justify-start w-[400px] px-8 pt-32 pb-16 h-full gap-4">
          <div class="flex flex-col justify-center items-start gap-4">
            <div class="text-white text-xl py-2">Authentifizierung</div>
            <div class="text-white text-sm py-2">Bitte geben Sie den 6-stelligen Code ein</div>
          </div>

          <form (ngSubmit)="verifyMfa()" [formGroup]="mfaForm"
                class="flex flex-col w-full h-full justify-start items-start gap-6">

            <div class="flex flex-col justify-between h-full w-full">
              <div class="flex flex-col w-full">
                <!--              <label for="code" class="block text-sm mb-1 font-medium text-white">2FA Code</label>-->
                <div class="flex flex-row justify-between w-full pb-6" id="code">
                  <input #otpInput formControlName="code1" type="number" maxlength="1" class="text-center text-sm rounded-lg bg-gray-700 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'text-white focus:ring-white focus:border-white border-gray-600'" (input)="moveToNext($event, 0)" (keydown)="moveToNext($event, 0)">
                  <input #otpInput formControlName="code2" type="number" maxlength="1" class="text-center text-sm rounded-lg bg-gray-700 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'text-white focus:ring-white focus:border-white border-gray-600'" (input)="moveToNext($event, 1)" (keydown)="moveToNext($event, 1)">
                  <input #otpInput formControlName="code3" type="number" maxlength="1" class="text-center text-sm rounded-lg bg-gray-700 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'text-white focus:ring-white focus:border-white border-gray-600'" (input)="moveToNext($event, 2)" (keydown)="moveToNext($event, 2)">
                  <input #otpInput formControlName="code4" type="number" maxlength="1" class="text-center text-sm rounded-lg bg-gray-700 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'text-white focus:ring-white focus:border-white border-gray-600'" (input)="moveToNext($event, 3)" (keydown)="moveToNext($event, 3)">
                  <input #otpInput formControlName="code5" type="number" maxlength="1" class="text-center text-sm rounded-lg bg-gray-700 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'text-white focus:ring-white focus:border-white border-gray-600'" (input)="moveToNext($event, 4)" (keydown)="moveToNext($event, 4)">
                  <input #otpInput formControlName="code6" type="number" maxlength="1" class="text-center text-sm rounded-lg bg-gray-700 placeholder-gray-400 block w-10 p-2.5" [class]="mfaError ? 'text-error ring-error border-error' : 'text-white focus:ring-white focus:border-white border-gray-600'" (input)="moveToNext($event, 5)" (keydown)="moveToNext($event, 5)">
                </div>
                <ng-container *ngIf="loginForm.get('code1')?.touched && loginForm.get('code1')?.invalid">
                  <span class="text-xs text-error pt-1" *ngIf="loginForm.get('code1')?.errors?.required">Sie müssen den 2FA Code eingeben.</span>
                  <span class="text-xs text-error pt-1" *ngIf="loginForm.get('code1')?.errors?.pattern">Der Code muss 6 Ziffern lang sein.</span>
                </ng-container>
                <ng-container *ngIf="mfaError">
                  <span class="text-xs text-error pt-1">Der eingegebene Code ist nicht korrekt.</span>
                </ng-container>
                <span class="text-sm text-primary items-start" (click)="resendMail()" *ngIf="response!.isMailMfa" mat-dialog-close>Code nicht erhalten?</span>
                <span class="text-sm text-primary items-start" (click)="resendMail()" *ngIf="!response!.isMailMfa" mat-dialog-close>Keinen Zugriff auf die App?</span>
              </div>

              <div class="flex flex-col w-full">
                <button
                  class="w-full h-[44px] mt-6 bg-primary text-white rounded-lg"
                  type="submit">Anmelden
                </button>
              </div>
            </div>
          </form>
        </div>


      </div>

      <div class="flex flex-col justify-between items-center z-40 w-[400px] h-full bg-white rounded-r-md relative gap-1"
        [class]="window.history.state['fromRegister'] ? 'animate-login' : '' ">
        <div class="flex w-full pt-24 flex-col justify-center items-center gap-1">
          <img class="w-[200px] h-[160px]" height="16" ngSrc="assets/logo_gradient.svg" width="16">
          <a class="text-[40px] font-bold font-brand text-secondary -mt-6">planBIC</a>
        </div>


        <div class="flex flex-col justify-end items-center pb-16">
          <a [routerLink]="['/login']" [state]="{'fromLogin': true, 'fromRegister': false}"
             class="text-sm font-medium text-primary">Zurück zum Login</a>
        </div>
      </div>

    </ng-container>


<!--    <ng-container *ngIf="isMfa">-->
<ng-container *ngIf="!isRegister && !isVerify && !isPasswordReset && !isDeletedAccount && !isMfa">
    <div class="flex flex-col justify-center items-start w-[800px] h-[640px] bg-secondary rounded-md absolute">
      <div class="flex flex-col justify-start w-[400px] px-8 pt-32 pb-16 h-full">
        <div class="flex flex-col justify-center items-start gap-4 mb-4">
          <div class="text-white text-xl py-2">Willkommen!</div>
          <div class="text-white text-sm py-2">Bitte geben Sie Ihre Anmeldedaten ein.</div>
        </div>

        <form (ngSubmit)="login()" [formGroup]="loginForm"
              class="flex flex-col w-full justify-between items-start h-full">

          <div class="flex flex-col w-full h-full justify-start">
            <div class="flex flex-col w-full" [class]=" (loginForm.get('mail')?.touched && loginForm.get('mail')?.invalid)  || notRegisteredError? 'pb-0.5' : 'pb-6'">
              <bic-input [dark]="true" [formGroup]="loginForm" formControlName="mail" (blur)="loginForm.get('mail')?.markAsTouched()" (focus)="loginForm.get('mail')?.markAsUntouched()"
                         [label]="'E-Mail'" [formControlName]="'mail'" [type]="'email'" [placeholder]="'E-Mail Adresse'" [errorCondition]="loginForm.get('mail')?.touched && loginForm.get('mail')?.invalid"></bic-input>
              <ng-container *ngIf="loginForm.get('mail')?.touched && loginForm.get('mail')?.invalid">
                <span class="text-xs text-error pt-1"
                      *ngIf="loginForm.get('mail')?.errors?.required">Sie müssen Ihre E-Mail eingeben.</span>
                <span class="text-xs text-error pt-1"
                      *ngIf="loginForm.get('mail')?.errors?.email">Bitte geben Sie eine valide E-Mail-Adresse an!</span>
              </ng-container>
              <ng-container *ngIf="notRegisteredError">
              <span *ngIf="loginForm.get('mail')?.value !== '' && !(loginForm.get('mail')?.touched && loginForm.get('mail')?.invalid)" class="text-xs text-error pt-1 w-full">Zu dieser E-Mail Adresse wurde
              noch kein Account erstellt!</span>
              </ng-container>

            </div>

            <div class="flex flex-col w-full" [class]="loginForm.get('password')?.touched && loginForm.get('password')?.invalid ? 'pb-0.5' : 'pb-6'">
              <bic-input [dark]="true" [isPassword]="true" [formGroup]="loginForm" formControlName="password" (blur)="loginForm.get('password')?.markAsTouched()" (focus)="loginForm.get('password')?.markAsUntouched()"
                         [(showPassword)]="showPassword" [label]="'Passwort'" [formControlName]="'password'" [type]="'password'" [placeholder]="'Passwort'" [errorCondition]="loginForm.get('password')?.touched && loginForm.get('password')?.invalid"></bic-input>
              <ng-container *ngIf="loginForm.get('password')?.touched && loginForm.get('password')?.invalid">
                <span class="text-xs text-error pt-1" *ngIf="loginForm.get('password')?.errors?.required">Sie müssen Ihr Passwort eingeben.</span>
              </ng-container>

            </div>

            <!--            </mat-form-field>-->

            <span *ngIf="loginError" class="text-white text-xs text-center w-full">Passwort oder E-Mail inkorrekt!</span>
            <span *ngIf="verificationError" class="text-white text-xs text-center w-full">Ihr Account wurde noch nicht
              verifiziert!</span>
            <div class="flex flex-row">
                <span [routerLink]="['/resetPassword']" class="text-sm text-primary items-start hover:cursor-pointer">Passwort
                vergessen?</span>
            </div>
          </div>


          <div class="flex flex-col w-full">
            <button
              class="w-full h-[44px] mt-6 bg-primary text-white rounded-lg"
              type="submit">Anmelden
            </button>
          </div>


        </form>
      </div>


    </div>

    <div
      class="flex flex-col justify-between items-center z-40 w-[400px] h-full bg-white rounded-r-md relative gap-1"
      [class]="window.history.state['fromRegister'] ? 'animate-login' : '' ">
      <div class="flex w-full pt-24 flex-col justify-center items-center gap-1">
        <img class="w-[200px] h-[160px]" height="16" ngSrc="assets/logo_gradient.svg" width="16">
        <a class="text-[40px] font-bold font-brand text-secondary -mt-6">planBIC</a>
      </div>
      <div class="flex flex-col justify-end items-center pb-16">
        <a class="text-sm text-secondary">Noch nicht registriert?</a>
        <a [routerLink]="['/register']" [state]="{'fromLogin': true, 'fromRegister': false}"
           class="text-sm font-medium text-primary" >Konto erstellen</a>
      </div>
    </div>
  </ng-container>

    <ng-container *ngIf="isRegister">
<!--    <ng-container *ngIf="isVerify && !authService.isLoggedIn()">-->

      <div class="flex flex-col justify-center items-end w-[800px] h-[640px] bg-secondary rounded-md absolute">
        <div class="flex flex-col w-[400px] justify-between px-8 py-16 h-full">
          <div class="flex flex-col h-full">
            <div class="flex flex-col justify-start items-start">
              <div class="text-white text-xl pt-10 pb-6">Jetzt Registrieren!</div>
            </div>
            <div class="flex flex-col w-full">
              <form [formGroup]="registerForm" class="flex flex-col w-full justify-start items-center">

                <div class="flex flex-col w-full" [class]="registerForm.get('firstName')?.touched && registerForm.get('firstName')?.invalid ? 'pb-0.5' : 'pb-6'">
                  <bic-input [dark]="true" [formGroup]="registerForm" formControlName="firstName" (blur)="registerForm.get('firstName')?.markAsTouched()" (focus)="registerForm.get('firstName')?.markAsUntouched()"
                             [label]="'Vorname'" [formControlName]="'firstName'" [type]="'text'" [placeholder]="'Vorname'" [errorCondition]="registerForm.get('firstName')?.touched && registerForm.get('firstName')?.invalid"></bic-input>
                  <ng-container *ngIf="registerForm.get('firstName')?.touched">
                  <span *ngIf="registerForm.get('firstName')?.errors?.required; else invalidNameError" class="text-xs text-error pt-1">
                    Sie müssen Ihren Vornamen angeben!
                  </span>
                    <ng-template #invalidNameError>
                    <span *ngIf="registerForm.get('firstName')?.errors?.invalidName" class="text-xs text-error pt-1">
                      Der Vorname muss mindestens 2 Zeichen lang sein.
                    </span>
                    </ng-template>
                  </ng-container>

                </div>

                <div class="flex flex-col w-full" [class]="registerForm.get('lastName')?.touched && registerForm.get('lastName')?.invalid ? 'pb-0.5' : 'pb-6'">
                  <bic-input [dark]="true" [formGroup]="registerForm" formControlName="lastName" (blur)="registerForm.get('lastName')?.markAsTouched()" (focus)="registerForm.get('lastName')?.markAsUntouched()"
                             [label]="'Nachname'" [formControlName]="'lastName'" [type]="'text'" [placeholder]="'Nachname'" [errorCondition]="registerForm.get('lastName')?.touched && registerForm.get('lastName')?.invalid"></bic-input>
                  <ng-container *ngIf="registerForm.get('lastName')?.touched">
                 <span *ngIf="registerForm.get('lastName')?.errors?.required; else invalidNameError" class="text-xs text-error pt-1">
                    Sie müssen Ihren Nachnamen angeben!
                  </span>
                    <ng-template #invalidNameError>
                    <span *ngIf="registerForm.get('lastName')?.errors?.invalidName" class="text-xs text-error pt-1">
                      Der Nachname muss mindestens 2 Zeichen lang sein.
                    </span>
                    </ng-template>
                  </ng-container>
                </div>

                <div class="flex flex-col w-full" [class]="registerForm.get('mail')?.touched && registerForm.get('mail')?.invalid ? 'pb-0.5' : 'pb-6'">
                  <bic-input [dark]="true" [formGroup]="registerForm" formControlName="mail" (blur)="registerForm.get('mail')?.markAsTouched()" (focus)="registerForm.get('mail')?.markAsUntouched()"
                             [label]="'E-Mail'" [formControlName]="'mail'" [type]="'email'" [placeholder]="'E-Mail Adresse'" [errorCondition]="registerForm.get('mail')?.touched && registerForm.get('mail')?.invalid"></bic-input>

<!--                  <label for="mail_registration" class="block mb-1 text-sm font-medium" [class]="registerForm.get('mail')?.touched && registerForm.get('mail')?.invalid ? 'text-error' : 'text-white' ">E-Mail</label>-->
<!--                  <input (blur)="registerForm.get('mail')?.markAsTouched()" (focus)="registerForm.get('mail')?.markAsUntouched()" formControlName="mail" type="email" id="mail_registration" [class]="registerForm.get('mail')?.touched && registerForm.get('mail')?.invalid ? 'text-error ring-error border-error' : 'text-white focus:ring-white focus:border-white border-gray-600'"  class="text-sm rounded-lg bg-gray-700  placeholder-gray-400  block w-full p-2.5" placeholder="E-Mail Adresse">-->
                  <ng-container *ngIf="registerForm.get('mail')?.touched && registerForm.get('mail')?.invalid">
                  <span
                    *ngIf="registerForm.get('mail')?.errors?.required" class="text-xs text-error pt-1">Sie müssen Ihre E-Mail eingeben.</span>
                    <span
                      *ngIf="registerForm.get('mail')?.errors?.email" class="text-xs text-error pt-1">Bitte geben Sie eine valide E-Mail-Adresse an!</span>
                  </ng-container>
                </div>
              </form>


              <div class="flex flex-col h-full w-full gap-2">
                <div class="flex flex-row justify-start items-center w-full gap-4 text-sm font-medium">
                  <input (change)="registerForm.controls.termsAndConditions.setValue(!registerForm.controls.termsAndConditions.value)" [checked]="registerForm.controls.termsAndConditions.value == true" id="default-checkbox" type="checkbox" value="" class="w-4 h-4 bg-gray-700 border-gray-600 rounded focus:ring-white focus:border-red ring-none focus:ring-0 checked:bg-gray-700">
                  <div >
                    <a class="text-white">Ich akzeptiere die </a>
                    <a href="https://thinkbic.de/agb/" target="_blank" class="underline text-white">AGB</a>
                    <a class="text-white"> und </a>
                    <a class="underline text-white" href="https://thinkbic.de/datenschutzerklaerung/" target="_blank">Datenschutz&shy;bestimmungen.</a>
                  </div>
                </div>
<!--                <span *ngIf="alreadyRegisteredError" class="text-xs text-error">Mit dieser E-Mail Adresse wurde bereits ein Account erstellt!</span>-->
                <span *ngIf="alreadyRegisteredError" class="text-xs text-error">Es existiert bereits ein Account mit dieser E-Mail Adresse!</span>
              </div>
            </div>
          </div>

          <div class="flex flex-row">
            <button (click)="register()"
                    class="w-full h-[44px] bg-primary text-white rounded-lg"
                    type="submit">Registrieren
            </button>
          </div>




        </div>
      </div>

      <div class="flex flex-col justify-between items-center z-40 w-[400px] h-full bg-white rounded-l-md relative gap-1"
        [class]="window.history.state['fromLogin'] ? 'animate-register' : '' ">
        <div class="flex w-full pt-24 flex-col justify-center items-center gap-1">
          <img class="w-[200px] h-[160px]" height="16" ngSrc="assets/logo_gradient.svg" width="16">
          <a class="text-[40px] font-bold font-brand text-secondary -mt-6">planBIC</a>
        </div>

        <div class="flex flex-col justify-center items-center pb-16">
          <a class="text-sm text-secondary">Bereits registriert?</a>
          <a [routerLink]="['/login']" [state]="{'fromRegister': true, 'fromLogin': false}"
             class="text-sm font-medium text-primary">Anmelden</a>
        </div>
      </div>

    </ng-container>

<!--        <ng-container *ngIf="isRegister">-->
    <ng-container *ngIf="isVerify && !authService.isLoggedIn()">

          <div class="flex flex-row justify-center items-end w-[800px] h-[640px] bg-secondary rounded-md absolute">
            <div class="flex flex-col justify-between items-center w-[400px] h-full bg-white rounded-l-md animate-login relative">
              <div class="flex w-full pt-24 flex-col justify-center items-center gap-1">
                <img class="w-[200px] h-[160px]" height="16" ngSrc="assets/logo_gradient.svg" width="16">
                <a class="text-[40px] font-bold font-brand text-secondary -mt-6">planBIC</a>
              </div>
            </div>

            <div class="flex flex-col justify-start items-center w-[400px] h-full bg-secondary rounded-r-md px-8 py-16 ">

              <form (ngSubmit)="setPassword()" [formGroup]="passwordForm"
                    class="flex flex-col w-full justify-between items-start gap-4 h-full">
                <div class="flex flex-col w-full items-start">
                  <span class="text-white text-xl py-2">Passwort</span>
                  <span class="text-white text-base pb-2">Wählen Sie ein Passwort für Ihr Konto</span>

                </div>

                <div class="flex flex-col w-full h-full justify-between">
                  <div class="flex flex-col w-full items-start">

                    <div class="flex flex-col w-full" [class]="passwordForm.get('password')?.touched && passwordForm.get('password')?.invalid ? 'pb-0.5' : 'pb-6'">
                      <bic-input [dark]="true" [isPassword]="true" [formGroup]="passwordForm" formControlName="password" (blur)="passwordForm.get('password')?.markAsTouched()" (focus)="passwordForm.get('password')?.markAsUntouched()"
                                 [(showPassword)]="showPassword" [label]="'Passwort'" [formControlName]="'password'" [type]="'password'" [placeholder]="'Passwort'" [errorCondition]="passwordForm.get('password')?.touched && passwordForm.get('password')?.invalid"></bic-input>
                      <ng-container *ngIf="passwordForm.get('password')?.touched && passwordForm.get('password')?.invalid">
                        <span class="text-xs text-error pt-1" *ngIf="passwordForm.get('password')?.errors?.required">Sie müssen Ihr Passwort eingeben.</span>
                        <ng-container *ngIf="!passwordForm.get('password')?.errors?.required">
                          <span class="text-xs text-error pt-1" *ngIf="!passwordForm.get('password')?.valid">Das Passwort entspricht nicht den Sicherheitsvorgaben.</span>
                        </ng-container>

                      </ng-container>

                    </div>

                    <div class="flex flex-col w-full" [class]="passwordForm.get('passwordRepeat')?.touched && passwordForm.get('passwordRepeat')?.invalid ? 'pb-0.5' : 'pb-6'">
                      <bic-input [dark]="true" [isPassword]="true" [formGroup]="passwordForm" formControlName="password" (blur)="passwordForm.get('passwordRepeat')?.markAsTouched()" (focus)="passwordForm.get('passwordRepeat')?.markAsUntouched()"
                                 [(showPassword)]="showPasswordRepeat" [label]="'Passwort wiederholen'" [formControlName]="'passwordRepeat'" [type]="'password'" [placeholder]="'Passwort'" [errorCondition]="passwordForm.get('passwordRepeat')?.touched && passwordForm.get('passwordRepeat')?.invalid"></bic-input>
                      <ng-container *ngIf="passwordForm.get('passwordRepeat')?.touched && passwordForm.get('passwordRepeat')?.invalid">
                        <span class="text-xs text-error pt-1" *ngIf="passwordForm.get('passwordRepeat')?.errors?.required">Sie müssen Ihr Passwort wiederholen.</span>
                        <ng-container *ngIf="passwordForm.get('passwordRepeat')?.errors?.matchValue != null">
                          <span class="text-xs text-error pt-1" *ngIf="!passwordForm.get('passwordRepeat')?.errors?.matchValue">Die Passwörter stimmen nicht überein.</span>
                        </ng-container>
                      </ng-container>

                    </div>

                    <div class="flex flex-col justify-start items-start w-[90%] pb-4">
                      <span class="text-white text-sm font-semibold pb-1">Vorgaben Passwortsicherheit</span>
                      <div class="flex flex-row items-center pl-4">
                        <ul class="text-white text-xs list-disc ">
                          <li [class]=" testLength(passwordForm.get('password')?.value) ? 'text-primary' : 'text-white'">Enthält mindestens 8 Zeichen</li>
                          <li [class]=" testUpperCase(passwordForm.get('password')?.value) && testLowerCase(passwordForm.get('password')?.value) ? 'text-primary' : 'text-white'">Ein Groß- und Kleinbuchstabe</li>
                          <li [class]=" testNumeric(passwordForm.get('password')?.value) ? 'text-primary' : 'text-white'">Eine Zahl</li>
                          <li [class]=" testSpecialCharacter(passwordForm.get('password')?.value) ? 'text-primary' : 'text-white'">Ein Sonderzeichen (!?&%=-.:*)</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <button
                    class="w-full h-[44px] mt-6 bg-primary text-white rounded-lg"
                    type="submit">Passwort bestätigen
                  </button>

                </div>

              </form>
            </div>


          </div>



    </ng-container>

    <ng-container *ngIf="isPasswordReset">

      <div class="flex flex-row justify-center items-start w-[800px] h-[640px] bg-secondary rounded-md absolute">
        <div class="flex flex-col justify-between items-start w-[400px] px-8 py-16 h-full">

          <form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm"
                class="flex flex-col w-full justify-between items-center h-full">
            <div class="flex flex-col h-full gap-4">
              <div class="flex flex-col justify-center items-start gap-4 mb-4">
                <h1 *ngIf="!isNewVerification" class="text-white text-xl">Passwort zurücksetzen</h1>
                <h1 *ngIf="isNewVerification" class="text-white text-xl">Bestätigungsemail erneuern</h1>
                <span class="text-white text-base">Bitte geben Sie die E-Mail für Ihr Konto ein.</span>
              </div>

              <div class="flex flex-col" >
                <div class="flex flex-col w-full" [class]="resetPasswordForm.get('mail')?.touched && resetPasswordForm.get('mail')?.invalid ? 'pb-0.5' : 'pb-6'">
                  <bic-input [dark]="true" [formGroup]="resetPasswordForm" formControlName="mail" (blur)="resetPasswordForm.get('mail')?.markAsTouched()" (focus)="resetPasswordForm.get('mail')?.markAsUntouched()"
                             [label]="'E-Mail'" [formControlName]="'mail'" [type]="'email'" [placeholder]="'E-Mail Adresse'" [errorCondition]="resetPasswordForm.get('mail')?.touched && resetPasswordForm.get('mail')?.invalid"></bic-input>
                  <ng-container *ngIf="resetPasswordForm.get('mail')?.touched && resetPasswordForm.get('mail')?.invalid">
                  <span
                    *ngIf="resetPasswordForm.get('mail')?.errors?.required" class="text-xs text-error pt-1">Sie müssen Ihre E-Mail eingeben.</span>
                    <span
                      *ngIf="resetPasswordForm.get('mail')?.errors?.email" class="text-xs text-error pt-1">Bitte geben Sie eine valide E-Mail-Adresse an!</span>
                  </ng-container>
                </div>
                <div class="flex flex-row">
                  <span [routerLink]="['/login']" class="text-sm text-primary items-start hover:cursor-pointer" mat-dialog-close>Zurück zum Login</span>
                </div>


              </div>
            </div>

            <div class="flex w-full">
              <button *ngIf="!isNewVerification" class="w-full h-[44px] mt-6 bg-primary text-white rounded-lg"
                      type="submit">Passwort
                zurücksetzen
              </button>
              <button *ngIf="isNewVerification"  class="w-full h-[44px] mt-6 bg-primary text-white rounded-lg"
                      type="submit">Email erneut
                senden
              </button>
            </div>

          </form>
        </div>


        <div
          class="flex flex-col justify-between items-center z-40 w-[400px] h-full bg-white rounded-r-md relative gap-1"
          [class]="window.history.state['fromRegister'] ? 'animate-login' : '' ">
          <div class="flex w-full pt-24 flex-col justify-center items-center gap-1">
            <img class="w-[200px] h-[160px]" height="16" ngSrc="assets/logo_gradient.svg" width="16">
            <a class="text-[40px] font-bold font-brand text-secondary -mt-6">planBIC</a>
          </div>


          <div class="flex flex-col justify-end items-center pb-16">
            <a class="text-sm text-secondary">Noch nicht registriert?</a>
            <a [routerLink]="['/register']" [state]="{'fromLogin': true, 'fromRegister': false}"
               class="text-sm font-medium text-primary" mat-dialog-close>Konto erstellen</a>
          </div>
        </div>

      </div>



    </ng-container>

  </div>

</div>
