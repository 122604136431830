/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Contingent } from './contingent';
import { FeatureConfig } from './featureConfig';
import { RoleResponse } from './roleResponse';

export interface StateConfig { 
    projectRunningChecksData?: Array<string>;
    projectRunningIfcChecksData?: Array<string>;
    projectRunningQualityChecksData?: Array<string>;
    projectRunningBimBaseChecksData?: Array<string>;
    projectRunningSWMChecksData?: Array<string>;
    runningClassifications?: Array<string>;
    featureConfigs?: Array<FeatureConfig>;
    contingent?: Contingent;
    organisations?: { [key: string]: RoleResponse; };
    projects?: { [key: string]: RoleResponse; };
    stage?: StateConfig.StageEnum;
}
export namespace StateConfig {
    export type StageEnum = 'dev' | 'beta' | 'live';
    export const StageEnum = {
        Dev: 'dev' as StageEnum,
        Beta: 'beta' as StageEnum,
        Live: 'live' as StageEnum
    };
}