import {Component, OnInit} from '@angular/core';
import {
  FileControllerService, IfcControllerService, LOICheckRule, LOIRequest,
  ProjectControllerService,
  ProjectFile,
  QualityControllerService,
  SimpleCheck
} from "../../api";
import {DrawerContentService} from "../../services/drawer-content.service";
import LphEnum = SimpleCheck.LphEnum;
import {StateConfigService} from "../../services/state/state-config.service";
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DefaultService} from "../../bim-api";
import {IFCCheck} from "../../api";
import LodTypeEnum = IFCCheck.LodTypeEnum;
import {faEdit} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-ifcCheckReport',
  templateUrl: './ifc-check.component.html',
  styleUrls: ['./ifc-check.component.scss']
})
export class IfcCheckComponent implements OnInit {

  lph = LphEnum.LPH1;
  checkRunning = false;
  selectedFiles: ProjectFile[] = [];
  ifcFiles: ProjectFile[] = [];

  lodType: typeof LodTypeEnum = LodTypeEnum;

  loiRules: LOICheckRule[] = []

  checkForm!: UntypedFormGroup;
  result: any;

  constructor(private projectControllerService: ProjectControllerService,
              private qualityControllerService: QualityControllerService,
              private bimApiService: DefaultService,
              private fileControllerService: FileControllerService,
              private stateConfigService: StateConfigService,
              private ifcControllerService: IfcControllerService,
              private router: Router,
              private dialog: MatDialog, private drawer: DrawerContentService) {
  }

  ngOnInit(): void {
    this.checkForm = new UntypedFormGroup({
      check: new UntypedFormControl(undefined, Validators.required),
      lodType: new UntypedFormControl(undefined),
      projectFile: new UntypedFormControl(undefined, Validators.required),
    });

    this.ifcControllerService.getRules2(this.stateConfigService.getProjectId()).subscribe(rules => {
      this.loiRules = rules;
    });

    this.fileControllerService.getIfcFiles(this.stateConfigService.selectedProject.id ?? 0).subscribe(files => {
      this.ifcFiles = files;
    })
  }

  startloiCheck() {
    this.checkRunning = true;
    const lodType = this.lod(this.checkForm.value["lodType"]);

    if (lodType != "") {
      this.bimApiService.checkLod({type: lodType, file: this.checkForm.value["projectFile"].link}).subscribe(
        (res) => {
          const loiRequest: LOIRequest = {
            lodType: this.checkForm.value["lodType"],
            projectFile: this.checkForm.value["projectFile"],
            uuid: res.instanceId
          }

          this.ifcControllerService.createCheck(loiRequest, this.stateConfigService.getProjectId()).subscribe((res) => {
            this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'reports']);
          });
        });
    } else {

      const rules: any = JSON.parse(this.checkForm.value["check"].loiRule).rules;
      this.bimApiService.checkLoi({file: this.checkForm.value["projectFile"].link, rules: rules}).subscribe(
        (res) => {

          const loiRequest: LOIRequest = {
            rule: this.checkForm.value["check"],
            lodType: undefined,
            projectFile: this.checkForm.value["projectFile"],
            uuid: res.instanceId
          }

          this.ifcControllerService.createCheck(loiRequest, this.stateConfigService.getProjectId()).subscribe((res) => {
            this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'reports']);
          });

        });
    }
  }

  lod(value: LodTypeEnum) {
    switch (value) {
      case LodTypeEnum._100:
        return "100";
      case LodTypeEnum._200:
        return "200";
      case LodTypeEnum._300:
        return "300";
      case LodTypeEnum._400:
        return "400";
      case LodTypeEnum._500:
        return "500";
      default:
        return "";
    }
  }

  protected readonly faEdit = faEdit;
}
