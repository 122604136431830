import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {
  faBars,
  faBorderAll, faBuilding,
  faCoffee,
  faGear,
  faNetworkWired,
  faProjectDiagram,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {LoadingService} from "../../../../services/loading/loading.service";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {OrganisationPopupComponent} from "../../../organisation/organisation-popup/organisation-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {light} from "@fortawesome/fontawesome-svg-core/import.macro";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  hovering: boolean = false;
  hoveringOrga: boolean = false;

  constructor(private router: Router, public loadingService: LoadingService, public stateService: StateConfigService,
  private dialog: MatDialog) {}

  ngOnInit(): void {

  }

  protected readonly faBars = faBars;
  protected readonly faProjectDiagram = faProjectDiagram;
  protected readonly faGear = faGear;
  protected readonly faBorderAll = faBorderAll;

  openNewOrganisation(){
    let ref = this.dialog.open(OrganisationPopupComponent, {
      panelClass: 'rounded-corners-dialog',
      data: {
        title: "Neues Unternehmen"
      },
      width: '60%',
      autoFocus: false
    });
    ref.afterClosed().subscribe(user => {
      this.stateService.update();
    });
  }

  test(entering: boolean){
    this.hovering = entering;
  }

  openEditOrganisation(organisationId: number){
    let ref = this.dialog.open(OrganisationPopupComponent, {
      panelClass: 'rounded-corners-dialog',
      data: {
        organisationId: organisationId,
        title: "Unternehmenseinstellungen"
      },
      width: '60%',
    });

    ref.afterClosed().subscribe(user => {
      this.stateService.update();
    });
  }

    routeToOrganisations(){
    this.stateService.selectProject(undefined, false);
    this.stateService.selectOrganisation(undefined, true, false, true);
  }

  protected readonly faNetworkWired = faNetworkWired;
  protected readonly faBuilding = faBuilding;
  protected readonly faUsers = faUsers;
}
