import {NgModule} from '@angular/core';
import {AuthComponent} from "./auth.component";
import {MaterialModule} from "../../material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {SalutationPipe} from "../../pipes/salutation.pipe";
import {LegalFormsPipe} from "../../pipes/legal-forms.pipe";
import {AddressInputModule} from "../util/./addressInput/address-input.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BicUiComponentsModule} from "@buildinginformationcloud/bic-ui-components";


@NgModule({
  declarations: [
    AuthComponent,
    SalutationPipe,
    LegalFormsPipe
  ],
  imports: [
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    AddressInputModule,
    NgOptimizedImage,
    FontAwesomeModule,
    BicUiComponentsModule,
  ],
  providers: [],
  bootstrap: [AuthComponent],
  exports: [
    SalutationPipe,
    LegalFormsPipe
  ]
})
export class AuthModule {
}
