import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Organisation, OrganisationControllerService, RoleResponse, User} from "../../api";
import {MatSort} from "@angular/material/sort";
import {Subject} from "rxjs";
import {PermissionService} from "../../services/permission/permission.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import PrivilegesEnum = RoleResponse.PrivilegesEnum;
import {Router} from "@angular/router";
import {StateConfigService} from "../../services/state/state-config.service";


@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.scss']
})
export class OrganisationComponent implements OnInit {

  @Input() user!: User;

  organisations: Organisation[] = [];

  selectedOrganisation!: Organisation | undefined;

  extendSettings: boolean = false;

  clearEvent: Subject<void> = new Subject<void>();
  organisationChangedEvent: Subject<Organisation> = new Subject<Organisation>();

  displayedColumns: string[] = ['name', 'role'];
  dataSource!: MatTableDataSource<Organisation>;

  leaveOrganisationBoolean: boolean = false;
  deleteOrganisationBoolean: boolean = false;
  deleteAndLeaveOrganisationBoolean: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('orgTbSort') orgTbSort = new MatSort();

  existsOtherAdminMap: Map<Organisation, boolean> = new Map<Organisation, boolean>();

  constructor(public stateService: StateConfigService,
              private router: Router, private organisationControllerService: OrganisationControllerService,
              protected permissionService: PermissionService) {
  }

  ngOnInit(): void {
    this.organisationControllerService.getOrganisations().subscribe(organisation => {
      this.organisations = organisation;
      for (let org of this.organisations) {
        if (this.permissionService.hasOrganisationPrivilege(PrivilegesEnum.ORGANISATIONSETTINGS, org.id!)) {
          this.organisationControllerService.existsOtherAdminInOrganisation(org.id!).subscribe(exists => {
            this.existsOtherAdminMap.set(org, exists);
          });
        } else {
          this.existsOtherAdminMap.set(org, false);
        }
      }
      this.dataSource = new MatTableDataSource(this.organisations);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }


  newOrgaPressed() {
    this.extendSettings = true
    this.clearEvent.next();
  }

  navigateToOrganisationSettings(org: Organisation) {
    this.router.navigate(['/organisations', org.id, 'settings']);
    this.stateService.selectOrganisation(org.id, false)
  }

  applyUpdatedOrganisation(org: Organisation | undefined) {
    if (org != undefined) {
      let newOrga: boolean = true;
      for (let i = 0; i < this.organisations.length; i++) {
        if (this.organisations[i].id == org.id) {
          this.organisations[i] = org;
          this.dataSource = new MatTableDataSource(this.organisations);
          this.clearEvent.next();
          newOrga = false;
          break;

        }
      }
      if (newOrga) {
        this.organisations.push(org);
        this.dataSource = new MatTableDataSource(this.organisations);
        this.refreshOrganisations()
        this.clearEvent.next();
      }
    }
    this.selectedOrganisation = undefined;
    this.extendSettings = false;
  }

  refreshOrganisations() {
    this.organisationControllerService.getOrganisations().subscribe(organisation => {
      this.organisations = organisation;
      this.dataSource = new MatTableDataSource(this.organisations);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.ngOnInit();
    });

  }

  openLeaveOrganisation(org: Organisation) {
    this.leaveOrganisationBoolean = true;
    this.selectedOrganisation = org;
  }

  leaveOrganisation(org: Organisation) {
    this.clearEvent.next();
    this.extendSettings = false;
    if (org.id != undefined) {
      this.organisationControllerService.leaveOrganisation(org.id).subscribe(() => {
        this.organisations = this.organisations.filter(obj => obj !== org);
        this.dataSource = new MatTableDataSource(this.organisations);
      });
    }
    this.leaveOrganisationBoolean = false;
  }

  openDeleteOrganisation(org: Organisation) {
    this.deleteOrganisationBoolean = true;
    this.selectedOrganisation = org;
  }

  openDeleteAndLeaveOrganisation(org: Organisation) {
    this.deleteAndLeaveOrganisationBoolean = true;
    this.selectedOrganisation = org;
  }

  deleteOrganisation(org: Organisation) {
    this.clearEvent.next();
    this.extendSettings = false;
    if (org.id != undefined) {
      this.organisationControllerService.deleteOrganisation(org.id).subscribe(() => {
        this.organisations = this.organisations.filter(obj => obj !== org);
        this.dataSource = new MatTableDataSource(this.organisations);
      });
    }
    this.deleteAndLeaveOrganisationBoolean = false;
  }


  protected readonly PrivilegesEnum = PrivilegesEnum;
}

