<div *ngIf="dataSource && dataSource.length > 0">

  <div class="flex flex-row justify-start gap-2 items-start">
    <button mat-raised-button color="primary" (click)="createNewTask()">Aufgabe erstellen</button>
    <button mat-raised-button color="primary" (click)="importBCFClicked.emit()">BCF importieren</button>
  </div>
  <div class="bg-white rounded-md shadow-full overflow-hidden overflow-y-scroll max-h-[80vh] mt-4 mx-3">
    <table [dataSource]="dataSource" matSort mat-table #tasksTbSort="matSort">

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>ID</th>
        <td *matCellDef="let element" mat-cell> {{element.markup?.topic?.atGuid}} </td>
      </ng-container>


      <!-- title Column -->
      <ng-container matColumnDef="title">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>Titel</th>
        <td *matCellDef="let element" mat-cell >
        <div class="line-clamp-2 pr-6">
          {{element.markup?.topic?.title}}
        </div>
        </td>
      </ng-container>


      <ng-container matColumnDef="prio">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>Priorität</th>
        <td *matCellDef="let element" mat-cell>
          <div
            [ngClass]="{'high': element.priority === priorityEnum.HIGH, 'normal': element.priority === priorityEnum.NORMAL, 'low' : element.priority === priorityEnum.LOW}">
            {{element.priority | priority }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="projectName">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>Leistungsphase</th>
        <td *matCellDef="let element" mat-cell>{{element.lph}}</td>
      </ng-container>

      <!-- deadline Column -->
      <ng-container matColumnDef="deadline">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>Deadline</th>
        <td *matCellDef="let element" mat-cell> {{element.markup?.topic?.dueDate | date: "dd.MM.yyyy"}} </td>
      </ng-container>

      <!-- state Column -->
      <ng-container matColumnDef="state">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>Status</th>
        <td *matCellDef="let element" mat-cell> {{element.state | taskState}} </td>
      </ng-container>

      <!-- people Column -->
      <ng-container matColumnDef="people">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Bearbeiter*in</th>
        <td *matCellDef="let element" mat-cell>
          {{element.markup?.topic?.creationUser?.firstName}} {{element.markup?.topic?.creationUser?.lastName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Erstellt von</th>
        <td *matCellDef="let element" mat-cell>
          <ng-container *ngIf="element.markup?.topic?.creationUser">
            {{element.markup?.topic?.creationUser?.firstName}} {{element.markup?.topic?.creationUser?.lastName}}
          </ng-container>
          <ng-container *ngIf="!element.markup?.topic?.creationUser">
            {{element.markup?.topic?.creationAuthor}}
          </ng-container>
        </td>
      </ng-container>

      <!-- create Column -->
      <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>Erstellt</th>
        <td *matCellDef="let element" mat-cell> {{element.markup?.topic?.creationDate | date: "dd.MM.yyyy - HH:mm"}} </td>
      </ng-container>

      <!-- state Column -->
      <ng-container matColumnDef="project">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>Projekt</th>
        <td *matCellDef="let element" mat-cell> {{element.projectName}} </td>
      </ng-container>



      <!-- actions Column -->
      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell class="!text-end">
          <button mat-icon-button class="pr-2" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" [matMenuTriggerData]="{task: element}">
            <fa-icon class="text-primary text-xl" [icon]="faEllipsisVertical"></fa-icon>
          </button>
          <!--button mat-icon-button matTooltip="Öffnen" (click)="selectTask(element)">
            <mat-icon>open_in_new</mat-icon>
          </button-->
        </td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row (dblclick)="selectTask(row)"></tr>
    </table>
    <!--mat-paginator class="mat-paginator-sticky" [length]="task_length" [pageSize]="default_page_size"
                   [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator-->
  </div>


</div>

<div *ngIf="dataSource && dataSource.length == 0" class="welcome flex flex-col justify-start items-center"
     id="welcome-screen">
  <img id="cloud" class="w-[330px] h-[330px]" ngSrc="assets/logo_gradient.svg" height="16" width="16" alt="">
  <button mat-raised-button class="h-[60px] w-[330px] text-base" color="primary" (click)="createNewTask()">Erstellen Sie Ihre erste Aufgabe</button>
  <div class="pt-4"></div>
  <button mat-raised-button class="h-[60px] w-[330px] text-base" color="primary" (click)="importBCFClicked.emit()">BCF importieren</button>
</div>


<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-task="task">
    <button mat-menu-item (click)="selectTask(task)">
      Bearbeiten
    </button>
    <button mat-menu-item (click)="exportBCFClicked.emit(task)">
      BCF-Export
    </button>
    <button mat-menu-item style="color: red" (click)="openDeleteDialog(task)">
      Löschen
    </button>
  </ng-template>
</mat-menu>
