<div class="flex flex-col z-40 h-full w-full bg-white shadow-full rounded-[10px] p-4">

    <div class="text-2xl font-extrabold p-8 text-center">Wilkommen bei der BIC!</div>

    <div class="w-full h-1/2 p-4 rounded-[10px]">

<img src="assets/BIC-Report-Background.png" alt="Photo 1" class="w-full h-full object-cover">
<!--      <img src="path/to/photo2.jpg" alt="Photo 2" class="w-full mb-4 rounded-md shadow-lg">-->

    </div>
    <div class="flex w-full mt-auto justify-between">
      <div>Hi</div>
      <button mat-raised-button class="bg-white m-0" mat-dialog-close>Schließen</button>
    </div>
</div>



