<div class="border-2 border-gray-200 rounded-md flex flex-row justify-between items-star w-full px-8 py-6">
  <div class="flex flex-row justify-between items-center w-full">

    <div *ngIf="!isHoaiCheck" class="text-primary text-lg font-medium">
      {{ checkType }}
    </div>

    <div *ngIf="isHoaiCheck" class="text-black text-lg font-medium">
      {{ checkType }}
    </div>

    <div class="flex flex-row justify-start items-center text-lg font-normal gap-6">
      {{ date | date: 'dd.MM.y' }}
<!--      <div class="text-gray-400 text-xs">Zeitpunkt der<br>Prüfung</div>-->
      <div class="flex flex-row gap-4">
      {{ date | date: 'HH:mm' }}
    </div>
    </div>

    <!--    coming soon: waiting for backend-->
    <!--    <div *ngIf="!isHoaiCheck" class="flex flex-row justify-start items-center text-2xl gap-4">-->
    <!--      {{ documents }}-->
    <!--      <div class="text-gray-400 text-xs">geprüfte<br>Dokumente</div>-->
    <!--    </div>-->

    <div class="flex flex-row justify-start items-center text-lg gap-4">
      {{ rules }}
      <div class="text-gray-500 text-xs">geprüfte<br>{{whatWasChecked}}</div>
    </div>

    <div class="flex flex-row gap-8">
      <div class="flex flex-row justify-center items-center gap-8">
        <app-circle-state [tooltip]="'Erfüllt'" [compact]="true" [state]="CircleState.GREEN" [value]="isFinite(completedValue) ? Math.floor(completedValue)  : 0"
                          class="w-20"></app-circle-state>
        <app-circle-state [tooltip]="'Teilweise erfüllt'" [compact]="true" [state]="CircleState.YELLOW" [value]="isFinite(warningValue) ? Math.floor(warningValue) : 0"
                          class="w-20"></app-circle-state>
        <app-circle-state [tooltip]="'Nicht erfüllt'" [compact]="true" [state]="CircleState.RED" [value]="isFinite(errorValue) ? Math.floor(errorValue) : 0"
                          class="w-20"></app-circle-state>
      </div>

      <div class="flex flex-row justify-center items-center" *ngIf="questionMarkText != ''" [tooltip]="questionMarkText">
        <fa-icon [icon]="faCircleQuestion" class="text-primary text-2xl"
                 #infoIcon></fa-icon>
      </div>




    </div>


<div class="flex flex-row gap-2">
    <div *ngIf="!showOpenFile" class="w-[151px]"></div>
    <bic-button *ngIf="showOpenFile" [text]="'Datei öffnen'" [iconLeft]="faArrowUpRightFromSquare" [border]="true" [color]="'#FFF'" [textColor]="'gray-900'"
                [disabled]="fileDeactivated || !fileId || fileId == ''" (click)="!fileDeactivated ? openFile(): undefined" [opacityHover]="false">
    </bic-button>

    <bic-button [text]="'Herunterladen'" [iconLeft]="faArrowDownToBracket" [border]="true" [color]="'#FFF'" [textColor]="'gray-900'"
                [disabled]="true || activateDownload" (click)="downloadPressed()" [opacityHover]="false">
    </bic-button>
</div>

    <!--div class="flex flex-col justify-center items-center mr-4 gap-4">
      <div class="w-full"   [matTooltip]="'Bald Verfügbar'" *ngIf="!activateDownload">
        <button class="flex flex-row items-center justify-start w-full" disabled mat-raised-button>
          <mat-icon class="scale-150" svgIcon="download"></mat-icon>
          <div>Herunterladen</div>
        </button>
      </div>

      <div class="w-full"   [matTooltip]="'Bericht herunterladen'" *ngIf="activateDownload">
        <button class="flex flex-row items-center justify-start w-full"  mat-raised-button (click)="downloadPressed()">
          <mat-icon class="scale-150" svgIcon="download"></mat-icon>
          <div>Herunterladen</div>
        </button>
      </div>

      <div class="w-full">
        <button class="flex flex-row items-center justify-start w-full" mat-raised-button matTooltip="Datei öffnen" [disabled]="fileDeactivated || !fileId || fileId == ''" (click)="openFile()">
          <mat-icon svgIcon="open" class="scale-75"></mat-icon>
          <div class="flex">Datei öffnen</div>
        </button>
      </div>
    </div-->

  </div>
</div>
