<div class="p-6 overflow-auto bg-white rounded-2xl shadow-item">
  <div class="flex flex-col justify-start items-center gap-4">

    <!-- Headline warning -->
    <div class="text-error font-medium">Möchten Sie dieses Projekt wirklich löschen?</div>

    <!-- Explanation text -->
    <div class="break-all px-3">Das Projekt "{{data.project.name}}" wird bei Bestätigung gelöscht.</div>

    <!-- Hint Area -->
    <div class="flex flex-col justify-start items-center px-3">
      <!-- Hint headline -->
      <div class="text-primary">Hinweis:</div>

      <!-- Hint text -->
      <div class="text-center">Zur Bestätigung halten Sie den Löschen-Knopf für <span class="text-primary">3 Sekunden</span> gedrückt.<br>
        Bei weiteren Fragen wenden Sie sich an unser Support-Team.
      </div>
    </div>

    <!-- Button Area -->
    <div class="flex flex-row justify-between w-full items-center pt-2">
      <button class="w-[20%]" color="white" mat-dialog-close mat-raised-button>
        Abbrechen
      </button>
      <button class="w-[20%]" (holdTime)="holdHandler($event)" appHoldable color="warn" id="deleteButton" mat-raised-button
              [matTooltip]="'Gedrückt halten'">
        <span>Löschen</span>
      </button>
    </div>

  </div>
</div>
