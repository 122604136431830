import {Component, Inject, OnInit} from '@angular/core';
import {
  faCircleXmark,
  faSquare,
  faSquareCheck
} from "@fortawesome/free-regular-svg-icons";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { FormControl, FormGroup } from '@angular/forms';
import {
  AuthControllerService, Organisation,
  OrganisationControllerService,
  Privilege,
  ProjectControllerService,
  Role
} from "../../../api";
import {SelectionModel} from "@angular/cdk/collections";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {data} from "autoprefixer";
import {StateConfigService} from "../../../services/state/state-config.service";

@Component({
  selector: 'app-custom-role-detail',
  templateUrl: './custom-role-detail.component.html',
  styleUrls: ['./custom-role-detail.component.scss']
})
export class CustomRoleDetailComponent implements OnInit{

  form = new FormGroup({
    roleTitle: new FormControl()
  });

  isExistingRole = false;
  isProject = false;

  privileges: Privilege[] = []
  role: Role | undefined

  selectedPrivileges: SelectionModel<String> = new SelectionModel<String>(true, []);



  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faSquare = faSquare;
  protected readonly faSquareCheck = faSquareCheck;
  constructor(@Inject(MAT_DIALOG_DATA) public data: { role?: Role, isProject: boolean, organisation: Organisation},
              private authControllerService: AuthControllerService,
              private projectControllerService: ProjectControllerService,
              private organisationControllerService: OrganisationControllerService,
              private stateService: StateConfigService,
              private dialogRef: MatDialogRef<CustomRoleDetailComponent>) {}

  ngOnInit() {
    this.isProject = this.data.isProject;

    this.isExistingRole = false
    if(this.data.role) {
      this.role = this.data.role
      this.form.controls.roleTitle.setValue(this.role?.name)


      this.role?.privileges?.forEach(privilege => {
        this.selectedPrivileges.select(privilege.id!);
      });

      this.isExistingRole = true
    }



    this.authControllerService.privileges().subscribe((privileges) => {
        this.privileges = privileges;
        console.log(this.privileges)
      }
    )
  }


  saveChanges() {
    const role: Role = {
      id: this.role?.id,
      name: this.form.controls.roleTitle.value,
      privileges: this.privileges.filter(privilege => this.selectedPrivileges.isSelected(privilege.id!))
    }


    if(this.isProject && !this.isExistingRole) {
      this.projectControllerService.createRole(role, this.stateService.getProjectId()).subscribe(( role) => {
        this.dialogRef.close(role);
      })
    }else if(this.isProject && this.isExistingRole) {
      this.projectControllerService.updateRole(role, this.stateService.getProjectId()).subscribe(() => {
        this.dialogRef.close();
      })
    }else if(!this.isProject && !this.isExistingRole) {
      this.organisationControllerService.createRole1(role, this.data.organisation.id!).subscribe((role) => {
        this.dialogRef.close(role);
      })
    }else if(!this.isProject && this.isExistingRole) {
      this.organisationControllerService.updateRole1(role, this.data.organisation.id!).subscribe(() => {
        this.dialogRef.close();
      })
    }
  }

  get allPrivileges() {
    if (this.isProject) {
      return this.projectPrivileges;
    }else {
      return this.organisationPrivileges;
    }
  }

  get projectPrivileges() {
    return this.privileges.filter(privilege => privilege.type == "PROJECT");
  }

  get organisationPrivileges() {
    return this.privileges.filter(privilege => privilege.type == "ORGANISATION");
  }

  get customPrivileges() {
    return this.privileges.filter(privilege => privilege.type == "CUSTOM");

  }

  checkInputFilled() {
    return (!this.form.controls.roleTitle.value || this.form.controls.roleTitle.value == "") ? false : true;
  }

  close() {
    this.dialogRef.close();
  }

  selectPrivilege(event: MatCheckboxChange, privilege: Privilege) {
    this.selectedPrivileges.toggle(privilege.id!);
  }

  isSelected(privilege: Privilege) {
    return this.selectedPrivileges.isSelected(privilege.id!);
  }

  get hasUserManagementPrivilege() {
    if (this.isProject) {
      return this.selectedPrivileges.selected.some(privilege => privilege == "PROJECT_USER_MANAGEMENT")
    }else {
      return this.selectedPrivileges.selected.some(privilege => privilege == "ORGANISATION_USER_MANAGEMENT")
    }
  }
}
