import {AfterViewInit, Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {StateConfigService} from "../../../services/state/state-config.service";
import {Parameter} from 'src/app/quality-api';
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {initFlowbite} from "flowbite";
import {ProjectControllerService} from "../../../api";

@Component({
  selector: 'app-qualityCheck-settings',
  templateUrl: './quality-settings.component.html',
  styleUrls: ['./quality-settings.component.scss']
})
export class QualitySettingsComponent implements OnInit, AfterViewInit, OnChanges {

  taskForm!: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: {parameters: Parameter[]}, private dialogRef: MatDialogRef<QualitySettingsComponent>, public stateConfigService: StateConfigService, public projectControllerService: ProjectControllerService) { }

  values = {};

  saveSettings: boolean = false

  ngOnInit(): void {
    this.setupForm();
    this.values = JSON.parse(this.stateConfigService.selectedProject.qualitySettings ?? "{}");
    this.taskForm.patchValue(this.values);
  }
  ngAfterViewInit() {
    initFlowbite()
  }
  ngOnChanges(changes: SimpleChanges) {
    initFlowbite()
  }

  get parameters(): Parameter[] {
    return this.data.parameters ?? [];
  }

  setupForm() {
    const config: any = {};
    this.parameters.forEach(param => {
      if (param.value)
        if (param.value == 'usage') {
          config[param.value] = [this.stateConfigService.selectedProject.projectKind, Validators.required];
        } else {
          config[param.value] = ['', Validators.required];
        }
    });
    this.values = config
    this.taskForm = this.formBuilder.group(config);
  }

  check() {
    if(this.saveSettings){
      this.saveProjectSettings()
    }
    this.dialogRef.close(this.taskForm.value);
  }

  saveProjectSettings(){
    let project = this.stateConfigService.selectedProject
    project.qualitySettings = JSON.stringify({...this.values, ...this.taskForm.value})
    let settings = JSON.parse(project.qualitySettings)
    if('usage' in settings){
      project.projectKind = settings['usage' as keyof typeof settings]
    }

    this.projectControllerService.updateProject(project,this.stateConfigService.getProjectId()).subscribe(project=>{
        this.stateConfigService.selectedProject = project
      }
    )
  }

  setCheckbox(){
    this.saveSettings=!this.saveSettings
  }

  protected readonly Parameter = Parameter;
  protected readonly faChevronDown = faChevronDown;
}
