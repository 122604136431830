<div class="flex flex-col justify-start items-center relative overflow-hidden p-4">
  <form [formGroup]="checkForm" class="flex flex-row w-full justify-start items-start gap-2">
    <mat-form-field appearance="outline">
      <mat-select formControlName="check" placeholder="Prüfregelsatz" #check>
        <mat-option [value]="'BIC'">BIC-Standard</mat-option>
        <mat-option *ngFor="let rule of loiRules" [value]="rule" class="w-full">
          <div class="flex flex-row justify-between items-center">
            {{rule.name}}
          </div>
        </mat-option>
        <mat-option routerLink="custom">
            <mat-icon>add</mat-icon>
            <span>Neu</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="check.value == 'BIC'">
      <mat-select formControlName="lodType" placeholder="LOI">
        <mat-option [value]="lodType._100">LOI 100</mat-option>
        <mat-option [value]="lodType._200">LOI 200</mat-option>
        <mat-option [value]="lodType._300">LOI 300</mat-option>
        <mat-option [value]="lodType._400">LOI 400</mat-option>
        <mat-option [value]="lodType._500">LOI 500</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>IFC-Datei</mat-label>
      <mat-select formControlName="projectFile">
        <mat-option *ngFor="let ifc of ifcFiles" [value]="ifc">{{ifc.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <div class="flex flex-row justify-center items-center mt-5">
    <!--
    <button  [disabled]="checkRunning || selectedFiles.length > 1 || selectedFiles.length == 0" (click)="startloiCheck()"
            color="primary"
            mat-raised-button>LOI Check
    </button>
    -->
    <button [disabled]="!checkForm.valid" (click)="startloiCheck()"
            color="primary"
            mat-raised-button>LOI Check
    </button>

    <ng-container *ngIf="check.value != null && check.value != 'BIC' ">
      <button [routerLink]="['custom', check.value.id]"
              color="primary"
              mat-raised-button>Regel bearbeiten
      </button>
    </ng-container>

  </div>
</div>


