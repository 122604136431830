import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentOperation'
})
export class DocumentOperationPipe implements PipeTransform {

  transform(value:  string): string {
    switch (value) {
      case "DELETE":
        return "Gelöscht"
      case "UPLOAD":
        return "Hochgeladen"
      case "EDIT":
        return "Bearbeitet"
      default:
        return "Hochgeladen"
    }
  }

}
