import { Injectable } from '@angular/core';
import {Router, RouterStateSnapshot, ActivatedRouteSnapshot, Resolve} from '@angular/router';
import { Observable, of } from 'rxjs';
import {Project, ProjectControllerService, QualityCheck, QualityControllerService} from "../../api";
import {SidebarService} from "../../services/sidebar/sidebar.service";
import {StateConfigService} from "../../services/state/state-config.service";
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QualityCheckResolver {

  constructor(private stateConfigService: StateConfigService,
              private qualityControllerService: QualityControllerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QualityCheck> {
    const id = route.paramMap.get('checkId')

    return this.qualityControllerService.getCheck1(id!, this.stateConfigService.getProjectId())

  }
}
