<div class="flex flex-col justify-start items-center relative overflow-hidden">
  <app-files class="w-full"
             [project]="{id: 0}"
             (selectedFilesChange)="updateSelectedFiles($event)"
             (fileCheckState)="checkRunning = $event">

  </app-files>

  <div class="flex flex-row justify-center items-center mt-5">
    <button  [disabled]="checkRunning || selectedFiles.length > 1 || selectedFiles.length == 0" (click)="startConcreteCheck()"
            color="primary"
            mat-raised-button>Betonprüfung
    </button>
  </div>
</div>
