
  <div class="w-full h-full shadow-full rounded-md bg-white">
<!--    <cdk-table #servicesTbSort="matSort" [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"-->
<!--               multiTemplateDataRows>-->

    <!-- Segment implementing highest layer HOAI Report table -->
    <div *ngIf="isHOAIReport">

      <!-- Subsegment HOAI table Leistungen view -->
      <cdk-table #servicesTbSort="matSort" [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc"
                 multiTemplateDataRows>

        <ng-container cdkColumnDef="name">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[70%] h-full px-7 py-4 font-medium"> {{ isGL ? "Grundleistungen": "Besondere Leistungen"}}
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let service" class="shrink-0 w-[70%] h-full px-7"> {{ service.name | service }}</cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="state">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] font-medium">Prüfstatus
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let service" class="flex flex-row justify-center h-full w-[10%]">
            <div class="flex flex-row justify-center items-center" [matTooltip]="service.result && service.result.succeed ? 'Erfüllt' : 'Nicht Erfüllt'">
              <ng-container *ngIf="service.result && service.result.succeed">
                <app-circle-state [state]="CircleState.GREEN" [useAsIcon]="true" [value]="100"
                                  class="w-6"></app-circle-state>
              </ng-container>
              <ng-container *ngIf="!service.result || !service.result.succeed">
                <app-circle-state [state]="CircleState.RED" [useAsIcon]="true" [value]="100"
                                  class="w-6"></app-circle-state>
              </ng-container>
            </div>
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="details">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] p-2 font-medium">Details
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let service" class="flex flex-row justify-center h-full w-[10%]">
            <ng-container *ngIf="service.result">
              <fa-icon [matTooltip]="'Datei vorhanden'" [icon]="faFile" class="w-6 text-2xl px-2 text-gray-500"></fa-icon>
            </ng-container>
            <ng-container *ngIf="!service.result">
              <fa-icon [matTooltip]="'Keine Datei gefunden'" [icon]="faFileCircleXmark" class="w-6 text-2xl px-2 text-mid-gray"></fa-icon>
            </ng-container>
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="actions">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] p-2 items-center"></cdk-header-cell>
          <cdk-cell *cdkCellDef="let service" class="flex w-[10%] h-full justify-center items-center">
            <fa-icon [matTooltip]="'Aufgabe erstellen'" (click)="createTask(service.name); $event.stopPropagation()" [icon]="faClipboardList" class="w-6 text-2xl text-primary hidden group-hover:block"></fa-icon>
          </cdk-cell>
        </ng-container>

        <!-- Subsegment table definition -->
        <cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"
                        class="flex flex-row justify-center items-center w-full border-b-2 border-b-gray-400 bg-white rounded-t-md overflow-hidden"></cdk-header-row>
        <cdk-row *cdkRowDef="let service; columns: displayedColumns;" (click)="service.result.succeedDocuments ? onSwapDocView(true, service) : $event.stopPropagation()"
                 class="flex flex-row justify-center items-center w-full py-1 border-t-2 border-b-gray-400 hover:bg-gray-200 hover:cursor-pointer group"></cdk-row>
<!--        <cdk-row *cdkRowDef="let service; columns: displayedColumns;" (click)="selectedService.result.succeedDocuments ? onSwapDocView(true, service) : $event.stopPropagation()"-->
<!--                 class="flex flex-row justify-center items-center w-full py-1 border-t-2 border-b-gray-400 hover:bg-gray-200 hover:cursor-pointer group"></cdk-row>-->
<!--        <cdk-row *cdkRowDef="let row; columns: displayedColumns;" (click)="expandedElement = expandedElement === row ? null : row; isDocView = true; setFirstDocumentAsSelected()"-->
<!--                 class="flex flex-row justify-center items-center w-full py-1 border-t-2 border-b-gray-400 hover:bg-gray-200 hover:cursor-pointer group"></cdk-row>-->
      </cdk-table>
    </div>


    <!-- Segment implementing document view of HOAI report table -->
    <div *ngIf="isHOAIDocView" class="flex flex-col h-full w-full relative">

      <!-- Subsegment HOAI document view header and return button-->
      <div class="flex px-1 py-1 bg-white rounded-t-md">
        <!-- Clickable return button -->
        <div (click)="onSwapDocView(false, undefined)" class="flex flex-row items-center justify-start px-2 py-1 gap-2 hover:bg-gray-200 hover:cursor-pointer select-none rounded-md">
          <fa-icon [icon]="faChevronLeft" class="w-2 text-primary"></fa-icon>
          <div>Teilleistung:</div>
          <div class="font-medium">{{selectedService.name | service}}</div>
        </div>
      </div>

      <!-- Subsegment HOAI document view table -->
      <cdk-table #servicesTbSort="matSort" [dataSource]="selectedService.result.succeedDocuments" matSort matSortActive="name" matSortDirection="asc"
                 multiTemplateDataRows class="z-0">

        <ng-container cdkColumnDef="name">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[70%] h-full px-7 py-4 font-medium">Dokumente
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let document" class="shrink-0 w-[70%] h-full px-7 break-all">{{document.name}}</cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="state">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] font-medium">Prüfstatus
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let document" class="flex flex-row justify-center h-full w-[10%]">
            <div class="flex flex-row justify-center items-center" [matTooltip]="documentStateShouldBeGreen(document.id) ? 'Erfüllt' : 'Nicht Erfüllt'">
              <ng-container *ngIf="documentStateShouldBeGreen(document.id)">
                <app-circle-state [state]="CircleState.GREEN" [useAsIcon]="true" [value]="100"
                                  class="w-6"></app-circle-state>
              </ng-container>
              <ng-container *ngIf="!documentStateShouldBeGreen(document.id)">
                <app-circle-state [state]="CircleState.RED" [useAsIcon]="true" [value]="100"
                                  class="w-6"></app-circle-state>
              </ng-container>
            </div>
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="details">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] p-2 font-medium">Details
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let document" class="flex flex-row justify-center h-full w-[10%]">
            <ng-container *ngIf="document.fulfilledServices">
              <fa-icon [matTooltip]="'Datei vorhanden'" [icon]="faFile" class="w-6 text-2xl px-2 text-gray-500"></fa-icon>
            </ng-container>
            <ng-container *ngIf="!document.fulfilledServices">
              <fa-icon [matTooltip]="'Keine Datei gefunden'" [icon]="faFileCircleXmark" class="w-6 text-2xl px-2 text-mid-gray"></fa-icon>
            </ng-container>
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="actions">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] p-2 items-center"></cdk-header-cell>
          <cdk-cell *cdkCellDef="let document" class="flex w-[10%] h-full justify-center items-center">
            <fa-icon [matTooltip]="'Aufgabe erstellen'" (click)="createTask(document.name); $event.stopPropagation()" [icon]="faClipboardList" class="w-6 text-2xl text-primary hidden group-hover:block"></fa-icon>
          </cdk-cell>
        </ng-container>

        <!-- Subsegment table definition -->
        <cdk-header-row *cdkHeaderRowDef="displayedColumns;"
                        class="sticky top-9 flex flex-row justify-center items-center w-full border-b-2 border-b-gray-400 bg-white overflow-hidden"></cdk-header-row>
        <cdk-row *cdkRowDef="let document; columns: displayedColumns;" (click)="document.fulfilledServices ? onSwapDocDetailView(true, document) : $event.stopPropagation()"
                 class="flex flex-row justify-center items-center w-full py-1 border-t-2 border-b-gray-400 hover:bg-gray-200 hover:cursor-pointer group"></cdk-row>
      </cdk-table>
    </div>




    <!-- Segment implementing document detail view of HOAI report table -->
    <div *ngIf="isHAOIDocDetailView" class="flex flex-col h-full w-full relative">

      <!-- Subsegment HOAI document view header and return button-->
      <div class="flex flex-row p-1 items-center gap-8 bg-white rounded-t-md">
        <!-- Clickable return button -->
        <div (click)="onSwapDocDetailView(false, undefined)" class="flex flex-row items-center justify-start px-2 py-1 gap-2 hover:bg-gray-200 hover:cursor-pointer select-none rounded-md">
          <fa-icon [icon]="faChevronLeft" class="w-2 text-primary"></fa-icon>
          <div>Datei:</div>
          <div class="font-medium">{{selectedDocumentFile.name}}</div>
        </div>
        <div [ngClass]="{'text-primary': documentStateShouldBeGreen(selectedDocumentFile.id!), 'text-error': !documentStateShouldBeGreen(selectedDocumentFile.id!)}" class="flex flex-row items-center font-medium gap-2">
          <div>{{getAmountOfSucceededAspects(getDocumentAspectsById(selectedDocumentFile.id)!)}} von {{getTotalAmountOfAspects(getDocumentAspectsById(selectedDocumentFile.id)!)}} erforderlichen Leistungsaspekten wurden gefunden</div>

          <div #infoTrigger (mouseenter)="hoveringInfo = true" (mouseleave)="hoveringInfo = false">
            <fa-icon [icon]="faCircleQuestion" [ngClass]="{'text-primary': documentStateShouldBeGreen(selectedDocumentFile.id!), 'text-error': !documentStateShouldBeGreen(selectedDocumentFile.id!)}"></fa-icon>
          </div>

          <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="infoTrigger" [cdkConnectedOverlayOpen]="hoveringInfo">
            <div class="bg-gray-300 rounded-[5px] p-[10px] shadow-full break-words w-full">
              <div [innerHTML]="'Nicht alle Leistungsaspekte sind erforderlich.</br></br>' +
               'Die Gesamtheit der Leistungsaspekte stellt lediglich</br>den maximalen Umfang dieser Teilleistung dar.</br>' +
                ' Projektspezifisch können weniger Leistungsaspekte</br>ausreichen als vorhanden.'"></div>
            </div>
          </ng-template>

        </div>
      </div>

      <!-- Subsegment HOAI document detail view table -->
      <cdk-table #servicesTbSort="matSort" [dataSource]="getDocumentAspectsById(selectedDocumentFile.id)!" matSort matSortActive="name" matSortDirection="asc"
                 multiTemplateDataRows class="z-0">

        <ng-container cdkColumnDef="name">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[70%] h-full px-7 py-4 font-medium">Leistungsaspekte
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let aspect" class="shrink-0 w-[70%] h-full px-2">
            <div class="flex flex-row gap-3">
              <div class="w-[9px]"><fa-icon [icon]="aspect === expandedAspect ? faChevronDown : faChevronRight"></fa-icon></div>
              {{aspect.serviceAspectName | aspect}}
            </div>
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="state">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] font-medium">Prüfstatus
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let aspect" class="flex flex-row justify-center h-full w-[10%]">
            <div class="flex flex-row justify-center items-center"
                 [matTooltip]="aspect.succeed ? 'Erfüllt' : 'Nicht Erfüllt'">
              <ng-container *ngIf="aspect.succeed">
                <app-circle-state [state]="CircleState.GREEN" [useAsIcon]="true" [value]="100"
                                  class="w-6"></app-circle-state>
              </ng-container>
              <ng-container *ngIf="!aspect.succeed">
                <app-circle-state [state]="CircleState.RED" [useAsIcon]="true" [value]="100"
                                  class="w-6"></app-circle-state>
              </ng-container>
            </div>
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="details">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] p-2 font-medium">Details
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let aspect" class="flex flex-row justify-center h-full w-[10%]">
            <ng-container>
              <fa-icon [matTooltip]="'Datei vorhanden'" [icon]="faFile" class="w-6 text-2xl px-2 text-gray-500"></fa-icon>
            </ng-container>
          </cdk-cell>
        </ng-container>

        <ng-container cdkColumnDef="actions">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row justify-center h-full w-[10%] p-2 items-center"></cdk-header-cell>
          <cdk-cell *cdkCellDef="let aspect" class="flex w-[10%] h-full justify-center items-center" >
            <fa-icon [matTooltip]="'Aufgabe erstellen'" (click)="createTask(aspectPipe.transform(aspect.serviceAspectName)); $event.stopPropagation()" [icon]="faClipboardList" class="w-6 text-2xl text-primary hidden group-hover:block"></fa-icon>
          </cdk-cell>
        </ng-container>

        <ng-container matColumnDef="aspectDetail">
          <cdk-cell *cdkCellDef="let aspect" [attr.colspan]="displayedColumns.length">
            <div [@detailExpand]="aspect == expandedAspect ? 'expanded' : 'collapsed'" class="flex overflow-hidden">
              <div *ngIf="aspect.serviceAspectName!='Konformitäts-Check'" class="flex flex-col w-full h-[10%] justify-start items-start border-t-2 border-b-gray-400 px-7 py-2">
                <div class="py-2">{{aspect.serviceAspectName | description}}</div>
                <div>Dieser Leistungsaspekt <u>kann</u> folgende Angaben beinhalten:</div>
                <div class="px-2" [innerHtml]="aspect.serviceAspectName | phrases"></div>
              </div>
              <div *ngIf="false && aspect.serviceAspectName=='Konformitäts-Check'" class="flex flex-col w-full h-[10%] justify-start items-start border-t-2 border-b-gray-400 py-2">
                <table>
                <tr *ngFor="let name of Object.getOwnPropertyNames(getRelevantParameterFromComplianceCheck())">
                  <td class="pl-7">
                    <div [innerHTML]="name | compliance"></div>
                  </td>
                  <td>
                    <div *ngIf="Object.getOwnPropertyDescriptor(ComplianceCheckResultForSelectedFile, name)?.value" [innerHTML]="name | compliancePhrases"></div>
                    <div *ngIf="!Object.getOwnPropertyDescriptor(ComplianceCheckResultForSelectedFile, name)?.value" [innerHTML]="name+'False' | compliancePhrases"></div>
                  </td>
                  <td class="w-[10%]">
                    <div class="flex flex-row justify-center items-center" [matTooltip]="Object.getOwnPropertyDescriptor(ComplianceCheckResultForSelectedFile, name)?.value ? 'Erfüllt' : 'Nicht Erfüllt'">
                    <ng-container *ngIf="Object.getOwnPropertyDescriptor(ComplianceCheckResultForSelectedFile, name)?.value">
                      <app-circle-state [state]="CircleState.GREEN" [useAsIcon]="true" [value]="100"
                                        class="w-6"></app-circle-state>
                    </ng-container>
                    <ng-container *ngIf="!Object.getOwnPropertyDescriptor(ComplianceCheckResultForSelectedFile, name)?.value">
                      <app-circle-state [state]="CircleState.RED" [useAsIcon]="true" [value]="100"
                                        class="w-6"></app-circle-state>
                    </ng-container>
                    </div>
                  </td>
                  <td class="w-[10%]">&nbsp;</td>
                  <td class="w-[10%]">&nbsp;</td>
                </tr>
                </table>
              </div>
            </div>
          </cdk-cell>
        </ng-container>

        <!-- Subsegment table definition -->
        <cdk-header-row *cdkHeaderRowDef="displayedColumns;"
                        class="sticky top-9 flex flex-row justify-center items-center w-full border-b-2 border-b-gray-400 bg-white overflow-hidden"></cdk-header-row>
        <cdk-row *cdkRowDef="let row; columns: displayedColumns;" (click)="expandedAspect = expandedAspect === row ? null : row" [ngClass]="{'bg-gray-200': expandedAspect === row}"
                 class="flex flex-row justify-center items-center w-full py-1 border-t-2 border-b-gray-400 hover:bg-gray-200 hover:cursor-pointer group"></cdk-row>
        <cdk-row *cdkRowDef="let row; columns: ['aspectDetail']"></cdk-row>
      </cdk-table>
    </div>



    <!-- Segment implementing projectView Role user table -->
    <div *ngIf="isProject">
      <app-users-list [project]="project" [isProject]="true"
                      [addDisabled]="!permissionService.hasProjectPrivilege('ROLE_USERS_ADMIN',stateConfigService.getProject()?.id ?? 0)"
                      [deleteDisabled]="!permissionService.hasProjectPrivilege('ROLE_USERS_ADMIN',stateConfigService.getProject()?.id ?? 0)"
                      [permissionsDisabled]="!permissionService.hasProjectPrivilege('ROLE_USERS_ADMIN',stateConfigService.getProject()?.id ?? 0)">
      </app-users-list>
    </div>

    <!-- Segment implementing organisationView Role user table -->
    <div *ngIf="organisation">
      <app-users-list [organisation]="organisation" [isProject]="false"
                      [addDisabled]="!permissionService.hasOrganisationPrivilege('ROLE_USERS_ADMIN',organisation.id ?? 0)"
                      [deleteDisabled]="!permissionService.hasOrganisationPrivilege('ROLE_USERS_ADMIN',organisation.id ?? 0)"
                      [permissionsDisabled]="!permissionService.hasOrganisationPrivilege('ROLE_USERS_ADMIN',organisation.id ?? 0)">
      </app-users-list>
    </div>

  </div>
