/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LOICheckRule } from './lOICheckRule';
import { ProjectFile } from './projectFile';

export interface LOIRequest { 
    rule?: LOICheckRule;
    lodType?: LOIRequest.LodTypeEnum;
    projectFile?: ProjectFile;
    uuid?: string;
}
export namespace LOIRequest {
    export type LodTypeEnum = 'LOD_100' | 'LOD_200' | 'LOD_300' | 'LOD_400' | 'LOD_500';
    export const LodTypeEnum = {
        _100: 'LOD_100' as LodTypeEnum,
        _200: 'LOD_200' as LodTypeEnum,
        _300: 'LOD_300' as LodTypeEnum,
        _400: 'LOD_400' as LodTypeEnum,
        _500: 'LOD_500' as LodTypeEnum
    };
}