<div class="shadow-full-new w-full flex flex-row justify-end items-center h-16 p-4 pr-6 gap-4">
  <div class="w-full flex justify-start">

    <div [ngClass]="{'hidden':showRenameTopic, 'flex':!showRenameTopic}" class="flex justify-start items-center pr-5">

      <p class="p-2 font-medium text-lg">{{ chatService.currentChat?.topic }}</p>

      <!--button [ngClass]="{'hidden':!hoverTitle, 'inline-flex':hoverTitle}" id="chatNameMenuButton"
              data-dropdown-toggle="chatNameMenu"
              class="px-2 focus:outline-none font-medium rounded-lg text-base text-center inline-flex items-center"
              type="button">
        <fa-icon class="text-gray-200 text-xl" [icon]="faEllipsisVertical"></fa-icon>
      </button-->

      <button id="chatNameMenuButton" [ngClass]="{'hidden':!chatService.currentChat, 'inline-flex':chatService.currentChat}"
              data-dropdown-toggle="chatNameMenu"
              data-dropdown-trigger="hover"
              class="px-2 focus:outline-none font-medium rounded-lg text-base text-center inline-flex items-center text-gray-200 hover:text-gray-400"
              type="button">
        <fa-icon class="text-inherit text-xl" [icon]="faEllipsisVertical"></fa-icon>
      </button>

      <div id="chatNameMenu"
           class="z-10 hidden bg-[#FFFFFF] divide-y divide-gray-100 rounded-lg shadow w-44 !ml-[-5.5rem]">
        <ul class="text-base font-medium text-gray-900" aria-labelledby="chatNameMenuButton">
          <li>
            <button (click)="showRenameTopic = true"
                    class="w-full flex text-left px-4 py-2 hover:bg-gray-100 gap-3 text-gray-900 text-sm items-center rounded-t-lg">
              <fa-icon class="text-lg text-gray-300" [icon]="faPenToSquare"></fa-icon>
              Umbenennen
            </button>

          </li>
          <li>
            <button (click)="openNewChat()"
                    class="w-full flex text-left px-4 py-2 hover:bg-gray-100 gap-3 text-gray-900 text-sm items-center rounded-b-lg">
              <fa-icon class="text-lg text-gray-300" [icon]="faPlus"></fa-icon>
              Neuer Chat
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div [ngClass]="{'hidden':!showRenameTopic, 'flex':showRenameTopic}" class="w-full flex justify-start">
      <div class="w-3/4 relative flex items-center">
        <!--div class="absolute inset-y-0 start-3 flex items-center pointer-events-none">
          <fa-icon class="flex text-[20px]"
                   [ngClass]="{'text-gray-300': !topicInputIsFocused, 'text-gray-400': topicInputIsFocused}"
                   [icon]="faPenToSquare"></fa-icon>
        </div-->
        <input type="text" #renameTopic
               class="block w-full p-2 ps-2 pe-[72px] text-lg text-gray-900 border border-gray-200 rounded-lg focus:outline-none overflow-ellipsis focus:ring-0 focus:border-gray-200"
               (focus)="topicInputIsFocused = true" (focusout)="topicInputIsFocused = false"
               placeholder="{{chatService.currentChat?.topic}}"
               required/>
        <button type="submit"
                class="absolute end-11 text-gray-300 hover:text-error focus:outline-none font-medium rounded-lg text-base"
                (click)="showRenameTopic = false"
                data-tooltip-target="tooltip-cancel" data-tooltip-placement="bottom">
          <fa-icon class="text-inherit text-lg" [icon]="faXmark"></fa-icon>
        </button>
        <div id="tooltip-cancel" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-100 rounded-lg shadow-sm opacity-0 tooltip">
          Abbrechen
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
        <button type="submit"
                class="absolute end-4 text-gray-300 hover:text-primary items-center focus:outline-none font-medium rounded-lg text-base"
                (click)="chatService.renameTopicTo(renameTopic.value); showRenameTopic = false"
                data-tooltip-target="tooltip-save" data-tooltip-placement="bottom">
          <fa-icon class="text-inherit text-lg" [icon]="faCheck"></fa-icon>
        </button>
        <div id="tooltip-save" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-100 rounded-lg shadow-sm opacity-0 tooltip">
          Speichern
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </div>
    </div>
  </div>

  <button id="filterMenuButton" data-dropdown-toggle="filterMenu"
          class="gap-2 rounded-lg cursor-pointer focus:outline-none px-3 py-1.5 text-center inline-flex items-center bg-gray-200 text-secondary hover:bg-gray-300"
          (click)="this.chatService.filterExpanded=!this.chatService.filterExpanded"
          [ngClass]="{ 'bg-gray-300': this.isFilterDropdownShown(), 'bg-gray-200': !this.isFilterDropdownShown() }"
          type="button">
    <fa-icon class="text-inherit flex w-4 h-4" [icon]="faFilter"></fa-icon>
    <p class="whitespace-nowrap text-sm">
      Filter</p>
    <fa-icon class="text-inherit flex w-4 h-4" *ngIf="!this.isFilterDropdownShown()"
             [icon]="faChevronDown"></fa-icon>
    <fa-icon class="text-inherit flex w-4 h-4" *ngIf="this.isFilterDropdownShown()" [icon]="faChevronUp"></fa-icon>
  </button>


  <div id="filterMenu"
       class="z-10 hidden divide-y divide-gray-100 rounded-lg bg-[#FFFFFF] shadow w-[190px] !ml-[3.75rem]">
    <ul class="text-gray-900 gap-3" aria-labelledby="filterMenuButton">
      <li *ngFor="let option of chatService.definedLPHs" class="first:rounded-t-lg first:pt-2 last:rounded-b-lg last:pb-2 hover:bg-gray-100">
        <div class="flex items-center gap-2 py-2 px-4" (click)="onOptionToggle(option, $event)">
          <input type="checkbox" value="" id="checkbox-{{option}}"
                 class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                 [checked]="chatService.filter.lph?.includes(option)">
          <label class="text-sm gap-0.5 font-medium text-gray-900">{{ showLPHName(option) }}</label>
        </div>
      </li>
    </ul>
  </div>

  <div
    class="flex flex-row justify-center items-center rounded-lg gap-2 hover:bg-gray-300 cursor-pointer select-none px-3 py-1.5 text-secondary"
    (click)="this.chatService.allFilesExpanded=!this.chatService.allFilesExpanded; this.chatService.chatHistoryExpanded=false"
    [ngClass]="{ 'bg-gray-300': this.chatService.allFilesExpanded, 'bg-gray-200': !this.chatService.allFilesExpanded }">
    <div>
      <fa-icon class="text-inherit flex w-4 h-4" [icon]="faCopy"></fa-icon>
    </div>
    <p class="whitespace-nowrap text-sm">
      Alle Dateien
    </p>
    <div>
      <fa-icon class="text-inherit flex w-4 h-4" *ngIf="!this.chatService.allFilesExpanded"
               [icon]="faChevronDown"></fa-icon>
      <fa-icon class="text-inherit flex w-4 h-4" *ngIf="this.chatService.allFilesExpanded"
               [icon]="faChevronUp"></fa-icon>
    </div>
  </div>

  <div
    class="flex flex-row justify-center items-center rounded-lg gap-2 hover:bg-gray-300 cursor-pointer select-none px-3 py-1.5 text-secondary"
    (click)="this.chatService.chatHistoryExpanded=!this.chatService.chatHistoryExpanded; this.chatService.allFilesExpanded=false"
    [ngClass]="{ 'bg-gray-300': this.chatService.chatHistoryExpanded, 'bg-gray-200': !this.chatService.chatHistoryExpanded }">
    <div>
      <fa-icon class="text-inherit flex w-4 h-4" [icon]="faMessages"></fa-icon>
    </div>
    <p class="whitespace-nowrap text-sm">
      Alle Chats
    </p>
    <div>
      <fa-icon class="text-inherit flex w-4 h-4" *ngIf="!this.chatService.chatHistoryExpanded"
               [icon]="faChevronDown"></fa-icon>
      <fa-icon class="text-inherit flex w-4 h-4" *ngIf="this.chatService.chatHistoryExpanded"
               [icon]="faChevronUp"></fa-icon>
    </div>
  </div>


</div>
