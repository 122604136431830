import { Pipe, PipeTransform } from '@angular/core';
import {Project} from "../api";

@Pipe({
  name: 'projectState'
})
export class ProjectStatePipe implements PipeTransform {

  transform(value: Project.StateEnum | undefined | string): string {
    switch (value) {
      case Project.StateEnum.BW: return "Baden-Württemberg";
      case Project.StateEnum.BY: return "Bayern";
      case Project.StateEnum.BE: return "Berlin";
      case Project.StateEnum.BB: return "Brandenburg";
      case Project.StateEnum.HB: return "Bremen";
      case Project.StateEnum.HH: return "Hamburg";
      case Project.StateEnum.HE: return "Hessen";
      case Project.StateEnum.MV: return "Mecklenburg-Vorpommern";
      case Project.StateEnum.NI: return "Niedersachsen";
      case Project.StateEnum.NW: return "Nordrhein-Westfalen";
      case Project.StateEnum.RP: return "Rheinland-Pfalz";
      case Project.StateEnum.SL: return "Saarland";
      case Project.StateEnum.SN: return "Sachsen";
      case Project.StateEnum.ST: return "Sachsen-Anhalt";
      case Project.StateEnum.SH: return "Schleswig-Holstein";
      case Project.StateEnum.TH: return "Thüringen";
      default: return value ?? "";

    }
  }

}


@Pipe({
  name: 'projectStateReverse'
})

export class ProjectStateReversePipe implements PipeTransform {

  transform(value: string): Project.StateEnum {
    switch (value) {
      case "Baden-Württemberg": return Project.StateEnum.BW;
      case "Bayern": return Project.StateEnum.BY;
      case "Berlin": return Project.StateEnum.BE;
      case "Brandenburg": return Project.StateEnum.BB;
      case "Bremen": return Project.StateEnum.HB;
      case "Hamburg": return Project.StateEnum.HH;
      case "Hessen": return Project.StateEnum.HE;
      case "Mecklenburg-Vorpommern": return Project.StateEnum.MV;
      case "Niedersachsen": return Project.StateEnum.NI;
      case "Nordrhein-Westfalen": return Project.StateEnum.NW;
      case "Rheinland-Pfalz": return Project.StateEnum.RP;
      case "Saarland": return Project.StateEnum.SL;
      case "Sachsen": return Project.StateEnum.SN;
      case "Sachsen-Anhalt": return Project.StateEnum.ST;
      case "Schleswig-Holstein": return Project.StateEnum.SH;
      case "Thüringen": return Project.StateEnum.TH;
      default: return Project.StateEnum.NW;
    }
  }
}
