import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from "../../../material.module";
import {LvComponent} from "./lv.component";
import {FilesModule} from "../../util/files/files.module";



@NgModule({
  declarations: [
    LvComponent,
  ],
  imports: [
    CommonModule,
    FilesModule,
    MaterialModule,
  ]
})
export class LvModule { }
