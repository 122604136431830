import {AfterViewInit, Component} from '@angular/core';
import {
  faCheck,
  faChevronDown,
  faChevronUp, faCopy,
  faEllipsisVertical, faFilter,
  faPenToSquare,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import {ChatService} from "../service/chat.service";
import {faMessages, faXmark} from "@fortawesome/pro-solid-svg-icons";
import {Router} from "@angular/router";
import {StateConfigService} from "../../../services/state/state-config.service";
import {initFlowbite} from "flowbite";

@Component({
  selector: 'app-chat-top-nav',
  templateUrl: './chat-top-nav.component.html',
  styleUrls: ['./chat-top-nav.component.scss']
})
export class ChatTopNavComponent implements AfterViewInit{

  showRenameTopic: boolean = false;

  constructor(private router: Router,
              private stateConfigService: StateConfigService,
              public chatService: ChatService) {
  }

  showLPHName(lph: string){
    return 'Leistungsphase '+lph.slice(-1)
  }

  onOptionToggle(lph: string, event: any) {
    const checkbox = event.target as HTMLInputElement;
    const checked = checkbox.checked ?? !(document.getElementById(`checkbox-${lph}`) as HTMLInputElement)?.checked;
    const filter = this.chatService.filter.lph ??= [];

    if (checked) {
      filter.push(lph);
    } else {
      this.chatService.filter.lph = filter.filter(filter => filter !== lph);
    }

    if (checkbox.checked === undefined) {
      (document.getElementById(`checkbox-${lph}`) as HTMLInputElement).checked = checked;
    }
  }

  openNewChat() {
    this.chatService.updateCurrentChat(undefined)
    this.chatService.currentChatMessages = []
    this.chatService.allFiles = []
    this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'chat'])
  }

  isFilterDropdownShown(){
    let element = document.getElementById('filterMenu') as Element
    const style = window.getComputedStyle(element);
    return style.display != 'none'
  }

  ngAfterViewInit() {
    initFlowbite()
  }

  protected readonly faChevronDown = faChevronDown;
  protected readonly faChevronUp = faChevronUp;
  protected readonly faMessages = faMessages;
  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faPlus = faPlus;
  protected readonly faPenToSquare = faPenToSquare;
  hoverTitle: boolean = false;
  protected readonly faXmark = faXmark;
  protected readonly faCheck = faCheck;
  topicInputIsFocused: boolean = false;
  protected readonly faCopy = faCopy;
  protected readonly faFilter = faFilter;
}
