<div class="flex flex-col items-center p-2 gap-8">
  <div class="flex flex-row justify-center items-center">
    <svg *ngIf="!image" class="w-[180px] h-[180px] text-gray-400 -left-[15px]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
    <img *ngIf="image" id="image" class="flex max-h-[400px] max-w-[400px]" src="{{image}}">
  </div>

  <div class="flex flex-row justify-between w-full">
    <button mat-flat-button (click)="close()">Abbrechen</button>
    <div class="flex flex-row ">
      <button mat-flat-button [disabled]="!image" (click)="removeImage()">Bild entfernen</button>
      <button color="primary" mat-flat-button (click)="uploadImage()">Bild auswählen</button>
    </div>
  </div>
</div>
