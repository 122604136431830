import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {ErrorService} from "../error/error.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {


  constructor(private snackbar: MatSnackBar, private router: Router, private errorService: ErrorService) {

    this.errorService.errorEvent$.pipe().subscribe(error => {
      if (error.url?.includes("state") && error.status === 0) {
        this.showErrorMessage("Keine Verbindung zum Server. Bitte überprüfen Sie Ihre Internetverbindung.");
      }
    });
  }


  public showErrorMessage(message: string): void {
    this.snackbar.open(message, "X", {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: ['error-snackbar']
    });
  }


}
