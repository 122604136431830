<div class="flex flex-col w-full justify-between items-center p-6 h-full" *ngIf="!changeMethode">
  <div class="flex flex-col w-full items-center gap-3">
    <span class="text-xl font-medium text-gray-700">2FA Deaktivieren</span>
    <span class="text-sm text-gray-700">Herabstufung der Kontosicherheit.</span>
  </div>

  <span  class="text-sm text-gray-700 text-center">
   Wenn Sie fortfahren, deaktivieren Sie die Zwei-Faktor-Authentifizierung für Ihr Konto. Um Ihr Konto bestmöglich zu schützen, empfehlen wir Ihnen, die 2FA aktiviert zu lassen. Falls Sie diese Schutzfunktion dennoch deaktivieren möchten, bestätigen Sie dies durch Auswahl von „Deaktivieren“.
  </span>

  <div class="flex flex-row w-full justify-end gap-4 border-t border-gray-100">
    <bic-button class="pt-6" [text]="'Abbrechen'" [textColor]="'gray-900'" [color]="'white'" [border]="true" (onClick)="close()"></bic-button>
    <bic-button class="pt-6" [text]="'Deaktivieren'" (onClick)="deactivate2FA()"></bic-button>

  </div>

</div>

<div class="flex flex-col w-full justify-between items-center p-6 h-full" *ngIf="changeMethode">
  <div class="flex flex-col w-full items-center gap-3">
    <span class="text-xl font-medium text-gray-700">2FA Anpassen</span>
    <span class="text-sm text-gray-700">Änderung der Kontosicherheit.</span>
  </div>

  <span  class="text-sm text-gray-700 text-center">
    Wenn Sie fortfahren, ändern Sie die Methode der Zwei-Faktor-Authentifizierung für Ihr Konto. Zuerst wird die bestehende Methode deaktiviert, anschließend die neue Methode aktiviert. Bitte bestätigen Sie die Änderung mit “Weiter”, falls Sie fortfahren möchten.
  </span>

  <div class="flex flex-row w-full justify-end gap-4 border-t border-gray-100">
    <bic-button class="pt-6" [text]="'Abbrechen'" [textColor]="'gray-900'" [color]="'white'" [border]="true" (onClick)="close()"></bic-button>
    <bic-button class="pt-6" [text]="'Weiter'" (onClick)="deactivate2FA()"></bic-button>

  </div>

</div>


