<div (dblclick)="openTaskDetails()" class="hover:cursor-grab px-1 rounded-lg bg-white shadow-full" [ngClass]="{'bg-primary-light': !selected,'bg-gray':selected}">
  <div class="flex flex-col p-4 w-full gap-4">
    <div class="flex flex-row justify-between items-center w-full">
      <div class="text-base font-semibold overflow-hidden w-full line-clamp-2">
        {{ task.markup?.topic?.title }}
      </div>
      <div [bicDropdownTrigger]="dropdown" class="hover:cursor-pointer">
        <fa-icon class="text-bic-gray text-sm px-1.5" [icon]="faEllipsisVertical"></fa-icon>
      </div>
      <bic-dropdown #dropdown [dropdownPlacement]="'bottom-start'">
        <bic-dropdown-element (onClick)="openTaskDetails()">
          <div class="flex flex-row text-sm gap-3 text-gray-700">
            <fa-icon class="text-gray-700" [icon]="faArrowUpRightFromSquare"></fa-icon>
            <span>Öffnen</span>
          </div>
        </bic-dropdown-element>
        <bic-dropdown-element>
          <div class="flex flex-row text-sm gap-3 text-gray-700">
            <fa-icon class="text-gray-700" [icon]="faCopy"></fa-icon>
            <span>Kopieren</span>
          </div>
        </bic-dropdown-element>
        <bic-dropdown-element (click)="exportBCFClicked.emit(task)">
          <div class="flex flex-row text-sm gap-3 text-gray-700">
            <fa-icon class="text-gray-700" [icon]="faArrowUpFromBracket"></fa-icon>
            <span>BCF Export</span>
          </div>
        </bic-dropdown-element>
        <bic-seperator></bic-seperator>
        <bic-dropdown-element (click)="openDeleteDialog()">
          <div class="flex flex-row text-sm gap-3 text-error">
            <fa-icon class="text-error" [icon]="faTrash"></fa-icon>
            <span>Löschen</span>
        </div>
        </bic-dropdown-element>
      </bic-dropdown>
    </div>
    <div class="flex flex-row w-full gap-4 break-words">
<!--      <div class="flex w-[40%] justify-center items-center rounded-lg bg-gray-700">-->
<!--        <fa-icon [icon]="faIfc" class="text-white text-[32px]"></fa-icon>-->
<!--      </div>-->
      <div class="flex w-[100%] text-sm line-clamp-3 min-h-[64px]">
        {{task.markup?.topic?.description}}
      </div>
    </div>
    <div class="flex flex-col" [class]="task.markup?.topic?.assignedToUser || task.markup?.topic?.dueDate ? 'gap-2' : ''">
      <div class="flex flex-row">
        <div class="flex flex-row px-2.5  text-gray-400 items-center h-6" [tooltip]="'Priorität'" [class]="task.markup?.topic?.assignedToUser || task.markup?.topic?.dueDate ? 'py-0.5' : 'pt-0.5'">
          <fa-icon class="text-sm px-1.5" [icon]="faCircleSmall" [ngClass]="{
          'text-error': task.priority === 'HIGH',
          'text-secondary': task.priority === 'NORMAL',
          'text-primary': task.priority === 'LOW'
          }"></fa-icon>
          <span class="text-gray-900 whitespace-nowrap text-sm pl-1">{{ task.priority| priority }}</span>
        </div>
        <div class="flex flex-row gap-1 px-2.5 text-gray-400 items-center h-6" [tooltip]="'Status'" [class]="task.markup?.topic?.assignedToUser || task.markup?.topic?.dueDate ? 'py-0.5' : 'pt-0.5'">
          <fa-icon class="text-sm px-1.5 text-secondary" [icon]="faCircleRegular" *ngIf="task.state === 'OPEN'"></fa-icon>
          <fa-icon class="text-sm px-1.5 -rotate-90 text-secondary" [icon]="faCircleHalfStroke" *ngIf="task.state === 'PROCESS'"></fa-icon>
          <fa-icon class="text-sm px-1.5 text-secondary" [icon]="faCircle" *ngIf="task.state === 'DONE'"></fa-icon>
          <fa-icon class="text-sm px-1.5 text-secondary" [icon]="faCircleCheck" *ngIf="task.state === 'VERIFIED'"></fa-icon>
<!--          <span class="text-gray-900 whitespace-nowrap text-sm">{{ task.state| taskState }}</span>-->
        </div>

      </div>
      <div class="flex flex-row w-full h-[1px] bg-gray-200" *ngIf="task.markup?.topic?.assignedToUser || task.markup?.topic?.dueDate">
      </div>
      <div class="flex flex-row w-full" [class]="task.markup?.topic?.dueDate ? 'justify-between' : 'justify-end'">
        <div class="flex flex-row rounded-md px-2 py-1 text-gray-400 items-center h-7" [class]="getDaysUntilDueDate(task.markup?.topic?.dueDate) <0 && (task.state !== 'DONE' || task.state !== 'VERIFIED') ? 'bg-red-200' : 'bg-gray-100'" *ngIf="task.markup?.topic?.dueDate" [tooltip]="getDaysUntilDueDate(task.markup?.topic?.dueDate) <0 ? 'überfällig seit' : 'Fällig in'">
          <fa-icon class="text-sm px-1.5" [icon]="faCalendarDays" [class]="getDaysUntilDueDate(task.markup?.topic?.dueDate) <0 && (task.state !== 'DONE' || task.state !== 'VERIFIED') ? 'text-red-400' : 'text-gray-400'"></fa-icon>
          <span class="whitespace-nowrap text-sm pl-1" [class]="getDaysUntilDueDate(task.markup?.topic?.dueDate) <0 && (task.state !== 'DONE' || task.state !== 'VERIFIED') ? 'text-error' : 'text-gray-900'">
            {{ getAbsoluteValue(getDaysUntilDueDate(task.markup?.topic?.dueDate)) }} {{ getAbsoluteValue(getDaysUntilDueDate(task.markup?.topic?.dueDate)) == 1 ? 'Tag' : 'Tage'}}</span>
        </div>

        <div class="flex flex-row items-center" *ngIf="task.markup?.topic?.assignedToUser" [tooltip]="'Zugewiesen'">
          <img *ngIf="task.markup?.topic?.assignedToUser.profileImage" class="w-7 h-7 rounded-full" src="{{task.markup?.topic?.assignedToUser.profileImage}}" alt="Verantwortlich">
          <div *ngIf="!task.markup?.topic?.assignedToUser.profileImage" class="relative inline-flex items-center justify-center w-7 h-7 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
            <span class="font-medium text-gray-600 dark:text-gray-300">{{task.markup?.topic?.assignedToUser.firstName.charAt(0).toUpperCase()}}{{task.markup?.topic?.assignedToUser.lastName.charAt(0).toUpperCase()}}</span>
          </div>
          <div class="flex flex-col gap-1 pl-2.5">
                <span class="text-xs font-semibold leading-3">
                  <span class="text-xs font-semibold leading-3" *ngIf="task.markup?.topic?.assignedToUser.title && task.markup?.topic?.assignedToUser.title != 'NONE'">
                    {{task.markup?.topic?.assignedToUser.title}}
                  </span>
                  {{task.markup?.topic?.assignedToUser.firstName}} {{task.markup?.topic?.assignedToUser.lastName}}</span>
            <span class="text-xs text-bic-gray leading-3" >{{task.markup?.topic?.assignedToUser.roleInProjects[stateConfigService.getProjectId()]?.emailName}}</span>
          </div>
        </div>

      </div>
    </div>


  </div>



<!--  <div class="flex flex-row items-start justify-between pl-2 gap-1 break-words">-->
<!--    <div class="text-xs font-medium overflow-hidden w-full line-clamp-2">-->
<!--      {{ task.markup?.topic?.title }}-->
<!--    </div>-->
<!--    <button mat-icon-button class="pr-2" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu">-->
<!--      <fa-icon class="text-primary text-xl" [icon]="faEllipsisVertical"></fa-icon>-->
<!--    </button>-->

<!--  </div>-->
<!--  <div class="text-xs pl-2">{{ task.markup?.topic?.creationDate | date: "dd.MM.yyyy - HH:mm" }}</div>-->
<!--  <div class="text-xs pl-2">{{ task.projectName }}</div>-->
<!--  <div class="mt-2 pl-2">-->
<!--    <div [innerHTML]="task.markup?.topic?.description"></div>-->
<!--  </div>-->
<!--  <div class="mt-2">-->
<!--    <mat-chip-listbox *ngIf="task.lph">-->
<!--      <mat-chip-option>-->
<!--        <mat-chip-avatar class="pb-4">-->
<!--          <mat-icon>maps_home_work</mat-icon>-->
<!--        </mat-chip-avatar>-->
<!--        {{ task.lph }}-->
<!--      </mat-chip-option>-->
<!--      <mat-chip-option *ngIf="task.markup.topic.dueDate">-->
<!--        <mat-chip-avatar>-->
<!--          <mat-icon>today</mat-icon>-->
<!--        </mat-chip-avatar>-->
<!--        {{ task.markup.topic.dueDate | date: "dd.MM.yyyy" }}-->
<!--      </mat-chip-option>-->
<!--      <mat-chip-option *ngIf="task.priority">-->
<!--        <mat-chip-avatar>-->
<!--          <mat-icon>priority_high</mat-icon>-->
<!--        </mat-chip-avatar>-->
<!--        {{ task.priority| priority }}-->
<!--      </mat-chip-option>-->
<!--    </mat-chip-listbox>-->
<!--    <mat-chip-listbox>-->
<!--      <mat-chip-option *ngIf="task.markup?.topic?.creationUser">-->
<!--        <mat-chip-avatar>-->
<!--          <app-avatar [user]="task.markup.topic.creationUser"></app-avatar>-->
<!--        </mat-chip-avatar>-->
<!--        {{ task.markup.topic.creationUser?.firstName }} {{ task.markup.topic.creationUser?.lastName }}-->
<!--      </mat-chip-option>-->
<!--      <mat-chip-option *ngIf="!task.markup?.topic?.creationUser">-->
<!--        {{ task.markup.topic.creationAuthor }}-->
<!--      </mat-chip-option>-->
<!--    </mat-chip-listbox>-->
<!--  </div>-->
</div>

<!--<mat-menu #menu="matMenu">-->
<!--  <ng-template matMenuContent let-node="node">-->
<!--    <button mat-menu-item (click)="openTaskDetails()">-->
<!--      Bearbeiten-->
<!--    </button>-->
<!--    <button mat-menu-item (click)="exportBCFClicked.emit(task)">-->
<!--      Als BCF exportieren-->
<!--    </button>-->
<!--    <button mat-menu-item style="color: red" (click)="openDeleteDialog()">-->
<!--      Löschen-->
<!--    </button>-->
<!--  </ng-template>-->
<!--</mat-menu>-->


<!--<app-task-detail [openDetails]="taskDetailService.getOpenedTask()?.id === task.id" [taskId]="task.id ?? ''" (taskChange)="taskChanged($event)"></app-task-detail>-->

