<div class="flex flex-col h-full w-full justify-start items-start gap-6 pb-6 font-medium bg-[#FFF]">
  <app-bim-base-check-stepper class="w-full"></app-bim-base-check-stepper>

  <div class="flex flex-col h-full w-full justify-start items-start gap-6 px-4 overflow-hidden" *ngIf="check">

    <app-overview-report [checkType]="'BIM Basis-Check'"
                         [completedValue]="totalCompleted()"
                         [errorValue]="totalError()"
                         [rules]="totalRules"
                         [warningValue]="totalWarning()"
                         [date]="check.checkDate"
                         [fileId]="check.projectFile.id"
                         [whatWasChecked]=" totalRules == 1 ? 'Regel' : 'Regeln'"
                         class="w-full">
    </app-overview-report>

    <div class="w-full flex flex-col justify-start items-center overflow-hidden rounded-md">
      <div class="w-full p-4 text-center text-gray-900 font-semibold rounded-t-md border border-gray-200 border-b-0">
        {{check.projectFile.name}}
      </div>
        <cdk-table [dataSource]="dataSource" class="w-full overflow-y-scroll border border-t-0 border-gray-200 border-b-0">

          <ng-container cdkColumnDef="info">
            <cdk-header-cell *cdkHeaderCellDef
                             class=" flex flex-row w-[3%] h-full justify-center items-center font-medium">

            </cdk-header-cell>
            <cdk-cell *cdkCellDef="let element"
                      class=" flex flex-row w-[3%] h-full justify-center items-center">
              <div>
                <fa-icon [tooltip]="element.rule_name" [tooltipSubtext]="element.rule_description" [tooltipDirection]="'right'"
                         [icon]="faCircleInfo" class="text-gray-300 hover:text-gray-900 text-lg"></fa-icon>
              </div>
            </cdk-cell>
          </ng-container>

          <ng-container cdkColumnDef="rule">
            <cdk-header-cell *cdkHeaderCellDef
                             class=" flex flex-row w-[38%] h-full justify-start items-center font-medium">
              PRÜFREGEL
            </cdk-header-cell>
            <cdk-cell *cdkCellDef="let element"
                      class=" flex flex-row w-[38%] h-full justify-start items-center"> {{ element.rule_name }}
            </cdk-cell>
          </ng-container>

          <ng-container cdkColumnDef="result">
            <cdk-header-cell *cdkHeaderCellDef
                             class=" flex flex-row w-[49%] h-full justify-start items-center font-medium">
              ERGEBNIS
            </cdk-header-cell>
            <cdk-cell *cdkCellDef="let element"
                      class=" flex flex-row w-[49%] h-full justify-start items-center px-1 text-gray-500"> {{ element.text }}
            </cdk-cell>
          </ng-container>

          <ng-container cdkColumnDef="state">
            <cdk-header-cell *cdkHeaderCellDef
                             class=" flex flex-row w-[7%] h-full justify-center items-center font-medium">
              PRÜFSTATUS
            </cdk-header-cell>
            <cdk-cell *cdkCellDef="let element" class="flex flex-row justify-center items-center m-1 w-[7%] h-full"
                      [tooltip]=" element.status ==='success' ? 'Erfüllt' : element.status ==='warning' ? 'Teilweise Erfüllt' : 'Nicht Erfüllt'" [tooltipDirection]="'bottom'">

              <ng-container *ngIf="element.status ==='success'">
                <app-circle-state [state]="CircleState.GREEN" [useAsIcon]="true" [value]="100"
                                  class="w-5"></app-circle-state>
              </ng-container>

              <ng-container *ngIf="element.status ==='warning'">
                <app-circle-state [state]="CircleState.YELLOW" [useAsIcon]="true" [value]="100"
                                  class="w-5"></app-circle-state>
              </ng-container>

              <ng-container *ngIf="element.status === 'error'">
                <app-circle-state [state]="CircleState.RED" [useAsIcon]="true" [value]="100"
                                  class="w-5"></app-circle-state>
              </ng-container>

            </cdk-cell>
          </ng-container>
          <ng-container cdkColumnDef="task">
            <cdk-header-cell *cdkHeaderCellDef
                             class=" flex flex-row w-[3%] h-full justify-center items-center font-medium"></cdk-header-cell>
            <cdk-cell *cdkCellDef="let element" class="flex flex-row justify-center items-center w-[3%] h-full"
                      [tooltip]="'Aufgabe erstellen'" [tooltipDirection]="'bottom'" (click)="createTask()">
              <button class="w-10 hidden-button">
                <fa-icon class="text-lg" [icon]="faClipboardCheck"></fa-icon>
              </button>
            </cdk-cell>
          </ng-container>

          <cdk-header-row *cdkHeaderRowDef="displayedColumns sticky: true;"
                          class="flex flex-row justify-start items-start w-full border-b border-gray-200 bg-gray-50 text-gray-500 p-4 font-semibold text-xs">
          </cdk-header-row>
          <cdk-row *cdkRowDef="let row; columns: displayedColumns;" (click)="setGuids(row)"
                   class="flex flex-row justify-start items-center w-full border-b border-b-gray-200 hover:bg-gray-100 py-4 font-medium text-sm">
          </cdk-row>

        </cdk-table>
    </div>


  </div>
</div>
