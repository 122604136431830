import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {
  ApplicationRef, ComponentFactory, ComponentFactoryResolver,
  Injectable,
  Injector,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {ComponentPortal, ComponentType, DomPortalOutlet, Portal} from "@angular/cdk/portal";

@Injectable({
  providedIn: 'root'
})
export class PopoutContentService {

  opened: boolean = false;
  windowRef: WindowProxy | null | undefined;

  constructor(private router: Router, public appRef: ApplicationRef, private injector: Injector, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url.includes('/files/')) {
          this.opened = true;
        }
      }
    })
  }

  open(url: string, title: string, inNewTab: boolean = false) {
    // if (inNewTab) {
    //   window.open(url, title, '_blank');
    //   return;
    // }

    this.windowRef = window.open(url, '_blank');

    // this.windowRef = window.open(url, title, 'width=1280,height=720');
  }

  close() {
    if (this.windowRef) {
      this.windowRef.close();
      this.opened = false;
    }
  }
}
