import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input, NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AuthControllerService,
  DashboardControllerService,
  Project,
  User,
  DashboardData,
  ProjectControllerService,
  Task,
  CompletenessCheck,
  ProjectServiceNotification,
  SimpleCheck,
  QualityCheck,
  IFCCheck,
  CheckControllerService, IfcControllerService, ProjectFileData, QualityControllerService
} from "../../api";
import {ToolbarService} from "../util/design/toolbar/toolbar.service";
import {IReportEmbedConfiguration, models} from "powerbi-client";
import {Color, ScaleType} from "@swimlane/ngx-charts";
import {
  faBars,
  faBuilding, faChartSimple,
  faChevronDown, faChevronLeft,
  faChevronRight, faClipboardList, faEllipsisVertical,
  faFileCircleCheck, faInfo,
  faListCheck
} from "@fortawesome/free-solid-svg-icons";
import {faFolderOpen, faCircleUser} from '@fortawesome/free-regular-svg-icons';
import {StateConfigService} from "../../services/state/state-config.service";
import StateEnum = Task.StateEnum;
import LphEnum = CompletenessCheck.LphEnum;
import {ActivatedRoute, Router} from "@angular/router";
import PartialServiceNameEnum = ProjectServiceNotification.PartialServiceNameEnum;
import {LphServicePipe} from "../../pipes/lph.pipe";
import {TaskStatePipe, TaskStateReversePipe} from "../../pipes/task-state.pipe";
import {LoiElement, LoiProperties} from "../report/components/ifcCheckReport/ifc-check-report.component";
import {CircleState} from "../util/design/circle-state/circle-state.component";
import {CheckItem, QualityService} from "../../quality-api";
import {DatePipe} from "@angular/common";
import {MatTableDataSource} from "@angular/material/table";
import {QualityCheckRule} from "../qualityCheck/quality-rules-report/quality-rules-report.component";
import {faBadgeCheck, faClipboardCheck, faCube, faInfoCircle} from "@fortawesome/pro-solid-svg-icons";
import {LphToNumberPipe} from "../../pipes/lphToNumber.pipe";


export interface CheckResultData {
  date: Date;
  values: {
    [key: string]: string;
  };
  succeeded: number;
  warning: number;
  failed: number;
  url: string;
}

/**
 * Dashboard for User
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  protected readonly faBars = faBars;
  protected readonly Task = Task;
  protected readonly undefined = undefined;
  protected readonly faBuilding = faBuilding;
  protected readonly faCircleUser = faCircleUser;
  protected readonly faFileCircleCheck = faFileCircleCheck;
  protected readonly faListCheck = faListCheck;
  protected readonly faChartSimple = faChartSimple;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faClipboardList = faClipboardList;
  protected readonly faFolderOpen = faFolderOpen;

  hover: boolean = false;
  data!: DashboardData;
  loaded: boolean = false;
  tasks: any[] = [];
  taskMap: Map<Task.StateEnum, number> = new Map<Task.StateEnum, number>();
  totalTasks: number = 0;
  selectedChecks: Map<String, number> = new Map<String, number>();
  resultArrays: Map<string, any[]> = new Map<string, any[]>();

  taskTemp: any[] = [
    {
      "name": "OPEN",
      "value": 0
    },
    {
      "name": "PROCESS",
      "value": 0
    },
    {
      "name": "DONE",
      "value": 0
    },
    {
      "name": "VERIFIED",
      "value": 0
    }
  ];

  dashboardCards = [
    {
      title: 'HOAI-Check',
      icon: this.faListCheck,
      navigateFunction: () => this.navigateToHOAICheck(),
      message: 'Es wurden keine HAOI-Checks erstellt',
    },
    {
      title: 'Qualitäts-Check',
      icon: this.faChartSimple,
      navigateFunction: () => this.navigateToQualityCheck(),
      message: 'Es wurden keine Qualitäts-Checks erstellt',
    },
    {
      title: 'BIM Basis-Check',
      icon: this.faFileCircleCheck,
      navigateFunction: () => this.navigateToBIMBasisCheck(),
      message: 'Es wurden keine BIM Basis-Checks erstellt',
    },
    {
      title: 'LOI-Check',
      icon: this.faFileCircleCheck,
      navigateFunction: () => this.navigateToLOICheck(),
      message: 'Es wurden keine LOI-Checks erstellt',
    },
  ];

  assignments = [
    {label: 'Offen', colorClass: 'text-secondary-45', opacity: ''},
    {label: 'In Bearbeitung', colorClass: 'text-primary', opacity: 'opacity-40'},
    {label: 'Erledigt', colorClass: 'text-primary', opacity: 'opacity-80'},
    {label: 'Verifiziert', colorClass: 'text-primary', opacity: ''}
  ];


  users!: User[];
  user!: User | undefined;
  selectedTaskLph: LphEnum | undefined;
  selectedCheckLph: LphEnum | undefined;
  lastChecks: Array<any> | undefined = [];
  selectedCheck: CompletenessCheck | undefined = undefined;
  checks: any[][] = [];
  numberOfIFCChecks = 0;
  currentLph: LphEnum[] = [LphEnum.LPH1, LphEnum.LPH2, LphEnum.LPH3, LphEnum.LPH4, LphEnum.LPH5]
  states = ["OPEN", "PROCESS", "DONE", "VERIFIED"]
  projectFileData: Map<LphEnum, { [p: string]: ProjectFileData }> = new Map<LphEnum, {
    [p: string]: ProjectFileData
  }>();
  taskArray: any[] = [];

  constructor(
    private dashboardController: DashboardControllerService,
    public stateConfigService: StateConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private lphServicePipe: LphServicePipe,
    private projectControllerService: ProjectControllerService,
    private statePipe: TaskStatePipe,
    private stateReversePipe: TaskStateReversePipe,
    private checkControllerService: CheckControllerService,
    private ifcControllerService: IfcControllerService,
    private cdr: ChangeDetectorRef,
    private lphToNumber: LphToNumberPipe,
    private qualityService: QualityService,
    private qualityControllerService: QualityControllerService,
    private ngZone: NgZone
  ) {
  }

  get project(): Project {
    return this.stateConfigService.selectedProject;
  }

  get isBim(){
    return this.stateConfigService.selectedProject.isBIM;
  }

  get basicTasks(): number[] {
    return [
      this.data.tasks?.OPEN?.length ?? 0,
      this.data.tasks?.PROCESS?.length ?? 0,
      this.data.tasks?.DONE?.length ?? 0,
      this.data.tasks?.VERIFIED?.length ?? 0
    ]
  }

  get hoaiChecks(): CheckResultData[] {
    return this.data.hoaiChecks!.map(check => {
      return {
        date: check.checkDate!,
        values: {
          "Leistunsbild": "Gebäude und Innenräume",
          "Leisungsphase": String(this.lphToNumber.transform(check.lph!)),
          "Geprüfte Leistungen": String(check.numberOfSelectedServices ?? 0),
          "Geprüfte Dateien": String(check.numberOfFiles ?? 0)
        },
        succeeded: check.numberOfSuccessfulServices ?? 0,
        warning: 0,
        failed: check.numberOfFailedServices ?? 0,
        url: `/projects/${this.project.id}/hoai/${check.id}`
      }
    });
  }

  get bimBaseChecks(): CheckResultData[] {
    return this.data.bimBaseChecks!.map(check => {
      return {
        date: check.checkDate!,
        values: {
          "Geprüfte Datei": check.fileName!,
          "Geprüfte Regeln": String((check.numberOfFailedRules ?? 0) + (check.numberOfSuccessfulRules ?? 0) + (check.numberOfWarningRules ?? 0)),
        },
        succeeded: check.numberOfSuccessfulRules ?? 0,
        warning: check.numberOfWarningRules ?? 0,
        failed: check.numberOfFailedRules ?? 0,
        url: `/projects/${this.project.id}/reports/bim-base/${check.id}`
      }
    });
  }

    get loiChecks(): CheckResultData[] {
    return this.data.loiChecks!.map(check => {
      return {
        date: check.checkDate!,
        values: {
          "Geprüfte Datei": check.fileName!,
          "Geprüfte LOI-Check-Regel": check.checkRuleName ?? "",
          "Geprüfte Bauteile": String(check.numberOfParts ?? 0),
          "Geprüfte Attribute": String(check.numberOfAttributes ?? 0),

        },
        succeeded: check.percentOfSuccessfulRules ?? 0,
        warning: 0,
        failed: check.percentOfFailedRules ?? 0,
        url: `/projects/${this.project.id}/reports/ifc/${check.id}`
      }
    });
  }

      get qualityChecks(): CheckResultData[] {
    return this.data.qualityChecks!.map(check => {
      return {
        date: check.checkDate!,
        values: {
          "Geprüfte IFC Modelle": String(check.numberOfIFCFiles ?? 0),
          "Geprüfte Leistungsverzeichnisse": String(check.numberOfLVFiles ?? 0),
          "Geprüfte Raumbücher": String(check.numberOfRBFiles ?? 0),
          "Geprüfte Regeln": String(check.numberOfCheckRules ?? 0),

        },
        succeeded: check.numberOfSuccessfulRules ?? 0,
        warning: check.numberOfWarningRules ?? 0,
        failed: check.numberOfFailedRules ?? 0,
        url: `/projects/${this.project.id}/quality/${check.id}/report`
      }
    });
  }


  ngOnInit(): void {

    this.dashboardController.getProjectDashboard(this.stateConfigService.getProjectId()).subscribe(data => {

      this.data = data;

      this.data.qualityChecks?.forEach(check => {
        check.fileChecks?.forEach(fileCheck => {
          let results = fileCheck.result ?? [];
          let keys = Object.keys(results);
          for (let key of keys) {
            let result = results[key];
            result.forEach((resultEntry: any) => {
              if (resultEntry.type == "error") {
                check.numberOfFailedRules = check.numberOfFailedRules ? check.numberOfFailedRules + 1 : 1
              } else if (resultEntry.type == "warning") {
                check.numberOfWarningRules = check.numberOfWarningRules ? check.numberOfWarningRules + 1 : 1
              } else {
                check.numberOfSuccessfulRules = check.numberOfSuccessfulRules ? check.numberOfSuccessfulRules + 1 : 1
              }
            })
          }
          this.resultArrays.set("QUALITY", this.buildResultArray("QUALITY"));
        });
      });

    });

  }

  changeStep(check: string, index: number) {

    this.selectedChecks.set(check, index);
    this.resultArrays.set(check, this.buildResultArray(check));
  }

  buildResultArray(check: string) {
    let array: any[] = [];
    if (this.data) {
      if (check == "HOAI") {
        array.push({
          name: "Erfüllt",
          value: this.data.hoaiChecks![this.selectedChecks.get(check)!].numberOfSuccessfulServices ?? 0
        });
        array.push({
          name: "Teilweise Erfüllt",
          value: 0
        });
        array.push({
          name: "Nicht Erfüllt",
          value: this.data.hoaiChecks![this.selectedChecks.get(check)!].numberOfFailedServices ?? 0
        });
      }
      if (check == "BIM") {
        array.push({
          name: "Erfüllt",
          value: this.data.bimBaseChecks![this.selectedChecks.get(check)!].numberOfSuccessfulRules ?? 0
        });
        array.push({
          name: "Teilweise Erfüllt",
          value: this.data.bimBaseChecks![this.selectedChecks.get(check)!].numberOfWarningRules ?? 0
        });
        array.push({
          name: "Nicht Erfüllt",
          value: this.data.bimBaseChecks![this.selectedChecks.get(check)!].numberOfFailedRules ?? 0
        });
      }
      if (check == "LOI") {
        array.push({
          name: "Erfüllt",
          value: this.data.loiChecks![this.selectedChecks.get(check)!].percentOfSuccessfulRules ?? 0
        });
        array.push({
          name: "Teilweise Erfüllt",
          value: 0
        });
        array.push({
          name: "Nicht Erfüllt",
          value: this.data.loiChecks![this.selectedChecks.get(check)!].percentOfFailedRules ?? 0
        });
      }
      if (check == "QUALITY") {
        array.push({
          name: "Erfüllt",
          value: this.data.qualityChecks![this.selectedChecks.get(check)!].numberOfSuccessfulRules ?? 0
        });
        array.push({
          name: "Teilweise Erfüllt",
          value: this.data.qualityChecks![this.selectedChecks.get(check)!].numberOfWarningRules ?? 0
        });
        array.push({
          name: "Nicht Erfüllt",
          value: this.data.qualityChecks![this.selectedChecks.get(check)!].numberOfFailedRules ?? 0
        });
      }
    }
    return array;

  }

  test() {
    let test = this.resultArrays.get('HOAI')
    return test;
  }

  transformDateToString(date: Date): string {
    let datePipe = new DatePipe('de-DE');
    return <string>datePipe.transform(date, "dd.MM.yyyy HH:mm");
  }

  getSizeOfFolder(size: number): string {
    if (this.calculateSizeInGB(size) < 1) {
      return this.calculateSizeInMB(size).toFixed(2) + ' MB'
    } else {
      return this.calculateSizeInGB(size).toFixed(2) + ' GB'
    }
  }

  getNumberOfTasks(): number {
    let sum = 0;
    if (this.data) {
      for (let state of this.states) {
        sum += this.data.tasks![state]?.length ?? 0;
      }
    }
    return sum;
  }

  getPercentageOfTasks(state: string): string {
    if (this.data) {
      if (this.getNumberOfTasks() == 0) {
        return "0";
      }
      return ((this.data.tasks![state]?.length ?? 0) / this.getNumberOfTasks() * 100).toFixed(0);
    } else {
      return "0";
    }
  }


  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }

  calculateSizeInGB(size: number): number {
    return size / 1024 / 1024 / 1024;
  }


  calculateArraysForChecks() {
    this.checks = [];
    for (let check of this.lastChecks!) {
      if (check.checkType == "HOAI") {
        if (check.checkResults == undefined || check.checkResults.length == 0) {
          let array: any[] = [{
            name: "Erfüllt",
            value: 0
          }, {
            name: "Teilweise Erfüllt",
            value: 0
          }, {
            name: "Nicht Erfüllt",
            value: 100
          }];
          this.checks.push(array);
        } else {
          let array: any[] = [{
            name: "Erfüllt",
            value: Math.round((check.checkResults?.reduce((acc: number, cur: {
              succeed: any;
            }) => cur.succeed ? acc + 1 : acc, 0) ?? 0) / check.partialServiceNames?.length * 100)
          }, {
            name: "Teilweise Erfüllt",
            value: 0
          }, {
            name: "Nicht Erfüllt",
            value: 100 - Math.round((check.checkResults?.reduce((acc: number, cur: {
              succeed: any;
            }) => cur.succeed ? acc + 1 : acc, 0) ?? 0) / check.partialServiceNames?.length * 100)
          }];
          this.checks.push(array);
        }
      } else if (check.checkType == "IFC") {
        if (check.results.startsWith("[")) {
          let result = JSON.parse(check.results);

          let array: any[] = [{
            name: "Erfüllt",
            value: this.medianOfPercentages(result)
          }, {
            name: "Teilweise Erfüllt",
            value: 0
          }, {
            name: "Nicht Erfüllt",
            value: 100 - this.medianOfPercentages(result)
          }];
          this.checks.push(array);
        } else {
          let array: any[] = [{
            name: "Erfüllt",
            value: 0
          }, {
            name: "Teilweise Erfüllt",
            value: 100
          }, {
            name: "Nicht Erfüllt",
            value: 0
          }];
          this.checks.push(array);
        }

      } else {
        let positions = this.positions(check);
        let redState = positions.filter((position: {
          state: CircleState;
        }) => position.state == CircleState.RED).length;
        let yellowState = positions.filter((position: {
          state: CircleState;
        }) => position.state == CircleState.YELLOW).length;
        let greenState = positions.filter((position: {
          state: CircleState;
        }) => position.state == CircleState.GREEN).length;

        let array: any[] = [{
          name: "Erfüllt",
          value: Math.round(greenState / positions.length * 100)
        }, {
          name: "Teilweise Erfüllt",
          value: Math.round(yellowState / positions.length * 100)
        }, {
          name: "Nicht Erfüllt",
          value: Math.round(redState / positions.length * 100)
        }];

        this.checks.push(array);
      }
    }
  }

  positions(check: QualityCheck): any[] {
    return Object.entries(check.results!).map(([key, value]) => {
      const data = value != "" ? (JSON.parse(value).ifcPositions as CheckItem[]) : []
      return {
        rule: key,
        result: data,
        state: this.stateOfResult(data)
      }
    });
  }

  stateOfResult(value: CheckItem[]): CircleState {
    if (value?.length === 0) {
      return CircleState.YELLOW;
    }
    // if (value?.filter(result => result.check.errors.length > 0).length > 0) {
    //   return CircleState.RED;
    // }
    // if (value?.filter(result => result.check.warnings.length > 0).length > 0) {
    //   return CircleState.YELLOW;
    // }
    return CircleState.GREEN;
  }

  medianOfPercentages(json: LoiElement[]): number {
    let value = Object.values(json).map(element => this.medianOfPercentagesElement(element)).reduce((a, b) => a + b, 0) / json.length
    return isNaN(value) ? 0 : Math.round(value)
  }

  medianOfPercentagesElement(element: LoiElement): number {
    let value = element.properties.map(property => this.percentCorrectProperty(property)).reduce((a, b) => a + b, 0) / element.properties.length
    return isNaN(value) ? 0 : Math.round(value)
  }

  percentCorrectProperty(property: LoiProperties): number {
    let value = property.guidListCorrect.length / (property.guidListCorrect.length + property.guidListFail.length) * 100
    return isNaN(value) ? 0 : Math.round(value)
  }


  getFoundedGL(): number {
    let gl = 0;
    for (let result of this.selectedCheck?.partialServiceNames!) {
      if (result.substring(0, 2) == "GL") {
        gl++;
      }
    }
    return gl;
  }

  getFoundedBL(): number {
    let bl = 0;
    for (let result of this.selectedCheck?.partialServiceNames!) {
      if (result.substring(0, 2) == "BL") {
        bl++;
      }
    }
    return bl;
  }

  getSucceededPartialServices(): number {
    return this.selectedCheck?.checkResults?.filter(result => result.succeed == true).length!;
  }

  getFailedPartialServices(): number {
    return this.getFoundedBL() + this.getFoundedGL() - this.getSucceededPartialServices();
  }


  setSelectedCheck(check: CompletenessCheck): void {
    this.selectedCheck = check;
  }


  navigateToTaskBoard(): void {
    this.router.navigate(['/tasks/list']);
  }

  navigateToFiles(): void {
    this.router.navigate(['/projects/' + this.project.id + '/files']);
  }

  navigateToProjectSettings(): void {
    this.router.navigate(['/projects/' + this.project.id + '/settings']);
  }

  navigateToReports(): void {
    this.router.navigate(['/projects/' + this.project.id + '/reports']);
  }

  navigateToHOAICheck() {
    this.router.navigate(['/projects/' + this.project.id + '/hoai']);
  }

  navigateToQualityCheck() {
    this.router.navigate(['/projects/' + this.project.id + '/quality']);
  }

  navigateToIfcCheck() {
    this.router.navigate(['/projects/' + this.project.id + '/reports/ifc/' + this.selectedCheck?.id]);
  }

  navigateToBIMBasisCheck() {
    this.router.navigate(['/projects/' + this.project.id + '/bim-basis-check']);
  }

  navigateToLOICheck() {
    this.router.navigate(['/projects/' + this.project.id + '/loi-check']);
  }

  navigateToSpecificHOAICheck(check: string) {
    this.router.navigate(['/projects/' + this.project.id + '/hoai/' + check]);
  }

  navigateToSpecificQualityCheck(check: string) {
    this.router.navigate(['/projects/' + this.project.id + '/quality/' + check + '/report']);
  }

  navigateToSpecificBIMBasisCheck(check: string) {
    this.router.navigate(['/projects/' + this.project.id + '/reports/bim-base/' + check]);
  }

  navigateToSpecificLOICheck(check: string) {
    this.router.navigate(['/projects/' + this.project.id + '/reports/ifc/' + check]);
  }


  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showGridLines = false;
  rotateXAxisTicks = false;
  noBarWhenZero = false;
  showDataLabel = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = true;
  yAxisLabel = 'Population';

  // options for pie-chart
  showLegendPie = false;
  showLabelsPie = true;
  isDoughnutPie = true;


  colorSchemeTasks: Color = {
    name: 'bicChartScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['rgba(4,46,63,0.45)', 'rgba(44,160,154,0.45)', 'rgba(44,160,154,0.65)', '#2CA09A']
  };

  colorSchemeCheck: Color = {
    name: 'bicChartScheme',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#009999', '#FFC000', '#EE3B3B']
  };

  faLinkIcon = faChevronRight;
  faDropDownIcon = faChevronDown;


  protected readonly Object = Object;
  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly LphEnum = LphEnum;
  protected readonly faCube = faCube;
  protected readonly faInfo = faInfo;
  protected readonly faBadgeCheck = faBadgeCheck;
  protected readonly faInfoCircle = faInfoCircle;
  protected readonly faClipboardCheck = faClipboardCheck;
}
