import {AfterViewInit, Component, OnInit} from '@angular/core';
import {faCircleQuestion, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {DocType, Parameter, QualityService} from "../../../quality-api";
import {Observable} from "rxjs";
import {StateConfigService} from "../../../services/state/state-config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DrawerContentService} from "../../../services/drawer-content.service";
import {MatDialog} from "@angular/material/dialog";
import {QualitySettingsComponent} from "../quality-settings/quality-settings.component";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {FileControllerService, ProjectFile, QualityCheck, QualityControllerService, Rule} from "../../../api";
import {
  faChevronLeft,
  faChevronRight,
  faFilter,
  faPlusCircle,
  faCircleXmark,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import {QualityCheckConfirmationComponent} from "../quality-check-confirmation/quality-check-confirmation.component";
import {PermissionService} from "../../../services/permission/permission.service";
import {faSquareDashedCirclePlus} from "@fortawesome/pro-solid-svg-icons";
import {faCircleInfo} from "@fortawesome/pro-solid-svg-icons";
import {initFlowbite} from "flowbite";

@Component({
  selector: 'app-quality-choose-rules',
  templateUrl: './quality-choose-rules.component.html',
  styleUrls: ['./quality-choose-rules.component.scss']
})
export class QualityChooseRulesComponent implements OnInit, AfterViewInit {

  check!: QualityCheck
  overlayOpenedFor!: string;

  rules: Rule[] = [];
  selectedRules: Rule[] = [];

  ifcFiles: ProjectFile[] = [];
  lvFiles: ProjectFile[] = [];
  roomBookFiles: ProjectFile[] = [];

  allFiles: ProjectFile[] = [];

  filteredAllFiles!: Observable<ProjectFile[]>

  categoryFilter: string[] = []

  dropZoneClassName: string = "custom-file-drop-check";
  contentClassName: string = "custom-file-drop-check-content";
  entered: boolean = false;

  constructor(private fileControllerService: FileControllerService,
              public stateConfigService: StateConfigService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public permissionService: PermissionService,
              public drawerService: DrawerContentService,
              public qualityService: QualityService,
              private dialog: MatDialog,
              private qualityControllerService: QualityControllerService) {

    this.ifcFiles = this.router.getCurrentNavigation()!.extras!.state!.ifcFiles;
    this.lvFiles = this.router.getCurrentNavigation()!.extras!.state!.lvFiles;
    this.roomBookFiles = this.router.getCurrentNavigation()!.extras!.state!.roomBookFiles;
  }

  ngOnInit(): void {
    this.check = this.activatedRoute.snapshot.data.qualityCheck;

    this.allFiles = this.ifcFiles.concat(this.lvFiles).concat(this.roomBookFiles);


    this.qualityControllerService.getRules1().subscribe(rules => {
      this.rules = rules;
      this.rules = this.filteredRules;
    });
  }

  ngAfterViewInit() {
    initFlowbite()
  }

  get docTypes(): DocType[] {
    const types = this.rules.map(rule => rule.docTypes ?? []).flat()

    // remove duplicates by id
    return types.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
  }

  get selectedDocTypes(): DocType[] {
    //const keys = Object.keys(this.check.projectFiles!).filter(key => this.check.projectFiles![key].length != 0).map(key => key);
    //const keys = this.allFiles.map(file => file.fileType).filter((value, index, self) => self.indexOf(value) === index);
    let keys = ['IFC', 'LV', 'RB'].filter((key, index) => [this.ifcFiles, this.lvFiles, this.roomBookFiles][index].length > 0);

    const types: DocType[] = []
    for (let key of keys) {
      types.push(this.docTypes.find(type => type.name === key)!);
    }
    return types;
  }

  get filteredRules(): Rule[] {
    return this.rules.filter(rule => this.selectedDocTypes.some(docType => rule.docTypes?.map(type => type.name).includes(docType.name)));
  }

  addRuleToSelectedRules(rule: Rule) {
    if (!this.selectedRules.includes(rule)) {
      this.selectedRules.push(rule);
    }
    this.rules = this.rules.filter(r => r !== rule);
  }

  areAllRulesSelected(): boolean {
    return this.rules.length === 0;
  }

  removeRuleFromSelectedRules(rule: Rule) {
    this.selectedRules = this.selectedRules.filter(selectedRule => selectedRule !== rule);
    this.rules.push(rule);
    this.rules.sort((a, b) => a.name!.localeCompare(b.name!) );
  }

  addAllRulesToSelectedRules() {
    this.selectedRules = this.selectedRules.concat(this.rules);
    this.rules = [];
  }

  addOrRemoveFilteredRulesFromSelectedRules(){
    if(this.filterRules.length==0){ //remove
      this.rules = this.rules.concat(this.selectedRules)
      this.rules.filter((obj1, index) => this.rules.indexOf(obj1) === index)
      this.selectedRules = []
    } else { //add
      this.selectedRules = this.selectedRules.concat(this.filterRules)
      this.rules = this.rules.filter(obj1 =>
        !this.selectedRules.some(obj2 => obj1.id === obj2.id)
      );
    }
  }

  removeAllRulesFromSelectedRules() {
    this.rules = this.rules.concat(this.selectedRules);
    this.selectedRules = [];
  }

  getFileName(option: ProjectFile): string {
    return option.name ?? "";
  }

  get parametersOfSelectedRules(): Parameter[] {
    const parameters = this.selectedRules.map(rule => rule.parameters ?? []).flat();

    // distinct by id
    return parameters.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
  }

  startQualityCheck() {
    if(this.parametersOfSelectedRules.length !== 0) {

      const dialogRef = this.dialog.open(QualitySettingsComponent, {
        panelClass: 'rounded-corners-dialog',
        data: {
          parameters: this.parametersOfSelectedRules
        },
        width: '750px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log(result)
          this.openPopup(result)
        }
      });
    }else{
      this.openPopup({});
    }
  }

  private openPopup(parameters: any) {
    const projectFiles ={
      "IFC": this.ifcFiles,
      "LV": this.lvFiles,
      "RB": this.roomBookFiles
    }

    const ref = this.dialog.open(QualityCheckConfirmationComponent, {
      panelClass: 'rounded-corners-dialog',
      width: '564px',
    });
    ref.afterClosed().subscribe(result => {
      console.log(result)
      if (result) {
        this.qualityControllerService.startCheck1({
          projectFiles: projectFiles,
          ruleIds: this.selectedRules.map(rule => rule.id!),
          parameters: parameters,
        }, this.stateConfigService.getProjectId()).subscribe(() => {
          this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'reports']);
        });
      }
    });
  }


  drop(event: CdkDragDrop<Rule[]>) {
    if (event.previousContainer === event.container) {
      // Wenn das Element in der gleichen Liste verschoben wird, verschieben Sie das Element innerhalb der Liste.
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Wenn das Element von einer Liste zur anderen verschoben wird, übertragen Sie das Element zwischen den Listen.
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  public fileOver(event: any) {
    this.dropZoneClassName = "custom-file-drop-check-drag";
    this.contentClassName = "custom-file-drop-check-content-drag";
    this.entered = true;
  }

  public fileLeave(event: any) {
    this.dropZoneClassName = "custom-file-drop-check";
    this.contentClassName = "custom-file-drop-check-content";
    this.entered = false;
  }

  get isStartQualityCheckDisabled(): boolean {
    return this.selectedRules?.length === 0 || this.isDisabled || !this.canCheckQuality;
  }

  get isDisabled(): boolean {
    //return Object.values(this.check.extractionIdsOfProjectFiles!).some(value => value === undefined);
    return false
  }

  get canCheckQuality(): boolean {
    return true //this.stateConfigService.canCheckQuality();
  }

  private _filter(value: string): ProjectFile[] {
    const filterValue = value.toLowerCase();

    return this.allFiles.filter(file => file.name!.toLowerCase().includes(filterValue));
  }

  get getCategories(): string[]{
    const beginnings = new Set<string>();

    this.rules.forEach(rule => {
      const beginning = rule.name.split(' ')[0];
      beginnings.add(beginning);
    });

    return Array.from(beginnings);
  };

  get filterRules(){
    if (this.categoryFilter.length === 0) {
      return this.rules;
    } else {
      return this.rules.filter(rule => {
        const category = rule.name.split(' ')[0];
        return this.categoryFilter.includes(category);
      });
    }
  }

  onCategoryFilterSelect(event: Event, category: string): void {
    console.log('in category select', category)
    const checkbox = event.target as HTMLInputElement;
    const checked = checkbox.checked ?? !(document.getElementById(`checkbox-${category}`) as HTMLInputElement)?.checked;

    if (checked) {
      this.categoryFilter.push(category);
    } else {
      this.categoryFilter = this.categoryFilter.filter(filter => filter !== category);
    }
  }

  getRuleInfoTooltip(rule:Rule){
    return rule.hint+"<br><b>Überprüfte Bauteile:</b> "+rule.parts+"<br><b>Notwendige Nutzerangaben:</b> "+rule.parametersText+"<br><b>Notwendige Modellinformationen:</b> "+rule.required_model_information+"<br><b>Überprüfte Dokumententypen:</b> "+rule.docTypesText
  }

  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faFolderOpen = faFolderOpen;
  protected readonly faCircleQuestion = faCircleQuestion;
  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faFilter = faFilter;
  protected readonly faSquareDashedCirclePlus = faSquareDashedCirclePlus;
  protected readonly faCircleInfo = faCircleInfo;
  protected readonly faChevronDown = faChevronDown;
}
