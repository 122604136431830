<div class="flex flex-col h-full w-full justify-start items-start bg-[#FFFFFF]">
  <app-hoai-stepper class="w-full"></app-hoai-stepper>

  <div class="flex flex-col justify-start items-start px-6 pt-12 w-full gap-6">
    <p class="flex text-gray-900 font-medium">Leistungsbild Gebäude und Innenräume:</p>


    <div class="flex flex-row justify-start item-start w-full">

      <bic-button [iconRight]="faChevronDown" [text]="'Leistungsphase ' + ((selectedLph | lphToNumber) + 1)"
                  [textColor]="'gray-900'"
                  [color]="'#FFF'" [border]="true" [opacityHover]="false"
                  [bicDropdownTrigger]="ruleFilterDropdown"></bic-button>

      <bic-dropdown #ruleFilterDropdown dropdownPlacement="bottom-start">
        <bic-dropdown-element value="Leistungsphase 1"
                              (onClick)="navigateToLPH(lphEnum.LPH1); ruleFilterDropdown.hide()">
          <input type="checkbox" value="" id="checkbox-LPH1"
                 class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                 [checked]="checkedLPH=='LPH1'">
          <label class="text-sm pl-3 font-medium text-gray-900">Leistungsphase 1</label>
        </bic-dropdown-element>
        <bic-dropdown-element value="Leistungsphase 2"
                              (onClick)="navigateToLPH(lphEnum.LPH2); ruleFilterDropdown.hide()">
          <input type="checkbox" value="" id="checkbox-LPH2"
                 class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                 [checked]="checkedLPH=='LPH2'">
          <label class="text-sm pl-3 font-medium text-gray-900">Leistungsphase 2</label>
        </bic-dropdown-element>
        <bic-dropdown-element value="Leistungsphase 3"
                              (onClick)="navigateToLPH(lphEnum.LPH3); ruleFilterDropdown.hide()">
          <input type="checkbox" value="" id="checkbox-LPH3"
                 class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                 [checked]="checkedLPH=='LPH3'">
          <label class="text-sm pl-3 font-medium text-gray-900">Leistungsphase 3</label>
        </bic-dropdown-element>
        <bic-dropdown-element value="Leistungsphase 4"
                              (onClick)="navigateToLPH(lphEnum.LPH4); ruleFilterDropdown.hide()">
          <input type="checkbox" value="" id="checkbox-LPH4"
                 class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                 [checked]="checkedLPH=='LPH4'">
          <label class="text-sm pl-3 font-medium text-gray-900">Leistungsphase 4</label>
        </bic-dropdown-element>
        <bic-dropdown-element value="Leistungsphase 5"
                              (onClick)="navigateToLPH(lphEnum.LPH5); ruleFilterDropdown.hide()">
          <input type="checkbox" value="" id="checkbox-LPH5"
                 class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                 [checked]="checkedLPH=='LPH5'">
          <label class="text-sm pl-3 font-medium text-gray-900">Leistungsphase 5</label>
        </bic-dropdown-element>
      </bic-dropdown>
    </div>
  </div>

  <div class="flex flex-col h-full w-full justify-start items-start gap-4 pt-8 pb-6 overflow-hidden" *ngIf="services">
    <div class="flex flex-row w-full gap-6 pb-8">
      <div class="flex flex-col w-1/2 pl-6 gap-8">
        <div class="flex flex-row">
          <p class="text-gray-900 ">Verfügbare Prüfregeln:</p>
        </div>
        <div class="flex flex-row justify-end items-center gap-1 pr-4">
          <bic-button text="Alle" textColor="gray-900" color="#FFF" border="true" [opacityHover]="false"
                      (onClick)="addOrRemoveAllServices()"></bic-button>
          <bic-button text="GL" textColor="gray-900" color="#FFF" border="true" [opacityHover]="false"
                      (onClick)="addOrRemoveAllGL()"
                      tooltip="Grundleistungen" *ngIf="selectedLph != LphEnum.LPH4"></bic-button>
          <bic-button text="BL" textColor="gray-900" color="#FFF" border="true" [opacityHover]="false"
                      (onClick)="addOrRemoveAllBL()"
                      tooltip="Besondere Leistungen" *ngIf="selectedLph != LphEnum.LPH4"></bic-button>
        </div>
      </div>

      <div class="flex flex-col w-1/2 pr-6 pl-2">
        <div class="flex flex-row">
          <p class="text-gray-900">Ausgewählte Prüfregeln:</p>
        </div>
      </div>
    </div>

    <div class="flex flex-row overflow-hidden h-full w-full gap-4">

      <div class="flex flex-col w-1/2">
        <div *ngIf="allServices.length==0" class="flex h-full justify-center items-center">Alle Prüfregeln ausgewählt
        </div>
        <div class="flex flex-col justify-start items-start h-full w-full overflow-hidden">
          <div class="flex flex-col flex-grow w-full overflow-y-scroll gap-3 py-1 pl-6 pr-4" cdkDropList
               #allQualityRulesList="cdkDropList"
               [cdkDropListData]="allServices"
               [cdkDropListConnectedTo]="selectedServicesList"
               (cdkDropListDropped)="drop($event)">
            <ng-container>
              <div
                class="flex flex-row shadow w-full rounded-lg justify-between items-center hover:cursor-grab"
                *ngFor="let rule of allServices" cdkDrag>
                <div class="flex flex-row justify-start items-center p-4 gap-4">
                  <ng-container *ngIf="!selectedServices.includes(rule)">
                    <button (click)="addToSelectedServices(rule)">
                      <fa-icon class="text-primary text-lg" [icon]="faPlusCircle"></fa-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="selectedServices.includes(rule)">
                    <button mat-icon-button (click)="removeFromSelectedServices(rule)">
                      <fa-icon [icon]="faCircleXmark" class="text-bic-yellow"></fa-icon>
                    </button>
                  </ng-container>
                  <div class="font-medium text-gray-700">
                    {{ rule | service }}
                  </div>
                </div>

                <div class="flex flex-row justify-end items-center gap-2 p-2">
                  <div class="flex flex-row justify-end items-center gap-2">
                    <div
                      class="bg-[#E6EAEC] p-0.5 px-3 rounded font-medium text-sm text-gray-500"
                      [tooltip]="isLPH4() ? 'Leistung' : isGL(rule) ? 'Grundleistung': 'Besondere Leistung'"
                      [tooltipDirection]="'left'">
                      {{ isLPH4() ? 'Leistung' : isGL(rule) ? 'GL' : 'BL' }}
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

      </div>

      <!--div class="w-1 bg-primary rounded-lg">
      </div-->

      <div class="flex flex-col w-1/2">

        <div class="flex flex-col justify-start items-start h-full w-full pr-6">
          <div class="flex flex-col flex-grow w-full pl-2 py-1 pr-2 h-[inherit]" cdkDropList
               #selectedServicesList="cdkDropList"
               [cdkDropListData]="selectedServices"
               [cdkDropListConnectedTo]="allQualityRulesList"
               (cdkDropListDropped)="drop($event); fileLeave($event)"
               (cdkDropListEntered)="fileOver($event)"
               (cdkDropListExited)="fileLeave($event)">

            <div class="flex flex-col w-full h-full">
              <div class=" flex flex-col w-full overflow-y-scroll">
                <div class=" flex flex-col w-full justify-between gap-3 pr-2" [ngClass]="{'pb-3': selectedServices.length>0}">
                  <div
                    class="flex flex-row shadow w-full rounded-lg justify-between items-center hover:cursor-grab"
                    *ngFor="let rule of selectedServices" cdkDrag>
                    <div class="flex flex-row justify-start items-center p-4 gap-4">
                      <button (click)="removeFromSelectedServices(rule)">
                        <fa-icon [icon]="faCircleXmark" class="text-bic-yellow text-lg"></fa-icon>
                      </button>
                      <div class="font-medium text-gray-700">
                        {{ rule | service }}
                      </div>
                    </div>
                    <div class="flex flex-row justify-end items-center gap-2 p-2">
                      <div class="flex flex-row justify-end items-center gap-2">
                        <div
                          class="bg-[#E6EAEC] p-0.5 px-3 rounded font-medium text-sm text-gray-500"
                          [tooltip]="isLPH4() ? 'Leistung' : isGL(rule) ? 'Grundleistung': 'Besondere Leistung'"
                          [tooltipDirection]="'left'">
                          {{ isLPH4() ? 'Leistung' : isGL(rule) ? 'GL' : 'BL' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pr-2 flex-grow min-h-[138px]">
                <div
                  class="flex flex-row justify-center items-center h-full bg-gray-50 border-2 border-dashed border-[#C6C9CB] rounded-lg p-4"
                  [class]="dropZoneClassName" *ngIf="services.length > 0">
                  <div class="flex flex-col justify-center items-start w-full h-full gap-[7px]"
                       [class]="contentClassName">
                    <div class="flex flex-row justify-center items-center w-full" *ngIf="!entered">
                      <!--mat-icon class="scale-[5]" [svgIcon]="'plus-circle'"></mat-icon-->
                      <fa-icon class="text-gray-400 text-[48px]" [icon]="faSquareDashedCirclePlus"></fa-icon>
                    </div>
                    <div class="flex flex-row justify-center items-center w-full text-gray-400">
                      <text *ngIf="entered">Hier loslassen</text>
                      <text *ngIf="!entered"><b>Prüfregeln</b> per Drag & Drop auswählen</text>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full justify-center items-center">
      <div [tooltipDirection]="'top'"
           [tooltip]="selectedServices.length == 0 ? 'Keine Prüfung ausgewählt' : !canCheckHOAI ? 'Kein Kontingent verfügbar' : undefined">
        <!--button color="primary" mat-raised-button
                [disabled]="!permissionService.hasProjectPrivilege('PROJECT_QUALITY_CHECK') || isStartQualityCheckDisabled"
                (click)="startQualityCheck()">
          Konformitäts-Check
          <fa-icon [icon]="faChevronRight" class="text-white"></fa-icon>
        </button-->
        <bic-button (click)="startCheck()" [text]="'Überprüfen'"
                    [disabled]="!permissionService.hasProjectPrivilege('PROJECT_QUALITY_CHECK') || isStartHOAICheckDisabled || !canCheckHOAI"
                    [iconRight]="faChevronRight">
        </bic-button>
      </div>
    </div>
  </div>
</div>

