/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Privilege { 
    id?: string;
    name?: string;
    type?: Privilege.TypeEnum;
    description?: string;
}
export namespace Privilege {
    export type TypeEnum = 'PROJECT' | 'ORGANISATION' | 'CUSTOM';
    export const TypeEnum = {
        PROJECT: 'PROJECT' as TypeEnum,
        ORGANISATION: 'ORGANISATION' as TypeEnum,
        CUSTOM: 'CUSTOM' as TypeEnum
    };
}