import {Component} from '@angular/core';
import {ImageUploadComponent} from "../image-upload/image-upload.component";
import {UserControllerService} from "../../../api";
import {Observable} from "rxjs";
import {ImageCropperComponent} from "../image-upload/image-cropper/image-cropper.component";
import {MatDialog} from "@angular/material/dialog";
import {ViewImageComponent} from "../image-upload/view-image/view-image.component";

/**
 * Upload of a Profile Image
 */
@Component({
  selector: 'app-profile-image-upload',
  templateUrl: '../image-upload/image-upload.component.html',
  styleUrls: ['../image-upload/image-upload.component.scss']
})
export class ProfileImageUploadComponent extends ImageUploadComponent {


  constructor(private userControllerService: UserControllerService, private dialog: MatDialog) {
    super();
  }

  /**
   * Initialization of Component
   */
  ngOnInit(): void {
  }

  /**
   * Uploads the Image using S3
   * @param event upload Event
   */
  uploadImage(event: any) {
    const files = event.target.files as FileList;
    if (files.length > 0) {
      let filename = files[0].name;
      let fileType = files[0].type;
      const _file = URL.createObjectURL(files[0]);
      this.resetInput();
      this.openAvatarEditor(_file)
        .subscribe(
          async (result) => {
            if (result) {
              let croppedFile = await this.urlToFile(result, filename, fileType);
              this.userControllerService.uploadImageForm(croppedFile)
                .subscribe(
                  (data) => {
                    super.image = data.profileImage;
                  })
            }
          }
        )
    }
  }


  async urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, {type: mimeType});
  }

  resetInput() {
    const input = document.getElementById('imageInput') as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  }

  openAvatarEditor(image: string): Observable<any> {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      panelClass: 'rounded-corners-dialog',
      height: '750px',
      data: image,
    });

    return dialogRef.afterClosed();
  }

  openImageOptions(image: string | undefined) {
    let dialogRef = this.dialog.open(ViewImageComponent, {
      panelClass: 'rounded-corners-dialog',
      width: '750px',
      data: {image: image},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'remove') {
        this.userControllerService.removeImage().subscribe(() => {
          super.image = undefined;
        })
      } else if (result === 'upload') {
        super.triggerClickOnInput()
      }
    });

  }


}
