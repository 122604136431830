<div class="flex flex-col h-full w-full justify-start items-start bg-[#FFF]">
  <app-loi-check-stepper class="w-full"></app-loi-check-stepper>

  <div class="flex flex-col h-full w-full justify-start items-start gap-6 py-12 overflow-hidden">
    <div class="flex flex-row w-full gap-6">
      <div class="flex flex-col pl-6 w-1/2">
        <div class="flex flex-row">
          <p class="text-gray-900">Verfügbare Prüfregeln:</p>
        </div>
      </div>

      <div class="flex flex-col pr-6 pl-2 w-1/2">
        <div class="flex flex-row">
          <p class="text-gray-900">Ausgewählte Prüfregeln:</p>
        </div>
      </div>
    </div>

    <div class="flex flex-row w-full gap-6 pl-6">


      <div class="flex flex-row w-1/2 pr-9 justify-between items-center h-fit">
        <bic-button [iconRight]="faChevronDown" [iconLeft]="faFilter" [text]="'Alle anzeigen'" [textColor]="'gray-900'"
                    [color]="'#FFF'" [border]="true" [opacityHover]="false" [bicDropdownTrigger]="loiFilterDropdown"
                    [dropdownTriggerFor]="'loiFilterDropdown'" [dropdownAlign]="'start'"
                    [dropdownDirection]="'bottom'"></bic-button>

        <bic-dropdown #loiFilterDropdown [dropdownPlacement]="'bottom-start'">
          <bic-dropdown-element class="flex flex-col"
                                (click)="onSelectLOIFilter($event, 'all'); $event.stopPropagation()">
            <input type="checkbox" value="" id="checkbox-all"
                   class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                   [checked]="loiFilter.includes('all')">
            <label class="text-sm pl-3 font-medium text-gray-900">Alle Standards</label>
          </bic-dropdown-element>
          <bic-dropdown-element class="flex flex-col"
                                (click)="onSelectLOIFilter($event, 'bic'); $event.stopPropagation()">
            <input type="checkbox" value="" id="checkbox-bic"
                   class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                   [checked]="loiFilter.includes('bic')">
            <label class="text-sm pl-3 font-medium text-gray-900">BIC-Standards</label>
          </bic-dropdown-element>
          <bic-dropdown-element class="flex flex-col"
                                (click)="onSelectLOIFilter($event, 'own'); $event.stopPropagation()">
            <input type="checkbox" value="" id="checkbox-own"
                   class="w-4 h-4 !text-primary bg-gray-50 rounded focus:ring-[none] border border-gray-300"
                   [checked]="loiFilter.includes('own')">
            <label class="text-sm pl-3 font-medium text-gray-900">Eigene Standards</label>
          </bic-dropdown-element>

        </bic-dropdown>

        <bic-button [iconLeft]="faPlus" [text]="'Neuer Standard'" [textColor]="'gray-900'" [color]="'#FFF'"
                    [border]="true" [opacityHover]="false"
                    (onClick)="navigateToCustom()"></bic-button>
      </div>

    </div>

    <div class="flex flex-row overflow-hidden h-full w-full gap-4">

      <div class="flex flex-col w-1/2">
        <div *ngIf="filterRules.length==0" class="flex h-full justify-center items-center">Alle Prüfregeln ausgewählt</div>
        <div class="flex flex-col justify-start items-start h-full w-full overflow-hidden" [ngClass]="{'hidden':filterRules.length==0}">
          <div #allQualityRulesList="cdkDropList" (cdkDropListDropped)="drop($event)"
               [cdkDropListConnectedTo]="selectedQualityRulesList"
               [cdkDropListData]="filterRules"
               cdkDropList
               class="flex flex-col flex-grow w-full overflow-y-scroll gap-3 py-1 pl-6 pr-4">
            <ng-container>
              <div
                *ngFor="let rule of filterRules; let i = index"
                cdkDrag
                class="flex flex-row shadow w-full rounded-lg justify-between items-center hover:cursor-grab">
                <div class="flex flex-row justify-start items-center px-4 py-2 gap-4">
                  <ng-container *ngIf="!selectedRules.includes(rule)">
                    <button (click)="addRuleToSelectedRules(rule)">
                      <fa-icon class="text-primary text-lg" [icon]="faPlusCircle"></fa-icon>
                    </button>
                  </ng-container>
                  <ng-container *ngIf="selectedRules.includes(rule)">
                    <button (click)="removeRuleFromSelectedRules(rule)">
                      <fa-icon [icon]="faCircleXmark" class="text-bic-yellow text-lg"></fa-icon>
                    </button>
                  </ng-container>
                  <div class="font-medium text-gray-700">
                    {{ rule.name }}
                  </div>
                </div>

                <div class="flex flex-row justify-end items-center gap-2 p-2">
                  <fa-icon *ngIf="!rule.id" [icon]="faLock"
                           class="text-gray-400 p-2 pr-2"></fa-icon>
                  <div class="pr-3" *ngIf="rule.id">
                    <button [bicDropdownTrigger]="editStandardDropdown">
                      <fa-icon [icon]="faEllipsisV" class="text-lg text-gray-300 hover:text-gray-400 py-[2px]"></fa-icon>
                    </button>
                    <bic-dropdown #editStandardDropdown [dropdownPlacement]="'bottom-start'">
                      <bic-dropdown-element (click)="navigateToRule(rule.id)" [tooltip]="'Standard bearbeiten'"
                                            class="w-full text-sm">
                        <fa-icon [icon]="faPenToSquare" class="text-gray-300 pr-[0.65rem]"></fa-icon>
                        Bearbeiten
                      </bic-dropdown-element>
                      <bic-dropdown-element (click)="deleteRule(rule.id)" [tooltip]="'Standard löschen'"
                                            class="w-full text-sm">
                        <fa-icon [icon]="faTrash" class="text-error pr-3"></fa-icon>
                        Löschen
                      </bic-dropdown-element>
                    </bic-dropdown>
                  </div>

                </div>

              </div>
            </ng-container>
          </div>
        </div>

      </div>

      <div class="flex flex-col w-1/2">

        <div class="flex flex-col justify-start items-start h-full w-full pr-6">
          <div #selectedQualityRulesList="cdkDropList" (cdkDropListDropped)="drop($event); fileLeave($event)"
               (cdkDropListEntered)="fileOver($event)"
               (cdkDropListExited)="fileLeave($event)"
               [cdkDropListConnectedTo]="allQualityRulesList"
               [cdkDropListData]="selectedRules"
               cdkDropList
               class="flex flex-col flex-grow w-full pl-2 py-1 pr-2 overflow-y-scroll">

            <div class=" flex flex-col h-full justify-between">
              <div class="flex flex-col gap-3 pr-2" [ngClass]="{'pb-3': selectedRules.length>0}">
                <div
                  *ngFor="let rule of selectedRules"
                  cdkDrag
                  class="flex flex-row shadow w-full rounded-lg px-4 py-[13px] gap-4 justify-start items-center hover:cursor-grab">
                  <button (click)="removeRuleFromSelectedRules(rule)">
                    <fa-icon [icon]="faCircleXmark" class="text-bic-yellow text-lg"></fa-icon>
                  </button>
                  <div class="font-medium text-gray-700">
                    {{ rule.name }}
                  </div>
                </div>
              </div>
              <div class="pr-2 flex-grow min-h-[138px]">
              <div
                *ngIf="loiRules.length > 0"
                [class]="dropZoneClassName"
                class="h-full flex flex-row justify-center items-center h-full bg-gray-50 border-2 border-dashed border-[#C6C9CB] rounded-lg">
                <div [class]="contentClassName"
                     class="flex flex-col justify-center items-start w-full h-full gap-[7px]">
                  <div *ngIf="!entered" class="flex flex-row justify-center items-center w-full">
                    <!--mat-icon [svgIcon]="'plus-circle'" class="scale-[5]"></mat-icon-->
                    <!--                  <fa-icon class="text-mid-gray" [icon]="faCircleXmark"></fa-icon>-->
                    <fa-icon class="text-gray-400 text-[48px]" [icon]="faSquareDashedCirclePlus"></fa-icon>
                  </div>
                  <div class="flex flex-row justify-center items-center w-full text-gray-400">
                    <text *ngIf="entered">Hier loslassen</text>
                    <text *ngIf="!entered"><b>Standard</b> per Drag & Drop auswählen</text>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="flex flex-row w-full justify-center items-center">
      <bic-button (onClick)="startLoiCheck()" [text]="'LOI-Check'" [iconRight]="faChevronRight"
                  [tooltipDirection]="'top'"
                  [tooltip]="selectedRules.length != 1 ? 'Bitte genau eine Prüfregel auswählen' : !permissionService.hasProjectPrivilege('PROJECT_BIM_TOOLS_CHECK') ? 'Keine Berechtigung' : ''"
                  [disabled]="selectedRules.length != 1 || !permissionService.hasProjectPrivilege('PROJECT_BIM_TOOLS_CHECK')">
      </bic-button>
    </div>
  </div>

</div>
