import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {faBars, faClipboardCheck, faClipboardList} from "@fortawesome/free-solid-svg-icons";
import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons";
import {faCircleInfo} from "@fortawesome/pro-solid-svg-icons";
import {MatTableDataSource} from "@angular/material/table";
import {CircleState} from "../../../util/design/circle-state/circle-state.component";
import {BIMBaseCheck, BimBaseCheckControllerService, Task} from "../../../../api";
import {TaskDetailsService} from "../../../../services/task-details.service";
import {ActivatedRoute} from "@angular/router";
import {Check} from "@buildinginformationcloud/bic-loi-check/lib/models/RuleInterface";
import {ExchangeService} from "../../../../services/exchange/exchange.service";

export interface CheckRule {
  id: string,
  rule_name: string,
  rule_description: string,
  text: string,
  status: string,
  guid_fail_list: string[],
  guid_pass_list: string[],
}

@Component({
  selector: 'app-bim-check-report',
  templateUrl: './bim-check-report.component.html',
  styleUrls: ['./bim-check-report.component.scss']
})
export class BimCheckReportComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['info', 'rule', 'result', 'state', 'task'];
  dataSource: MatTableDataSource<CheckRule> = new MatTableDataSource<CheckRule>();

  check!: BIMBaseCheck
  openedOverlay: string | null = null;

  protected readonly faBars = faBars;
  protected readonly CircleState = CircleState;
  protected readonly faClipboardList = faClipboardList;

  constructor(private taskDetailService: TaskDetailsService,
              private bimBaseCheckControllerService: BimBaseCheckControllerService,
              private activatedRoute: ActivatedRoute,
              private exchangeService: ExchangeService) {
  }

  ngOnInit() {
    const projectId = this.activatedRoute.snapshot.params.id
    const checkId = this.activatedRoute.snapshot.params.reportId

    this.exchangeService.clear();

    this.bimBaseCheckControllerService.getCheck4(checkId, projectId).subscribe(check => {
      this.check = check;
      this.dataSource.data = JSON.parse(check.results!);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.result) {
      this.dataSource.data = changes.result.currentValue;
    }
  }

  get totalRules() {
    return this.dataSource.data.length;
  }

  totalCompleted() {
    let allCompleted = this.dataSource.data.filter(t => t.status === "success").length;
    return Math.round(allCompleted / this.totalRules * 100);
  }

  totalWarning() {
    let allWarning = this.dataSource.data.filter(t => t.status === "warning").length;
    return Math.round(allWarning / this.totalRules * 100);
  }

  totalError() {
    let allFailed = this.dataSource.data.filter(t => t.status === "error").length;
    return Math.round(allFailed / this.totalRules * 100);
  }

  createTask() {
    let task = {
      markup: {
        topic: {
          title: 'Überarbeiten notwendig für: '
        }
      },
    }
    this.taskDetailService.setOpenedTask(task as Task);
  }

  setGuids(row: CheckRule) {
    this.exchangeService.setSuccessGuids(row.guid_pass_list);
    this.exchangeService.setFailedGuids(row.guid_fail_list);
  }


  protected readonly faQuestionCircle = faCircleQuestion;
  protected readonly faCircleInfo = faCircleInfo;
  protected readonly faClipboardCheck = faClipboardCheck;
}
