<div class="w-full">
  <app-stepper [linear]="true" (selectedIndexChange)="changeStep($event)" [selectedIndex]="selectedStep">
    <cdk-step [label]="'Dateiauswahl'" [completed]="completedSteps.includes(0)" [editable]="false">
    </cdk-step>
    <cdk-step [label]="'Prüfregelauswahl'" [completed]="completedSteps.includes(1)" [editable]="false">
    </cdk-step>
    <cdk-step [label]="'Konformitäts-Check'" [completed]="completedSteps.includes(2)">
    </cdk-step>
    <cdk-step [label]="'Ergebnis - Dateien'" [completed]="completedSteps.includes(3)">
    </cdk-step>
    <cdk-step [label]="'Ergebnis - Prüfregeln'" [completed]="completedSteps.includes(4)">
    </cdk-step>
    <cdk-step [label]="'Ergebnis - Detail'" [completed]="completedSteps.includes(5)">
    </cdk-step>
  </app-stepper>

</div>
