<div class="flex flex-col z-40 h-full w-full bg-white shadow-full rounded-[10px] p-2">
  <!--  <div class="flex flex-row">-->
  <div class="flex flex-col">
    <div class="text-xl font-medium pt-6 px-8 text-center">Abokauf für das Profil
      "{{ selectedOrganisation }}"
    </div>
    <div class="px-20 text-center pt-4">Das ausgewählte Abo "<span class="underline">{{ productName }}</span>" wird für
      das aktuelle Profil
      "<span class="underline">{{ selectedOrganisation }}</span>" gebucht.
    </div>
    <div class="text-center text-primary font-medium pt-6">Hinweis:</div>
    <div class="px-20 text-center">Beim Kauf eines Abos im persönlichen Profil stehen die gekauften Guthaben nur Ihnen
      bzw. Nutzer*innen Ihres Profils zur Verfügung.<br>
      Wird der Kauf für das Profil einer Organisation getätigt können alle Mitglieder mit entsprechender Bereachtigung
      die Guthaben nutzen und Prüfungen durchführen.<br>
      Sie können das ausgewählte Profil jederzeit über die Seitennavigation wechseln.
    </div>
    <!--      <div class="px-32 text-center pt-4">Verbrauchte Guthaben für HOAI-Check: 2 von 7</div>-->
    <div class="flex flex-row pt-4 justify-between">
      <mat-dialog-actions class="flex justify-between w-full">
        <button mat-raised-button class="bg-white w-32" mat-dialog-close>Abbrechen</button>
        <div class="flex-grow"></div>
        <button mat-raised-button color="primary" class="text-white w-32 whitespace-nowrap" [mat-dialog-close]="true">
          Zur Buchung
        </button>
      </mat-dialog-actions>
    </div>
  </div>


  <!--  </div>-->
</div>
