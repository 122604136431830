import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'classificationResult'
})
export class ClassificationResultPipe implements PipeTransform {

  transform(value:  string): string {
    switch (value) {
      case "TEXT":
        return "PDF"
      case "HYBRID":
        return "Hybrid"
      case "PLAN":
        return "Plan"
      default:
        return "Hybrid"
    }
  }

}
