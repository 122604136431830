<div class="w-full">
  <app-stepper [linear]="true" (selectedIndexChange)="changeStep($event)" [selectedIndex]="selectedStep">
    <cdk-step [label]="'Dateiauswahl'" [completed]="completedSteps.includes(0)" [editable]="false">
    </cdk-step>
    <cdk-step [label]="'Prüfregelauswahl'" [completed]="completedSteps.includes(1)" [editable]="false">
    </cdk-step>
    <cdk-step [label]="'Ergebnis'" [completed]="completedSteps.includes(2)">
    </cdk-step>
  </app-stepper>
</div>
