import {Component, Input, OnInit} from '@angular/core';
import {ImageUploadComponent} from "../image-upload/image-upload.component";
import {Organisation, OrganisationControllerService} from "../../../api";
import {Observable} from "rxjs";
import {ImageCropperComponent} from "../image-upload/image-cropper/image-cropper.component";
import {ViewImageComponent} from "../image-upload/view-image/view-image.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-organisation-image-upload',
  templateUrl: '../image-upload/image-upload.component.html',
  styleUrls: ['../image-upload/image-upload.component.scss']
})
export class OrganisationImageUploadComponent extends ImageUploadComponent{


  @Input() organisation!: Organisation;
  constructor(private organisationControllerService: OrganisationControllerService, private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
  }

  uploadImage(event: any) {
    const files = event.target.files as FileList;
    if (files.length > 0) {
      let filename = files[0].name;
      let fileType = files[0].type;
      const _file = URL.createObjectURL(files[0]);
      this.resetInput();
      this.openAvatarEditor(_file)
        .subscribe(
          async (result) => {
            if(result){
              let croppedFile = await this.urlToFile(result, filename, fileType);
              this.organisationControllerService.uploadImage1Form(croppedFile, this.organisation.id!)
                .subscribe(
                  (data) => {
                    super.image = data.profileImage;
                  })
            }
          }
        )
    }


/*    this.organisationControllerService.uploadImage1Form(event.target.files[0], this.organisation.id!).subscribe((organisation) => {
      super.image = organisation.profileImage;
      super.imageChange.emit(organisation.profileImage);
    })*/
  }


  async urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  }

  resetInput(){
    const input = document.getElementById('imageInput') as HTMLInputElement;
    if(input){
      input.value = "";
    }
  }

  openAvatarEditor(image: string): Observable<any> {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      panelClass: 'rounded-corners-dialog',
      data: image,
    });

    return dialogRef.afterClosed();
  }

  openImageOptions(image: string | undefined){
    let dialogRef = this.dialog.open(ViewImageComponent, {
      panelClass: 'rounded-corners-dialog',
      width: '750px',
      data: {image: image},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result === 'remove'){
        this.organisationControllerService.removeImage2(this.organisation.id!).subscribe( () => {
          super.image = undefined;
        })
      }else if(result === 'upload'){
        super.triggerClickOnInput()
      }
    });

  }

}
