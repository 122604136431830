import { Component } from '@angular/core';
import {faArrowRightFromBracket, faBagShopping, faRightFromBracket, faUser} from "@fortawesome/free-solid-svg-icons";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingService} from "../../../../../services/loading/loading.service";
import {AuthService} from "../../../../../services/auth/auth.service";
import {SidebarService} from "../../../../../services/sidebar/sidebar.service";
import {OAuthService} from "angular-oauth2-oidc";
import {OverlayContainer} from "@angular/cdk/overlay";
import {MatDialog} from "@angular/material/dialog";
import {ProjectControllerService, StateControllerService} from "../../../../../api";
import {DrawerContentService} from "../../../../../services/drawer-content.service";
import {StateConfigService} from "../../../../../services/state/state-config.service";
import {PopoutContentService} from "../../../../../services/popout/popout.service";
import {IfcStateService} from "../../../../../services/state/ifc-state.service";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";


@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrls: ['./sidebar-footer.component.scss']
})
export class SidebarFooterComponent {

  showAnalysisMenu = false;
  selectedButton: string = '';

  constructor(public authService: AuthService, public oauthService: OAuthService, public dialog: MatDialog,
              public projectControllerService: ProjectControllerService,
              public stateConfigService: StateConfigService, private stateControllerService: StateControllerService,
              private router: Router) { }

  performRouteLink(routeName: string){
        switch(routeName) {
          case "checkout":
            this.router.navigateByUrl('/checkout')
            break;
          case "profile":
            this.router.navigateByUrl('/profile')
            break;
          case "logout":
            this.authService.logout();
            this.router.navigateByUrl('login')
            break;
        }
  }

  protected readonly faUser = faUser;
  protected readonly faBagShopping = faBagShopping;
  protected readonly faRightFromBracket = faRightFromBracket;
}
