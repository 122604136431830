import {inject, NgModule} from '@angular/core';
import {CanActivateFn, PreloadAllModules, Router, RouterModule} from "@angular/router";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {AuthComponent} from "./components/auth/auth.component";
import {AuthGuardService as AuthGuard} from "./services/auth/auth-guard.service";
import {ProfileComponent} from "./components/profile/profile.component";
import {ProjectsComponent} from "./components/./projects/projects.component";
import {ProjectSettings} from "./components/./projectSettings/project-settings.component";
import {TasksComponent} from "./components/tasks/tasks.component";
import {UserResolver} from "././resolver/user/user.resolver";
import {ProjectResolver} from "././resolver/project/project.resolver";
import {UnsavedChangesGuard} from "./guards/unsaved-changes.guard";
import {CheckoutComponent} from "./components/checkout/checkout.component";
import {ApplicationComponent} from "./components/special/application/application.component";
import {StateConfigService} from "./services/state/state-config.service";
import {PermissionService} from "./services/permission/permission.service";
import {HoaiCheckComponent} from "./components/./hoaiCheck/hoai-check.component";
import {IfcViewerComponent} from "./components/special/ifc-viewer/ifc-viewer.component";
import {ReportComponent} from "./components/report/report.component";
import {hoaiCheckDetailComponent} from "./components/hoaiCheck/hoaiCheckDetail/hoai-check-detail.component";
import {IfcCheckReportComponent} from "./components/report/components/ifcCheckReport/ifc-check-report.component";
import {LoiCheckEditorComponent} from "./components/ifcCheck/components/loi-check-editor/loi-check-editor.component";
import {FilesHomeviewComponent} from "./components/files/files-homeview/files-homeview.component";
import {FileViewerComponent} from "./components/util/file-viewer/file-viewer.component";
import {BimBasisCheckComponent} from "./components/ifcCheck/components/bim-basis-check/bim-basis-check.component";
import {BimToolsComponent} from "./components/bim-tools/bim-tools.component";
import {ChatComponent} from "./components/chat/chat.component";
import {
  FireprotectionCheckComponent
} from "./components/swm/fireprotection/fireprotection-check/fireprotection-check.component";
import {featureFlagGuard, FeatureFlagGuardService} from "./services/feature-flag-guard.service";
import {
  QualityChooseFilesComponent
} from "./components/qualityCheck/quality-choose-files/quality-choose-files.component";
import {
  QualityChooseRulesComponent
} from "./components/qualityCheck/quality-choose-rules/quality-choose-rules.component";
import {QualityCheckResolver} from "./resolver/qualityCheck/quality-check-resolver.service";
import {QualityFileReportComponent} from "./components/qualityCheck/quality-file-report/quality-file-report.component";
import {
  QualityRulesReportComponent
} from "./components/qualityCheck/quality-rules-report/quality-rules-report.component";
import {
  QualityResultReportComponent
} from "./components/qualityCheck/quality-result-report/quality-result-report.component";
import {
  QualityComplianceReport
} from "./components/qualityCheck/quality-compliance-report/quality-compliance-report.component";
import {RoleResponse} from "./api";
import {BimCheckReportComponent} from "./components/report/components/bim-check-report/bim-check-report.component";
import {PlanResultComponent} from "./components/planCheck/plan-result/plan-result.component";
import {PlanChooseFilesComponent} from "./components/planCheck/plan-choose-files/plan-choose-files.component";
import {Observable} from "rxjs";
import {LoadingService} from './services/loading/loading.service';
import {HoaiCheckResolver} from "./resolver/hoaiCheck/hoai-check-resolver.service";

import {
  LoiCheckChooseRulesComponent
} from "./components/ifcCheck/components/loi-check-choose-rules/loi-check-choose-rules.component";
import {
  LoiCheckChooseFilesComponent
} from "./components/ifcCheck/components/loi-check-choose-files/loi-check-choose-files.component";
import {OrganisationListComponent} from "./components/organisationList/organisation-list.component";
import PrivilegesEnum = RoleResponse.PrivilegesEnum;
import {
  FireprotectionReportComponent
} from "./components/swm/fireprotection/fireprotection-report/fireprotection-report.component";
import {SwmCheckResolverService} from "./resolver/swmCheck/swm-check-resolver.service";
import {FileSelectionComponent} from "./components/swm/fireprotection/file-selection/file-selection.component";
import {FileReportComponent} from "./components/swm/fireprotection/file-report/file-report.component";
import {OrganisationResolver} from "./resolver/organisation/organisation.resolver";
import {
  OrganisationSettingsComponent
} from "./components/organisation/organisation-settings/organisation-settings.component";
import {OrganisationCreateComponent} from "./components/organisation/organisation-create/organisation-create.component";

/**
 * Routing Module
 */
@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot([], {
    onSameUrlNavigation: 'reload',
    paramsInheritanceStrategy: 'always',
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(router: Router, stateConfigService: StateConfigService, permissionService: PermissionService) {

    router.config = [
      {path: '', redirectTo: 'organisations', pathMatch: 'full'},
      {
        path: 'projects',
        canActivate: [AuthGuard],
        data: {title: "Projekte"},
        children: [
          {path: '', component: ProjectsComponent, data: {title: null}, pathMatch: 'full'},
          {
            path: 'create',
            resolve: {
              user: UserResolver,
            },
            component: ProjectSettings,
            canActivate: [AuthGuard],
            canDeactivate: [UnsavedChangesGuard],
            data: {title: "Neues Projekt"}
          },
          {
            path: ':id',
            canActivate: [AuthGuard],
            resolve: {
              user: UserResolver,
              project: ProjectResolver,
            },
            children: [
              {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'},
              {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: {title: "Dashboard"}},
              {
                path: 'swm',
                canActivate: [AuthGuard, featureFlagGuard('swm', '')],
                loadChildren: () => import('./components/swm/swm.module').then(m => m.SwmModule),
              },
              {
                path: 'files',
                canActivate: [AuthGuard],
                children: [
                  {
                    path: '',
                    component: FilesHomeviewComponent,
                    data: {title: 'Dateien', titleParam: null},
                  },
                  {
                    path: ':id',
                    component: FileViewerComponent,
                    data: {title: 'Dateien', titleParam: null},
                  },
                ]
              },
              // {path: 'fireprotection', component: FireprotectionCheckComponent, canActivate: [featureFlagGuard('swm', '') ], data: {title: "Brandschutz-Check"}},
              {
                path: 'fireprotection',
                data: {title: "Brandschutz-Check"},
                runGuardsAndResolvers: 'always',
                canActivate: [featureFlagGuard('swm', '') ],
                children: [
                  // {
                  //   path: '', component: FireprotectionCheckComponent
                  // },
                  {
                    path: '', component: FileSelectionComponent
                  },{
                    path: 'ruleSelection', component: FireprotectionCheckComponent
                  },
                  {
                    path: ':checkId',
                    resolve: {
                      swmCheck: SwmCheckResolverService
                    },
                    children: [
                      {path: '', component: FileReportComponent},
                      {path: ':fileId', component: FireprotectionReportComponent}
                    ]
                  }
                ]
              },
              {
                path: 'hoai',
                data: {title: "HOAI-Check"},
                children: [
                  {path: '', component: HoaiCheckComponent},
                  {
                    path: ':checkId',
                    resolve: {
                      hoaiCheck: HoaiCheckResolver
                    },
                    children: [
                      {path: '', redirectTo: 'report', pathMatch: 'full'},
                      {path: 'report', component: hoaiCheckDetailComponent},
                    ]
                  },
                ]
              },
              {path: 'tasks', component: TasksComponent, data: {title: "Tasks"}},
              {
                path: 'quality',
                children: [
                  {path: '', component: QualityChooseFilesComponent, data: {title: "Qualitäts-Check"}},
                  {path: 'files', component: QualityChooseFilesComponent, data: {title: "Qualitäts-Check Dateien"}},
                  {
                    path: 'rules',
                    component: QualityChooseRulesComponent,
                    data: {title: "Qualitäts-Check Prüfregeln"}
                  },
                  {
                    path: ':checkId', resolve: {
                      qualityCheck: QualityCheckResolver,
                    }, children: [
                      {
                        path: 'report', data: {title: "Qualitäts-Check Ergebnis"}, children: [
                          {path: '', component: QualityFileReportComponent},
                          {path: 'compliance', component: QualityComplianceReport},
                          {path: ':fileId', component: QualityRulesReportComponent},
                          {path: ":fileId/:ruleId", component: QualityResultReportComponent}
                        ]
                      }
                    ]
                  },
                ],
              },
              {
                path: 'plan',
                children: [
                  {path: '', component: PlanChooseFilesComponent, data: {title: "Planprüfung"}},
                  {path: 'files', component: PlanChooseFilesComponent, data: {title: "Planprüfung Dateien"}},
                  {path: 'report', component: PlanResultComponent, data: {title: "Planprüfung Ergebnis"}},
                ],
              },
              {path: 'bim-basis-check', component: BimBasisCheckComponent, data: {title: "BIM Basis-Check"}},
              {
                path: 'loi-check',
                children: [
                  {path: '', component: LoiCheckChooseFilesComponent, data: {title: "LOI-Check"}},
                  {
                    path: 'custom',
                    children: [
                      {
                        path: '',
                        component: LoiCheckEditorComponent,
                        data: {title: "LOI-Check (benutzerdefiniert)"}
                      },
                      {
                        path: ':id',
                        component: LoiCheckEditorComponent,
                        data: {title: "LOI-Check (benutzerdefiniert)"}
                      },
                    ]
                  },
                  {
                    path: ':fileId',
                    component: LoiCheckChooseRulesComponent,
                    data: {title: "LOI-Check Regeln"}
                  },
                ]
              },
              {
                path: 'application',
                children: [
                  {path: '', component: ApplicationComponent, data: {title: "Bauantrag"}},
                  {
                    path: 'create',
                    component: ApplicationComponent,
                    data: {title: "Bauantrag anlegen", create: true}
                  },
                ]
              },
              {
                path: 'reports',
                children: [
                  {
                    path: '',
                    component: ReportComponent,
                    data: {
                      title: "Berichte",
                      titleParam: null
                    },
                  },
                  {
                    path: 'hoai/:reportId',
                    component: hoaiCheckDetailComponent,
                    data: {
                      title: "HOAI-Check",
                      titleParam: null,

                    }
                  },
                  {
                    path: 'ifc/:reportId',
                    component: IfcCheckReportComponent,
                    data: {
                      title: "IFC-Check",
                      titleParam: null,
                    }
                  },
                  {
                    path: 'bim-base/:reportId',
                    component: BimCheckReportComponent,
                    data: {
                      title: "BIM-Basis-Check",
                      titleParam: null,
                    }
                  }
                ]
              },
              {
                path: 'settings',
                component: ProjectSettings,
                canDeactivate: [UnsavedChangesGuard],
                data: {title: "Projektdaten", titleParam: null},
              },
              {
                path: 'chat',
                canActivate: [AuthGuard],
                runGuardsAndResolvers: 'always',
                resolve: {
                  project: ProjectResolver,
                  user: UserResolver,
                },
                data: {title: "Chat"},
                children: [
                  {path: '', component: ChatComponent, pathMatch: 'full'},
                  {path: ':chatId', component: ChatComponent},
                  {path: '**', redirectTo: ''}
                ]
              },
            ]
          },
          {path: '**', redirectTo: 'dashboard'}
        ]
      },
      {
        path: 'tasks',
        component: TasksComponent,
        data: {title: "Tasks"}
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            component: ReportComponent,
            data: {
              title: "Berichte",
              titleParam: null
            },
          },
          {
            path: 'hoai/:reportId',
            component: hoaiCheckDetailComponent,
            data: {
              title: "HOAI-Check",
              titleParam: null,

            }
          },
          {
            path: 'ifc/:reportId',
            component: IfcCheckReportComponent,
            data: {
              title: "IFC-Check",
              titleParam: null,
            }
          }
        ]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
        canDeactivate: [UnsavedChangesGuard],
        data: {title: "Account"},
        resolve: {
          user: UserResolver,
        },
      },
      {
        path: 'ifc-viewer',
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        data: {title: "IFC-Viewer"},
        component: IfcViewerComponent,
      },
      {
        path: 'organisations',
        canActivate: [AuthGuard],
        data: {title: "Unternehmen"},
        children:[
          {path: '', component: OrganisationListComponent, data: {title: null}, pathMatch: 'full'},
          {
            path: 'create',
            resolve: {
              user: UserResolver,
            },
            component: OrganisationCreateComponent,
            canActivate: [AuthGuard],
            data: {title: "Neue Organisation"}
          },
          {
            path: ':id',
            canActivate: [AuthGuard],
            resolve: {
              user: UserResolver,
              organisation: OrganisationResolver,
            },
            children: [
              {
                path: 'settings',
                component: OrganisationSettingsComponent,
                canDeactivate: [UnsavedChangesGuard],
                data: {title: "Organisationsdaten", titleParam: null},
              },
            ]
          }
        ]
      },
      {
        path: 'register',
        component: AuthComponent,
        data: {logout: false, resetPassword: false, newVerification: false, isProfile: false, register: true},
      },
      {
        path: 'verify',
        component: AuthComponent,
        data: {verify: true, isPassword: false}
      },
      {
        path: 'verifyNewPassword',
        component: AuthComponent,
        data: {verify: true, isPassword: true}
      },
      {
        path: 'resetPassword',
        component: AuthComponent,
        data: {logout: false, resetPassword: true, newVerification: false, isProfile: false}
      },
      {
        path: 'newVerificationPassword',
        component: AuthComponent,
        data: {logout: false, resetPassword: true, newVerification: true, isProfile: false}
      },
      {
        path: 'newVerificationProfile',
        component: AuthComponent,
        data: {logout: false, resetPassword: true, newVerification: true, isProfile: true}
      },
      {
        path: 'login',
        component: AuthComponent,
        data: {logout: false, resetPassword: false, newVerification: false, isProfile: false}
      },
      {
        path: 'mfa',
        component: AuthComponent,
        data: {logout: false, resetPassword: false, newVerification: false, isProfile: false, isMfa: true}
      },
      {
        path: 'checkout',
        data: {title: "Abo Verwaltung"},
        component: CheckoutComponent
      },
      {
        path: 'logout',
        component: AuthComponent,
        data: {logout: true, resetPassword: false, newVerification: false, isProfile: false}
      },
      {
        path: 'tasks',
        component: TasksComponent,
        canActivate: [AuthGuard],
        data: {title: "Tasks"},
        children: [
          {
            path: 'list',
            component: TasksComponent,
            canActivate: [AuthGuard],
            data: {title: null, tab: 0}
          },
          {
            path: 'board',
            component: TasksComponent,
            canActivate: [AuthGuard],
            data: {title: null, tab: 1}
          },
          {
            path: ':id',
            component: TasksComponent,
            canActivate: [AuthGuard],
            canDeactivate: [UnsavedChangesGuard],
            data: {title: null},
          },
          {
            path: 'create',
            component: TasksComponent,
            canActivate: [AuthGuard],
            canDeactivate: [UnsavedChangesGuard],
            data: {title: null}
          },
          {path: '**', redirectTo: 'list'}
        ]
      },
      {path: '**', redirectTo: ''}
    ];
  }
}


export function privilegeGuard(
  privilege: PrivilegesEnum,
  isProject: boolean = true,
): CanActivateFn {
  return (): Observable<boolean> => {
    const permissionService: PermissionService = inject(PermissionService);
    const loadingService: LoadingService = inject(LoadingService);
    const router: Router = inject(Router);
    const stateService: StateConfigService = inject(StateConfigService);

    return new Observable<boolean>(observer => {

      stateService.updated.subscribe(() => {

        const allowed = isProject ? permissionService.hasProjectPrivilege(privilege) : permissionService.hasOrganisationPrivilege(privilege);

        return allowed ? observer.next(true) : router.navigate(['/projects']);
      });

    });
  };
}
