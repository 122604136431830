/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Chapter } from './chapter';
import { K2Rule } from './k2Rule';

export interface K1Rule { 
    id?: string;
    name?: string;
    result?: Array<Chapter>;
    k2Rules?: Array<K2Rule>;
    deviations?: Array<Chapter>;
}