<div class="w-full h-full flex flex-col justify-end">
  <ng-container *ngIf="chatService.currentChat">
    <div #chatViewContainer class="flex-1 w-full overflow-y-scroll bg-[#FFFFFF] px-2 gap-6 flex flex-col">
      <div *ngFor="let message of chatService.currentChatMessages; index as i">

        <div *ngIf="isNewDate(i)" class="w-full px-1 py-2 pb-8 text-gray-400 flex text-xs justify-center items-center">
          <div class="flex-grow border-t-2 border-gray-100"></div>
          <p class="px-4">{{ message.createdOn | date: 'dd.MM.yyyy' }}</p>
          <div class="flex-grow border-t-2 border-gray-100"></div>
        </div>

<!--        <div *ngIf="message.sender=='user'  && i === chatService.currentChatMessages.length - 1" class="w-full flex justify-center py-2 text-gray-500 text-sm">-->
<!--          <p>Thinking...</p>-->
<!--        </div>-->

        <div *ngIf="message.sender=='user'" class=" flex w-full gap-4">
          <div class="w-[50%]"></div>
          <app-avatar [user]="chatService.getUser()"></app-avatar>
          <div class="rounded-md p-2 bg-[#E6EAEC73] w-[50%] flex items-center">
            <p class="px-2">{{ message.message }}</p>
          </div>
        </div>

        <div *ngIf="message.sender=='user' && i === chatService.currentChatMessages.length - 1 " class="w-full flex gap-4 pr-24">

          <div class="flex items-center justify-center w-8 h-8 bg-gray-50 rounded-full border border-gray-100">
            <mat-icon svgIcon="chatBIC" style="color:#009999 !important;"></mat-icon>
          </div>
          <div class="rounded-md p-2 flex justify-end bg-gray-50 border-gray-100 border">
            <div class="pl-2 flex items-center w-[96%]">
              <p>Typing...</p>
            </div>
          </div>
        </div>

        <div *ngIf="message.sender=='bot'">
          <div class="w-full flex gap-4 pr-24">
            <div class="flex items-center justify-center w-8 h-8 bg-gray-50 rounded-full border border-gray-100">
              <mat-icon svgIcon="chatBIC" style="color:#009999 !important;"></mat-icon>
            </div>
            <div class="rounded-md p-2 w-full flex justify-end bg-gray-50 border-gray-100 border"
                 (mouseenter)="hovering[i] = true" (mouseleave)="hovering[i] = false">
              <div class="pl-2 flex items-center w-[96%]">
                <p>{{ message.message }}</p>
              </div>
              <button [ngClass]="{'flex':hovering[i], 'hidden':!hovering[i]}" class="w-[4%] flex items-start justify-end pr-2"
                      [cdkCopyToClipboard]="message.message"
                      data-tooltip-target="tooltip-copy-{{i}}" type="button"
                      (mouseover)="showTooltip('tooltip-copy-'+i,'tooltip-copy-button-'+i)"
                      id="tooltip-copy-button-{{i}}">
                <fa-icon class="text-gray-200 hover:text-gray-300" [icon]="faCopy"></fa-icon>
              </button>
              <div id="tooltip-copy-{{i}}" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-100 rounded-lg shadow-sm opacity-0 tooltip">
                Kopieren
                <div class="tooltip-arrow" data-popper-arrow></div>
              </div>
              <div *ngIf="!hovering[i]" class="w-[4%] pr-2"></div>
            </div>
          </div>
          <div *ngIf="message.table" class="w-full flex pr-24 pl-12 pt-4">
          <bic-table class="w-full" [data]="message.table.rows" [columns]="message.table.columns"
                     [columnNames]="message.table.columns" [columnTypes]="getColumnTypes(message.table.columns)"
                      [columnAlignments]="getColumnAlignments(message.table.columns)" [columnWidths]="getColumnWidth(message.table.columns)">
          </bic-table>
            </div>
          <div *ngIf="message.files" class="flex w-full pl-12 pr-24">
            <div class="grid grid-cols-3 pt-4 select-none w-full gap-6">
              <div *ngFor="let file of getSourceFilesNew(message); index as j">
                <div
                  class="rounded-md bg-gray-50 py-2 px-4 w-full flex flex-row border gap-2 items-center text-gray-900 border-gray-100"
                (click)="chooseFile(file, message)"
                  [ngClass]="{'border-gray-300 hover:cursor-pointer':message.metadata?.['chooseFiles'] && fileIsSelected(file), 'hover:border-gray-300 hover:text-primary hover:cursor-pointer':message.metadata?.['chooseFiles'] && !fileIsSelected(file)}">
                  <!--fa-icon (click)="openFolder(file); $event.stopPropagation()"
                           class="text-[18px] text-gray-200 hover:text-gray-300 cursor-pointer flex !max-w-[20px]"
                           [icon]="faFolderOpen"></fa-icon-->
                  <p
                    class="text-sm text-ellipsis text-nowrap whitespace-nowrap break-normal overflow-hidden text-inherit"
                    [ngClass]="{'text-primary':message.metadata?.['chooseFiles'] && fileIsSelected(file), 'hover:text-inherit':message.metadata?.['chooseFiles'] && !fileIsSelected(file)}">
                    {{ file.fileName }}</p>
                  <fa-icon class="text-gray-200 hover:text-gray-300 cursor-pointer text-base ml-auto"
                           [icon]="faFile" (click)="openSource(file)"></fa-icon>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!chatService.currentChat">
    <div class="flex flex-col justify-center items-center h-[inherit] w-full px-2 gap-12">
      <p class="font-semibold text-[32px]">Willkommen bei chatBIC!</p>
      <p class="font-semibold">Probieren Sie diese Prompts aus...</p>
      <div class="w-full flex flex-row">
        <div class="w-[15%]"></div>
        <div class="flex flex-wrap gap-2 justify-center p-2">
          <ng-container *ngFor="let prompt of getPrompts()"
                        class="p-2">
            <div
              class="rounded-[10px] px-4 py-2 justify-center font-normal text-sm bg-gray-50 hover:bg-gray-200 border-gray-100 text-gray-900"
              (click)="usePrompt(prompt)">{{ prompt }}
            </div>
          </ng-container>
        </div>
        <div class="w-[15%]"></div>
      </div>
    </div>
  </ng-container>
</div>



