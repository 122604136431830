import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-dashboard-tasks',
  templateUrl: './dashboard-tasks.component.html',
  styleUrls: ['./dashboard-tasks.component.scss']
})
export class DashboardTasksComponent {

  @Input() basicTasks: number[] = [0, 0, 0, 0];
  @Input() bcfTasks: number[] = [0, 0, 0, 0];

  get total(): number {
    return this.basicTasks.reduce((a, b) => a + b, 0) + this.bcfTasks.reduce((a, b) => a + b, 0);
  }

  get open(): number {
    return this.basicTasks[0] + this.bcfTasks[0];
  }

  get inProgress(): number {
    return this.basicTasks[1] + this.bcfTasks[1];
  }

  get resolved(): number {
    return this.basicTasks[2] + this.bcfTasks[2];
  }

  get verified(): number {
    return this.basicTasks[3] + this.bcfTasks[3];
  }

  get openPercentage(): number {
    return this.total === 0 ? 0 : Math.round(this.open / this.total * 100);
  }

  get inProgressPercentage(): number {
    return this.total === 0 ? 0 : Math.round(this.inProgress / this.total * 100);
  }

  get resolvedPercentage(): number {
    return this.total === 0 ? 0 : Math.round(this.resolved / this.total * 100);
  }

  get verifiedPercentage(): number {
    return this.total === 0 ? 0 : Math.round(this.verified / this.total * 100);
  }





}
