<div class="flex flex-col gap-4 w-full h-full">
  <div class="flex flex-row justify-between">
    <div class="flex flex-row font-medium text-lg gap-2">
      <div class="flex flex-row justify-center items-center w-8 h-8 rounded-lg bg-gray-100 cursor-pointer hover:bg-gray-300 active:opacity-75 transition">
        <ng-content select="[card-icon]"></ng-content>
      </div>
      <ng-content select="[card-title]"></ng-content>
    </div>
    <fa-icon class="text-lg text-gray-300 cursor-pointer hover:opacity-80 active:opacity-50 transition" [icon]="faInfoCircle" [tooltip]="tooltipText" tooltipDirection='left'></fa-icon>
  </div>
  <ng-content select="[card-content]"></ng-content>
</div>
