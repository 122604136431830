<div class="rounded-md shadow-custom w-full h-full p-4 pr-1 flex justify-start items-start overflow-hidden">
  <table class=" block w-full max-w-full table-fixed max-h-full overflow-y-scroll overflow-x-hidden !bg-[#FFFFFF]">
    <tbody class="w-full h-full block pr-1">
    <ng-container *ngFor="let chat of getChatList(); index as i">
      <tr *ngIf="isNewDate(i)" class="w-full text-gray-400 text-xs h-[3rem] flex items-center">
        {{ chat.createdOn | date: 'dd.MM.yyyy' }}
      </tr>
      <tr *ngIf="!renameChat[i]"
        class="flex flex-row justify-end items-center hover:bg-gray-100 border border-gray-200 text-gray-900 text-sm px-2"
        (click)="selectChat(chat)"
        [ngClass]="{'bg-gray-200': chat.id == getCurrentChat()?.id, 'rounded-t-lg':isNewDate(i), 'rounded-b-lg':isNewDate(i+1)}"
        (mouseenter)="hovering[i] = true" (mouseleave)="hovering[i] = false">
        <p
          class="text-gray-900 text-ellipsis text-nowrap whitespace-nowrap break-normal overflow-hidden w-full px-2 py-2">
          {{ chat.topic }}
        </p>

        <div *ngIf="hovering[i]" [bicDropdownTrigger]="allChatsDropdown" class="p-2" (click)="$event.stopPropagation()">
        <fa-icon  [icon]="faEllipsisVertical"
                 class="h-full hover:text-gray-400 text-gray-300 flex flex-row justify-center items-center rounded-md"></fa-icon>
        </div>
        <bic-dropdown #allChatsDropdown [dropdownPlacement]="'bottom-start'">
          <bic-dropdown-element (click)="renameChat[i]=true; $event.stopPropagation()"
                                class="w-full text-sm">
            <fa-icon [icon]="faPenToSquare" class="text-gray-300 pr-3"></fa-icon>
            Umbenennen
          </bic-dropdown-element>
          <bic-dropdown-element (click)="deleteChat(chat); $event.stopPropagation()"
                                class="w-full text-sm">
            <fa-icon [icon]="faTrash" class="text-[#E3B3B4] pr-3"></fa-icon>
            Löschen
          </bic-dropdown-element>
        </bic-dropdown>
      </tr>
      <tr *ngIf="renameChat[i]" class="flex relative items-center">
        <input type="text" #renameTopic
               class="block w-full p-2 ps-2 pe-[72px] text-sm text-gray-900 border border-gray-200 rounded-lg focus:outline-none overflow-ellipsis focus:ring-0 focus:border-gray-200"
               (focus)="topicInputIsFocused = true" (focusout)="topicInputIsFocused = false"
               placeholder="{{chatService.threads[i].topic}}"
               required/>
        <button type="submit"
                class="absolute end-11 text-gray-300 hover:text-error focus:outline-none font-medium rounded-lg text-base"
                (click)="renameChat[i] = false">
          <fa-icon class="text-inherit text-lg" [icon]="faXmark"></fa-icon>
        </button>
        <button type="submit"
                class="absolute end-4 text-gray-300 hover:text-primary items-center focus:outline-none font-medium rounded-lg text-base"
                (click)="chatService.renameTopicTo(renameTopic.value); renameChat[i]=false">
          <fa-icon class="text-inherit text-lg" [icon]="faCheck"></fa-icon>
        </button>
      </tr>
    </ng-container>
    </tbody>
  </table>
</div>

