import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";

class ToolbarAction {
  action?: () => void;
  disabled: () => boolean = () => false;
  routerLink?: any[]

}

interface SearchAction extends ToolbarAction {

  search: (value: string) => void;
}

interface ToolbarConfig {
  search?: SearchAction;
  add?: ToolbarAction;
  edit?: ToolbarAction;
  save?: ToolbarAction;
  delete?: ToolbarAction;
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  config: ToolbarConfig = {}
  constructor(router: Router) {


    router.events.subscribe(event => {

      if (event instanceof NavigationStart) {
        if (event.url !== router.url) {
          this.config = {}
        }
      }
    });
  }

  isEmpty() {
    return Object.keys(this.config).length === 0;
  }
}
