<div class="flex flex-row w-full px-6 py-3.5 text-xl text-black items-center font-medium shadow-full-new justify-between">
  <div>Profil & Organisation</div>

  <div class=" rounded-lg flex flex-row justify-end items-center gap-2 h-10">
    <!-- Add Project Button (Visible in List View) -->
    <!--    <button *ngIf="isListView" mat-raised-button color="primary" (click)="openNewOrganisation()">-->
    <!--      <fa-icon class="text-white pr-2" [icon]="faPlus"></fa-icon>-->
    <!--      <span>Neues Unternehmen anlegen</span>-->
    <!--    </button>-->
    <bic-button *ngIf="isListView" [color]="'white'" [textColor]="'gray-900'" [border]="true" (onClick)="openNewOrganisation()" [text]="'Organisation'" [iconLeft]="faPlus" class="mr-2" ></bic-button>
    <div class="rounded-lg flex flex-row justify-end items-center ml-auto h-[42px] border border-gray-200" >
      <!-- Grid View Button -->
      <button (click)="setCardView()" class="flex flex-row p-2 rounded-l">
        <div class="flex h-5 w-5 justify-center items-center">
          <fa-icon [icon]="faGrid2" [ngClass]="isCardView ? 'text-gray-900' : 'text-gray-300'"></fa-icon>
        </div>
      </button>

      <div class="border-l-2 border-gray-300 h-6"></div>

      <!-- List View Button -->
      <button (click)="setListView()" class="flex flex-row p-2 rounded-l">
        <div class="flex h-5 w-5 justify-center items-center">
          <fa-icon [icon]="faBars" [ngClass]="isListView ? 'text-gray-900' : 'text-gray-300'"></fa-icon>
        </div>
      </button>
    </div>
  </div>
</div>

<div *ngIf="dataSource"
     class="flex flex-col justify-start items-start h-full w-full gap-2">
  <div class="flex flex-row justify-start items-start flex-wrap w-full overflow-y-scroll p-2">
    <div *ngIf="isCardView"
         class="flex flex-col justify-start items-start p-2 w-full">
<!--      <div class="flex flex-row justify-between items-center w-full gap-2">-->
<!--        <div class="flex flex-row justify-start items-center gap-2"></div>-->
<!--        <div class="shadow-full rounded flex flex-row justify-end items-center">-->
<!--          &lt;!&ndash; Grid View Button &ndash;&gt;-->
<!--          <button (click)="setCardView()" class="flex flex-row p-2 rounded-l">-->
<!--            <div class="flex flex-wrap h-5 w-5 justify-start items-center gap-0.5">-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--            </div>-->
<!--          </button>-->
<!--          <div class="border-l-2 border-primary h-6"></div>-->
<!--          &lt;!&ndash; List View Button &ndash;&gt;-->
<!--          <button (click)="setListView()" class="flex flex-row p-2 rounded-l">-->
<!--            <div class="flex flex-wrap h-5 w-5 justify-start items-center gap-0.5">-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--            </div>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->


      <div class="flex flex-row justify-start items-start flex-wrap gap-10 w-full h-full p-2">

        <bic-card (click)="stateService.selectOrganisation(undefined, true, true)" class="p-2">
          <div class="flex flex-col justify-between h-full w-full overflow-hidden">
            <!-- Project Name and Menu Icon -->
            <div class="flex flex-row items-center justify-between pl-2 gap-1">
              <div class="text-xl font-black overflow-hidden break-words hyphens-auto line-clamp-2">
                Persönlich
              </div>
              <button mat-icon-button class="pr-2" [matMenuTriggerFor]="personalSettings"
                      (click)="$event.stopPropagation()">
                <fa-icon class="text-primary text-3xl" [icon]="faEllipsisVertical"></fa-icon>
              </button>

              <mat-menu #personalSettings="matMenu">
                <button mat-menu-item [routerLink]="['/profile']" >
                  Profil bearbeiten
                </button>
              </mat-menu>
            </div>

            <div class="flex flex-col justify-end w-full h-full items-center mt-2 mb-4 p-2 gap-5">
              <div class="flex flex-row gap-2 w-full items-center">
                <div>
                  <fa-icon class="text-lg" [icon]="faUserGroup"></fa-icon>
                </div>
                <div class=" pl-1.5">{{ user.firstName[0] + '. ' + user.lastName }}</div>

              </div>

              <div class="flex flex-row gap-2 w-full items-center">
                <div>
                  <fa-icon class="text-lg" [icon]="faBuilding"></fa-icon>
                </div>
                <div class="pl-2.5">Projekte</div>
                <div class="ml-auto pr-4">{{ numberOfPersonalProjects }}</div>
              </div>
            </div>
          </div>
        </bic-card>

        <bic-card *ngFor="let organisation of dataSource.data" (click)="navigateToOrganisation(organisation)"
                  class="p-2">
          <div class="flex flex-col justify-between h-full w-full overflow-hidden">
            <!-- Project Name and Menu Icon -->
            <div class="flex flex-row items-center justify-between pl-2 gap-1">
              <div class="text-xl font-black overflow-hidden break-words hyphens-auto line-clamp-2">
                {{ organisation.name }}
              </div>
              <!-- <div class="border-2 border-fuchsia-800"> -->
              <button mat-icon-button class="pr-2" [matMenuTriggerFor]="organisationSettings"
                      (click)="$event.stopPropagation()">
                <fa-icon class="text-primary text-3xl" [icon]="faEllipsisVertical"></fa-icon>
              </button>
              <!-- </div> -->

              <mat-menu #organisationSettings="matMenu">
                <button mat-menu-item *ngIf=true (click)="navigateToOrganisationSettings(organisation)">
                  Einstellungen
                </button>
                <button *ngIf="!getIsAdmin(organisation) || otherAdmins.get(organisation.id)" mat-menu-item (click)="openLeaveOrganisation(organisation)">
                  <span class="text-red-500">Verlassen</span>
                </button>
                <ng-container *ngIf="getIsAdmin(organisation)" class="text-red-500">
                  <button *ngIf="!otherAdmins.get(organisation.id)" mat-menu-item (click)="openDeleteAndLeaveOrganisation(organisation)">
                    <span class="text-red-500">Löschen und Verlassen</span>
                  </button>
                  <button *ngIf="otherAdmins.get(organisation.id)" mat-menu-item (click)="openDeleteOrganisation(organisation)">
                    <span class="text-red-500">Löschen</span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>

            <!--             Project Information-->
            <div class="flex flex-col justify-end w-full h-full items-center mt-2 mb-4 p-2 gap-5">
<!--              <div class="flex flex-row gap-2 w-full items-center">-->
<!--                <div>-->
<!--                  <fa-icon [icon]="faNetworkWired" class="text-lg"></fa-icon>-->
<!--                </div>-->
<!--                <div class="pl-1.5">(to be changed)</div>-->
<!--              </div>-->

              <div class="flex flex-row gap-2 w-full items-center">
                <div>
                  <fa-icon class="text-lg" [icon]="faUserGroup"></fa-icon>
                </div>
                <div class=" pl-1.5">Mitarbeitende</div>
                <div class="ml-auto pr-4">{{ organisation.users.length }}</div>
              </div>

              <div class="flex flex-row gap-2 w-full items-center">
                <div>
                  <fa-icon class="text-lg" [icon]="faBuilding"></fa-icon>
                </div>
                <div class="pl-2.5">Projekte</div>
                <div class="ml-auto pr-4">{{ organisation.numberOfProjects }}</div>
              </div>

<!--              <div class="flex flex-row gap-2 justify-start items-center">-->
<!--                <div>-->
<!--                  <fa-icon class="text-lg" [icon]="faUser"></fa-icon>-->
<!--                </div>-->
<!--                <div class="pl-3"></div>-->
<!--              </div>-->
            </div>
          </div>
        </bic-card>



        <bic-card (click)="openNewOrganisation()" class="p-2">
          <div class="flex flex-col justify-center items-center h-full">
            <fa-icon class="text-[90px]" [icon]="faPlus"></fa-icon>
          </div>

        </bic-card>
      </div>

    </div>
    <div *ngIf="isListView"
         class="flex flex-row justify-between items-start flex-wrap gap-5 w-full p-2">
<!--      <div class="flex flex-row justify-between items-center w-full gap-2">-->
<!--        <div class="flex flex-row justify-start items-center gap-2 w-full">-->
<!--          <button mat-raised-button color="primary" class="ml-0" (click)="openNewOrganisation()">-->
<!--            <fa-icon class="text-white pr-2" [icon]="faPlus"></fa-icon>-->
<!--            <span>Neues Unternehmen anlegen</span>-->
<!--          </button>-->
<!--        </div>-->
<!--        <div class="shadow-full rounded flex flex-row justify-end items-center">-->
<!--          &lt;!&ndash; Grid View Button &ndash;&gt;-->
<!--          <button (click)="setCardView()" class="flex flex-row p-2 rounded-l">-->
<!--            <div class="flex flex-wrap h-5 w-5 justify-start items-center gap-0.5">-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--            </div>-->
<!--          </button>-->
<!--          <div class="border-l-2 border-primary h-6"></div>-->
<!--          &lt;!&ndash; List View Button &ndash;&gt;-->
<!--          <button (click)="setListView()" class="flex flex-row p-2 rounded-l">-->
<!--            <div class="flex flex-wrap h-5 w-5 justify-start items-center gap-0.5">-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--            </div>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
      <!--          <bic-row [routerLink]="['/projects/create']" class="bg-primary rounded-lg">-->
      <!--            <div class="flex flex-row justify-center items-center h-full">-->
      <!--              <div class="text-white font-medium pl-2">Projekt erstellen</div>-->
      <!--            </div>-->
      <!--          </bic-row>-->
      <bic-row (click)="stateService.selectOrganisation(undefined, true, true)"
               class="flex flex-col w-full justify-start ">
        <div class="flex flex-row justify-between items-center w-full pl-2 gap-1">
          <div class="flex flex-row w-[40%] text-lg font-medium break-all hyphens-auto line-clamp-2 h-full">
            Persönlich
          </div>

          <div
            class="flex flex-row w-[15%] gap-2 justify-start items-center break-all hyphens-auto line-clamp-2 h-full opacity-0">
            <div>
              <fa-icon [icon]="faNetworkWired" class="text-lg"></fa-icon>
            </div>
            <div class="pl-1.5 break-all hyphens-auto line-clamp-2">
            </div>
          </div>


            <div class="flex flex-row w-[15%] gap-2 justify-start items-center break-all hyphens-auto line-clamp-2 h-full">
              <div>
                <fa-icon class="text-lg" [icon]="faUserGroup"></fa-icon>
              </div>
              <div class="pl-1.5 break-all hyphens-auto line-clamp-2">{{ user.firstName[0] + '. ' + user.lastName }}</div>

            </div>

            <div class="flex flex-row w-[15%] gap-2 justify-start items-center break-all hyphens-auto line-clamp-2 h-full">
              <div>
                <fa-icon class="text-lg" [icon]="faBuilding"></fa-icon>
              </div>
              <div class="pl-1.5 break-all hyphens-auto line-clamp-2">{{ numberOfPersonalProjects}} Projekte</div>
            </div>

          <div
            class="flex flex-row w-[15%] justify-start items-center gap-2 break-all hyphens-auto line-clamp-2 h-full opacity-0">
            <div>
              <fa-icon [icon]="faUser" class="text-lg"></fa-icon>

            </div>
            <div class="pl-0.5 break-all hyphens-auto line-clamp-2"></div>
          </div>

          <div class="flex flex-row justify-end items-center">
            <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="personalSettings" class="pr-2"
                    mat-icon-button>
              <fa-icon [icon]="faEllipsisVertical" class="text-primary text-3xl"></fa-icon>
            </button>
          </div>

          <mat-menu #personalSettings="matMenu">
            <button mat-menu-item [routerLink]="['/profile']" >
              Profil bearbeiten
            </button>
          </mat-menu>

        </div>
      </bic-row>
      <bic-row (click)="navigateToOrganisation(organisation)" *ngFor="let organisation of dataSource.data"
               class="flex flex-col w-full justify-start ">
        <div class="flex flex-row justify-between items-center w-full pl-2 gap-1">
          <!-- Project Name and Menu Icon -->
          <div class="flex flex-row w-[40%] text-lg font-medium break-all hyphens-auto line-clamp-2 h-full">
            {{ organisation.name }}
          </div>
          <!-- Project Information -->


          <div
            class="flex flex-row w-[15%] gap-2 justify-start items-center break-all hyphens-auto line-clamp-2 h-full opacity-0">
            <div>
              <fa-icon [icon]="faNetworkWired" class="text-lg"></fa-icon>
            </div>
            <div class="pl-1.5 break-all hyphens-auto line-clamp-2">
              <div class="pl-2.5">Projekte</div>
                <div class="ml-auto pr-4">{{ organisation.numberOfProjects }}</div>
            </div>
          </div>

          <div
            class="flex flex-row w-[15%] justify-start items-center gap-2 break-all hyphens-auto line-clamp-2 h-full">
            <div>
              <fa-icon [icon]="faUserGroup" class="text-lg"></fa-icon>
            </div>
            <div *ngIf="organisation.users.length == 1" class="pl-1">{{ organisation.users.length }} Person</div>
            <div *ngIf="organisation.users.length != 1" class="pl-1">{{ organisation.users.length }} Personen</div>
          </div>
          <div
            class="flex flex-row w-[15%] justify-start items-center gap-2 break-all hyphens-auto line-clamp-2 h-full">
            <div>
              <fa-icon [icon]="faBuilding" class="text-lg"></fa-icon>
            </div>
            <div class="pl-0.5 break-all hyphens-auto line-clamp-2">{{ organisation.numberOfProjects }} Projekte</div>
          </div>
          <div
            class="flex flex-row w-[15%] justify-start items-center gap-2 break-all hyphens-auto line-clamp-2 h-full opacity-0">
            <div>
              <fa-icon [icon]="faUser" class="text-lg"></fa-icon>

            </div>
            <div class="pl-0.5 break-all hyphens-auto line-clamp-2 "></div>
          </div>
          <div class="flex flex-row justify-end items-center">
            <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="organisationSettings" class="pr-2"
                    mat-icon-button>
              <fa-icon [icon]="faEllipsisVertical" class="text-primary text-3xl"></fa-icon>
            </button>
          </div>
          <mat-menu #organisationSettings="matMenu">
            <button (click)="navigateToOrganisationSettings(organisation)" mat-menu-item>
              Einstellungen
            </button>
            <button *ngIf="!getIsAdmin(organisation) || otherAdmins.get(organisation.id)" mat-menu-item (click)="openLeaveOrganisation(organisation)">
              <span class="text-red-500">Verlassen</span>
            </button>
            <ng-container *ngIf="getIsAdmin(organisation)" class="text-red-500">
              <button *ngIf="!otherAdmins.get(organisation.id)" mat-menu-item (click)="openDeleteAndLeaveOrganisation(organisation)">
                <span class="text-red-500">Löschen und Verlassen</span>
              </button>
              <button *ngIf="otherAdmins.get(organisation.id)" mat-menu-item (click)="openDeleteOrganisation(organisation)">
                <span class="text-red-500">Löschen</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </bic-row>
    </div>
    <app-pop-up *ngIf="leaveOrganisationBoolean" [header]="'Unternehmen verlassen?'" [message]="'Durch diesen Vorgang verlassen Sie das Unternehmen ' + selectedOrganisation.name + '.' +
   '<br> <br> Sie verlieren somit den Zugang zu allen Projekten ihres Unternehmens!'" [approveButtonName]="'Bestätigen'"
                (approvedPressed)="leaveOrganisation(selectedOrganisation)"
                (declinedPressed)="leaveOrganisationBoolean=false"></app-pop-up>

    <app-pop-up *ngIf="deleteOrganisationBoolean" [header]="'Unternehmen löschen?'" [holdable]="true" [message]="'Durch diesen Vorgang wird das Unternehmen ' + selectedOrganisation.name +
   ' unwiderruflich gelöscht! <br> <br> Alle Personen werden aus dem Unternehmen entfernt und dieses anschließend gelöscht!'"
                [approveButtonName]="'Bestätigen'" (approvedPressed)="deleteOrganisation(selectedOrganisation)"
                (declinedPressed)="deleteOrganisationBoolean = false"></app-pop-up>

    <app-pop-up *ngIf="deleteAndLeaveOrganisationBoolean" [header]="'Unternehmen lösche und verlassen?'"
                [holdable]="true" [message]="'Durch diesen Vorgang wird das Unternehmen ' + selectedOrganisation.name +
   ' unwiderruflich gelöscht. <br> <br> Da Sie die administrative Rolle des Unternehmens besitzen, wird dieses beim Verlassen gelöscht! <br> Fall Sie lediglich das Unternehmen verlassen wollen, ernennen Sie zuvor einen zusätzlichen Administrator, der Ihre Position übernimmt! '"
                [approveButtonName]="'Bestätigen'" (approvedPressed)="deleteOrganisation(selectedOrganisation)"
                (declinedPressed)="deleteAndLeaveOrganisationBoolean = false"></app-pop-up>


  </div>
</div>





