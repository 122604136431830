import {Component, OnInit} from '@angular/core';
import {FileControllerService, ProjectFile, SWMCheck} from "../../../../api";
import {SelectionModel} from "@angular/cdk/collections";
import {FileTreeNode} from "../../../util/files/files.component";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {SWMFolderEnum} from "../files/files.component";
import {ActivatedRoute, Router} from "@angular/router";
import {faCircleXmark, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-file-selection',
  templateUrl: './file-selection.component.html',
  styleUrls: ['./file-selection.component.scss']
})
export class FileSelectionComponent implements OnInit {

  swmCheck: SWMCheck | undefined;

  filteredBSK: ProjectFile[] = [];
  runningDocumentClassification: string[] = [];
  selectedFolder: string = '';
  selectedBSKFiles: SelectionModel<FileTreeNode> = new SelectionModel<FileTreeNode>(true, []);

  constructor(private fileControllerService: FileControllerService, private stateConfigService: StateConfigService, private activatedRoute: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.loadFiles()
    this.stateConfigService.runningClassificationsChange.subscribe((runningClassifications) => {
      this.runningDocumentClassification = runningClassifications;
      this.loadFiles();
    });
    this.swmCheck = this.activatedRoute.snapshot.parent?.data['swmCheck']
  }

  loadFiles() {
    this.fileControllerService.getFiles(this.stateConfigService.getProjectId()).subscribe(files => {
      //@ts-ignore
      this.filteredBSK = files.filter(file => file.folderSWM === SWMFolderEnum.BSK).filter(file => file.analyseCompleted === true).filter(file => file.analyseFailed === false)
    });
  }

    onFolderClick(folder: string) {
    this.selectedFolder = folder;
  }

    get shownFiles(): ProjectFile[] {
    switch (this.selectedFolder) {
      case 'BSK':
        return this.filteredBSK;
      default:
        return [];
    }
  }

   get shownSelectedFiles(): SelectionModel<FileTreeNode> {
    switch (this.selectedFolder) {
      case 'BSK':
        return this.selectedBSKFiles;
      default:
        return new SelectionModel<FileTreeNode>(true, []);
    }
  }

    getSelectedBSKFiles(): { name: string, size: string }[] {
    return this.selectedBSKFiles.selected.map(node => ({name: node.name || '', size: this.calculateSize(node.size!)}));
  }

  private calculateSize(size: number): string {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (size < kb) {
      return `${size} Bytes`;
    } else if (size < mb) {
      return `${(size / kb).toFixed(2)} KB`;
    } else if (size < gb) {
      return `${(size / mb).toFixed(2)} MB`;
    } else {
      return `${(size / gb).toFixed(2)} GB`;
    }
  }

  removeSelectedBSKFile(file: FileTreeNode) {
    this.selectedBSKFiles.selected.forEach((value, index) => {
      if(value.name == file.name){
        this.selectedBSKFiles.deselect(value);
      }
    });

  }

  navigateToChoosingRules() {
   this.router.navigate(['projects/' + this.stateConfigService.getProjectId() +  '/fireprotection/ruleSelection'], { state: { files: this.selectedBSKFiles.selected } });
  }


  protected readonly faFolderOpen = faFolderOpen;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faCircleXmark = faCircleXmark;
}
