import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {LoadingService} from "../../services/loading/loading.service";
import {catchError, map} from "rxjs/operators";
import {ActivatedRoute, Params} from "@angular/router";

/**
 * Interceptor on HTTP Request for Loading
 */
@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {

  /**
   * Constructor
   * @param loadingService LoadingService
   */
  constructor(private loadingService: LoadingService) {
  }

  /**
   * Intercept on any HTTP Request and sets Loading state
   * @param request an HTTP Request
   * @param next an HTTP Handler
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.interceptRunner(request, next)
  }

  /**
   * Intercept on any HTTP Request for Project Analysis and sets Loading state
   * @param request an HTTP Request
   * @param next an HTTP Handler
   */
  interceptRunner(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loadingService.setLoading(true, request.url);
    return next.handle(request)
      .pipe(catchError((err) => {
        this.loadingService.setLoading(false, request.url);
        return err;
      }))
      .pipe(map<HttpEvent<any> | unknown, any>((evt: HttpEvent<any> | unknown) => {
        if (evt instanceof HttpResponse) {
          this.loadingService.setLoading(false, request.url);
        }
        return evt;
      }));
  }
}
