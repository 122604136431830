import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {faChevronDown, faChevronUp, faClipboardList} from "@fortawesome/free-solid-svg-icons";
import {CircleState} from "../../util/design/circle-state/circle-state.component";
import {MatTableDataSource} from "@angular/material/table";
import {ProjectFile, QualityCheck, QualityControllerService, QualityRequest, Task} from "../../../api";
import {TaskDetailsService} from "../../../services/task-details.service";
import LphEnum = Task.LphEnum;
import {Check, QualityService} from "../../../quality-api";
import {ActivatedRoute, Router} from "@angular/router";
import {QualityCheckRule} from "../quality-rules-report/quality-rules-report.component";

export function stateToCircleState(state: string): CircleState {
  switch (state) {
    case 'success':
      return CircleState.GREEN;
    case 'error':
      return CircleState.RED;
    case 'warning':
      return CircleState.YELLOW;
    default:
      return CircleState.GREEN;
  }

}

interface QualityCheckFile {
  projectFile: ProjectFile;
  check: Check;
}

@Component({
  selector: 'app-quality-file-report',
  templateUrl: './quality-file-report.component.html',
  styleUrls: ['./quality-file-report.component.scss']
})
export class QualityFileReportComponent implements OnInit {

  qualityCheck!: QualityCheck
  @Input() lph!: LphEnum;

  //@Input() lph!: LphEnum;

  @Output() viewChange = new EventEmitter<boolean>();

  data: any[] = []

  displayedColumnsNames = ['Dateityp','Dateiname','Prüfstatus']
  displayedColumns = ['type', 'filename', 'state']
  columnsTypes = ['text', 'text', 'status']
  columnsWidths = ['20%', '70%', '10%']
  columnsAlignment = ['start', 'start', 'center']

  navigateToRuleView(file: any) {
    this.router.navigate([file.id], {relativeTo: this.activatedRoute});
  }

  navigateToComplianceCheck(){
    this.router.navigate(["compliance"], {relativeTo: this.activatedRoute});
  }

  constructor(private taskDetailService: TaskDetailsService,
              private qualityService: QualityService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.qualityCheck = this.activatedRoute.parent?.parent?.snapshot.data.qualityCheck;

    for (let qualityCheckProjectFile of this.qualityCheck.qualityCheckProjectFiles!) {
      this.data.push({type:qualityCheckProjectFile.check!.fileType, filename: qualityCheckProjectFile.projectFile!.name, state:this.overallState({
          projectFile: qualityCheckProjectFile.projectFile!,
          check: qualityCheckProjectFile.check!
        }), id:qualityCheckProjectFile.projectFile!.id})

    }
  }

  overallState(file: QualityCheckFile){
    // @ts-ignore
    const result = Object.values(file.check.result).flatMap(result => result).map(result => result.type)

    if (result.includes('error')) {
      return 'error'
    } else if (result.includes('warning')) {
      return 'warning'
    } else {
      return 'success'
    }
  }

  get completed() {
    return (100 / this.total) * this.data.filter(file => file.state === 'success').length
  }

  get warning() {
    return (100 / this.total) * this.data.filter(file => file.state === 'warning').length
  }

  get error() {
    return (100 / this.total) * this.data.filter(file => file.state === 'error').length
  }
  get total() {
    return this.data.length
  }

  createTask(element: any) {
    console.log('create task', element)
    let task = {
      markup: {
        topic: {
          title: 'Überarbeiten notwendig für: ' + element.filename ?? ' ',
        }
      },
    }

    this.taskDetailService.setOpenedTask(task as Task);
  }


  protected readonly faClipboardList = faClipboardList;
  protected readonly CircleState = CircleState;
  protected readonly stateToCircleState = stateToCircleState;
}
