import { Pipe, PipeTransform } from '@angular/core';
import {Task} from "../api";
import PriorityEnum = Task.PriorityEnum;

@Pipe({
  name: 'priority'
})
export class PriorityPipe implements PipeTransform {

  transform(value: PriorityEnum): String {
    switch (value) {
      case PriorityEnum.HIGH: return "Hoch";
      case PriorityEnum.NORMAL: return "Normal";
      case PriorityEnum.LOW: return "Niedrig";
      default: return "Normal";

    }
  }

}
