<div *ngIf="dataSource">
  <cdk-table #orgTbSort="matSort" [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="name">
      <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[40%] h-full pl-7 py-4 font-medium" mat-sort-header> Name
      </cdk-header-cell>
      <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[40%] pl-7 py-1 break-words"> {{ element.name }}</cdk-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[50%] h-full py-4 font-medium" mat-sort-header> Berechtigung
      </cdk-header-cell>
      <cdk-cell *cdkCellDef="let element"
                class="shrink-0 w-[50%] py-1 break-words">{{ user.roleInProjects[element.id].name | roleAndPrivileges }}
      </cdk-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <cdk-header-cell *cdkHeaderCellDef
                       class="flex flex-row grow w-[10%] h-full justify-end py-4 font-medium pr-4"></cdk-header-cell>
      <cdk-cell *cdkCellDef="let element" class="flex flex-row grow w-[10%] justify-end items-center py-1 pr-4">
        <button [matMenuTriggerFor]="menu">
          <fa-icon [icon]="faEllipsisVertical"></fa-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button [routerLink]="['/projects/' + element.id + '/settings']" mat-menu-item>
            Bearbeiten
          </button>
        </mat-menu>
      </cdk-cell>
    </ng-container>

    <cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"
                    class="flex flex-row justify-left items-center w-full border-b-2 border-b-gray-400 bg-white rounded-t-md overflow-hidden"></cdk-header-row>
    <cdk-row *cdkRowDef="let row; columns: displayedColumns;"
             class="flex flex-row justify-left items-center w-full py-1 border-t-2 border-b-gray-400"></cdk-row>

  </cdk-table>
  <!-- <div class="flex flex-row justify-end mb-4 mt-2">
     <button [routerLink]="['/projects/create']" color="basic" mat-raised-button matTooltip="Neues Projekt erstellen">
       <mat-icon>add</mat-icon>
       Hinzufügen
     </button>
   </div>-->
</div>
