import { Pipe, PipeTransform } from '@angular/core';
import SalutationEnum = User.SalutationEnum;
import {User} from "../api";

@Pipe({
  name: 'salutation'
})
export class SalutationPipe implements PipeTransform {

  transform(value: SalutationEnum): String {
    switch (value) {
      case SalutationEnum.HERR: return "Herr";
      case SalutationEnum.FRAU: return "Frau";
      case SalutationEnum.NONE: return "(keine Anrede)";
      default: return "";

    }
  }

}
