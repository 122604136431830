<div class="shadow-custom rounded-md w-full h-full flex flex-col justify-start items-start gap-2">
  <p class="text-xs text-gray-900 font-medium px-4 pr-1 pt-4">Alle Dateien</p>
  <table class="block w-full max-w-full table-fixed max-h-full overflow-y-scroll overflow-x-hidden !bg-[#FFFFFF]">
    <tbody class="w-full h-full block pr-1">
    <tr *ngFor="let file of this.chatService.allFiles; index as i" class="flex w-full border-b border-gray-200">
      <div class="px-4 py-2 w-full flex flex-row gap-2 items-center hover:bg-gray-100 "
           (click)="openSource(file); $event.stopPropagation()">
        <fa-icon (click)="openFolder(file); $event.stopPropagation()" class="text-[18px] text-gray-200 hover:text-gray-300 cursor-pointer flex !max-w-[20px]"
                 [icon]="faFolderOpen"></fa-icon>
        <p class="text-sm text-ellipsis text-nowrap whitespace-nowrap break-normal overflow-hidden w-full text-gray-900 px-2 py-2">
          {{ file.fileName }}</p>
        <!--fa-icon (click)="openSource(file); $event.stopPropagation()" class="text-gray-200 hover:text-gray-300 cursor-pointer flex !w-4 !h-4"
                 [icon]="faFile"></fa-icon-->
      </div>
    </tr>
    </tbody>
  </table>

</div>
