export const complianceParameterMap = {
  "BL_1_1": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_10": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_11": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "BL_1_12": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_13": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_14": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_15": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_16": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_17": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_2": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_3": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_4": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_5": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": true
  },
  "BL_1_6": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_7": {
    "city": true,
    "floor": true,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "BL_1_8": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_1_9": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_1": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_10": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_11": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_12": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_13": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_14": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_15": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_2": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_3": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_4": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_5": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_6": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_7": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "BL_2_8": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_2_9": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "BL_3_1": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_3_2": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_3_3": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_4_1": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "BL_4_2": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "BL_4_3": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_5_1": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "BL_5_2": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "BL_5_3": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_5_4": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "BL_5_5": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_1_A": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_1_B": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": true
  },
  "GL_1_C": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_1_D": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_2_A": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_2_B": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_2_C": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_2_D": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_2_E": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_2_F": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_2_G": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_2_H": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_3_A": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_3_B": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_3_C": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_3_D": {
    "city": true,
    "floor": true,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_3_E": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_3_F": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_AABA_else": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_AVK_BW_NW": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Abstandsflaechen_HB_HE_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Abstandsflaechenuebernahme_BY": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Angaben_Beherbergungsbetten_NW": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Angaben_Beherbergungsstaette_ohne_Brandschutzkonzept_NW": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Angaben_CO_Warnanlage_NW": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Angaben_zu_gewerblichen_Anlagen_BW": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Angaben_zum_Betriebsraum_NW": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Angaben_zum_Krankenhaus_NW": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Anlagen_Gebuehren_HH": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": true
  },
  "GL_4_Ansicht_BW_NW": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Ansicht_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Ansicht_else": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Antrag_auf_Abloesung_notwendiger_Einstellplaetze_NI": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Anzahl_Fahrradstellplaetze_RP": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_ArbeitsstaettenVO_RP": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Art_der_baulichen_Nutzung_BB_BE_BW_NW_SL": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Artenschutz_else": {
    "city": true,
    "floor": true,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Aussenanlagenplan_BE": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Auszug_Bebauungsplan_HB": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Auszug_Bebauungsplan_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_B": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_4_BFK_HE_NW": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_BRI_umbauter_Raum_HB_HE_MV_NW_RP_SL_ST": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Bauartgenehmigung_else": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Baubeschreibung_Fluessiggas_1_RP": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Baubeschreibung_Fluessiggas_3_RP": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Baubeschreibung_Heizoel_1_RP": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Baubeschreibung_Heizoel_3_RP": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Baubeschreibung_else": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": true
  },
  "GL_4_Baukosten_HB": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Baukostenwert_HB": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Baumbestandsbescheinigung_1_HB": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Baumbestandsbescheinigung_2_HB": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Baumbestandsbescheinigung_HH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Baunebenrecht_HB": {
    "city": false,
    "floor": false,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Bauvorlageberechtigung_else": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Bauwert_HB": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Benennung_Bauleitender_BW_SH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Berechnung_Verkaufsstaette_NW": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Bericht_ueber_den_geprueften_Brandschutznachweis_BE": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Bericht_ueber_den_geprueften_Standsicherheitnachweis_BE": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Bescheinigung_nach_67_Abs_4_LBO_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Beschreibung_Baugrundstueck_SL": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Beschreibung_barrierefreie_Wohnungen_BE": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Beschreibung_baulicher_Anlagen_SL": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Beschreibung_der_Baumassnahmen_geringen_Umfangs_SL": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Bestuhlungswegplan_und_Rettungswegplan_NW": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Betriebsbeschreibung_else": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Bodenschutzkonzept_BW": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Brandschutzkonzept_BY_RP_ST": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Brandschutzkonzept_Sonderbauten_BB_BE_BY_HB_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Brandschutznachweis_BB_BE_BY_HB_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Brandschutznachweis_in_Bauvorlagen_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_C": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Darstellung_Zufahrtsverhaeltnisse_ST": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Einfuegungsnachweis_HE": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Energieausweis_else": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Ergaenzungsplan_HB": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Erklaerung_der_Nachbarschaft_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Erklaerung_zum_barrierefreien_Bauen_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Erlaeuterung_gegen_nachbarliche_Zustimmung_RP": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Erschliessungssicherung_BB_BE_BY_HB_HH_MV_SH_ST_TH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Erschuetterungsnachweis_BE_BY_HB_HH_SH_SN_ST_TH": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Feuerungsanlagen_BW_RP_SL": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Flaechen_RP": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Foerderungsnachweis_SH": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Freiflaechengestaltungsplan_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Freiflaechenplan_HB_HE": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Gebaeudeklasse_Hoehe_BB_BW_HB_HH_MV_NI_SH": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_4_Geschossberechnung_HB": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Grundbuchauszug_BE_HB": {
    "city": false,
    "floor": true,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_4_Grundriss_BW": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundriss_HE": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundriss_NI": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundriss_NW": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundriss_RP": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundriss_SL": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundriss_SN_ST": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundriss_else": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundstuecksentwaesserung_1_BW_RP_SH_SL": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": true
  },
  "GL_4_Grundstuecksentwaesserung_2_BW_RP_SH_SL": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": true
  },
  "GL_4_Herstellungskosten_BE_NI_NW_RP_ST": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Hochwasserrisiko_RP": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Hygienegutachten_HE": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Kriterienkatalog_BB_BE_BY_HB_MV_NW_SH_SL_SN_ST_TH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Laermschutzkonzept_HE": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Lageplan_BB_BE_BW_HB_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Liegenschaftsplan_BB": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_4_Liegenschaftsplan_BB_BE_BY_HE_HH_MV_NI_NW_RP_SH_SL_SN_ST_TH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Liegenschaftsplan_BE_BY_HE_MV_NI_NW_RP_SH_SL_TH": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_4_Liegenschaftsplan_HH": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_4_Liegenschaftsplan_SN_ST": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_4_Mass_der_baulichen_Nutzung_else": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Mobilitaetsbedarf_HB": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Nachbarliche_Zustimmung_BE_BY_HB_RP_SN_TH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Nachweis_Stellplaetze_fuer_Fahrraeder_BE": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Nachweis_barrierefreie_Stellplaetze_BE": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Oeffentlichkeitsbeteiligung_BY": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Perspektiven_BE": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Pruefverzicht_Brandschutznachweis_HB": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Pruefverzicht_Standsicherheitsnachweis_HB": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Rohbaukosten_NI_NW": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Sachverstaendigenbescheinigung_Brandschutz_BY_MV_NW_RP_SH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Sachverstaendigenbescheinigung_Schallschutz_NW": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Sachverstaendigenbescheinigung_Waermeschutz_NW": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Sachverstaendigennachweis_Standsicherheit_BB_BE_BY_HB_MV_NW_RP_SH_SN_TH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Sachverstaendigennachweis_Standsicherheit_SL_ST": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Schallschutznachweis_BB_BE_BW_BY_HB_HE_HH_MV_NW_RP_SH_SL_SN_ST_TH": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Schalltechnische_Untersuchung_BB_SL": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Schnitt_BB": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Schnitt_BE_BW_BY_HB_HH_MV_NI": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Schnitt_HE_RP_SH_SL_SN_ST_TH": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Schnitt_NW": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Spielplatznachweis_else": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Standsicherheitsnachweis_BW": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Standsicherheitsnachweis_else": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Statistik_else": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Stellplatznachweis_else": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Stellungnahme_BE": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Stellungnahme_Bodenschutzbehoerde_HB": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Stoerfallbetrieb_RP": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_UVP_else": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Uebereinstimmungserklaerung_BE": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Uebersichtsplan_HE_RP_SH": {
    "city": false,
    "floor": false,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_Unbedenklichkeitsbescheinigung_Feuerwehr_HB": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Unterlagen_ueber_Brennstofflagerung_NI": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Vereinfachtes_Abfallverwertungskonzept_BW": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Vergleichsberechnung_MV": {
    "city": true,
    "floor": false,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Vertretervollmacht_BB_BE_HE_MV_NW_SN_ST": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_Waermeschutznachweis_BB_BE_BY_HB_HE_HH_NW_RP_SH_SL_ST_TH": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_amtliche_Basiskarte_NW": {
    "city": true,
    "floor": true,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_amtlicher_Lageplan_BB_BY_NI_NW_SL": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_anrechenbare_Kosten_MV_SH_ST": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_anrechenbarer_Bauwert_HB": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_beabsichtigte_Nutzung_ST": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_denkmalschutzrechtliche_Erlaubnis_BY": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_ergaenzende_Baubeschreibung_MV": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_erneuerbare_Energien_BB": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_4_erteilte_Bescheide_BE_HB": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_oeffentlich_rechtliche_Vorschriften_HB_HH": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_oeffentliche_Bekanntmachung_BY": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_oertliche_Bauvorschrift_BB_SL": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  },
  "GL_4_qualifizierter_Lageplan_HB": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_qualifizierter_Lageplan_NI": {
    "city": true,
    "floor": true,
    "owner": true,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_4_wassergefaehrdende_Stoffe_else": {
    "city": true,
    "floor": true,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_5_A": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": true
  },
  "GL_5_B": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_5_C": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_5_D": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": true,
    "street": false
  },
  "GL_5_E": {
    "city": true,
    "floor": false,
    "owner": false,
    "zipCode": true,
    "projectName": true,
    "street": true
  },
  "GL_5_F": {
    "city": false,
    "floor": false,
    "owner": false,
    "zipCode": false,
    "projectName": false,
    "street": false
  }
}
