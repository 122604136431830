<div class="flex flex-col justify-between h-full" *ngIf="checks.length > 0 && selectedCheck">

  <div>
  <div
    class="w-full h-6 overflow-hidden rounded-tl-lg rounded-tr-lg border border-gray-50 justify-start items-start inline-flex">
    <ng-container *ngFor="let check of checks; let i = index">
      <div class="pl-3 py-1 flex-col justify-start items-start inline-flex select-none"
           [ngClass]="{
            'bg-gray-50': selectedCheck.url == check.url,
            'hover:bg-gray-50 bg-white': selectedCheck.url != check.url,
            'w-[34%]':checks.length==3 && i==2,
            'w-[33%]': checks.length==3 && i!=2,
            'w-full': checks.length==1,
            'w-[50%]': checks.length==2,
            'w-[25%]': checks.length==4
           }"
           (click)="selectedCheck = check">
        <div class="justify-start items-start w-full gap-2 inline-flex">
          <div
            class="text-gray-500 text-sm font-medium font-['Inter'] leading-none">{{ check.date | date: 'dd.MM.yy' }}
          </div>
        </div>
      </div>
      <div class="w-px self-stretch bg-gray-50"></div>
    </ng-container>
  </div>
  <div
    class="w-full h-32 px-3 py-2 bg-gray-50 rounded-bl-lg rounded-br-lg flex-col justify-start items-center gap-2 inline-flex">
    <div class="self-stretch h-28 flex-col justify-start items-start gap-2 flex">
      <ng-container *ngFor="let item of values | keyvalue: originalOrder">
        <div class="self-stretch justify-between items-center inline-flex">
          <div class="text-gray-500 text-sm font-normal font-['Inter'] leading-none">{{ item.key }}:</div>
          <div class="px-3 py-0.5 bg-gray-100 rounded-md justify-center items-center gap-1 flex">
            <div class="text-center text-gray-500 text-xs font-medium font-['Inter'] leading-none">
              {{ item.value }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  </div>


  <app-dashboard-ring-chart class="h-full" [url]="selectedCheck.url" [succeeded]="succeeded" [warning]="warning"
                            [failed]="failed"></app-dashboard-ring-chart>
</div>

<div class="flex flex-col justify-between h-full" *ngIf="!(checks.length > 0 && selectedCheck)">
  <div class="flex h-[152px] bg-gray-50 text-gray-500 justify-center items-center">Keine Prüfung vorhanden</div>

  <app-dashboard-ring-chart class="h-full" [succeeded]="0" [warning]="0"
                            [failed]="0"></app-dashboard-ring-chart>

</div>
