/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Subscription } from './subscription';
import { User } from './user';

export interface NotificationSubscription { 
    id?: number;
    created?: Date;
    user?: User;
    endpoint?: string;
    expirationTime?: Date;
    options?: string;
    p256dh?: string;
    auth?: string;
    subscription?: Subscription;
}