<div class="flex flex-col shadow-full h-full w-full rounded-[10px] justify-start overflow-hidden" [class]="gapBetweenHeadlineAndContent ? 'gap-2': ''">

  <div class="flex flex-row text-base font-black justify-between pt-3 pl-4">
    {{ title }}
    <div class="items-end pr-4">
      <div *ngIf="info">
      <fa-icon class="text-lg" [icon]="icon"></fa-icon>
      </div>
      <div *ngIf="!info">
        <button (click)="buttonClicked()">
          <fa-icon class=" text-lg" [icon]="icon"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <div class=" w-full h-full flex flex-row justify-between">
    <ng-content></ng-content>
  </div>
</div>
