import {Component} from '@angular/core';
import {faCircle, faCircleArrowRight, faX} from "@fortawesome/free-solid-svg-icons";
import {PopoutContentService} from "../../../services/popout/popout.service";
import {StateConfigService} from "../../../services/state/state-config.service";
import {ChatService} from "../service/chat.service";
import {Router} from "@angular/router";
import {FileControllerService} from "../../../api";

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss']
})
export class MessageViewComponent {

  constructor(protected chatService: ChatService,
              private router: Router) {
  }

  messagePlaceholder() {
    return this.chatService.ifcMessage ? 'Frage zum IFC-Modell' : 'Frage zu den Projektdaten';
  }

  askQuestion(question: string) {
    if(this.chatService.ifcMessage && this.chatService.chosenFilesForIFCFilter.length<1){
      const message: {
        id: string,
        message: string | undefined,
        createdOn: Date,
        metadata: Record<string, string> | undefined,
        sender: string,
        files: { dataId: string, fileName: string }[] | undefined,
        table: { columns: string[], rows: string[][] } | undefined
      } = {
        id: '1',
        message: 'Bitte wählen Sie eine Datei aus.',
        files: undefined,
        metadata: {},
        createdOn: new Date(),
        sender: 'bot',
        table: undefined
      }

      this.chatService.currentChatMessages.push(message)
    } else{
    if (this.chatService.currentChat && this.chatService.currentChat.id!='0') {
      this.chatService.sendMessage(this.chatService.currentChat.id, question, false)
      this.chatService.question = '';
    } else {
      this.chatService.createChat().then(newThreadId => {
        this.chatService.sendMessage(newThreadId, question, true)
        this.chatService.question = '';

        /*this.router.navigate(['projects', this.chatService.currentProject, 'chat', newThreadId]).then(() => {
          //this.chatService.getChatMessages(chat.id);
        })*/
      })
    }
    }
  }

  clickedIFCFilter() {
    this.chatService.ifcMessage=!this.chatService.ifcMessage;
    if(this.chatService.ifcMessage && this.chatService.chosenFilesForIFCFilter.length==0 && !this.chatService.currentChatMessages.some(message=>message.id=='0')) {
      const message: {
        id: string,
        message: string | undefined,
        createdOn: Date,
        metadata: Record<string, string> | undefined,
        sender: string,
        files: { dataId: string, fileName: string }[] | undefined,
        table: { columns: string[], rows: string[][] } | undefined
      } = {
        id: '0',
        message: 'Ich habe die folgenden Dateien in Ihrem Projekt gefunden. Bitte wählen Sie eine aus, damit wir die Konversation basierend auf Ihrer Auswahl fortsetzen können.',
        files: this.chatService.projectIFCFiles,
        metadata: {},
        createdOn: new Date(),
        sender: 'bot',
        table: undefined
      }
      // @ts-ignore
      message.metadata['chooseFiles'] = 'true'

      this.chatService.currentChatMessages.push(message)
      if(!this.chatService.currentChat)
        this.chatService.currentChat = {id:'0', topic:'Neuer Chat', createdOn:new Date()}
    }
  }


  protected readonly faX = faX;
  protected readonly faCircleArrowRight = faCircleArrowRight;
  ifcButtonHover: boolean = false;
  hovering: boolean[] = [];

}
