/**
 * BIC Quality API
 * Quality Services API - Use this API to access the Quality Services of the Building Information Cloud (BIC).
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ComplianceDocumentsDocuments } from './complianceDocumentsDocuments';

export interface ComplianceDocuments { 
    component: string;
    requiredInformation: string;
    status: string;
    response: string;
    documents: Array<ComplianceDocumentsDocuments>;
}