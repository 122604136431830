/**
 * bic-bim-api
 * Import from bic-bim-api Function App
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BasecheckBody } from '../model/basecheckBody';
import { CheckLodBody } from '../model/checkLodBody';
import { CheckLoiBody } from '../model/checkLoiBody';
import { GenerateFlbBody } from '../model/generateFlbBody';
import { HoaicheckBody } from '../model/hoaicheckBody';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'https://services.thinkbic.de/bim';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * base-check
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public baseCheck(body?: BasecheckBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public baseCheck(body?: BasecheckBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public baseCheck(body?: BasecheckBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public baseCheck(body?: BasecheckBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/base-check`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * bcf2json
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bcf2jsonForm(file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public bcf2jsonForm(file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public bcf2jsonForm(file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public bcf2jsonForm(file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/bcf2json`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * check_lod
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkLod(body?: CheckLodBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public checkLod(body?: CheckLodBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public checkLod(body?: CheckLodBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public checkLod(body?: CheckLodBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'applications/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/check_lod`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * check_loi
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkLoi(body?: CheckLoiBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public checkLoi(body?: CheckLoiBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public checkLoi(body?: CheckLoiBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public checkLoi(body?: CheckLoiBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/check_loi`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * generate_flb
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateFlb(body?: GenerateFlbBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public generateFlb(body?: GenerateFlbBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public generateFlb(body?: GenerateFlbBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public generateFlb(body?: GenerateFlbBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'applications/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/generate_flb`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * hoai-check
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hoaiCheck(body?: HoaicheckBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public hoaiCheck(body?: HoaicheckBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public hoaiCheck(body?: HoaicheckBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public hoaiCheck(body?: HoaicheckBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'applications/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/hoai-check`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * json2bcf
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public json2bcf(body?: any, observe?: 'body', reportProgress?: boolean): Observable<BasecheckBody>;
    public json2bcf(body?: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BasecheckBody>>;
    public json2bcf(body?: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BasecheckBody>>;
    public json2bcf(body?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BasecheckBody>('post',`${this.basePath}/json2bcf`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * loi_excel_to_json
     * 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loiExcelToJsonForm(file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public loiExcelToJsonForm(file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public loiExcelToJsonForm(file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public loiExcelToJsonForm(file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/loi_excel_to_json`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * status
     * 
     * @param instanceId Instance ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public status(instanceId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public status(instanceId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public status(instanceId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public status(instanceId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (instanceId === null || instanceId === undefined) {
            throw new Error('Required parameter instanceId was null or undefined when calling status.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (instanceId !== undefined && instanceId !== null) {
            queryParameters = queryParameters.set('instanceId', <any>instanceId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKeyHeader) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]) {
            headers = headers.set('Ocp-Apim-Subscription-Key', this.configuration.apiKeys["Ocp-Apim-Subscription-Key"]);
        }

        // authentication (apiKeyQuery) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["subscription-key"]) {
            queryParameters = queryParameters.set('subscription-key', this.configuration.apiKeys["subscription-key"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/status`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
