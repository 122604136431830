<div class="flex flex-col justify-start items-start w-full h-full gap-4" cdkDropListGroup *ngIf="tasks">
<!--  <div class="flex flex-row justify-start gap-2 items-start">-->
<!--    <button mat-raised-button color="primary" (click)="createNewTask()">Aufgabe erstellen</button>-->
<!--    <button mat-raised-button color="primary" (click)="importBCFClicked.emit()">BCF importieren</button>-->
<!--    <button mat-raised-button [disabled]="selectedTasks.length==0" color="primary" (click)="exportBCFsClicked.emit(selectedTasks)">BCF exportieren</button>-->
<!--  </div>-->

  <div class="flex flex-row justify-between items-start  w-full h-full overflow-y-scroll">
    <ng-container *ngFor="let state of stateEnum | keyvalue: keepOrder" >
      <div class="h-full w-[24%] flex flex-col rounded-xl" cdkDropList [cdkDropListData]="taskStateFrom(tasks.get(state.key), state.value)" (cdkDropListDropped)="drop($event)">
        <div class="flex flex-col p-0.5 w-full gap-4" *ngIf="stateConfigService.getProjectId() > 0 ">
          <h2 class="px-1 text-back font-medium text-base">{{state.key | taskState}}</h2>
          <div class="flex flex-row w-full rounded-lg border-dashed border border-gray-300 p-4 items-center justify-center text-gray-500 gap-2 hover:cursor-pointer" (click)="createNewTask()">
            <fa-icon [icon]="faPlus" class="text-gray-500"></fa-icon>
            Neue Task
          </div>
        </div>
        <div class="flex flex-col gap-4 h-full overflow-y-scroll p-1 pt-4">
<!--          <div class="flex flex-col w-full p-5">-->
<!--            <div class="flex flex-row w-full pb-2 justify-between items-center border-b border-gray-200">-->
<!--              <span>Neue Task erstellen</span>-->
<!--              <fa-icon [icon]="faX"></fa-icon>-->
<!--            </div>-->
<!--            <div [formGroup]="newTaskForm">-->
<!--              <div formGroupName="markup">-->
<!--                <div formGroupName="topic">-->
<!--&lt;!&ndash;                  <bic-input formControlName="title" [label]="'Name'" [placeholder]="'Name hinzufügen * (erforderlich)'"></bic-input>&ndash;&gt;-->
<!--&lt;!&ndash;                  <bic-input bicDatepickerInput formControlName="dueDate" [label]="'Fällig am'" [placeholder]="'27/08/2023'"></bic-input>&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->


<!--          </div>-->
          <div *ngFor="let task of tasks.get(state.value)" cdkDrag>
            <app-task-card [task]="task" [selected]="isTaskSelected(task)" (exportBCFClicked)="exportBCFClicked.emit($event)" (click)="selectTask(task)"></app-task-card>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!--<div *ngIf="tasks && tasksArray().length == 0" class="welcome flex flex-col justify-start items-center" id="welcome-screen">-->
<!--  <img id="cloud" class="w-[330px] h-[330px]" ngSrc="assets/logo_gradient.svg" height="16" width="16" alt="">-->
<!--  <button mat-raised-button class="h-[60px] w-[330px] text-base" color="primary" (click)="createNewTask()">Erstellen Sie Ihre erste Aufgabe</button>-->
<!--  <div class="pt-4"></div>-->
<!--  <button mat-raised-button class="h-[60px] w-[330px] text-base" color="primary" (click)="importBCFClicked.emit()">BCF importieren</button>-->
<!--</div>-->
