import {EventEmitter, Injectable, Output} from '@angular/core';
import {AuthControllerService, FileControllerService, UserControllerService, UserFullDto} from "../../../api";
import {DefaultService as TokenDefaultService} from "../../../chatBIC-api";
import {StateConfigService} from "../../../services/state/state-config.service";
import {
  ChatClient, ChatMessage,
  ChatThreadClient,
  CreateChatThreadOptions, CreateChatThreadRequest,
  SendMessageOptions,
  SendMessageRequest,
} from '@azure/communication-chat';
import {AzureCommunicationTokenCredential} from '@azure/communication-common';
import {from, Observable, of, switchMap} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  filter: { lph?: string[], projectId?: string } = {};

  definedLPHs = ['LPH1', 'LPH2', 'LPH3', 'LPH4', 'LPH5']
  definedPrompts = ['Zu wie viel Prozent wird das Budget...?', 'Wie viele Etagen...?', 'Gibt es Bereiche, in denen Einsparungen vorgenommen werden könnten?', 'Wie viele Öffnungen gibt es im ersten Stock?']
  definedIFCPrompts = ['Welche Eigenschaften kennen wir von den Fenstern in diesem Projekt?', 'Welches Fenster ist flächenmäßig das Größte?', 'Wie viele Türen gibt es im ersten Stock?', 'Liste mir die Möbel in diesem Gebäude auf.', 'Was sind die Maßeinheiten in diesem Projekt?']
  user: any
  projects: { id: string, name: string }[] = []
  currentProject: number = 0
  projectIFCFiles: { dataId: string, fileName: string }[] = []

  chosenFilesForIFCFilter: { dataId: string, fileName: string }[] = []

  public filterExpanded: boolean = false
  public chatHistoryExpanded: boolean = false
  public allFilesExpanded: boolean = false

  endpoint = environment.chatbicCommunicationServiceEndpoint
  bot_identity = environment.chatbicBotIdentity

  threads: { id: string; topic: string, createdOn: Date }[] = [];
  currentChat: { id: string; topic: string, createdOn: Date } | undefined = undefined
  currentChatMessages: {
    id: string;
    message: string | undefined,
    createdOn: Date,
    metadata: Record<string, string> | undefined,
    sender: string | undefined,
    files: { dataId: string, fileName: string }[] | undefined,
    table: { columns: string[], rows: {} } | undefined
  }[] = [];

  ifcMessage: boolean = false;
  chatIdFromRouting: string | undefined
  allFiles: { dataId: string, fileName: string }[] = []
  question: string = ''

  token: string = '';

  @Output() messagesScrollDown = new EventEmitter<any>

  constructor(private authControllerService: AuthControllerService,
              private stateConfigService: StateConfigService,
              private chatTokenApiService: TokenDefaultService,
              private userControllerService: UserControllerService,
              private fileControllerSerivce: FileControllerService) {
  }

  initializeUserProjects() {
    this.projects = []
    this.stateConfigService.projects.forEach((value, key) => {
      this.projects.push({'id': String(key), 'name': String(value.name)})
    })
  }

  initializeProjectFilter() {
    this.filter.projectId = String(this.stateConfigService.getProjectId())
  }

  getUser() {
    return this.user
  }

  getProjects() {
    return this.projects
  }

  getFilter(): { lph?: string[], projectId?: string } {
    return this.filter;
  }

  addFilter(filter: string, category: string) {
    if (!this.hasFilter(filter, category)) {
      if (category === 'lph') {
        if (this.filter.lph) {
          this.filter.lph.push(filter)
        } else {
          this.filter.lph = [filter]
        }
      } else if (category === 'projectId') {
        // if (this.filter.projectId) {
        this.filter.projectId = filter
        //}
      }
    }
  }

  removeFilter(filter: string, category: string) {
    if (category === 'lph') {
      if (this.filter.lph) {
        if (this.filter.lph.length <= 1) {
          delete this.filter.lph
        } else {
          const index: number = this.filter.lph.indexOf(filter);
          if (index !== -1) {
            this.filter.lph?.splice(index, 1);
          }
        }

      }
    } else if (category === 'projectId') {
      if (this.filter.projectId) {
        delete this.filter.projectId
      }
    }
  }

  hasFilter(filter: string, category: string) {
    if (category === 'lph') {
      if (this.filter.lph) {
        return this.filter.lph.includes(filter)
      }
    } else if (category === 'projectId') {
      if (this.filter.projectId) {
        return this.filter.projectId === filter
      }
    }
    return false
  }

  renameTopicTo(newTopic: string) {
    if (this.currentChat && newTopic.trim().length > 0) {
      this.changeTopic(this.currentChat.id, newTopic)
    }
  }

  updateCurrentChat(chat: { id: string; topic: string; createdOn: Date } | undefined) {
    this.currentChat = chat;
  }

  async listChats() {
    const chatClient = new ChatClient(this.endpoint, new AzureCommunicationTokenCredential(this.token));
    const chatThreads = chatClient.listChatThreads()
    this.threads = [];

    for await (const thread of chatThreads) {
      const properties = await chatClient.getChatThreadClient(thread.id).getProperties()
      if (properties.metadata?.['projectId'] == this.currentProject.toString() && !properties.deletedOn) {
        this.threads.push({id: thread.id, topic: thread.topic, createdOn: properties.createdOn});
      }
    }

    this.threads.sort((a, b) => {
      return b.createdOn.getTime() - a.createdOn.getTime()
    })
    return this.threads;
  }

  async getChatMessages(chatThreadId: string): Promise<{
    id: string;
    message: string | undefined,
    createdOn: Date,
    metadata: Record<string, string> | undefined,
    sender: string | undefined,
    files: { dataId: string, fileName: string }[] | undefined,
    table: { columns: string[], rows: {} } | undefined
  }[]> {
    const chatClient = new ChatClient(this.endpoint, new AzureCommunicationTokenCredential(this.token));
    const threadClient = chatClient.getChatThreadClient(chatThreadId);
    const chatMessages = threadClient.listMessages();
    this.currentChatMessages = [];
    this.allFiles = [];

    for await (const chatMessage of chatMessages) {
      if (chatMessage.senderDisplayName) {
        this.addMessageToChat({
          id: chatMessage.id,
          message: chatMessage.content?.message,
          createdOn: chatMessage.createdOn,
          metadata: chatMessage.metadata,
          sender: chatMessage.senderDisplayName,
          files: undefined,
          table: undefined
        })
      }
    }

    //this.currentChatMessages.reverse()
    this.messagesScrollDown.emit()
    return this.currentChatMessages
  }

  async sendMessage(chatThreadId: string, message: string, isFirstMessage: boolean) {
    const chatClient = new ChatClient(this.endpoint, new AzureCommunicationTokenCredential(this.token));
    const threadClient: ChatThreadClient = chatClient.getChatThreadClient(chatThreadId);
    const sendMessageRequest: SendMessageRequest =
      {
        content: message
      };
    let sendMessageOptions: SendMessageOptions =
      {
        senderDisplayName: 'user',
        metadata: {
          'isIFC': String(this.ifcMessage),
          'isFirstMessage': String(isFirstMessage),
          'filter': JSON.stringify(this.filter),
          'ifcFiles': JSON.stringify(this.chosenFilesForIFCFilter)
        }
      };
    await threadClient.sendMessage(sendMessageRequest, sendMessageOptions)
  }

  async listenForChanges() {
    const chatClient = new ChatClient(this.endpoint, new AzureCommunicationTokenCredential(this.token));
    await chatClient.startRealtimeNotifications()
    chatClient.on("chatMessageReceived", (e) => {
      if (this.currentChat && e.threadId == this.currentChat?.id) {
        this.addMessageToChat({
          id: e.id,
          message: e.message,
          createdOn: e.createdOn,
          sender: e.senderDisplayName,
          metadata: e.metadata,
          files: undefined,
          table: undefined
        }, false)
      }
    });
    chatClient.on("chatThreadPropertiesUpdated", (e) => {
      if (this.currentChat && e.threadId == this.currentChat?.id) {
        this.currentChat.topic = e.properties.topic
        const thread = this.threads.find(thread => thread.id == e.threadId)
        thread ? thread.topic = e.properties.topic : null
      }
    });
  }

  async stopListeningForNotifications() {
    const chatClient = new ChatClient(this.endpoint, new AzureCommunicationTokenCredential(this.token));


    chatClient.off('chatMessageReceived', (e) => {
      console.log('in stop message', e)
    });
    chatClient.off('chatThreadPropertiesUpdated', (e) => {
      console.log('in stop message', e)
    });

    await chatClient.stopRealtimeNotifications()
    console.log('stopped listening')
  }

  async createChat() {
    const chatClient = new ChatClient(this.endpoint, new AzureCommunicationTokenCredential(this.token));
    const createChatThreadRequest: CreateChatThreadRequest = {
      topic: "Neuer Chat"
    };
    const createChatThreadOptions: CreateChatThreadOptions = {
      participants: [
        {
          id: {communicationUserId: this.bot_identity},
          displayName: 'bot'
        }
      ],
      metadata: {
        'projectId': this.currentProject.toString()
      }
    };
    const createChatThreadResult = await chatClient.createChatThread(
      createChatThreadRequest,
      createChatThreadOptions
    );
    // @ts-ignore
    const newThread = {id: createChatThreadResult.chatThread.id, topic: "Neuer Chat", createdOn: new Date()}
    this.threads.unshift(newThread)
    this.updateCurrentChat(newThread)
    // @ts-ignore
    return createChatThreadResult.chatThread.id;
  }

  async deleteChat(chatThreadId: string) {
    const chatClient = new ChatClient(this.endpoint, new AzureCommunicationTokenCredential(this.token));
    return chatClient.deleteChatThread(chatThreadId).then(() => {
      let index = this.threads.findIndex(thread => thread.id == chatThreadId)
      if (index !== -1) {
        this.threads.splice(index, 1);
      }
    })
  }

  async changeTopic(chatThreadId: string, newTopic: string) {
    const chatClient = new ChatClient(this.endpoint, new AzureCommunicationTokenCredential(this.token));
    const chatThreadClient = chatClient.getChatThreadClient(chatThreadId)
    return chatThreadClient.updateTopic(newTopic).then(res => {
      if (this.currentChat) {
        let index = this.threads.findIndex(thread => thread.id == chatThreadId)
        this.threads[index].topic = newTopic
        this.currentChat.topic = newTopic
      }
    })
  }

  addMessageToChat(chatMessage: {
    id: string,
    message: string | undefined,
    createdOn: Date,
    metadata: Record<string, string> | undefined,
    sender: string,
    files: { dataId: string, fileName: string }[] | undefined,
    table: { columns: string[], rows: {} } | undefined
  }, unshift:boolean = true) {
    const message: {
      id: string,
      message: string | undefined,
      createdOn: Date,
      metadata: Record<string, string> | undefined,
      sender: string,
      files: { dataId: string, fileName: string }[] | undefined,
      table: { columns: string[], rows: {} } | undefined
    } = {
      id: chatMessage.id,
      message: chatMessage.message,
      createdOn: chatMessage.createdOn,
      metadata: chatMessage.metadata,
      sender: chatMessage.sender,
      files: undefined,
      table: undefined
    };

    if (!this.currentChatMessages.some(m => m.id == message.id)) {
      console.log('has table', message.metadata?.["hasTable"])
      if (message.sender == "bot" && message.message && message.metadata && message.metadata["hasTable"] && message.metadata["hasTable"] == "true") {
        const messageContent = JSON.parse(message.message)
        message.message = messageContent.message
        message.table = messageContent.table
      }

      if (message.metadata && message.metadata["files"]) {
        let messageFiles: { dataId: string, fileName: string }[] = []
        JSON.parse(message.metadata["files"]).forEach((file: { dataId: string, fileName: string }) => {
          messageFiles.push(file)
          if (this.allFiles.find(f => f.dataId == file.dataId) == undefined) {
            this.allFiles.push(file);
          }
        });
        message.files = messageFiles
      }
      if(unshift){
        this.currentChatMessages.unshift(message);
      } else {
        this.currentChatMessages.push(message)
      }
      this.messagesScrollDown.emit()
    }
  }

  initializeChat(): void {
    this.authControllerService.profile().pipe(
      switchMap(user => {
        console.log('got user')
        this.user = user

        this.currentProject = this.stateConfigService.getProjectId();
        this.initializeUserProjects()
        this.initializeProjectFilter()

        this.fileControllerSerivce.getIfcFiles(this.currentProject).subscribe((files) => {
          files.forEach(file => {
            if (file.id && file.name)
              this.projectIFCFiles.push({dataId: file.id, fileName: file.name})
          })
        })

        console.log('all initialized')

        if (!user.chatIdentity) {
          return this.chatTokenApiService.generateIdentity().pipe(
            switchMap(identity => {
              user.chatIdentity = identity;
              this.user.chatIdentity = identity
              return this.userControllerService.updateUser(user);
            }),
            map(() => user)
          );
        } else {
          return of(user);
        }
      }),
      switchMap(user => this.getTokenWithExpiryCheck(this.user.chatIdentity)),
      tap(token => {
        this.token = token.value
        console.log('got token')
      }),
      switchMap(token => from(this.listChats())),
      switchMap(chatThreads => {
        if (!this.currentChat && this.chatIdFromRouting) {
          console.log('got id form routing')
          this.currentChat = this.threads.find((chat) => chat.id == this.chatIdFromRouting);
          if (this.currentChat) {
            return from(this.getChatMessages(this.currentChat?.id));
          }
        }
        return of(chatThreads);
      }),
      switchMap(chatThreads => from(this.stopListeningForNotifications())),
      catchError(error => {
        console.error('Error initializing chat:', error);
        return of(null);
      })
    ).subscribe(() => {
      console.log('subscribe to changes')
      this.listenForChanges();
    });
  }

  private getTokenWithExpiryCheck(identity: string): Observable<{ value: string, expiry: string }> {
    const tokenData = localStorage.getItem('chatToken');
    if (tokenData) {
      const token = JSON.parse(tokenData);
      const tokenExpiry = new Date(token.expiry);
      const now = new Date();
      if (tokenExpiry > now) {
        return of(token); // Token is valid, return it
      } else {
        return this.generateAndStoreToken(identity); // Token expired, generate a new one
      }
    } else {
      return this.generateAndStoreToken(identity); // No token in storage, generate a new one
    }
  }

  private generateAndStoreToken(identity: string): Observable<{ value: string, expiry: string }> {
    return this.chatTokenApiService.generateToken(identity).pipe(
      tap(token => {
        const expiryDate = (new Date(Date.now() + 82800000)).toISOString() //23h
        const tokenData = {value: token, expiry: expiryDate};
        localStorage.setItem('chatToken', JSON.stringify(tokenData)); // Store token with expiry in local storage
      }),
      map(token => ({value: token, expiry: (new Date(Date.now() + 86400000)).toISOString()})) // Return token with expiry
    );
  }

}
