<ng-container *ngIf="incorrectFileType">
  <h1>Datei(en) mit falschem Dateiformat gefunden!</h1>
  <h4>In dem von Ihnen ausgewählten Ordner befinden sich Dateien, welche nicht dem Dateityp PDF oder IFC zuzuordnen sind. Diese werden daher nicht hochgeladen.</h4>
</ng-container>
<br>
<ng-container *ngIf="forbiddenChar">
  <h1>Datei(en) mit verbotenen Zeichen gefunden!</h1>
  <h4>Mindestens einer der von Ihnen ausgewählten Dateien enthält ein verbotenes Zeichen (\/:*?"<>|´`’‘). Diese werden daher nicht hochgeladen.</h4>
</ng-container>
<mat-dialog-actions align="end">
  <button mat-icon-button (click)="dialogRef.close(true)">
    <mat-icon>check</mat-icon>
  </button>
</mat-dialog-actions>
