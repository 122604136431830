import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChatService} from "./service/chat.service";
import {ActivatedRoute} from "@angular/router";
import {initFlowbite} from "flowbite";
import {AuthControllerService} from "../../api";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {



  constructor(protected chatService: ChatService, private activated: ActivatedRoute, private authControllerService: AuthControllerService) {
  }

  unloadHandler = (event: Event) => {
    this.chatService.stopListeningForNotifications()
    window.removeEventListener('beforeunload', this.unloadHandler)
  };

  ngOnInit() {
    initFlowbite()
    this.chatService.chatIdFromRouting = this.activated.snapshot.params.chatId
    this.chatService.currentChat = undefined
    this.chatService.currentChatMessages = []
    this.chatService.ifcMessage = false
    this.chatService.initializeChat()
    window.addEventListener('beforeunload', this.unloadHandler)
  }

  ngOnDestroy(){
    this.chatService.stopListeningForNotifications()
    window.removeEventListener('beforeunload', this.unloadHandler)
  }

}
