<div class="flex flex-col h-full w-full justify-start items-start gap-6 pb-6 font-medium bg-[#FFF]">
  <app-quality-stepper class="w-full"></app-quality-stepper>
  <div class="flex flex-col w-full justify-start items-start gap-6 px-4 overflow-hidden">

    <app-overview-report [checkType]="'Qualitätsprüfung'"
                         [completedValue]="completed"
                         [date]="qualityCheck.checkDate"
                         [errorValue]="error"
                         [rules]="total"
                         [warningValue]="warning"
                         [fileDeactivated]="false"
                         [fileId]="fileId"
                         [whatWasChecked]=" total == 1 ? 'Regel' : 'Regeln'"
                         class="w-full">
    </app-overview-report>

    <div class="overflow-hidden h-full w-full">
      <div class="flex w-full overflow-y-scroll h-full">
    <bic-table class="w-full" [data]="data" [columnNames]="displayedColumnsNames" [columns]="displayedColumns" [columnTypes]="columnsTypes" [columnWidths]="columnsWidths"
               [paginator]="false" [columnAlignments]="columnsAlignment" (rowClick)="navigateToResultView($event)" [createTicket]="true" (createTicketClicked)="createTask($event)"
                [backbutton]="true" [backButtonText]="'Dateien'" (backButtonClicked)="navigateToFileReport()" [title]="fileName"></bic-table>
    </div>
    </div>
  </div>
</div>
