import { Component } from '@angular/core';
import {faBars, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {DashboardControllerService, ProjectFile, ProjectFileData} from "../../../../api";
import FolderEnum = ProjectFile.FolderEnum;
import {ActivatedRoute, Router} from "@angular/router";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {faCircleQuestion, faFolderOpen} from "@fortawesome/free-regular-svg-icons";


export enum SWMFolderEnum {
  BSK = "BSK",
  EB = "EB",
  PUB = "PUB",
  O = "O",
}

export enum SWMModuleEnum {
  SWMFILES = "SWMfiles",
}

@Component({
  selector: 'app-swm-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent {

  folders: typeof SWMFolderEnum = SWMFolderEnum;
  breadcrumbNavigation: ProjectFile[] = [];

  selectedFolder!:  FolderEnum | SWMFolderEnum;
  openedFolderOverlay:SWMFolderEnum | null = null;

  projectFileData: { [p: string]: ProjectFileData } = {};

  selectedModule: SWMModuleEnum = SWMModuleEnum.SWMFILES;
  modules: typeof SWMModuleEnum = SWMModuleEnum;

  constructor(private route: ActivatedRoute,
              private dashboardControllerService: DashboardControllerService,
              private router: Router, public stateConfigService: StateConfigService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.route.parent?.queryParams.subscribe(params => {
        if (params.module) {
          this.selectedModule = params.module;
          this.selectedFolder = params.folder;
        } else {
          this.router.navigate([], {queryParams: {module: SWMModuleEnum.SWMFILES}});
        }

          this.dashboardControllerService.getProjectFileDataSWM( this.stateConfigService.selectedProject.id!).subscribe(projectFiles => {
            this.projectFileData = projectFiles;
            }
          );

      });
    });
  }

  folderData(folder: SWMFolderEnum): ProjectFileData {
    return this.projectFileData[folder] ?? {size: 0, numberOfFiles: 0};
  }

  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }

  calculateSizeInGB(size: number): number {
    return size / 1024 / 1024 / 1024;
  }

  getFolders(): Set<SWMFolderEnum> {
    return new Set([
          SWMFolderEnum.BSK,
          SWMFolderEnum.EB,
          SWMFolderEnum.PUB,
          SWMFolderEnum.O
        ]);
  }

  navigateToModule(module: SWMModuleEnum): void {
    this.router.navigate([], {queryParams: {module: module}});
  }

  navigateToFiles(): void {
    this.router.navigate([], {queryParams: {module: SWMModuleEnum.SWMFILES}});
  }

  openFolder(folder: SWMFolderEnum): void {
    this.router.navigate([], {queryParams: {folder: folder}, queryParamsHandling: "merge"}).then(() => {
      this.selectedFolder = folder;
    });
  }

  getInfoText(folder: SWMFolderEnum): string {
    switch (folder) {
      case SWMFolderEnum.BSK:
        return "Bitte laden Sie hier das projektspezifische Brandschutzkonzept hoch.";
      case SWMFolderEnum.EB:
        return "Bitte laden Sie hier die projektspezifischen Erläuterungsbericht hoch.";
      case SWMFolderEnum.PUB:
        return "Bitte laden Sie hier die projektspezifische Planungsunterlagen sowie BIM-Modelle hoch.";
      case SWMFolderEnum.O:
        return "Bitte laden Sie hier weitere projektspezifische Bauprojektunterlagen hoch.";
      default:
        return "";
    }
  }

  protected readonly faBars = faBars;
  protected readonly faFolderOpen = faFolderOpen;
  protected readonly faCircleQuestion = faCircleQuestion;
  protected readonly faChevronRight = faChevronRight;
}
