import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Organisation, OrganisationControllerService, User, SimplePlace} from "../../../api";
import {
  AbstractControl,
  FormControl, FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup, ValidationErrors,
  Validators
} from "@angular/forms";
import LegalFormEnum = Organisation.LegalFormEnum;
import {Observable, Subject, Subscription} from "rxjs";
import {PermissionService} from "../../../services/permission/permission.service";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {ProjectStateReversePipe} from "../../../pipes/project-state.pipe";
import {KeyValue} from "@angular/common";
import {AddressInput} from "../../special/application/State";
import CountryEnum = Organisation.CountryEnum;
import StateEnum = Organisation.StateEnum;
import {CanDeactivateComponent} from "../../../guards/unsaved-changes.guard";
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {HoaiConfirmationDialog} from "../../hoaiCheck/hoai-check.component";
import {StateConfigService} from "../../../services/state/state-config.service";
import {
  checkVAT,
  austria,
  belgium,
  bulgaria,
  cyprus,
  czechRepublic,
  germany,
  denmark,
  greece,
  spain,
  estonia,
  finland,
  france,
  croatia,
  hungary,
  ireland,
  italy,
  latvia,
  lithuania,
  luxembourg,
  malta,
  netherlands,
  poland, portugal, romania, slovenia, slovakiaRepublic, sweden
} from 'jsvat';


@Component({
  selector: 'app-organisation-settings',
  templateUrl: './organisation-settings.component.html',
  styleUrls: ['./organisation-settings.component.scss']
})
export class OrganisationSettingsComponent implements OnInit, OnDestroy, CanDeactivateComponent {

  clearEvent: Subject<void> = new Subject<void>();

  private eventsSubscription!: Subscription;
  @Input() clearEventTriggered!: Observable<void>;
  private organisationChangedSubscription!: Subscription;
  @Input() organisationChangedEventTriggered!: Observable<Organisation>;

  @Output() organisationSaved = new EventEmitter<Organisation>();
  organisations: Organisation[] = [];
  organisation!: Organisation | undefined;
  organisationId!: number;

  leaveOrDelete: boolean | undefined;

  generalInfoForm!: FormGroup;
  addressInfoForm!: FormGroup;
  locationGuesses$!: Observable<SimplePlace[]>;

  legalForm: typeof LegalFormEnum = LegalFormEnum;

  organisationChangedEvent: Subject<Organisation> = new Subject<Organisation>();

  states: typeof StateEnum = StateEnum;
  dataSource!: MatTableDataSource<Organisation>;

  unsubscribe = new Subject<void>()


  @ViewChild('saveButton') saveButton!: HTMLButtonElement;

  user: User | undefined;

  countries = [
    //{ 'jsvat': 'andorra', 'code': 'AD', 'country': 'AND' },
    { 'jsvat': austria, 'code': 'AT', 'country': 'AUT' },
    { 'jsvat': belgium, 'code': 'BE', 'country': 'BEL' },
    //{ 'jsvat': 'brazil', 'code': 'BR', 'country': 'BRA' },
    { 'jsvat': bulgaria, 'code': 'BG', 'country': 'BGR' },
    //{ 'jsvat': 'switzerland', 'code': 'CH', 'country': 'CHE' },
    { 'jsvat': cyprus, 'code': 'CY', 'country': 'CYP' },
    { 'jsvat': czechRepublic, 'code': 'CZ', 'country': 'CZE' },
    { 'jsvat': germany, 'code': 'DE', 'country': 'DEU' },
    { 'jsvat': denmark, 'code': 'DK', 'country': 'DNK' },
    { 'jsvat': greece, 'code': 'GR', 'country': 'GRC' },
    { 'jsvat': spain, 'code': 'ES', 'country': 'ESP' },
    { 'jsvat': estonia, 'code': 'EE', 'country': 'EST' },
    { 'jsvat': finland, 'code': 'FI', 'country': 'FIN' },
    { 'jsvat': france, 'code': 'FR', 'country': 'FRA' },
    //{ 'jsvat': 'unitedKingdom', 'code': 'GB', 'country': 'GBR' },
    { 'jsvat': croatia, 'code': 'HR', 'country': 'HRV' },
    { 'jsvat': hungary, 'code': 'HU', 'country': 'HUN' },
    { 'jsvat': ireland, 'code': 'IE', 'country': 'IRL' },
    { 'jsvat': italy, 'code': 'IT', 'country': 'ITA' },
    { 'jsvat': latvia, 'code': 'LV', 'country': 'LVA' },
    { 'jsvat': lithuania, 'code': 'LT', 'country': 'LTU' },
    { 'jsvat': luxembourg, 'code': 'LU', 'country': 'LUX' },
    { 'jsvat': malta, 'code': 'MT', 'country': 'MLT' },
    { 'jsvat': netherlands, 'code': 'NL', 'country': 'NLD' },
    //{ 'jsvat': 'norway', 'code': 'NO', 'country': 'NOR' },
    { 'jsvat': poland, 'code': 'PL', 'country': 'POL' },
    { 'jsvat': portugal, 'code': 'PT', 'country': 'PRT' },
    { 'jsvat': romania, 'code': 'RO', 'country': 'ROU' },
    //{ 'jsvat': 'russiaFederation', 'code': 'RU', 'country': 'RUS' },
    //{ 'jsvat': 'serbia', 'code': 'RS', 'country': 'SRB' },
    { 'jsvat': slovenia, 'code': 'SI', 'country': 'SVN' },
    { 'jsvat': slovakiaRepublic, 'code': 'SK', 'country': 'SVK' },
    { 'jsvat': sweden, 'code': 'SE', 'country': 'SWE' }
  ]




  constructor(private formBuilder: UntypedFormBuilder, private organisationControllerService: OrganisationControllerService, public permissionService: PermissionService,private route: ActivatedRoute, private stateService: StateConfigService, private snackbar: MatSnackBar,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const organisationId = params['id'];
      this.organisationId = params['id'];
      this.organisationControllerService.getOrganisation(organisationId).subscribe((data) => {
        this.organisation = data;
        this.organisationChangedEvent.next(this.organisation);
        this.generalInfoForm = this.formBuilder.group({
          id: new FormControl(""),
          name: new UntypedFormControl("", [Validators.required]),
          legalForm: new UntypedFormControl(),
          organisationMail: new UntypedFormControl("", [Validators.required, Validators.email]),
          vatNumber: new UntypedFormControl("",[this.checkVATField])
        });

        this.addressInfoForm = this.formBuilder.group({
          street: new UntypedFormControl(""),
          houseNumber: new UntypedFormControl(""),
          zipCode: new UntypedFormControl("", [Validators.required]),
          city: new UntypedFormControl("", [Validators.required]),
          country: new UntypedFormControl("", [Validators.required]),
          state: new UntypedFormControl(""),
        });

        this.generalInfoForm.patchValue(this.organisation!)
        if (this.organisation && !this.permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS', this.organisation?.id!)) {
          this.generalInfoForm.disable();
        }

        this.addressInfoForm.patchValue(this.organisation!)
        if (this.organisation && !this.permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS', this.organisation?.id!)) {
          this.addressInfoForm.disable();
        }

        this.generalInfoForm.valueChanges.subscribe(value => {
          this.generalInfoForm.markAsDirty();
          if (this.generalInfoForm.invalid) {
            this.snackbar.open("Bitte füllen Sie alle Pflichtfelder aus", "OK", {duration: 2000});
          }
        });
        this.addressInfoForm.valueChanges.subscribe(value => {
          this.addressInfoForm.markAsDirty();
          if (this.addressInfoForm.invalid) {
            this.snackbar.open("Bitte füllen Sie alle Pflichtfelder aus", "OK", {duration: 2000});
          }
        });
        if (this.permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS', this.organisationId)) {
          this.generalInfoForm.disable();
          this.addressInfoForm.disable();
        }

        this.generalInfoForm.get('country')?.valueChanges.subscribe((newValue: String) => {
          const vatControl = this.generalInfoForm.get('vatNumber');
          const existingVatValue = vatControl?.value;

          if (!existingVatValue || this.countries.some((country: { code: any; }) => country.code === existingVatValue)) {
            const countryCode = this.getCountry(newValue)?.code
            vatControl?.setValue(countryCode);
          }
        });

      });

    });


  }

  getCountry(countryName: String){
    // @ts-ignore
    for (const country of this.countries) {
      if (countryName === country.country) {
        return country;
      }
    }
    return null;
  }

  validForm(): boolean {

    return (this.generalInfoForm.valid || this.generalInfoForm.disabled) && (this.addressInfoForm.valid || this.addressInfoForm.disabled);
  }

  disableForm(): boolean {

    return this.generalInfoForm.disabled && this.addressInfoForm.disabled;
  }

  canDeactivate(): boolean {

    return this.generalInfoForm.pristine && this.addressInfoForm.pristine;
  }


  updateLocation(location: SimplePlace) {
    let projectStateReversePipe = new ProjectStateReversePipe()

    this.addressInfoForm.get("street")?.setValue(location.street);
    this.addressInfoForm.get("houseNumber")?.setValue(location.addressNumber);
    this.addressInfoForm.get("zipCode")?.setValue(location.postalCode);
    this.addressInfoForm.get("city")?.setValue(location.municipality);
    this.addressInfoForm.get("country")?.setValue(location.country);
    if (location.region != null && location.country == "DEU") {
      this.addressInfoForm.get("state")?.enable();
      this.addressInfoForm.get("state")?.setValue(projectStateReversePipe.transform(location.region));
    } else {
      this.addressInfoForm.get("state")?.setValue(undefined);
      this.addressInfoForm.get("state")?.disable();
    }
  }

  valueAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return a.value.localeCompare(b.value);
  }


  get saveDisabled() {
    return this.generalInfoForm.invalid || this.addressInfoForm.invalid;
  }

  save() {
    this.organisation = {
      ...this.generalInfoForm.value,
      ...this.addressInfoForm.value
    };
    if (this.organisation?.vatNumber && this.organisation.country && this.organisation.vatNumber==this.getCountry(this.organisation.country)?.code){
      this.organisation.vatNumber = undefined
    }

    this.organisationControllerService.updateOrganisation(this.organisation!, this.organisationId).subscribe(organisation => {
      this.generalInfoForm.markAsPristine();
      this.addressInfoForm.markAsPristine();
      this.organisation = organisation;
      this.organisationSaved.emit(this.organisation);
      this.snackbar.open("Änderungen gespeichert", "OK", {
        duration: 1000,
      });
    });
  }

  leaveDialog() {
    const dialogRef = this.dialog.open(OrgLeaveConfirmationDialog, {
      panelClass: 'rounded-corners-dialog',
      width: '650px',
      data: {
        organisation: this.organisation
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.leaveOrganisation(this.organisation!)
      }
    });
  }

  leaveOrganisation(org: Organisation) {
    this.clearEvent.next();
    if (org.id != undefined) {
      this.organisationControllerService.leaveOrganisation(org.id).subscribe(() => {
        this.organisations = this.organisations.filter(obj => obj !== org);
        this.dataSource = new MatTableDataSource(this.organisations);
        this.stateService.selectProject(undefined, false);
        this.stateService.selectOrganisation(undefined, true, false, true);
      });
    }
  }

  deleteDialog() {
    const dialogRef = this.dialog.open(OrgDeleteConfirmationDialog, {
      panelClass: 'rounded-corners-dialog',
      width: '650px',
      data: {
        organisation: this.organisation,
        leaveOrDelete: this.leaveOrDelete
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result) {
          this.deleteOrganisation(this.organisation!)
        } else {
          this.leaveOrganisation(this.organisation!)
        }
      }
    });
  }
  private checkVATField = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const country = this.getCountry(this.addressInfoForm?.get("country")?.value)
    if(value != undefined && value != ""){
      if (country && country.code!=value && !checkVAT(value, [country.jsvat]).isValid) {
        return { validVATRequired: true };
      }
    }
    return null; // Validation passed
  }



  deleteOrganisation(org: Organisation) {
    this.clearEvent.next();
    if (org.id != undefined) {
      this.organisationControllerService.deleteOrganisation(org.id).subscribe(() => {
        this.organisations = this.organisations.filter(obj => obj !== org);
        this.dataSource = new MatTableDataSource(this.organisations);
        this.stateService.selectProject(undefined, false);
        this.stateService.selectOrganisation(undefined, true, false, true);
      });
    }
  }



  ngOnDestroy() {
    this.eventsSubscription?.unsubscribe();
    this.organisationChangedSubscription?.unsubscribe();
  }


  protected readonly faUserPlus = faUserPlus;
}

@Component({
  selector: 'organisation-delete-confirmation-dialog',
  templateUrl: './organisation-delete-confirmation.component.html',
  styleUrls: ['./organisation-settings.component.scss'],
})
export class OrgDeleteConfirmationDialog implements OnInit {

  existsOtherAdminMap: Map<Organisation, boolean> = new Map<Organisation, boolean>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    organisation: Organisation,
    leaveOrDelete: boolean,
  }, private dialogRef: MatDialogRef<OrgDeleteConfirmationDialog>,
              public permissionService: PermissionService,) {
  }

  ngOnInit() {
  }

  leave(): void {
    this.data.leaveOrDelete = false;
    this.dialogRef.close(this.data.leaveOrDelete);
  }

  delete(): void {
    this.data.leaveOrDelete = true;
    this.dialogRef.close(this.data.leaveOrDelete);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'organisation-leave-confirmation-dialog',
  templateUrl: './organisation-leave-confirmation.component.html',
  styleUrls: ['./organisation-settings.component.scss'],
})
export class OrgLeaveConfirmationDialog implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    organisation: Organisation
  }, private dialogRef: MatDialogRef<OrgLeaveConfirmationDialog>) {
  }

  ngOnInit() {
  }
}
