import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Comment, TaskControllerService, User} from "../../../api";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {DomSanitizer} from "@angular/platform-browser";
import {
  faArrowUpFromBracket,
  faArrowUpRightFromSquare, faCopy,
  faEllipsisVertical, faPenToSquare,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {StateConfigService} from "../../../services/state/state-config.service";
import {PopoutContentService} from "../../../services/popout/popout.service";
import {faPaperPlaneTop, faReply} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-task-comments',
  templateUrl: './task-comments.component.html',
  styleUrls: ['./task-comments.component.scss']
})
export class TaskCommentsComponent implements OnInit {

  @Input() formGroup!: UntypedFormGroup;
  @Input() user!: User;
  @Output() saveTask: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() reloadCommands: EventEmitter<any> = new EventEmitter<any>();

  editStates: Map<number, boolean> = new Map<number, boolean>();

  newComment!: UntypedFormGroup;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '40px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Geben Sie hier Ihren Kommentar ein',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

  constructor(private formBuilder: UntypedFormBuilder, private taskControllerService: TaskControllerService, private domSanitizer: DomSanitizer, private stateConfigService: StateConfigService, private popoutService: PopoutContentService) {
  }

  ngOnInit(): void {
    this.newComment = this.formBuilder.group({
      atGuid: new UntypedFormControl(),
      date: new UntypedFormControl(),
      author: new UntypedFormControl(),
      comment: new UntypedFormControl(),
      viewpoint: new UntypedFormControl(),
      modifiedDate: new UntypedFormControl(),
      modifiedAuthor: new UntypedFormControl(),
      authorUser: new UntypedFormControl(),
      modifiedAuthorUser: new UntypedFormControl(),
    })
  }

  comment(index: number): Comment {
    return this.comments.value[index]! as Comment;
  }

  commentControl(index: number): UntypedFormGroup {
    return this.comments.controls[index] as UntypedFormGroup
  }

  get comments(): UntypedFormArray {
    return this.formGroup.get('markup.comment') as UntypedFormArray;
  }

  get empty() {
    return this.comment(0)? Object.values(this.comment(0)).every(parameter => parameter == null) : true
  }

  editComment(index: number): boolean {
    return this.editStates.get(index) ?? false;
  }

  setEditComment(index: number, state: boolean) {
    this.editStates.set(index, state);
  }

  addComment(comment: Comment) {
    const form = this.formBuilder.group({
      atGuid: new UntypedFormControl(""),
      date: new UntypedFormControl(new Date()),
      author: new UntypedFormControl(this.user.mail),
      comment: new UntypedFormControl(comment.comment),
      viewpoint: new UntypedFormControl(null),
      modifiedDate: new UntypedFormControl(null),
      modifiedAuthor: new UntypedFormControl(""),
      authorUser: new UntypedFormControl(this.user),
      modifiedAuthorUser: new UntypedFormControl(null),
    })
    if (this.empty && this.comment(0)) {
      this.comments.at(0).setValue(form.value)
    } else {
      this.comments.push(form);
    }
    this.saveTask.emit(this.comments.length-1)
  }

  addCommentFrom(comment: Comment) {
    const form = this.formBuilder.group({
      atGuid: new UntypedFormControl(comment.atGuid),
      date: new UntypedFormControl(comment.date),
      author: new UntypedFormControl(this.user.mail),
      comment: new UntypedFormControl(comment.comment),
      viewpoint: new UntypedFormControl(comment.viewpoint),
      modifiedDate: new UntypedFormControl(new Date()),
      modifiedAuthor: new UntypedFormControl(this.user.mail),
      authorUser: new UntypedFormControl(comment.authorUser),
      modifiedAuthorUser: new UntypedFormControl(this.user),
    })

    this.comments.push(form);
  }

  deleteComment(commentIndex: number) {
    this.comments.removeAt(commentIndex)
  }

  getSnapshotURL(comment: Comment) {
    if (comment.viewpoint?.snapshotLink) {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(comment.viewpoint?.snapshotLink)
    }
    return null
  }

  openIFC() {
    let url = this.formGroup.get("ifcLink")?.value

    if (url) {
      localStorage.setItem("IFCURL", url)

      let baseUrl = window.location.origin + '/projects/' + this.stateConfigService.selectedProject.id + '/files/' + '0';
      this.popoutService.open(baseUrl, "IFC-Viewer")
    }
  }

  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faPaperPlaneTop = faPaperPlaneTop;
  protected readonly faReply = faReply;
  protected readonly faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  protected readonly faTrash = faTrash;
  protected readonly faArrowUpFromBracket = faArrowUpFromBracket;
  protected readonly faCopy = faCopy;
  protected readonly faPenToSquare = faPenToSquare;
}
