import {EventEmitter, Injectable} from '@angular/core';
import {
  FeatureConfig, Organisation, OrganisationControllerService,
  Project,
  ProjectControllerService,
  ProjectServiceNotification, RoleResponse,
  StateControllerService, User, UserFullDto, Contingent
} from "../../api";
import LphEnum = ProjectServiceNotification.LphEnum;
import {interval, Observable, startWith, Subscriber, Subscription, switchMap} from "rxjs";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {NotificationsService} from "../notifications/notifications.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {PermissionService} from "../permission/permission.service";
import {LoadingService} from "../loading/loading.service";

@Injectable({
  providedIn: 'root'
})
export class StateConfigService {

  public runningChecks: string[] = [];
  public runningIfcChecks: string[] = [];
  public runningQualityChecks: string[] = [];
  public runningBimBaseChecks: string[] = [];
  public runningSWMChecks: string[] = [];
  public runningClassifications: string[] = [];

  private subscription!: Subscription;


  private featureConfigs: FeatureConfig[] = [];
  public selectedFeatureConfig!: FeatureConfig;
  public projects: Map<number, RoleResponse> = new Map<number, RoleResponse>();
  public organisations: Map<number, RoleResponse> = new Map<number, RoleResponse>();

  public selectedProject!: Project;
  public selectedOrganisation!: Organisation;
  public selectedServicePhase: LphEnum = LphEnum.LPH1;

  private userContingent!: Contingent;

  public lphEnum: typeof LphEnum = LphEnum;

  public stage!: string

  private updateSubscriber!: Subscriber<void>
  public updated: Observable<void> = new Observable<void>(observer => {
    this.updateSubscriber = observer;
  });

  private runningChecksSubscriber!: Subscriber<string[]>
  public runningChecksChange: Observable<string[]> = new Observable<string[]>(observer => {
    this.runningChecksSubscriber = observer;
  });

  private runningIfcChecksSubscriber!: Subscriber<string[]>
  public runningIfcChecksChange: Observable<string[]> = new Observable<string[]>(observer => {
    this.runningIfcChecksSubscriber = observer;
  });

  private runningQualityChecksSubscriber!: Subscriber<string[]>
  public runningQualityChecksChange: Observable<string[]> = new Observable<string[]>(observer => {
    this.runningQualityChecksSubscriber = observer;
  });

  private runningBimBaseChecksSubscriber!: Subscriber<string[]>
  public runningBimBaseChecksChange: Observable<string[]> = new Observable<string[]>(observer => {
    this.runningBimBaseChecksSubscriber = observer;
  });

  private runningSWMChecksSubscriber!: Subscriber<string[]>
  public runningSWMChecksChange: Observable<string[]> = new Observable<string[]>(observer => {
    this.runningSWMChecksSubscriber = observer;
  });

  private runningClassificationsSubscriber!: Subscriber<string[]>
  public runningClassificationsChange: Observable<string[]> = new Observable<string[]>(observer => {
    this.runningClassificationsSubscriber = observer;
  });

  public organisationChange: EventEmitter<void> = new EventEmitter<void>();
  public projectChange: EventEmitter<Project> = new EventEmitter<Project>();

  constructor(private stateControllerService: StateControllerService,
              private router: Router,
              private loadingService: LoadingService,
              private projectControllerService: ProjectControllerService,
              private organisationControllerService: OrganisationControllerService) {
  }

  get contingent(): Contingent {
    return this.selectedOrganisation ? this.selectedOrganisation.contingent! : this.userContingent;
  }

  get filesUploaded(): boolean {
    return (this.selectedProject?.numberOfFiles ?? 0) > 0;
  }

  public start(): void {
    this.isPersonal = localStorage.getItem("isPersonal") == "true";
    let selectedProjectTemp = Number(localStorage.getItem("project"));
    this.selectOrganisation(Number(localStorage.getItem("organisation")), false, this.isPersonal);
    this.selectProject(selectedProjectTemp, false);
    let firstTime = true;


    this.loadingService.setLoading(true, "init-data-load");
    this.updateData().subscribe(() => {
      this.subscription = interval(5000)
        .pipe(
          startWith(0),
          switchMap(async () => this.updateData().subscribe()),
        ).subscribe();

      this.loadingService.setLoading(false, "init-data-load");

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const urlParts = event.url.split("/");
          if (urlParts.length > 1 && urlParts[1] === "projects") {
            if(firstTime){
              firstTime = false;
              this.selectOrganisation(Number(localStorage.getItem("organisation")), false, this.isPersonal);
            }
            this.selectProject(Number(urlParts[2]), false);
          }
        }
      });

      this.runningClassificationsChange.subscribe(classifications => {
      });
    })


  }

  public stop(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public update(): Observable<boolean> {
    return this.updateData();
  }

  private updateData(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      let previousRunningChecks = this.runningChecks;
      let previousRunningIfcChecks = this.runningIfcChecks;
      let previousRunningQualityChecks = this.runningQualityChecks;
      let previousRunningBimBaseChecks = this.runningBimBaseChecks;
      let previousRunningSWMChecks = this.runningSWMChecks;
      let previousRunningClassifications = this.runningClassifications;

      this.stateControllerService.getState(this.getProjectId().toString()).subscribe(state => {
        this.runningChecks = state.projectRunningChecksData ?? [];
        this.runningIfcChecks = state.projectRunningIfcChecksData ?? [];
        this.runningQualityChecks = state.projectRunningQualityChecksData ?? [];
        this.runningBimBaseChecks = state.projectRunningBimBaseChecksData ?? [];
        this.runningSWMChecks = state.projectRunningSWMChecksData ??[];
        this.runningClassifications = state.runningClassifications ?? [];


        this.featureConfigs = state.featureConfigs!;
        this.selectedFeatureConfig = this.getCurrentFeatureConfig();

        this.userContingent = state.contingent!;

        this.stage = state.stage!;

        for (let projectsKey in state.projects) {
          this.projects.set(Number(projectsKey), state.projects[projectsKey]);
        }

        for (let organisationsKey in state.organisations) {
          this.organisations.set(Number(organisationsKey), state.organisations[organisationsKey]);
          if (this.selectedOrganisation?.id === Number(organisationsKey)) {
            this.selectedOrganisation.name = state.organisations[organisationsKey].name;
          }
        }

        if (JSON.stringify(previousRunningIfcChecks) !== JSON.stringify(this.runningIfcChecks)) {
          this.runningIfcChecksSubscriber.next(this.runningIfcChecks ?? []);
        }

        if (JSON.stringify(previousRunningQualityChecks) !== JSON.stringify(this.runningQualityChecks)) {
          this.runningQualityChecksSubscriber.next(this.runningQualityChecks ?? []);
        }

        if (JSON.stringify(previousRunningBimBaseChecks) !== JSON.stringify(this.runningBimBaseChecks)) {
          this.runningBimBaseChecksSubscriber.next(this.runningBimBaseChecks ?? []);
        }


        if (JSON.stringify(previousRunningClassifications) !== JSON.stringify(this.runningClassifications)) {
          this.runningClassificationsSubscriber?.next(this.runningClassifications ?? []);
        }

        if (JSON.stringify(previousRunningChecks) !== JSON.stringify(this.runningChecks)) {
          this.runningChecksSubscriber?.next(this.runningChecks ?? []);
        }

        this.updateSubscriber?.next();

        observer.next(true);
      }, error => {
        observer.error(error);
      });


    });
  }

  public getOrganisationId(): number {
    return this.selectedOrganisation?.id ? this.selectedOrganisation.id : Number(localStorage.getItem("organisation"));
  }

  public getProject(): Project | undefined {
    return this.selectedProject;
  }

  public getProjectId(): number {
    return this.selectedProject?.id ? this.selectedProject.id : Number(localStorage.getItem("project"));
  }

  public get isPersonal(): boolean {
    return localStorage.getItem("isPersonal") === "true";
  }

  public set isPersonal(isPersonal: boolean) {
    localStorage.setItem("isPersonal", isPersonal ? "true" : "false");
  }

  public getOrganisation(): Organisation | undefined {
    return this.selectedOrganisation;
  }

  public selectOrganisation(organisationId: number | undefined, navigate: boolean = true,  isPersonal: boolean = false, deselect: boolean = false): void {

    if (deselect) {
      localStorage.setItem("organisation", "");
      localStorage.setItem("isPersonal",  "false")
      this.selectedOrganisation = undefined!;
      this.selectedProject = undefined!;
      this.router.navigateByUrl(`organisations`);
    }else{
      localStorage.setItem("organisation", organisationId?.toString() ?? "");
      localStorage.setItem("isPersonal", isPersonal ? "true" : "false")
      this.isPersonal = isPersonal;

      if (organisationId) {
      this.organisationControllerService.getOrganisation(organisationId).subscribe(organisation => {
        this.selectedOrganisation = organisation;
      });
    }else {
      this.selectedOrganisation = undefined!;
    }

    if(this.selectedProject && navigate){
      this.router.navigateByUrl(`organisations`);
    }else if( navigate){
      this.router.navigateByUrl(`projects`);
    }


    }
  }

  public selectProject(projectId: number | undefined, navigate: boolean = true): void {
    localStorage.setItem("project", projectId?.toString() ?? "");

    if (projectId) {
      this.projectControllerService.getProject(projectId).subscribe(project => {
        this.selectedProject = project;
        if (navigate) {
          this.projectChange.emit(this.selectedProject);
          this.router.navigateByUrl(`projects/${project.id}`);
        }

      });
    } else {
      if (navigate) {
        this.router.navigateByUrl(`projects`).then(() => {
          this.selectedProject = undefined!;
          this.projectChange.emit(undefined!);
          this.router.navigateByUrl(`projects`);
        });

      }
    }

  }

  public selectServicePhase(servicePhase: LphEnum): void {
    this.selectedServicePhase = servicePhase;
  }

  public canCheckHOAI(): boolean {
    return (this.projects.get(this.getProjectId())?.contingent?.hoaiChecks ?? 0) > 0;
  }

  public canCheckLOI(): boolean {
    return (this.projects.get(this.getProjectId())?.contingent?.loiChecks ?? 0) > 0;
  }

  public canCheckQuality(): boolean {
    return (this.projects.get(this.getProjectId())?.contingent?.qualityChecks ?? 0) > 0;
  }

  public canCheckBimBase(): boolean {
    return (this.projects.get(this.getProjectId())?.contingent?.bimBaseChecks ?? 0) > 0;
  }

  public canDownloadBuildingApplication(): boolean {
    return (this.projects.get(this.getProjectId())?.contingent?.buildingApplications ?? 0) > 0;
  }

  public canAddProjectMember(): boolean {
    return this.projects.get(this.getProjectId())?.contingent?.trialEnd == null;
  }

  private getCurrentFeatureConfig(): FeatureConfig {
    let featureConfigOfCurrentOrganisation = this.featureConfigs.find(featureConfig => featureConfig.orgaId === this.selectedOrganisation?.id);
    return featureConfigOfCurrentOrganisation ?? this.featureConfigs.find(featureConfig => featureConfig.orgaId === null)!;
  }

  public getFeatureConfig(): FeatureConfig {
    return this.selectedFeatureConfig;
  }


}
