/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ServiceRelationEvaluationResult { 
    succeed?: boolean;
    type?: ServiceRelationEvaluationResult.TypeEnum;
    definedServiceAspects?: Array<string>;
    fulfilledServiceAspects?: Array<string>;
    necessaryNumber?: number;
}
export namespace ServiceRelationEvaluationResult {
    export type TypeEnum = 'K0' | 'SERVICE_ASPECT' | 'SERVICE_ASPECT_RELATION' | 'RULE_PART' | 'DEPENDENT_PARTIAL_SERVICE';
    export const TypeEnum = {
        K0: 'K0' as TypeEnum,
        SERVICEASPECT: 'SERVICE_ASPECT' as TypeEnum,
        SERVICEASPECTRELATION: 'SERVICE_ASPECT_RELATION' as TypeEnum,
        RULEPART: 'RULE_PART' as TypeEnum,
        DEPENDENTPARTIALSERVICE: 'DEPENDENT_PARTIAL_SERVICE' as TypeEnum
    };
}