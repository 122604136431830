import { Pipe, PipeTransform } from '@angular/core';
import {Project, Task} from "../api";
import StateEnum = Task.StateEnum;

@Pipe({
  name: 'taskState'
})
export class TaskStatePipe implements PipeTransform {

  transform(value: StateEnum): String {
    switch (value) {
      case "OPEN":
        return "Offen"
      case "PROCESS":
        return "In Bearbeitung"
      case "DONE":
        return "Erledigt"
      case "VERIFIED":
        return "Verifiziert"
      default:
        return "Offen"
    }
  }

}

@Pipe({
  name: 'taskStateReverse'
})
export class TaskStateReversePipe implements PipeTransform {

  transform(value: String): StateEnum {
    switch (value) {
      case "Offen":
        return "OPEN"
      case "In Bearbeitung":
        return "PROCESS"
      case "Erledigt":
        return "DONE"
      case "Verifiziert":
        return "VERIFIED"
      default:
        return "OPEN"
    }
  }

}
