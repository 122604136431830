<div class="text-xl font-medium m-4">
  Gebäudeklassen Tool
</div>

<div class="p-4 flex flex-row justify-start items-start gap-4">
  <div class="flex-grow">
    <form [formGroup]="formBuildingClass">
      <div>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Bundesland des Gebäudes</mat-label>
          <mat-select formControlName="state" required>
            <mat-option *ngFor="let state of states | keyvalue" [value]="state.value">
              {{state.value | projectState}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngIf="formBuildingClass.value.state">
        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Nutzung des Gebäudes</mat-label>
            <mat-select formControlName="agricultural" required (focus)="infoStringBuildingClass = 'agricultural'">
              <ng-container
                *ngIf="formBuildingClass.value.state != 'THUERINGEN' && formBuildingClass.value.state != 'NORDRHEIN_WESTFALEN' && formBuildingClass.value.state != 'RHEINLAND_PFALZ' ">
                <mat-option [value]=true> Land- oder forstwirtschaftliche Nutzung</mat-option>
                <mat-option [value]=false> Anderweitige Nutzung</mat-option>
              </ng-container>
              <ng-container *ngIf="formBuildingClass.value.state == 'THUERINGEN'">
                <mat-option [value]=true> Gebäude, die einem land- oder forstwirtschaftlichen Betrieb dienen
                </mat-option>
                <mat-option [value]=false> Anderweitige Nutzung</mat-option>
              </ng-container>
              <ng-container *ngIf="formBuildingClass.value.state == 'NORDRHEIN_WESTFALEN'">
                <mat-option [value]=true> Land- oder forstwirtschaftliche Nutzung (und Gebäude mit vergleichbarer
                  Nutzung)
                </mat-option>
                <mat-option [value]=false> Anderweitige Nutzung</mat-option>
              </ng-container>
              <ng-container *ngIf="formBuildingClass.value.state == 'RHEINLAND_PFALZ'">
                <mat-option [value]=0> Land- oder forstwirtschaftliche Nutzung</mat-option>
                <mat-option [value]=1> Wohngebäude</mat-option>
                <mat-option [value]=2> Anderweitige Nutzung</mat-option>
              </ng-container>

            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Umgebungssituation des Gebäudes</mat-label>
            <mat-select formControlName="detached" required (focus)="infoStringBuildingClass = 'detached'">
              <mat-option [value]=true> freistehendes Gebäude</mat-option>
              <mat-option [value]=false> nicht freistehendes Gebäude</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Gebäudehöhe</mat-label>
            <mat-select formControlName="height" required (focus)="infoStringBuildingClass = 'height'">
              <mat-option [value]=0> < 7 Meter</mat-option>
              <mat-option [value]=1> &geq; 7 und < 13 Meter</mat-option>
              <mat-option [value]=2> &geq; 13 Meter</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Anzahl Nutzungseinheiten</mat-label>
            <mat-select formControlName="usageUnitsMoreThanTwo" required
                        (focus)="infoStringBuildingClass = 'usageUnitsMoreThanTwo'">
              <ng-container *ngIf="formBuildingClass.value.state != 'RHEINLAND_PFALZ'">
                <mat-option [value]=false> 2 oder weniger</mat-option>
                <mat-option [value]=true> Mehr als 2</mat-option>
              </ng-container>
              <ng-container *ngIf="formBuildingClass.value.state == 'RHEINLAND_PFALZ'">
                <mat-option [value]=0> 1</mat-option>
                <mat-option [value]=1> 2</mat-option>
                <mat-option [value]=2> 3</mat-option>
                <mat-option [value]=3> Mehr als 3</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Summe der Grundfläche</mat-label>
            <mat-select formControlName="floorSpace" required (focus)="infoStringBuildingClass = 'floorSpace'">
              <ng-container
                *ngIf="formBuildingClass.value.state != 'HESSEN' && formBuildingClass.value.state != 'NORDRHEIN_WESTFALEN' && formBuildingClass.value.state != 'RHEINLAND_PFALZ'">
                <mat-option [value]=0> Insgesamt &leq; 400 m²</mat-option>
                <mat-option [value]=1> Je Nutzungseinheit &leq; 400 m²</mat-option>
                <mat-option [value]=2> Insgesamt und je Nutzungseinheit > 400 m²</mat-option>
              </ng-container>
              <ng-container
                *ngIf="formBuildingClass.value.state == 'HESSEN' || formBuildingClass.value.state =='NORDRHEIN_WESTFALEN'">
                <mat-option [value]=0> Insgesamt &leq; 400 m²</mat-option>
                <mat-option [value]=1> Je Nutzungseinheit in einem Geschoss &leq; 400 m²</mat-option>
                <mat-option [value]=2> Insgesamt und je Nutzungseinheit in einem Geschoss > 400 m²</mat-option>
              </ng-container>
              <ng-container *ngIf="formBuildingClass.value.state == 'RHEINLAND_PFALZ'">
                <mat-option [value]=false> Insgesamt &leq; 400 m²</mat-option>
                <mat-option [value]=true> Insgesamt > 400 m²</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Gebäudelage</mat-label>
            <mat-select formControlName="underground" required (focus)="infoStringBuildingClass = 'underground'">
              <ng-container
                *ngIf="formBuildingClass.value.state != 'NIEDERSACHSEN' && formBuildingClass.value.state != 'RHEINLAND_PFALZ'">
                <mat-option [value]=false> Überirdisch</mat-option>
                <mat-option [value]=true> Unterirdisch</mat-option>
              </ng-container>
              <ng-container *ngIf="formBuildingClass.value.state == 'NIEDERSACHSEN'">
                <mat-option [value]=false> Überirdisch</mat-option>
                <mat-option [value]=true> Unterirdisch (mit Aufenthaltsräumen)</mat-option>
              </ng-container>
              <ng-container *ngIf="formBuildingClass.value.state == 'RHEINLAND_PFALZ'">
                <mat-option [value]=0> Überirdisch</mat-option>
                <mat-option [value]=1> Unterirdisch</mat-option>
                <mat-option [value]=2> Hanglage
                  (die dritte Wohnung bzw. Nutzungseinheit von insgesamt drei Wohnungen bzw. Nutzungseinheiten liegt im
                  untersten Geschoss und hat ihren Zugang unmittelbar vom Freien aus)
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>
    </form>

    <div class="text-l font-medium p-2" *ngIf="buildingClass">
      Gebäudeklasse: {{buildingClass}}
    </div>
  </div>

  <div class="shadow-full rounded-sm w-1/3 overflow-auto" *ngIf="infoStringBuildingClass">
    <div>
      <div class="text-xl font-medium p-2">
        Hinweise
      </div>
      <div class="p-2">
        <ng-container *ngIf="infoStringBuildingClass == 'agricultural'">
          <ng-container *ngIf="formBuildingClass.value.state != 'RHEINLAND_PFALZ'">
            Wie wird das Gebäude genutzt? Falls das Gebäude nicht land- oder forstwirtschaftlich genutzt wird, geben Sie
            bitte „anderweitige Nutzung an“.
          </ng-container>
          <ng-container *ngIf="formBuildingClass.value.state == 'RHEINLAND_PFALZ'">
            Wie wird das Gebäude genutzt? Falls das Gebäude nicht ein land- oder forstwirtschaftliches Betriebsgebäude
            oder Wohngebäude ist, geben Sie bitte „anderweitige Nutzung an“
          </ng-container>
        </ng-container>
        <ng-container *ngIf="infoStringBuildingClass == 'detached'">
          Ein Gebäude ist als „freistehend“ einzustufen, wenn es zu den Grundstücksgrenzen jeweils einen Abstand von
          mindestens 2,50 m und zu anderen Gebäuden, sowohl auf demselben als auch auf dem Nachbargrundstück, einen
          Abstand von mindestens 5,00 m einhält. Hierbei sind auch unterirdische Gebäudeteile zu berücksichtigen.
        </ng-container>
        <ng-container *ngIf="infoStringBuildingClass == 'height'">
          In Deutschland ist die Gebäudehöhe definiert als das gemittelte Maß zwischen der Geländeoberfläche und der
          Fußbodenoberkante des höchstgelegenen Geschosses, in dem ein Aufenthaltsraum möglich ist.
        </ng-container>
        <ng-container *ngIf="infoStringBuildingClass == 'usageUnitsMoreThanTwo'">
          Eine Nutzungseinheit ist definiert als ein abgeschlossener, einem bestimmten Nutzungszweck zugeordneter Bereich.
          Nutzungsbereiche können über mehrere Geschosse gehen, sobald sie intern verbunden sind.
        </ng-container>
        <!--      <ng-container *ngIf="infoString == 'floorSpace'">-->
        <!--        Wie wird das Gebäude genutzt? Falls das Gebäude nicht land- oder forstwirtschaftlich genutzt wird, geben Sie bitte „anderweitige Nutzung an“.-->
        <!--      </ng-container>-->
        <ng-container *ngIf="infoStringBuildingClass == 'underground'">
          Unterirdisch ist ein Gebäude, wenn es keine oberirdischen Geschosse hat. Lediglich die Erschließung darf
          oberirdisch liegen.
        </ng-container>
      </div>

    </div>
  </div>

</div>
