/**
 * BIC Quality API
 * Quality Services API - Use this API to access the Quality Services of the Building Information Cloud (BIC).
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Extraction } from './extraction';
import { FileType } from './fileType';

export interface Check { 
    id?: string;
    fileUrl?: string;
    fileType?: FileType;
    parameters?: any;
    extraction?: Extraction;
    result?: any;
}