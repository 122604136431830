import {Component, OnInit} from '@angular/core';
import {RuleResult, SWMCheck, SWMCheckResult, Task} from "../../../../api";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons";
import {TaskDetailsService} from "../../../../services/task-details.service";
import {CircleState} from "../../../util/design/circle-state/circle-state.component";


@Component({
  selector: 'app-file-report',
  templateUrl: './file-report.component.html',
  styleUrls: ['./file-report.component.scss']
})
export class FileReportComponent implements OnInit {

  swmCheck!: SWMCheck;
  checkResults: SWMCheckResult[] = [];
  displayedColumns = ['filename', 'state', 'task'];
  dataSource: MatTableDataSource<SWMCheckResult> = new MatTableDataSource<SWMCheckResult>();

  temp : SWMCheckResult[]  = [{"file_name": "BSK_Marienplatz.pdf", "result": [{"id": "7_2", "name": "7.2", "description": "Gefahrenbeurteilung", "k1Rules": [{"id": "7_2__0", "name": "7.2 Gefahrenbeurteilung und Einstufung in Gef\u00e4hrdungsstufen", "result": [], "k2Rules": []}]}, {"id": "7_3", "name": "7.3", "description": "Brandszenarien", "k1Rules": [{"id": "7_3__0", "name": "7.3 Brandszenarien", "result": [], "k2Rules": []}, {"id": "7_3__1", "name": "7.3 Brandszenarien", "result": [], "k2Rules": [{"id": "7_3__1_1", "name": "7.3 Brandszenarien", "result": []}]}]}]}]

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private taskDetailService: TaskDetailsService) {
  }

  ngOnInit(): void {
    this.swmCheck = this.activatedRoute.parent?.snapshot.data.swmCheck;
    this.checkResults = this.swmCheck.checkResult ?? [];


    // this.swmCheck = this.temp;
    this.dataSource = new MatTableDataSource(this.checkResults);
  }

  createTask(element: SWMCheckResult){
    let task = {
      markup: {
        topic: {
          title: 'Überarbeiten des BSKs: ' + element.file_name,
        }
      }
    }

    this.taskDetailService.setOpenedTask(task as Task);

  }

  get completed() {
    return (100 / this.total) * this.dataSource.data.filter(result => this.calculateResult(result) === 'success').length
  }

  get warning() {
    return 0;
  }

  get error() {
    return (100 / this.total) * this.dataSource.data.filter(result => this.calculateResult(result) === 'error').length
  }

  get total() {
    return this.dataSource.data.length
  }

  get fixedCheckDate(): Date{
    let date = new Date(this.swmCheck.checkDate!);
    date.setHours(date.getHours() + 2);
    return date;
  }

  calculateResult(element: SWMCheckResult){
    let numberOfErrors = 0
    let numberOfWarnings = 0
    let numberOfSuccess = 0

    for(let ruleResult of element.result ?? []){
      let numberOfFailedK1Rules =  ruleResult.k1Rules!.filter(rule => rule.result?.length == 0 ).length;
        if (numberOfFailedK1Rules == 0){
          let numberOfFailedK2Rules = ruleResult.k1Rules!.filter(rule => rule.k2Rules!.filter(k2Rule => k2Rule.result?.length == 0).length > 0).length;
          if (numberOfFailedK2Rules == 0) {
            numberOfSuccess++
          }else{
            numberOfWarnings++
          }
        }else if (numberOfFailedK1Rules == ruleResult.k1Rules!.length) {
         numberOfErrors ++
        }else{
          numberOfWarnings++
        }
    }
    if(numberOfSuccess == element.result!.length){
      return "success"
    }else{
      return "error"
    }

  }


  navigateToRulesView(element: SWMCheckResult){
    this.router.navigate([element.file_id], {relativeTo: this.activatedRoute});
  }

  protected readonly faClipboardList = faClipboardList;
  protected readonly CircleState = CircleState;
}
