<div *ngIf="organisation" class="mt-2 h-full w-full overflow-y-scroll px-4 pb-2 pt-8">
  <mat-accordion>
    <!-- panel: general project info -->
    <mat-expansion-panel class="py-2 px-4" *ngIf="true" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-[20px] text-secondary">{{ organisation.name }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="generalInfo"></ng-container>
    </mat-expansion-panel>

    <!-- panel:  project address info -->
    <mat-expansion-panel class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-[20px] text-secondary">Adresse</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="addressInfo"></ng-container>
    </mat-expansion-panel>

    <!-- panel:  project members info -->
    <mat-expansion-panel class="py-2 px-4">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="text-[20px] text-secondary">Organisationsmitglieder</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container>

        <div class="pb-4">
          <app-users-list
            [addDisabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)"
            [deleteDisabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)"
            [permissionsDisabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)"
            [isProject]="false"
            [organisation]="organisation"
            [changeEventTriggered]="organisationChangedEvent.asObservable()"
            [fromProjectAccordion]="true"
          ></app-users-list>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #generalInfo>
  <form [formGroup]="generalInfoForm" class="pt-2 pb-4">
    <div class="flex flex-col justify-start gap-12">

      <!-- content row split into 3 columns-->
      <div class="flex flex-row justify-center pl-2 gap-16">

        <!-- project image -->
        <div class="flex flex-row h-[150px] w-[150px] pt-4 justify-center items-center">
          <app-organisation-image-upload *ngIf="organisation" [(image)]="organisation.profileImage"
                                         [organisation]="organisation"></app-organisation-image-upload>
        </div>

        <!-- inputs -->
        <div class="flex flex-row h-[150px] w-full justify-between gap-12">
          <!-- name and id column -->
          <div class="flex flex-col justify-between h-full flex-grow gap-8">
            <bic-input class="w-full" [label]="'Name der Organisation *'" [formControlName]="'name'"
                       [placeholder]="'Eingeben'" [errorCondition]="generalInfoForm.controls.name.touched && generalInfoForm.controls.name.invalid"></bic-input>

<!--            <mat-form-field appearance="outline" class="flex flex-row h-12 w-full" subscriptSizing="dynamic">-->
<!--              <mat-label>Name der Organisation</mat-label>-->
<!--              <input formControlName="name" matInput required>-->
<!--              <mat-error *ngIf="generalInfoForm.controls.name.touched && generalInfoForm.controls.name.invalid">-->
<!--                <span *ngIf="generalInfoForm.controls.name.errors?.required">Sie müssen einen Namen angeben!</span>-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->

            <bic-input class="w-full" [label]="'Geschäftliche E-Mail *'" [formControlName]="'organisationMail'"
                       [placeholder]="'Eingeben'"></bic-input>

<!--            <mat-form-field appearance="outline" class="flex flex-row h-12 w-full" subscriptSizing="dynamic">-->
<!--              <mat-label>Geschäftliche E-Mail</mat-label>-->
<!--              <input formControlName="organisationMail" matInput>-->
<!--            </mat-form-field>-->
          </div>

          <!-- owner and project kind column -->
          <div class="flex flex-col justify-between h-full flex-grow gap-8">
            <bic-select [formControlName]="'legalForm'" [label]="'Rechtsform'" class="w-full">
              <bic-dropdown-element *ngFor="let i of legalForm | keyvalue" [value]="i.key">{{ i.value | legalForms }}</bic-dropdown-element>
            </bic-select>

<!--            <mat-form-field appearance="outline" class="flex-row h-12 w-full" subscriptSizing="dynamic">-->
<!--              <mat-label>Rechtsform</mat-label>-->
<!--              <mat-select formControlName="legalForm" [disabled]="generalInfoForm.controls['legalForm'].disabled">-->
<!--                <mat-option *ngFor="let i of legalForm | keyvalue"-->
<!--                            [value]="i.key">{{ i.value | legalForms }}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->

            <bic-input class="w-full" [label]="'Umsatzsteueridentifikationsnummer'" [formControlName]="'vatNumber'"
                       [placeholder]="'Eingeben'" [errorCondition]="generalInfoForm.controls.vatNumber.touched && generalInfoForm.controls.vatNumber.invalid"></bic-input>



<!--            <mat-form-field appearance="outline" class="flex-row h-12 w-full" subscriptSizing="dynamic">-->
<!--              <mat-label>Umsatzsteueridentifikationsnummer</mat-label>-->
<!--              <input formControlName="vatNumber" matInput>-->
<!--              <mat-error *ngIf="generalInfoForm.controls.vatNumber.touched && generalInfoForm.controls.vatNumber.invalid">-->
<!--                <span *ngIf="generalInfoForm.controls.vatNumber.errors?.validVATRequired">Sie müssen eine gültige Umsatzsteueridentifikationsnummer angeben!</span>-->
<!--              </mat-error>-->
<!--            </mat-form-field>-->
          </div>


        </div>
      </div>

      <!-- delete and save buttons -->
      <div class="flex flex-row justify-between">
        <div *ngIf="permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)" class="w-[120px]">
          <bic-button [tooltip]="'Organisation löschen'" [text]="'Löschen'" (onClick)="deleteDialog()" [textColor]="'white'" [color]="'error'"
                      [border]="true"></bic-button>

<!--          <button [matTooltip]="'Organisation löschen'" (click)="deleteDialog()" mat-raised-button color="warn" class="bg-error">-->
<!--            Löschen-->
<!--          </button>-->
        </div>

        <div *ngIf="!permissionService.hasOrganisationPrivilege('ORGANISATION_USER_MANAGEMENT', organisation.id!)" class="w-[120px]">
          <bic-button [tooltip]="'Organisation verlassen'" [text]="'Verlassen'" (onClick)="leaveDialog()" [textColor]="'white'" [color]="'error'"
                      [border]="true"></bic-button>

<!--          <button [matTooltip]="'Organisation löschen'" (click)="leaveDialog()" mat-raised-button color="warn">-->
<!--            Verlassen-->
<!--          </button>-->
        </div>

        <div class="w-[120px]">
          <bic-button [text]="'Speichern'" (onClick)="save()"
                      [disabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS') || saveDisabled"></bic-button>

<!--          <button mat-raised-button color="primary" (click)="save()"-->
<!--                  [disabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS') || saveDisabled">-->
<!--            Speichern-->
<!--          </button>-->
        </div>
      </div>
      <!--      </div>-->

    </div>
  </form>
</ng-template>


<ng-template #addressInfo>
  <div class="flex flex-col justify-start w-full gap-12">
    <app-address-input
      formControlNameZipcode="zipCode"
      formControlNameCity="city"
      formControlNameHouseNumber="houseNumber"
      [stateDisabled]="false"
      [displayPlotNumber]="false"
      [formGroup]="addressInfoForm"></app-address-input>

    <div class="flex flex-row justify-end pt-6">
      <div class="w-[120px]">
        <bic-button [text]="'Speichern'" (onClick)="save()"
                    [disabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS') || saveDisabled"></bic-button>
<!--        -->
<!--        <button mat-raised-button #saveButton color="primary"-->
<!--                [disabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS') || saveDisabled"-->
<!--                (click)="save()">Speichern-->
<!--        </button>-->
      </div>
    </div>
  </div>




<!--

  <form [formGroup]="addressInfoForm" class="pt-2 pb-4">
    <div class="flex flex-col justify-start w-full gap-12">

      &lt;!&ndash; content is one big column split into 2 rows + button row &ndash;&gt;
      <div class="flex flex-row justify-between w-full gap-16">

        <div class="flex flex-col w-[50%] gap-8">
          <mat-form-field appearance="outline" class="h-[50px] w-full">
            <mat-label>Straße</mat-label>
            <input #street required formControlName="street" matInput [matAutocomplete]="autoStreet"
                   (ngModelChange)="locationGuesses$ = serviceControllerService.getLocationSuggestions(street.value)">
            <mat-autocomplete #autoStreet="matAutocomplete">
              <mat-option *ngFor="let location of (locationGuesses$ | async) ?? []" [value]="location.street"
                          (onSelectionChange)="updateLocation(location)">
                {{ location.street }} {{ location.addressNumber }}
                , {{ location.postalCode }} {{ location.municipality }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <div class="flex flex-row w-full gap-16">
            &lt;!&ndash; zipCode &ndash;&gt;
            <mat-form-field appearance="outline" class="h-full w-[50%]">
              <mat-label>Postleitzahl</mat-label>
              <input required formControlName="zipCode" matInput maxlength="5">
            </mat-form-field>

            &lt;!&ndash; city / buildingPlace &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[50%]">
              <mat-label>Ort</mat-label>
              <input required formControlName="city" matInput>
            </mat-form-field>
          </div>




        </div>

        <div class="flex flex-col w-[50%] gap-8">

          &lt;!&ndash; houseNumber &ndash;&gt;
          <mat-form-field appearance="outline" class="h-[50px] w-full">
            <mat-label>Hausnummer</mat-label>
            <input required formControlName="houseNumber" matInput>
          </mat-form-field>

          <div class="flex flex-row w-full gap-16">
            &lt;!&ndash; country &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[50%]">
              <mat-label>Land</mat-label>
              <mat-select formControlName="country">
                <mat-option *ngFor="let country of countries | sortCountries |keyvalue: valueAscOrder"
                            [value]="country.key">
                  {{ country.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            &lt;!&ndash; state &ndash;&gt;
            <mat-form-field appearance="outline" class="h-[50px] w-[50%]">
              <mat-label>Bundesland</mat-label>
              <mat-select formControlName="state">
                <mat-option *ngFor="let state of states | keyvalue" [value]="state.value">
                  {{ state.value | projectState }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </div>

      &lt;!&ndash; save button &ndash;&gt;
      <div class="flex flex-row justify-end pt-6">
        <div class="w-[120px]">
          <button mat-raised-button #saveButton color="primary"
                  [disabled]="!permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS') || saveDisabled"
                  (click)="save()">Speichern
          </button>
        </div>
      </div>


    </div>

  </form>
  -->
</ng-template>

