import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  CheckControllerService,
  FileControllerService,
  Project,
  ProjectControllerService,
  ProjectFile, User,
} from "../../../api";
import {SelectionModel} from "@angular/cdk/collections";
import {MoveFilesDialogComponent} from "./components/move-files-dialog/move-files-dialog.component";
import {StateConfigService} from "../../../services/state/state-config.service";
import {PermissionService} from "../../../services/permission/permission.service";
import {RenameFileData} from "./components/files-table/files-table.component";
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxFileDropEntry} from "ngx-file-drop";
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {faArrowUpFromBracket, faCopy, faDownload, faEdit, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import LphEnum = ProjectFile.LphEnum;
import {OrganisationPopupComponent} from "../../organisation/organisation-popup/organisation-popup.component";
import {CopyFilesDialogComponent} from "./components/copy-files-dialog/copy-files-dialog.component";
import {faArrowDownToBracket, faGrid2, faSquareDashedCirclePlus} from "@fortawesome/pro-solid-svg-icons";

export interface FileTreeNode extends ProjectFile {
  renaming: boolean;
}


@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
})
export class FilesComponent implements OnInit, OnChanges {

  @Input() project!: Project

  @Input() folder!: ProjectFile.FolderEnum;

  @Input() lph!: LphEnum;

  @Input() checkAllowed: boolean = false;

  @Output() fileCheckState = new EventEmitter<boolean>();

  @Output() openedFile = new EventEmitter<ProjectFile>();

  @Output() selectedFilesChange = new EventEmitter<ProjectFile[]>();


  @Input() breadcrumbNavigation: ProjectFile[] = [];
  @Output() breadcrumbNavigationChange = new EventEmitter<ProjectFile[]>();

  @ViewChild('fileInput') fileInput: any;

  dragAndDropHovered: boolean = false;

  uploadingDocuments: Map<string, number> = new Map<string, number>();
  runningDocumentClassifications: string[] = [];

  fileTrees: ProjectFile[] = [];
  selectedFiles: SelectionModel<FileTreeNode> = new SelectionModel<FileTreeNode>(true, []);

  dropZoneClassName: string = "custom-file-drop";
  contentClassName: string = "custom-file-drop-content";
  dragged = false;
  fileTooLarge= false;

  public files: NgxFileDropEntry[] = [];


  constructor(private projectControllerService: ProjectControllerService,
              private fileControllerService: FileControllerService,
              private checkControllerService: CheckControllerService,
              public stateConfigService: StateConfigService,
              protected permissionService: PermissionService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              private http: HttpClient) {

    this.selectedFiles.changed.subscribe((selection) => {
      this.selectedFilesChange.emit(selection.source.selected);
    });
  }

  ngOnInit(): void {
    this.loadFiles();

    this.stateConfigService.runningClassificationsChange.subscribe((runningClassifications) => {
      this.runningDocumentClassifications = runningClassifications;
      this.loadFiles();
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    this.fileLeave(null)
    let filesToUpload: File[] = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].fileEntry.isFile) {
        const fileEntry = files[i].fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if(this.folder == ProjectFile.FolderEnum.PLANUNGUNDBIM) {
            if(file.type == "application/pdf" || file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              || file.name.split(".")[file.name.split(".").length -1] == "ifc") {
              filesToUpload.push(file);
            }
          }else{
            if(file.type == "application/pdf" || file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
              filesToUpload.push(file);
            }
          }

          if (i == files.length - 1) {
            this.upload(filesToUpload, true)
          }

        });
      } else {
        // Leerer Ordner wurde hochgeladen
        const fileEntry = files[i].fileEntry as FileSystemDirectoryEntry;
      }

    }

  }

  public fileOver(event: any) {
    this.dropZoneClassName = "custom-file-drop-drag";
    this.contentClassName = "custom-file-drop-content-drag";
    this.dragged = true;
  }

  public fileLeave(event: any) {
    this.dropZoneClassName = "custom-file-drop";
    this.contentClassName = "custom-file-drop-content";
    this.dragged = false;
  }

  get uploadingPercentage(): number {
    let percentage = 0;
    this.uploadingDocuments.forEach((value, key) => {
      percentage += value;
    });
    return Math.round(percentage / this.uploadingDocuments.size);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.folder) {
      this.loadFiles();
    }
  }

  loadFiles() {
    this.fileControllerService.getFiles(this.stateConfigService.getProjectId(), this.folder, this.lph).subscribe(files => {
      this.fileTrees = files;
      this.breadcrumbNavigation = [];
    });
  }

  breadcrumbChanged(files: ProjectFile[]) {
    this.breadcrumbNavigation = files;
    this.breadcrumbNavigationChange.emit(files);
  }

  removeSelectedFilesTrees() {
    this.selectedFiles.selected.forEach(file => {
      this.fileControllerService.deleteFile(file.id!).subscribe(files => {
        this.fileTrees = files;
        this.selectedFiles.deselect(file);
        this.snackBar.open("Dateien gelöscht", "OK", {
          duration: 2000,
        });
      });
    });
  }

  removeFile(node: ProjectFile) {
    this.fileControllerService.deleteFile(node.id!).subscribe(files => {
      this.fileTrees = files;
      this.snackBar.open("Datei gelöscht", "OK", {
        duration: 2000,
      });
    });
  }

  renameFile(data: RenameFileData) {
    this.fileControllerService.renameFile(data.newName, data.file.id!).subscribe(files => {
      this.fileTrees = files;
      this.selectedFiles.clear();
    });

  }

  createFolder(name: string) {
    if (this.breadcrumbNavigation.length > 0) {
      name = this.breadcrumbNavigation.map(f => f.name).join("/") + "/" + name;
    }

    this.fileControllerService.createFolder(name, this.project.id!, this.folder, this.lph).subscribe(files => {
      this.fileTrees = files;
    });
  }

  openMoveFilesDialog() {
    let dialogRef = this.dialog.open(MoveFilesDialogComponent, {
      width: '30%',
      panelClass:"rounded-corners-dialog",
      data: {
        fileTrees: this.fileTrees,
        selectedFiles: this.selectedFiles.selected
      }
    });

    dialogRef.afterClosed().subscribe((result: FileTreeNode[]) => {
      let ids = result.map(file => file.id!);
      this.fileControllerService.moveFile(ids, result[0].lph!, result[0].folder!).subscribe(files => {
        this.fileTrees = files;
        this.selectedFiles.clear();
      });


    });
  }

  openCopyFilesDialog() {
    let ref = this.dialog.open(CopyFilesDialogComponent, {
      width: '30%',
      panelClass:"rounded-corners-dialog",
      data: {
        fileTrees: this.fileTrees,
        selectedFiles: this.selectedFiles.selected
      }
    });

    ref.afterClosed().subscribe((result: FileTreeNode[]) => {
      let ids = result.map(file => file.id!);
      this.fileControllerService.copyFile(ids, result[0].lph!, result[0].folder!).subscribe(files => {
        this.fileTrees = files;
        this.selectedFiles.clear();
      });


    });
  }

  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }


  openFileTooLargeDialog() {
    let ref = this.dialog.open(FileTooLargeDialog, {
      panelClass: 'rounded-corners-dialog',
      data: {
      },
    });

    ref.afterClosed().subscribe(user => {
    });
  }


  upload(event: any, drop?: boolean) {
    let files: File[] = [];
    if (drop) {
      files = Array.from(event);
    } else {
      files = event.target.files;
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      this.fileTooLarge = false;

      if (this.calculateSizeInMB(file.size) > 6000) {
        this.openFileTooLargeDialog();
        continue;
      }

      let filetype = file.webkitRelativePath.split(".")[file.webkitRelativePath.split(".").length - 1].toLowerCase();
      //filetype == '' for single files not in folder
      if(this.folder == ProjectFile.FolderEnum.PLANUNGUNDBIM) {
        if(filetype == 'pdf' || filetype == 'ifc' || filetype == 'xlsx' || filetype == '') {
          this.fileControllerService.addFile(this.project.id!, this.folder, this.lph, file.name, file.size).subscribe(s => {
            if (i == files.length - 1) {
              this.uploadFileUsingPresignedUrlWithProgress(s.string!, file, s.fileId!, true);
            } else {
              this.uploadFileUsingPresignedUrlWithProgress(s.string!, file, s.fileId!, false);
            }
          });
        }else{
          this.snackBar.open("Eine Datei innerhalb des Ordners enthält ein nicht akzeptiertes Dateiformat", "Close", {
            duration: 5000,
          });
        }
      }else{
        if(filetype == 'pdf' || filetype == 'xlsx' || filetype == '') {
          this.fileControllerService.addFile(this.project.id!, this.folder, this.lph, file.name, file.size).subscribe(s => {
            if (i == files.length - 1) {
              this.uploadFileUsingPresignedUrlWithProgress(s.string!, file, s.fileId!, true);
            } else {
              this.uploadFileUsingPresignedUrlWithProgress(s.string!, file, s.fileId!, false);
            }
          });
        }else{
          this.snackBar.open("Eine Datei innerhalb des Ordners enthält ein nicht akzeptiertes Dateiformat", "Close", {
            duration: 5000,
          });
        }
      }
    }
  }

  uploadFileUsingPresignedUrlWithProgress(url: string, file: File, fileId: string, loadFiles: boolean): void {
    const req = new HttpRequest('PUT', url, file, {
      reportProgress: true,
    });

    this.http.request(req).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total!);
          this.uploadingDocuments.set(file.name, percentDone);
        } else if (event instanceof HttpResponse) {
          this.uploadingDocuments.delete(file.name);
          this.fileControllerService.classify(fileId).subscribe(data => {
            if (loadFiles) {
              this.loadFiles();
            }
          });
        }
      });
  }


  classifyDocument(file: ProjectFile) {
    this.fileControllerService.classify(file.id!).subscribe(data => {
      this.ngOnInit();
    });
  }

  downloadSelectedFiles() {
    if (this.selectedFiles.selected.length == 1 && !this.selectedFiles.selected[0].isFolder) {
      let file = this.selectedFiles.selected[0];
      const a = document.createElement('a');
      a.href = <string>file.link;
      a.target = '_blank';
      a.click();
    }
    if (this.selectedFiles.selected.length > 1 || this.selectedFiles.selected[0].isFolder) {
      let selectedFiles = this.selectedFiles.selected;
      let children: ProjectFile[] = [];
      for (let i = 0; i < selectedFiles.length; i++) {
        if (selectedFiles[i].isFolder) {
          children = children.concat(this.getAllChilden(selectedFiles[i]));
        }
      }
      selectedFiles = selectedFiles.filter(file => !file.isFolder);
      children = children.filter(c => !c.isFolder);
      let files: (string)[] = selectedFiles.map(file => file.objectKey ?? "").concat(children.map(file => file.objectKey ?? ""));
      this.fileControllerService.downloadMultipleFiles(files, this.stateConfigService.getProjectId() ?? 0).subscribe(data => {
        const a = document.createElement('a');
        a.href = <string>data.url;
        a.target = '_self';
        a.click();
      });
    }
  }

  private getAllChilden(file: ProjectFile): ProjectFile[] {
    let children: ProjectFile[] = [];
    // if (file.isFolder) {
    //     file.children?.forEach(c => {
    //         children.push(c);
    //         let deeperChildren = this.getAllChilden(c);
    //         if (deeperChildren.length > 0) {
    //             children = children.concat(deeperChildren);
    //         }
    //     });
    // }
    return children;
  }


  //TODO: bei else normal zurückgeben und Änderung der Eingabe berücksichtigen
  searchFiles(searchTerm: string) {
    let searchResult: ProjectFile[] = [];
    for (let file of this.fileTrees) {
      // @ts-ignore
      if (file.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchResult.push(file);
      }
    }
    return searchResult;
  }
  searchTerm: string = "";

  protected readonly LphEnum = LphEnum;
  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faArrowUpFromBracket = faArrowUpFromBracket;
  protected readonly faDownload = faDownload;
  protected readonly faMagnifyingGlass = faMagnifyingGlass;
  protected readonly faCopy = faCopy;
    protected readonly faArrowDownToBracket = faArrowDownToBracket;
  protected readonly faGrid2 = faGrid2;
  protected readonly faSquareDashedCirclePlus = faSquareDashedCirclePlus;
  protected readonly faEdit = faEdit;
}


/**
 * Dialog for User Deletion
 */
@Component({
  selector: 'file-too-bigDialog',
  templateUrl: './file-too-large-dialog.html',
  styleUrls: ['./files.component.scss'],
})
export class FileTooLargeDialog implements OnInit {
  constructor (@Inject(MAT_DIALOG_DATA) public data: { }, private dialogRef: MatDialogRef<FileTooLargeDialog>) {
  }

  ngOnInit() {
  }


  protected readonly faCircleXmark = faCircleXmark;
}
