/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Markup } from './markup';
import { User } from './user';
import { VisualizationInfo } from './visualizationInfo';

export interface Task { 
    id?: string;
    state?: Task.StateEnum;
    priority?: Task.PriorityEnum;
    informedUsers?: Array<User>;
    lph?: Task.LphEnum;
    markup?: Markup;
    visualizationInfo?: Array<VisualizationInfo>;
    ifcLink?: string;
    ifcName?: string;
    isImported?: boolean;
    isBCF?: boolean;
    projectID?: number;
    projectName?: string;
}
export namespace Task {
    export type StateEnum = 'OPEN' | 'PROCESS' | 'DONE' | 'VERIFIED';
    export const StateEnum = {
        OPEN: 'OPEN' as StateEnum,
        PROCESS: 'PROCESS' as StateEnum,
        DONE: 'DONE' as StateEnum,
        VERIFIED: 'VERIFIED' as StateEnum
    };
    export type PriorityEnum = 'HIGH' | 'NORMAL' | 'LOW';
    export const PriorityEnum = {
        HIGH: 'HIGH' as PriorityEnum,
        NORMAL: 'NORMAL' as PriorityEnum,
        LOW: 'LOW' as PriorityEnum
    };
    export type LphEnum = 'GENERAL' | 'LPH1' | 'LPH2' | 'LPH3' | 'LPH4' | 'LPH5' | 'LPH6' | 'PLANS';
    export const LphEnum = {
        GENERAL: 'GENERAL' as LphEnum,
        LPH1: 'LPH1' as LphEnum,
        LPH2: 'LPH2' as LphEnum,
        LPH3: 'LPH3' as LphEnum,
        LPH4: 'LPH4' as LphEnum,
        LPH5: 'LPH5' as LphEnum,
        LPH6: 'LPH6' as LphEnum,
        PLANS: 'PLANS' as LphEnum
    };
}