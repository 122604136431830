/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Contingent } from './contingent';

export interface RoleResponse { 
    privileges?: Array<RoleResponse.PrivilegesEnum>;
    name?: string;
    contingent?: Contingent;
}
export namespace RoleResponse {
    export type PrivilegesEnum = 'PROJECT_VIEWER' | 'PROJECT_SETTINGS' | 'PROJECT_USER_MANAGEMENT' | 'PROJECT_FILE_EDIT' | 'PROJECT_FILE_DELETE' | 'PROJECT_TASK_EDIT' | 'PROJECT_TASK_DELETE' | 'PROJECT_HOAI_CHECK' | 'PROJECT_QUALITY_CHECK' | 'PROJECT_BIM_TOOLS_CHECK' | 'PROJECT_SHOP' | 'ORGANISATION_VIEWER' | 'ORGANISATION_SETTINGS' | 'ORGANISATION_USER_MANAGEMENT' | 'ORGANISATION_PROJECT_EDIT' | 'ORGANISATION_PROJECT_DELETE' | 'ORGANISATION_SHOP';
    export const PrivilegesEnum = {
        PROJECTVIEWER: 'PROJECT_VIEWER' as PrivilegesEnum,
        PROJECTSETTINGS: 'PROJECT_SETTINGS' as PrivilegesEnum,
        PROJECTUSERMANAGEMENT: 'PROJECT_USER_MANAGEMENT' as PrivilegesEnum,
        PROJECTFILEEDIT: 'PROJECT_FILE_EDIT' as PrivilegesEnum,
        PROJECTFILEDELETE: 'PROJECT_FILE_DELETE' as PrivilegesEnum,
        PROJECTTASKEDIT: 'PROJECT_TASK_EDIT' as PrivilegesEnum,
        PROJECTTASKDELETE: 'PROJECT_TASK_DELETE' as PrivilegesEnum,
        PROJECTHOAICHECK: 'PROJECT_HOAI_CHECK' as PrivilegesEnum,
        PROJECTQUALITYCHECK: 'PROJECT_QUALITY_CHECK' as PrivilegesEnum,
        PROJECTBIMTOOLSCHECK: 'PROJECT_BIM_TOOLS_CHECK' as PrivilegesEnum,
        PROJECTSHOP: 'PROJECT_SHOP' as PrivilegesEnum,
        ORGANISATIONVIEWER: 'ORGANISATION_VIEWER' as PrivilegesEnum,
        ORGANISATIONSETTINGS: 'ORGANISATION_SETTINGS' as PrivilegesEnum,
        ORGANISATIONUSERMANAGEMENT: 'ORGANISATION_USER_MANAGEMENT' as PrivilegesEnum,
        ORGANISATIONPROJECTEDIT: 'ORGANISATION_PROJECT_EDIT' as PrivilegesEnum,
        ORGANISATIONPROJECTDELETE: 'ORGANISATION_PROJECT_DELETE' as PrivilegesEnum,
        ORGANISATIONSHOP: 'ORGANISATION_SHOP' as PrivilegesEnum
    };
}