<div class="w-full h-full bg-[#FFFFFF]">
  <div class="w-full h-full flex flex-col">
    <div class="flex w-full h-[64px]">
      <app-chat-top-nav class="flex w-full justify-end "></app-chat-top-nav>
    </div>
    <div class="flex-auto overflow-hidden">
    <div class="flex flex-row w-full flex-grow h-full justify-start overflow-hidden">
      <div class="w-[7%]"></div>
      <app-chat-view class="h-inherit pt-6 pb-4" [ngClass]="{'w-[68%]':this.chatService.chatHistoryExpanded || this.chatService.allFilesExpanded, 'w-[86%]':!this.chatService.chatHistoryExpanded&&!this.chatService.allFilesExpanded}"></app-chat-view>
      <app-all-chats-view *ngIf="this.chatService.chatHistoryExpanded"
                          class="w-[25%] h-inherit pl-4 pr-6 pb-4 pt-6"></app-all-chats-view>
      <app-all-files-view *ngIf="this.chatService.allFilesExpanded"
                          class="w-[25%] h-inherit pl-4 pr-6 pb-4 pt-6"></app-all-files-view>
      <div *ngIf="!this.chatService.chatHistoryExpanded && !this.chatService.allFilesExpanded" class="w-[7%]"></div>
    </div>
    </div>
    <div class="flex-none w-full">
      <div class="flex flex-row w-full">
        <div class="w-[7%]"></div>
        <app-message-view [ngClass]="{'w-[68%]':this.chatService.chatHistoryExpanded || this.chatService.allFilesExpanded, 'w-[86%]':!this.chatService.chatHistoryExpanded&&!this.chatService.allFilesExpanded}"></app-message-view>
        <div *ngIf="this.chatService.chatHistoryExpanded || this.chatService.allFilesExpanded" class="w-[25%]"></div>
        <div *ngIf="!this.chatService.chatHistoryExpanded && !this.chatService.allFilesExpanded" class="w-[7%]"></div>
      </div>
    </div>
  </div>


</div>
