import { Component, Input } from '@angular/core';
import { Project } from 'src/app/api';

@Component({
  selector: 'app-dashboard-project-information',
  templateUrl: './dashboard-project-information.component.html',
  styleUrls: ['./dashboard-project-information.component.scss']
})
export class DashboardProjectInformationComponent {

  @Input() project!: Project;

}
