import {Component, Input} from '@angular/core';
import {Contingent, ProductSettings } from 'src/app/api';
import {faCheck} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-checkout-feature-list',
  templateUrl: './checkout-feature-list.component.html',
  styleUrls: ['./checkout-feature-list.component.scss']
})
export class CheckoutFeatureListComponent {

  @Input() isInfo: boolean = false;
  @Input() productSettings!: ProductSettings
  @Input() active: boolean = false;
  @Input() contingent!: Contingent

  protected readonly faCheck = faCheck;

  get isTrial(): boolean {
    return this.productSettings.isTrial ?? false;
  }
}
