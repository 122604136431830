import {Component, Input, OnInit} from '@angular/core';
import {DecimalPipe, NgIf} from "@angular/common";

export enum CircleState {
  GREEN = "green",
  YELLOW = "yellow",
  RED = "red",
}

@Component({
  selector: 'app-circle-state',
  templateUrl: './circle-state.component.html',
  styleUrls: ['./circle-state.component.scss'],
  imports: [
    NgIf,
    DecimalPipe
  ],
  standalone: true
})
export class CircleStateComponent implements OnInit {

  @Input() state: CircleState = CircleState.GREEN;
  @Input() value: number = 20;
  @Input() compact: boolean = false;
  @Input() useAsIcon: boolean = false;

  private readonly circumference: number = 772.45;

  constructor() { }

  ngOnInit(): void {
  }

  public calculateDashOffset(): number {
    return this.circumference - (this.value / 100 * this.circumference);
  }

  protected readonly CircleState = CircleState;
}
