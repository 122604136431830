import {Component, Input} from '@angular/core';
import {CdkStepper} from "@angular/cdk/stepper";
import {matStepperAnimations} from "@angular/material/stepper";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

/**
 * Stepper using Cloud Icons
 */
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{provide: CdkStepper, useExisting: StepperComponent}],
  animations: [
    matStepperAnimations.horizontalStepTransition,
  ],
})
export class StepperComponent extends CdkStepper {


  onClick(index: number): void {
    this.selectedIndex = index;
    this._stateChanged();
  }

  protected readonly faLinkIcon = faChevronRight;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
}
