import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'bic-card',
  templateUrl: './bic-card.component.html',
  styleUrls: ['./bic-card.component.scss']
})
export class BicCardComponent implements OnInit {

  @Output() click = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
