import { Pipe, PipeTransform } from '@angular/core';
import {ProjectFile} from "../api";
import FolderEnum = ProjectFile.FolderEnum;
import LphEnum = ProjectFile.LphEnum;

@Pipe({
  standalone: true,
  name: 'folderName'
})
export class FolderPipe implements PipeTransform {

  // @ts-ignore
  transform(value: FolderEnum, lph: LphEnum): String {

    if(lph == LphEnum.LPH1){

      switch (value) {

        case FolderEnum.BESTANDSAUFNAHME: return "Bestandsaufnahme";

        case FolderEnum.KOSTEN: return "Kosten";

        case FolderEnum.TERMINE: return "Termine";

        case FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH: return "Funktions-, Raumprogramm, Raumbuch";

        case FolderEnum.UMWELTTECHNISCHEBELANGE: return "Umwelttechnische Belange";

        case FolderEnum.BEDARFSPLANUNGUNDERMITTLUNG: return "Bedarfsplanung und Ermittlung";

        case FolderEnum.STANDORTANALYSE: return "Standortanalyse";

        case FolderEnum.BETRIEBSPLANUNG: return "Betriebsplanung";

        case FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN: return "Weitere Bauprojektunterlagen";

      }

    }else if (lph == LphEnum.LPH2){

      switch (value) {

        case FolderEnum.BESTANDSAUFNAHME: return "Bestandsaufnahme";

        case FolderEnum.KOSTEN: return "Kosten";

        case FolderEnum.TERMINE: return "Termine";

        case FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH: return "Funktions-, Raumprogramm, Raumbuch";

        case FolderEnum.UMWELTTECHNISCHEBELANGE: return "Umwelttechnische Belange";

        case FolderEnum.PLANUNGUNDBIM: return "Planung und BIM";

        case FolderEnum.BAURECHTBAUGENEHMIGUNG: return "Baurecht und Baugenehmigung";

        case FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN: return "Weitere Bauprojektunterlagen";

      }

    }else if (lph == LphEnum.LPH3){

      switch (value) {

        case FolderEnum.BESTANDSAUFNAHME: return "Bestandsaufnahme";

        case FolderEnum.KOSTEN: return "Kosten";

        case FolderEnum.TERMINE: return "Termine";

        case FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH: return "Funktions-, Raumprogramm, Raumbuch";

        case FolderEnum.UMWELTTECHNISCHEBELANGE: return "Umwelttechnische Belange";

        case FolderEnum.PLANUNGUNDBIM: return "Planung und BIM";

        case FolderEnum.BAURECHTBAUGENEHMIGUNG: return "Baurecht und Baugenehmigung";

        case FolderEnum.OBJEKTBESCHREIBUNG: return "Objektbeschreibung";

        case FolderEnum.GUTACHTEN: return "Gutachten";

        case FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN: return "Weitere Bauprojektunterlagen";

      }

    }else if (lph == LphEnum.LPH4){

      switch (value) {

        case FolderEnum.BESTANDSAUFNAHME: return "Bestandsaufnahme";

        case FolderEnum.PLANUNGUNDBIM: return "Planung und BIM";

        case FolderEnum.BAURECHTBAUGENEHMIGUNG: return "Baurecht und Baugenehmigung";

        case FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH: return "Funktions-, Raumprogramm, Raumbuch";

        case FolderEnum.KOSTEN: return "Kosten";

        case FolderEnum.OBJEKTBESCHREIBUNG: return "Objektbeschreibung";

        case FolderEnum.GUTACHTEN: return "Gutachten";

        case FolderEnum.STATIK: return "Statik";

        case FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN: return "Weitere Bauprojektunterlagen";

      }

    }else if(lph == LphEnum.LPH5){

      switch (value) {

        case FolderEnum.BESTANDSAUFNAHME: return "Bestandsaufnahme";

        case FolderEnum.KOSTEN: return "Kosten";

        case FolderEnum.TERMINE: return "Termine";

        case FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH: return "Funktions-, Raumprogramm, Raumbuch";

        case FolderEnum.UMWELTTECHNISCHEBELANGE: return "Umwelttechnische Belange";

        case FolderEnum.PLANUNGUNDBIM: return "Planung und BIM";

        case FolderEnum.BAURECHTBAUGENEHMIGUNG: return "Baurecht und Baugenehmigung";

        case FolderEnum.OBJEKTBESCHREIBUNG: return "Objektbeschreibung";

        case FolderEnum.GUTACHTEN: return "Gutachten";

        case FolderEnum.STATIK: return "Statik";

        case FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN: return "Weitere Bauprojektunterlagen";

      }

    }else if(lph == LphEnum.LPH6){

      switch (value) {

        case FolderEnum.LEISTUNGSVERZEICHNIS: return "Leistungsverzeichnis";

      }
    }

    else{

      return "Keine LPH ausgewählt";

    }

  }


}

