<div class="h-full w-full flex flex-col justify-start items-start gap-4">
  <div class="flex flex-row justify-start items-center gap-2">
    <div *ngIf="!fromProjectAccordion" class="text-xl font-medium">
      {{ isProject ? 'Projektmitglieder' : 'Organisationsmitglieder' }}
    </div>
    <button *ngIf="!fromProjectAccordion" mat-raised-button color="primary" (click)="addUser()" [disabled]="addDisabled">
      <fa-icon class="text-white text-xl" [icon]="faUserPlus"></fa-icon>
    </button>
  </div>

  <div class="w-full h-full bg-white" [ngClass]="{'shadow-full rounded-md': !fromProjectAccordion}">
    <div class="w-full h-full flex flex-col flex-nowrap content-between gap-4">

      <cdk-table #usersTbSort="matSort" [dataSource]="dataSource" matSort matSortActive="lastName" matSortDirection="asc"
                 multiTemplateDataRows matSortDisableClear>

        <!-- firstname column -->
        <ng-container cdkColumnDef="firstName">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[15%] h-full pl-7 py-4 font-medium"> Vorname</cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[15%] pl-7 py-1 break-words"> {{ element.firstName }}
          </cdk-cell>
        </ng-container>

        <!-- surname column -->
        <ng-container cdkColumnDef="lastName">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[15%] h-full py-4 font-medium">Nachname</cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[15%] py-1 break-words">{{ element.lastName }}</cdk-cell>
        </ng-container>

        <!-- email column -->
        <ng-container cdkColumnDef="mail">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[17%] h-full pr-4 py-4 font-medium">E-Mail</cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[17%] py-1 break-words">{{ element.mail }}</cdk-cell>
        </ng-container>

        <!-- status column -->
        <ng-container matColumnDef="status">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[13%] h-full py-4 font-medium"> Status</cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[13%] py-1 break-words">
            <div class="flex flex-row justify-start items-center pl-4">
              <ng-container *ngIf="user">
                <ng-container *ngIf="element.isActivated && element.id != user.id">
                  <app-circle-state [matTooltip]="'Erfolgreich hinzugefügt'" [state]="CircleState.GREEN" [useAsIcon]="true" [value]="100"
                                    class="w-6"></app-circle-state>
                </ng-container>
                <ng-container *ngIf="!element.isActivated && element.id != user.id">
                  <app-circle-state [matTooltip]="'Registrierung ausstehend'" [state]="CircleState.YELLOW" [useAsIcon]="true" [value]="100"
                                    class="w-6"></app-circle-state>
                </ng-container>
              </ng-container>
            </div>
          </cdk-cell>
        </ng-container>

        <!-- authorization column -->
        <ng-container matColumnDef="authorization">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[14%] h-full py-4 font-medium"
                           [ngClass]="{'w-[23%]': !isProject}">Berechtigung
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[14%] py-1 break-words cursor-default"
                    [ngClass]="{'w-[23%]': !isProject}">
            <div #authDropdownTrigger (click)="permissionToAddUser(element); $event.stopPropagation()"
                 (document:click)="onClickSomewhereToCloseMenues()"
                 class="flex flex-row justify-between items-center gap-2" [class]=" (permissionsDisabled || element.id == user.id) ? 'cursor-not-allowed' : 'cursor-pointer' ">

              <div class="flex flex-row w-[95%] break-all" [class]=" (permissionsDisabled || element.id == user.id) ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer' ">
                  <div class="overflow-hidden w-full line-clamp-1">
                      {{ (project ? element.roleInProjects[project.id].name   : element.roleInOrganisations[organisation.id!].name )| roleAndPrivileges }}
                  </div>
              </div>

              <div class="flex flex-row w-[5%]" [class]="(permissionsDisabled || element.id == user.id) ? 'cursor-not-allowed' : 'cursor-pointer' ">
                <fa-icon [class.text-gray-200]="permissionsDisabled || element.id == user.id" [icon]="faCaretDown" class="text-black" [class]=" (permissionsDisabled || element.id == user.id) ? 'cursor-not-allowed text-gray-400' : 'cursor-pointer' "></fa-icon>
              </div>

              <!-- authorizations dropdown -->
              <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="authDropdownTrigger"
                           [cdkConnectedOverlayOpen]="element === authToDropdownFrom">
                <div class="flex flex-col justify-between p-2 shadow-full rounded-md bg-white gap-4">

                  <!-- default roles -->
                  <div *ngFor="let role of roles">
                    <div class="flex flex-row justify-between items-center gap-8 pr-1">
                      <!-- TODO: auch role übergeben -->
                      <div #infoTrigger (mouseenter)="showRoleInfoFor = role" (mouseleave)="showRoleInfoFor = undefined">
                        <div (click)="updateUser(element, role)"
                             class="w-full rounded-md p-1 hover:bg-gray-200 hover:cursor-pointer">{{ role.name | roleAndPrivileges }}
                        </div>
                      </div>

                      <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="infoTrigger" [cdkConnectedOverlayOpen]="role === showRoleInfoFor">
                        <!-- roles[2] is currently admin, roles[1] is currently editor, roles[0] is currently viewer -->
                        <div *ngIf="role === roles[2]" class="bg-gray-300 rounded-[5px] p-[10px] shadow-full break-words w-full">
                          <div [innerHTML]="'Dateien hochladen, bearbeiten oder<br>löschen sowie Ausführung von Prüfungen'"></div>
                        </div>
                        <div *ngIf="role === roles[1]" class="bg-gray-300 rounded-[5px] p-[10px] shadow-full break-words w-full">
                          <div [innerHTML]="'Lesezugriff auf Projektdaten und Dateien<br>sowie Prüfberichte und Modelle'"></div>
                        </div>
                        <div *ngIf="role === roles[0]" class="bg-gray-300 rounded-[5px] p-[10px] shadow-full break-words w-full">
                          <div [innerHTML]="'Kaufrechte sowie Verwaltung von<br>Projektdaten, Mitgliedern und Berechtigungen'"></div>
                        </div>
                      </ng-template>


                      <fa-icon (click)="clickedInDropdown = true; openedSubMenu()" [icon]="faLock"
                               class="text-gray-300 hover:cursor-not-allowed"></fa-icon>
                    </div>
                  </div>

                  <!-- TODO: custom roles (currently just hardcoded, should be ngFor, or should be merged with ngFor above -->
                  <div *ngFor="let role of customRoles">
                    <div class="flex flex-row justify-between items-center gap-8">
                      <!-- TODO: auch rolle übergeben und Rolle_test123 mit Variable für Rollennamen ersetzen -->
                      <div class="w-full rounded-md p-1 hover:bg-gray-200 hover:cursor-pointer" (click)="updateUser(element, role)">
                        {{role.name}}
                      </div>
                      <div #customAuthEditTrigger="cdkOverlayOrigin" cdkOverlayOrigin
                           (click)="clickedInDropdown = true; openedSubMenu(); selectedCustomRole = role; toggleOrigin(customAuthEditTrigger, role)"
                           class="flex flex-row justify-center w-7 hover:cursor-pointer">
                        <fa-icon [icon]="faEllipsisVertical" class="text-primary"></fa-icon>
                      </div>
                    </div>
                  </div>

                  <!-- authorization custom roles edit menu -->
                  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="triggerOrigin"
                               [cdkConnectedOverlayOpen]="selectedCustomRole">
                    <div class="flex flex-col justify-between p-2 shadow-full rounded-md bg-white gap-2">
                      <div class="p-1 rounded-md hover:bg-gray-200 hover:cursor-pointer">
                        <!-- TODO: statt currentCustomRoleName, gleiche Rollennamen Variable wie oben -->
                        <div (click)="editCustomRole(selectedCustomRole, isProject)"
                             class="flex flex-row justify-between items-center gap-8">
                          <div>Bearbeiten</div>
                          <fa-icon [icon]="faPenToSquare" class="text-black"></fa-icon>
                        </div>
                      </div>
                      <!-- TODO: deleteCustomRole is still a dummy, should be given the role from the ngFor that should be at the start of the custom roles -->
                      <div (click)="deleteCustomRole(selectedCustomRole)" class="p-1 rounded-md hover:bg-gray-200 hover:cursor-pointer">
                        <div class="flex flex-row justify-between items-center gap-8">
                          <div class="text-error">Löschen</div>
                          <fa-icon [icon]="faTrashCan" class="text-error"></fa-icon>
                        </div>
                      </div>
                    </div>
                  </ng-template>



                  <!-- button to create a new custom role -->
                  <div (click)="createCustomRole(element)"
                       class="flex flex-row justify-between items-center gap-8 font-medium rounded-md p-1 hover:bg-gray-200 hover:cursor-pointer">
                    Benutzerdefiniert
                    <fa-icon [icon]="faPlus" class="text-primary hover:cursor-pointer"></fa-icon>
                  </div>

                </div>
              </ng-template>

            </div>
          </cdk-cell>
        </ng-container>


        <!-- role column -->
        <ng-container matColumnDef="role">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[14%] h-full pl-5 py-4 font-medium"
                           [ngClass]="{'w-[23%]': !isProject}">Rolle
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[14%] py-1 break-words"
                    [ngClass]="{'w-[23%]': !isProject}">
            <mat-select *ngIf="isProject" [disabled]="user == undefined || element.id == user.id || permissionsDisabled"
                        [(value)]="element.roleInProjects[project.id!]" (selectionChange)="updateUser(element)">
              <mat-option *ngFor="let role of roles | keyvalue" [value]="role.value">
                {{ role.value | roleAndPrivileges }}
              </mat-option>
              <mat-select-trigger>
                <div class="flex flex-row gap-3 justify-start">
                  <fa-icon [icon]="faCaretDown" class="text-black"></fa-icon>
                  {{ element.roleInProjects[project.id!].name }}
                </div>
              </mat-select-trigger>
            </mat-select>
            <mat-select *ngIf="!isProject" [disabled]="user == undefined || element.id == user.id || permissionsDisabled"
                        [(value)]="element.roleInOrganisations[organisation.id!]" (selectionChange)="updateUser(element)">
              <mat-option *ngFor="let role of roles | keyvalue" [value]="role.value">
                {{ role.value | roleAndPrivileges }}
              </mat-option>
              <mat-select-trigger>
                <div class="flex flex-row gap-3 justify-start">
                  <fa-icon [icon]="faCaretDown" class="text-black"></fa-icon>
                  {{ element.roleInOrganisations[organisation.id!].name }}
                </div>
              </mat-select-trigger>
            </mat-select>
          </cdk-cell>
        </ng-container>

        <!-- organisation column -->
        <ng-container cdkColumnDef="organisation">
          <cdk-header-cell *cdkHeaderCellDef class="shrink-0 w-[18%] h-full pl-6 py-4 font-medium">Organisation
          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="shrink-0 w-[18%] pl-6 py-1 break-words">Building Information-Dummy
          </cdk-cell>
        </ng-container>

        <!-- actions column -->
        <ng-container cdkColumnDef="actions">
          <cdk-header-cell *cdkHeaderCellDef class="flex flex-row grow h-full justify-end py-4 font-medium pr-4">

          </cdk-header-cell>
          <cdk-cell *cdkCellDef="let element" class="flex flex-row grow justify-end items-center py-1 pr-4">
            <button [matTooltip]="isProject ? 'Projektmitglied entfernen' : 'Orgnsationsmitglied entfernen'" (click)="removeUser(element);" [disabled]="user.id == element.id || permissionsDisabled">
              <div class="flex flex-row w-full"  [class]=" permissionsDisabled ? 'cursor-not-allowed' : ' cursor-pointer'">
                <fa-icon  [icon]="faTrashCan"
                        [class.text-gray-200]="user.id == element.id || permissionsDisabled"
                        [class.text-error]="user.id != element.id && !permissionsDisabled"
                       class="w-5 text-xl text-error"
             ></fa-icon>
              </div>
            </button>

          </cdk-cell>
        </ng-container>

        <!-- table definition -->
        <ng-container *ngIf="isProject">
          <cdk-header-row *cdkHeaderRowDef="displayedColumnsProject"
                          class="flex flex-row justify-left items-center w-full border-b-2 border-b-gray-400 bg-white rounded-t-md overflow-hidden"></cdk-header-row>
          <cdk-row *cdkRowDef="let row; columns: displayedColumnsProject;"
                   class="flex flex-row justify-left items-center w-full py-1 border-t-2 border-b-gray-400"></cdk-row>
        </ng-container>
        <ng-container *ngIf="!isProject">
          <cdk-header-row *cdkHeaderRowDef="displayedColumnsOrganisation"
                          class="flex flex-row justify-left items-center w-full border-b-2 border-b-gray-400 bg-white rounded-t-md overflow-hidden"></cdk-header-row>
          <cdk-row *cdkRowDef="let row; columns: displayedColumnsOrganisation;"
                   class="flex flex-row justify-left items-center w-full py-1 border-t-2 border-b-gray-400"></cdk-row>
        </ng-container>
        <!--    <ng-container *ngIf="!isProject">-->
        <!--      <cdk-header-row *matHeaderRowDef="displayedColumnsOrganisation" mat-header-row></cdk-header-row>-->
        <!--      <cdk-row *matRowDef="let row; columns: displayedColumnsOrganisation;" mat-row></cdk-row>-->
        <!--    </ng-container>-->

      </cdk-table>

      <div *ngIf= "fromProjectAccordion" class="flex flex-row justify-end">
        <bic-button [tooltip]="addDisabled ? 'Keine Berechtigung' : isProject ? 'Projektmitglied hinzufügen' : 'Organisationsmitglied hinzufügen'" [text]="'Hinzufügen'" [iconLeft]="faUserPlus" [color]="'primary'" [border]="true" (click)="addUser()" [disabled]="addDisabled || !canAddUser"></bic-button>

<!--        <button [matTooltip]="addDisabled ? 'Keine Berechtigung' : isProject ? 'Projektmitglied hinzufügen' : 'Organisationsmitglied hinzufügen'" mat-raised-button color="bg-white" (click)="addUser()" [disabled]="addDisabled || !canAddUser" class="p-2">-->
<!--          <div class="flex flex-row justify-between items-center gap-2">-->
<!--            <fa-icon class="text-primary" [icon]="faUserPlus"></fa-icon>-->
<!--            <div class="font-medium">Hinzufügen</div>-->
<!--          </div>-->
<!--        </button>-->
      </div>
    </div>
  </div>

</div>
