<div class="flex flex-col z-40 h-full bg-[#FFFFFF] shadow rounded-lg">
  <div class="flex flex-col p-6 gap-4">
    <div class="text-base font-semibold text-center ">Möchten Sie die Prüfung wirklich ausführen?</div>
    <div class="py-2 px-4 text-sm text-center">
      Bei Bestätigung wird die Datei <span class="underline">{{data.ifcFile.name}}</span> geprüft.
    </div>
    <div class="py-2 px-4">
      <div class="text-center font-medium text-sm underline">Hinweis:</div>
      <div class="text-center text-sm text-normal">Das Ausführen der Prüfung verbraucht <span class="font-medium">1 Guthaben</span> für
        das Modul LOI-Check
      </div>
    </div>
    <div class="flex flex-row justify-between">
      <mat-dialog-actions class="flex justify-between w-full gap-2">
        <div class="flex-grow"></div>
        <bic-button mat-dialog-close [text]="'Abbrechen'" [color]="'#FFF'" [textColor]="'gray-900'" [border]="true" [opacityHover]="false"></bic-button>
        <bic-button [text]="'Prüfen'" [color]="'primary'" [mat-dialog-close]="true"></bic-button>
      </mat-dialog-actions>
    </div>
  </div>

</div>


