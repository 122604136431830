/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Coloring } from './coloring';
import { Selection } from './selection';
import { ViewSetupHints } from './viewSetupHints';
import { Visibility } from './visibility';

export interface Components { 
    id?: string;
    viewSetupHints?: ViewSetupHints;
    visibility?: Visibility;
    selection?: Selection;
    coloring?: Coloring;
}