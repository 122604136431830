import {
  CheckControllerService,
  CheckResults,
  CompactPartialServiceEvaluationResult,
  CompactServiceAspectEvaluationResult,
  CompletenessCheck,
  ProjectFile,
  ProjectServiceNotification,
  Task
} from "../../../api";
import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatSort} from "@angular/material/sort";
import {DrawerContentService} from "../../../services/drawer-content.service";
import {StateConfigService} from "../../../services/state/state-config.service";
import {TaskDetailsService} from "../../../services/task-details.service";
import {AspectPipe, DescriptionPipe, PhrasesPipe, ServicePipe} from "../../../pipes/service.pipe";
import {PdfService} from "../../../services/pdf/pdf.service";
import {PdfViewerComponent} from "../../util/files/components/pdf-viewer/pdf-viewer.component";
import {
  faBars,
  faChevronDown,
  faChevronUp,
  faEllipsisVertical,
  faFile,
  faFileCircleXmark,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import {CircleState} from "src/app/components/util/design/circle-state/circle-state.component";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import PartialServiceNameEnum = ProjectServiceNotification.PartialServiceNameEnum;
import LphEnum = Task.LphEnum;

interface CheckRule {
  name: string,
  service: PartialServiceNameEnum,
  state: 'success' | 'error' | 'warning',
  documents: CheckDocument[],
  result: CheckResults | undefined
}

interface CheckDocument {
  name: string,
  service: PartialServiceNameEnum,
  state: 'success' | 'error' | 'warning',
  projectFile: ProjectFile,
  result: CheckResults | undefined
  aspects: CheckAspects[]
}

interface CheckAspects {
  name: string;
  aspect: string;
  description?: string;
  phrases?: string;
  state: 'success' | 'error' | 'warning';
}

@Component({
  selector: 'app-hoaiCheckDetail',
  templateUrl: './hoai-check-detail.component.html',
  styleUrls: ['./hoai-check-detail.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class hoaiCheckDetailComponent implements OnInit {

  @Input() check!: CompletenessCheck;


  displayedColumnsNamesBL = ['Besondere Leistung', 'Prüfstatus']
  displayedColumnsNamesGL = ['Grundleistung', 'Prüfstatus']
  displayedColumns = ['name', 'state']
  columnsTypes = ['text', 'status']
  columnsWidths = ['90%', '10%']
  columnsAlignment = ['start', 'center']

  displayedColumnsNamesDocuments = ['Name', 'Prüfstatus']
  displayedColumnsDocuments = ['name', 'state']
  columnsTypesDocuments = ['text', 'status']
  columnsWidthsDocuments = ['90%', '10%']
  columnsAlignmentDocuments = ['start', 'center']

  displayedColumnsNamesAspects = ['Name', 'Prüfstatus']
  displayedColumnsAspects = ['name', 'state']
  columnsTypesAspects = ['text', 'status']
  columnsWidthsAspects = ['90%', '10%']
  columnsAlignmentAspects = ['start', 'center']


  expandedElement!: CheckRule | undefined;
  isDocView: boolean = false;
  isDocDetailView: boolean = false;
  docViewOf: string = '';
  selectedService: CheckRule | undefined;
  selectedDocumentFile!: ProjectFile;
  isDetail: boolean = false;
  selectedDocument: CheckDocument | undefined;

  services: CheckRule[] = [];
  servicesGL: CheckRule[] = [];
  servicesBL: CheckRule[] = [];
  uncheckedServices: CheckRule[] = [];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('servicesTbSort') servicesTbSort = new MatSort();


  constructor(
    private checkControllerService: CheckControllerService,
    public drawerService: DrawerContentService,
    public stateConfigService: StateConfigService,
    private taskDetailService: TaskDetailsService,
    private servicePipe: ServicePipe,
    private aspectPipe: AspectPipe,
    private aspectDescriptionPipe: DescriptionPipe,
    private aspectPhrasesPipe: PhrasesPipe,
    public pdfService: PdfService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  get total(): number {
    return this.totalCompleted() + this.totalWarning() + this.totalError();
  }

  get completed(): number {
    return Math.round(this.totalCompleted() / this.total * 100);
  }

  get warning(): number {
    return Math.round(this.totalWarning() / this.total * 100);
  }

  get error(): number {
    return Math.round(this.totalError() / this.total * 100);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.checkId !== undefined) {
        this.check = {id: params.checkId}
      }
      this.checkControllerService.getCheck3(this.stateConfigService.getProjectId(), this.check.id!).subscribe((check) => {
        this.check = check;
        this.services = this.createServicesData(this.check.checkResults);

        if (this.check.checkResults?.length !== 0) {
        }
        this.addUncheckedServices(check.partialServiceNames ?? []);


        this.services.sort((a, b) => (a.service > b.service) ? 1 : -1);

        this.servicesGL = this.services.filter(service => service.service.includes('GL'));
        this.servicesBL = this.services.filter(service => service.service.includes('BL'));


        this.createPdf();
      });
    });
  }

  createPdf() {
    this.pdfService.getReportBlob(this.stateConfigService.selectedProject, this.stateConfigService.selectedOrganisation, this.check.lph!, this.check).then((blob: any) => {
    });
  }

  downloadPdf() {
    const evaluations: Map<LphEnum, CompletenessCheck> = new Map<LphEnum, CompletenessCheck>();
    evaluations.set(this.check.lph!, this.check);

    this.pdfService.downloadReport(this.stateConfigService.selectedProject, this.stateConfigService.selectedOrganisation, this.check.lph!, this.check);
  }

  setFirstDocumentAsSelected() {
    // @ts-ignore
    this.selectedDocument = this.expandedElement?.result?.checkResults[0].s3KeyOfAnalyzedDocument ?? '';
  }

  createServicesData(checkResults?: CheckResults[]): CheckRule[] {
    return checkResults?.map(({partialServiceName, ...rest}) => ({
      service: partialServiceName,
      name: this.servicePipe.transform(partialServiceName),
      state: rest.succeed ? 'success' : 'error',
      result: rest,
      documents: this.createDocumentsData(rest)
    })) as CheckRule[] ?? [];
  }

  createDocumentsData(result: CheckResults): CheckDocument[] {
    return result.checkResults?.map(({s3KeyOfAnalyzedDocument, ...rest}) => ({
      name: this.projectFileForDocument(result, s3KeyOfAnalyzedDocument!).name,
      service: result.partialServiceName,
      state: rest.succeed ? 'success' : 'error',
      projectFile: this.projectFileForDocument(result, s3KeyOfAnalyzedDocument!),
      result: rest,
      aspects: this.createAspectsData(result, s3KeyOfAnalyzedDocument!)
    })) as CheckDocument[] ?? [];
  }

  createAspectsData(result: CheckResults, doc: string): CheckAspects[] {
    const aspects = result.checkResults?.filter((result) => result.s3KeyOfAnalyzedDocument === doc)[0].serviceAspectEvaluationResults;
    const checkAspects: CheckAspects[] = [];

    aspects?.forEach(aspect => {
      checkAspects.push({
        name: this.aspectPipe.transform(aspect.serviceAspectName!),
        aspect: aspect.serviceAspectName!,
        description: this.aspectDescriptionPipe.transform(aspect.serviceAspectName!),
        phrases: this.aspectPhrasesPipe.transform(aspect.serviceAspectName!),
        state: aspect.succeed ? 'success' : 'error'
      });
    });

    return checkAspects;
  }

  addUncheckedServices(partialServiceNames?: PartialServiceNameEnum[]): void {
    let data = this.services;

    partialServiceNames?.forEach(service => {
      if (!this.services.some(s => s.service === service)) {
        let check = {
          service: service,
          name: this.servicePipe.transform(service),
          documents: [],
          state: 'error',
          result: undefined
        }
        // @ts-ignore
        data.push(check);
        // @ts-ignore
        this.uncheckedServices.push(check);
      }
    });

    this.services = data;
  }

  totalCompleted(): number {
    if(!this.isDocView) {
      return this.check.checkResults?.reduce((acc, cur) => cur.succeed ? acc + 1 : acc, 0) ?? 0;
    } else if(this.isDocView && !this.isDocDetailView){
      let completed = this.selectedService?.documents.filter(document => document.state=='success').length
      return completed ? completed : 0
    } else if(this.isDocDetailView){
      let completed = this.selectedDocument?.aspects.filter(aspect => aspect.state=='success').length
      return completed ? completed : 0
    }
    return 0
  }

  totalWarning(): number {
    if(!this.isDocView) {
      return 0
    } else if(this.isDocView && !this.isDocDetailView){
      let warning =  this.selectedService?.documents.filter(document => document.state=='warning').length
      return warning ? warning : 0
    } else if(this.isDocDetailView){
      let warning = this.selectedDocument?.aspects.filter(aspect => aspect.state=='warning').length
      return warning ? warning : 0
    }
    return 0
  }

  totalError(): number {
    if(!this.isDocView) {
      return this.uncheckedServices.length + (this.check.checkResults?.reduce((acc, cur) => !cur.succeed ? acc + 1 : acc, 0) ?? 0);
    } else if(this.isDocView && !this.isDocDetailView){
      let error = this.selectedService?.documents.filter(document => document.state=='error').length
      return error ? error : 0
    } else if(this.isDocDetailView){
      let error = this.selectedDocument?.aspects.filter(aspect => aspect.state=='error').length
      return error ? error : 0
    }
    return 0
  }


  /*totalCompleted(): number {
    return this.check.checkResults?.reduce((acc, cur) => cur.succeed ? acc + 1 : acc, 0) ?? 0;
  }

  totalWarning(): number {
    return 0
  }

  totalError(): number {
    return this.uncheckedServices.length + (this.check.checkResults?.reduce((acc, cur) => !cur.succeed ? acc + 1 : acc, 0) ?? 0);
  }*/

  sortCheckResults() {
    // @ts-ignore
    return this.expandedElement.result.checkResults.sort((a, b) => (a.succeed === b.succeed) ? 0 : a.succeed ? -1 : 1);
  }

  openPDF() {
    this.drawerService.close();
    const projectFile = this.selectedDocument?.projectFile;
    this.drawerService.open(PdfViewerComponent, {
      large: false,
      title: projectFile!.name ?? '',
      data: {
        link: projectFile!.link,
      }
    })

  }

  createTask(partialService: string) {
    let task = {
      markup: {
        topic: {
          title: 'Überarbeiten der HOAI-Prüfung: ' + this.servicePipe.transform(partialService),
        }
      },
      lph: this.check.lph,
    }

    this.taskDetailService.setOpenedTask(task as Task);
  }

  currentDocumentCheck(succeed: boolean): CompactPartialServiceEvaluationResult[] | undefined {
    return this.expandedElement?.result?.checkResults?.filter((result) => result.s3KeyOfAnalyzedDocument === this.selectedDocument);
  }

  getExpandedResult() {
    let result = this.expandedElement?.result?.checkResults?.filter((result) => result.s3KeyOfAnalyzedDocument === this.selectedDocument)
    if (result!.length > 0) {
      return result![0];
    } else {
      return [];
    }
  }

  currentDocumentServiceAspectCheck(succeed: boolean) {
    // @ts-ignore
    return this.getExpandedResult()?.serviceAspectEvaluationResults.filter((serviceAspect) => serviceAspect.succeed == succeed);

  }

  projectFileForDocument(result: CheckResults, document: string): ProjectFile {
    const id = document.split('/')[2].split('.')[0];

    return result?.succeedDocuments?.find((doc) => doc.id === id)!;
  }

  navigateToRuleView(rule: CheckRule) {
    this.selectedService = rule;
    this.isDocView = true;
  }

  navigateBackToRules() {
    this.isDocView = false;
    this.isDocDetailView = false;
    this.selectedService = undefined;
  }

  navigateToDocView(doc: CheckDocument) {
    this.selectedDocument = doc
    this.isDocView = true;
    this.isDocDetailView = true;
    this.selectedDocumentFile = doc.projectFile
  }

  navigateBackToDocView() {
    this.isDocDetailView = false;
    this.isDocView = true;
    this.selectedDocument = undefined;
    this.selectedDocumentFile = {}
  }

  protected readonly faBars = faBars;
  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronDown = faChevronDown;
  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly CircleState = CircleState;
  protected readonly faFile = faFile;
  protected readonly faFileCircleXmark = faFileCircleXmark;
  protected readonly faClipboardList = faClipboardList;

}
