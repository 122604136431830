import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {SelectionModel} from "@angular/cdk/collections";
import {Router} from "@angular/router";
import {
  faBars,
  faBuilding,
  faEllipsisVertical,
  faGrip,
  faListCheck,
  faPlus,
  faUser,
  faUserGroup
} from "@fortawesome/free-solid-svg-icons";
import {faFile, faFolderOpen} from "@fortawesome/free-regular-svg-icons";
import {CompletenessCheck, Project, ProjectControllerService} from "../../api";
import {ToolbarService} from "../util/design/toolbar/toolbar.service";
import {StateConfigService} from "../../services/state/state-config.service";
import {PermissionService} from "../../services/permission/permission.service";
import {DeleteDialogComponent} from "../util/deleteDialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {faGrid2} from "@fortawesome/pro-solid-svg-icons";

/**
 * Lists all Projects
 */
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {


  displayedColumns: string[] = ['select', 'name', 'image', 'id', 'projectManager', 'projectOwner', 'lastCheck', 'actions'];

  dataSource!: MatTableDataSource<Project>;
  dataSourceWithObjectColumn = new MatTableDataSource<Project>;
  selection = new SelectionModel<Project>(true, []);

  projectEvaluationMap: Map<Project, CompletenessCheck> = new Map<Project, CompletenessCheck>();

  isCardView: boolean = true;
  isListView: boolean = false;

  leaveProjectBoolean: boolean = false;
  deleteProjectBoolean: boolean = false;
  deleteAndLeaveProjectBoolean: boolean = false;

  selectedProject!: Project;

  adminMap: Map<Project, boolean> = new Map<Project, boolean>();
  otherAdmins: Map<number, boolean> = new Map<number, boolean>();


  setCardView(): void {
    this.isCardView = true;
    this.isListView = false;
  }

  setListView(): void {
    this.isCardView = false;
    this.isListView = true;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild('projectsTbSort') projectsTbSort = new MatSort();

  /**
   * All Project of User
   */
  projects!: Project[];
  projects_length: number = 0;
  //TODO: SEARCH FOR BETTER SOLUTION
  default_page_size: number = 1000;

  /**
   * Constructor
   * @param projectControllerService ProjectControllerService
   * @param dialog
   * @param router
   * @param toolbar
   * @param stateService
   * @param permissionService
   */
  constructor(private projectControllerService: ProjectControllerService,
              private dialog: MatDialog,
              private router: Router,
              private toolbar: ToolbarService,
              private stateService: StateConfigService,
              public permissionService: PermissionService) {

    // toolbar.config = {
    //   add: {
    //     disabled: () => false,
    //     routerLink: ['/projects/create']
    //   },
    //   delete: {
    //     disabled: () => {
    //       if (this.selection.isEmpty()){
    //         return true;
    //       }else {
    //         let projects = this.selection.selected;
    //         for (let project of projects){
    //           if(!this.permissionService.hasProjectPermission(project.id!, "ROLE_PROJECT_ADMIN")){
    //             return  true;
    //             }
    //           }
    //         return false;
    //       }
    //     },
    //     action: () => this.deleteProjects()
    //   }
    // }
  }


  /**
   * Initialization of Component
   */

  ngOnInit(): void {
    this.stateService.organisationChange.subscribe(() => {
      this.loadProjects()
    });

    this.loadProjects();
  }


  loadProjects() {
    if(this.stateService.getOrganisationId()){
      this.projectControllerService.getProjectsByOrganisationId(this.stateService.getOrganisationId() ?? 0).subscribe(projects => {
        this.projects = projects;
        let datasource_temp = projects.slice(0,this.default_page_size);
        // datasource_temp.forEach(project => {
        //   if(this.permissionService.hasProjectPermission(project.id!, "ROLE_PROJECT_CHECKER")){
            // this.projectControllerService.getLatestEvaluationForProject(project.id!).subscribe(evaluation => {
            //   this.projectEvaluationMap.set(project, evaluation);
            // });
          // }
        // });
        this.dataSource = new MatTableDataSource(datasource_temp);
        this.projects.forEach(project => {
          this.stateService.projects.get(project.id ?? 0)?.privileges?.length == 11 ? this.adminMap.set(project, true) : this.adminMap.set(project, false);
        })
        this.projectControllerService.otherAdmins(this.getAdminProjectIds()).subscribe(admins => {
          this.otherAdmins = this.convertToMap(admins);
        });

      })
    }else{
      this.projectControllerService.getPersonalProjects().subscribe(projects => {
        this.projects = projects;
        let datasource_temp = projects.slice(0,this.default_page_size);
        // datasource_temp.forEach(project => {
        //   if(this.permissionService.hasProjectPermission(project.id!, "ROLE_PROJECT_CHECKER")){
            // this.projectControllerService.getLatestEvaluationForProject(project.id!).subscribe(evaluation => {
            //   this.projectEvaluationMap.set(project, evaluation);
            // });
          // }
        // });
        this.dataSource = new MatTableDataSource(datasource_temp);
        this.projects.forEach(project => {
          this.stateService.projects.get(project.id ?? 0)?.privileges?.length == 11 ? this.adminMap.set(project, true) : this.adminMap.set(project, false);
        })

        this.projectControllerService.otherAdmins(this.getAdminProjectIds()).subscribe(admins => {
          this.otherAdmins = this.convertToMap(admins);
        });
      })
    }
  }

  // ngAfterViewInit() {
  //   if(this.stateService.getOrganisationId()){
  //     console.log("get projects by organisation id");
  //     this.projectControllerService.getProjectsByOrganisationId(this.stateService.getOrganisationId() ?? 0).subscribe(projects => {
  //       let datasource_temp = projects.slice(0,this.default_page_size);
  //       datasource_temp.forEach(project => {
  //         if(this.permissionService.hasProjectPermission(project.id!, "ROLE_PROJECT_CHECKER")){
  //           this.projectControllerService.getLatestEvaluationForProject(project.id!).subscribe(evaluation => {
  //             this.projectEvaluationMap.set(project, evaluation);
  //           });
  //         }
  //       });
  //       this.dataSource = new MatTableDataSource(datasource_temp);
  //     })
  //   }else{
  //     this.projectControllerService.getPersonalProjects().subscribe(projects => {
  //       let datasource_temp = projects.slice(0,this.default_page_size);
  //       datasource_temp.forEach(project => {
  //         if(this.permissionService.hasProjectPermission(project.id!, "ROLE_PROJECT_CHECKER")){
  //           this.projectControllerService.getLatestEvaluationForProject(project.id!).subscribe(evaluation => {
  //             this.projectEvaluationMap.set(project, evaluation);
  //           });
  //         }
  //       });
  //       this.dataSource = new MatTableDataSource(datasource_temp);
  //     })
  //   }
  // }

  deleteProjects() {
    let deleteDialog = this.dialog.open(DeleteDialogComponent, {
      panelClass:"rounded-corners-dialog",
      data: {
        projects: this.selection.selected
      }
    }).afterClosed().subscribe(() => {
      if(this.stateService.getOrganisationId()){
        this.projectControllerService.getProjectsByOrganisationId(this.stateService.getOrganisationId() ?? 0).subscribe(projects => {
          this.dataSource = new MatTableDataSource(projects);
          this.selection.clear()
        });
      }else {
        this.projectControllerService.getPersonalProjects().subscribe(projects => {
          this.dataSource = new MatTableDataSource(projects);
          this.selection.clear()
        });
      }
    })
  }

  leaveProject(project: Project) {
    this.projectControllerService.leaveProject(project.id!).subscribe(() => {
      this.loadProjects();
    })

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: Project): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id!}`;
  }

  navigateToProject(row: Project) {
    this.stateService.selectProject(row.id!);

  }

  navigateToProjectSettings(project: Project) {
    this.router.navigate(['/projects', project.id, 'settings']);
  }

  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }

  calculateSizeInGB(size: number): number {
    return size / 1024 / 1024 / 1024;
  }

  selectProject(row: Project) {
    this.selection.toggle(row);
  }

  openLeaveProject(project: Project){
    this.leaveProjectBoolean = true;
    this.selectedProject = project;
  }

  openDeleteProject(project: Project){
    this.deleteProjectBoolean = true;
    this.selectedProject = project;
  }

  openDeleteAndLeaveProject(project: Project){
    this.deleteAndLeaveProjectBoolean = true;
    this.selectedProject = project;
  }

  deleteProject(project: Project){
    this.projectControllerService.deleteProject(project.id!).subscribe(() => {
      this.loadProjects();
    });
    this.deleteProjectBoolean = false;
    this.deleteAndLeaveProjectBoolean = false;
  }

  getIsAdmin(project: Project){
    return this.adminMap.get(project);
  }

  getAdminProjectIds(): number[] {
    const adminProjectIds: number[] = [];
    this.adminMap.forEach((isAdmin, project) => {
      if (isAdmin) {
        if (project.id !== undefined) {
          adminProjectIds.push(project.id);
        }
      }
    });
    return adminProjectIds;
  }

  convertToMap(obj: { [key: string]: boolean }): Map<number, boolean> {
    const map = new Map<number, boolean>();
    for (const [key, value] of Object.entries(obj)) {
      const numericKey = parseInt(key, 10);
      if (!isNaN(numericKey)) {
        map.set(numericKey, value);
      }
    }
    return map;
  }




  protected readonly faBars = faBars;
  protected readonly faGrip = faGrip;
  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faPlus = faPlus;
  protected readonly faFile = faFile;
  protected readonly faUser = faUser;
  protected readonly faListCheck = faListCheck;
  protected readonly faUserGroup = faUserGroup;
  protected readonly faBuilding = faBuilding;
    protected readonly faFolderOpen = faFolderOpen;
    protected readonly faGrid2 = faGrid2;
}
