import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import {ApiModule as ChatTokenApiModule} from "../../chatBIC-api";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {DesignModule} from "../util/design/design.module";
import { ChatViewComponent } from './chat-view/chat-view.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { ChatTopNavComponent } from './chat-top-nav/chat-top-nav.component';
import { AllChatsViewComponent } from './all-chats-view/all-chats-view.component';
import { AllFilesViewComponent } from './all-files-view/all-files-view.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { MessageViewComponent } from './message-view/message-view.component';
import {BicUiComponentsModule} from "@buildinginformationcloud/bic-ui-components";



@NgModule({
  declarations: [
    ChatComponent,
    ChatViewComponent,
    ChatTopNavComponent,
    AllChatsViewComponent,
    AllFilesViewComponent,
    MessageViewComponent
  ],
  imports: [
    CommonModule,
    ChatTokenApiModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    DesignModule,
    FontAwesomeModule,
    ClipboardModule,
    BicUiComponentsModule,
  ]
})
export class ChatModule { }
