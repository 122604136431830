import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {ErrorService} from "../../services/error/error.service";
import {NotificationsService} from "../../services/notifications/notifications.service";
import {MatSnackBar} from "@angular/material/snack-bar";

/**
 * Interceptor for HTTP Errors
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  /**
   * Constructor
   * @param snackbar MatSnackBar
   * @param errorService ErrorService
   * @param notificationService
   */
  constructor(private snackbar: MatSnackBar, private errorService: ErrorService, private notificationService: NotificationsService) {
  }

  /**
   * Intercept on any HTTP Error code
   * @param request an HTTP Request
   * @param next an HTTP Handler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = `Error Code: ${error.status}, Message: ${error.message}`;
          }

          this.errorService.triggerError(error);
          return throwError(() => errorMsg);
        })
      )
  }
}
