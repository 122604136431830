<div class="p-6 overflow-auto bg-white rounded-2xl shadow-item">
  <div class="flex flex-col justify-start items-center gap-4">

    <!-- Headline warning -->
    <div class="text-error font-medium">Verlassen ohne Speichern?</div>
    <!-- Explanation text -->
    <div>Wollen Sie die Seite wirklich verlassen, ohne die Änderungen zu speichern?</div>
    <div> Wählen Sie Verlassen um fortzufahren oder Abbrechen, um auf der aktuellen Seite zu bleiben</div>

    <!-- Buttons -->
    <div class="flex flex-row pt-4 w-full justify-between items-center">
      <button (click)="cancelClicked()" class="w-[20%] font-medium" mat-dialog-close mat-raised-button>
        Abbrechen
      </button>

      <button (click)="checkClicked()" class="w-[20%] font-medium" color="warn" mat-raised-button>
        Verlassen
      </button>
    </div>

  </div>
</div>

