<div class="grid grid-cols-4 break-all w-full h-full p-4 gap-4 grid-rows-3" *ngIf="data">
  <! -- top row -->
  <app-dashboard-card class="border border-gray-200 w-full h-full rounded-lg p-4 row-span-1" [tooltipText]="'Übersicht und Verwaltung aller Projektdaten und Beteiligten.'">
    <div card-icon><fa-icon class="text-base" (click)="navigateToProjectSettings()" [icon]="faBuilding"></fa-icon></div>
    <div card-title>Projektinformationen</div>
    <div card-content class="h-full"><app-dashboard-project-information [project]="project"></app-dashboard-project-information></div>
  </app-dashboard-card>

  <app-dashboard-card class="border border-gray-200 w-full col-span-3 rounded-lg p-4 row-span-1" [tooltipText]="'Planung und Verwaltung von Aufgaben/BCF.'">
    <div card-icon><fa-icon class="text-base" (click)="navigateToTaskBoard()" [icon]="faClipboardCheck"></fa-icon></div>
    <div card-title>Tasks</div>
    <div card-content class="flex w-full h-full"><app-dashboard-tasks class="flex w-full h-full" [basicTasks]="basicTasks"></app-dashboard-tasks></div>
  </app-dashboard-card>

  <! -- bottom row -->

  <app-dashboard-card class="border border-gray-200 w-full rounded-lg p-4 row-span-2" [class.col-span-2]="!isBim" [tooltipText]="'Vollständigkeitsprüfung von Planungsunterlagen nach HOAI.'">
    <div card-icon (click)="navigateToHOAICheck()"><img ngSrc="../../../assets/pictos/hoai-check.svg" alt="" height="16" width="16"></div>
    <div card-title>HOAI-Check</div>
    <div card-content class="h-full"><app-dashboard-checks [checks]="hoaiChecks"></app-dashboard-checks></div>
  </app-dashboard-card>

  <app-dashboard-card *ngIf="isBim" class="border border-gray-200 w-full rounded-lg p-4 row-span-2" [tooltipText]="'Überprüfen des IFC-Modells auf formelle Modellierungskriterien.'">
    <div card-icon><fa-icon class="text-base" (click)="navigateToBIMBasisCheck()" [icon]="faCube"></fa-icon></div>
    <div card-title>BIM-Basis-Check</div>
    <div card-content class="h-full"><app-dashboard-checks [checks]="bimBaseChecks"></app-dashboard-checks></div>
  </app-dashboard-card>

  <app-dashboard-card *ngIf="isBim" class="border border-gray-200 w-full rounded-lg p-4 row-span-2" [tooltipText]="'Überprüfung des Informationsgehalts von IFC-Modellen.'">
    <div card-icon><fa-icon class="text-base" (click)="navigateToLOICheck()" [icon]="faInfoCircle"></fa-icon></div>
    <div card-title>LOI-Check</div>
    <div card-content class="h-full"><app-dashboard-checks [checks]="loiChecks"></app-dashboard-checks></div>
  </app-dashboard-card>

  <app-dashboard-card class="border border-gray-200 w-full rounded-lg p-4 row-span-2" [class.col-span-2]="!isBim" [tooltipText]="'Abgleich von Planungsdokumenten und IFC-Modellen mit Normen und Richtlinien.'">
    <div card-icon><fa-icon class="text-base" (click)="navigateToQualityCheck()" [icon]="faBadgeCheck"></fa-icon></div>
    <div card-title>Qualitäts-Check</div>
    <div card-content class="h-full"><app-dashboard-checks [checks]="qualityChecks"></app-dashboard-checks></div>
  </app-dashboard-card>

</div>
