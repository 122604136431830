<div class="p-4 overflow-auto bg-white rounded-2xl shadow-full">
  <div class="flex flex-col justify-start items-start gap-2">

    <!-- headline -->
    <div class="flex flex-row w-full justify-between items-center pr-2">
      <div class="font-medium">Benutzerdefinierte Berechtigung {{ isExistingRole ? "bearbeiten" : "erstellen" }}</div>
      <fa-icon (click)="close()" [icon]="faCircleXmark" class="text-xl text-black hover:cursor-pointer"></fa-icon>
    </div>

    <!-- role name field -->
    <div [formGroup]="form" class="flex flex-row w-full">
      <mat-form-field appearance="outline" class="flex w-full py-2" subscriptSizing="fixed">
        <mat-label>Name der Berechtigung</mat-label>
        <input name="roleTitle" formControlName="roleTitle" matInput required>
      </mat-form-field>
    </div>

    <!-- project role permissions -->
    <!-- TODO: with incoming existing custom rule -> display checkbox as checked according to saved rights in rule -->
    <div class="flex flex-col w-full justify-start items-start gap-3 pr-3">
      <div class="flex flex-row w-full justify-between items-center" *ngFor="let privilege of allPrivileges">
        {{ privilege.name | roleAndPrivileges }}
        <div class="w-[18px] h-[18px] overflow-hidden flex items-center justify-center">
          <div>
            <mat-checkbox  [checked]="isSelected(privilege)" (change)="selectPrivilege($event, privilege)"></mat-checkbox>
          </div>
        </div>
      </div>
    </div>


    <!-- cancel and save buttons -->
    <div class="flex flex-row w-full justify-between items-center pt-4">
      <button (click)="close()" mat-raised-button class="w-[30%]">
        <span>Abbrechen</span>
      </button>
      <button (click)="saveChanges()" [disabled]="!checkInputFilled()" color="primary" mat-raised-button
              class="w-[30%]">
        <span>{{ isExistingRole ? "Speichern" : "Erstellen" }}</span>
      </button>
    </div>

  </div>
</div>
<!--<p>{{this.data.roleName}}</p>-->
