import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {QualityCheck} from "../../../api";

@Component({
  selector: 'app-quality-stepper',
  templateUrl: './quality-stepper.component.html',
  styleUrls: ['./quality-stepper.component.scss']
})
export class QualityStepperComponent implements OnInit {
  selectedStep = 0;

  completedSteps: number[] = [0];

  qualityCheck!: QualityCheck

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.qualityCheck = this.activatedRoute.snapshot.parent!.data.qualityCheck;

    if(this.qualityCheck) {
      this.completedSteps.push(0);

      if(Object.values(this.qualityCheck.extractionIdsOfProjectFiles!)?.length > 0) {
        this.completedSteps.push(1);
      }

      if(this.qualityCheck.completed) {
        this.selectedStep = 2;
        this.completedSteps = [0, 1, 2, 3, 4, 5];
      }
    }


    const url = this.activatedRoute.snapshot.pathFromRoot
      .map(v => v.url)
      .filter(v => v.length > 0)
      .slice(2)
      .flat()

    if (url.length == 1 || url.at(-1)?.path == 'files') {
      this.selectedStep = 0;
    }

    if (url.at(-1)?.path == 'rules') {
      this.selectedStep = 1;
    }

    if (url.at(-1)?.path == 'compliance') {
      this.selectedStep = 2;

    }else if (url.at(-1)?.path == 'report') {
      this.selectedStep = 3;

    }else if (url.at(-2)?.path == 'report') {
      this.selectedStep = 4;

    }else if (url.at(-3)?.path == 'report') {
      this.selectedStep = 5;
    }
  }

  changeStep(index: number): void {
    const projectId = this.activatedRoute.snapshot.parent!.parent!.params.id;
    const qualityCheckId = this.qualityCheck.id;

    const fileId = this.activatedRoute.snapshot.params.fileId;
    const ruleId = this.activatedRoute.snapshot.params.ruleId;

    if (index == 0) {
      this.router.navigate(["projects", projectId, "quality", qualityCheckId, "files"]);

    }else if (index == 1) {
      this.router.navigate(["projects", projectId, "quality", qualityCheckId, "rules"]);

    }else if (index == 2) {
      this.router.navigate(["projects", projectId, "quality", qualityCheckId, "report", "compliance"]);

    }else if (index == 3) {
      this.router.navigate(["projects", projectId, "quality", qualityCheckId, "report"]);

    }else if (index == 4) {
      if(fileId) {
        this.router.navigate(["projects", projectId, "quality", qualityCheckId, "report", fileId]);
      }

    }else if (index == 5) {
      if(fileId && ruleId) {
        this.router.navigate(["projects", projectId, "quality", qualityCheckId, "report", fileId, ruleId]);
      }
    }
  }


}
