// import {Component, Input, OnInit, ViewChild} from '@angular/core';
// import {VERSION} from "@angular/cdk";
// import {MatTableDataSource} from "@angular/material/table";
// import {faClipboardList, faFile, faFileCircleXmark} from "@fortawesome/free-solid-svg-icons";
// import {CircleState} from "../design/circle-state/circle-state.component";
// import {animate, state, style, transition, trigger} from "@angular/animations";
// import {
//   CheckControllerService,
//   CheckResults,
//   CompactPartialServiceEvaluationResult,
//   CompletenessCheck, ProjectFile,
//   Task
// } from "../../../api";
// import {MatSort} from "@angular/material/sort";
// import {DrawerContentService} from "../../../services/drawer-content.service";
// import {StateConfigService} from "../../../services/state/state-config.service";
// import {TaskDetailsService} from "../../../services/task-details.service";
// import {ServicePipe} from "../../../pipes/service.pipe";
// import {PdfService} from "../../../services/pdf/pdf.service";
// import {ActivatedRoute} from "@angular/router";
// import {PdfViewerComponent} from "../files/components/pdf-viewer/pdf-viewer.component";
import {
  CheckControllerService,
  CheckResults,
  CompactServiceAspectEvaluationResult,
  CompletenessCheck,
  Organisation,
  Project,
  ProjectFile,
  ProjectServiceNotification,
  Task
} from "../../../api";
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatSort} from "@angular/material/sort";
import {DrawerContentService} from "../../../services/drawer-content.service";
import {StateConfigService} from "../../../services/state/state-config.service";
import {TaskDetailsService} from "../../../services/task-details.service";
import {AspectActivatedPipe, AspectPipe, DescriptionPipe, ServicePipe} from "../../../pipes/service.pipe";
import {PdfService} from "../../../services/pdf/pdf.service";
import {
  faBars,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faEllipsisVertical,
  faFile,
  faFileCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons";
import {CircleState} from "src/app/components/util/design/circle-state/circle-state.component";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import {PermissionService} from "../../../services/permission/permission.service";
import {complianceParameterMap} from "./complianceParameterMap";
import PartialServiceNameEnum = ProjectServiceNotification.PartialServiceNameEnum;

interface CheckRule {
  name: PartialServiceNameEnum,
  result: CheckResults | undefined
}

@Component({
  selector: 'app-bic-table',
  templateUrl: './bic-table.component.html',
  styleUrls: ['./bic-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

// so far, the check and datasource are mandatory inputs
export class BicTableComponent implements OnInit, OnChanges {

  //Inputs for HoaiReport Table
  @Input() check!: CompletenessCheck;
  @Input() dataSource!: MatTableDataSource<CheckRule>;
  @Input() isHOAIReport: boolean = false;
  @Input() isHOAIDocView: boolean = false;
  @Input() isHAOIDocDetailView: boolean = false;
  @Input() selectedService!: CheckRule;
  @Input() selectedDocumentFile!: ProjectFile;
  @Input() isGL: boolean = true

  @Output() swappingDocView = new EventEmitter<{ isDocView: boolean, docViewOf: string, selectedService: CheckRule }>
  @Output() swappingDocDetailView = new EventEmitter<{ isDocDetailView: boolean, selectedDocumentFile: ProjectFile }>

  //Inputs for Role Table
  @Input() project!: Project;
  @Input() organisation!: Organisation;
  @Input() isProject = false;
  @Input() permissionService!: PermissionService;

  displayedColumns = ['name', 'state', 'details', 'actions']
  expandedElement!: CheckRule | undefined;
  expandedAspect!: CompactServiceAspectEvaluationResult | undefined;
  isDocView: boolean = false;
  isDocDetailView: boolean = false;
  docViewOf: string = '';
  selectedDocument: string = '';
  documentCheckStateMap: Map<string, boolean> = new Map<string, boolean>();
  documentAspectMap: Map<string, Array<CompactServiceAspectEvaluationResult>> = new Map<string, Array<CompactServiceAspectEvaluationResult>>();
  hoveringInfo = false;

  services: MatTableDataSource<CheckRule> = new MatTableDataSource<CheckRule>();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('servicesTbSort') servicesTbSort = new MatSort();
  protected readonly faBars = faBars;
  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronDown = faChevronDown;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly CircleState = CircleState;
  protected readonly faFile = faFile;
  protected readonly faFileCircleXmark = faFileCircleXmark;
  protected readonly faClipboardList = faClipboardList;
  protected readonly faCircleQuestion = faCircleQuestion;


  constructor(
    private checkControllerService: CheckControllerService,
    public drawerService: DrawerContentService,
    public stateConfigService: StateConfigService,
    private taskDetailService: TaskDetailsService,
    private servicePipe: ServicePipe,
    public aspectPipe: AspectPipe,
    public descriptionPipe: DescriptionPipe,
    public pdfService: PdfService,
    private activatedRoute: ActivatedRoute,
    public aspectActivatedPipe: AspectActivatedPipe,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedService) {
      this.createDocumentCheckStateMapForService(this.selectedService);
    }
  }

  get ComplianceCheckResultForSelectedFile() {
    if (this.selectedDocumentFile && this.selectedDocumentFile.id){
      return this.selectedService?.result!.checkResults!.find((result) => result.s3KeyOfAnalyzedDocument?.includes(<string>this.selectedDocumentFile?.id))?.complianceCheckResult
    } else {
      return undefined
    }
  }

  getRelevantParameterFromComplianceCheck() {
    if (this.selectedDocumentFile && this.selectedDocumentFile.id) {
      let complianceCheckResult = this.ComplianceCheckResultForSelectedFile
      if (complianceCheckResult) {
        let service: string = this.selectedService.name
        // @ts-ignore
        const relevantParameters = complianceParameterMap[service];
         return Object.fromEntries(
           Object.entries(complianceCheckResult)
             .filter(([param]) => relevantParameters[param])
         )
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }

  getResultFromComplianceCheckResult(){
    let relevantParameter = this.getRelevantParameterFromComplianceCheck()
    if (relevantParameter) {
      const booleanValues: boolean[] = Object.values(relevantParameter);
      return booleanValues.every(value => value);
    }
    return undefined
  }

  //Swaps from Hoai document view to Hoai service view and back. Triggered when clicking a service to see the documents
  //or when clicking the back button in the document view.
  onSwapDocView(isDocView: boolean, selectedService: CheckRule) {
    this.isDocView = isDocView;
    this.selectedService = selectedService;
    if (this.isGL) {
      this.docViewOf = "GL"
    } else {
      this.docViewOf = "BL"
    }
    this.swappingDocView.emit({isDocView: this.isDocView, docViewOf: this.docViewOf, selectedService: this.selectedService});
  }

  onSwapDocDetailView(isDocDetailView: boolean, selectedFile: ProjectFile) {
    this.isDocDetailView = isDocDetailView;
    this.selectedDocumentFile = selectedFile;
    this.swappingDocDetailView.emit({isDocDetailView: this.isDocDetailView, selectedDocumentFile: this.selectedDocumentFile});
  }

  createTask(partialService: string) {
    let task = {
      markup: {
        topic: {
          title: 'Überarbeiten der HOAI-Prüfung: ' + this.servicePipe.transform(partialService),
        }
      },
      lph: this.check.lph,
    }


    this.taskDetailService.setOpenedTask(task as Task);
  }

  //Creates a map that saves whether a document check state should be green or red.
  //If a document.id is found in the respective s3Keys, check the result. If it succeeded, icons should be displayed green.
  //Also creates a map so that all aspects of a document can be conveniently retrieved.
  createDocumentCheckStateMapForService(selectedService: CheckRule) {
    if(selectedService) {
      let documents = selectedService.result!.succeedDocuments;
      let checkResults = selectedService.result!.checkResults;
      let tempDocumentCheckStateMap = new Map<string, boolean>();
      let tempDocumentAspectMap = new Map<string, Array<CompactServiceAspectEvaluationResult>>
      if(documents && checkResults) {
        documents.forEach((document: ProjectFile) => {
          tempDocumentCheckStateMap.set(document.id!, false);
          for (let result of checkResults!) {
            if (result.s3KeyOfAnalyzedDocument!.includes(document.id!)) {
              tempDocumentCheckStateMap.set(document.id!, result.succeed!);
              tempDocumentAspectMap.set(document.id!, result.serviceAspectEvaluationResults!)
              break;
            }
          }
        });
      }
      this.documentCheckStateMap = tempDocumentCheckStateMap;

      // remove previoud compliance check results
      tempDocumentAspectMap.forEach((value, key) => {
        if(value.some(aspect => aspect.serviceAspectName === "Konformitäts-Check")) {
          tempDocumentAspectMap.set(key, value.filter(aspect => aspect.serviceAspectName !== "Konformitäts-Check"))
        }
      })

      if(this.selectedDocumentFile && this.selectedDocumentFile.id && this.ComplianceCheckResultForSelectedFile && this.getRelevantParameterFromComplianceCheck()) {
        tempDocumentAspectMap.get(this.selectedDocumentFile.id)!.push({serviceAspectName: "Konformitäts-Check", succeed: this.getResultFromComplianceCheckResult()})
      }
      this.documentAspectMap = tempDocumentAspectMap;
    }
  }

  //Check the map whether a document check state should be shown green or red by id.
  documentStateShouldBeGreen(docId: string) {
    if(this.documentCheckStateMap) {
      return this.documentCheckStateMap.get(docId)
    }
    return false;
  }

  getDocumentAspectsById(docId: string) {
    if(this.documentAspectMap) {
      return this.documentAspectMap.get(docId)?.filter(aspect => this.aspectActivatedPipe.transform(aspect.serviceAspectName!));
    }
    return [];
  }

  getTotalAmountOfAspects(aspects: Array<CompactServiceAspectEvaluationResult>) {
    if(aspects) {
      return aspects.length;
    }
    return 0;
  }

  getAmountOfSucceededAspects(aspects: Array<CompactServiceAspectEvaluationResult>) {
    let amountSucceededAspects = 0;
    if(aspects) {
      aspects.forEach((aspect) => {
        if(aspect.succeed) {
            amountSucceededAspects += 1;
        }
      });
    }
    return amountSucceededAspects;
  }

  toggleExpandOrCollapse() {
  }

  setFirstDocumentAsSelected() {
    // @ts-ignore
    this.selectedDocument = this.expandedElement?.result?.checkResults[0].s3KeyOfAnalyzedDocument ?? '';
  }

  getExpandedResult(){
    let result = this.expandedElement?.result?.checkResults?.filter((result) => result.s3KeyOfAnalyzedDocument === this.selectedDocument)
    if(result!.length > 0){
      return result![0];
    }else{
      return [];
    }
  }

  protected readonly Object = Object;
}
