import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterLink } from "@angular/router";
import {UnsavedChangesDialogComponent} from "../unsavedChangesDialog/unsaved-changes-dialog.component";
import {Observable} from "rxjs";
import {ToolbarService} from "./toolbar.service";



@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  title: string = "Test";

  constructor(public toolbar: ToolbarService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activatedRoute.data.subscribe(data => {
          this.title = data.title;
        });
      }
    });
  }

}
