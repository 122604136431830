import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Organisation, OrganisationControllerService} from "../../api";
import {StateConfigService} from "../../services/state/state-config.service";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OrganisationResolver  {
  constructor(private stateConfigService: StateConfigService, private organisationControllerService: OrganisationControllerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Organisation> {
    let id = Number(route.paramMap.get('id'));
    if (isNaN(id)) {
      id = this.stateConfigService.getOrganisationId();
    }

    return this.organisationControllerService.getOrganisation(id).pipe(
      tap(organisation => this.stateConfigService.selectedOrganisation = organisation)
    );

  }
}
