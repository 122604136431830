/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FileUploadStringTransfer } from '../model/fileUploadStringTransfer';
import { ProjectFile } from '../model/projectFile';
import { ZipResponse } from '../model/zipResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class FileControllerService {

    protected basePath = 'https://api.beta.planbic.de';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param projectId 
     * @param folderString 
     * @param lph 
     * @param orgfilename 
     * @param filesize 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFile(projectId: number, folderString: string, lph: string, orgfilename: string, filesize: number, parentId?: string, observe?: 'body', reportProgress?: boolean): Observable<FileUploadStringTransfer>;
    public addFile(projectId: number, folderString: string, lph: string, orgfilename: string, filesize: number, parentId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileUploadStringTransfer>>;
    public addFile(projectId: number, folderString: string, lph: string, orgfilename: string, filesize: number, parentId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileUploadStringTransfer>>;
    public addFile(projectId: number, folderString: string, lph: string, orgfilename: string, filesize: number, parentId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addFile.');
        }

        if (folderString === null || folderString === undefined) {
            throw new Error('Required parameter folderString was null or undefined when calling addFile.');
        }

        if (lph === null || lph === undefined) {
            throw new Error('Required parameter lph was null or undefined when calling addFile.');
        }

        if (orgfilename === null || orgfilename === undefined) {
            throw new Error('Required parameter orgfilename was null or undefined when calling addFile.');
        }

        if (filesize === null || filesize === undefined) {
            throw new Error('Required parameter filesize was null or undefined when calling addFile.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }
        if (folderString !== undefined && folderString !== null) {
            queryParameters = queryParameters.set('folderString', <any>folderString);
        }
        if (lph !== undefined && lph !== null) {
            queryParameters = queryParameters.set('lph', <any>lph);
        }
        if (parentId !== undefined && parentId !== null) {
            queryParameters = queryParameters.set('parentId', <any>parentId);
        }
        if (orgfilename !== undefined && orgfilename !== null) {
            queryParameters = queryParameters.set('orgfilename', <any>orgfilename);
        }
        if (filesize !== undefined && filesize !== null) {
            queryParameters = queryParameters.set('filesize', <any>filesize);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<FileUploadStringTransfer>('post',`${this.basePath}/files/upload`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public classify(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public classify(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public classify(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public classify(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling classify.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/files/${encodeURIComponent(String(id))}/classify`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param lph 
     * @param folder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public copyFile(body: Array<string>, lph: string, folder: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectFile>>;
    public copyFile(body: Array<string>, lph: string, folder: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectFile>>>;
    public copyFile(body: Array<string>, lph: string, folder: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectFile>>>;
    public copyFile(body: Array<string>, lph: string, folder: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling copyFile.');
        }

        if (lph === null || lph === undefined) {
            throw new Error('Required parameter lph was null or undefined when calling copyFile.');
        }

        if (folder === null || folder === undefined) {
            throw new Error('Required parameter folder was null or undefined when calling copyFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ProjectFile>>('post',`${this.basePath}/files/copy/${encodeURIComponent(String(lph))}/${encodeURIComponent(String(folder))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param projectId 
     * @param folder 
     * @param lph 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFolder(body: string, projectId: number, folder: string, lph: string, parentId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectFile>>;
    public createFolder(body: string, projectId: number, folder: string, lph: string, parentId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectFile>>>;
    public createFolder(body: string, projectId: number, folder: string, lph: string, parentId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectFile>>>;
    public createFolder(body: string, projectId: number, folder: string, lph: string, parentId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createFolder.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling createFolder.');
        }

        if (folder === null || folder === undefined) {
            throw new Error('Required parameter folder was null or undefined when calling createFolder.');
        }

        if (lph === null || lph === undefined) {
            throw new Error('Required parameter lph was null or undefined when calling createFolder.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }
        if (folder !== undefined && folder !== null) {
            queryParameters = queryParameters.set('folder', <any>folder);
        }
        if (lph !== undefined && lph !== null) {
            queryParameters = queryParameters.set('lph', <any>lph);
        }
        if (parentId !== undefined && parentId !== null) {
            queryParameters = queryParameters.set('parentId', <any>parentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ProjectFile>>('post',`${this.basePath}/files/createFolder`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFile(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectFile>>;
    public deleteFile(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectFile>>>;
    public deleteFile(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectFile>>>;
    public deleteFile(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProjectFile>>('delete',`${this.basePath}/files/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadMultipleFiles(body: Array<string>, id: number, observe?: 'body', reportProgress?: boolean): Observable<ZipResponse>;
    public downloadMultipleFiles(body: Array<string>, id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ZipResponse>>;
    public downloadMultipleFiles(body: Array<string>, id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ZipResponse>>;
    public downloadMultipleFiles(body: Array<string>, id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling downloadMultipleFiles.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadMultipleFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ZipResponse>('post',`${this.basePath}/files/${encodeURIComponent(String(id))}/downloadMultipleFiles`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFile(id: string, observe?: 'body', reportProgress?: boolean): Observable<ProjectFile>;
    public getFile(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectFile>>;
    public getFile(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectFile>>;
    public getFile(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ProjectFile>('get',`${this.basePath}/files/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFileStatus(id: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public getFileStatus(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public getFileStatus(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public getFileStatus(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFileStatus.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/files/${encodeURIComponent(String(id))}/status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param enumValue 
     * @param lph 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFiles(projectId: number, enumValue?: string, lph?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectFile>>;
    public getFiles(projectId: number, enumValue?: string, lph?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectFile>>>;
    public getFiles(projectId: number, enumValue?: string, lph?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectFile>>>;
    public getFiles(projectId: number, enumValue?: string, lph?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getFiles.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }
        if (enumValue !== undefined && enumValue !== null) {
            queryParameters = queryParameters.set('enumValue', <any>enumValue);
        }
        if (lph !== undefined && lph !== null) {
            queryParameters = queryParameters.set('lph', <any>lph);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProjectFile>>('get',`${this.basePath}/files`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIfcFiles(projectId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectFile>>;
    public getIfcFiles(projectId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectFile>>>;
    public getIfcFiles(projectId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectFile>>>;
    public getIfcFiles(projectId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getIfcFiles.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProjectFile>>('get',`${this.basePath}/files/getIfcFiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param lph 
     * @param folder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveFile(body: Array<string>, lph: string, folder: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectFile>>;
    public moveFile(body: Array<string>, lph: string, folder: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectFile>>>;
    public moveFile(body: Array<string>, lph: string, folder: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectFile>>>;
    public moveFile(body: Array<string>, lph: string, folder: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling moveFile.');
        }

        if (lph === null || lph === undefined) {
            throw new Error('Required parameter lph was null or undefined when calling moveFile.');
        }

        if (folder === null || folder === undefined) {
            throw new Error('Required parameter folder was null or undefined when calling moveFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ProjectFile>>('post',`${this.basePath}/files/move/${encodeURIComponent(String(lph))}/${encodeURIComponent(String(folder))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public renameFile(body: string, id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectFile>>;
    public renameFile(body: string, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectFile>>>;
    public renameFile(body: string, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectFile>>>;
    public renameFile(body: string, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling renameFile.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling renameFile.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ProjectFile>>('post',`${this.basePath}/files/${encodeURIComponent(String(id))}/rename`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
