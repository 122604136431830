<div class="flex flex-col justify-start items-center">
  <div>
    <svg *ngIf="state == CircleState.GREEN" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" class="w-full">

      <path
        d="m141.73,0C63.46,0,0,63.46,0,141.73s63.46,141.73,141.73,141.73,141.73-63.46,141.73-141.73S220.01,0,141.73,0Zm0,245.67c-57.4,0-103.94-46.53-103.94-103.94s46.53-103.94,103.94-103.94,103.94,46.53,103.94,103.94-46.53,103.94-103.94,103.94Z"
        style="fill:#d9d9d9;"/>
      <circle id="circle" class="stroke-primary" [style.stroke-dashoffset]="calculateDashOffset()" cx="141.73" cy="141.73" r="122.94"/>

      <ng-container *ngIf="!compact">
        <circle cx="141.73" cy="141.73" r="103.94" style="fill:#099; opacity:.2;"/>
        <path
          d="m205.91,108.12l-8.47-8.47c-2.95-2.95-7.74-2.95-10.69,0l-62.44,62.44-28.37-28.37c-2.95-2.95-7.74-2.95-10.69,0l-7.69,7.69c-2.95,2.95-2.95,7.74,0,10.69l42.11,42.11c2.95,2.95,7.74,2.95,10.69,0l4.37-4.37c.27-.2.54-.4.79-.65l70.39-70.39c2.95-2.95,2.95-7.74,0-10.69Z"
          style="fill:#FFFFFF; opacity:.56;"/>
      </ng-container>

      <ng-container *ngIf="compact">
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="67px" font-weight="bold" class="fill-[#099]">{{value}}%</text>
      </ng-container>

      <ng-container *ngIf="useAsIcon">
        <circle cx="141.73" cy="141.73" r="103.94" style="fill:#009999;"/>
        <path
          d="m205.91,108.12l-8.47-8.47c-2.95-2.95-7.74-2.95-10.69,0l-62.44,62.44-28.37-28.37c-2.95-2.95-7.74-2.95-10.69,0l-7.69,7.69c-2.95,2.95-2.95,7.74,0,10.69l42.11,42.11c2.95,2.95,7.74,2.95,10.69,0l4.37-4.37c.27-.2.54-.4.79-.65l70.39-70.39c2.95-2.95,2.95-7.74,0-10.69Z"
          style="fill:#FFFFFF; opacity:.99;"/>
      </ng-container>
    </svg>

    <svg *ngIf="state == CircleState.YELLOW" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" class="w-full">

      <path
        d="m141.73,0C63.46,0,0,63.46,0,141.73s63.46,141.73,141.73,141.73,141.73-63.46,141.73-141.73S220.01,0,141.73,0Zm0,245.67c-57.4,0-103.94-46.53-103.94-103.94s46.53-103.94,103.94-103.94,103.94,46.53,103.94,103.94-46.53,103.94-103.94,103.94Z"
        style="fill:#d9d9d9; opacity:.56;"/>
      <circle id="circle" class="stroke-[#ffc000]" [style.stroke-dashoffset]="calculateDashOffset()" cx="141.73" cy="141.73" r="122.94"/>
      <ng-container *ngIf="!compact">

        <circle cx="141.73" cy="141.73" r="103.94" style="fill:#ffc000; opacity:.2;"/>
        <rect x="125.73" y="84.46" width="32" height="68.67" rx="5.67" ry="5.67" style="fill:#fff;"/>
        <circle cx="141.73" cy="180.11" r="18.9" style="fill:#fff;"/>
      </ng-container>

      <ng-container *ngIf="compact">
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="67px" font-weight="bold" class="fill-[#ffc000]">{{value}}%</text>
      </ng-container>

      <ng-container *ngIf="useAsIcon">
        <circle cx="141.73" cy="141.73" r="103.94" style="fill:#ffc000;"/>
        <rect x="125.73" y="84.46" width="32" height="68.67" rx="5.67" ry="5.67" style="fill:#fff;"/>
        <circle cx="141.73" cy="180.11" r="18.9" style="fill:#fff;"/>
      </ng-container>
    </svg>


    <svg *ngIf="state == CircleState.RED" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" class="w-full">

      <path
        d="m141.73,0C63.46,0,0,63.46,0,141.73s63.46,141.73,141.73,141.73,141.73-63.46,141.73-141.73S220.01,0,141.73,0Zm0,245.67c-57.4,0-103.94-46.53-103.94-103.94s46.53-103.94,103.94-103.94,103.94,46.53,103.94,103.94-46.53,103.94-103.94,103.94Z"
        style="fill:#d9d9d9; opacity:.56;"/>
        <circle id="circle" class="stroke-[#ee3b3b]" [style.stroke-dashoffset]="calculateDashOffset()" cx="141.73" cy="141.73" r="122.94"/>
      <ng-container *ngIf="!compact">
        <circle cx="141.73" cy="141.73" r="103.94" style="fill:#ee3b3b; opacity:.2;"/>

        <rect x="69.07" y="126.4" width="145.33" height="30.67" rx="5.67" ry="5.67"
              transform="translate(141.73 -58.71) rotate(45)" style="fill:#fff;"/>
        <rect x="69.07" y="126.4" width="145.33" height="30.67" rx="5.67" ry="5.67"
              transform="translate(342.17 141.73) rotate(135)" style="fill:#fff;"/>
      </ng-container>

      <ng-container *ngIf="compact">
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="67px" font-weight="bold" class="fill-[#ee3b3b]">{{value | number: '1.0-0' }}%</text>
      </ng-container>

      <ng-container *ngIf="useAsIcon">
        <circle cx="141.73" cy="141.73" r="110.94" style="fill:#ee3b3b;"/>
        <rect x="69.07" y="126.4" width="145.33" height="30.67" rx="5.67" ry="5.67"
              transform="translate(141.73 -58.71) rotate(45)" style="fill:#fff;"/>
        <rect x="69.07" y="126.4" width="145.33" height="30.67" rx="5.67" ry="5.67"
              transform="translate(342.17 141.73) rotate(135)" style="fill:#fff;"/>
      </ng-container>
    </svg>
  </div>
  <div *ngIf="!compact">
    <ng-content></ng-content>
  </div>
</div>
