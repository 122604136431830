import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';

import {Router} from "@angular/router";
import {AuthControllerService, User} from "../../api";
import {StateConfigService} from "../state/state-config.service";
import {IfcStateService} from "../state/ifc-state.service";

/**
 * Service for Authentication.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * Constructor
   * @param router Router
   * @param http HttpClient
   * @param jwtHelper JwtHelperService
   * @param authControllerService
   * @param stateConfig
   * @param ifcStateService
   */
  constructor(private router: Router, private http: HttpClient, private jwtHelper: JwtHelperService,
              private authControllerService: AuthControllerService, private stateConfig: StateConfigService, private ifcStateService: IfcStateService) {

  }

  /**
   * Logs In using the given data
   * @param _username to login
   * @param _password to login
   */
  // tslint:disable-next-line:variable-name
  //TODO: change to authServiceController
  login(_username: string, _password: string): Observable<boolean> {
    const dataSubject = new Subject<boolean>();

    this.authControllerService.login({
      username: _username,
      password: _password
    }).subscribe(next => {


      if(next.mfaRequired) {
        this.router.navigate(['/mfa'], { state: { response: next } });
        return;
      }

      // TODO: Disable because PWA disabled
      //this.pushNotificationsService.subscribeToNotification();

      window.localStorage.setItem('token', next.token!);
      this.stateConfig.start();
      dataSubject.next(true);
    }, error => {
      localStorage.removeItem('token');
      sessionStorage.clear();
      dataSubject.error(false);
    });

    return dataSubject.asObservable();
  }


  loginMfa(_username: string, _password: string, _mfaCode: string): Observable<boolean> {
    const dataSubject = new Subject<boolean>();

    this.authControllerService.verify2FA({
      username: _username,
      password: _password,
      verificationCode: _mfaCode
    }).subscribe(next => {

      window.localStorage.setItem('token', next.token!);
      this.stateConfig.start();
      dataSubject.next(true);
    }, error => {
      localStorage.removeItem('token');
      sessionStorage.clear();
      dataSubject.error(false);
    });

    return dataSubject.asObservable();

  }

  /**
   * Checks if a user is logged in
   */
  isLoggedIn(): boolean {
    try {
      return !this.jwtHelper.isTokenExpired(localStorage.getItem('token')!);
    } catch (e) {
      this.logout();
      return false;
    }
  }

  /**
   * logout th user.
   */
  logout(): void {
    this.clearStorage();
    this.stateConfig.stop();
    this.ifcStateService.stop();
    // window.location.reload();
  }

  clearStorage(): void {
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')

    localStorage.clear()
    sessionStorage.clear()
  }

}
