import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-bim-base-check-stepper',
  templateUrl: './bim-base-check-stepper.component.html',
  styleUrls: ['./bim-base-check-stepper.component.scss']
})
export class BimBaseCheckStepperComponent implements OnInit {
  selectedStep = 0;

  completedSteps: number[] = [0];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    const url = this.activatedRoute.snapshot.pathFromRoot
      .map(v => v.url)
      .filter(v => v.length > 0)
      .slice(2)
      .flat()

    if (url.length == 1 || url.at(-1)?.path == 'files') {
      this.completedSteps = []
      this.selectedStep = 0;
    }

    if (url.at(-3)?.path == 'reports') {
      this.completedSteps = [0, 1];
      this.selectedStep = 1;
    }
  }

  changeStep(index: number): void {

  }
}
