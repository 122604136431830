<mat-dialog-content>
  <div class="flex flex-col justify-center items-center">
    <h1 id="warning">{{data.header}}</h1>
  </div>

  <h4 style="text-align: center" [innerHTML]="data.message"></h4>

  <div class="flex flex-row justify-between items-center">
    <button (click)="declinePressed()"  id="declineButton" mat-dialog-close mat-raised-button
            matTooltip="Kehren Sie zu den Projekteinstellungen zurück">
      Abbrechen
    </button>
    <button *ngIf="holdable" (holdTime)="holdHandler($event)" appHoldable color="primary" id="deleteButton" mat-raised-button
            matTooltip="Verlassen und unwiderruflich löschen">
      <span>{{data.approveButtonName}}</span>
    </button>
    <button *ngIf="!holdable" (click)="approvePressed()" color="primary" id="approveButton" mat-raised-button
            matTooltip="Bestätigen">
      <span>{{data.approveButtonName}}</span>
    </button>
  </div>
</mat-dialog-content>
