import {Component, OnInit} from '@angular/core';
import {
  Project,
  ProjectControllerService
} from "../../../api";
import {Router} from "@angular/router";
import StateEnum = AddressInput.StateEnum;
import {AddressInput} from "./State";
import {StateConfigService} from "../../../services/state/state-config.service";


@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {
  states: typeof StateEnum = StateEnum;
  project!: Project

  application: any = {}

  constructor(private projectController: ProjectControllerService, private stateService: StateConfigService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.projectController.getProject(this.stateService.getProjectId()).subscribe((project) => {
      this.project = project
      if (this.project.buildingApplication != null) {
        this.application = JSON.parse(this.project.buildingApplication)
      }
    })
  }

  save(application: any) {
    this.project.buildingApplication = JSON.stringify(application)
    this.projectController.updateProject(this.project, this.project.id!).subscribe((project) => {
      this.project = project
    });

  }
}
