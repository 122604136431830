import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {Browser} from "leaflet";

/**
 * Upload of Image Component
 */
@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  /**
   * the image to show as S3 url
   */
  @Input() image!: string | undefined;
  @Output() imageChange: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Defines if the image should not be editable
   */
  @Input() showOnly: boolean = false;

  @ViewChild('imageInput') imageInputElement!: ElementRef;

  showEdit = false;

  triggerClickOnInput() {
    this.imageInputElement.nativeElement.click();
  }


  /**
   * Initialization of Component
   */
  ngOnInit(): void {
  }

  /**
   * upload method - implement in child class component
   * @param event upload event
   */
  uploadImage(event: any) {
    // Need to be implemented in child class component
  }

  openImageOptions(image: string | undefined){

  }




  protected readonly faUser = faUser;
  protected readonly Browser = Browser;
  protected readonly faPen = faPen;
}
