<div class="flex flex-col z-40 h-full bg-[#FFFFFF] shadow rounded-lg">
  <!--  <div class="flex flex-row">-->
  <div class="flex flex-col p-6 gap-4">
    <div class="text-base font-semibold text-center p-2">Möchten Sie die Prüfung wirklich ausführen?</div>
    <div class="text-center text-sm font-normal p-4">Bei Bestätigung der Prüfung werden die <span class="font-medium">ausgewählten Prüfregeln</span>
      mit den <span class="font-medium">ausgewählten Dateien</span> ausgeführt.
    </div>
    <div class="p-4">
    <div class="text-center font-medium text-sm underline">Hinweis:</div>
    <div class="text-center text-sm text-normal">Das Ausführen der Prüfung verbraucht <span class="font-medium">1 Guthaben</span> für
      das Modul Qualitäts-Check
    </div>
    </div>
    <!--      <div class="px-32 text-center pt-4">Verbrauchte Guthaben für Qualitäts-Check: 2 von 7</div>-->
    <div class="flex flex-row pt-4 justify-between">
      <mat-dialog-actions class="flex justify-between w-full gap-2">
        <div class="flex-grow"></div>
        <!--button class="bg-[#FFFFFF]">Abbrechen</button-->
        <bic-button mat-dialog-close [text]="'Abbrechen'" [color]="'#FFF'" [textColor]="'gray-900'" [border]="true" [opacityHover]="false"></bic-button>
        <!--div class="flex-grow"></div-->
        <!--button class="text-white bg-primary w-28" [mat-dialog-close]="true">
          Prüfen
        </button-->
        <bic-button [text]="'Prüfen'" [color]="'primary'" [mat-dialog-close]="true"></bic-button>
      </mat-dialog-actions>
    </div>
  </div>


  <!--  </div>-->
</div>
