 <div class="flex flex-col items-center justify-center z-40 h-full w-full bg-white shadow-full rounded-[10px] p-2">
    <div class="flex flex-row justify-end w-full">
      <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="flex flex-row gap-4">
      <div class="flex flex-col pr-20 pl-20 pb-10 justify-center items-center">
        <div class="text-2xl font-medium text-red-600 text-center">Maximale Dateigröße überschritten!</div>
        <div class="text-lg font-medium text-center pt-4 pb-8">Aus technischen Gründen können nur IFC-Dateien bis zu
          einer Größe von maximal 6 GB hochgeladen bzw. verarbeitet werden.</div>
      </div>


    </div>
  </div>
