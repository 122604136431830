import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {LoadingService} from "./services/loading/loading.service";
import {delay, filter, map, mergeMap} from "rxjs/operators";
import {AuthService} from "./services/auth/auth.service";
import {SidebarService} from "./services/sidebar/sidebar.service";
import {VERSION} from "../environments/version";
import {OverlayContainer} from "@angular/cdk/overlay";
import {OAuthService} from "angular-oauth2-oidc";
import {Organisation, Project, ProjectControllerService, ProjectFile, User} from "./api";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {StateConfigService} from "./services/state/state-config.service";
import {DrawerContentService} from "./services/drawer-content.service";
import {
  faArrowRightFromBracket,
  faBagShopping,
  faBuilding,
  faChartSimple,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleInfo,
  faClipboardCheck,
  faClipboardList,
  faCube,
  faEye,
  faFileCircleCheck,
  faFileSignature,
  faFolderOpen,
  faGauge,
  faListCheck,
  faUser,
  faStamp,
} from "@fortawesome/free-solid-svg-icons";
// import {faFolderOpen} from '@fortawesome/free-regular-svg-icons'; # replaced with solid version
import {MatDialog} from "@angular/material/dialog";
import {IfcStateService} from "./services/state/ifc-state.service";
import {OrganisationPopupComponent} from "./components/organisation/organisation-popup/organisation-popup.component";
import {PopoutContentService} from "./services/popout/popout.service";
import {PermissionService} from "./services/permission/permission.service";
import {BimBaseStateService} from "./services/state/bim-base-state.service";
import {FirstStepsComponent} from "./components/first-steps/first-steps.component";
import FolderEnum = ProjectFile.FolderEnum;
import StateEnum = Project.StateEnum;
import {initFlowbite} from "flowbite";
import {ExchangeService} from "./services/exchange/exchange.service";
import {faBadgeCheck, faMemoCircleCheck, faMemoCircleInfo, faGrid2} from "@fortawesome/pro-solid-svg-icons";
import {faChatbicAi, faIfc, faReport} from "@awesome.me/kit-6ed88ea8d1/icons/modules/kit/custom";


/**
 * Root component for application
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  /**
   * Display state of the analysis running menu
   */
  allowedApplicationForms: StateEnum[] = [StateEnum.BY, StateEnum.NW, StateEnum.BE, StateEnum.HH, StateEnum.NI, StateEnum.BB, StateEnum.HB, StateEnum.HE, StateEnum.MV];

  pageTitle = ""

  /**
   * VERSION of the Application
   */
  VERSION = VERSION;
  user!: User;
  organisation!: Organisation;
  folder: FolderEnum = FolderEnum.KOSTEN;
  lph: ProjectFile.LphEnum = ProjectFile.LphEnum.LPH1;

  sidebarOpened: boolean = true;
  sidebarCollapsed: boolean = false;
  hasTouchScreen: boolean = false;

  /**
   * Constructor
   * @param router Angular Router
   * @param route Activated Route
   * @param loadingService LoadingService
   * @param authService AuthService
   * @param sidebarService SidebarService
   * @param oauthService oAuthService
   * @param overlayContainer OverlayContainer
   * @param dialog
   * @param projectControllerService ProjectControllerService
   * @param drawerContentService
   * @param stateConfigService
   * @param popoutContentService
   * @param ifcStateService
   * @param permissionService
   * @param bimBaseStateService
   * @param matIconRegistry
   * @param domSanitizer
   */
  constructor(private router: Router, public route: ActivatedRoute, public loadingService: LoadingService,
              public authService: AuthService, public sidebarService: SidebarService, public oauthService: OAuthService,
              private overlayContainer: OverlayContainer, public dialog: MatDialog,
              public projectControllerService: ProjectControllerService,
              public drawerContentService: DrawerContentService,
              public stateConfigService: StateConfigService,
              public popoutContentService: PopoutContentService,
              private ifcStateService: IfcStateService,
              public permissionService: PermissionService,
              private bimBaseStateService: BimBaseStateService,
              private exchangeService: ExchangeService,
              public matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    router.events.subscribe((hint) => {
      sidebarService.hint = "";
      sidebarService.hintTitle = "";
      sidebarService.isProject = false;
      sidebarService.isRegister = false;
    })

    this.matIconRegistry.addSvgIcon('pdf', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/PDF.svg'));
    this.matIconRegistry.addSvgIcon('ifc', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/IFC.svg'));
    this.matIconRegistry.addSvgIcon('dwg', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/DWG.svg'));
    this.matIconRegistry.addSvgIcon('folder', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/Folder.svg'));
    this.matIconRegistry.addSvgIcon('ifc-icon', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/ifc-icon.svg'));
    this.matIconRegistry.addSvgIcon('ifc-icon-white', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/ifc-icon-white.svg'));
    this.matIconRegistry.addSvgIcon('download', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/download.svg'));
    this.matIconRegistry.addSvgIcon('open', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/open.svg'));
    this.matIconRegistry.addSvgIcon('upload', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/upload.svg'));
    this.matIconRegistry.addSvgIcon('plus-circle', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/pictos/plus-circle.svg'));
    this.matIconRegistry.addSvgIcon('chatBIC', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/chatBIC/chatBIC.svg'));
    this.matIconRegistry.addSvgIcon('chatBIC-primary', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/chatBIC/chatBIC-primary.svg'));
    this.matIconRegistry.addSvgIcon('chatBIC_add', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/chatBIC/chatBIC_add.svg'));
    this.matIconRegistry.addSvgIcon('chatBIC_filter', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/chatBIC/chatBIC_filter.svg'));
    this.matIconRegistry.addSvgIcon('chatBIC-add-primary', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/chatBIC/chatBIC-add-primary.svg'));
    this.matIconRegistry.addSvgIcon('chatBIC-filter-primary', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/chatBIC/chatBIC-filter-primary.svg'));
    this.matIconRegistry.addSvgIcon('chatBIC-light-gray', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/chatBIC/chatBIC-light-gray.svg'));

  }

  ngAfterViewInit() {
    initFlowbite()
  }

  /**
   * Component initialization
   */
  ngOnInit() {
    window.addEventListener('storage', (event) => {
      if (event.key === 'token' && !event.newValue) {
        this.authService.logout();
        this.router.navigateByUrl("/login");
      }
    });
    initFlowbite();
    this.detectTouchScreen();

    const screenSize = this.getScreenSize();

    if (this.authService.isLoggedIn()) {
      //this.openFirstStepsDialog();
      this.stateConfigService.start();
      this.ifcStateService.start();
      this.bimBaseStateService.start();
      this.exchangeService.start();
    }


    // Updates sidebar Title on navigation
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.rootRoute(this.route)),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
      mergeMap((route: ActivatedRoute) => route.data)
    ).subscribe((event: { [name: string]: any }) => {
      this.sidebarService.title = event['title'];
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
      }
    });

    this.listenToLoading();

    this.sidebarCollapsed = localStorage.getItem("sidebarCollapsed") === "true";
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: Event) {
    this.popoutContentService.close();
  }

  /**
   * Activates LoadingService in combination with HTTP Interceptor
   */
  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.loadingService.isLoading = loading;
      });
  }

  openFirstStepsDialog(): void {
    const ref= this.dialog.open(FirstStepsComponent, {
      panelClass: 'rounded-corners-dialog',
      width: '50vw',
      height: '75vh',
    });

    ref.afterClosed().subscribe(user => {
    });
  }

  /**
   * Logout for admins using Azure AD
   */
  public azureLogout(): void {
    this.oauthService.logOut()
    this.ngOnInit()
  }

  /**
   * Gets the root for given route
   * @param route given actual route
   * @private
   */
  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  applicationFromActivated() {
    return this.allowedApplicationForms.includes(this.stateConfigService.selectedProject.state!)
  }

  setSidebarStatus(status: boolean) {
    this.sidebarCollapsed = status;
    localStorage.setItem("sidebarCollapsed", String(status));
  }

  openNewOrganisation(){
    let ref = this.dialog.open(OrganisationPopupComponent, {
      panelClass: 'rounded-corners-dialog',
      data: {
        title: "Neues Unternehmen"
      },
      width: '60%',
    });
    ref.afterClosed().subscribe(user => {
      this.stateConfigService.update();
    });
  }



  detectTouchScreen() {
    const userAgent = navigator.userAgent;
    const isIOS = /iPad/.test(userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const isAndroidTablet = /Android/.test(userAgent) && !/Mobile/.test(userAgent);
    const isTablet = isIOS || isAndroidTablet; // Basic check for iOS and Android tablets

    const likelyDesktopOrLaptop = window.innerWidth >= 768;

    if (!isTablet && !likelyDesktopOrLaptop) {
      if ('maxTouchPoints' in navigator) {
        this.hasTouchScreen = navigator.maxTouchPoints > 0;
      } else {
        const matchMedia = window.matchMedia('(pointer: coarse)');
        if (matchMedia.matches) {
          this.hasTouchScreen = true;
        } else if ('orientation' in window) {
          this.hasTouchScreen = true; // Deprecated but can be a good indication
        } else {
          // Using userAgent as a last resort
          const userAgent = (navigator as any).userAgent;
          this.hasTouchScreen = (
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(userAgent) ||
            /\b(Android|Windows Phone|iPod)\b/i.test(userAgent)
          );
        }
      }
    } else {
      this.hasTouchScreen = false; // Explicitly exclude tablets
    }
  }




  openEditOrganisation(organisationId: number){
    let ref = this.dialog.open(OrganisationPopupComponent, {
      panelClass: 'rounded-corners-dialog',
      data: {
        organisationId: organisationId,
        title: "Unternehmenseinstellungen"
      },
      width: '60%',
    });

    ref.afterClosed().subscribe(user => {
      this.stateConfigService.update();
    });
  }
  getScreenSize(): { width: number; height: number; devicePixelRatio: number } {
    const screenSize = {
      width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      devicePixelRatio: window.devicePixelRatio
    };

    return screenSize;
  }

  navigateWithEmptyFiles(url : (string | number) []){
    this.router.navigate(['projects/' + this.stateConfigService.getProjectId() +  '/fireprotection/ruleSelection'], { state: { files: [] } }).then(() =>  this.router.navigate(url, { state: { files: [] } }))
  }



hasApplicationTool() {
    return this.stateConfigService.selectedProject.state === StateEnum.BY
      || this.stateConfigService.selectedProject.state === StateEnum.NW
      || this.stateConfigService.selectedProject.state === StateEnum.BE
      || this.stateConfigService.selectedProject.state === StateEnum.HH
      || this.stateConfigService.selectedProject.state === StateEnum.NI
      || this.stateConfigService.selectedProject.state === StateEnum.HE
      || this.stateConfigService.selectedProject.state === StateEnum.MV
      || this.stateConfigService.selectedProject.state === StateEnum.HB
      || this.stateConfigService.selectedProject.state === StateEnum.BB
  }


  protected readonly faArrowRightFromBracket = faArrowRightFromBracket;
  protected readonly faBadgeCheck = faBadgeCheck;
  protected readonly faBagShopping = faBagShopping;
  protected readonly faBuilding = faBuilding;
  protected readonly faChartSimple = faChartSimple;
  protected readonly faChatbicAi = faChatbicAi;
  protected readonly faCheck = faCheck;
  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faCircleCheck = faCircleCheck;
  protected readonly faCircleInfo = faCircleInfo;
  protected readonly faClipboardList = faClipboardList;
  protected readonly faClipboardCheck = faClipboardCheck;
  protected readonly faCube = faCube;
  protected readonly faEye = faEye;
  protected readonly faFileCircleCheck = faFileCircleCheck;
  protected readonly faFileSignature = faFileSignature;
  protected readonly faFolderOpen = faFolderOpen;
  protected readonly faGauge = faGauge;
  protected readonly faGrid2 = faGrid2;
  protected readonly faIfc = faIfc;
  protected readonly faListCheck = faListCheck;
  protected readonly faMemoCircleInfo = faMemoCircleInfo;
  protected readonly faMemoCircleCheck = faMemoCircleCheck;
  protected readonly faReport = faReport;
  protected readonly faStamp = faStamp;
  protected readonly faUser = faUser;

  protected readonly Project = Project;
  protected readonly StateEnum = StateEnum;
  protected readonly window = window;
}
