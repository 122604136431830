import { Pipe, PipeTransform } from '@angular/core';
import {Organisation} from "../api";

@Pipe({
  name: 'legalForms'
})
export class LegalFormsPipe implements PipeTransform {

  transform(value: Organisation.LegalFormEnum | undefined | string): string {
    switch (value) {
      case Organisation.LegalFormEnum.GMBH: return "GmbH";
      case Organisation.LegalFormEnum.UG: return "UG";
      case Organisation.LegalFormEnum.GBR: return "GbR";
      case Organisation.LegalFormEnum.OHG: return "OHG";
      case Organisation.LegalFormEnum.ONEMANBUSINESS: return "Einzelunternehmen";
      case Organisation.LegalFormEnum.GMBHCOKG: return "GmbH & Co. KG";
      case Organisation.LegalFormEnum.KG: return "KG";
      case Organisation.LegalFormEnum.AG: return "AG";
      case Organisation.LegalFormEnum.PRIVATEPERSON: return "Privatperson";
      case Organisation.LegalFormEnum.AUTHORITY: return "Behörde";
      default: return value ?? "";
    }
  }

}
