<!--div style="width: 50px; height: 50px;" *ngIf="user">
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve"
       style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
       viewBox="0 0 200 200">
    <circle cx="50" cy="45" r="45"></circle>
    <text *ngIf="user" x="50" y="45" text-anchor="middle" font-size="40"
          dy=".35em">{{user!.firstName!.substring(0, 1)}}{{user!.lastName!.substring(0, 1)}}</text>
  </svg>
</div-->



<div *ngIf="user" class="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
  <span class="font-medium text-xs text-primary dark:text-gray-300">{{user!.firstName!.substring(0, 1)}}{{user!.lastName!.substring(0, 1)}}</span>
</div>


