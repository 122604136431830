import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'bic-row',
    templateUrl: './bic-row.component.html',
    styleUrls: ['./bic-row.component.scss']
})
export class BicRowComponent implements OnInit {

  @Output() click = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

}
