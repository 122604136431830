<div class="flex flex-col justify-center gap-4 w-full"
     [ngClass]="{
        'items-center' : !isInfo,
        'items-start' : isInfo
        }">
  <ng-container *ngIf="!isInfo">
    <ng-container *ngTemplateOutlet="data"></ng-container>
  </ng-container>

  <ng-container *ngIf="isInfo">
    <ng-container *ngTemplateOutlet="info"></ng-container>
  </ng-container>
</div>


<ng-template #data>
  <app-checkout-feature-list-item class="feature-list-item w-full px-1.5">
    <fa-icon [icon]="faCheck" class="text-primary text-sm"></fa-icon>
    <span class="feature-title w-full text-black px-2.5">Projektbeteiligte</span>
    <ng-container *ngIf="productSettings.users == -1">
      <span class="feature-value text-right whitespace-nowrap font-semibold">unbegrenzt</span>
    </ng-container>
    <ng-container *ngIf="productSettings.users != -1">
      <span *ngIf="!isTrial" class="text-base text-gray-500 whitespace-nowrap pr-1.5">bis zu</span> <span class="feature-value text-right whitespace-nowrap font-semibold">{{ productSettings.users }} x</span>
    </ng-container>
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item class="feature-list-item w-full px-1.5">
    <fa-icon [icon]="faCheck" class="text-primary text-sm"></fa-icon>
    <span class="feature-title w-full text-black px-2.5">HOAI-Check</span>
    <span *ngIf="!isTrial" class="text-base text-gray-500 whitespace-nowrap pr-1.5">bis zu</span> <span class="feature-value text-right whitespace-nowrap font-semibold">{{ productSettings.hoaiChecks }} x</span>
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item class="feature-list-item w-full px-1.5">
    <fa-icon [icon]="faCheck" class="text-primary text-sm"></fa-icon>
    <span class="feature-title w-full text-black px-2.5">Qualitäts-Check</span>
    <span *ngIf="!isTrial" class="text-base text-gray-500 whitespace-nowrap pr-1.5">bis zu</span> <span class="feature-value text-right whitespace-nowrap font-semibold">{{ productSettings.qualityChecks }} x</span>
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item class="feature-list-item w-full px-1.5">
    <fa-icon [icon]="faCheck" class="text-primary text-sm"></fa-icon>
    <span class="feature-title w-full text-black px-2.5">LOI-Check</span>
    <span *ngIf="!isTrial" class="text-base text-gray-500 whitespace-nowrap pr-1.5">bis zu</span> <span class="feature-value text-right whitespace-nowrap font-semibold">{{ productSettings.loiChecks }} x</span>
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item class="feature-list-item w-full px-1.5">
    <fa-icon [icon]="faCheck" class="text-primary text-sm"></fa-icon>
    <span class="feature-title w-full text-black px-2.5">BIM-Basis-Check</span>
    <span *ngIf="!isTrial" class="text-base text-gray-500 whitespace-nowrap pr-1.5">bis zu</span> <span class="feature-value text-right whitespace-nowrap font-semibold">{{ productSettings.bimBaseChecks }} x</span>
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item class="feature-list-item w-full px-1.5">
    <fa-icon [icon]="faCheck" class="text-primary text-sm"></fa-icon>
    <span class="feature-title w-full text-black px-2.5">Bauantragstool</span>
    <ng-container *ngIf="productSettings.buildingApplications == -1">
      <span class="feature-value text-right whitespace-nowrap font-semibold">unbegrenzt</span>
    </ng-container>
    <ng-container *ngIf="productSettings.buildingApplications != -1">
      <span *ngIf="!isTrial" class="text-base text-gray-500 whitespace-nowrap pr-1.5">bis zu</span> <span class="feature-value text-right whitespace-nowrap font-semibold">{{ productSettings.buildingApplications }} x</span>
    </ng-container>
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item class="feature-list-item w-full px-1.5">
    <fa-icon [icon]="faCheck" class="text-primary text-sm"></fa-icon>
    <span class="feature-title w-full text-black px-2.5">chatBIC</span>
    <ng-container *ngIf="productSettings.chatBIC == -1">
      <span class="feature-value text-right whitespace-nowrap font-semibold">unbegrenzt</span>
    </ng-container>
    <ng-container *ngIf="productSettings.chatBIC != -1">
      <span *ngIf="!isTrial" class="text-base text-gray-500 whitespace-nowrap pr-1.5">bis zu</span> <span class="feature-value text-right whitespace-nowrap font-semibold">{{ productSettings.chatBIC }} x</span>
    </ng-container>
  </app-checkout-feature-list-item>
</ng-template>



<ng-template #info>
  <app-checkout-feature-list-item>
    HOAI-Checks
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item>
    Qualitäts-Checks
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item>
    LOI-Checks
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item>
    BIM-Basis-Checks
  </app-checkout-feature-list-item>
  <app-checkout-feature-list-item>
    chatBIC
  </app-checkout-feature-list-item>
</ng-template>
