import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CircleState, CircleStateComponent} from "../../../util/design/circle-state/circle-state.component";
import {faArrowUpRightFromSquare, faDownload} from "@fortawesome/free-solid-svg-icons";
import {PopoutContentService} from "../../../../services/popout/popout.service";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {DatePipe, NgIf} from "@angular/common";
import {MaterialModule} from "../../../../material.module";
import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BicUiComponentsModule} from "@buildinginformationcloud/bic-ui-components";
import {faArrowDownToBracket} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-overview-report',
  templateUrl: './overview-report.component.html',
  styleUrls: ['./overview-report.component.scss'],
  imports: [
    CircleStateComponent,
    DatePipe,
    MaterialModule,
    NgIf,
    FontAwesomeModule,
    BicUiComponentsModule
  ],
  standalone: true
})
export class OverviewReportComponent implements OnInit {

  @Input() checkType: string = '';
  @Input() date: Date = new Date();
  @Input() documents: number = 0;
  @Input() rules: number = 0;
  @Input() completedValue: number = 0;
  @Input() warningValue: number = 0;
  @Input() errorValue: number = 0;
  @Input() isHoaiCheck: boolean = false;
  @Input() fileDeactivated: boolean = false;
  @Input() fileId: string = '';
  @Input() whatWasChecked: string = "Regeln";
  @Input() activateDownload: boolean = false;
  @Input() questionMarkText: string = '';
  @Input() showOpenFile: boolean = true;

  @Output() download: EventEmitter<void> = new EventEmitter<void>();
  protected readonly CircleState = CircleState;

  constructor(
    private popoutContentService: PopoutContentService,
    private stateConfigService: StateConfigService
  ) {
  }

  ngOnInit(): void {
  }

  openFile() {
    let baseUrl = window.location.origin + '/projects/' + this.stateConfigService.selectedProject.id + '/files/' + this.fileId;
    this.popoutContentService.open(baseUrl, '', true);
  }

  downloadPressed(){
    this.download.emit();
  }

  protected readonly faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  protected readonly isFinite = isFinite;
  protected readonly Math = Math;
    protected readonly faCircleQuestion = faCircleQuestion;
  protected readonly faDownload = faDownload;
  protected readonly faArrowDownToBracket = faArrowDownToBracket;
}
