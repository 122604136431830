<div class="flex flex-row w-full px-6 py-3.5 text-xl text-black items-center font-medium shadow-full-new justify-between">
  <div>Projektübersicht</div>

  <div class=" rounded-lg flex flex-row justify-end items-center gap-2 h-10">
    <!-- Add Project Button (Visible in List View) -->
    <!--    <button *ngIf="isListView" mat-raised-button color="primary" (click)="openNewOrganisation()">-->
    <!--      <fa-icon class="text-white pr-2" [icon]="faPlus"></fa-icon>-->
    <!--      <span>Neues Unternehmen anlegen</span>-->
    <!--    </button>-->
    <bic-button *ngIf="isListView" [color]="'white'" [textColor]="'gray-900'" [border]="true" [routerLink]="['/projects/create']" [text]="'Projekt'" [iconLeft]="faPlus" class="mr-2"></bic-button>
    <div class="rounded-lg flex flex-row justify-end items-center ml-auto h-[42px] border border-gray-200" >

      <!-- Grid View Button -->
      <button (click)="setCardView()" class="flex flex-row p-2 rounded-l">
        <div class="flex h-5 w-5 justify-center items-center">
          <fa-icon [icon]="faGrid2" [ngClass]="isCardView ? 'text-gray-900' : 'text-gray-300'"></fa-icon>
        </div>
      </button>

      <div class="border-l-2 border-gray-300 h-6"></div>

      <button (click)="setListView()" class="flex flex-row p-2 rounded-l">
        <div class="flex h-5 w-5 justify-center items-center">
          <fa-icon [icon]="faBars" [ngClass]="isListView ? 'text-gray-900' : 'text-gray-300'"></fa-icon>
        </div>
      </button>
    </div>
  </div>
</div>


<div *ngIf="dataSource && dataSource.data.length > 0"
     class="flex flex-col justify-start items-start h-full w-full gap-2">
  <div class="flex flex-row justify-start items-start flex-wrap w-full overflow-y-scroll p-2">
    <div *ngIf="isCardView"
         class="flex flex-col justify-start items-start p-2 w-full">

      <div class="flex flex-row justify-start items-start flex-wrap gap-10 w-full h-full p-2">
        <bic-card *ngFor="let project of dataSource.data" (click)="navigateToProject(project)" class="p-2">
          <div class="flex flex-col justify-between h-full w-full overflow-hidden">
            <!-- Project Name and Menu Icon -->
            <div class="flex flex-row items-center justify-between pl-2 gap-1 break-words">
              <div class="text-xl font-black line-clamp-1">
                {{project.name}}
              </div>
              <!-- <div class="border-2 border-fuchsia-800"> -->
              <button mat-icon-button class="pr-2" [matMenuTriggerFor]="projectSettings" (click)="$event.stopPropagation()">
                <fa-icon class="text-primary text-3xl" [icon]="faEllipsisVertical"></fa-icon>
              </button>
              <!-- </div> -->

              <mat-menu #projectSettings="matMenu">
                <button mat-menu-item (click)="navigateToProjectSettings(project)">
                  Einstellungen
                </button>
                <button *ngIf="!getIsAdmin(project) || otherAdmins.get(project.id)" mat-menu-item (click)="openLeaveProject(project)">
                  <span class="text-red-500">Verlassen</span>
                </button>
                <ng-container *ngIf="getIsAdmin(project)" class="text-red-500">
                  <button *ngIf="!otherAdmins.get(project.id)" mat-menu-item (click)="openDeleteAndLeaveProject(project)">
                    <span class="text-red-500">Löschen und Verlassen</span>
                  </button>
                  <button *ngIf="otherAdmins.get(project.id)" mat-menu-item (click)="openDeleteProject(project)">
                    <span class="text-red-500">Löschen</span>
                  </button>
                </ng-container>
              </mat-menu>
            </div>

            <!-- Project Information -->
            <div class="flex flex-col justify-center items-start mt-2 mb-4 p-2 gap-3">
              <div class="flex flex-row gap-2 w-full items-center">
                <div>
                  <fa-icon class="text-lg" [icon]="faFile"></fa-icon>
                </div>
                <div class="pl-3.5">Dateien</div>
                <div class="flex flex-row gap-2 ml-auto pr-4 items-center">
                  {{ project.numberOfFiles }} -
                  {{ (calculateSizeInGB(project.fileSize) < 1) ?
                  (calculateSizeInMB(project.fileSize) | number: '.0-2' : 'de-DE') + ' MB' :
                  (calculateSizeInGB(project.fileSize) | number: '.0-2' : 'de-DE') + ' GB' }}
                </div>

              </div>

              <div class="flex flex-row gap-2 w-full items-center">
                <div>
                  <fa-icon class="text-lg" [icon]="faUserGroup"></fa-icon>
                </div>
                <div class=" pl-1.5">Personen</div>
                <div class="ml-auto pr-4">{{project.users.length}}</div>
              </div>

              <div class="flex flex-row gap-2 w-full items-center">
                <div>
                  <fa-icon class="text-lg" [icon]="faListCheck"></fa-icon>
                </div>
                <div class="pl-2.5">Prüfungen</div>
                <div class="ml-auto pr-4">{{project.checksAmount}}</div>
              </div>
              <div class="flex flex-row gap-2 justify-start items-center">
                <div>
                  <fa-icon *ngIf="!project.organisationProject" class="text-lg" [icon]="faUser"></fa-icon>
                  <fa-icon *ngIf="project.organisationProject" class="text-lg" [icon]="faBuilding"></fa-icon>
                </div>

                <div class="pl-3">{{project.owner}} </div>
              </div>
            </div>
          </div>
        </bic-card>

        <bic-card [routerLink]="['/projects/create']" class="p-2">
          <div class="flex flex-col justify-center items-center h-full">
            <fa-icon class="text-[90px]" [icon]="faPlus"></fa-icon>
          </div>

        </bic-card>
      </div>

    </div>
    <div *ngIf="isListView"
         class="flex flex-row justify-between items-start flex-wrap gap-5 w-full p-2">
<!--      <div class="flex flex-row justify-between items-center w-full gap-2">-->
<!--        <div class="flex flex-row justify-start items-center gap-2 w-full">-->
<!--          <button mat-raised-button color="primary" class="ml-0" [routerLink]="['/projects/create']">-->
<!--            <fa-icon class="text-white pr-2" [icon]="faPlus"></fa-icon>-->
<!--            <span>Neues Projekt</span>-->
<!--            </button>-->
<!--        </div>-->
<!--        <div class="shadow-full rounded flex flex-row justify-end items-center">-->
<!--          &lt;!&ndash; Grid View Button &ndash;&gt;-->
<!--          <button (click)="setCardView()" class="flex flex-row p-2 rounded-l">-->
<!--            <div class="flex flex-wrap h-5 w-5 justify-start items-center gap-0.5">-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--              <div [ngClass]="isCardView?'bg-primary':'bg-gray-300'" class="w-2 h-2 rounded-sm"></div>-->
<!--            </div>-->
<!--          </button>-->
<!--          <div class="border-l-2 border-primary h-6"></div>-->
<!--          &lt;!&ndash; List View Button &ndash;&gt;-->
<!--          <button (click)="setListView()" class="flex flex-row p-2 rounded-l">-->
<!--            <div class="flex flex-wrap h-5 w-5 justify-start items-center gap-0.5">-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--              <div [ngClass]="isListView?'bg-primary':'bg-gray-300'" class="w-5 h-0.5 rounded-sm"></div>-->
<!--            </div>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <bic-row [routerLink]="['/projects/create']" class="bg-primary rounded-lg">-->
<!--        <div class="flex flex-row justify-center items-center h-full">-->
<!--          <div class="text-white font-medium pl-2">Projekt erstellen</div>-->
<!--        </div>-->
<!--      </bic-row>-->
      <bic-row (click)="navigateToProject(project)" *ngFor="let project of dataSource.data"
               class="flex flex-col w-full justify-start ">
        <div class="flex flex-row justify-between items-center w-full pl-2 gap-1 break-words">
          <!-- Project Name and Menu Icon -->
          <div class="w-[35%] text-lg font-medium line-clamp-1 h-full">
            {{project.name}}
          </div>
          <!-- Project Information -->
          <ng-container *ngIf="calculateSizeInGB(project.fileSize) < 1">
            <div class="flex flex-row w-[20%] gap-2 justify-start items-center break-all hyphens-auto line-clamp-2 h-full">
              <div>
                <fa-icon [icon]="faFile" class="text-lg"></fa-icon>
              </div>
              <div class="pl-1.5 break-all hyphens-auto line-clamp-2">{{calculateSizeInMB(project.fileSize) | number: '.0-2'}} MB
                - {{project.numberOfFiles}} Dateien
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="calculateSizeInGB(project.fileSize) >= 1">
            <div class="flex flex-row w-[20%] gap-2 justify-start items-center break-all hyphens-auto line-clamp-2 h-full">
              <div>
                <fa-icon [icon]="faFile" class="text-lg"></fa-icon>
              </div>
              <div class="pl-1.5 break-all hyphens-auto line-clamp-2">{{calculateSizeInGB(project.fileSize)  | number: '.0-2'}} GB
                - {{project.numberOfFiles}} Dateien
              </div>
            </div>
          </ng-container>
          <div class="flex flex-row w-[13%] justify-start items-center gap-2 break-all hyphens-auto line-clamp-2 h-full">
            <div>
              <fa-icon [icon]="faUserGroup" class="text-lg"></fa-icon>
            </div>
            <div *ngIf="project.users.length == 1" class="pl-1">{{project.users.length}} Person</div>
            <div *ngIf="project.users.length != 1" class="pl-1">{{project.users.length}} Personen</div>
          </div>
          <div class="flex flex-row w-[14%] justify-start items-center gap-2 break-all hyphens-auto line-clamp-2 h-full">
            <div>
              <fa-icon [icon]="faListCheck" class="text-lg"></fa-icon>
            </div>
            <div class="pl-0.5 break-all hyphens-auto line-clamp-2">{{project.checksAmount}} Prüfungen</div>
          </div>
          <div class="flex flex-row w-[18%] justify-start items-center gap-2 break-all hyphens-auto line-clamp-2 h-full">
            <div>
              <fa-icon *ngIf="!project.organisationProject" [icon]="faUser" class="text-lg"></fa-icon>
              <fa-icon *ngIf="project.organisationProject" [icon]="faBuilding" class="text-lg"></fa-icon>
            </div>
            <div class="pl-0.5 break-all hyphens-auto line-clamp-2">{{project.owner}} </div>
          </div>
          <div class="flex flex-row justify-end items-center">
            <button (click)="$event.stopPropagation()" [matMenuTriggerFor]="projectSettings" class="pr-2"
                    mat-icon-button>
              <fa-icon [icon]="faEllipsisVertical" class="text-primary text-3xl"></fa-icon>
            </button>
          </div>
          <mat-menu #projectSettings="matMenu">
            <button (click)="navigateToProjectSettings(project)" mat-menu-item>
              Einstellungen
            </button>
            <button *ngIf="!getIsAdmin(project) || otherAdmins.get(project.id)" mat-menu-item (click)="openLeaveProject(project)">
              <span class="text-red-500">Verlassen</span>
            </button>
            <ng-container *ngIf="getIsAdmin(project)" class="text-red-500">
              <button *ngIf="!otherAdmins.get(project.id)" mat-menu-item (click)="openDeleteAndLeaveProject(project)">
                <span class="text-red-500">Löschen und Verlassen</span>
              </button>
              <button *ngIf="otherAdmins.get(project.id)" mat-menu-item (click)="openDeleteProject(project)">
                <span class="text-red-500">Löschen</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </bic-row>
  </div>
  </div>
</div>

<div *ngIf="dataSource && dataSource.data.length == 0" class="welcome flex flex-col justify-start items-center" id="welcome-screen">
  <img class="w-[330px] h-[330px]" id="cloud" ngSrc="assets/logo_gradient.svg" alt="" height="16" width="16">
  <button mat-raised-button class="h-[60px] w-[330px] text-base" color="primary" routerLink="/projects/create">Erstellen Sie Ihr erstes Projekt</button>
</div>

<ng-template>
  <table [dataSource]="dataSource" mat-table matSort #projectsTbSort="matSort">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)" #checkbox>
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Image Column -->
    <ng-container matColumnDef="image">
      <th *matHeaderCellDef mat-header-cell>Bild</th>
      <td *matCellDef="let element" mat-cell  (click)="navigateToProject(element)" > <img *ngIf="element.image"
                                                                                          ngSrc="element.image" alt="Bild" height="90"> </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>ID</th>
      <td *matCellDef="let element" mat-cell  (click)="navigateToProject(element)"> {{element.projectIdIntern || "Keine ID vergeben"}} </td>
    </ng-container>


    <!-- firstname Column -->
    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
      <td *matCellDef="let element" mat-cell  (click)="navigateToProject(element)"> {{element.name}} </td>
    </ng-container>


    <ng-container matColumnDef="projectManager">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Projektverantwortlich</th>
      <td *matCellDef="let element" mat-cell  (click)="navigateToProject(element)"> {{element.projectManager.firstName}}  {{element.projectManager.lastName}}</td>
    </ng-container>

    <ng-container matColumnDef="projectOwner">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Besitzer</th>
      <td *matCellDef="let element" mat-cell  (click)="navigateToProject(element)">
        {{element.owner}}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastCheck">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Letzte Prüfung</th>
      <td *matCellDef="let element" mat-cell  (click)="navigateToProject(element)"> {{projectEvaluationMap.get(element)?.checkDate | date: "dd.MM.y - HH:mm"  || ""}} <br> {{projectEvaluationMap.get(element)?.lph }}</td>
    </ng-container>


    <!-- actions Column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>Aktionen</th>
      <td *matCellDef="let element" mat-cell>
        <button id="openProjectButton" mat-icon-button matTooltip="Öffnen" [routerLink]="['/projects/' + element.id]">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row ></tr>
  </table>

  <mat-paginator class="mat-paginator-sticky" [length]="projects_length" [pageSize]="default_page_size" [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
</ng-template>

<app-pop-up *ngIf="leaveProjectBoolean" [header]="'Projekt verlassen?'" [message]="'Durch diesen Vorgang verlassen Sie das Projekt ' + selectedProject.name + '.' +
   '<br> <br> Sie verlieren somit den Zugang zu diesem Projekt!'" [approveButtonName]="'Bestätigen'"
            (approvedPressed)="leaveProject(selectedProject)"
            (declinedPressed)="leaveProjectBoolean=false"></app-pop-up>

<app-pop-up *ngIf="deleteProjectBoolean" [header]="'Projekt verlassen?'" [holdable]="true" [message]="'Durch diesen Vorgang wird das Projekt ' + selectedProject.name +
   ' unwiderruflich gelöscht! <br> <br> Alle Personen werden aus dem Project entfernt und dieses anschließend gelöscht!'"
            [approveButtonName]="'Bestätigen'" (approvedPressed)="deleteProject(selectedProject)"
            (declinedPressed)="deleteProjectBoolean = false"></app-pop-up>

<app-pop-up *ngIf="deleteAndLeaveProjectBoolean" [header]="'Projekt lösche und verlassen?'"
            [holdable]="true" [message]="'Durch diesen Vorgang wird das Projekt ' + selectedProject.name +
   ' unwiderruflich gelöscht. <br> <br> Da Sie die administrative Rolle des Projekts besitzen, wird dieses beim Verlassen gelöscht! <br> Fall Sie lediglich das Projekt verlassen wollen, ernennen Sie zuvor einen zusätzlichen Administrator, der Ihre Position übernimmt! '"
            [approveButtonName]="'Bestätigen'" (approvedPressed)="deleteProject(selectedProject)"
            (declinedPressed)="deleteAndLeaveProjectBoolean = false"></app-pop-up>
