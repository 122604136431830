import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {faClipboardList} from "@fortawesome/free-solid-svg-icons";
import {MatTableDataSource} from "@angular/material/table";
import {QualityCheck, QualityControllerService, QualityRequest, Rule, Task} from "../../../api";
import {TaskDetailsService} from "../../../services/task-details.service";
import {CircleState} from "../../util/design/circle-state/circle-state.component";
import {StateConfigService} from "../../../services/state/state-config.service";
import {DomUtil} from "leaflet";
import getPosition = DomUtil.getPosition;
import LphEnum = Task.LphEnum;
import {ActivatedRoute, Router} from '@angular/router';
import { QualityService } from 'src/app/quality-api';

export interface QualityCheckRule {
  rule: Rule;
  results: QualityCheckResult[];
}

export interface QualityCheckResult {
  type: string;
  responseText: string;
  numberOfTotalComponents: number;
  count: number;
  components: QualityCheckComponent[];
}

export interface QualityCheckComponent {
  id: string;
  "title": string;
  attributes: any;
}

@Component({
  selector: 'app-quality-rules-report',
  templateUrl: './quality-rules-report.component.html',
  styleUrls: ['./quality-rules-report.component.scss']
})
export class QualityRulesReportComponent implements OnInit {

  qualityCheck!: QualityCheck
  rules: Rule[] = [];

  fileId: string = '';
  fileName: string | undefined = undefined;

  data: any[] = []

  displayedColumnsNames = ['Kategorie', 'Prüfregel', 'Prüfstatus']
  displayedColumns = ['category', 'name', 'state']
  columnsTypes = ['text', 'text', 'status']
  columnsWidths = ['20%', '70%', '10%']
  columnsAlignment = ['start', 'start','center']

  navigateToResultView(rule: any) {
    this.router.navigate([rule.id], {relativeTo: this.activatedRoute});
  }
  navigateToFileReport(){
    this.router.navigate(["projects", this.stateConfigService.getProjectId(), "quality", this.qualityCheck.id, "report"]);
  }

  constructor(
    private taskDetailService: TaskDetailsService,
    private qualityService: QualityService,
    private qualityControllerService: QualityControllerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stateConfigService: StateConfigService
  ) {
  }

  ngOnInit(): void {
    this.qualityCheck = this.activatedRoute.parent?.parent?.snapshot.data.qualityCheck;
    this.qualityControllerService.getRules1().subscribe(rules => {
      this.rules = rules;

      this.fileId = this.activatedRoute.snapshot.params.fileId;
      const checkId = this.qualityCheck.checkIdsOfProjectFiles![this.fileId];

      let file = this.qualityCheck.qualityCheckProjectFiles!.find(file => file.projectFile!.id === this.fileId)!;
      let check = file.check!

      this.fileName = file.projectFile!.name

      let dataLocal: any[] = []

      Object.keys(check.result).forEach((rule_id: string) => {
        const rule = this.rules.find(rule => rule.codeId === rule_id)!;
        if(rule) {
          const results = check.result[rule_id];

          dataLocal.push({category:this.category({
            rule: rule!,
            results: results!
          }), name:rule!.name, state:this.overallState({
            rule: rule!,
            results: results!
          }), id:rule!.id})
        }
      });

      this.data = dataLocal

    });
  }

  category(rule: QualityCheckRule): string {
    return rule.rule.name.split(' ')[0];
  }

  overallState(rule: QualityCheckRule) {
    const result = rule.results.flatMap(result => result).map(result => result.type)

    if (result.includes('error')) {
      return 'error'
    } else if (result.includes('warning')) {
      return 'warning'
    } else {
      return 'success'
    }
  }

  get completed() {
    return (100 / this.total) * this.data.filter(file => file.state === 'success').length
  }

  get warning() {
    return (100 / this.total) * this.data.filter(file => file.state === 'warning').length
  }

  get error() {
    return (100 / this.total) * this.data.filter(file => file.state === 'error').length
  }
  get total() {
    return this.data.length
  }


  createTask(element: any) {
     let task = {
      markup: {
        topic: {
          title: 'Überarbeiten notwendig für: ' + element.name,
        }
      },
    }

    this.taskDetailService.setOpenedTask(task as Task);
  }

  protected readonly CircleState = CircleState;
  protected readonly faClipboardList = faClipboardList;
}
