import { Component, OnInit } from '@angular/core';
import {Form, FormBuilder, FormGroup, UntypedFormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-storage-class',
  templateUrl: './storage-class.component.html',
  styleUrls: ['./storage-class.component.scss']
})
export class StorageClassComponent implements OnInit {

  formTRGS!: FormGroup;
  trgs!: number;
  trgsWarningNumber!: number;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formTRGS = this.formBuilder.group({
      storageClass1: new UntypedFormControl("", [Validators.required]),
      storageClass2: new UntypedFormControl("", [Validators.required]),
    });

    this.formTRGS.statusChanges.subscribe(() => {
      this.calculateTRGS();
    });
  }

  calculateTRGS() {
    if(this.formTRGS.valid) {
      let sc1 = this.formTRGS.value.storageClass1;
      let sc2 = this.formTRGS.value.storageClass2;

      // wgk = 0 => erlaubt
      // wgk = 1 => Warnung / eingeschränkt möglich
      // wgk = 2 => nicht möglich

      if(sc1 == '1'){
        if (sc2 == '1'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if (sc1 == '2A') {
        //Mit 2A(3) + 2B(2) +5.1C(1) +8A, 10-13, 11(2) eing ||8B+12+13
        if(sc2 == '2A'){
          this.trgs = 1;
          this.trgsWarningNumber = 3;
        }else if(sc2 == '2B' || sc2 == '8A' || sc2 == '10-13' || sc2 == '11'){
          this.trgs = 1;
          this.trgsWarningNumber = 2;
        }else if(sc2 == '5.1C'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        }else if (sc2 == '8B' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '2B'){
        //Mit 2A(2) + 5.1C(1) eing || 2B + 6.1 alle + 8 alle +10-13+ 10 +11 +12 +13
        if(sc2 == '2A'){
          this.trgs = 1;
          this.trgsWarningNumber = 2;
        }else if(sc2 == '5.1C'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        }else if (sc2 == '2B' || sc2 == '6.1A' || sc2 == '6.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '3'){
        //Mit 5.1B(4) + 6.1D(6) + 10-13(5) + 11(5) eing ||2B, 3, 6.1A, 6.1C, 8A, 8B, 10, 12, 13
        if(sc2 == '5.1B') {
          this.trgs = 1;
          this.trgsWarningNumber = 4;
        }else if(sc2 == '6.1D'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        }else if(sc2 == '11' || sc2 == '10-13'){
          this.trgs = 1;
          this.trgsWarningNumber = 5;
        }else if(sc2 == '2B' || sc2 == '3' || sc2 == '6.1A' || sc2 == '6.1C' || sc2 == '8A' || sc2 == '8B'|| sc2 == '10' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '4.1A'){
        //Mit 4.1A, 4.1B, 5.2, 8A, 8B, 10-13, 10,11,12,13(1) eing
        if(sc2 == '4.1A' || sc2 == '4.1B' || sc2 == '5.2' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13') {
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '4.1B'){
        //Mit 4.1A(1) + 4.2,4.3(6) + 5.1B(4) + 5.2(1) + 6.1A(8) + 6.1D(6) eing || 4.1B + 6.1C + 8-13
        if(sc2 == '4.1A' || sc2 == '5.2'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        }else if(sc2 == '4.2' || sc2 == '4.3' || sc2 == '6.1D'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        }else if(sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 4;
        }else if(sc2 == '6.1D') {
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        }else if(sc2 == '4.1B' || sc2 == '6.1C' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '4.2'){
        //Mit 4.1B, 4.3, 6.1C, 6.1D, 8A, 8B 10-13, 10, 11 (6) eing || 4.2, 12, 13
        if(sc2 == '4.1B' || sc2 == '4.3' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11') {
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        }else if(sc2 == '4.2' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '4.3'){
        //Mit 4.1B, 4.2, 6.1C, 6.1D, 8A, 8B, 10-13, 10, 11, 12 eing ||4.3, 13
        if(sc2 == '4.1B' || sc2 == '4.3' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12') {
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        }else if(sc2 == '4.2' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '5.1A'){
        //5.1A, 5.1B, 12, 13
        if(sc2 == '5.1A' || sc2 == '5.1B' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '5.1B'){
        //Mit 3, 4.1B (3) + 5.1C(1) + 6.1A, 6.1B(4) + 6.1C, 6.1D(6) + 8A, 10-13, 10, 11 (7) eing || 5.1A, 5.1B, 8B, 12, 13
        if(sc2 == '3' || sc2 == '4.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 3;
        }else if(sc2 == '5.1C'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        }else if(sc2 == '6.1A' || sc2 == '6.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 4;
        }else if(sc2 == '6.1C' || sc2 == '6.1D') {
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        }else if(sc2 == '8A' || sc2 == '10-13' || sc2 == '10' || sc2 == '11'){
          this.trgs = 1;
          this.trgsWarningNumber = 7;
        }else if(sc2 == '5.1A' || sc2 == '5.1B' || sc2 == '8B' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '5.1C'){
        //Mit 2A, 2B, 5.1B, 5.1C, 8A, 8B, 10-13, 10, 11, 12, 13 (1) eing
        if(sc2 == '2A' || sc2 == '2B' || sc2 == '5.1B' || sc2 == '5.1C' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '5.2'){
        //Mit 4.1A, 4.1B, 10-13, 10, 11 (1) eing || 5.2, 12, 13
        if(sc2 == '4.1A' || sc2 == '4.1B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        }else if(sc2 == '5.2' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '6.1A'){
        //Mit 4.1B(8) + 5.1B(4), 10-13, 11 (5) eing || 2B, 3, 6.1A, 6.1B, 6.1C, 6.1D, 8A, 8B, 10, 12, 13
        if(sc2 == '4.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 8;
        } else if(sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 4;
        } else if(sc2 == '10-13' || sc2 == '11'){
          this.trgs = 1;
          this.trgsWarningNumber = 5;
        } else if(sc2 == '2B' || sc2 == '3' || sc2 == '6.1A' || sc2 == '6.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '6.1B'){
        //Mit 5.1B(4) + 10-13, 11 (5) eing || 2B, 6.1A, 6.1B, 6.1C, 6.1D, 8A, 8B, 10, 12, 13
        if(sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 4;
        } else if(sc2 == '10-13' || sc2 == '11'){
          this.trgs = 1;
          this.trgsWarningNumber = 5;
        } else if(sc2 == '2B' || sc2 == '6.1A' || sc2 == '6.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '6.1C'){
        //Mit 4.2, 4.3, 5.1B (6) eing || 2B, 3, 4.1B, 6.1A, 6.1B, 6.1C, 6.1D, 8A, 8B, 10-13, 10, 11, 12, 13
        if(sc2 == '4.2' || sc2 == '4.3' || sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        } else if(sc2 == '2B' || sc2 == '3' || sc2 == '4.1B' || sc2 == '6.1A' || sc2 == '6.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '6.1D'){
        //Mit 3, 4.1B, 4.2, 4.3, 5.1B (6) eing ||  2B, 4.1B, 6.1A, 6.1B, 6.1C, 6.1D, 8A, 8B, 10-13, 10, 11, 12, 13
        if(sc2 == '3' || sc2 == '4.1B' || sc2 == '4.2' || sc2 == '4.3' || sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        } else if(sc2 == '2B' || sc2 == '4.1B' || sc2 == '6.1A' || sc2 == '6.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '6.2'){
        //6.2
        if(sc2 == '6.2'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '7'){
        //Mit 7(1) eing
        if(sc2 == '7'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '8A'){
        //Mit 2A(2) + 4.1A(1) + 4.2, 4.3(6) + 5.1B(7) + 5.1C(1) eing. ||2B, 3, 4.1B, 6.1A, 6.1B, 6.1C, 6.1D, 8A, 8B, 10-13, 10, 11, 12, 13
        if(sc2 == '2A'){
          this.trgs = 1;
          this.trgsWarningNumber = 2;
        } else if(sc2 == '4.1A' || sc2 == '5.1C'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        } else if(sc2 == '4.2' || sc2 == '4.3'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        } else if(sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 7;
        } else if(sc2 == '2B' || sc2 == '3' || sc2 == '4.1B' || sc2 == '6.1A' || sc2 == '6.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '8B'){
        //Mit 4.1A(1) + 4.2, 4.3(6) + 5.1C(1) eing. || 2A, 2B, 3, 4.1B, 5.1B, 6.1A, 6.1B, 6.1C, 6.1D, 8A, 8B, 10-13, 10, 11, 12, 13
        if(sc2 == '4.1A' || sc2 == '5.1C'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        } else if(sc2 == '4.2' || sc2 == '4.3'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        } else if(sc2 == '2A' || sc2 == '2B' || sc2 == '3' || sc2 == '4.1B' || sc2 == '5.1B' || sc2 == '6.1A' || sc2 == '6.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '10'){
        //Mit 4.1A(1) + 4.2, 4.3(6) + 5.1B(7) + 5.1C, 5.2 (1) eing. ||2B, 3, 4.1B, 6.1A, 6.1B, 6.1C, 6.1D, 8A, 8B, 10-13, 10, 11, 12, 13
        if(sc2 == '4.1A' || sc2 == '5.1C' || sc2 == '5.2'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        } else if(sc2 == '4.2' || sc2 == '4.3'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        } else if(sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 7;
        } else if(sc2 == '2B' || sc2 == '3' || sc2 == '4.1B' || sc2 == '6.1A' || sc2 == '6.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '11'){
        //Mit Mit 2A(2)+ 3(5) + 4.1A(1) + 4.2, 4.3(6) + 5.1B(7) + 5.1C, 5.2(1) + 6.1A, 6.1B(5) eing. || 2B, 4.1B, 6.1C, 6.1D, 8A, 8B, 10-13, 10, 11, 12, 13
        if(sc2 == '2A'){
          this.trgs = 1;
          this.trgsWarningNumber = 2;
        } else if(sc2 == '3' || sc2 == '6.1A' || sc2 == '6.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 5;
        } else if(sc2 == '4.1A' || sc2 == '5.1C' || sc2 == '5.2'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        } else if(sc2 == '4.2' || sc2 == '4.3'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        } else if(sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 7;
        } else if(sc2 == '2B' || sc2 == '4.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '12'){
        //Mit 4.1A(1) + 4.3(6) + 5.1C(1) eing || alle außer 1, 6.2, 7
        if(sc2 == '4.1A' || sc2 == '5.1C'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        } else if(sc2 == '4.3'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        } else if(sc2 == '1' || sc2 == '6.2' || sc2 == '7'){
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '13'){
        //Mit 4.1A(1) + 5.1C(1) eing || alle außer 1, 6.2, 7
        if(sc2 == '4.1A' || sc2 == '5.1C'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        } else if(sc2 == '1' || sc2 == '6.2' || sc2 == '7'){
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        }
      }else if(sc1 == '10-13'){
        //Mit Mit 2A(2)+ 3(5) + 4.1A(1) + 4.2, 4.3(6) + 5.1B(7) + 5.1C, 5.2(1) + 6.1A, 6.1B(5) eing. || 2B, 4.1B, 6.1C, 6.1D, 8A, 8B, 10-13, 10, 11, 12, 13
        if(sc2 == '2A'){
          this.trgs = 1;
          this.trgsWarningNumber = 2;
        } else if(sc2 == '3' || sc2 == '6.1A' || sc2 == '6.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 5;
        } else if(sc2 == '4.1A' || sc2 == '5.1C' || sc2 == '5.2'){
          this.trgs = 1;
          this.trgsWarningNumber = 1;
        } else if(sc2 == '4.2' || sc2 == '4.3'){
          this.trgs = 1;
          this.trgsWarningNumber = 6;
        } else if(sc2 == '5.1B'){
          this.trgs = 1;
          this.trgsWarningNumber = 7;
        } else if(sc2 == '2B' || sc2 == '4.1B' || sc2 == '6.1C' || sc2 == '6.1D' || sc2 == '8A' || sc2 == '8B' || sc2 == '10-13' || sc2 == '10' || sc2 == '11' || sc2 == '12' || sc2 == '13'){
          this.trgs = 0;
          this.trgsWarningNumber = 0;
        } else{
          this.trgs = 2;
          this.trgsWarningNumber = 0;
        }
      }
    }
  }

}
