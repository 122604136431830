<div class="flex flex-col h-full w-full">
  <div class="flex flex-row w-full px-6 py-3.5 text-xl text-black justify-between items-center font-medium shadow-full-new">
    Tasks
    <div class="flex flex-row gap-4 whitespace-nowrap">
      <bic-button [bicDropdownTrigger]="dropdownBcf" [text]="'BCF Tasks'" [textColor]="'black'" [color]="'white'" [border]="true" [iconLeft]="faIfc"></bic-button>
      <bic-dropdown #dropdownBcf [dropdownPlacement]="'bottom-start'">
        <bic-dropdown-element (onClick)="bcfUpload.click()">
          <div class="flex flex-row text-sm gap-3 text-gray-700">
            <fa-icon class="text-gray-700" [icon]="faArrowDownToBracket"></fa-icon>
            <span>BCF Import</span>
          </div>
        </bic-dropdown-element>
        <bic-dropdown-element>
          <div class="flex flex-row text-sm gap-3 text-gray-700">
            <fa-icon class="text-gray-700" [icon]="faArrowUpFromBracket"></fa-icon>
            <span>BCF Export</span>
          </div>
        </bic-dropdown-element>
      </bic-dropdown>
<!--      Filter noch ausgeklammert-->
      <bic-button [bicDropdownTrigger]="dropdownFilter" [text]="'Alle Anzeigen'" [textColor]="'black'" [color]="'white'" [border]="true" [iconLeft]="faFilter" *ngIf="false"></bic-button>
      <bic-dropdown #dropdownFilter [dropdownPlacement]="'bottom-start'" *ngIf="false">
        <bic-dropdown-element>
          <div class="flex flex-row text-sm gap-3 text-black">
            <div class="flex items-center">
              <input id="checkbox-filter-1" type="checkbox"
                     class="w-4 h-4 rounded text-primary-600 checked:bg-primary focus:ring-[none] bg-gray-100 border-gray-300">
              <label for="checkbox-filter-1" class="sr-only">checkbox</label>
            </div>
            <span>Alle Anzeigen</span>
          </div>
        </bic-dropdown-element>
        <bic-dropdown-element>
          <div class="flex flex-row text-sm gap-3 text-black">
            <div class="flex items-center">
              <input id="checkbox-filter-2" type="checkbox"
                     class="w-4 h-4 rounded text-primary-600 checked:bg-primary focus:ring-[none] bg-gray-100 border-gray-300">
              <label for="checkbox-filter-2" class="sr-only">checkbox</label>
            </div>
            <span>Basic Tasks</span>
          </div>
        </bic-dropdown-element>
        <bic-dropdown-element>
          <div class="flex flex-row text-sm gap-3 text-black">
            <div class="flex items-center">
              <input id="checkbox-filter-3" type="checkbox"
                     class="w-4 h-4 rounded text-primary-600 checked:bg-primary focus:ring-[none] bg-gray-100 border-gray-300">
              <label for="checkbox-filter-3" class="sr-only">checkbox</label>
            </div>
            <span>BCF Tasks</span>
          </div>
        </bic-dropdown-element>
      </bic-dropdown>
<!--      Suche noch deaktiviert-->
      <div class="w-full" *ngIf="false">
        <form class="flex items-center">
          <label for="simple-search" class="sr-only">Suche</label>
          <div class="relative w-full">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                   fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"/>
              </svg>
            </div>
            <input type="text" id="simple-search"
                   class="border block w-full pl-10 p-2 bg-gray-50 border-gray-300 text-gray-900 text-sm rounded-lg ring-primary focus:ring-gray-300 focus:border-gray-300 "
                   placeholder="Suche">
          </div>
        </form>
      </div>

    </div>
  </div>
<!--  <div class="flex flex-row justify-start items-center text-gray-400 py-2 px-4">-->
<!--    <button (click)="changeViewType(viewType.LIST)" [class.active]="activeLinkIndex == viewType.LIST" class="mr-4">Tabelle</button>-->
<!--    <button (click)="changeViewType(viewType.BOARD)" [class.active]="activeLinkIndex == viewType.BOARD">Board</button>-->
<!--  </div>-->
<!--  <div class="h-1 bg-gray-400 shadow"></div>-->
<!--  <div class="h-4"></div>-->
  <div class="flex flex-grow h-full overflow-hidden">
    <div class="flex flex-grow p-6">
      <app-task-list #applist class="w-full" *ngIf="activeLinkIndex == viewType.LIST" (importBCFClicked)="bcfUpload.click()" (exportBCFClicked)="exportBCF($event)"></app-task-list>
      <app-task-board #board class="w-full" *ngIf="activeLinkIndex == viewType.BOARD" (importBCFClicked)="bcfUpload.click()" (exportBCFClicked)="exportBCF($event)" (exportBCFsClicked)="exportBCFs($event)"></app-task-board>
    </div>
  </div>
</div>
<input hidden #bcfUpload type="file" accept=".bcf, .bcfzip" (change)="importBCF($event)">
