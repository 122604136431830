<div class="w-full px-2 pt-4">
  <div class="w-full flex border-2 border-gray-200 rounded-md border-solid">
    <div class="p-1">
      <button (click)="clickedIFCFilter()" class="rounded-md py-2 px-2 h-[38px] w-[38px]"
              [ngClass]="{ 'bg-secondary text-gray-100': this.chatService.ifcMessage || this.ifcButtonHover, 'bg-gray-200 text-[#042e3f]': !this.chatService.ifcMessage && !this.ifcButtonHover }"
              (mouseenter)="ifcButtonHover = true" (mouseleave)="ifcButtonHover = false"
              data-tooltip-target="tooltip-ifc" data-tooltip-placement="left" type="button">
        <mat-icon svgIcon="ifc-icon" class="flex !w-5 !h-5 !text-inherit"></mat-icon>
      </button>

      <div id="tooltip-ifc" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-gray-900 bg-gray-50 border border-gray-100 rounded-lg shadow-sm opacity-0 tooltip">
        <p *ngIf="this.chatService.ifcMessage">IFC-Filter an</p><p *ngIf="!this.chatService.ifcMessage">IFC-Filter aus</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>

    </div>
    <input [(ngModel)]="chatService.question" matInput class="w-full bg-inherit p-2 pl-4 focus:outline-none" (keyup.enter)="askQuestion(chatService.question);$event.stopPropagation()"
           [placeholder]="messagePlaceholder()">
    <button (click)="askQuestion(chatService.question);$event.stopPropagation()" class="px-2">
     <fa-icon class="hover:text-gray-300 text-gray-200 text-2xl" [icon]="faCircleArrowRight"></fa-icon>
   </button>

  </div>
  <div class="flex flex-row">
    <div *ngFor="let filters of chatService.getFilter().lph; index as i" class="pt-2 pr-2">
      <div (mouseenter)="hovering[i] = true" (mouseleave)="hovering[i] = false"
           [ngClass]="{'pr-4 pl-4': !hovering[i]}"
           class="flex flex-row justify-center rounded-md bg-gray-100 text-gray-900 hover:bg-gray-200 p-1">
        <p [ngClass]="{'pl-0.5': !hovering[i]}">{{ filters }}</p>
        <button *ngIf="hovering[i]" (click)="chatService.removeFilter(filters, 'lph')">
          <fa-icon class="pl-2.5 pr-1 text-gray-900
             text-sm" [icon]="faX"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="pb-8"></div>
</div>


