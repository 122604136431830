import {Component, Inject, OnInit} from '@angular/core';
import {
  Organisation,
  Project, ProjectControllerService,
  User,
  UserControllerService
} from "../../../../api";
import {PermissionService} from "../../../../services/permission/permission.service";
import {ActivatedRoute} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-user-permissions-dialog',
  templateUrl: './user-permissions-dialog.component.html',
  styleUrls: ['./user-permissions-dialog.component.scss']
})
export class UserPermissionsDialogComponent implements OnInit {

  user!: User;
  project!: Project;
  organisation!: Organisation;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { user: User, isProject: boolean, route: ActivatedRoute },
              public dialogRef: MatDialogRef<UserPermissionsDialogComponent>,
              public permissionService: PermissionService,
              public projectControllerService: ProjectControllerService,
              public userControllerService: UserControllerService) {
  }

  ngOnInit() {
    this.data.route.parent?.data.subscribe(data => {
      this.project = data.project;
      this.organisation = data.organisation;
    });

    this.user = this.data.user;

    if (this.data.isProject) {
}

  }

  saveUser() {

  }
}
