<div class="p-6 overflow-auto overflow-y-scroll bg-white rounded-2xl shadow-item">
  <div class="flex flex-col justify-start items-center gap-4">

    <!-- Headline warning -->
    <div class="text-error font-medium">Möchten Sie die nachfolgenden Projekte wirklich löschen?</div>
    <!--        Sind Sie sicher, dass Sie die nachfolgenden Projekte löschen wollen?-->


    <div class="flex flex-col justify-center items-center">
      <mat-chip-listbox class="justify-between items-center">
        <ng-container *ngIf="data.projects">
          <mat-chip-option *ngFor="let project of data.projects">{{project.name}}</mat-chip-option>
        </ng-container>

      </mat-chip-listbox>
    </div>

    <!-- Explanation text -->
    <div class="flex flex-col justify-start items-center px-3">
      <!-- Hint headline -->
      <div class="text-primary">Hinweis:</div>

      <!-- Hint text -->
      <div class="break-all px-3">Die oben aufgeführten Projekte werden bei Bestätigung gelöscht.<br>
        Dazu halten Sie den Löschen-Knopf für <span class="text-primary">3 Sekunden</span> gedrückt.<br>
        Bei weiteren Fragen wenden Sie sich an unser Support-Team.</div>

    </div>



    <div class="flex flex-row justify-between w-full items-center pt-2">
      <button class="w-[20%]" color="white" mat-dialog-close mat-raised-button>
        <span>Abbrechen</span>
      </button>
      <button class="w-[20%]" (holdTime)="holdHandler($event)" appHoldable color="primary" id="deleteButton" mat-raised-button
              [matTooltip]="'Gedrückt halten'">
        <span>Löschen</span>
      </button>

    </div>

  </div>

</div>





<!--<mat-dialog-content>-->


<!--  <h4 style="text-align: center">Sind Sie sicher, dass Sie die nachfolgenden Projekte löschen wollen?</h4>-->

<!--  <mat-chip-list class="justify-between items-center">-->
<!--    <ng-container *ngIf="data.projects">-->
<!--      <mat-chip *ngFor="let project of data.projects">Projekt "{{project.name}}"</mat-chip>-->
<!--    </ng-container>-->

<!--  </mat-chip-list>-->

<!--  <br><br>-->

<!--  <div fxLayout="row" fxLayoutAlign="space-between center">-->
<!--    <button color="primary" mat-dialog-close mat-raised-button-->
<!--            matTooltip="Kehren Sie zurück">-->
<!--      Abbrechen-->
<!--    </button>-->
<!--    <button (holdTime)="holdHandler($event)" appHoldable color="primary" id="deleteButton" mat-raised-button-->
<!--            matTooltip="Zum Löschen gedrückt halten">-->
<!--      <span>Löschen</span>-->
<!--    </button>-->
<!--  </div>-->
<!--</mat-dialog-content>-->
