import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-unsavedChangesDialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent implements OnInit {

  result: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: {}, public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>) { }

  ngOnInit(): void {
  }

  checkClicked(){
    this.dialogRef.close("check");
  }

  discardClicked(){
    this.dialogRef.close("discard");
  }

  cancelClicked(){
    this.dialogRef.close("cancel");
  }

}
