import {Injectable} from '@angular/core';
import {
  CheckResults,
  CompletenessCheck, Organisation,
  Project, ProjectControllerService,
  ProjectServiceNotification,
} from "../../api";
import {LphPipe, LphServicePipe} from "../../pipes/lph.pipe";
import {AspectPipe, ServicePipe} from "../../pipes/service.pipe";
import LphEnum = ProjectServiceNotification.LphEnum;
import {LoadingService} from "../loading/loading.service";
import {CompactPartialServiceEvaluationResult} from "../../api";
import {CompactServiceAspectEvaluationResult} from "../../api";
import PartialServiceNamesEnum = CompletenessCheck.PartialServiceNamesEnum;
import {StateConfigService} from "../state/state-config.service";
import {Observable} from "rxjs";
import autoprefixer from "autoprefixer";

function assembleDateInformation() {
  let date = new Date();
  return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
}

function findInlineHeight(cell: any, maxWidth: any, usedWidth = 0) {

  function mapTableBodies(innerTableCell: any) {
    const inlineHeight: any = findInlineHeight(
      innerTableCell,
      maxWidth,
      usedWidth
    );

    usedWidth = inlineHeight.width;
    return inlineHeight.height;
  }

  let calcLines = (inlines: any) => {
    if (!inlines)
      return {
        height: 0,
        width: 0,
      };
    let currentMaxHeight = 0;
    let lastHadLineEnd = false;
    for (const currentNode of inlines) {
      usedWidth += currentNode.width;
      if (usedWidth > maxWidth || lastHadLineEnd) {
        currentMaxHeight += currentNode.height;
        usedWidth = currentNode.width;
      } else {
        currentMaxHeight = Math.max(currentNode.height, currentMaxHeight);
      }
      lastHadLineEnd = !!currentNode.lineEnd;
    }
    return {
      height: currentMaxHeight,
      width: usedWidth,
    };
  }
  if (cell._offsets) {
    usedWidth += cell._offsets.total;
  }
  if (cell._inlines && cell._inlines.length) {
    return calcLines(cell._inlines);
  }  else if (cell.stack && cell.stack[0]) {
    // @ts-ignore
    return cell.stack.map(item => {
      return findInlineHeight(item, maxWidth);
    }).reduce((prev: any, next: any) => {
      return {
        height: prev.height + next.height,
        width: Math.max(prev.width + next.width)
      };
    });
  } else if (cell.table) {
    let currentMaxHeight = 0;
    for (const currentTableBodies of cell.table.body) {
      const innerTableHeights = currentTableBodies.map(mapTableBodies);
      currentMaxHeight = Math.max(...innerTableHeights, currentMaxHeight);
    }
    return {
      height: currentMaxHeight,
      width: usedWidth,
    };
  } else if (cell._height) {
    usedWidth += cell._width;
    return {
      height: cell._height,
      width: usedWidth,
    };
  }

  return {
    height: null,
    width: usedWidth,
  };
}


function applyVerticalAlignment(node: any, rowIndex: number, align: any, manualHeight = 0) { // New default argument
  const allCellHeights = node.table.body[rowIndex].map(
    (innerNode: any, columnIndex: any) => {
      if (innerNode._span) return null
      const calcWidth = [...Array(innerNode.colSpan || 1).keys()].reduce((acc, i) => {
        return acc + node.table.widths[columnIndex + i]._calcWidth
      }, 0)
      const mFindInlineHeight = findInlineHeight(innerNode, calcWidth, 0)
      return mFindInlineHeight.height;
    }
  );
  // @ts-ignore
  const maxRowHeight = manualHeight ? manualHeight[rowIndex] : Math.max(...allCellHeights); // handle manual height
  node.table.body[rowIndex].forEach((cell: any, ci: any) => {
    if (allCellHeights[ci] && maxRowHeight > allCellHeights[ci]) {
      let topMargin;

      let cellAlign = align;
      if (Array.isArray(align)) {
        cellAlign = align[ci];
      }

      if (cellAlign === 'bottom') {
        topMargin = maxRowHeight - allCellHeights[ci];
      } else if (cellAlign === 'center') {
        topMargin = (maxRowHeight - allCellHeights[ci]) / 2;
      }

      if (topMargin) {
        if (cell._margin) {
          cell._margin[1] = topMargin;
        } else {
          cell._margin = [0, topMargin, 0, 0];
        }
      }
    }
  });
}


@Injectable({
  providedIn: 'root'
})

export class PdfService {
  pdfMake: any;

  reportBlob!: String;
  reportBase64!: String;

  icons: any = {
    succeed: "<?xml version=\"1.0\" encoding=\"UTF-8\"?><svg id=\"Ebene_1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 100 100\"><defs><style>.cls-1{fill:#008c8c;}.cls-2{fill:#fff;}</style></defs><circle class=\"cls-1\" cx=\"50\" cy=\"50\" r=\"40\"/><g><rect class=\"cls-2\" x=\"36.71\" y=\"47.99\" width=\"50\" height=\"8\" transform=\"translate(-14.17 79.44) rotate(-60)\"/><polygon class=\"cls-2\" points=\"28.09 52.21 24.09 59.14 45.74 71.64 45.74 71.64 49.74 64.71 28.09 52.21\"/></g></svg>",
    failed: "<?xml version=\"1.0\" encoding=\"UTF-8\"?><svg id=\"Ebene_1\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 100 100\"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#932525;}</style></defs><circle class=\"cls-2\" cx=\"50\" cy=\"50\" r=\"40\"/><g><rect class=\"cls-1\" x=\"25\" y=\"46\" width=\"50\" height=\"8\" transform=\"translate(-20.71 50) rotate(-45)\"/><rect class=\"cls-1\" x=\"46\" y=\"25\" width=\"8\" height=\"50\" transform=\"translate(-20.71 50) rotate(-45)\"/></g></svg>"
  }

  fonts = {
    Raleway: {
      normal: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/Raleway-Regular.ttf',
      bold: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/Raleway-Bold.ttf',
      italics: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/Raleway-Italic.ttf',
      bolditalics: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/Raleway-BoldItalic.ttf'
    },
    MaterialIcons: {
      normal: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/MaterialIcons-Regular.ttf',
      bold: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/MaterialIcons-Regular.ttf',
      italics: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/MaterialIcons-Regular.ttf',
      bolditalics: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/MaterialIcons-Regular.ttf'
    }
  }

  constructor(private lphPipe: LphPipe, private servicePipe: ServicePipe,
              private stateConfigService: StateConfigService,
              private loadingService: LoadingService,
              private projectControllerService: ProjectControllerService,
              private aspectPipe: AspectPipe,
              private lphServicePipe: LphServicePipe) {
  }

  async loadPdfMaker(): Promise<void> {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule;
    }
  }

  downloadReport(project: Project, organisation: Organisation, lph: LphEnum, check: CompletenessCheck): void {
    this.generateReportPdf(project, organisation, lph, check).then(pdf => {
      pdf.download('report.pdf');
    });
  }

  async getReportBase64(project: Project, organisation: Organisation,  lph: LphEnum, check: CompletenessCheck): Promise<void> {
    let pdf = await this.generateReportPdf(project, organisation, lph, check)
    pdf.getBase64((b: any) => {
      this.reportBase64 = b;
    });
  }

  async getReportBlob(project: Project, organisation: Organisation, lph: LphEnum, check: CompletenessCheck): Promise<void> {
    let pdf = await this.generateReportPdf(project, organisation, lph, check)
    pdf.getBlob((b: any) => {
      this.reportBlob = URL.createObjectURL(b);
    });
  }



  calculateSucceedPercentage(check: CompletenessCheck): number {
    // @ts-ignore
    return Math.round((check.checkResults?.reduce((acc: number, cur: { succeed: any; }) => cur.succeed ? acc + 1 : acc, 0) ?? 0)/ check.partialServiceNames?.length * 100)
  }

  test(): string{
    return this.stateConfigService.selectedOrganisation.name ?? '';
  }


  createStandardResultTable(check: CompletenessCheck): any {
    var partialServices = check.partialServiceNames;
    var standardServices = partialServices?.filter((service: any) => service.toString().startsWith('GL')) ?? [];
    standardServices.sort();
    var standardResults: boolean[] = [];
    for (let i = 0; i < standardServices.length; i++) {
      let found = false;
      for (let checkResult of check.checkResults!) {
        if(standardServices[i].toString() === checkResult.partialServiceName){
          standardResults.push(checkResult.succeed!);
          found = true;
        }
      }
      if(!found){
        standardResults.push(false);
      }
    }
    var standardResultTable = new Array(standardServices.length+1);


    standardResultTable[0] = [
      {
        margin: [5, 7, 0, 0],
        border: [true, true, false, true],
        text: 'Grundleistungen',
        fontSize: 12,
        bold: true,
      }, {
        margin: [5, 7, 0, 0],
        border: [false, true, false, true],
        text: 'Status',
        fontSize: 12,
        bold: true,
        alignment: 'center',
      }, {
        margin: [5, 7, 0, 0],
        border: [false, true, true, true],
        text: 'Seite',
        fontSize: 12,
        bold: true,
        alignment: 'center',
      }
    ];

    for (let i = 1; i < standardResultTable.length; i++) {
      //Element is first and last: Some space on top and bottom as well as bottom line
      if (i == 1 && i == standardResultTable.length-1) {
        standardResultTable[i] = [
          {
            margin: [5, 5, 0, 5],
            border: [true, false, false, true],
            text: this.servicePipe.transform(standardServices[i-1]),
            fontSize: 12,
          }, {
            margin: [5, 5, 0, 5],
            border: [false, false, false, true],
            image: standardResults[i-1] ? 'successIcon' : 'errorIcon',
            width: 15,
            alignment: 'center',
          }, {
            margin: [5, 5, 0, 5],
            border: [false, false, true, true],
            pageReference: standardServices[i-1],
            fontSize: 12,
            alignment: 'center',
          },
        ];
      }
      //Element is first but not last: Some space on top
      else if (i == 1 && i < standardResultTable.length-1) {
        standardResultTable[i] = [
          {
            margin: [5, 5, 0, 0],
            border: [true, false, false, false],
            text: this.servicePipe.transform(standardServices[i-1]),
            fontSize: 12,
          }, {
            margin: [5, 5, 0, 0],
            border: [false, false, false, false],
            image: standardResults[i-1] ? 'successIcon' : 'errorIcon',
            width: 15,
            alignment: 'center',
          }, {
            margin: [5, 5, 0, 0],
            border: [false, false, true, false],
            pageReference: standardServices[i-1],
            fontSize: 12,
            alignment: 'center',
          },
        ];
      }
      //Element is last but not first: Some space on bottom as well as bottom line
      else if (i != 1 && i == standardResultTable.length-1) {
        standardResultTable[i] = [
          {
            margin: [5, 0, 0, 5],
            border: [true, false, false, true],
            text: this.servicePipe.transform(standardServices[i-1]),
            fontSize: 12,
          }, {
            margin: [5, 0, 0, 5],
            border: [false, false, false, true],
            image: standardResults[i-1] ? 'successIcon' : 'errorIcon',
            width: 15,
            alignment: 'center',
          }, {
            margin: [5, 0, 0, 5],
            border: [false, false, true, true],
            pageReference: standardServices[i-1],
            fontSize: 12,
            alignment: 'center',
          },
        ];
      }
      //Element is neither first nor last: No space on top or bottom
      else {
        standardResultTable[i] = [
          {
            margin: [5, 0, 0, 0],
            border: [true, false, false, false],
            text: this.servicePipe.transform(standardServices[i-1]),
            fontSize: 12,
          }, {
            margin: [5, 0, 0, 0],
            border: [false, false, false, false],
            image: standardResults[i-1] ? 'successIcon' : 'errorIcon',
            width: 15,
            alignment: 'center',
          }, {
            margin: [5, 0, 0, 0],
            border: [false, false, true, false],
            pageReference: standardServices[i-1],
            fontSize: 12,
            alignment: 'center',
          },
        ];
      }
    }

    return standardResultTable;
  }

  createSpecialResultTable(check: CompletenessCheck): any {
    var partialServices = check.partialServiceNames;
    var specialServices = partialServices?.filter((service: any) => service.toString().startsWith('BL')) ?? [];
    specialServices.sort();
    var specialResults: boolean[] = [];
    for (let i = 0; i < specialServices.length; i++) {
      let found = false;
      for (let checkResult of check.checkResults!) {
        if(specialServices[i].toString() === checkResult.partialServiceName){
          specialResults.push(checkResult.succeed!);
          found = true;
        }
      }
      if(!found){
        specialResults.push(false);
      }
    }
    var specialResultTable = new Array(specialServices.length+1);

    specialResultTable[0] = [
      {
        margin: [5, 7, 0, 0],
        border: [true, true, false, true],
        text: 'Besondere Leistungen',
        fontSize: 12,
        bold: true,
      }, {
        margin: [5, 7, 0, 0],
        border: [false, true, false, true],
        text: 'Status',
        fontSize: 12,
        bold: true,
        alignment: 'center',
      }, {
        margin: [5, 7, 0, 0],
        border: [false, true, true, true],
        text: 'Seite',
        fontSize: 12,
        bold: true,
        alignment: 'center',
      }
    ];

    for (var i = 1; i < specialResultTable.length; i++) {
      //Element is first and last: Some space on top and bottom as well as bottom line
      if (i == 1 && i == specialResultTable.length-1) {
        specialResultTable[i] = [
          {
            margin: [5, 5, 0, 5],
            border: [true, false, false, true],
            text: this.servicePipe.transform(specialServices[i-1]),
            fontSize: 12,
          }, {
            margin: [5, 5, 0, 5],
            border: [false, false, false, true],
            image: specialResults[i-1] ? 'successIcon' : 'errorIcon',
            width: 15,
            alignment: 'center',
          }, {
            margin: [5, 5, 0, 5],
            border: [false, false, true, true],
            pageReference: specialServices[i-1],
            fontSize: 12,
            alignment: 'center',
          },
        ];
      }
      //Element is first but not last: Some space on top
      else if (i == 1 && i < specialResultTable.length-1) {
        specialResultTable[i] = [
          {
            margin: [5, 5, 0, 0],
            border: [true, false, false, false],
            text: this.servicePipe.transform(specialServices[i-1]),
            fontSize: 12,
          }, {
            margin: [5, 5, 0, 0],
            border: [false, false, false, false],
            image: specialResults[i-1] ? 'successIcon' : 'errorIcon',
            width: 15,
            alignment: 'center',
          }, {
            margin: [5, 5, 0, 0],
            border: [false, false, true, false],
            pageReference: specialServices[i-1],
            fontSize: 12,
            alignment: 'center',
          },
        ];
      }
      //Element is last but not first: Some space on bottom as well as bottom line
      else if (i != 1 && i == specialResultTable.length-1) {
        specialResultTable[i] = [
          {
            margin: [5, 0, 0, 5],
            border: [true, false, false, true],
            text: this.servicePipe.transform(specialServices[i-1]),
            fontSize: 12,
          }, {
            margin: [5, 0, 0, 5],
            border: [false, false, false, true],
            image: specialResults[i-1] ? 'successIcon' : 'errorIcon',
            width: 15,
            alignment: 'center',
          }, {
            margin: [5, 0, 0, 5],
            border: [false, false, true, true],
            pageReference: specialServices[i-1],
            fontSize: 12,
            alignment: 'center',
          },
        ];
      }
      //Element is neither first nor last: No space on top or bottom
      else {
        specialResultTable[i] = [
          {
            margin: [5, 0, 0, 0],
            border: [true, false, false, false],
            text: this.servicePipe.transform(specialServices[i-1]),
            fontSize: 12,
          }, {
            margin: [5, 0, 0, 0],
            border: [false, false, false, false],
            image: specialResults[i-1] ? 'successIcon' : 'errorIcon',
            width: 15,
            alignment: 'center',
          }, {
            margin: [5, 0, 0, 0],
            border: [false, false, true, false],
            pageReference: specialServices[i-1],
            fontSize: 12,
            alignment: 'center',
          },
        ];
      }
    }

    return specialResultTable;
  }

  createAspectsTable(arr: any, succeed: boolean): any {
    let result = [];
    for(let i = 0; i < arr.aspects.length; i++) {
      if(arr.aspects[i].succeed === succeed){
        result.push(
          {
            text: this.aspectPipe.transform(arr.aspects[i].serviceAspectName ?? ''),
            fontSize: 12,
            alignment: 'left',
            lineHeight: 1.25,
          });
      }
    }
    return result;
  }



  createDetails(check: CompletenessCheck) : any {
    var standardServices = check.partialServiceNames?.filter((service: any) => service.toString().startsWith('GL')) ?? [];
    var specialServices = check.partialServiceNames?.filter((service: any) => service.toString().startsWith('BL')) ?? [];
    standardServices.sort();
    specialServices.sort();
    var partialServices = standardServices.concat(specialServices);
    var details: any[] = [];
    var serviceResults: boolean[] = [];
    for (let i = 0; i < partialServices.length; i++) {
      let found = false;
      for (let checkResult of check.checkResults!) {
        if(partialServices[i].toString() === checkResult.partialServiceName){
          serviceResults.push(checkResult.succeed!);
          found = true;
        }
      }
      if(!found){
        serviceResults.push(false);
      }
    }
    let serviceDocumentArray  = [];
    let serviceDocumentIndexMap = new Map();
    let index = 0;
    for (let service of partialServices) {
      serviceDocumentArray.push({service: service, documents: []});
      serviceDocumentIndexMap.set(service, index);
      index++;
    }
    for (let checkResult of check.checkResults!) {
      // serviceDocumentMap.get(service)?.push(checkResult.succeedDocuments!);
      // for (let innerCheckResult of checkResult.checkResults!){
      //   as<dfasd
      //     let documentID = innerCheckResult.s3KeyOfAnalyzedDocument!.split('/')[2].split('.')[0];
      // }

      for (let i = 0; i < checkResult.checkResults!.length; i++){
        let filename = checkResult.succeedDocuments![i].name;
        let checkSuccess = checkResult.checkResults![i].succeed;
        let aspects = checkResult.checkResults![i].serviceAspectEvaluationResults;
        let result = {
          filename: filename,
          checkSuccess: checkSuccess,
          aspects: aspects
        }
        // @ts-ignore
        serviceDocumentArray[serviceDocumentIndexMap.get(checkResult.partialServiceName)]?.documents.push(result);
        // serviceDocumentArray.get(checkResult.partialServiceName)?.push(result);
      }

    }

    //var specialServices = partialServices?.filter((service: any) => service.toString().startsWith('BL')) ?? [];
    // @ts-ignore
    //var specialResults: boolean[] = check.checkResults?.filter((result: any) => result.partialServiceName.startsWith('BL') && result.succeed);

    var checkResultLength = check.checkResults!.length;

    //gray service titles
    for (let i = 0; i < serviceDocumentArray.length; i++) {
      details.push([{
          margin: [0, 8, 0, 0],
          table: {
            widths: ['auto', '*'],
            heights: [26],
            body: [
              [{
                margin: [5, 7, 0, 0],
                border: [true, true, false, true],
                text: this.servicePipe.transform(serviceDocumentArray[i].service),
                fontSize: 12,
                bold: true,
                alignment: 'left',
                fillColor: '#E2E2E2',
                id: serviceDocumentArray[i].service,
              }, {
                margin: [5, 6, 0, 0],
                border: [false, true, true, true],
                image: serviceResults[i] ? 'successIcon' : 'errorIcon',
                width: 15,
                alignment: 'left',
                fillColor: '#E2E2E2',
              }]
            ],
          },
          layout: {
            hLineColor: '#E2E2E2',
            vLineColor: '#E2E2E2',
            defaultBorder: false,
            paddingLeft: function(i: any, node: any) { return 5; },
            paddingRight: function(i: any, node: any) { return 5; },
            paddingTop: function(i: any, node: any) { return 5; },
            paddingBottom: function(i: any, node: any) { return 5; },
          },
        }]
      )
      //Documents for each detail
      if(serviceDocumentArray[i].documents.length > 0) {
        for (let j = 0; j < serviceDocumentArray[i].documents.length; j++) {
          details.push([
            //Row 1
            {
              margin: [0, 15, 0, 0],
              table: {
                widths: [90, 'auto', '*'],
                heights: [22],
                body: [
                  [{
                    margin: [5, 5, 0, 0],
                    border: [true, true, false, true],
                    text: 'Dokument:',
                    fontSize: 12,
                    bold: true,
                    alignment: 'left',
                  }, {
                    margin: [5, 5, 0, 0],
                    border: [false, true, false, true],
                    text: serviceDocumentArray[i].documents[j]['filename'],
                    fontSize: 12,
                    alignment: 'left',
                  }, {
                    margin: [5, 5, 0, 0],
                    border: [false, true, true, true],
                    image: serviceDocumentArray[i].documents[j]['checkSuccess'] ? 'successIcon' : 'errorIcon',
                    width: 15,
                    alignment: 'left',

                  }]
                ],
              },
              layout: {
                hLineColor: '#99A09C',
                vLineColor: '#99A09C',
                defaultBorder: false,
                paddingLeft: function (i: any, node: any) {
                  return 5;
                },
                paddingRight: function (i: any, node: any) {
                  return 5;
                },
                paddingTop: function (i: any, node: any) {
                  return 5;
                },
                paddingBottom: function (i: any, node: any) {
                  return 5;
                },
              },
            }]);
            //Row 2: successes
          if(this.createAspectsTable(serviceDocumentArray[i].documents[j], true).length > 0){
            details.push([{
              margin: [0, 0, 0, 0],
              table: {
                widths: [78, '*'],
                //heights: [22],
                body: [
                  [{
                    margin: [5, 5, 0, 0],
                    border: [true, false, false, false],
                    text: 'Bestanden:',
                    fontSize: 12,
                    alignment: 'left',
                    color: '#2CA09A',
                  }, {
                    margin: [5, 6, 5, 0],
                    border: [false, false, true, false],
                    ul: this.createAspectsTable(serviceDocumentArray[i].documents[j], true),
                    color: '#2CA09A',
                  }]
                ],
              },
              layout: {
                hLineColor: '#99A09C',
                vLineColor: '#99A09C',
                defaultBorder: false,
                paddingLeft: function (i: any, node: any) {
                  return 5;
                },
                paddingRight: function (i: any, node: any) {
                  return 5;
                },
                paddingTop: function (i: any, node: any) {
                  return 5;
                },
                paddingBottom: function (i: any, node: any) {
                  return 5;
                },
              },
            }]);
          }
            //Row 3: errors
          if(this.createAspectsTable(serviceDocumentArray[i].documents[j], false).length > 0){
            details.push([{
              margin: [0, 0, 0, 0],
              table: {
                widths: [78, '*'],
                //heights: [22],
                body: [
                  [{
                    margin: [5, 5, 0, 0],
                    border: [true, false, false, false],
                    text: 'Fehler:',
                    fontSize: 12,
                    alignment: 'left',
                    color: '#EE3B3B',
                  }, {
                    margin: [5, 6, 5, 0],
                    border: [false, false, true, false],
                    ul: this.createAspectsTable(serviceDocumentArray[i].documents[j], false),
                    color: '#EE3B3B',
                  }]
                ],
              },
              layout: {
                hLineColor: '#99A09C',
                vLineColor: '#99A09C',
                defaultBorder: false,
                paddingLeft: function (i: any, node: any) {
                  return 5;
                },
                paddingRight: function (i: any, node: any) {
                  return 5;
                },
                paddingTop: function (i: any, node: any) {
                  return 5;
                },
                paddingBottom: function (i: any, node: any) {
                  return 5;
                },
              },
            }]);
          }

            //Finisher Line
          details.push([{
              margin: [0, 0, 0, 0],
              table: {
                widths: ['*'],
                //heights: [22],
                body: [
                  [{
                    margin: [0, 0, 0, 0],
                    border: [false, true, false, false],
                    text: '',
                    color: '#2CA09A',
                  }]
                ],
              },
              layout: {
                hLineColor: '#99A09C',
                vLineColor: '#99A09C',
                defaultBorder: false,
                paddingLeft: function (i: any, node: any) {
                  return 5;
                },
                paddingRight: function (i: any, node: any) {
                  return 5;
                },
                paddingTop: function (i: any, node: any) {
                  return 5;
                },
                paddingBottom: function (i: any, node: any) {
                  return 5;
                },
              },
            }])
        }
      }else{
        details.push([
          //Row 1
          {
            margin: [0, 15, 0, 0],
            table: {
              widths: [90, '*'],
              heights: [22],
              body: [
                [{
                  margin: [5, 5, 0, 0],
                  border: [true, true, false, true],
                  text: 'Dokument:',
                  fontSize: 12,
                  bold: true,
                  alignment: 'left',
                }, {
                  margin: [5, 5, 0, 0],
                  border: [false, true, true, true],
                  text: 'Nicht gefunden',
                  fontSize: 12,
                  alignment: 'left',
                }]
              ],
            },
            layout: {
              hLineColor: '#99A09C',
              vLineColor: '#99A09C',
              defaultBorder: false,
              paddingLeft: function (i: any, node: any) {
                return 5;
              },
              paddingRight: function (i: any, node: any) {
                return 5;
              },
              paddingTop: function (i: any, node: any) {
                return 5;
              },
              paddingBottom: function (i: any, node: any) {
                return 5;
              },
            },
          },
          //Finisher Line
          {
            margin: [0, 0, 0, 0],
            table: {
              widths: ['*'],
              //heights: [22],
              body: [
                [{
                  margin: [0, 0, 0, 0],
                  border: [false, true, false, false],
                  text: '',
                  color: '#2CA09A',
                }]
              ],
            },
            layout: {
              hLineColor: '#99A09C',
              vLineColor: '#99A09C',
              defaultBorder: false,
              paddingLeft: function (i: any, node: any) {
                return 5;
              },
              paddingRight: function (i: any, node: any) {
                return 5;
              },
              paddingTop: function (i: any, node: any) {
                return 5;
              },
              paddingBottom: function (i: any, node: any) {
                return 5;
              },
            },
          }])
      }

    }
    return details;
  }

  private readonly circumference: number = 772.45;

  public calculateDashOffset(): number {
    return this.circumference - (20 / 100 * this.circumference);
  }

  generateSVGDiagram(check: CompletenessCheck): any {
    let test = this.calculateDashOffset();
    var htmlToPdfmake = require("html-to-pdfmake");
    var html = htmlToPdfmake(`
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" class="w-full">
<!--    <path-->
<!--      d="m141.73,0C63.46,0,0,63.46,0,141.73s63.46,141.73,141.73,141.73,141.73-63.46,141.73-141.73S220.01,0,141.73,0Zm0,245.67c-57.4,0-103.94-46.53-103.94-103.94s46.53-103.94,103.94-103.94,103.94,46.53,103.94,103.94-46.53,103.94-103.94,103.94Z"-->
<!--      style="fill:#d9d9d9;"/>-->
    <circle cx="141.73" cy="141.73" r="122.94" style="stroke-dashoffset: 772.45; fill: none; stroke-width: 38; stroke-opacity: 150; paint-order: stroke; stroke-linecap: round; stroke-dasharray: 772.45; transform: rotate(-90deg); transform-origin: center; stroke: #009999; opacity: 0.99"/>

<!--    <circle cx="141.73" cy="141.73" r="103.94" style="fill:#099; opacity:0.2;"/>-->
<!--      <path-->
<!--        d="m205.91,108.12l-8.47-8.47c-2.95-2.95-7.74-2.95-10.69,0l-62.44,62.44-28.37-28.37c-2.95-2.95-7.74-2.95-10.69,0l-7.69,7.69c-2.95,2.95-2.95,7.74,0,10.69l42.11,42.11c2.95,2.95,7.74,2.95,10.69,0l4.37-4.37c.27-.2.54-.4.79-.65l70.39-70.39c2.95-2.95,2.95-7.74,0-10.69Z"-->
<!--        style="fill:#FFFFFF; opacity:0.56;"/>-->

    </svg>
`);
    return html;
  }


  async generateReportPdf(project: Project, organisation: Organisation, lph: LphEnum, check: CompletenessCheck): Promise<any> {
    await this.loadPdfMaker();


    const def = {
      fonts: {
        Raleway: {
          normal: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/Raleway-Regular.ttf',
          bold: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/Raleway-Bold.ttf',
          italics: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/Raleway-Italic.ttf',
          bolditalics: 'https://de-thinkbic-fonts.s3.eu-central-1.amazonaws.com/Raleway-BoldItalic.ttf'
        }
      },
      info: {
        title: 'BIC',
        author: 'BIC',
      },
      permissions: {
        printing: 'highResolution',
        modifying: false,
        copying: false,
        annotating: false,
        fillingForms: false,
        contentAccessibility: true,
        documentAssembly: true
      },
      pageMargins: [40, 80, 40, 40],
      background: function(currentPage: any, pageSize: { width: number; height: number;}) {
        return (currentPage === 1) ? [{
                 image: 'reportBackground',
                 //opacity: 0.9,
                 width: pageSize.width + 20,
                 //alignment: 'center',
                 margin: [0, 0, 0, 0]
               }] : [''];
      },
      header: function(currentPage: any, pageSize: { width: number; height: number;}) {
        // @ts-ignore
        return (currentPage === 1) ? [''] : [{
          margin: [40, 20, 40, 0],
          table: {
            widths: [180, 186, 120],
            body: [
              [{
                border: [true, true, false, false],
                text: 'HOAI-Check',
                fontSize: 10,
              }, {
                border: [false, true, false, false],
                text: 'Projekt',
                fontSize: 10,
              }, {
                border: [false, true, true, false],
                text: organisation?.name ?? '',
                fontSize: 10,
              }],
              [{
                border: [true, false, false, false],
                text: 'Gebäude und Innenräume',
                fontSize: 10,
              }, {
                border: [false, false, false, false],
                text: 'Projektart',
                fontSize: 10,
              }, {
                border: [false, false, true, true],
                image: 'organisationImage',
                fit: [100, 30],
                rowSpan: 2,
              }],
              [{
                border: [true, false, false, true],
                text: 'Leistungsphase ' + lph.charAt(3),
                fontSize: 10,
              }, {
                border: [false, false, false, true],
                text: project.name,
                fontSize: 10,
              }, {
                border: [false, false, true, true],
                text: '',
                fontSize: 10,
              }],
            ]
          },
          layout: {
            hLineColor: '#99A09C',
            vLineColor: '#99A09C',
            defaultBorder: false,
          },
        }]
      },
      footer: function(currentPage: any, pageCount: any){
        // @ts-ignore
        return (currentPage === 1) ? [''] : [
          {
            columns: [
              {
                margin: [40, 10, 0, 0],
                text: assembleDateInformation(),
                fontSize: 10,
                alignment: 'left',
              }, {
                image: 'logo',
                width: 100,
                alignment: 'center',
              }, {
                 margin: [0, 10, 45, 0],
                text: 'Seite ' + currentPage,
                fontSize: 10,
                alignment: 'right',
              }
            ]
          }
        ]
      },


      // ######################################################################################################################
      // CONTENT
      // ######################################################################################################################
      content: [
        {
          text: 'BIC REPORT',
          style: 'coverTitle',
          alignment: 'left',
          //margin: [20, 10, 0, 0],
          absolutePosition: {x: 58, y: 40},
        },
        {
          text: 'HOAI-Check',
          style: 'coverSubTitle',
          alignment: 'left',
          //margin: [20, 10, 0, 0]
          absolutePosition: {x: 60, y: 105},
        },
        {
          text: project.name,
          style: 'coverInfo',
          alignment: 'left',
          //margin: [20, 30, 0, 0]
          absolutePosition: {x: 60, y: 170},
        },
        {
          text: organisation?.name ?? '',
          style: 'coverInfo',
          alignment: 'left',
          //margin: [20, 15, 0, 0]
          absolutePosition: {x: 60, y: 205},
        },
        {
          image: 'reportBgHouse',
          width: 540,
          //alignment: 'center',
          //margin: [28, 0, 0, 0]
          absolutePosition: {x: 50, y: 225},
        },
        {
          image: 'logo',
          width: 480,
          alignment: 'center',
          //margin: [0, 5, 0, 0],
          pageBreak: 'after',
          absolutePosition: {x: 50, y: 666},
        },



    //Part 1

        //Report Overview
        //Headline
        {
          text: 'Ergebnisübersicht',
          fontSize: 14,
          margin: [10, 20, 0, 0]
        },
        //Overview Table
        {
          margin: [0, 8, 0, 0],
          table: {
            widths: [170, 100, 100, 100],
            heights: [80, 30],
            body: [
              [{
                border: [true, true, false, true],
                table: {
                  widths: [180],
                  body: [
                    [{
                      text: 'Leistungsphase ' + lph.charAt(3),
                      fontSize: 14,
                      color: '#2CA09A',
                    }],
                    [{
                      text: 'Gebäude und Innenräume',
                      fontSize: 12,
                    }]
                  ]
                },
                layout: {
                  defaultBorder: false,
                }
              }, {
                border: [false, true, false, true],
                text: this.calculateSucceedPercentage(check) + '%',
                fontSize: 20,
                alignment: 'center',
                margin: [0, 30, 0, 0],
                color: '#2CA09A',
              }, {
                border: [false, true, false, true],
                text: '0%',
                fontSize: 20,
                alignment: 'center',
                margin: [0, 30, 0, 0],
                color: '#F3CE86',
              }, {
                border: [false, true, true, true],
                text: 100 - this.calculateSucceedPercentage(check) + '%',
                fontSize: 20,
                alignment: 'center',
                margin: [0, 30, 0, 0],
                color: '#EE3B3B',
              }],

              [{
                margin: [5, 6, 0, 0],
                border: [true, false, false, true],
                text: [{
                  text: check.partialServiceNames?.length,
                  fontSize: 16,
                }, {
                  text: '   Prüfungen gesamt',
                  fontSize: 12,
                }]
              }, {
                margin: [5, 6, 0, 0],
                border: [false, false, false, true],
                text: [{
                  text: check.checkResults?.reduce((acc, cur) => cur.succeed ? acc + 1 : acc, 0) ?? 0,
                  fontSize: 16,
                }, {
                  text: '   Bestanden',
                  fontSize: 12,
                }
              ]}, {
                margin: [10, 6, 0, 0],
                border: [false, false, false, true],
                text: [{
                  text: '0',
                  fontSize: 16,
                }, {
                  text: '   Warnungen',
                  fontSize: 12,
                }
              ]}, {
                margin: [15, 6, 0, 0],
                border: [false, false, true, true],
                text: [{
                  text: check.partialServiceNames!.length - check.checkResults!.reduce((acc, cur) => cur.succeed ? acc + 1 : acc, 0) ?? 0,
                  fontSize: 16,
                }, {
                  text: '   Fehler',
                  fontSize: 12,
                }
              ]} ],
            ]
          },
          layout: {
            hLineColor: '#99A09C',
            vLineColor: '#99A09C',
            defaultBorder: false,
            paddingLeft: function(i: any, node: any) { return 5; },
            paddingRight: function(i: any, node: any) { return 5; },
            paddingTop: function(i: any, node: any) { return 5; },
            paddingBottom: function(i: any, node: any) { return 5; },
          },
        },

        //Check Results
        //Headline
        {
          text: 'Prüfergebnisse',
          fontSize: 14,
          margin: [10, 35, 0, 0]
        },
        //Results Table
        {
          margin: [0, 8, 0, 0],
          table: {
            widths: [300, 100, 80],
            heights: [26],
            body: this.createStandardResultTable(check),
          },
          layout: {
            hLineColor: '#99A09C',
            vLineColor: '#99A09C',
            defaultBorder: false,
            paddingLeft: function(i: any, node: any) { return 5; },
            paddingRight: function(i: any, node: any) { return 5; },
            paddingTop: function (index: any, node: any) {
              applyVerticalAlignment(node, index, 'center');
              return 0;
            },
            paddingBottom: function(i: any, node: any) { return 5; },
          },
        },
        //Old hardcoded Results Table
        // {
        //   margin: [0, 8, 0, 0],
        //   table: {
        //     widths: [300, 100, 80],
        //     heights: [26],
        //     body: [
        //       [
        //         {
        //           margin: [5, 7, 0, 0],
        //           border: [true, true, false, true],
        //           text: 'Grundleistungen',
        //           fontSize: 12,
        //           bold: true,
        //         }, {
        //           margin: [5, 7, 0, 0],
        //           border: [false, true, false, true],
        //           text: 'Prüfstatus',
        //           fontSize: 12,
        //           bold: true,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 7, 0, 0],
        //           border: [false, true, true, true],
        //           text: 'Seite',
        //           fontSize: 12,
        //           bold: true,
        //           alignment: 'center',
        //         }
        //       ],
        //       //Row 1
        //       [
        //         {
        //           margin: [5, 5, 0, 0],
        //           border: [true, false, false, false],
        //           text: this.servicePipe.transform(check.partialServiceNames![0]),
        //           fontSize: 12,
        //         }, {
        //           margin: [5, 5, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'successIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 5, 0, 0],
        //           border: [false, false, true, false],
        //           text: '3',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 2
        //       [
        //         {
        //           margin: [5, 0, 0, 0],
        //           border: [true, false, false, false],
        //           text: 'Entwurfsplanung - Schnittzeichnungen',
        //           fontSize: 12,
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'successIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, true, false],
        //           text: '4',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 3
        //       [
        //         {
        //           margin: [5, 0, 0, 0],
        //           border: [true, false, false, false],
        //           text: 'Koordination der Fachplanung',
        //           fontSize: 12,
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'successIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, true, false],
        //           text: '5',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 4
        //       [
        //         {
        //           margin: [5, 0, 0, 0],
        //           border: [true, false, false, false],
        //           text: 'Objektbeschreibung',
        //           fontSize: 12,
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'errorIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, true, false],
        //           text: '6',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 5
        //       [
        //         {
        //           margin: [5, 0, 0, 0],
        //           border: [true, false, false, false],
        //           text: 'Verhandlung über die Genehmigungsfähigkeit',
        //           fontSize: 12,
        //           }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'errorIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, true, false],
        //           text: '6',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 6
        //       [
        //         {
        //           margin: [5, 0, 0, 0],
        //           border: [true, false, false, false],
        //           text: 'Kostenschätzung',
        //           fontSize: 12,
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'successIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, true, false],
        //           text: '7',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 7
        //       [
        //         {
        //           margin: [5, 0, 0, 5],
        //           border: [true, false, false, true],
        //           text: 'Fortschreiben des Terminplans',
        //           fontSize: 12,
        //         }, {
        //           margin: [5, 0, 0, 5],
        //           border: [false, false, false, true],
        //           image: 'warningIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 5],
        //           border: [false, false, true, true],
        //           text: '7',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //     ],
        //   },
        //   layout: {
        //     hLineColor: '#99A09C',
        //     vLineColor: '#99A09C',
        //     defaultBorder: false,
        //     paddingLeft: function(i: any, node: any) { return 5; },
        //     paddingRight: function(i: any, node: any) { return 5; },
        //     paddingTop: function(i: any, node: any) { return 5; },
        //     paddingBottom: function(i: any, node: any) { return 5; },
        //   },
        // },

        //Special Results Table
        {
          margin: [0, 25, 0, 0],
          table: {
            widths: [300, 100, 80],
            heights: [26],
            body: this.createSpecialResultTable(check),
          },
          layout: {
            hLineColor: '#99A09C',
            vLineColor: '#99A09C',
            defaultBorder: false,
            paddingLeft: function(i: any, node: any) { return 5; },
            paddingRight: function(i: any, node: any) { return 5; },
            paddingTop: function (index: any, node: any) {
              applyVerticalAlignment(node, index, 'center');
              return 0;
            },
            paddingBottom: function(i: any, node: any) { return 5; },
          },
        pageBreak: 'after',
        },
        // Old hardcoded Special Results Table
        // {
        //   margin: [0, 25, 0, 0],
        //   table: {
        //     widths: [300, 100, 80],
        //     heights: [26],
        //     body: [
        //       [
        //         {
        //           margin: [5, 7, 0, 0],
        //           border: [true, true, false, true],
        //           text: 'Besondere Leistungen',
        //           fontSize: 12,
        //           bold: true,
        //         }, {
        //           margin: [5, 7, 0, 0],
        //           border: [false, true, false, true],
        //           text: 'Prüfstatus',
        //           fontSize: 12,
        //           bold: true,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 7, 0, 0],
        //           border: [false, true, true, true],
        //           text: 'Seite',
        //           fontSize: 12,
        //           bold: true,
        //           alignment: 'center',
        //         }
        //       ],
        //       //Row 1
        //       [
        //         {
        //           margin: [5, 5, 0, 0],
        //           border: [true, false, false, false],
        //           text: 'Alternative Kostenuntersuchung',
        //           fontSize: 12,
        //           }, {
        //           margin: [5, 5, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'warningIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 5, 0, 0],
        //           border: [false, false, true, false],
        //           text: '9',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 2
        //       [
        //         {
        //           margin: [5, 0, 0, 0],
        //           border: [true, false, false, false],
        //           text: 'Wirtschaftlichkeitsberechnung',
        //           fontSize: 12,
        //           }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'errorIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, true, false],
        //           text: '9',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 3
        //       [
        //         {
        //           margin: [5, 0, 0, 0],
        //           border: [true, false, false, false],
        //           text: 'Vertiefte Kostenberechnung',
        //           fontSize: 12,
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, false, false],
        //           image: 'successIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 0],
        //           border: [false, false, true, false],
        //           text: '10',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //       //Row 4
        //       [
        //         {
        //           margin: [5, 0, 0, 5],
        //           border: [true, false, false, true],
        //           text: 'Fortschreiben von Raumbüchern',
        //           fontSize: 12,
        //         }, {
        //           margin: [5, 0, 0, 5],
        //           border: [false, false, false, true],
        //           image: 'successIcon',
        //           width: 15,
        //           alignment: 'center',
        //         }, {
        //           margin: [5, 0, 0, 5],
        //           border: [false, false, true, true],
        //           text: '10',
        //           fontSize: 12,
        //           alignment: 'center',
        //         },
        //       ],
        //     ],
        //   },
        //   layout: {
        //     hLineColor: '#99A09C',
        //     vLineColor: '#99A09C',
        //     defaultBorder: false,
        //     paddingLeft: function(i: any, node: any) { return 5; },
        //     paddingRight: function(i: any, node: any) { return 5; },
        //     paddingTop: function(i: any, node: any) { return 5; },
        //     paddingBottom: function(i: any, node: any) { return 5; },
        //   },
        //   pageBreak: 'after',
        // },

        //Page with details
        //Headline
        {
          text: 'Prüfdetails',
          fontSize: 14,
          margin: [10, 15, 0, 0],
        },
        //Details
        this.createDetails(check),
        // this.generateSVGDiagram(check),
        // {
        //   margin: [0, 8, 0, 0],
        //   table: {
        //     widths: ['auto', '*'],
        //     heights: [26],
        //     body: this.createDetail(check),
        //   },
        //   layout: {
        //     hLineColor: '#E2E2E2',
        //     vLineColor: '#E2E2E2',
        //     defaultBorder: false,
        //     paddingLeft: function(i: any, node: any) { return 5; },
        //     paddingRight: function(i: any, node: any) { return 5; },
        //     paddingTop: function(i: any, node: any) { return 5; },
        //     paddingBottom: function(i: any, node: any) { return 5; },
        //   },
        // },



      ],

      // ######################################################################################################################
      // STYLES
      // ######################################################################################################################
      defaultStyle: {
        font: 'Raleway',
        fontSize: 11,
      },
      styles: {
        icon: {
          font: 'MaterialIcons'
        },
        coverTitle: {
          fontSize: 48,
          bold: true,
          color: '#214f7c', //secondary
        },
        coverSubTitle: {
          fontSize: 20,
          bold: false,
          color: '#214f7c', //secondary
        },
        coverInfo: {
          fontSize: 20,
          bold: true,
          color: '#214f7c', //secondary
        },
        title: {
          fontSize: 16,
          bold: false,
        },
        header: {
          fontSize: 16,
          bold: false,
          decoration: 'underline'
        },
        subheader: {
          fontSize: 16,
          bold: false,
          decoration: 'underline'
        },
        underline: {
          fontSize: 14,
          decoration: 'underline'
        },
        serviceTable: {
          margin: [0, 0, 0, 0]
        },
        serviceTableHeader01: {
          bold: true,
          fontSize: 15,
          color: 'black'
        },
        serviceTableHeader02: {
          bold: true,
          fontSize: 13,
          color: 'black'
        },
        serviceTableHeader03: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },

        serviceTableErrorText: {
          bold: false,
          fontSize: 8,
          color: 'gray'
        }
      },
      images: {
        logo: window.location.origin + '/assets/Logo.jpg',
        reportBackground: window.location.origin + '/assets/BIC-Report-Background.png',
        reportBgHouse: window.location.origin + '/assets/BIC-Report-House.png',
        successIcon: window.location.origin + '/assets/BIC-Report-SuccessIcon.png',
        warningIcon: window.location.origin + '/assets/BIC-Report-WarningIcon.png',
        errorIcon: window.location.origin + '/assets/BIC-Report-ErrorIcon.png',
        organisationImage: organisation?.profileImage ?? window.location.origin + '/assets/noImage.png',
      }

    };
    return this.pdfMake.createPdf(def, null, this.fonts);
  }

  // getServiceRows(project: Project, evaluations: Map<LphEnum, CompletenessCheck>, basicServices: Boolean, lph: LphEnum): any[] {
  //   let services: any[] = [];
  //
  //   if (evaluations.get(lph)) {
  //     evaluations.get(lph)!.checkResults!.forEach(partialServiceResponse => {
  //       if (partialServiceResponse.partialServiceName?.includes(basicServices ? "GL" : "BL")) {
  //         services.push(
  //           [
  //             {
  //               text: this.servicePipe.transform(partialServiceResponse.partialServiceName),
  //               alignment: 'start',
  //               colSpan: 2
  //             },
  //             {},
  //             {
  //               width: "30",
  //               svg: (partialServiceResponse.succeed || partialServiceResponse.fulfilledByAffidavit) ? this.icons.succeed : this.icons.failed,
  //               alignment: 'center'
  //             },
  //             {
  //               text: (partialServiceResponse.succeed || partialServiceResponse.fulfilledByAffidavit) ? (partialServiceResponse.fulfilledByAffidavit ? "Eidesstattlich versichert" : "Durch Dokumente belegt") : "-",
  //               alignment: 'center'
  //             },
  //           ],
  //         );
  //
  //         if (!partialServiceResponse.succeed && !partialServiceResponse.fulfilledByAffidavit) {
  //           services.push(
  //             [
  //               {text: "Hinweis", alignment: 'center', style: 'serviceTableHeader02'},
  //               this.getInfoTextFor(partialServiceResponse),
  //               {},
  //               {},
  //             ]
  //           )
  //         }
  //       }
  //     });
  //
  //     if (services.length > 0) {
  //       services = [
  //         [
  //           {
  //             text: basicServices ? "Grundleistung" : "Besondere Leistung",
  //             style: 'serviceTableHeader03',
  //             alignment: 'center',
  //             colSpan: 2
  //           },
  //           {},
  //           {text: 'Status', style: 'serviceTableHeader03', alignment: 'center'},
  //           {text: 'Art', style: 'serviceTableHeader03', alignment: 'center'},
  //
  //         ],
  //         ...services
  //       ]
  //     }
  //   }
  //
  //   return services;
  // }
  //
  // getServicePhaseTable(project: Project, evaluations: Map<LphEnum, CompletenessCheck>): any[] {
  //   let servicePhaseTable: any[] = [];
  //
  //   for (let lph of Object.values(LphEnum)) {
  //     if (evaluations.get(lph)) {
  //       servicePhaseTable.push(
  //         {
  //           style: 'serviceTable',
  //           layout: 'lightHorizontalLines',
  //           pageBreak: 'after',
  //           table: {
  //             widths: [50, '*', 40, 'auto'],
  //             headerRows: 3,
  //             body: [
  //               [
  //                 {
  //                   text: 'Geprüfte Leistungsphase: ' + lph,
  //                   style: 'serviceTableHeader01',
  //                   colSpan: 4,
  //                   alignment: 'center'
  //                 },
  //                 {},
  //                 {},
  //                 {},
  //               ],
  //               [
  //                 {text: 'Geprüfte Teilleistung', style: 'serviceTableHeader02', alignment: 'center', colSpan: 2},
  //                 {},
  //                 {
  //                   text: 'Ergebnis der Prüfung \n Vollständigkeit der Teilleistung',
  //                   style: 'serviceTableHeader02',
  //                   alignment: 'center',
  //                   colSpan: 2
  //                 },
  //                 {},
  //               ],
  //               ...this.getServiceRows(project, evaluations, true, lph),
  //               ...this.getServiceRows(project, evaluations, false, lph),
  //
  //             ]
  //           }
  //         },
  //       )
  //     }
  //
  //   }
  //
  //   return servicePhaseTable;
  // }
  //
  // getInfoTextFor(partialServiceResponse: CompactPartialServiceEvaluationResult): any {
  //   if (!partialServiceResponse.succeed) {
  //     return {
  //       text: "Die Teilleistung konnte nicht vollständig nachgewiesen werden.\n\n" +
  //         "Grundlagen für diese Teilleistung wurden nicht erfüllt:\n\n" +
  //         this.getDependentPartialServiceListFor(partialServiceResponse) + "\n",
  //       alignment: 'start',
  //       colSpan: 3,
  //       style: 'serviceTableErrorText'
  //     }
  //   }
  //
  //   if (partialServiceResponse.succeed) {
  //     if (partialServiceResponse.affidavitPossible) {
  //       return {
  //         text: "Durch die Prüfung konnte die Erfüllung der der Teilleistung nicht nachgewiesen werden.\n\n" +
  //           "Für den Nachweis der Erfüllung dieser Teilleistung können Sie eidesstattlich versichern, dass die Teilleistung erbracht wurde oder folgende Nachweise hochladen:\n\n" +
  //           this.getAspectListFor(partialServiceResponse) + "\n",
  //         alignment: 'start',
  //         colSpan: 3,
  //         style: 'serviceTableErrorText'
  //       }
  //     } else {
  //       return {
  //         text: "Durch die Prüfung konnte die Erfüllung der der Teilleistung nicht nachgewiesen werden.\n\n" +
  //           "Für den Nachweis der Erfüllung dieser Teilleistung müssen Sie folgende Nachweise hochladen:\n\n" +
  //           this.getAspectListFor(partialServiceResponse) + "\n",
  //         alignment: 'start',
  //         colSpan: 3,
  //         style: 'serviceTableErrorText'
  //       }
  //     }
  //
  //   } else {
  //     if (partialServiceResponse.affidavitPossible) {
  //       return {
  //         text: "Durch die Prüfung konnte die Erfüllung der der Teilleistung nicht nachgewiesen werden.\n\n" +
  //           "Für den Nachweis der Erfüllung dieser Teilleistung können Sie eidesstattlich versichern, dass die Teilleistung erbracht wurde oder einen Nachweis hochladen.\n\n",
  //         alignment: 'start',
  //         colSpan: 3,
  //         style: 'serviceTableErrorText'
  //       }
  //     } else {
  //       return {
  //         text: "Durch die Prüfung konnte die Erfüllung der der Teilleistung nicht nachgewiesen werden.\n\n" +
  //           "Für den Nachweis der Erfüllung dieser Teilleistung müssen Sie einen Nachweis hochladen.\n\n",
  //         alignment: 'start',
  //         colSpan: 3,
  //         style: 'serviceTableErrorText'
  //       }
  //     }
  //   }
  // }
  //
  // getAspectListFor(partialServiceResponse: CompactPartialServiceEvaluationResult): any {
  //   let aspectList: string = "";
  //   // partialServiceResponse.serviceAspectEvaluationResults!.forEach(aspect => {
  //   //   aspectList += "- " + this.aspectPipe.transform(aspect.serviceAspectName!) + "\n";
  //   // });
  //   return aspectList;
  // }
  //
  // getDependentPartialServiceListFor(partialServiceResponse: CompactPartialServiceEvaluationResult): any {
  //   let dependentPartialServiceList: string = "";
  //   // partialServiceResponse.serviceRelationEvaluationResults!.forEach(aspect => {
  //   //   dependentPartialServiceList += "- " + aspect.definedServiceAspects + "\n";
  //   // });
  //   return dependentPartialServiceList;
  // }
}


