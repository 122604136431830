import { Component } from '@angular/core';

@Component({
  selector: 'app-checkout-feature-list-item',
  templateUrl: './checkout-feature-list-item.component.html',
  styleUrls: ['./checkout-feature-list-item.component.scss']
})
export class CheckoutFeatureListItemComponent {

}
