import {Injectable, OnDestroy} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {UnsavedChangesDialogComponent} from "../components/util/design/unsavedChangesDialog/unsaved-changes-dialog.component";
import {checkPort} from "@angular-devkit/build-angular/src/utils/check-port";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

export interface CanDeactivateComponent {
  unsubscribe: Subject<void>
  canDeactivate: () => boolean;
  save(): void;
  validForm(): boolean;
  disableForm(): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard  {
  constructor(private dialog: MatDialog, private snackbar: MatSnackBar) {}

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(component == null) {
      return true;
    }

    component.unsubscribe.next();

    if(component.disableForm()){
      return true;
    }else{
      if(component.validForm()){
        if(component.canDeactivate()){
          return true;
        }else{
          return new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {
              panelClass:"rounded-corners-dialog",
              data: {component: component}
            });
            dialogRef.afterClosed().toPromise().then(result => {
              if(result == "check"){
                component.save();
                this.snackbar.open('Änderungen gespeichert!', 'OK', {duration: 3000});
                resolve(true);
              }else if(result == "discard"){
                this.snackbar.open('Änderungen verworfen!', 'OK', {duration: 3000});
                resolve(true);
              }else{
                resolve(false);
              }
            })
          });
        }
      }else{
        this.snackbar.open('Änderungen nicht gespeichert, da nicht alle Pflichtfelder ausgefüllt sind!', 'OK', {duration: 5000});
        return false;
      }
    }








    // console.log(component.canDeactivate());
    //
    // component.unsubscribe.next();
    //
    // if (!component.canDeactivate()) {
    //   if (component.validForm()) {
    //
    //     if(component.disableForm()) {
    //       return true;
    //     }else {
    //       component.save();
    //       this.snackbar.open('Änderungen gespeichert!', 'OK', {duration: 3000});
    //       return true;
    //     }
    //
    //   }else{
    //     this.snackbar.open('Änderungen nicht gespeichert, da nicht alle Pflichtfelder ausgefüllt sind!', 'OK', {duration: 5000});
    //     return false;
    //   }
    // }else {
    //     return component.canDeactivate();
    // }
  }
}
