<div class="flex flex-col h-full w-full justify-start items-start bg-[#FFF]">
  <app-quality-stepper class="w-full"></app-quality-stepper>

  <div class="flex flex-col h-full w-full justify-start items-start gap-6 px-6 py-12 overflow-hidden">
    <div class="flex flex-row w-full gap-6">
      <div class="flex flex-col w-1/2">
        <div class="flex flex-row">
          <p class="text-gray-900">Dateiauswahl:</p>
        </div>
      </div>

      <div class="flex flex-col w-1/2">
        <div class="flex flex-row">
          <p class="text-gray-900">Ausgewählte Dateien:</p>
        </div>
      </div>

    </div>

    <div class="flex flex-row overflow-hidden h-full w-full gap-6">


      <div class="flex flex-col w-1/2">
        <!--      <div class="flex flex-row pb-2">-->
        <!--        <p class="text-black">Dateiauswahl:</p>-->
        <!--      </div>-->
        <ng-container *ngIf="selectedFolder === ''">
          <div class="flex flex-col justify-start items-start rounded-lg h-full w-full p-6 gap-6 border border-gray-200">
            <button (click)="onFolderClick('IFC')"
                    class="flex flex-row justify-start items-center rounded-lg shadow w-full p-4 hover:bg-gray-200 gap-2">
              <fa-icon [icon]="faFolderOpen" class="text-primary text-lg mr-4"></fa-icon>
              IFC-Modelle<p class="text-xs font-normal text-gray-700"> (Es werden nur Architektur- oder Koordinationsmodelle geprüft)</p>
              <fa-icon [icon]="faChevronRight" class="text-primary text-lg ml-auto"></fa-icon>
            </button>
            <button (click)="onFolderClick('RB')"
                    class="flex flex-row justify-start items-center rounded-lg shadow w-full p-4 gap-2 hover:bg-gray-200">
              <fa-icon [icon]="faFolderOpen" class="text-primary text-lg mr-4"></fa-icon>
              Raumbücher
              <fa-icon [icon]="faChevronRight" class="text-primary text-lg ml-auto"></fa-icon>
            </button>
            <button (click)="onFolderClick('LV')"
                    class="flex flex-row justify-start items-center rounded-lg shadow w-full p-4 gap-2 hover:bg-gray-200">
              <fa-icon [icon]="faFolderOpen" class="text-primary text-lg mr-4"></fa-icon>
              Leistungsverzeichnisse
              <fa-icon [icon]="faChevronRight" class="text-primary text-lg ml-auto"></fa-icon>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedFolder !== ''">
          <div class="rounded-md w-full h-full ">
            <bic-table [data]="shownFiles" [columnNames]="columns" [columns]="column" [columnTypes]="colTypes"
            [backbutton]="true" [backButtonText]="'Zurück'" [selectionFields]="true" [(selectedRows)]="shownSelectedFiles" (backButtonClicked)="onFolderClick('')"
            [columnWidths]="['55%', '20%', '25%']" [searchbar]="true" [columnAlignments]="['start', 'center', 'end']"></bic-table>
          </div>
        </ng-container>


      </div>

      <div class="flex flex-col w-1/2 gap-4">
        <!--      <div class="flex flex-row pb-2">-->
        <!--        <p class="text-black">Ausgewählte Dateien:</p>-->
        <!--      </div>-->
        <div class="flex flex-row h-full w-full">
          <div class="flex flex-col gap-4 h-full w-full">
            <div class="flex flex-col justify-start items-start rounded-lg h-1/3 w-full border border-gray-200">
              <div class="flex justify-center items-center w-full text-gray-500 text-sm bg-gray-50 border-b border-gray-200 rounded-t-lg p-4">IFC-Modell</div>
              <div class="overflow-y-scroll w-full items-stretch">
                <div *ngFor="let file of getSelectedIFCFiles()"
                     class="flex flex-row justify-between items-center w-full p-4 border-b border-gray-200 hover:bg-gray-100">
                  <div class="flex flex-row justify-between w-[90%]">
                    <span class="w-[60%] text-sm">{{ file.name }}</span>
                    <span class="w-[20%] text-sm">{{ file.size }}</span>
                    <span class="w-[20%] text-sm">{{ file.lastModified | date: 'dd.MM.yyyy HH:mm'}}</span>
                  </div>
                  <div class="flex flex-row justify-end items-center w-[10%]">
                      <fa-icon class="text-gray-200 hover:text-error cursor-pointer" [icon]="faXmark" (click)="removeSelectedIFCFile(file)"></fa-icon>
                  </div>
                </div>
              </div>
              <div *ngIf="getSelectedIFCFiles().length === 0"
                   class="flex flex-row justify-center items-center w-full h-full text-gray rounded-lg pr-4">
                Keine Datei ausgewählt
              </div>
            </div>
            <div class="flex flex-col justify-start items-start rounded-lg h-1/3 w-full pb-4 border border-gray-200">
              <div class="flex justify-center items-center w-full text-gray-500 text-sm bg-gray-50 border-b border-gray-200 rounded-t-lg p-4">Raumbuch</div>
              <div class="overflow-y-scroll w-full items-stretch">
                <div *ngFor="let file of getSelectedRBFiles()"
                     class="flex flex-row justify-between items-center w-full p-4 border-b border-gray-200 hover:bg-gray-100">
                  <div class="flex flex-row justify-between w-[90%]">
                    <span class="w-[60%] text-sm">{{ file.name }}</span>
                    <span class="w-[20%] text-sm">{{ file.size }}</span>
                    <span class="w-[20%] text-sm">{{ file.lastModified | date: 'dd.MM.yyyy HH:mm'}}</span>
                  </div>
                  <div class="flex flex-row justify-end items-center w-[10%]">
                    <fa-icon class="text-gray-200 hover:text-error" [icon]="faXmark" (click)="removeSelectedRBFile(file)"></fa-icon>
                  </div>
                </div>
              </div>
              <div *ngIf="getSelectedRBFiles().length === 0"
                   class="flex flex-row justify-center items-center w-full h-full text-gray">
                Keine Datei ausgewählt
              </div>
            </div>
            <div class="flex flex-col justify-start items-start rounded-lg h-1/3 w-full pb-4 border border-gray-200">
              <div class="flex justify-center items-center w-full text-gray-500 text-sm bg-gray-50 border-b border-gray-200 rounded-t-lg p-4">Leistungsverzeichnis</div>
              <div class="overflow-y-scroll w-full items-stretch">
                <div *ngFor="let file of getSelectedLVFiles()"
                     class="flex flex-row justify-between items-center w-full p-4 border-b border-gray-200 hover:bg-gray-100">
                  <div class="flex flex-row justify-between w-[90%]">
                    <span class="w-[60%] text-sm">{{ file.name }}</span>
                    <span class="w-[20%] text-sm">{{ file.size }}</span>
                    <span class="w-[20%] text-sm">{{ file.lastModified | date: 'dd.MM.yyyy HH:mm'}}</span>
                  </div>
                  <div class="flex flex-row justify-end items-center w-[10%]">
                    <fa-icon class="text-gray-200 hover:text-error" [icon]="faXmark" (click)="removeSelectedLVFile(file)"></fa-icon>
                  </div>
                </div>
              </div>
              <div *ngIf="getSelectedLVFiles().length === 0"
                   class="flex flex-row justify-center items-center w-full h-full text-gray">
                Keine Datei ausgewählt
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="flex flex-row w-full justify-center items-center">
      <div
        >
        <!--button (click)="navigateToChoosingRules()" color="primary" mat-raised-button
                [disabled]="!permissionService.hasProjectPrivilege('PROJECT_QUALITY_CHECK') || check || !canCheckQuality || (selectedIFCFiles.isEmpty() && selectedLVFiles.isEmpty() && selectedRBFiles.isEmpty())">
          Prüfungsauswahl
          <fa-icon [icon]="faChevronRight" class="text-white"></fa-icon>
        </button-->
        <bic-button (click)="navigateToChoosingRules()" [text]="'Prüfregelauswahl'" [tooltip]="!canCheckQuality ? 'Kein Kontingent verfügbar' : ''" [tooltipDirection]="'top'"
                    [disabled]="!permissionService.hasProjectPrivilege('PROJECT_QUALITY_CHECK') || check || !canCheckQuality || (selectedIFCFiles.length<1 && selectedLVFiles.length<1 && selectedRBFiles.length<1)"
                    [iconRight]="faChevronRight">
        </bic-button>
      </div>
    </div>
  </div>

</div>





