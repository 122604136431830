import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Organisation, OrganisationControllerService} from "../../../api";
import {FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import LegalFormEnum = Organisation.LegalFormEnum;
import {Observable, Subject, Subscription} from "rxjs";
import {PermissionService} from "../../../services/permission/permission.service";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";


@Component({
  selector: 'app-organisation-create',
  templateUrl: './organisation-create.component.html',
  styleUrls: ['./organisation-create.component.scss']
})
export class OrganisationCreateComponent implements OnInit{

  @Input() organisation: Organisation | undefined;

  organisationForm!: UntypedFormGroup;

  legalForm: typeof LegalFormEnum = LegalFormEnum;

  organisationChangedEvent: Subject<Organisation> = new Subject<Organisation>();



  constructor(private formBuilder: UntypedFormBuilder, private organisationControllerService: OrganisationControllerService, public permissionService: PermissionService, private router: Router) { }

  ngOnInit(): void {
    this.organisationForm = this.formBuilder.group({
      id: new FormControl(""),
      name: new UntypedFormControl("", [Validators.required]),
      legalForm: new UntypedFormControl(),
      street: new UntypedFormControl("", [Validators.required]),
      houseNumber: new UntypedFormControl("", [Validators.required]),
      zipCode: new UntypedFormControl("", [Validators.required]),
      country: new UntypedFormControl("", [Validators.required]),
      city: new UntypedFormControl("", [Validators.required]),
      state: new UntypedFormControl("", [Validators.required]),
      organisationMail: new UntypedFormControl("", [Validators.required, Validators.email]),
      vatNumber: new UntypedFormControl()
    });

    this.organisationForm.patchValue(this.organisation!)
    if (this.organisation && !this.permissionService.hasOrganisationPrivilege('ORGANISATION_SETTINGS', this.organisation?.id!)){
      this.organisationForm.disable();
    }

  }


  save() {
    this.organisation = this.organisationForm.value;
      this.organisationControllerService.createOrganisation(this.organisation!).subscribe(organisation => {
        this.organisation = organisation;
      });
  }

  cancel() {
    this.router.navigate(['/organisations']);
  }

}
