/**
 * chatbic-api-beta
 * CHAT Services API - Use this API to access the CHAT Services of the Building Information Cloud (BIC).
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LoadRequestParameters { 
    fileName?: string;
    fileType?: string;
    folder?: string;
    lph?: string;
    dataId?: string;
    projectId?: number;
}