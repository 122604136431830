<div class="flex flex-col justify-start items-start gap-6 h-full">

  <app-hoai-stepper [isDocView]="isDocView" [isDetail]="isDocDetailView" class="w-full"></app-hoai-stepper>

  <div class="flex flex-col w-full px-4">
    <div class="flex overflow-y-scroll overflow-x-hidden">
      <app-overview-report (download)="downloadPdf()"
                           [checkType]="'Leistungsphase ' + ((check?.lph | lphToNumber) + 1)"
                           [completedValue]="completed"
                           [date]="check.checkDate"
                           [errorValue]="error"
                           [rules]="total"
                           [warningValue]="warning"
                           [fileDeactivated]="selectedDocument === undefined"
                           [fileId]="selectedDocumentFile?.id ?? undefined"
                           [whatWasChecked]=" total == 1 ? 'Leistung' : 'Leistungen'"
                           class="w-full">
      </app-overview-report>
    </div>

  </div>


  <div class="w-full h-full px-4 overflow-y-scroll">
    <!-- Segment containing both Grundleistungen and Besondere Leistungen bic tables -->
    <div *ngIf="!isDocView" class="flex flex-col justify-start items-center w-full gap-6 rounded-t-md">

      <!-- Grundleistungen bic table -->
      <div class="w-full rounded-t-md" *ngIf="servicesGL.length > 0">
        <bic-table class="w-full" [data]="servicesGL" [columnNames]="displayedColumnsNamesGL" [columns]="displayedColumns"
                   [columnTypes]="columnsTypes" [columnWidths]="columnsWidths"
                   [paginator]="false" [columnAlignments]="columnsAlignment" (rowClick)="navigateToRuleView($event)"
                   [createTicket]="true" (createTicketClicked)="createTask($event)">
        </bic-table>
      </div>

      <!-- Besondere Leistungen bic table -->
      <div class="w-full rounded-t-md" *ngIf="servicesBL.length > 0">
        <bic-table class="w-full" [data]="servicesBL" [columnNames]="displayedColumnsNamesBL" [columns]="displayedColumns"
                   [columnTypes]="columnsTypes" [columnWidths]="columnsWidths"
                   [paginator]="false" [columnAlignments]="columnsAlignment" (rowClick)="navigateToRuleView($event)"
                   [createTicket]="true" (createTicketClicked)="createTask($event)">
        </bic-table>
      </div>

    </div>


    <!-- Segment containing the document view of a Grund- or Besondere Leistung -->
    <div *ngIf="isDocView && !isDocDetailView"
         class="flex flex-col justify-start items-center w-full gap-6 rounded-t-md">

      <!-- DocView bic table -->
      <div class="w-full rounded-t-md">
        <bic-table class="w-full" backButtonText="Zurück" backbutton=true (backButtonClicked)="navigateBackToRules()"
                   [title]="selectedService.name"
                   [data]="selectedService.documents" [columnNames]="displayedColumnsNamesDocuments" [columns]="displayedColumnsDocuments"
                   [columnTypes]="columnsTypesDocuments" [columnWidths]="columnsWidthsDocuments"
                   [paginator]="false" [columnAlignments]="columnsAlignmentDocuments" (rowClick)="navigateToDocView($event)"
                   [createTicket]="true" (createTicketClicked)="createTask($event)">
        </bic-table>
      </div>
    </div>


    <!-- Segment containing the document detail view of a document file -->
    <div *ngIf="isDocDetailView" class="flex flex-col justify-start items-center w-full gap-6 rounded-t-md">

      <!-- DocDetailView bic table -->
      <div class="w-full rounded-t-md">
        <bic-table #aspectTable class="w-full" backButtonText="Zurück" backbutton=true (backButtonClicked)="navigateBackToDocView()"
                   [title]="selectedDocument?.name"
                   [data]="selectedDocument.aspects" [columnNames]="displayedColumnsNamesAspects" [columns]="displayedColumnsAspects"
                   [columnTypes]="columnsTypesAspects" [columnWidths]="columnsWidthsAspects"
                   [paginator]="false" [columnAlignments]="columnsAlignmentAspects"
                   [textfield]="true"
                   [createTicket]="true" (createTicketClicked)="createTask($event)">

          <ng-template bicTableExpandableRow let-element>
            <div class="w-[90%] flex-col flex justify-center items-start p-4 text-gray-900">
              <div>{{ element?.description }}</div>
              <br>
              <div [innerHtml]="element.phrases"></div>
            </div>
          </ng-template>

        </bic-table>
      </div>
    </div>
  </div>
</div>
