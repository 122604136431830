/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BimBaseDashboardData } from './bimBaseDashboardData';
import { CompletenessDashboardData } from './completenessDashboardData';
import { LOIDashboardData } from './lOIDashboardData';
import { ProjectFilesDashboardData } from './projectFilesDashboardData';
import { QualityDashboardData } from './qualityDashboardData';
import { TaskDashboardData } from './taskDashboardData';
import { UserSimpleDto } from './userSimpleDto';

export interface DashboardData { 
    users?: Array<UserSimpleDto>;
    tasks?: { [key: string]: Array<TaskDashboardData>; };
    hoaiChecks?: Array<CompletenessDashboardData>;
    qualityChecks?: Array<QualityDashboardData>;
    bimBaseChecks?: Array<BimBaseDashboardData>;
    loiChecks?: Array<LOIDashboardData>;
    projectFiles?: Array<ProjectFilesDashboardData>;
}