<div class="p-4 bg-white">
    <div class="flex flex-col">
      <div class="flex flex-row justify-between items-center pb-4 text-xl">
        {{title}}

      </div>
<!--      <app-organisation-create [organisation]="organisation" [clearEventTriggered]="clearEvent.asObservable()"-->
<!--                                 [organisationChangedEventTriggered]="organisationChangedEvent.asObservable()"-->
<!--      [opendAsPopup]="true" (closePopup)="close()"></app-organisation-create>-->
    </div>

</div>





