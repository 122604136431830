import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ApiModule} from "../../../api";
import {AddressInputModule} from "../../util/./addressInput/address-input.module";
import {ApplicationComponent} from "./application.component";
import {RouterLink} from "@angular/router";
import {ProjectStatePipe} from "../../../pipes/project-state.pipe";
import {ServicePipe} from "../../../pipes/service.pipe";
import {BicBafToolModule} from "@buildinginformationcloud/bic-baf-tool";
import {MaterialModule} from "../../../material.module";
import {DrawerContentService} from "../../../services/drawer-content.service";


@NgModule({
  declarations: [
    ApplicationComponent,
    ServicePipe,
  ],
    imports: [
        ApiModule,
        CommonModule,
        MatIconModule,
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        RouterLink,
        AddressInputModule,
        BicBafToolModule,
    ],
  providers: [
    DrawerContentService,
    ProjectStatePipe,
  ],
    exports: [
        ServicePipe,
    ]
})
export class ApplicationModule {
}
