/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SimpleCheck { 
    id?: string;
    checkDate?: Date;
    lph?: SimpleCheck.LphEnum;
    lodType?: SimpleCheck.LodTypeEnum;
    projectName?: string;
    completed?: boolean;
    checkType?: SimpleCheck.CheckTypeEnum;
    failed?: boolean;
    checkerName?: string;
}
export namespace SimpleCheck {
    export type LphEnum = 'GENERAL' | 'LPH1' | 'LPH2' | 'LPH3' | 'LPH4' | 'LPH5' | 'LPH6' | 'PLANS';
    export const LphEnum = {
        GENERAL: 'GENERAL' as LphEnum,
        LPH1: 'LPH1' as LphEnum,
        LPH2: 'LPH2' as LphEnum,
        LPH3: 'LPH3' as LphEnum,
        LPH4: 'LPH4' as LphEnum,
        LPH5: 'LPH5' as LphEnum,
        LPH6: 'LPH6' as LphEnum,
        PLANS: 'PLANS' as LphEnum
    };
    export type LodTypeEnum = 'LOD_100' | 'LOD_200' | 'LOD_300' | 'LOD_400' | 'LOD_500';
    export const LodTypeEnum = {
        _100: 'LOD_100' as LodTypeEnum,
        _200: 'LOD_200' as LodTypeEnum,
        _300: 'LOD_300' as LodTypeEnum,
        _400: 'LOD_400' as LodTypeEnum,
        _500: 'LOD_500' as LodTypeEnum
    };
    export type CheckTypeEnum = 'QUALITY' | 'HOAI' | 'IFC' | 'BASE' | 'SWM';
    export const CheckTypeEnum = {
        QUALITY: 'QUALITY' as CheckTypeEnum,
        HOAI: 'HOAI' as CheckTypeEnum,
        IFC: 'IFC' as CheckTypeEnum,
        BASE: 'BASE' as CheckTypeEnum,
        SWM: 'SWM' as CheckTypeEnum
    };
}