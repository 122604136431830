import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

/**
 *
 * Directive for Drag & Drop actions on files
 */
@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  /**
   * Output for dropped FileLists
   */
  @Output() dropped = new EventEmitter<FileList>();

  /**
   * State of hovered view
   */
  @Output() hoveredChange = new EventEmitter<boolean>();
  @Input() hovered = false;

  /**
   * Constructor
   */
  constructor() {
  }

  /**
   * Action on Drop files
   * @param $event drop event
   */
  @HostListener('drop', ['$event'])
  onDrop($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.dropped.emit($event.dataTransfer.files);
    this.hovered = false;
    this.hoveredChange.emit(false);
  }

  /**
   * Action on Dragover
   * @param $event dragover event
   */
  @HostListener('dragover', ['$event'])
  onDragOver($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.hovered = true;
    this.hoveredChange.emit(true);
  }

  /**
   * Action on Dragleave
   * @param $event dragleave event
   */
  @HostListener('dragleave', ['$event'])
  onDragLeave($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
    this.hovered = false;
    this.hoveredChange.emit(false);
  }


}
