<div class="flex flex-col h-full">
  <app-swm-stepper class="w-full"></app-swm-stepper>

  <div class="flex flex-col justify-start items-start gap-2 h-full overflow-hidden">


  <div class="flex flex-col w-full p-4">
        <app-overview-report [checkType]="'Brandschutz-Check'"
                         [completedValue]="completed"
                         [date]="fixedCheckDate"
                         [errorValue]="error"
                         [rules]="total"
                         [warningValue]="warning"
                         [fileDeactivated]="false"
                         [whatWasChecked]=" total == 1 ? 'Datei' : 'Dateien'"
                         [questionMarkText]="'Die prozentualen Angaben beziehen sich auf die Anzahl der erfüllten bzw. nicht erfüllten Brandschutzkonzepte, die den Brandschutz-Check durchlaufen haben. Eine Datei erfüllt den Brandschutz-Check nicht, sobald mindestens eine Prüfregel (siehe Ergebnis-Prüfregel) fehlschlägt. '"
                         class="w-full">
    </app-overview-report>

  </div>




  <div class="w-full h-full px-4 overflow-y-scroll">
    <div class="h-full w-full">
          <cdk-table [dataSource]="dataSource" class="w-full" multiTemplateDataRows>

<!--            <ng-container cdkColumnDef="type">-->
<!--              <cdk-header-cell class="w-[10%] flex flex-row justify-start h-full px-7 py-2 font-medium" *cdkHeaderCellDef>-->
<!--                Dateityp-->
<!--              </cdk-header-cell>-->
<!--              <cdk-cell class="w-[10%] flex flex-row justify-start h-full px-7 py-2"-->
<!--                        *cdkCellDef="let element"> {{ element.check.fileType }}-->
<!--              </cdk-cell>-->
<!--            </ng-container>-->

            <ng-container cdkColumnDef="filename">
              <cdk-header-cell class="w-[60%] flex flex-row justify-start h-full py-2 font-medium" *cdkHeaderCellDef>
                Dateiname
              </cdk-header-cell>
              <cdk-cell class="w-[60%] flex flex-row justify-start h-full py-2"
                        *cdkCellDef="let element"> {{ element.file_name }}
              </cdk-cell>
            </ng-container>

            <ng-container cdkColumnDef="state">
              <cdk-header-cell class="w-[20%] flex flex-row justify-center h-full py-2 font-medium" cdk-header-cell
                               *cdkHeaderCellDef>Prüfstatus
              </cdk-header-cell>
              <cdk-cell class=" w-[20%] flex flex-row justify-center h-full" cdk-cell
                        *cdkCellDef="let element">
                <div class="flex flex-row justify-center items-center">
                <ng-container *ngIf="calculateResult(element) == 'error'">
                  <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"
                                    [state]="CircleState.RED"></app-circle-state>
                </ng-container>
                <ng-container *ngIf="calculateResult(element) == 'warning'">
                  <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"
                                    [state]="CircleState.YELLOW"></app-circle-state>
                </ng-container>
                <ng-container *ngIf="calculateResult(element) == 'success'">
                  <app-circle-state class="w-6" [value]="100" [useAsIcon]="true"
                                    [state]="CircleState.GREEN"></app-circle-state>
                </ng-container>
              </div>
              </cdk-cell>
            </ng-container>

            <ng-container cdkColumnDef="task">
              <cdk-header-cell class="w-[20%] flex flex-row justify-center h-full py-2"
                               *cdkHeaderCellDef></cdk-header-cell>
              <cdk-cell class="w-[20%] flex flex-row justify-center h-full"
                        *cdkCellDef="let element">
                <fa-icon [matTooltip]="'Aufgabe erstellen'" (click)="createTask(element); $event.stopPropagation()" [icon]="faClipboardList"
                         class="text-2xl w-6 text-primary hidden group-hover:block"></fa-icon>
              </cdk-cell>
            </ng-container>

            <cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"
                            class="flex flex-row justify-start items-start w-full border-b-2 border-b-gray-400 bg-white px-2"></cdk-header-row>
            <cdk-row *cdkRowDef="let row; columns: displayedColumns;"
                     class="flex flex-row justify-start items-center w-full border-b-2 border-b-gray-300 hover:bg-gray-200 hover:cursor-pointer px-2 group"
                     (click)="navigateToRulesView(row)"></cdk-row>

          </cdk-table>



    </div>

  </div>



</div>

</div>



