import {Component, Inject, OnInit} from '@angular/core';
import {
  faChevronDown,
  faChevronRight,
  faEdit,
  faEllipsisV, faFilter, faPenToSquare,
  faPlus,
  faPlusCircle,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {faCircleQuestion, faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {
  FileControllerService,
  IFCCheck,
  IfcControllerService,
  LOICheckRule,
  LOIRequest,
  ProjectFile
} from "../../../../api";
import {StateConfigService} from "../../../../services/state/state-config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {Rule} from "../../../../quality-api";
import {DefaultService} from "../../../../bim-api";
import {faLock} from "@fortawesome/free-solid-svg-icons/faLock";
import {PermissionService} from "../../../../services/permission/permission.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  LoiDeleteStandardConfirmationDialogComponent
} from "../loi-delete-standard-confirmation-dialog/loi-delete-standard-confirmation-dialog.component";
import LodTypeEnum = IFCCheck.LodTypeEnum;
import {faSquareDashedCirclePlus} from "@fortawesome/pro-solid-svg-icons";

interface LOIDefaultRule extends LOICheckRule {
  loiType: LodTypeEnum
}

@Component({
  selector: 'app-loi-check-choose-files-choose-rules',
  templateUrl: './loi-check-choose-rules.component.html',
  styleUrls: ['./loi-check-choose-rules.component.scss'],
})
export class LoiCheckChooseRulesComponent implements OnInit {

  constructor(private ifcControllerService: IfcControllerService,
              private stateConfigService: StateConfigService,
              private fileControllerService: FileControllerService,
              public permissionService: PermissionService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private bimApiService: DefaultService,
              public dialog: MatDialog) {
  }

  loiRules: LOICheckRule[] = [];
  //create 5 standard loi rules with the names BIC-Standard LOI (number), the numbers are: 100, 200, 300, 400, 500
  BICStandardLOIRules: LOIDefaultRule[] = [
    {name: "BIC-Standard LOI 100", loiRule: "", loiType: LodTypeEnum._100},
    {name: "BIC-Standard LOI 200", loiRule: "", loiType: LodTypeEnum._200},
    {name: "BIC-Standard LOI 300", loiRule: "", loiType: LodTypeEnum._300},
    {name: "BIC-Standard LOI 400", loiRule: "", loiType: LodTypeEnum._400},
    {name: "BIC-Standard LOI 500", loiRule: "", loiType: LodTypeEnum._500}
  ];
  selectedRules: (LOIDefaultRule)[] = [];

  deleteStandard: boolean = false;


  dropZoneClassName: string = "custom-file-drop-check";
  contentClassName: string = "custom-file-drop-check-content";
  entered: boolean = false;
  rulesLoaded: boolean = false;

  projectFile!: ProjectFile;
  protected readonly faEllipsisV = faEllipsisV;

  loiFilter: string[]=['all']

  ngOnInit() {
    const projectFileId = this.activatedRoute.snapshot.params.fileId;

    this.fileControllerService.getFile(projectFileId).subscribe(file => {
      this.projectFile = file;
    });
    this.loiRules = JSON.parse(JSON.stringify(this.BICStandardLOIRules));

    this.ifcControllerService.getRules2(this.stateConfigService.getProjectId()).subscribe(rules => {

      this.loiRules.push(...rules)
      this.rulesLoaded = true;
    });
  }

  navigateToCustom() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['projects', this.getProjectId(), 'loi-check', 'custom']));
    window.open(url, '_blank');
  }

  navigateToRule(ruleId: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['projects', this.getProjectId(), 'loi-check', 'custom', ruleId]));
    window.open(url, '_blank');
  }

  getProjectId(): number {
    return this.stateConfigService.getProjectId();
  }

  drop(event: CdkDragDrop<Rule[]>) {
    const previousList = event.previousContainer.data;
    const targetList = event.container.data;

    if (event.previousContainer === event.container) {
      // Wenn das Element in der gleichen Liste verschoben wird, verschieben Sie das Element innerhalb der Liste.
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Wenn das Element von einer Liste zur anderen verschoben wird, übertragen Sie das Element zwischen den Listen.
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  public fileOver(event: any) {
    this.dropZoneClassName = "custom-file-drop-check-drag";
    this.contentClassName = "custom-file-drop-check-content-drag";
    this.entered = true;
  }

  public fileLeave(event: any) {
    this.dropZoneClassName = "custom-file-drop-check";
    this.contentClassName = "custom-file-drop-check-content";
    this.entered = false;
  }

  areAllRulesSelected(): boolean {
    return this.selectedRules.length === this.loiRules.length;
  }

  /* get filteredRules(): Rule[] {
     return this.loiRules.filter(rule => this.selectedDocTypes.some(docType => rule.docTypes?.map(type => type.name).includes(docType.name)));
   }*/

  removeRuleFromSelectedRules(rule: LOICheckRule) {
    this.selectedRules = this.selectedRules.filter(selectedRule => selectedRule !== rule);
    this.loiRules.push(rule);
    this.loiRules.sort((a, b) => a.name!.localeCompare(b.name!))
  }


  removeAllRulesFromSelectedRules() {
    this.selectedRules = [];
  }

  addRuleToSelectedRules(rule: LOIDefaultRule | LOICheckRule) {

    // @ts-ignore
    if (!this.selectedRules.includes(rule)) {
      // @ts-ignore
      this.selectedRules.push(rule);
    }
    this.loiRules = this.loiRules.filter(r => r !== rule)
  }

  startLoiCheck() {
    const dialogRef = this.dialog.open(LoiConfirmationDialog, {
      panelClass: 'rounded-corners-dialog',
      width: '538px',
      data: {ifcFile: this.projectFile},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const rule: LOIDefaultRule = this.selectedRules.pop()!
        if (rule.loiType != undefined) {
          const lodType = this.lod(rule.loiType);
          this.bimApiService.checkLod({type: lodType, file: this.projectFile.link}).subscribe(
            (res) => {
              const loiRequest: LOIRequest = {
                lodType: rule.loiType,
                projectFile: this.projectFile,
                uuid: res.instanceId
              }
              this.ifcControllerService.createCheck(loiRequest, this.stateConfigService.getProjectId()).subscribe((res) => {
                this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'reports']);
              });
            });
        } else {
          const rules: any = JSON.parse(rule.loiRule!).rules;
          this.bimApiService.checkLoi({file: this.projectFile.link, rules: rules}).subscribe(
            (res) => {

              const loiRequest: LOIRequest = {
                rule: rule,
                lodType: undefined,
                projectFile: this.projectFile,
                uuid: res.instanceId
              }
              this.ifcControllerService.createCheck(loiRequest, this.stateConfigService.getProjectId()).subscribe((res) => {
                this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'reports']);
              });

            });
        }
      }
    });
  }

  lod(value: LodTypeEnum) {
    switch (value) {
      case LodTypeEnum._100:
        return "100";
      case LodTypeEnum._200:
        return "200";
      case LodTypeEnum._300:
        return "300";
      case LodTypeEnum._400:
        return "400";
      case LodTypeEnum._500:
        return "500";
      default:
        return "";
    }
  }

  deleteRule(id: string) {
    const dialogRef = this.dialog.open(LoiDeleteStandardConfirmationDialogComponent, {
      panelClass: 'rounded-corners-dialog',
      width: '750px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ifcControllerService.deleteRule(id, this.getProjectId()).subscribe(() => {
          this.loiRules = this.loiRules.filter(rule => rule.id !== id);
        });
      }
    });
  }

  get filterRules(){
    if (this.loiFilter[0]=='all') {
      return this.loiRules;
    }
    if (this.loiFilter[0] == 'bic'){
      return this.BICStandardLOIRules
    }
    console.log('own', this.loiRules, this.BICStandardLOIRules.length, this.loiRules.length)
    if (this.BICStandardLOIRules.length >= this.loiRules.length) {
      return [];
    }

    return this.loiRules.slice(this.BICStandardLOIRules.length);
  }

  onSelectLOIFilter(event: Event, filter: string): void {
    console.log('in loi select', filter)
    const checkbox = event.target as HTMLInputElement;
    const checked = checkbox.checked ?? !(document.getElementById(`checkbox-${filter}`) as HTMLInputElement)?.checked;

    if (checked) {
      this.loiFilter = [filter];
    } else {
      this.loiFilter = ['all']
    }
  }

  protected readonly faChevronRight = faChevronRight;
  protected readonly faCircleQuestion = faCircleQuestion;
  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faPlus = faPlus;
  protected readonly faLock = faLock;
    protected readonly faSquareDashedCirclePlus = faSquareDashedCirclePlus;
  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faChevronDown = faChevronDown;
  protected readonly faFilter = faFilter;
  protected readonly faPenToSquare = faPenToSquare;
  protected readonly faTrash = faTrash;
}

@Component({
  selector: 'app-loi-check-confirmation-dialog',
  templateUrl: './loi-check-confirmation-dialog.html',
  styleUrls: ['./loi-check-choose-rules.component.scss'],
})
export class LoiConfirmationDialog implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {ifcFile: ProjectFile }, private dialogRef: MatDialogRef<LoiConfirmationDialog>) {
  }

  ngOnInit() {
  }


  protected readonly faCircleXmark = faCircleXmark;
}
