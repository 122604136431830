import {AfterViewChecked, Component, Inject, Input, OnInit} from "@angular/core";
import {DrawerContentService} from "../../../../../services/drawer-content.service";
import {TaskDetailsService} from "../../../../../services/task-details.service";

@Component({
  selector: 'app-ifc-viewer',
  templateUrl: './ifc-viewer.component.html',
  styleUrls: ['./ifc-viewer.component.scss']
})
export class IfcViewerComponent implements OnInit, AfterViewChecked {

  constructor(
    public drawerContentService: DrawerContentService,
    public taskService: TaskDetailsService,
  ) {
  }

  ngAfterViewChecked(): void {
    window.dispatchEvent(new Event('resize'));
  }


  data!: { link: string; ids: string[]}

  public ngOnInit() {
    this.data = this.drawerContentService.config?.data;
  }

  createTask() {
    this.taskService.openDialog();
  }
}
