import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import ApexCharts from 'apexcharts';

@Component({
  selector: 'app-dashboard-tasks-state',
  templateUrl: './dashboard-tasks-state.component.html',
  styleUrls: ['./dashboard-tasks-state.component.scss']
})
export class DashboardTasksStateComponent implements AfterViewInit {

  @ViewChild('columnChart') columnChart!: ElementRef;

  @Input() state: string = 'Offen';
  @Input() percentage: number = 0;

  @Input() basicTasks: number = 0;
  @Input() bcfTasks: number = 0;

  @Input() totalTasks: number = 0;


  get colors() {
    return ['#042e3f', '#f4f5f6']
  }


  get options() {
    return {
      series: [{
        data: [this.basicTasks, this.bcfTasks]
      }],
       chart: {
        height: "100%",
        width: "100%",
        type: "bar",
        sparkline: {
          enabled: true,
        },
      },
      colors: this.colors,
      plotOptions: {
        bar: {
          borderRadius: 4,
          track: {
            background: '#E5E7EB',
          },
          columnWidth: '80%',
          distributed: true,
          hollow: {
            margin: 0,
            size: "32%",
          },
          colors: {
            backgroundBarColors: ['#F3F4F6'],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 4,
          },
        }
      },
      grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
          left: 2,
          right: 2,
          top: -23,
          bottom: -20,
        },
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      yaxis: {
        show: false,
        max: this.totalTasks,
        min: 0
      },
      xaxis: {
        categories: [
          'basic',
          'bcf',
        ],
        labels: {
          enabled: false
        },
        axisBorder: {
          enabled: false
        },
        axisTicks: {
          enabled: false
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.columnChart && typeof ApexCharts !== 'undefined') {
      const chart = new ApexCharts(this.columnChart.nativeElement, this.options);
      chart.render();
    }
  }

}
