import {Component, ElementRef, Inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { AuthControllerService, User } from 'src/app/api';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {faShieldCheck} from "@fortawesome/pro-solid-svg-icons";
import {ErrorService} from "../../../services/error/error.service";

@Component({
  selector: 'fta-popup',
  templateUrl: './fta-popup.component.html',
  styleUrls: ['./fta-popup.component.scss']
})
export class FtaPopupComponent implements OnInit{

  // qrCodeString : string | undefined = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAV4AAAFeAQAAAADlUEq3AAADB0lEQVR4Xu2ZMZazMAyEzUuRMkfgKBwNH42jcIQtU+RFv2ZkWAWcvGz9j4oFy59TzI4tAcW+j0c5Zj6E4ByCcwjOITjH/wE/C8MHt7XM5WK2MnEzq+Vy5/1FcBfGndVn5IfHFUNP/oxL8eRtQwR3YKemanYv4zJj5BeOsOru4hfBH2E4dbTadPYpX1MEfwPb4+qmnAdzb5r5tgbVLoLfwf6Hu/u2TnXAwAWOpUhuiOAzXBgNPl04KbgLbwFlzWBRLkWqpmnBZ5g6T960OMULLWoQeB72LS+4A1tUkVKimEQVaZucTeCUdrfgA+xahrITiwm9GQ3N7lvBPbjlGe0o3EcFDU22qOAjjPz1Bzq7U1f0NQVruLvb+Sj4DHsi8n5fY6nhfFxxTHZ2t+AN3roVRNSUdcIUwlCcXXX+puAj3JQdePE8asqMtOsMi7rOx90teNcZrfJvJ4NpJvfzkX8Fn2F6M7REVXaBtxdV1Wf8hyztbsEZvtCbzO/d3zPkpn1zORac4b3RoykHPrXNzbCGly6tyxF8hqkllR23hoadzEaFbwV34KgbeOaIoxDKFlRldy1L9fy7uwW/wAjXmV99Ys2EVFj0jvaGiOATDFOOcfitlDsMyzUnnQW/wLjDFwx+a6Tcy/ZKGU5FUnAfDm+iphgsCrm3pTQst7zgDmysw2z7YErqPMe2hn2NI8FdGM+2k0VpqXx4W6KYYKngD/ATLcxSYMo1lHWLxmmJbrpdBPdgTKNj3t8lexUxLq0F4Zd0MApOMA+/AmV9hk+67Gv2pvrVooIP8IRLGVFFDKpjFFseI8EfYJcV+9kbGuQXDNHQQG6P1MkITnCLZlF+yIBTubtBvegs+MV1RMom9yV/eWQTOMWvCT7D8B+n0f3FUViiCdx0zn4WnOHIuzd9TbTRFUnXmf+DqeLXBL+HIekUI+xnGtbQBLZ/heD3MCxaWjnmg0heI7gL4zbpjE3uJyL6GoxWvqIX3IMLg3nIjReiTPrubt00WMFn+NsQnENwDsE5BOcQnONv8D+n5nJr5ba6sQAAAABJRU5ErkJggg=='
  qrCodeString : string | undefined = undefined;
  mfaForm!: UntypedFormGroup;
  mfaError: boolean = false;
  @ViewChildren('otpInput') otpInputs!: QueryList<ElementRef>;

  displayQrCode = false;
  displayMfaForm = false;
  displayMfaSuccess = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { isMail : boolean, user: User }, private authControllerService: AuthControllerService, public formBuilder: UntypedFormBuilder,
              private errorService: ErrorService, public dialogRef: MatDialogRef<FtaPopupComponent>) {
  }

  ngOnInit(): void {
    this.mfaForm = this.formBuilder.group({
      code1: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code2: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code3: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code4: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code5: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      code6: ['', [Validators.required, Validators.pattern(/^\d$/)]]
    });
    console.log(this.data.isMail);
    console.log(this.data.user);

    if(!this.data.isMail){
      this.displayQrCode = true;
      this.authControllerService.activate2FA(false).subscribe( url =>{
        console.log(url);
        this.qrCodeString = url.value;
      });

    }else{
      this.authControllerService.activate2FA(true).subscribe( url =>{
        this.displayMfaForm = true;
      });
    }
  }

  moveToNext(event: any, index: number): void {
    const input = event.target;
    const key = event.key;

    if (key === 'Backspace' && input.value.length === 0 && index > 0) {
      this.otpInputs.toArray()[index - 1].nativeElement.focus();
    } else if (input.value.length === 1 && index < 5) {
      this.otpInputs.toArray()[index + 1].nativeElement.focus();
    }
  }

  verify(): void {
    if(this.mfaForm.valid){
      this.errorService.errorEvent$.subscribe((error) => {
        if (error.status == 401) {
          this.mfaError = true
        }
      });


      const code = this.mfaForm.value.code1 + this.mfaForm.value.code2 + this.mfaForm.value.code3 + this.mfaForm.value.code4 + this.mfaForm.value.code5 + this.mfaForm.value.code6;
      this.authControllerService.verify2FA({username: this.data.user.mail, password: '', verificationCode: code}).subscribe(() => {
        console.log('success');
        this.displayMfaForm = false;
        this.displayMfaSuccess = true;
      });
    }
  }

  deactivate2FA(): void {
    this.authControllerService.deactivate2FA().subscribe(() => {
      this.displayMfaSuccess = false;
      this.displayMfaForm = true;
      this.dialogRef.close({success: false});
    });
  }

  closeAfterSuccess(): void {
    this.displayQrCode = false;
    this.displayMfaForm = false;
    this.displayMfaSuccess = false;
    this.dialogRef.close({success: true});
  }

  protected readonly faShieldCheck = faShieldCheck;
}
