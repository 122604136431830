<form [formGroup]="taskForm">
  <div class="flex flex-col justify-start items-start w-full gap-4 p-6 overflow-hidden">
    <div class="w-full flex flex-col gap-4">
      <div class="flex flex-row justify-between items-center text-base font-semibold">Prüfeinstellungen</div>
      <!--mat-form-field-- appearance="outline" class="w-full" *ngFor="let parameter of parameters">
        <mat-label>{{ parameter.name }}</mat-label>
        <ng-container *ngIf="parameter.type == Parameter.TypeEnum.BOOL">
          <mat-select #boolSelect [value]="values[parameter.value]" (valueChange)="values[parameter.value] = boolSelect.value" required
                      formControlName="{{parameter.value}}">
            <mat-option [value]="true">Ja</mat-option>
            <mat-option [value]="false">Nein</mat-option>
          </mat-select>
        </ng-container>

        <ng-container *ngIf="parameter.type == Parameter.TypeEnum.INT">
          <input #numberInput [value]="values[parameter.value]" (change)="values[parameter.value] = +numberInput.value"
                 matInput required formControlName="{{parameter.value}}" type="number" [min]="parameter.value == 'numberOfEmployees' ? 0 : null" [max]="parameter.value == 'numberOfEmployees' ? 10000 : null"/>
        </ng-container>

        <ng-container *ngIf="parameter.type == Parameter.TypeEnum.FLOAT">
          <input #floatInput [value]="values[parameter.value]" (change)="values[parameter.value] = +floatInput.value"
                 matInput required formControlName="{{parameter.value}}" type="number"/>
        </ng-container>

        <ng-container *ngIf="parameter.type == Parameter.TypeEnum.ENUM">
          <mat-select #enumSelect [value]="values[parameter.value]" (valueChange)="values[parameter.value] = enumSelect.value" required
                      formControlName="{{parameter.value}}">
            <mat-option *ngFor="let value of parameter.enums" [value]="value.value">{{ value.name }}</mat-option>
          </mat-select>
        </ng-container>


      </mat-form-field-->

      <ng-container *ngFor="let parameter of parameters">
        <ng-container *ngIf="parameter.type == Parameter.TypeEnum.BOOL">
          <bic-select [formControlName]="parameter.value" [label]="parameter.name" [placeholder]="values[parameter.value]" [required]="true"
                      (blur)="taskForm.get(parameter.value)?.markAsTouched()" (focus)="taskForm.get(parameter.value)?.markAsUntouched()" [errorCondition]="taskForm.get(parameter.value)?.touched && taskForm.get(parameter.value)?.invalid">
            <bic-dropdown-element [value]="true">Ja</bic-dropdown-element>
            <bic-dropdown-element [value]="false">Nein</bic-dropdown-element>
          </bic-select>
        </ng-container>
        <ng-container *ngIf="parameter.type == Parameter.TypeEnum.INT">
          <bic-input [formGroup]="taskForm" [formControlName]="parameter.value" [label]="parameter.name" [placeholder]="values[parameter.value]" [type]="'number'" [required]="true" [showX]="false"
                     (blur)="taskForm.get(parameter.value)?.markAsTouched()" (focus)="taskForm.get(parameter.value)?.markAsUntouched()" [errorCondition]="taskForm.get(parameter.value)?.touched && taskForm.get(parameter.value)?.invalid">
          </bic-input>
        </ng-container>
        <ng-container *ngIf="parameter.type == Parameter.TypeEnum.FLOAT">
          <bic-input [formGroup]="taskForm" [formControlName]="parameter.value" [label]="parameter.name" [placeholder]="values[parameter.value]" [type]="'number'" [required]="true" [showX]="false"
                     (blur)="taskForm.get(parameter.value)?.markAsTouched()" (focus)="taskForm.get(parameter.value)?.markAsUntouched()" [errorCondition]="taskForm.get(parameter.value)?.touched && taskForm.get(parameter.value)?.invalid">
          </bic-input>
        </ng-container>
        <ng-container *ngIf="parameter.type == Parameter.TypeEnum.ENUM">
          <bic-select [formControlName]="parameter.value" [label]="parameter.name" [placeholder]="values[parameter.value]" [required]="true"
                      (blur)="taskForm.get(parameter.value)?.markAsTouched()" (focus)="taskForm.get(parameter.value)?.markAsUntouched()" [errorCondition]="taskForm.get(parameter.value)?.touched && taskForm.get(parameter.value)?.invalid">
            <bic-dropdown-element *ngFor="let parameterEnum of parameter.enums" [value]="parameterEnum.value">{{parameterEnum.name}}</bic-dropdown-element>
          </bic-select>
        </ng-container>
      </ng-container>

      <!--    <mat-form-field appearance="outline" class="w-full">-->
      <!--      <mat-label>Geländeoberkante (m)</mat-label>-->
      <!--      <input matInput type="number" [(ngModel)]="data.terrainLevel"/>-->
      <!--      <mat-hint class="tracking-tighter">Es sollten die gleichen Bezugshöhen wie im BIM-Modell verwendet werden</mat-hint>-->
      <!--    </mat-form-field>-->

      <!--  </div>-->

      <!--  <div *ngIf="data.qualityRules?.includes('ASR_3_5_RAUMTEMPERATUR')" class="w-full">-->

      <!--    <div class="flex flex-row justify-between items-center pb-4 text-xl">ASR 3.5 Raumtemperatur</div>-->

      <!--    <mat-form-field appearance="outline" class="w-full">-->
      <!--      <mat-label>Arbeitsschwere</mat-label>-->
      <!--      <mat-select [(ngModel)]="data.asr_3_5_Raumtemperature.workLevel">-->
      <!--        <mat-option *ngFor="let level of workLevels" [value]="level">-->
      <!--          {{ level | workLevel}}-->
      <!--        </mat-option>-->
      <!--      </mat-select>-->
      <!--    </mat-form-field>-->

      <!--    <mat-form-field appearance="outline" class="w-full">-->
      <!--      <mat-label>Arbeitshaltung</mat-label>-->
      <!--      <mat-select [(ngModel)]="data.asr_3_5_Raumtemperature.workActivity">-->
      <!--        <mat-option *ngFor="let activity of workActivities" [value]="activity">-->
      <!--          {{ activity | workActivity}}-->
      <!--        </mat-option>-->
      <!--      </mat-select>-->
      <!--    </mat-form-field>-->
      <!--  </div>-->

      <!--  <div-->
      <!--    *ngIf="data.qualityRules!.includes('ASR_4_2_PAUSENRAEUME') || data.qualityRules!.includes('ASR_4_3_ERSTEHILFERAEUME')">-->

      <!--    <div class="flex flex-row justify-between items-center pb-4 text-xl">ASR 4.2 Pausenräume / ASR 4.3 Erste Hilfe Räume</div>-->
      <!--    <mat-form-field appearance="outline" class="w-full">-->
      <!--      <mat-label>Anzahl Mitarbeiter*innen</mat-label>-->
      <!--      <input matInput type="number" [(ngModel)]="data.numberOfEmployees"/>-->
      <!--    </mat-form-field>-->
      <!--  </div>-->


      <div class="flex flex-row justify-between items-center w-full gap-2 pb-2 pt-4">
        <!--button mat-raised-button mat-dialog-close class="w-2/6 m-0">Abbrechen</button>
        <button (click)="check()" class="w-2/6 m-0" color="primary" [disabled]="taskForm.invalid" mat-raised-button>
          Prüfen
        </button-->

        <div class="flex-grow"></div>
        <bic-button mat-dialog-close [text]="'Abbrechen'" [color]="'#FFF'" [textColor]="'gray-900'" [border]="true" [opacityHover]="false"></bic-button>
        <bic-button [text]="'Prüfen'" [color]="'primary'" [disabled]="!taskForm.valid" (click)="check()"></bic-button>
      </div>
    </div>
  </div>
</form>
