<div class="flex flex-col h-full w-full justify-start items-start gap-4 font-medium">

  <div class="flex flex-col h-full w-full justify-start items-start gap-4 px-4 pb-4 pt-4 overflow-hidden">
    <div class="flex flex-row w-full">
      <div class="flex flex-col px-2 w-1/2">
        <div class="flex flex-row">
          <p class="text-black">Dateiauswahl:</p>
        </div>
      </div>

      <div class="flex flex-col px-2 w-1/2">
        <div class="flex flex-row">
          <p class="text-black">Ausgewählte Dateien:</p>
        </div>
      </div>

    </div>

    <div class="flex flex-row overflow-hidden h-full w-full gap-4 p-2">


      <div class="flex flex-col w-1/2">
        <!--      <div class="flex flex-row pb-2">-->
        <!--        <p class="text-black">Dateiauswahl:</p>-->
        <!--      </div>-->
        <ng-container *ngIf="selectedFolder === ''">
          <div class="flex flex-col justify-start items-start rounded-lg h-full shadow-full w-full p-4">
            <button (click)="onFolderClick('PV')"
                    class="flex flex-row justify-start items-center rounded-lg shadow-full w-full p-4 mb-4 hover:bg-gray-200">
              <fa-icon [icon]="faFolderOpen" class="text-primary text-2xl mr-4"></fa-icon>
              Planverzeichnis
              <fa-icon [icon]="faChevronRight" class="text-primary text-2xl ml-auto"></fa-icon>
            </button>
            <button (click)="onFolderClick('PL')"
                    class="flex flex-row justify-start items-center rounded-lg shadow-full w-full p-4 mb-4 hover:bg-gray-200">
              <fa-icon [icon]="faFolderOpen" class="text-primary text-2xl mr-4"></fa-icon>
              Pläne
              <fa-icon [icon]="faChevronRight" class="text-primary text-2xl ml-auto"></fa-icon>
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="selectedFolder !== ''">
          <div class="rounded-md shadow-full w-full h-full overflow-y-scroll">
            <div class="flex flex-row w-full">
              <button (click)="onFolderClick('')" class=" p-2 hover:bg-gray-200">
                <fa-icon [icon]="faChevronLeft" class="text-primary"></fa-icon>
                <span class="text-primary"> Zurück</span>
              </button>
            </div>
            <!--          <div class="flex flex-col w-full h-full">-->
            <app-files-table [projectFiles]="shownFiles" [runningDocumentClassification]="runningDocumentClassification"
                             [selectedFiles]="shownSelectedFiles"
                             class="flex flex-row overflow-hidden">
            </app-files-table>
            <!--          </div>-->

          </div>


        </ng-container>


      </div>

      <div class="flex flex-col w-1/2 gap-4">
        <!--      <div class="flex flex-row pb-2">-->
        <!--        <p class="text-black">Ausgewählte Dateien:</p>-->
        <!--      </div>-->
        <div class="flex flex-row h-full w-full">
          <div class="flex flex-col gap-4 h-full w-full">
            <div class="flex flex-col justify-start items-start rounded-lg shadow-full h-1/2 w-full p-4">
              <div class="flex flex-row w-full text-black rounded-lg">Planverzeichnis</div>
              <div class="overflow-y-scroll w-full items-stretch">
                <div *ngFor="let file of getSelectedPVFiles()"
                     class="flex flex-row justify-between items-start w-full pr-2">
                  <span>{{ file.name }}</span>
                  <span>{{ file.size }}</span>
                </div>
              </div>
              <div *ngIf="getSelectedPVFiles().length === 0"
                   class="flex flex-row justify-center items-center w-full h-full text-gray rounded-lg pr-4">
                keine Datei ausgewählt
              </div>
            </div>
            <div class="flex flex-col justify-start items-start rounded-lg shadow-full h-1/2 w-full p-4">
              <div class="flex flex-row w-full text-black mb-2">Pläne</div>
              <div class="overflow-y-scroll w-full items-stretch">
                <div *ngFor="let file of getSelectedPLFiles()"
                     class="flex flex-row justify-between items-start w-full pr-2">
                  <span>{{ file.name }}</span>
                  <span>{{ file.size }}</span>
                </div>
              </div>
              <div *ngIf="getSelectedPLFiles().length === 0"
                   class="flex flex-row justify-center items-center w-full h-full text-gray">
                keine Datei ausgewählt
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div class="flex flex-row w-full justify-center items-center">
      <button (click)="navigateToChoosingRules()" color="primary" mat-raised-button
              [disabled]="(selectedPVFiles.isEmpty() && selectedPLFiles.isEmpty())">
        Prüfung
        <fa-icon [icon]="faChevronRight" class="text-white"></fa-icon>
      </button>
    </div>

  </div>


</div>





