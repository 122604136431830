<div class="flex flex-col z-40 h-full w-full bg-white shadow-full rounded-[10px] p-2">
  <!--  <div class="flex flex-row">-->
  <div class="flex flex-col">
    <div class="text-red-500 text-[20px] font-medium pt-6 px-8 text-center">Sind Sie sich sicher, dass Sie diesen Standard
      Löschen wollen?
    </div>
    <!--    <div class="px-32 text-center pt-4">Standard: {{ standardName }}</div>-->
    <div class="text-center text-primary font-medium pt-6">Hinweis:</div>
    <div class="px-32 text-center">Bei Bestätigung wird dieser Standard unwiderruflich gelöscht.</div>
    <div class="flex flex-row pt-4 justify-between">
      <mat-dialog-actions class="flex justify-between w-full">
        <button class="bg-white" mat-dialog-close mat-raised-button>Abbrechen</button>
        <div class="flex-grow"></div>
        <button (click)="onDelete()" class="text-white w-28" color="primary" mat-raised-button>
          Löschen
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</div>

