<div class="p-6 overflow-auto bg-white rounded-2xl shadow-item">
  <div class="flex flex-col justify-start items-center gap-4">

    <!-- Headline warning -->
    <div class="text-base font-semibold text-center ">Verlassen ohne Speichern?</div>
    <!-- Explanation text -->
    <div>Wollen Sie die Seite wirklich verlassen, ohne die Änderungen zu speichern?</div>
    <div> Wählen Sie Verlassen um fortzufahren oder Abbrechen, um auf der aktuellen Seite zu bleiben</div>

    <!-- Buttons -->
<!--    <div class="flex flex-row pt-4 w-full justify-between items-center">-->
    <div class="flex flex-row justify-end w-full pt-4 gap-2">
    <mat-dialog-actions class="flex justify-between w-full gap-2">
      <div class="flex-grow"></div>
      <bic-button  [text]="'Verlassen'" [color]="'white'" [textColor]="'gray-900'" [border]="true" (onClick)="checkClicked()"></bic-button>
      <bic-button  [text]="'Abbrechen'"  (onClick)="cancelClicked()"></bic-button>
<!--      <bic-button *ngIf="isListView" [color]="'white'" [textColor]="'gray-900'" [border]="true" [routerLink]="['/projects/create']" [text]="'Projekt'" [iconLeft]="faPlus" class="mr-2"></bic-button>-->

    </mat-dialog-actions>

    </div>

  </div>
</div>

