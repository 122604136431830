import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  BimBaseCheckControllerService,
  CheckControllerService,
  CompletenessCheck,
  IfcControllerService,
  ProjectFile,
  QualityCheck,
  QualityControllerService,
  SimpleCheck, SwmControllerService
} from "../../api";
import {StateConfigService} from "../../services/state/state-config.service";
import {Subscription} from "rxjs";
import {DrawerContentService} from "../../services/drawer-content.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, Sort} from "@angular/material/sort";
import LphEnum = ProjectFile.LphEnum;
import CheckTypeEnum = QualityCheck.CheckTypeEnum;
import {CheckTypePipe} from "../../pipes/check-type.pipe";
import {LodPipe} from "../../pipes/lod.pipe";
import {faBars, faChevronRight, faPlus} from "@fortawesome/free-solid-svg-icons";
import {faArrowDownToBracket} from "@fortawesome/pro-solid-svg-icons";

interface CheckFilter {
  projectId?: number;
  lph?: LphEnum;
}

interface CheckData {
  id: string | undefined;
  date: Date;
  type: string;
  lph_loi: string | undefined;
  checker: string;
  state: string;
  check: SimpleCheck;
}

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {

  displayedColumnsNames = ['Modul', 'Datum', 'LPH/LOI', 'Prüfer \u20F0 in', 'Status']
  displayedColumns: string[] = ['type', 'date', 'lph_loi', 'checker', 'state'];
  columnsTypes = ['text', 'date', 'text', 'text', 'checkStatus']
  columnsWidths = ['20%', '20%', '20%', '20%', '20%']
  columnsAlignment = ['start', 'start', 'start', 'start', 'start']

  dataSource: MatTableDataSource<SimpleCheck> = new MatTableDataSource<SimpleCheck>();

  qualityChecks: SimpleCheck[] = [];
  completenessChecks: SimpleCheck[] = [];
  ifcChecks: SimpleCheck[] = [];
  bimBaseChecks: SimpleCheck[] = [];
  swmChecks: SimpleCheck[] = [];

  data: CheckData[] = [];

  runningChecksSubscription!: Subscription;

  @ViewChild('reportTbSort') reportTbSort = new MatSort();

  timer: number = 0

  constructor(
    private drawerService: DrawerContentService,
    private checkControllerService: CheckControllerService,
    private qualityControllerService: QualityControllerService,
    private ifcControllerService: IfcControllerService,
    private bimBaseCheckControllerService: BimBaseCheckControllerService,
    private router: Router,
    private checkTypePipe: CheckTypePipe,
    private lodTypePipe: LodPipe,
    private activatedRoute: ActivatedRoute,
    private stateConfigService: StateConfigService,
    private swmControllerService: SwmControllerService) {
  }

  ngOnDestroy(): void {
    this.runningChecksSubscription.unsubscribe();
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      if (params.reportId !== undefined) {
        this.router.navigate([params.reportId], {relativeTo: this.activatedRoute});
      }
    });

    this.checkControllerService.getChecks3(this.stateConfigService.getProject()?.id, this.stateConfigService.selectedOrganisation?.id).subscribe(checks => {
      this.completenessChecks = checks;
      this.updateDataSource();

    });

    this.qualityControllerService.getChecks1(this.stateConfigService.getProject()?.id, this.stateConfigService.selectedOrganisation?.id).subscribe(checks => {
      this.qualityChecks = checks;
      this.updateDataSource()
    });

    this.ifcControllerService.getChecks2(this.stateConfigService.getProject()?.id, this.stateConfigService.selectedOrganisation?.id).subscribe(checks => {
      this.ifcChecks = checks;
      this.updateDataSource()
    });

    this.bimBaseCheckControllerService.getChecks4(this.stateConfigService.getProject()?.id, this.stateConfigService.selectedOrganisation?.id).subscribe(checks => {
      this.bimBaseChecks = checks;
      this.updateDataSource()
    });

    this.swmControllerService.getChecks(this.stateConfigService.getProjectId()).subscribe(checks => {
      this.swmChecks = checks;
      this.updateDataSource()
    });

    this.dataSource.filterPredicate = (data: CompletenessCheck, filter: string) => {
      let filterData: CheckFilter = JSON.parse(filter);

      return (filterData.projectId === undefined || this.stateConfigService.getProjectId() === filterData.projectId) &&
        (filterData.lph === undefined || data.lph === filterData.lph);
    }

    this.runningChecksSubscription = this.stateConfigService.runningChecksChange.subscribe(runningChecks => {
      this.dataSource.data = this.dataSource.data.map(check => {
        let runningCheckId = runningChecks.find(id => id === check.id)
        check.completed = runningCheckId === undefined;

        return check;
      });

      this.data = this.data.map(check=>{
        let runningCheckId = runningChecks.find(id => id === check.id)
        check.state = runningCheckId === undefined ? 'completed' : 'running'

        return check;
      })
    });
  }


  updateDataSource() {
    this.data = this.completenessChecks
      .concat(this.qualityChecks)
      .concat(this.ifcChecks)
      .concat(this.bimBaseChecks)
      .concat(this.swmChecks)
      .sort((a, b) => {
        return new Date(b.checkDate!).getTime() - new Date(a.checkDate!).getTime()!;
      })
      .map(check => {
        let checkData: CheckData = {
          id: check.id,
          date: new Date(check.checkDate!),
          type: this.checkTypePipe.transform(check.checkType!),
          lph_loi: check.checkType === CheckTypeEnum.HOAI ? check.lph : check.checkType === CheckTypeEnum.IFC ? this.lodTypePipe.transform(check.lodType!) : '',
          checker: check.checkerName!,
          state: check.completed ? 'completed' : check.failed ? 'failed' : 'running',
          check: check
        }

        return checkData;
      })

  }

  markedOrClicked(event: any) {
    if (event != 0) {
      this.timer = event;
    }
  }

  openCheck(check: SimpleCheck) {

    const projectId = this.stateConfigService.getProjectId()!;
    if (this.timer <= 500) {
      // @ts-ignore
      // @ts-ignore
      switch (check.checkType) {
        case CheckTypeEnum.HOAI:
          if (check.completed) {
            window.open(this.router.serializeUrl(this.router.createUrlTree(['projects', projectId, 'hoai', check.id])), '_blank');
          }
          break;
        case CheckTypeEnum.QUALITY:
          if (check.completed) {
            window.open(this.router.serializeUrl(this.router.createUrlTree(['projects', projectId, 'quality', check.id, 'report'])), '_blank');
          }
          break;

        case CheckTypeEnum.IFC:
          if (check.completed) {
            window.open(this.router.serializeUrl(this.router.createUrlTree(['projects', projectId, 'reports', 'ifc', check.id])), '_blank');
          }
          break;
        case CheckTypeEnum.BASE:
          if (check.completed) {
            window.open(this.router.serializeUrl(this.router.createUrlTree(['projects', projectId, 'reports', 'bim-base', check.id])), '_blank');
            break;
          }
          break;
        case CheckTypeEnum.SWM:
          if (check.completed) {
            window.open(this.router.serializeUrl(this.router.createUrlTree(['projects', projectId, 'fireprotection', check.id])), '_blank');
            break;
          }
          break;
        default:
          break;
      }
    }
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }


    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return this.compare(a.checkDate!, b.checkDate!, isAsc);
        case 'type':
          return this.compare(a.checkType!, b.checkType!, isAsc);
        case 'lph':
          return this.compare(a.lph!, b.lph!, isAsc);
        case 'checker':
          return this.compare(a.checkerName!, b.checkerName!, isAsc);
        case 'state':
          return this.compare(a.completed!, b.completed!, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


    protected readonly faChevronRight = faChevronRight;
  protected readonly faBars = faBars;
  protected readonly faPlus = faPlus;
  protected readonly faArrowDownToBracket = faArrowDownToBracket;
}
