<div [formGroup]="formGroup" *ngIf="formGroup">
  <div class="flex flex-col justify-between items-start w-full">
    <div class="flex flex-row gap-2 w-full">
      <div class="flex flex-row justify-between w-full gap-12">

        <div class="flex flex-col w-[50%]">
<!--          <mat-form-field appearance="outline" subscriptSizing="dynamic">-->
<!--            <mat-label>Straße</mat-label>-->
<!--            <input #street required [formControlName]="formControlNameStreet" matInput [matAutocomplete]="autoStreet" (ngModelChange)="locationGuesses$ = serviceControllerService.getLocationSuggestions(street.value)">-->
<!--            <mat-autocomplete #autoStreet="matAutocomplete">-->
<!--              <mat-option *ngFor="let location of (locationGuesses$ | async) ?? []" [value]="location.street" (onSelectionChange)="updateLocation(location)">-->
<!--                {{location.street}} {{location.addressNumber}}, {{location.postalCode}} {{location.municipality}}-->
<!--              </mat-option>-->
<!--            </mat-autocomplete>-->
<!--          </mat-form-field>-->

          <bic-input [bicDropdownTrigger]="dropdown" #street class="w-full" [formControlName]="formControlNameStreet" [label]="'Straße *'" [required]="true" (change)="test(street.value); locationGuesses$ = serviceControllerService.getLocationSuggestions(street.value)" (valueChange)="test(street.value); locationGuesses$ = serviceControllerService.getLocationSuggestions(street.value)">
          </bic-input>

          <bic-dropdown #dropdown dropdownPlacement="bottom-start" [widthOfTriggerElement]="true">
            <ng-container *ngFor="let location of (locationGuesses$ | async) ?? []">
              <bic-dropdown-element (onClick)="updateLocation(location)" [value]="location.street">
                {{location.street}} {{location.addressNumber}}, {{location.postalCode}} {{location.municipality}}
              </bic-dropdown-element>
            </ng-container>
          </bic-dropdown>

          <div class="flex flex-row w-full gap-12 pt-6">
            <bic-input *ngIf="!zipCodeDisabled" [formControlName]="formControlNameZipCode" [label]="'Postleitzahl *'" [required]="true" class="w-[50%]"></bic-input>

<!--            -->
<!--            <mat-form-field *ngIf="!zipCodeDisabled" appearance="outline" class="flex-grow" subscriptSizing="dynamic">-->
<!--              <mat-label>Postleitzahl</mat-label>-->
<!--              <input required [formControlName]="formControlNameZipCode" matInput maxlength="5">-->
<!--            </mat-form-field>-->

            <bic-input *ngIf="!cityDisabled" [formControlName]="formControlNameCity" [label]="'Ort *'" [required]="true" class="w-[50%]"></bic-input>
<!--            -->
<!--            <mat-form-field *ngIf="!cityDisabled"  appearance="outline" class="flex-grow" subscriptSizing="dynamic">-->
<!--              <mat-label>Ort</mat-label>-->
<!--              <input required [formControlName]="formControlNameCity" matInput>-->
<!--            </mat-form-field>-->
          </div>
        </div>

        <div class="flex flex-col w-[50%] gap-6">
          <div class="flex flex-row gap-12 w-full">
            <bic-input [formControlName]="formControlNameHouseNumber" [label]="'Hausnummer *'" [required]="true" class="w-full"></bic-input>

<!--            <mat-form-field appearance="outline" class="flex-grow" subscriptSizing="dynamic">-->
<!--              <mat-label>Hausnummer</mat-label>-->
<!--              <input required [formControlName]="formControlNameHouseNumber" matInput>-->
<!--            </mat-form-field>-->

            <bic-input *ngIf="displayPlotNumber" [formControlName]="formControlNamePlotNumber" [label]="'Flurstücksnummer'" class="w-full"></bic-input>


<!--            <mat-form-field appearance="outline" class="flex-grow" *ngIf="displayPlotNumber" subscriptSizing="dynamic">-->
<!--              <mat-label>Flurstücksnummer</mat-label>-->
<!--              <input matInput [formControlName]="formControlNamePlotNumber">-->
<!--            </mat-form-field>-->
          </div>

          <div class="flex flex-row gap-16 w-full">

            <bic-select *ngIf="!countryDisabled" [formControlName]="formControlNameCountry" [label]="'Land *'" [required]="true" class="w-full">
              <bic-dropdown-element *ngFor="let country of countries | sortCountries |keyvalue: valueAscOrder" [value]="country.key">
                {{country.value}}
              </bic-dropdown-element>
            </bic-select>

<!--            <mat-form-field *ngIf="!countryDisabled" appearance="outline" class="flex-grow" subscriptSizing="dynamic">-->
<!--              <mat-label>Land</mat-label>-->
<!--              <mat-select [formControlName]="formControlNameCountry">-->
<!--                <mat-option *ngFor="let country of countries | sortCountries |keyvalue: valueAscOrder" [value]="country.key">-->
<!--                  {{country.value}}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->

            <bic-select *ngIf="!displayState" [formControlName]="formControlNameState" [label]="'Bundesland *'" [required]="true" class="w-full">
              <bic-dropdown-element *ngFor="let state of states | keyvalue" [value]="state.value">
                {{state.value | projectState}}
              </bic-dropdown-element>
            </bic-select>

<!--            <mat-form-field *ngIf="displayState" appearance="outline" class="flex-grow" subscriptSizing="dynamic" >-->
<!--              <mat-label>Bundesland</mat-label>-->
<!--              <mat-select [formControlName]="formControlNameState" [disabled]="true">-->
<!--                <mat-option *ngFor="let state of states | keyvalue" [value]="state.value">-->
<!--                  {{state.value | projectState}}-->
<!--                </mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
          </div>
        </div>









        <!--
              <mat-form-field appearance="outline" class="flex-grow" >
                <mat-label>Straße</mat-label>
                <input #street required [formControlName]="formControlNameStreet" matInput [matAutocomplete]="autoStreet" (ngModelChange)="locationGuesses$ = serviceControllerService.getLocationSuggestions(street.value)">
                <mat-autocomplete #autoStreet="matAutocomplete">
                  <mat-option *ngFor="let location of (locationGuesses$ | async) ?? []" [value]="location.street" (onSelectionChange)="updateLocation(location)">
                    {{location.street}} {{location.addressNumber}}, {{location.postalCode}} {{location.municipality}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Hausnummer</mat-label>
                <input required [formControlName]="formControlNameHouseNumber" matInput>
              </mat-form-field>

              <mat-form-field appearance="outline" *ngIf="displayPlotNumber">
                <mat-label>Flurstücksnummer</mat-label>
                <input matInput [formControlName]="formControlNamePlotNumber">
              </mat-form-field>



            </div>

            <div class="flex flex-row gap-2 w-full">
              <mat-form-field *ngIf="!zipCodeDisabled" appearance="outline" class="flex-grow">
                <mat-label>Postleitzahl</mat-label>
                <input required [formControlName]="formControlNameZipCode" matInput maxlength="5">
              </mat-form-field>

              <mat-form-field *ngIf="!cityDisabled"  appearance="outline" class="flex-grow">
                <mat-label>Ort</mat-label>
                <input required [formControlName]="formControlNameCity" matInput>
              </mat-form-field>

              <mat-form-field *ngIf="!countryDisabled" appearance="outline" class="flex-grow">
                <mat-label>Land</mat-label>
                <mat-select [formControlName]="formControlNameCountry">
                  <mat-option *ngFor="let country of countries | sortCountries |keyvalue: valueAscOrder" [value]="country.key">
                    {{country.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="!stateDisabled" appearance="outline" class="flex-grow">
                <mat-label>Bundesland</mat-label>
                <mat-select [formControlName]="formControlNameState">
                  <mat-option *ngFor="let state of states | keyvalue" [value]="state.value">
                    {{state.value | projectState}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>-->
      </div>
    </div>
  </div>

</div>
