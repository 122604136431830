<div class="flex flex-row w-full px-6 py-3.5 text-xl text-black items-center font-medium shadow-full-new" *ngIf="!selectedLph">
  <div class="flex flex-row justify-start items-center gap-2 w-full h-10">
    <button class="flex flex-row justify-start items-center gap-2 p-2 -ml-2 hover:bg-gray-200 rounded-md text-black text-base" >
      Dateien
    </button>
    <div>
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </div>
  </div>
</div>
<div class="flex flex-col justify-start items-start gap-2 h-full p-4" *ngIf="!selectedLph">
  <div class="flex flex-row justify-start items-start flex-wrap gap-10 overflow-y-scroll p-2 -ml-2">
    <ng-container *ngFor="let lph of stateConfigService.lphEnum | keyvalue; let i = index">
      <bic-card class="flex flex-col justify-start p-2" *ngIf="lph.value != stateConfigService.lphEnum.PLANS && lph.value != stateConfigService.lphEnum.GENERAL"
                (click)="navigateToLPH(lph.value)">

        <div class="flex flex-col justify-between h-full w-full">
          <!-- Folder Name and Menu Icon -->
          <div class=" flex flex-col justify-start gap-2">
          <div class="flex flex-row justify-between items-start">
            <div class="flex flex-row justify-start items-center gap-4 pl-2 pt-2 w-full">
              <fa-icon [icon]="faFolderOpen" class="text-primary text-2xl"></fa-icon>

              <div class="text-lg font-medium text-primary flex flex-row items-start justify-start w-full">
                Leistungsphase {{lph.value | lph}}
              </div>
            </div>
          </div>
            <div class="flex flex-row px-2 pb-2">
              <div class="text-lg font-medium flex flex-row items-start justify-start w-full">
                {{lph.value | lphName}}
              </div>
            </div>
          </div>


            <!--          <div class="text-lg pr-1" #overlayTrigger (mouseenter)="openedFolderOverlay = folder" (mouseleave)="openedFolderOverlay = null">-->
            <!--            <fa-icon [icon]="faCircleQuestion" class="text-primary text-2xl" #infoIcon></fa-icon>-->
            <!--          </div>-->



            <!--          <ng-template-->
            <!--            cdkConnectedOverlay-->
            <!--            [cdkConnectedOverlayOrigin]="overlayTrigger"-->
            <!--            [cdkConnectedOverlayOpen]="openedFolderOverlay == folder"-->
            <!--          >-->
            <!--            <div class="text-xs bg-white rounded-[5px] p-[5px] shadow-full break-words w-full">-->
            <!--              <div [innerHTML]="getInfotext(folder)"></div>-->
            <!--            </div>-->
            <!--          </ng-template>-->
            <!--        </div>-->



<!--             Folder Information -->
                    <div class="flex flex-col justify-center gap-3 pb-4 text-md">


                      <div class="flex flex-row gap-2 justify-between items-center" *ngIf="calculateSizeInGB(lphData(lph.value).size) < 1">
                        <div class="pl-2">Größe</div>
                        <div class="pr-2">{{calculateSizeInMB(lphData(lph.value).size) | number: '.0-2'}} MB</div>
                      </div>

                      <div class="flex flex-row gap-2 justify-between items-center" *ngIf="calculateSizeInGB(lphData(lph.value).size) >= 1">
                        <div class="pl-2">Größe</div>
                        <div class="pr-2">{{calculateSizeInGB(lphData(lph.value).size) | number: '.0-2'}} GB</div>
                      </div>

                      <div class="flex flex-row gap-2 justify-between items-center">
                        <div class="pl-2">Dateien</div>
                        <div class="pr-2">{{lphData(lph.value).numberOfFiles}}</div>
                      </div>

<!--                      <div class="flex flex-row gap-2 justify-between items-center" *ngIf="false">-->
<!--                        <div class="pl-2">Zuletzt geändert</div>-->
<!--                        <div class="pr-2">03.06.2023</div>-->
<!--                      </div>-->
                    </div>
        </div>

      </bic-card>
    </ng-container>
  </div>
</div>
<app-project-files *ngIf="selectedLph"></app-project-files>
