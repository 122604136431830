<div *ngIf="hasTouchScreen"
     class="flex justify-center items-center w-full h-screen bg-gradient-to-br from-secondary from-0% via-[#042E3F] via-15% to-primary to-90% pt-16 pb-32">
  <div class="bg-white rounded-md mx-6 my-8 h-full w-full overflow-auto gap-8">
    <div class="flex w-full flex-col justify-center items-center">
      <img class="w-56 h-56 " height="224" ngSrc="assets/logo_gradient.svg" width="224">
      <a class="text-[30px] font-bold font-brand text-secondary -mt-6">planBIC</a>
    </div>
    <div class="flex flex-col w-full- h-full p-4">
      <div class="flex flex-col text-center text-base text-medium mt-4 px-8">Unsere App ist derzeit leider nicht für
        Mobilgeräte optimiert.
      </div>
      <div class="flex flex-col text-base text-center mt-4 px-6">Zur optimalen Nutzung empfehlen wir Laptops oder
        Tablets mit einer
        Auflösung von 1080 x 1920 Pixeln oder mehr.
      </div>
      <div class="text-center text-base mt-6 px-6">
        Weitere Informationen zur App und Hardwarevoraussetzungen finden Sie
        <a href="https://www.thinkbic.de/" class="text-primary underline" target="_blank"
           rel="noopener noreferrer">hier</a>!
      </div>
    </div>
  </div>
</div>


<div *ngIf="!hasTouchScreen" class="flex h-full w-full flex-col items-start justify-start bg-white ">
  <div class="flex h-full w-full flex-row overflow-hidden shadow-full">
    <div *ngIf=" authService.isLoggedIn()"
         class="flex flex-col justify-between bg-secondary shadow-right-side text-white w-64  h-full">


      <div class="flex flex-col h-full">

        <!--        Sidebar with unselected project-->
        <div *ngIf="authService.isLoggedIn() && stateConfigService.selectedProject != undefined"
             class="flex flex-col h-full overflow-x-hidden w-64">

          <ng-container *ngIf="stateConfigService.getFeatureConfig()">
            <div class="flex flex-row justify-center items-center pb-5 pt-4"
                 *ngIf="stateConfigService.getFeatureConfig().swmCheck ?? false">
              <div class="flex flex-col justify-start items-center gap-1">
                <img class="h-[76px] xl:h-[76px] hover:cursor-pointer" src="assets/SWM.svg"
                     (click)="stateConfigService.selectProject(undefined)" alt="">
                <div class="text-lg font-extrabold font-brand">goBIC</div>
              </div>
            </div>

            <div class="flex flex-row justify-center items-center pb-5 pt-4"
                 *ngIf="!stateConfigService.getFeatureConfig().swmCheck ?? true">
              <div class="flex flex-col justify-start items-center gap-1">
                <img class="h-[76px] xl:h-[76px] hover:cursor-pointer" src="assets/logo_white.svg"
                     (click)="stateConfigService.selectProject(undefined)" alt="thinkBIC">
                <div class="text-lg font-extrabold font-brand">planBIC</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!stateConfigService.getFeatureConfig()">
            <div class="flex flex-row justify-center items-center pb-5 pt-4">
              <div class="flex flex-col justify-start items-center gap-1">
                <img class="h-[76px] xl:h-[76px] hover:cursor-pointer" src="assets/logo_white.svg"
                     (click)="stateConfigService.selectProject(undefined)" alt="thinkBIC">
                <div class="text-lg font-extrabold font-brand">planBIC</div>
              </div>
            </div>
          </ng-container>


          <app-navbar class="flex flex-col w-full"></app-navbar>

          <app-sidebar [(opened)]="sidebarOpened"
                       class="flex flex-col h-full justify-between content-between overflow-hidden w-64 pt-1 sidebar">
            <div class="flex flex-col gap-1 w-full">
              <div class="font-medium pt-2 pl-6 text-xs text-gray-400">Allgemein</div>

              <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                [title]="sidebarCollapsed ? '' : 'Dashboard'" [icon]="faGrid2"
                                [selected]="'dashboard'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'dashboard']"
                                disabled></app-sidebar-item>

              <ng-container *ngIf="stateConfigService.getFeatureConfig()">
                <app-sidebar-item *ngIf="!stateConfigService.getFeatureConfig().swmCheck ?? true" class="w-full"
                                  [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                  [title]="sidebarCollapsed ? '' : 'Dateien'" [icon]="faFolderOpen"
                                  [selected]="'files'"
                                  [routerLink]="['projects', stateConfigService.selectedProject.id, 'files']"
                                  [queryParams]="{lph: undefined}" [queryParamsHandling]="'merge'"></app-sidebar-item>
                <app-sidebar-item *ngIf="stateConfigService.getFeatureConfig().swmCheck ?? false" class="w-full"
                                  [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                  [title]="sidebarCollapsed ? '' : 'Dateien'" [icon]="faFolderOpen"
                                  [selected]="'files'"
                                  [routerLink]="['projects', stateConfigService.selectedProject.id, 'swm', 'files']"
                                  [queryParams]="{lph: undefined}" [queryParamsHandling]="'merge'"></app-sidebar-item>
              </ng-container>
              <ng-container *ngIf="!stateConfigService.getFeatureConfig()">
                <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                  [title]="sidebarCollapsed ? '' : 'Dateien'" [icon]="faFolderOpen"
                                  [selected]="'files'"
                                  [routerLink]="['projects', stateConfigService.selectedProject.id, 'files']"
                                  [queryParams]="{lph: undefined}" [queryParamsHandling]="'merge'"></app-sidebar-item>
              </ng-container>

              <!--          Tasks-->
              <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed" title="Tasks"
                                [icon]="faClipboardCheck" [selected]="'tasks'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'tasks']"></app-sidebar-item>

              <!--          Bericht-->
              <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                [title]="sidebarCollapsed ? '' : 'Berichte'"
                                [icon]="faReport" [selected]="'reports'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'reports']"></app-sidebar-item>

              <div class="font-medium pt-2 pl-6 text-xs text-gray-400">Prüfungen</div>

              <!--            HOAI Check-->
              <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                [title]="sidebarCollapsed ? '' : 'HOAI-Check'"
                                [icon]="faMemoCircleCheck" [selected]="'hoai'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'hoai']"></app-sidebar-item>

              <!--            BIM Basis Check-->
              <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                title="BIM-Basis-Check"
                                [icon]="faCube" [selected]="'bim-basis-check'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'bim-basis-check']"
                                [beta]="false" [ifc]="true"
                                *ngIf="stateConfigService.selectedProject.isBIM"></app-sidebar-item>

              <!--            LOI Check-->
              <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed" title="LOI-Check"
                                [icon]="faCircleInfo" [selected]="'loi-check'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'loi-check']"
                                [beta]="false" *ngIf="stateConfigService.selectedProject.isBIM"></app-sidebar-item>

              <!--            Quality Check-->
              <app-sidebar-item [beta]="false" class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                [title]="sidebarCollapsed ? '' : 'Qualitäts-Check'"
                                [icon]="faBadgeCheck" [selected]="'quality'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'quality']"></app-sidebar-item>

              <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                *ngIf="stateConfigService.selectedProject.id == 44 || stateConfigService.selectedProject.id == 72 || stateConfigService.selectedProject.id == 77"
                                [title]="sidebarCollapsed ? '' : 'Planprüfung'"
                                [icon]="faChartSimple" [selected]="'plan'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'plan']">
              </app-sidebar-item>

              <ng-container *ngIf="stateConfigService.getFeatureConfig()">
                <div class="font-light pt-6 pl-6 text-xs"
                     *ngIf="stateConfigService.getFeatureConfig().swmCheck ?? false">SWM-Prüfungen
                </div>

                <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                  [title]="sidebarCollapsed ? '' : 'Brandschutz-Check'" [tooltip]="'Brandschutz-Check'"
                                  [icon]="faCheck"
                                  [routerLink]="['projects', stateConfigService.selectedProject.id, 'fireprotection']"
                                  [selected]="'fireprotection'"
                                  *ngIf="stateConfigService.getFeatureConfig().swmCheck ?? false"></app-sidebar-item>
              </ng-container>

              <div class="font-medium pt-2 pl-6 text-xs text-gray-400">Tools</div>

              <!--          Bauantragstool-->
              <app-sidebar-item class="w-full" [disabled]="!applicationFromActivated()" [opened]="sidebarOpened"
                                [collapsed]="sidebarCollapsed" [title]="sidebarCollapsed ? '' : 'Bauantragstool'"
                                [icon]="faStamp" [selected]="'application'"
                                [routerLink]="hasApplicationTool() ? ['projects', stateConfigService.selectedProject.id, 'application'] : ['projects', stateConfigService.selectedProject.id]"
                                [comingSoon]="!hasApplicationTool()">
              </app-sidebar-item>

              <!--          IFC-Viewer-->
              <app-sidebar-item class="w-full" [opened]="sidebarOpened" [collapsed]="sidebarCollapsed"
                                [title]="sidebarCollapsed ? '' : 'IFC-Viewer'" [icon]="faIfc" [selected]="'ifc-viewer'"
                                routerLink="ifc-viewer" *ngIf="stateConfigService.selectedProject.isBIM">
              </app-sidebar-item>

              <!--          Chat-BIC-->
              <app-sidebar-item class="w-full" [collapsed]="sidebarCollapsed" [opened]="sidebarOpened"
                                title="chatBIC" [icon]="faChatbicAi" [selected]="'chat'"
                                [routerLink]="['projects', stateConfigService.selectedProject.id, 'chat']">
              </app-sidebar-item>
            </div>

          </app-sidebar>

          <app-sidebar-footer></app-sidebar-footer>
        </div>

        <!--        Sidebar without selected project-->
        <div
          *ngIf="authService.isLoggedIn() && stateConfigService.selectedProject == undefined && !popoutContentService.opened"
          class="flex flex-col h-full">

          <ng-container *ngIf="stateConfigService.getFeatureConfig()">
            <div class="flex flex-row justify-center items-center pb-5 pt-4"
                 *ngIf="stateConfigService.getFeatureConfig().swmCheck ?? false">
              <div class="flex flex-col justify-start items-center gap-1">
                <img class="h-[76px] xl:h-[76px] hover:cursor-pointer" src="assets/SWM.svg"
                     (click)="stateConfigService.selectProject(undefined)" alt="">
                <div class="text-lg font-extrabold font-brand">goBIC</div>
              </div>
            </div>
            <div class="flex flex-row justify-center items-center pb-5 pt-4"
                 *ngIf="!stateConfigService.getFeatureConfig().swmCheck ?? true">
              <div class="flex flex-col justify-start items-center gap-1">
                <img class="h-[76px] xl:h-[76px] hover:cursor-pointer" src="assets/logo_white.svg"
                     (click)="stateConfigService.selectProject(undefined)" alt="thinkBIC">
                <div class="text-lg font-extrabold font-brand">planBIC</div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!stateConfigService.getFeatureConfig()">
            <div class="flex flex-row justify-center items-center pb-5 pt-4">
              <div class="flex flex-col justify-start items-center gap-1">
                <img class="h-[76px] xl:h-[76px] hover:cursor-pointer" src="assets/logo_white.svg"
                     (click)="stateConfigService.selectProject(undefined)" alt="thinkBIC">
                <div class="text-lg font-extrabold font-brand">planBIC</div>
              </div>
            </div>
          </ng-container>

          <app-navbar class="flex flex-col w-full"></app-navbar>

          <app-sidebar [(opened)]="sidebarOpened"
                       class="flex flex-col h-full justify-between content-between overflow-hidden w-64">
            <div class="flex flex-col gap-1 w-full">
              <div class="font-medium pt-2 pl-6 text-xs text-gray-400">Allgemein</div>
              <app-sidebar-item class="w-full" [opened]="sidebarOpened" title="Tasks" [icon]="faClipboardCheck"
                                [routerLink]="['tasks']" [selected]="'tasks'"></app-sidebar-item>
              <app-sidebar-item class="w-full" [opened]="sidebarOpened" title="Berichte" [icon]="faReport"
                                [routerLink]="['reports']" [selected]="'reports'"></app-sidebar-item>
              <!--        <app-sidebar-item [beta]="true" class="w-full" [opened]="sidebarOpened" title="IFC-Viewer" [icon]="faEye"-->
              <!--                          routerLink="ifc-viewer"></app-sidebar-item>-->
            </div>
          </app-sidebar>
          <app-sidebar-footer></app-sidebar-footer>
        </div>
      </div>
    </div>


    <div class="w-full flex flex-row justify-start items-start gap-2 z-0">
      <div *ngIf="loadingService.isLoading"
           class="absolute top-0 left-0 flex h-full w-full flex-row items-center justify-center backdrop-blur-sm z-[10000]">
        <app-cloud-number [last]="true" [loading]="true"></app-cloud-number>
      </div>

      <div *ngIf="!drawerContentService.large" [ngClass]="{'bg-white rounded-sm ': authService.isLoggedIn()}"
           class="flex-grow h-full">
        <div class="flex flex-col h-full ">
          <div class="flex-grow h-full">
            <router-outlet></router-outlet>
          </div>


        </div>
      </div>

      <div *ngIf="drawerContentService.opened"
           [ngClass]="{'w-full': drawerContentService.large, 'w-1/2': !drawerContentService.large}"
           class="flex flex-col h-full justify-start items-start bg-white rounded-sm shadow-full-old overflow-y-scroll">
        <div class="flex h-10 w-full flex-row items-center justify-between">
          <div>
            <button mat-icon-button *ngIf="!drawerContentService.config.fixedSize"
                    (click)="drawerContentService.toggleSize()">
              <mat-icon *ngIf="!drawerContentService.large">chevron_left</mat-icon>
              <mat-icon *ngIf="drawerContentService.large">chevron_right</mat-icon>
            </button>
            <button mat-icon-button *ngIf="drawerContentService.config.deleteAction"
                    (click)="drawerContentService.config.deleteAction()">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button (click)="drawerContentService.config.saveAction()"
                    *ngIf="drawerContentService.config.saveAction">
              <mat-icon>save</mat-icon>
            </button>
          </div>

          <div>
            {{ drawerContentService.config.title }}
          </div>

          <button mat-icon-button (click)="drawerContentService.close()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div class="flex-grow w-full h-96">
          <ng-container *ngComponentOutlet="drawerContentService.component"></ng-container>
        </div>
      </div>
    </div>

  </div>
</div>
