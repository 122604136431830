<div class="flex flex-col justify-center items-center p-2">
  <div class="flex flex-row p-2">
    <h1 *ngIf="!data.isProject && organisation">Zur Organisation "{{organisation.name}}" hinzufügen</h1>
    <h1 *ngIf="data.isProject && project">Projektmitglieder zu Projekt "{{ project.name }}" hinzufügen</h1>
  </div>
  <div class="flex flex-col justify-center items-center p-2 w-full">
    <mat-form-field appearance="outline" class="flex w-full pb-4" subscriptSizing="dynamic">
      <input #userMail (matChipInputTokenEnd)="add($event)" (keyup.enter)="addUserByMails()" [matAutocomplete]="userAuto"
             class="flex w-full"
             matInput
             placeholder="Einladen per E-Mail ...">
      <mat-autocomplete #userAuto="matAutocomplete" (optionSelected)="selected($event)">
        <ng-container *ngIf="data.isProject">
          <mat-option *ngFor="let option of filteredUsers" [value]="option.mail">
            {{option.mail}}
          </mat-option>
        </ng-container>
      </mat-autocomplete>
    </mat-form-field>
    <div class="flex flex-col">
      <div class="flex flex-row" *ngFor="let mail of usersToAdd">
        <mat-chip class="m-1" (removed)="removeFromToAdd(mail)">
          {{mail}}
        <button matChipRemove>
          <fa-icon [icon]="faCircleXmark" class="text-black"></fa-icon>
        </button>
        </mat-chip>

      </div>

    </div>





<!--    <mat-chip-listbox class="mat-mdc-chip-set-stacked" aria-label="Color selection">-->
<!--      <mat-chip-option *ngFor="let mail of usersToAdd" class="w-1/2" (removed)="removeFromToAdd(mail)">-->
<!--        {{mail}}-->
<!--        <button matChipRemove>-->
<!--          <fa-icon [icon]="faCircleXmark" class="text-black"></fa-icon>-->
<!--        </button>-->
<!--      </mat-chip-option>-->
<!--    </mat-chip-listbox>-->

    <!--<mat-chip-listbox class="flex flex-col justify-start mat-mdc-chip-set-stacked"  #chipList>
      <mat-chip-option class="flex flex-row w-full" *ngFor="let mail of usersToAdd" (removed)="removeFromToAdd(mail)">
        {{mail}}
        <button matChipRemove>
          <fa-icon [icon]="faCircleXmark" class="text-black"></fa-icon>
        </button>
      </mat-chip-option>
    </mat-chip-listbox>-->
  </div>
</div>

<mat-dialog-actions align="center" class="flex flex-row justify-between space-x-60">
  <button class="shadow-full rounded-md p-2" mat-dialog-close>
    Abbrechen
  </button>

<!--  <button (click)="addUserByMails()" class="bg-primary text-white shadow-full rounded-md p-2">-->
<!--    Hinzufügen-->
<!--  </button>-->
  <bic-button [color]="'white'" [textColor]="'gray-900'" [border]="true" (onClick)="addUserByMails()" [text]="'Hinzufügen'" [iconLeft]="faUserPlus" class="mr-2"></bic-button>

</mat-dialog-actions>

