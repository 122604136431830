import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  faBars,
  faBurger,
  faChevronRight,
  faEllipsisVertical,
  faGrip,
  faHouse,
  faInfo,
  faListCheck,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {faCircleQuestion, faFile, faFolderOpen,} from "@fortawesome/free-regular-svg-icons";
import {StateConfigService} from "../../services/state/state-config.service";
import {DashboardControllerService, SimpleCheck} from 'src/app/api';
import {ProjectFile, ProjectFileData} from "../../api";
import FolderEnum = ProjectFile.FolderEnum;
import LphEnum = SimpleCheck.LphEnum;
import {faGrid2} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'app-project-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {

  folders: typeof FolderEnum = FolderEnum;
  breadcrumbNavigation: ProjectFile[] = [];

  selectedFolder!: FolderEnum
  openedFolderOverlay: FolderEnum | null = null;

  isExpanded: boolean = false;
  showToggle: boolean = false;

  projectFileData: { [p: string]: ProjectFileData } = {};

  isCardView: boolean = true;
  isListView: boolean = false;

  constructor(private route: ActivatedRoute,
              private dashboardControllerService: DashboardControllerService,
              private router: Router, public stateConfigService: StateConfigService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.route.parent?.queryParams.subscribe(params => {
        if (params.lph) {
          this.stateConfigService.selectedServicePhase = params.lph;
          this.selectedFolder = params.folder;

          this.dashboardControllerService.getProjectFileData(
            this.stateConfigService.getProjectId(),
            this.stateConfigService.selectedServicePhase).subscribe(projectFiles => {
            this.projectFileData = projectFiles;
          })

        }
      });
    });
  }

  folderData(folder: FolderEnum): ProjectFileData {
    return this.projectFileData[folder] ?? {size: 0, numberOfFiles: 0};
  }

  get lph(): LphEnum {
    return this.stateConfigService.selectedServicePhase;
  }

  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }

  calculateSizeInGB(size: number): number {
    return size / 1024 / 1024 / 1024;
  }

  getFoldersFor(lph: LphEnum): Set<FolderEnum> {
    switch (lph) {
      case LphEnum.LPH1:
        return FilesComponent.lph1;
      case LphEnum.LPH2:
        return FilesComponent.lph2;
      case LphEnum.LPH3:
        return FilesComponent.lph3;
      case LphEnum.LPH4:
        return FilesComponent.lph4;
      case LphEnum.LPH5:
        return FilesComponent.lph5;
      case LphEnum.LPH6:
        return FilesComponent.lph6;
      default:
        return new Set();
    }
  }

  navigateToLPH(lph: LphEnum): void {
    this.router.navigate([], {queryParams: {lph: lph}});
  }

  navigateToHome(): void {
    this.router.navigate([]);
  }

  openFolder(folder: FolderEnum): void {
    this.router.navigate([], {queryParams: {folder: folder}, queryParamsHandling: "merge"}).then(() => {
      this.selectedFolder = folder;
    });
  }

  public static readonly lph1: Set<FolderEnum> = new Set([
    FolderEnum.BESTANDSAUFNAHME,
    FolderEnum.KOSTEN,
    FolderEnum.TERMINE,
    FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH,
    FolderEnum.UMWELTTECHNISCHEBELANGE,
    FolderEnum.BEDARFSPLANUNGUNDERMITTLUNG,
    FolderEnum.STANDORTANALYSE,
    FolderEnum.BETRIEBSPLANUNG,
    FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN
  ]);

  public static readonly lph2: Set<FolderEnum> = new Set([
    FolderEnum.BESTANDSAUFNAHME,
    FolderEnum.KOSTEN,
    FolderEnum.TERMINE,
    FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH,
    FolderEnum.UMWELTTECHNISCHEBELANGE,
    FolderEnum.PLANUNGUNDBIM,
    FolderEnum.BAURECHTBAUGENEHMIGUNG,
    FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN
  ]);

  public static readonly lph3: Set<FolderEnum> = new Set([
    FolderEnum.BESTANDSAUFNAHME,
    FolderEnum.KOSTEN,
    FolderEnum.TERMINE,
    FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH,
    FolderEnum.UMWELTTECHNISCHEBELANGE,
    FolderEnum.PLANUNGUNDBIM,
    FolderEnum.BAURECHTBAUGENEHMIGUNG,
    FolderEnum.OBJEKTBESCHREIBUNG,
    FolderEnum.GUTACHTEN,
    FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN
  ]);


  public static readonly lph4: Set<FolderEnum> = new Set([
    FolderEnum.BESTANDSAUFNAHME,
    FolderEnum.PLANUNGUNDBIM,
    FolderEnum.BAURECHTBAUGENEHMIGUNG,
    FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH,
    FolderEnum.KOSTEN,
    FolderEnum.OBJEKTBESCHREIBUNG,
    FolderEnum.GUTACHTEN,
    FolderEnum.STATIK,
    FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN
  ]);

  public static readonly lph5: Set<FolderEnum> = new Set([
    FolderEnum.BESTANDSAUFNAHME,
    FolderEnum.KOSTEN,
    FolderEnum.TERMINE,
    FolderEnum.FUNKTIONSRAUMPROGRAMMRAUMBUCH,
    FolderEnum.UMWELTTECHNISCHEBELANGE,
    FolderEnum.PLANUNGUNDBIM,
    FolderEnum.BAURECHTBAUGENEHMIGUNG,
    FolderEnum.OBJEKTBESCHREIBUNG,
    FolderEnum.GUTACHTEN,
    FolderEnum.STATIK,
    FolderEnum.WEITEREBAUPROJEKTUNTERLAGEN
  ]);

  public static readonly lph6: Set<FolderEnum> = new Set([
    FolderEnum.LEISTUNGSVERZEICHNIS,
  ]);

  getPreviewText(folder: FolderEnum): string {
    const lines = this.getInfotext(folder).split('<br>');
    if (lines.length > 5) {
      this.showToggle = true;
      if (!this.isExpanded) {
        return lines.slice(0, 4).join('<br>') + '<br>';
      }
      return this.getInfotext(folder);
    }
    this.showToggle = false;
    return this.getInfotext(folder);
  }

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }

  getInfotext(folder: FolderEnum):string{
    switch (this.stateConfigService.selectedServicePhase){
      case "LPH1": {
        switch (folder){
          case "BESTANDSAUFNAHME": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br> – Bestandsunterlagen<br>" +
            "– Bestandsaufnahmen<br>" +
            "– Technische Substanzerkundungen"
          case "KOSTEN": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>– Wirtschaftlichkeitsuntersuchungen"
          case "TERMINE": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +

            "– Terminpläne <br>" +
            "– Projektstrukturpläne"
          case "FUNKTIONS_RAUMPROGRAMM_RAUMBUCH": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Funktionsprogramme<br>" +
            "– Raumprogramme<br>" +
            "– Raumbücher<br>" +
            "– Flächenberechnungen (BGF, BRI)"
          case "UMWELTTECHNISCHE_BELANGE": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Umwelterheblichkeit des Projektes<br>" +
            "– Umweltverträglichkeit des Projektes<br>" +
            "– Zertifizierungssystem-relevante Anforderungen "
          case "BEDARFSPLANUNG_UND_ERMITTLUNG": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Bedarfsplanungen<br>" +
            "– Bedarfsermittlungen<br>" +
            "– Machbarkeitsstudien"
          case "STANDORTANALYSE":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Ortsbesichtigungen<br>" +
            "– Standortanalysen <br>" +
            "– Dokumente zu Grundstücks- und Objektauswahl, -beschaffung und -übertragung"
          case "BETRIEBSPLANUNG":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Betriebsplanungen"
          case "WEITERE_BAUPROJEKTUNTERLAGEN": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Unterlagen zu Beratungen zum gesamten Leistungs- und Untersuchungsbedarf<br>" +
            "– Entscheidungshilfen für die Auswahl anderer an der Planung fachlich Beteiligter<br>" +
            "– Verfahrensbetreuung, Mitwirken bei der Vergabe von Planungs- und Gutachterleistungen<br>" +
            "– Zusammenfassungen, Erläuterungen und Dokumentationen der Ergebnisse<br>" +
            "– Weitere Bauprojektunterlagen (Dokumente die keinem anderen Ordner zugeordnet werden können)<br>"
        }
        return ""
      }
      case "LPH2":{
        switch (folder){
          case "BESTANDSAUFNAHME": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Bestandsunterlagen<br>" +
            "– Bestandsaufnahmen<br>" +
            "– Technische Substanzerkundungen"
          case "KOSTEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Kostenschätzungen nach DIN 276, Vergleich mit den finanziellen Rahmenbedingungen<br>" +
            "– Untersuchungen alternativer Lösungsansätze nach verschiedenen Anforderungen einschließlich Kostenbewertung<br>" +
            "– Finanzierungspläne<br>" +
            "– Unterlagen zu Kredit- und Fördermittelbeschaffungen<br>" +
            "– Wirtschaftlichkeitsuntersuchungen<br>" +
            "– Vertiefte Kostenschätzungen nach Positionen einzelner Gewerke"
          case "TERMINE": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Terminpläne <br>" +
            "– Projektstrukturpläne"
          case "FUNKTIONS_RAUMPROGRAMM_RAUMBUCH": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Funktionsprogramme<br>" +
            "– Raumprogramme<br>" +
            "– Raumbücher<br>" +
            "– Flächenberechnungen (BGF, BRI)"
          case "UMWELTTECHNISCHE_BELANGE": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Zertifizierungssystem-relevante Anforderungen <br>" +
            "– Zertifizierungsunterlagen"
          case "PLANUNG_UND_BIM": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Planunterlagen<br>" +
            "– IFC-Dateien (BIM-Modelle)"
          case "BAURECHT_BAUGENEHMIGUNG":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Unterlagen zu Vorverhandlungen über die Genehmigungsfähigkeit<br>" +
            "– Unterlagen zur Voranfrage (Bauanfrage)<br>" +
            "– Bauordnungsrechtliche Nachweise für den vorbeugenden und organisatorischen Brandschutz bei baulichen Anlagen besonderer Art und Nutzung, Bestandsbauten oder im Falle von Abweichungen von der Bauordnung"
          case "WEITERE_BAUPROJEKTUNTERLAGEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Unterlagen, Protokolle zu Abstimmungen mit anderen fachlich an der Planung Beteiligten<br>" +
            "– Unterlagen zu Abstimmungen der Zielvorstellungen, Hinweise auf Zielkonflikte<br>" +
            "– Kataloge für die Planung und Abwicklung der Programmziele<br>" +
            "– Präsentationsmodelle, Perspektivische Darstellungen, Bewegte Darstellung/Animation, Farb- und Materialcollagen, digitales Geländemodell<br>"+
            "– Zusammenfassungen, Erläuterungen und Dokumentationen der Ergebnisse<br>" +
            "– Weitere Bauprojektunterlagen (Dokumente die keinem anderen Ordner zugeordnet werden können)"
        }
        return ""
      }
      case "LPH3":{
        switch (folder){
          case "BESTANDSAUFNAHME":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Bestandsunterlagen<br>" +
            "– Bestandsaufnahmen<br>" +
            "– Technische Substanzerkundungen"
          case "KOSTEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Kostenberechnungen nach DIN 276 und Vergleich mit der Kostenschätzung<br>" +
            "– Dokumente zu Alternativen/Varianten und deren Wertung mit Kostenuntersuchung (Optimierung)<br>" +
            "– Wirtschaftlichkeitsberechnungen<br>" +
            "– Vertiefte Kostenberechnungen"
          case "TERMINE": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Terminpläne <br>" +
            "– Projektstrukturpläne"
          case "FUNKTIONS_RAUMPROGRAMM_RAUMBUCH":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Funktionsprogramme<br>" +
            "– Raumprogramme<br>" +
            "– Raumbücher<br>" +
            "– Flächenberechnungen (BGF, BRI)"
          case "UMWELTTECHNISCHE_BELANGE": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Zertifizierungssystem-relevante Anforderungen <br>" +
            "– Zertifizierungsunterlagen"
          case "PLANUNG_UND_BIM":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Planunterlagen<br>" +
            "– IFC-Dateien (BIM-Modelle)"
          case "BAURECHT_BAUGENEHMIGUNG":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Unterlagen zu Verhandlungen über die Genehmigungsfähigkeit"
          case "OBJEKTBESCHREIBUNG":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Objektbeschreibungen<br>" +
            "– Bau- und Ausstattungsbeschreibungen"
          case "GUTACHTEN": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Dokumente und Gutachten zu städtebaulichen Rahmenbedingungen<br>" +
            "– Dokumente und Gutachten zu gestalterischen, funktionalen, technischen, wirtschaftlichen, ökologischen, sozialen, öffentlich-rechtlichen Rahmenbedingungen <br>" +
            "– Brandschutzgutachten <br>" +
            "– Schallschutzgutachten, Lärmschutzkonzept<br>" +
            "– Wärmeschutznachweis, Energieausweis<br>" +
            "– Umweltverträglichkeitsprüfung, Artenschutzgutachten<br>" +
            "– Bodenschutzkonzept, Entwässerungskonzept<br>" +
            "– Spielplatznachweis, Stellplatznachweis<br>" +
            "– Hygienegutachten<br>" +
            "– Abfallverwertungskonzept<br>" +
            "– Dokumente, Konzepte und Gutachten zu weiteren Themen"
          case "WEITERE_BAUPROJEKTUNTERLAGEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Zusammenfassungen, Erläuterungen und Dokumentationen der Ergebnisse<br>" +
            "– Weitere Bauprojektunterlagen (Dokumente die keinem anderen Ordner zugeordnet werden können)"
        }
        return ""
      }
      case "LPH4":{
        switch (folder){
          case "BESTANDSAUFNAHME":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Bestandsunterlagen<br>" +
            "– Bestandsaufnahmen<br>" +
            "– Technische Substanzerkundungen<br>" +
            "– Grundbuchauszüge<br>" +
            "– Liegenschaftspläne, Lagepläne"
          case "PLANUNG_UND_BIM":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Planunterlagen<br>" +
            "– IFC-Dateien (BIM-Modelle)"
          case "BAURECHT_BAUGENEHMIGUNG":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden<br>" +
            "– Kriterienkataloge<br>" +
            "– Erteilte Bescheide, Übereinstimmungserklärungen, Anträge auf Abweichung und Befreiung, Nachbarliche Zustimmungen und Erklärungen<br>" +
            "– Nachbarliche Zustimmungen und Erklärungen, Abstandsflächenübernahmen<br>" +
            "– Erklärungen zum barrierefreien Bauen, Barrierefreiheitskonzepte<br>" +
            "– Angaben zur Gebäudeklasse<br>" +
            "– Angaben zum Maß der baulichen Nutzung<br>" +
            "– Öffentliche Bekanntmachungen, Öffentlichkeitsbeteiligungen<br>" +
            "– Öffentlich rechtliche und örtliche Vorschriften<br>" +
            "– Vertretungsvollmachten<br>" +
            "– Statistiken<br>" +
            "– Prüfverzichte und Sachverständigenbescheinigungen (Brandschutz, Schallschutz, Wärmeschutz und Standsicherheit)<br>"
          case "FUNKTIONS_RAUMPROGRAMM_RAUMBUCH":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Funktionsprogramme<br>" +
            "– Raumprogramme<br>" +
            "– Raumbücher<br>" +
            "– Flächenberechnungen (BGF, BRI)"
          case "KOSTEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Förderungsnachweise<br>" +
            "– Herstellungskosten, Rohbaukosten, Baukosten<br>" +
            "– Vergleichberechnungen <br>" +
            "– Anlage Gebühren<br>" +
            "– Anrechenbare Kosten, anrechenbarer Bauwert, Baukostenwert, Bauwert"
          case "OBJEKTBESCHREIBUNG":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Objektbeschreibungen<br>" +
            "– Bau- und Ausstattungsbeschreibungen <br>" +
            "– Betriebsbeschreibungen "
          case "GUTACHTEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Dokumente und Gutachten zu städtebaulichen Rahmenbedingungen<br>" +
            "– Dokumente und Gutachten zu gestalterischen, funktionalen, technischen, wirtschaftlichen, ökologischen, sozialen, öffentlich-rechtlichen Rahmenbedingungen <br>" +
            "– Brandschutzgutachten <br>" +
            "– Schallschutzgutachten, Lärmschutzkonzept<br>" +
            "– Wärmeschutznachweis, Energieausweis<br>" +
            "– Umweltverträglichkeitsprüfung, Artenschutzgutachten<br>" +
            "– Bodenschutzkonzept, Entwässerungskonzept<br>" +
            "– Spielplatznachweis, Stellplatznachweis<br>" +
            "– Hygienegutachten<br>" +
            "– Abfallverwertungskonzept<br>" +
            "– Erläuterungen zu wassergefährdenden Stoffen, Nachweise zum Artenschutz, Stellungnahmen der Bodenschutzbehörde<br>" +
            "– Dokumente, Konzepte und Gutachten zu weiteren Themen"
          case "STATIK":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Konzepte, Berechnungen und Dokumentationen zu Statik / Tragwerksplanung<br>" +
            "– Gutachten und Nachweise zur Standsicherheit<br>" +
            "– Erschütterungsnachweise"
          case "WEITERE_BAUPROJEKTUNTERLAGEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Angaben zu Feuerungsanlagen<br>" +
            "– Angaben zum Störfallbetrieb<br>" +
            "– Angaben zu Beherbergungsbetten<br>" +
            "– Angaben zur CO2 Warnanlage<br>" +
            "– Weitere Bauprojektunterlagen (Dokumente die keinem anderen Ordner zugeordnet werden können)"
        }
        return ""
      }
      case "LPH5":{
        switch (folder){
          case "BESTANDSAUFNAHME":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Bestandsunterlagen<br>" +
            "– Bestandsaufnahmen<br>" +
            "– Technische Substanzerkundungen<br>" +
            "– Grundbuchauszüge<br>" +
            "– Liegenschaftspläne, Lagepläne<br>" +
            "– Basiskarten<br>" +
            "– Bebauungspläne"
          case "KOSTEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Kostenberechnungen nach DIN 276 und Vergleich mit der Kostenschätzung<br>" +
            "– Dokumente zu Alternativen/Varianten und deren Wertung mit Kostenuntersuchung (Optimierung)<br>" +
            "– Wirtschaftlichkeitsberechnungen<br>" +
            "– Vertiefte Kostenberechnungen"
          case "TERMINE": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Terminpläne <br>" +
            "– Projektstrukturpläne"
          case "FUNKTIONS_RAUMPROGRAMM_RAUMBUCH":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Funktionsprogramme<br>" +
            "– Raumprogramme<br>" +
            "– Raumbücher<br>" +
            "– Flächenberechnungen (BGF, BRI)<br>" +
            "– Dokumente zum Anlagenkennzeichnungssystem (AKS)"
          case "UMWELTTECHNISCHE_BELANGE": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Zertifizierungssystem-relevante Anforderungen <br>" +
            "– Zertifizierungsunterlagen"
          case "PLANUNG_UND_BIM": return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Planunterlagen  <br>" +
            "– Ausführungs-, Detail- und Konstruktionszeichnungen nach Art und Größe des Objekts im erforderlichen Umfang und Detaillierungsgrad unter Berücksichtigung aller fachspezifischen Anforderungen, zum Beispiel bei Gebäuden im Maßstab 1:50 bis 1:1, zum Beispiel bei Innenräumen im Maßstab 1:20 bis 1:1<br>" +
            "– IFC-Dateien (BIM-Modelle)"
          case "BAURECHT_BAUGENEHMIGUNG":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Baugenehmigung zugehörige Anlagen<br>"
          case "OBJEKTBESCHREIBUNG":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Objektbeschreibungen<br>" +
            "– Bau- und Ausstattungsbeschreibungen <br>" +
            "– Betriebsbeschreibungen"
          case "GUTACHTEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Dokumente und Gutachten zu städtebaulichen Rahmenbedingungen<br>" +
            "– Dokumente und Gutachten zu gestalterischen, funktionalen, technischen, wirtschaftlichen, ökologischen, sozialen, öffentlich-rechtlichen Rahmenbedingungen <br>" +
            "– Brandschutzgutachten <br>" +
            "– Schallschutzgutachten, Lärmschutzkonzept<br>" +
            "– Wärmeschutznachweis, Energieausweis<br>" +
            "– Umweltverträglichkeitsprüfung, Artenschutzgutachten<br>" +
            "– Bodenschutzkonzept, Entwässerungskonzept<br>" +
            "– Spielplatznachweis, Stellplatznachweis<br>" +
            "– Hygienegutachten<br>" +
            "– Abfallverwertungskonzept<br>" +
            "– Dokumente, Konzepte und Gutachten zu weiteren Themen"
          case "STATIK":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Konzepte, Berechnungen und Dokumentationen zu Statik / Tragwerksplanung<br>" +
            "– Gutachten und Nachweise zur Standsicherheit"
          case "WEITERE_BAUPROJEKTUNTERLAGEN":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Weitere Bauprojektunterlagen (Dokumente die keinem anderen Ordner zugeordnet werden können)"
        }
        return ""
      }
      case "LPH6":{

        switch (folder){
          case "LEISTUNGSVERZEICHNIS":return "Dokumente mit folgenden Inhalten sollten in diesen Ordner abgelegt werden: <br>" +
            "– Leistungsverzeichnisse<br>"
        }

        return ""
      }
      case "GENERAL":{
        return ""
      }
      case "PLANS":{
        return ""
      }

    }
    return "";
  }

  setCardView(): void {
    this.isCardView = true;
    this.isListView = false;
  }

  setListView(): void {
    this.isCardView = false;
    this.isListView = true;
  }

  protected readonly faBurger = faBurger;
  protected readonly faBars = faBars;
  protected readonly faFolderOpen = faFolderOpen;
  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faUser = faUser;
  protected readonly faFile = faFile;
  protected readonly faListCheck = faListCheck;
  protected readonly faInfo = faInfo;
  protected readonly faCircleQuestion = faCircleQuestion;
  protected readonly faHouse = faHouse;
  protected readonly document = document;
  protected readonly faGrip = faGrip;
  protected readonly faGrid2 = faGrid2;
}
