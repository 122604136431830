import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Project, ProjectControllerService, ProjectFile} from "../../api";
import LphEnum = ProjectFile.LphEnum;

/**
 * Project Loading data
 */
interface ProjectLoading {
  /**
   * ID of Project
   */
  projectId: number,

  /**
   * The Project
   */
  project: Project,

  /**
   * LPH of Project
   */
  lph: LphEnum,

  /**
   * State of analysis
   */
  state: boolean
}

/**
 * handling loading states of application
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  /**
   * Subject of Loading states
   */
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Map of Requests and states
   */
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  /**
   * Subject of Loading states for analysis
   */
  analysisSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Map of Requests and states for analysis
   */
  analysisMap: Map<string, boolean> = new Map<string, boolean>();

  /**
   * State of loading
   */
  isLoading: boolean = false;

  /**
   * Constructor
   * @param projectControllerService ProjectControllerService
   */
  constructor(public projectControllerService: ProjectControllerService) {
  }

  /**
   * Sets loading states in Subjects for given paramenters
   * @param loading Loading state
   * @param url url of request
   */
  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if(url.includes("async") || url.includes("state") || url.includes("status") || url.includes("upload") || url.includes("s3.eu-central-1.amazonaws.com") || url.includes("de-thinkbic-dev-projects-data.s3-accelerate.amazonaws.com" )|| url.includes("classify") || url.includes("services.thinkbic.de/chat")) {
      if (loading) {
        this.analysisMap.set(url, loading);
        this.analysisSub.next(true);
      } else if (!loading && this.analysisMap.has(url)) {
        this.analysisMap.delete(url);
      }
      if (this.analysisMap.size === 0) {
        this.analysisSub.next(false);
      }
    }else {
      if (loading) {
        this.loadingMap.set(url, loading);
        this.loadingSub.next(true);
      } else if (!loading && this.loadingMap.has(url)) {
        this.loadingMap.delete(url);
      }
      if (this.loadingMap.size === 0) {
        this.loadingSub.next(false);
      }
    }
  }
}
