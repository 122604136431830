import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {
  CheckControllerService,
  CheckResults,
  CompletenessCheck, DashboardControllerService,
  Project,
  ProjectControllerService,
  SimpleCheck
} from "../../api";
import LphEnum = CompletenessCheck.LphEnum;
import {DrawerContentService} from "../../services/drawer-content.service";
import {hoaiCheckDetailComponent} from "./hoaiCheckDetail/hoai-check-detail.component";
import {StateConfigService} from "../../services/state/state-config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LphServicePipe} from "../../pipes/lph.pipe";
import PartialServiceNameEnum = CheckResults.PartialServiceNameEnum;
import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
import {
    faBars,
    faChevronDown,
    faChevronRight,
    faFilter,
    faPlusCircle,
    faSortDown
} from "@fortawesome/free-solid-svg-icons";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {PermissionService} from "../../services/permission/permission.service";
import {ServiceActivatedPipe, ServicePipe} from "../../pipes/service.pipe";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {faCircleInfo, faSquareDashedCirclePlus} from "@fortawesome/pro-solid-svg-icons";
import {initFlowbite} from "flowbite";


@Component({
  selector: 'app-hoaiCheckReport',
  templateUrl: './hoai-check.component.html',
  styleUrls: ['./hoai-check.component.scss']
})
export class HoaiCheckComponent implements OnInit, AfterViewInit {

  lphEnum: typeof LphEnum = LphEnum;
  numberOfFiles: number = 0;

  services!: PartialServiceNameEnum[][];
  allServices: PartialServiceNameEnum[] = [];
  selectedServices: PartialServiceNameEnum[] = [];
  dropZoneClassName: string = "custom-file-drop-check";
  contentClassName: string = "custom-file-drop-check-content";
  entered: boolean = false;
  checkedLPH: string = ''

  constructor(
    private drawerService: DrawerContentService,
    public stateConfigService: StateConfigService,
    private checkControllerService: CheckControllerService,
    private router: Router,
    public permissionService: PermissionService,
    private route: ActivatedRoute,
    private lphServicePipe: LphServicePipe,
    private projectControllerService: ProjectControllerService,
    public dialog: MatDialog,
    private serviceActivatedPipe: ServiceActivatedPipe,
    private dashboardControllerService: DashboardControllerService){
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.lph) {
        this.stateConfigService.selectedServicePhase = params.lph;
      }else {
        this.stateConfigService.selectedServicePhase = LphEnum.LPH1;
      }

      this.navigateToLPH(this.stateConfigService.selectedServicePhase);


      this.dashboardControllerService.getNumberOfFiles(this.stateConfigService.selectedProject.id ?? 0, this.stateConfigService.selectedServicePhase).subscribe(data => {
        this.numberOfFiles = data;
      });
    });

  }

  get filesUploaded(): boolean {
    return this.numberOfFiles > 0;
  }

  public fileOver(event: any) {
    this.dropZoneClassName = "custom-file-drop-check-drag";
    this.contentClassName = "custom-file-drop-check-content-drag";
    this.entered = true;
  }

  get canCheckHOAI(): boolean {
    return this.stateConfigService.canCheckHOAI();
  }

  get servicesLoaded(): boolean {
    return this.services !== undefined && this.services.length == 2;
  }

  public fileLeave(event: any) {
    this.dropZoneClassName = "custom-file-drop-check";
    this.contentClassName = "custom-file-drop-check-content";
    this.entered = false;
  }

  navigateToLPH(lph: LphEnum): void {

    this.checkedLPH = lph

    if(this.stateConfigService.selectedServicePhase) {
      this.stateConfigService.selectedServicePhase = lph;
    }else {
      this.stateConfigService.selectedServicePhase = LphEnum.LPH1;
      lph = LphEnum.LPH1;
    }
    this.services = [];

    const lphNumber = lph.toString().replace('LPH', '');

    this.services[0] = Object.values(PartialServiceNameEnum).filter(s => s.toString().includes('GL_' + lphNumber) && (lphNumber == "4" ? s.toString().includes('_' + this.projectState.toString()) || s.toString().includes('_else') : true));
    this.services[1] = Object.values(PartialServiceNameEnum).filter(s => s.toString().includes('BL_' + lphNumber) && (lphNumber == "4" ? s.toString().includes('_' + this.projectState.toString()) || s.toString().includes('_else') : true));
    this.services[0] = this.services[0].filter(s => this.serviceActivatedPipe.transform(s));
    this.services[1] = this.services[1].filter(s => this.serviceActivatedPipe.transform(s));


    this.allServices = this.sortServices(this.services[0]).concat(this.sortServices(this.services[1]));
    this.allServices = this.allServices.filter(s => this.serviceActivatedPipe.transform(s));

    this.selectedServices = [];
    this.router.navigate([], {queryParams: {lph: lph}});
  }

  sortServices(services : PartialServiceNameEnum[]): PartialServiceNameEnum[] {
    let servicePipe = new ServicePipe();
    return services.sort((a, b) => servicePipe.transform(a).localeCompare(servicePipe.transform(b)));
  }

  addToSelectedServices(service: PartialServiceNameEnum) {
    this.selectedServices.push(service);
    this.allServices = this.allServices.filter(s => s !== service);
  }

  get selectedLph() {
    return this.stateConfigService.selectedServicePhase;
  }

  get projectState(): Project.StateEnum {
    return this.stateConfigService.selectedProject.state!;
  }

  areAllSelected(): boolean {
    return this.areAllGLSelected() && this.areAllBLSelected();
  }

  removeFromSelectedServices(service: PartialServiceNameEnum) {
    this.selectedServices = this.selectedServices.filter(s => s !== service);
    let index = -1;
    if (this.services[0].includes(service)) {
      index = this.services[0].indexOf(service);
    }else{
      index = this.services[1].indexOf(service) + this.services[0].length;
    }
    this.allServices.splice(index, 0, service);
  }

  addOrRemoveAllServices(){
    console.log(this.selectedServices, this.allServices)
    if(this.allServices.length==0){
      this.allServices = [...this.services[0], ...this.services[1]]
      this.selectedServices = []
    } else {
      this.allServices = []
      this.selectedServices = [...this.services[0], ...this.services[1]]
    }
  }

  addOrRemoveAllGL(){
    let isRemove = this.selectedServices.filter(s => this.services[0].includes(s)).length==this.services[0].length;
    if(isRemove){ //remove
      this.allServices = this.allServices.concat(this.services[0])
      this.selectedServices = this.selectedServices.filter(s => !this.services[0].includes(s))
    } else { //add
      this.addAllGLToSelectedServices()
    }
  }

  addOrRemoveAllBL(){
    let isRemove = this.selectedServices.filter(s => this.services[1].includes(s)).length==this.services[1].length;
    if(isRemove){ //remove
      this.allServices = this.allServices.concat(this.services[1])
      this.selectedServices = this.selectedServices.filter(s => !this.services[1].includes(s))
    } else { //add
      this.addAllBLToSelectedServices()
    }
  }


  addAllGLToSelectedServices() {
    this.selectedServices = this.selectedServices.filter(s => !this.services[0].includes(s));
    this.selectedServices = this.selectedServices.concat(this.services[0]);
    this.allServices = this.allServices.filter(s => !this.services[0].includes(s));
  }

  addAllBLToSelectedServices() {
    this.selectedServices = this.selectedServices.filter(s => !this.services[1].includes(s));
    this.selectedServices = this.selectedServices.concat(this.services[1]);
    this.allServices = this.allServices.filter(s => !this.services[1].includes(s));
  }

  removeAllGLFromSelectedServices() {
    //this.selectedServices = this.selectedServices.filter(s => !this.services[0].includes(s));
    for(let service of this.services[0]){
      this.removeFromSelectedServices(service)
    }
  }

  removeAllBLFromSelectedServices() {
    //this.selectedServices = this.selectedServices.filter(s => !this.services[1].includes(s));
    for(let service of this.services[1]){
      this.removeFromSelectedServices(service)
    }
  }

  areAllGLSelected(): boolean {
    return  this.services[0].every(s => this.selectedServices.includes(s));
  }

  areAllBLSelected(): boolean {
    return this.services[1].every(s => this.selectedServices.includes(s));
  }

  startCheck() {
    const dialogRef = this.dialog.open(HoaiConfirmationDialog, {
      panelClass: 'rounded-corners-dialog',
      width: '750px',
      data: {
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let tempArray = this.selectedServices;
        this.projectControllerService.startAnalysis(tempArray, this.stateConfigService.selectedProject.id ?? 0, this.selectedLph).subscribe(
          check => {
            this.router.navigate(['projects', this.stateConfigService.getProjectId(), 'reports']);
          });
      }
    });
  }

  isGL(service: PartialServiceNameEnum): boolean {
    return service.toString().includes('GL');
  }

  isLPH4(): boolean {
    return this.selectedLph == LphEnum.LPH4;
  }

  isBL(service: PartialServiceNameEnum): boolean {
    return service.toString().includes('BL');
  }

  drop(event: CdkDragDrop<PartialServiceNameEnum[]>) {
    if (event.previousContainer === event.container) {
      // Wenn das Element in der gleichen Liste verschoben wird, verschieben Sie das Element innerhalb der Liste.
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // Wenn das Element von einer Liste zur anderen verschoben wird, übertragen Sie das Element zwischen den Listen.
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  openCheck(check: SimpleCheck) {
    if (check.completed) {
      this.drawerService.close();
      this.drawerService.open(hoaiCheckDetailComponent, {
        data: check,
        fixedSize: true,
        large: false,
        title: ''
      })
    }
  }

  get isStartHOAICheckDisabled(): boolean {
    return this.services?.length === 0 || !this.canCheckHOAI;
  }

  protected readonly Number = Number;
  protected readonly faCircleXmark = faCircleXmark;
  protected readonly faBars = faBars;
  protected readonly LphEnum = LphEnum;
  protected readonly faFilter = faFilter;
  protected readonly faPlusCircle = faPlusCircle;
  protected readonly faCircleInfo = faCircleInfo;
  protected readonly faSquareDashedCirclePlus = faSquareDashedCirclePlus;
  protected readonly faChevronRight = faChevronRight;

  ngAfterViewInit(): void {
    initFlowbite();
  }

  protected readonly faSortDown = faSortDown;
    protected readonly faChevronDown = faChevronDown;
}


/**
 * Dialog for User Deletion
 */
@Component({
  selector: 'file-too-bigDialog',
  templateUrl: './hoai-check-confirmation-dialog.html',
  styleUrls: ['./hoai-check.component.scss'],
})
export class HoaiConfirmationDialog implements OnInit {
  constructor (@Inject(MAT_DIALOG_DATA) public data: { }, private dialogRef: MatDialogRef<HoaiConfirmationDialog>) {
  }

  ngOnInit() {
  }


  protected readonly faCircleXmark = faCircleXmark;
}
