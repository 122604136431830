import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

/**
 * Searchbar Component
 */
@Component({
  selector: 'app-search-filter-bar',
  templateUrl: './search-filter-bar.component.html',
  styleUrls: ['./search-filter-bar.component.scss']
})
export class SearchFilterBarComponent implements OnInit {

  /**
   * Data for searching
   */
  @Input() data: any[] = [];

  /**
   * Property name to search in
   */
  @Input() propertyName: string = '';

  /**
   * action on data change
   */
  @Output() dataChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  /**
   * searchText
   */
  searchText: string = '';

  /**
   * complete Data
   */
  completeData: any[] = [];

  /**
   * Constructor
   */
  constructor() { }

  /**
   * Initialization of Component
   */
  ngOnInit(): void {
    this.completeData = this.data;
  }

  /**
   * Search in given data with searchText
   */
  search() {
    if(this.searchText.length > 0) {
      let filteredData = this.data.filter(d => {
        return d[this.propertyName].toLowerCase().includes(this.searchText.toLowerCase());
      });

      this.dataChange.emit(filteredData);
    }else {
      this.dataChange.emit(this.completeData);
    }
  }

}
