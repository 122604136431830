import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {DrawerContentService} from "../../../../../services/drawer-content.service";

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  constructor(public drawerContentService: DrawerContentService) {
  }

  @Input() link: string = "";
  @Input() formData: any = {};

  data!: { link: string; formData: any }

  ngOnInit(): void {
    if(this.drawerContentService.config?.data){
      this.data = this.drawerContentService.config.data;
      this.link = this.data.link;
      this.formData = this.data.formData;
    }
  }
}
