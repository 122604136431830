import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {CountryPipe} from "./country.pipe";


@Injectable()
@Pipe({
  name: 'sortCountries'
})
export class SortCountriesPipe implements PipeTransform {
  transform(value: any[]): any {
    let countryPipe = new CountryPipe();
    let countryMap = new Map<string, string>();


    for (const valueKey in value) {
      countryMap.set(value[valueKey], countryPipe.transform(value[valueKey]));
    }
    return countryMap;

  }
}
