import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Task, User, TaskControllerService} from "../../../api";
import {Router} from "@angular/router";
import {ConnectionPositionPair} from "@angular/cdk/overlay";
import {TaskDetailsService} from "../../../services/task-details.service";
import {Subject} from "rxjs";
import {
  faArrowUpFromBracket, faArrowUpRightFromSquare, faCircle, faCircleCheck,
  faCircleExclamation, faCopy,
  faEllipsisVertical,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {MatDialog} from "@angular/material/dialog";
import {TaskDeleteDialog} from "../task-detail/task-detail.component";
import {faIfc} from "@awesome.me/kit-6ed88ea8d1/icons/modules/kit/custom";
import {
  faArrowDownToBracket,
  faCalendarDays,
  faCircleHalfStroke,
  faCircleSmall
} from "@fortawesome/pro-solid-svg-icons";
import {StateConfigService} from "../../../services/state/state-config.service";
import {faCircle as faCircleRegular} from "@fortawesome/pro-regular-svg-icons";

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent implements OnInit {

  @Input() task!: Task;
  @Input() selected: boolean = false;
  @Output() taskChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportBCFClicked: EventEmitter<Task> = new EventEmitter<Task>();
  taskChangedEvenet: Subject<Task> = new Subject<Task>();
  isOpen = false;

  positions: ConnectionPositionPair[] = [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center'
    }
  ];

  constructor(private dialog: MatDialog, private taskControllerService: TaskControllerService,  private router: Router, public taskDetailService: TaskDetailsService, public stateConfigService: StateConfigService) { }


  ngOnInit(): void {
    this.taskDetailService.currentTaskChanged.subscribe(task => {
      if (task && task.id === this.task.id) {
        this.task = task;
        this.taskChange.emit(this.task);
      }
    });
  }

  taskChanged(task: Task) {
    this.task = task;
    this.taskChange.emit(task);

  }

  openTaskDetails() {
      this.taskDetailService.setOpenedTask(this.task);
  }

  openDeleteDialog() {
    let dialogRef = this.dialog.open(TaskDeleteDialog, {
      panelClass:"rounded-corners-dialog",
      data: {task: this.task}
    });

  }


  navigateOnDoubleClick(taskID: string | undefined) {
    this.router.navigate(['/tasks', taskID]);
  }

  getDaysUntilDueDate(dueDate: Date): number {
    const currentDate = new Date();
    const due = new Date(dueDate);
    const timeDiff = due.getTime() - currentDate.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  getAbsoluteValue(value: number): number {
    return Math.abs(value);
  }

    protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faIfc = faIfc;
  protected readonly faCalendarDays = faCalendarDays;
  protected readonly faCircleExclamation = faCircleExclamation;
  protected readonly faArrowDownToBracket = faArrowDownToBracket;
  protected readonly faTrash = faTrash;
  protected readonly faArrowUpFromBracket = faArrowUpFromBracket;
  protected readonly faCopy = faCopy;
  protected readonly faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  protected readonly faCircleSmall = faCircleSmall;
  protected readonly faCircleHalfStroke = faCircleHalfStroke;
  protected readonly faCircle = faCircle;
  protected readonly faCircleCheck = faCircleCheck;
  protected readonly faCircleRegular = faCircleRegular;
}
