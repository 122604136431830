/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { User } from './user';
import { Viewpoint } from './viewpoint';

export interface Comment { 
    atGuid?: string;
    date?: Date;
    author?: string;
    comment?: string;
    viewpoint?: Viewpoint;
    modifiedDate?: Date;
    modifiedAuthor?: string;
    authorUser?: User;
    modifiedAuthorUser?: User;
}