import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {SelectionModel} from "@angular/cdk/collections";
import {Router} from "@angular/router";
import {
  faBars,
  faBuilding,
  faEllipsisVertical,
  faGrip,
  faListCheck, faNetworkWired,
  faPlus,
  faUser,
  faUserGroup
} from "@fortawesome/free-solid-svg-icons";
import {faFile} from "@fortawesome/free-regular-svg-icons";
import {
  AuthControllerService,
  CompletenessCheck,
  Organisation,
  OrganisationControllerService, ProjectControllerService, User,
  UserSimpleDto,

} from "../../api";
import {ToolbarService} from "../util/design/toolbar/toolbar.service";
import {StateConfigService} from "../../services/state/state-config.service";
import {PermissionService} from "../../services/permission/permission.service";
import {DeleteDialogComponent} from "../util/deleteDialog/delete-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {OrganisationPopupComponent} from "../organisation/organisation-popup/organisation-popup.component";
import {Subject} from "rxjs";
import {faGrid2} from "@fortawesome/pro-solid-svg-icons";


@Component({
  selector: 'app-organisation-list',
  templateUrl: './organisation-list.component.html',
  styleUrls: ['./organisation-list.component.scss']
})
export class OrganisationListComponent implements OnInit {

  displayedColumns: string[] = ['select', 'name', 'image', 'id', 'projectManager', 'projectOwner', 'lastCheck', 'actions'];

  @Input() user!: User;

  dataSource!: MatTableDataSource<Organisation>;
  selection = new SelectionModel<Organisation>(true, []);

  organisationEvaluationMap: Map<Organisation, CompletenessCheck> = new Map<Organisation, CompletenessCheck>();

  numberOfPersonalProjects: number = 0;

  isCardView: boolean = true;
  isListView: boolean = false;

  setCardView(): void {
    this.isCardView = true;
    this.isListView = false;
  }

  setListView(): void {
    this.isCardView = false;
    this.isListView = true;
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  @ViewChild('projectsTbSort') projectsTbSort = new MatSort();

  /**
   * All Project of User
   */
  organisations: Organisation[] = [];
  selectedOrganisation!: Organisation | undefined;


  leaveOrganisationBoolean: boolean = false;
  deleteOrganisationBoolean: boolean = false;
  deleteAndLeaveOrganisationBoolean: boolean = false;





  adminMap: Map<Organisation, boolean> = new Map<Organisation, boolean>();
  otherAdmins: Map<number, boolean> = new Map<number, boolean>();

  /**
   * Constructor
   * @param organisationService ProjectControllerService
   * @param dialog
   * @param router
   * @param toolbar
   * @param stateService
   * @param permissionService
   * @param authControllerService
   * @param projectControllerService
   */
  constructor(private organisationService: OrganisationControllerService,
              private dialog: MatDialog,
              private router: Router,
              private toolbar: ToolbarService,
              public stateService: StateConfigService,
              public permissionService: PermissionService,
              private authControllerService: AuthControllerService,
              public projectControllerService: ProjectControllerService) {

  }


  /**
   * Initialization of Component
   */

  ngOnInit(): void {
    // if(this.stateService.isPersonal){
    //   this.router.navigateByUrl("/projects");
    // }else{
    //   this.authControllerService.profile().subscribe(user =>
    //   {
    //     this.user = user;
    //     this.loadOrganisations();
    //   })
    // }
 this.authControllerService.profile().subscribe(user =>
      {
        this.user = user;
        this.loadOrganisations();
      })
  }


  loadOrganisations() {
    this.organisationService.getOrganisations().subscribe(organisation => {
      this.organisations = organisation;
      this.dataSource = new MatTableDataSource(this.organisations);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.organisations.forEach(org => {
        this.stateService.organisations.get(org.id ?? 0)?.privileges?.length == 6 ? this.adminMap.set(org, true) : this.adminMap.set(org, false);
      });

      this.organisationService.otherAdmins1(this.getAdminOrganisationIds()).subscribe(admins => {
        this.otherAdmins = this.convertToMap(admins);
      });


    });

    this.projectControllerService.getPersonalProjects().subscribe(projects => {
      this.numberOfPersonalProjects = projects.length;
    });

  }


  navigateToOrganisation(row: Organisation) {
    this.stateService.selectOrganisation(row.id!);
  }
  routerNavigateToOrganisation(organisation: Organisation){
    this.router.navigate(['/organisations', organisation.id, 'settings']);
  }

  openNewOrganisation(){
    // let ref = this.dialog.open(OrganisationPopupComponent, {
    //   panelClass: 'rounded-corners-dialog',
    //   data: {
    //     title: "Neue Organisation"
    //   },
    //   width: '60%',
    // });
    // ref.afterClosed().subscribe(user => {
    //   this.stateService.update();
    //   this.ngOnInit();
    // });

    this.router.navigate(['/organisations', 'create']);

  }

  // openEditOrganisation(organisationId: number){
  //   let ref = this.dialog.open(OrganisationPopupComponent, {
  //     panelClass: 'rounded-corners-dialog',
  //     data: {
  //       organisationId: organisationId,
  //       title: "Unternehmenseinstellungen"
  //     },
  //     width: '60%',
  //   });
  //
  //   ref.afterClosed().subscribe(user => {
  //     this.stateService.update();
  //     this.ngOnInit();
  //   });
  // }

  navigateToOrganisationSettings(org: Organisation) {

    this.router.navigate(['/organisations', org.id, 'settings']);
    this.stateService.selectOrganisation(org.id, false)
  }


  openLeaveOrganisation(org: Organisation){
    this.leaveOrganisationBoolean = true;
    this.selectedOrganisation = org;
  }

  leaveOrganisation(org: Organisation){

    if(org.id != undefined){
      this.organisationService.leaveOrganisation(org.id).subscribe(() => {
        this.organisations = this.organisations.filter(obj => obj !== org);
        this.dataSource = new MatTableDataSource(this.organisations);
      });
    }
    this.leaveOrganisationBoolean = false;
  }

  openDeleteOrganisation(org: Organisation){
    this.deleteOrganisationBoolean = true;
    this.selectedOrganisation = org;
  }

  openDeleteAndLeaveOrganisation(org: Organisation){
    this.deleteAndLeaveOrganisationBoolean = true;
    this.selectedOrganisation = org;
  }

  deleteOrganisation(org: Organisation){
    if(org.id != undefined){
      this.organisationService.deleteOrganisation(org.id).subscribe(() => {
        this.organisations = this.organisations.filter(obj => obj !== org);
        this.dataSource = new MatTableDataSource(this.organisations);
      });
    }
    this.deleteAndLeaveOrganisationBoolean = false;
  }

  // routeToProjectsOfPersonal(){
  //   this.stateService.isPersonal = true;
  //   this.stateService.selectOrganisation(undefined, false)
  // }


  calculateSizeInMB(size: number): number {
    return size / 1024 / 1024;
  }

  getNumberOfProjects(org: Organisation){
    let numb = 0;
    this.projectControllerService.getProjectsByOrganisationId(org.id ?? 0).subscribe( orgs => {
      numb =  orgs.length ?? 0;
    })
    return numb
  }

  getIsAdmin(org: Organisation){
    return this.adminMap.get(org);
  }

  getAdminOrganisationIds(): number[] {
    const adminOrganisationIds: number[] = [];
    this.adminMap.forEach((isAdmin, organisation) => {
      if (isAdmin) {
        // Stellen Sie sicher, dass die Organisation eine ID hat, bevor Sie sie hinzufügen
        if (organisation.id !== undefined) {
          adminOrganisationIds.push(organisation.id);
        }
      }
    });
    return adminOrganisationIds;
  }

convertToMap(obj: { [key: string]: boolean }): Map<number, boolean> {
    const map = new Map<number, boolean>();
    for (const [key, value] of Object.entries(obj)) {
      const numericKey = parseInt(key, 10);
      if (!isNaN(numericKey)) {
        map.set(numericKey, value);
      }
    }
    return map;
  }


  protected readonly faBars = faBars;
  protected readonly faGrip = faGrip;
  protected readonly faEllipsisVertical = faEllipsisVertical;
  protected readonly faPlus = faPlus;
  protected readonly faFile = faFile;
  protected readonly faUser = faUser;
  protected readonly faListCheck = faListCheck;
  protected readonly faUserGroup = faUserGroup;
  protected readonly faBuilding = faBuilding;

  protected readonly faNetworkWired = faNetworkWired;
    protected readonly faGrid2 = faGrid2;
}
