<div class="flex flex-col h-full w-full justify-start items-start gap-6 pb-6 bg-[#FFF]">
  <app-loi-check-stepper class="w-full"></app-loi-check-stepper>

  <div class="flex flex-row w-full px-4">
    <!-- Under title -->
    <div class="flex flex-row w-full h-fit justify-between gap-8 rounded-md border border-gray-200 py-6 px-8">

      <!-- Description Section -->
      <div class="flex flex-col justify-center items-start">
        <div class="font-medium text-lg text-primary">
          LOI-Check

        </div>
        <div class="text-xs font-normal text-gray-400">
          Überprüfung des BIM-Modells auf alphanumerische Informationen
        </div>

        <!--div class="flex flex-row font-medium py-4">
          Datei: {{ check.projectFile?.name ?? '' }}
        </div-->

      </div>

      <div class="flex flex-row justify-start items-center text-lg font-normal">
        {{ check.checkDate | date:'dd.MM.y' }}
      </div>

      <div class="flex flex-row justify-start items-center text-lg font-normal">
        <ng-container *ngIf="check.lodType">
          {{ check.lodType | lod }}
        </ng-container>
        <ng-container *ngIf="check.loiCheckRule">
          {{ check.loiCheckRule.name }}
        </ng-container>
      </div>

      <div class="flex flex-row gap-4 justify-start items-center" *ngIf="allCount>0">
        <p class="text-gray-900 text-lg">{{allCount}}</p>
        <p class="text-gray-500 text-xs">geprüfte<br>Bauteile</p>
      </div>

      <!--        <div class="flex flex-col justify-center gap-4 align-bottom items-center w-72">-->
      <!--            &lt;!&ndash;          <div class="flex flex-row px-3 py-2 gap-2 rounded-md text-primary items-center cursor-pointer hover:bg-gray-200 active:opacity-50">&ndash;&gt;-->
      <!--            &lt;!&ndash;            <fa-icon class="fa-xs" [icon]="faBars"></fa-icon>&ndash;&gt;-->
      <!--            &lt;!&ndash;            <p>Prüfungen</p>&ndash;&gt;-->
      <!--            &lt;!&ndash;          </div>&ndash;&gt;-->

      <!--            &lt;!&ndash;          <div class="px-3 py-2 gap-2 rounded-md bg-primary text-white cursor-pointer hover:opacity-75 active:opacity-50">&ndash;&gt;-->
      <!--            &lt;!&ndash;            Überprüfen&ndash;&gt;-->
      <!--            &lt;!&ndash;          </div>&ndash;&gt;-->
      <!--          <div class="flex flex-row w-full shadow-full p-2 rounded-md justify-center gap-4">-->
      <!--            <fa-icon [icon]="faClipboardList" class="scale-150"></fa-icon>-->
      <!--            BCF erstellen-->
      <!--          </div>-->

      <!--           <div class="flex flex-row w-full shadow-full p-2 rounded-md justify-center gap-4">-->
      <!--             <mat-icon svgIcon="open"></mat-icon>-->
      <!--            Datei öffnen-->
      <!--          </div>-->


      <!--&lt;!&ndash;            <button mat-raised-button (click)="createTask()" color="basic">BCF erstellen</button>&ndash;&gt;-->
      <!--&lt;!&ndash;            <button mat-raised-button (click)="openFile()" color="basic">Datei öffnen</button>&ndash;&gt;-->
      <!--          </div>-->

      <!-- Graph section -->
        <div class="w-20">
          <app-circle-state [value]="score" [compact]="true"></app-circle-state>
        </div>

      <div class="flex flex-row gap-2 justify-start items-center">
        <bic-button [text]="'Datei öffnen'" [iconLeft]="faArrowUpRightFromSquare" [border]="true" [color]="'#FFF'"
                    [textColor]="'gray-900'"
                    (click)="openFile()" [opacityHover]="false">
        </bic-button>

        <bic-button [text]="'BCF erstellen'" [iconLeft]="faClipboardCheck" [border]="true" [color]="'#FFF'"
                    [textColor]="'gray-900'"
                    (click)="createTask()" [opacityHover]="false">
        </bic-button>
      </div>

      <!--div class="flex flex-col justify-center items-center mr-4 gap-4 w-72 pr-4">
        <div class="w-full">
          <button class="flex flex-row items-center justify-start w-full gap-4" mat-raised-button
                  matTooltip="BCF erstellen" (click)="createTask()">
            <fa-icon [icon]="faClipboardList" class="scale-150"></fa-icon>
            BCF erstellen
          </button>
        </div>

        <div class="w-full">
          <button class="flex flex-row items-center justify-start w-full" mat-raised-button matTooltip="Datei öffnen"
                  (click)="openFile()">
            <mat-icon svgIcon="open" class="scale-75"></mat-icon>
            <div class="flex">Datei öffnen</div>
          </button>
        </div>
      </div-->


    </div>

    <div *ngIf="isError()"
         class="flex flex-col h-full w-full overflow-scroll justify-start items-center">
      <div class="text-error font-medium">
        Es ist ein Fehler aufgetreten!
      </div>
      <div>
        {{ errorDescription() }}
      </div>
    </div>
  </div>

  <div class="flex flex-col h-full w-full justify-start items-start gap-4 px-4 overflow-hidden">

    <div class="overflow-hidden h-full w-full">
      <div class="flex w-full overflow-y-scroll h-full">
      <bic-table class="w-full" [data]="data" [columnNames]="displayedColumnsNames" [columns]="displayedColumns" [columnTypes]="columnsTypes" [columnWidths]="columnsWidths" [columnAlignments]="columnsAlignment"
                 [subtable]="true" [subtableData]="subData" [subtableColumns]="subDisplayedColumns" [subtableColumnNames]="subDisplayedColumnsNames" [subtableColumnTypes]="subColumnsTypes" [subtableColumnWidths]="subColumnsWidths" [subtableColumnAlignments]="subColumnsAlignment"
                 [paginator]="false" [subtablePaginator]="false" (rowClick)="changeSubData($event)" [createTicket]="true" (createTicketClicked)="createTask()" [subtableCreateTicket]="true" [title]="check.projectFile?.name" [subtableShowHeader]="false"
                 ></bic-table>
    </div>
    </div>

    <!-- LOI View -->
    <!--<div class="w-full h-full flex flex-col shadow-full rounded-md p-2">

      &lt;!&ndash; LOI-Table &ndash;&gt;
      <div class="flex flex-col h-full w-full overflow-scroll select-none" *ngIf="!isError()">
        <cdk-table [dataSource]="dataSource" multiTemplateDataRows class="w-full">

          <ng-container cdkColumnDef="name">

            <cdk-header-cell class="text-start w-full h-full" *cdkHeaderCellDef>Bauteiltyp</cdk-header-cell>
            <cdk-cell class="w-full h-full flex flex-row" *cdkCellDef="let element">
              <ng-container *ngIf="expandedElements.includes(element)">
                <div>
                  <fa-icon class="text-primary pr-2" [icon]="faChevronUp"></fa-icon>
                </div>
              </ng-container>
              <ng-container *ngIf="!expandedElements.includes(element)">
                <div>
                  <fa-icon class="text-primary pr-2" [icon]="faChevronDown"></fa-icon>
                </div>
              </ng-container>

              <div>{{ element.name }}</div>
            </cdk-cell>

          </ng-container>

          <ng-container cdkColumnDef="count">

            <cdk-header-cell class="w-full h-full text-center" *cdkHeaderCellDef>Anzahl Bauteile</cdk-header-cell>
            <cdk-cell class="flex-row justify-center items-center text-center w-full h-full" *cdkCellDef="let element">
              <div> {{ element.count }}</div>
            </cdk-cell>

          </ng-container>

          <ng-container cdkColumnDef="result">

            <cdk-header-cell class=" w-full h-full text-center" *cdkHeaderCellDef>Auswertungsergebnis</cdk-header-cell>
            <cdk-cell class="flex-row justify-center items-center text-center w-full h-full" *cdkCellDef="let element">
              <ng-container *ngIf="numberOfProperties(element) != 0">
                {{ medianOfPercentagesElement(element) }} %
              </ng-container>
              <ng-container *ngIf="numberOfProperties(element) == 0">
                -
              </ng-container>

            </cdk-cell>

          </ng-container>

          &lt;!&ndash; Expanded Content Column - The detail row is made up of this one column that spans across all columns &ndash;&gt;
          <ng-container matColumnDef="expandedDetail">
            <cdk-cell *cdkCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div [@detailExpand]="expandedElements.includes(element) ? 'expanded' : 'collapsed'"
                   class="flex overflow-hidden w-full">
                <cdk-table [dataSource]="selectedDataSources.get(element)!" class="w-full">
                  <ng-container cdkColumnDef="name">

                    <cdk-cell class="flex-row justify-start items-center w-full h-full pl-7"
                              *cdkCellDef="let element"> {{ element.name }}
                    </cdk-cell>

                  </ng-container>

                  <ng-container cdkColumnDef="count">

                    <cdk-cell class="flex-row justify-start items-center text-center w-full h-full"
                              *cdkCellDef="let element"></cdk-cell>

                  </ng-container>

                  <ng-container cdkColumnDef="result">

                    <cdk-cell class="flex-row justify-start items-center text-center w-full h-full pr-1"
                              *cdkCellDef="let element"> {{ percentCorrectProperty(element) }} %
                    </cdk-cell>

                  </ng-container>

                  <cdk-row *cdkRowDef="let row; columns: displayedColumns;"
                           class="flex flex-row justify-start items-center w-full border-t-2 border-b-gray-400 py-2 px-2"></cdk-row>
                </cdk-table>
              </div>
            </cdk-cell>
          </ng-container>

          <cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky:true"
                          class="p-2 flex flex-row justify-start items-center w-full border-b-2 border-b-gray-400  hover:cursor-pointer bg-white"></cdk-header-row>
          <cdk-row *cdkRowDef="let row; columns: displayedColumns;"
                   (click)="selectElement(row);"

                   [class]="expandedElements.includes(row) ? 'flex flex-row justify-center items-center w-full border-t-2 border-b-gray-400 hover:bg-gray-200 py-2 px-2 hover:cursor-pointer font-medium rounded' : 'flex flex-row justify-center items-center w-full border-t-2 border-b-gray-400 hover:bg-gray-200 py-2 px-2 hover:cursor-pointer'"

          ></cdk-row>
          <cdk-row *cdkRowDef="let row; columns: ['expandedDetail']"></cdk-row>
        </cdk-table>
      </div>
    </div>-->

    <!-- IFC-Viewer -->
    <div *ngIf="false" class="w-full h-full rounded-md overflow-hidden shadow-full">
      <div class="w-full h-full flex justify-around items-center ">
        <bic-ifc-viewer class="w-full h-full relative" [ifcURL]="check?.projectFile.link ?? ''"
                        [succeedGUIDs]="failedGuids"></bic-ifc-viewer>
      </div>
    </div>

  </div>
</div>
