import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AddressInputComponent} from "./address-input.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../../../material.module";
import {ProjectStatePipe, ProjectStateReversePipe} from "../../../pipes/project-state.pipe";
import {AppModule} from "../../../app.module";
import {CountryPipe} from "../../../pipes/country.pipe";
import {SortCountriesPipe} from "../../../pipes/sort-countries.pipe";
import {BicUiComponentsModule} from "@buildinginformationcloud/bic-ui-components";



@NgModule({
    declarations: [
        AddressInputComponent,
        ProjectStatePipe,
      CountryPipe,
      SortCountriesPipe,
      ProjectStateReversePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        BicUiComponentsModule,

    ],
    exports: [
        AddressInputComponent,
      ProjectStatePipe,
      CountryPipe,
      SortCountriesPipe,
      ProjectStateReversePipe
    ]
})
export class AddressInputModule { }
