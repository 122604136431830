
<div class="flex flex-col w-full gap-3">
  <div class="flex flex-row-full justify-between items-center bg-gray-100 p-3">
    <span class="px-1 text-base font-semibold text-gray-700">Konto löschen?</span>
    <fa-icon [icon]="faXmark" class="text-gray-400 cursor-pointer" (click)="close()"></fa-icon>
  </div>
  <div class="flex flex-col w-full justify-center items-center py-3 gap-2">
    <span class="text-sm text-gray-700 px-4 py-2">Ihr Konto wird deaktiviert und anschließend gelöscht.</span>
    <div class="flex flex-col w-full px-4 py-2 justify-center items-center">
      <span class="text-sm text-gray-700 font-medium">Hinweis:</span>
      <span class="text-sm text-gray-700 text-center">Ihre Daten werden in 30 Tagen gelöscht. Sie können die Löschung innerhalb dieses Zeitraums über den Support widerrufen.</span>
    </div>
  </div>
  <div class="flex flex-row w-full justify-end items-center p-3 gap-4">
    <bic-button [text]="'Abbrechen'" [textColor]="'gray-900'" [color]="'white'" [border]="true" (onClick)="close()"></bic-button>
    <bic-button [text]="'Löschen'" (onClick)="delete()"></bic-button>

  </div>

</div>
